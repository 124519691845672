import { useTranslation } from "react-i18next";
import { Card, Row, Col, CardHeader, CardBody } from "reactstrap";
import Button from "../../../../components/Button";
import "./style.scss";

function Layout({ close, children, step, loading }) {
	const { t } = useTranslation();

	return (
		<div className="add-employee">
			<Card className="flex-fill card">
				<CardHeader className="responsive">
					<Row>
						<Col xs="12" md="6">
							<h3 className="">{t("people")}</h3>
							<p className="mb-0 text-xs font-weight-bold text-muted">
								{t("improve-business-efficiency")}
							</p>
						</Col>
						<Col
							className="text-right d-flex flex-wrap justify-content-md-end align-items-center mt-3 mt-md-0"
							xs="12"
							md="6"
						>
							{step > 0 && (
								<>
									<Button
										className="border-dark px-3 btn-round btn-icon"
										size="sm"
										onClick={close}
										disabled={loading}
									>
										{t("cancel")}
									</Button>
									<Button
										className="btn-dark px-3 btn-round btn-icon"
										color="default"
										size="sm"
										type="submit"
										loading={loading}
									>
										{t("save-and-continue")}
									</Button>
								</>
							)}
						</Col>
					</Row>
				</CardHeader>
				
				<CardBody className="d-flex flex-column justify-content-center align-items-center mb-auto px-0">
					{children}
				</CardBody>
			</Card>
		</div>
	);
}

export default Layout;
