import { useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "./Map";
import Marker from "./Marker";
import { mapStyleConfig } from "../../../../config/mapStyleConfig";
import {useQrCodeClockIn} from "../../context";

function Map() {
	const { area, position } = useQrCodeClockIn();

	const mapOptions = useMemo(
		() => ({
			center: position,
			zoom: 20,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			zoomControl: false,
			...mapStyleConfig,
		}),
		[position],
	);

	return (
		<Wrapper
			apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&callback=Function.prototype&libraries=places,drawing`}
		>
			<GoogleMap options={mapOptions} area={area} position={position}>
				<Marker
					position={position}
					icon={{
						path: window.google.maps.SymbolPath.CIRCLE,
						scale: 5,
						strokeColor: "red",
					}}
				/>
			</GoogleMap>
		</Wrapper>
	);
}

export default Map;
