import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Option } from "rc-select";
import DateInput from "../../../../../components/Inputs/DateInput";
import Field from "../../../../../components/Field";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import Text from "../../../../../components/Inputs/Text";
import CustomTimeInput from "../../../../../components/Inputs/CustomTimeInput";
import Select from "../../../../../components/Inputs/Select";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { useAuthentication } from "../../../../Authentication/context";
import { useCalculateDates } from "./useCalculateDates";

function FormContent({ mode, selected, form, disabled }) {
    const { t } = useTranslation();
    const { company } = useAuthentication();
    useCalculateDates(form);

    return (
        <>
            <BaseSettingsFormContent mode={mode} disabled={disabled} />

            <Field
                name="country"
                label={t("country")}
                rules={[
                    {
                        required: true,
                        message: t("required-country"),
                    },
                ]}
            >
                <ResourceSelect
                    labelPropName="name"
                    resourcePath="/countries?pagination=false"
                    hasCompany={false}
                    placeholder={t("country")}
                    hasSearch
                    disabled={disabled}
                />
            </Field>

            <Field
                name="frequency"
                label={t("frequency")}
                rules={[
                    {
                        required: true,
                        message: t("required-frequency"),
                    },
                ]}
            >
                <Select
                    placeholder={t("frequency")}
                    disabled={disabled}
                    open={
                        mode === "edit" && selected?.frequency
                            ? false
                            : undefined
                    }
                >
                    <Option value="weekly" key="weekly">
                        {t("weekly")}
                    </Option>

                    <Option value="biweekly" key="biweekly">
                        {t("biweekly")}
                    </Option>

                    <Option value="semi-monthly" key="semi-monthly">
                        {t("semi-monthly")}
                    </Option>

                    <Option value="monthly" key="monthly">
                        {t("monthly")}
                    </Option>
                </Select>
            </Field>

            <Field
                name="periodStartDate"
                label={t("period-start-date")}
                dependencies={["frequency, periodEndDate"]}
                hidden={true}
                className="d-flex flex-column"
            >
                <DateInput disabled={disabled} />
            </Field>

            <BaseField noStyle shouldUpdate>
                {({}, {}, { getFieldValue }) => (
                    <Field
                        name="periodEndDate"
                        dependencies={["frequency"]}
                        label={t("period-end-date")}
                        rules={[
                            {
                                required: true,
                                message: t("required-period-end-date"),
                            },
                        ]}
                        hidden={
                            !["weekly", "biweekly", undefined].includes(
                                getFieldValue("frequency"),
                            )
                        }
                        className="d-flex flex-column"
                    >
                        <DateInput disabled={disabled} />
                    </Field>
                )}
            </BaseField>

            <Field
                name="timeSheetLockDate"
                label={t("timesheet-lock-date")}
                rules={[
                    {
                        required: true,
                        message: t("required-timesheet-lock-date"),
                    },
                ]}
            >
                <Text
                    type="number"
                    placeholder={t("timesheet-lock-date")}
                    disabled={disabled}
                />
            </Field>

            <Field
                name="time"
                label={t("time")}
                rules={[
                    {
                        required: true,
                        message: t("required-time"),
                    },
                ]}
            >
                <CustomTimeInput
                    name="time"
                    form={form}
                    timeFormat={company?.settings.timeFormat}
                    className="w-100"
                    disabled={disabled}
                />
            </Field>
        </>
    );
}

export default FormContent;
