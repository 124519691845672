import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Field from ".";
import Switch from "../Inputs/Switch";

function Input({ value, onChange, ...props }) {
	const checked = useMemo(() => {
		if (value === "active") {
			return true;
		}
		return false;
	}, [value]);

	const componentOnChange = useCallback(
		(e) => {
			const val = e.target.checked ? "active" : "inactive";
			onChange(val);
		},
		[onChange]
	);

	return <Switch checked={checked} onChange={componentOnChange} {...props} />;
}

function StatusField({ disabled }) {
	const { t } = useTranslation();

	return (
		<Field name="status" label={t("status")}>
			<Input disabled={disabled} />
		</Field>
	);
}

export default StatusField;
