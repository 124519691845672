import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Option } from "rc-select";
import { formatNumber } from "libphonenumber-js";
import { createColumnHelper } from "@tanstack/react-table";
import ResourceSelect from "../../components/Inputs/ResourceSelect";
import Select from "../../components/Inputs/Select";
import Button from "../../components/Button";
import { Tooltip } from "reactstrap";
import { useFetchRoles, useImpersonate } from "./api";
import {
    getPrimaryActiveResource,
    renderUserName,
} from "../../utils/helpers/user";
import {
    renderSettingsResourceLabel,
    renderOrgLevelResourceLabel,
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
} from "../../utils/helpers/settings";
import { firstToUpper } from "../../utils/helpers/string";
import { OrgLevelGroupsContext } from "../Settings/General/OrganisationLevelGroups/context";
import { useAccess } from "../../utils/hooks/access";
import { useVisible } from "../../utils/hooks/useVisible";

export const getUserSearchFilters = (search) => ({
    search,
    fields: ["id", "firstName", "lastName"],
    withoutLookups: "true",
});

const columnHelper = createColumnHelper();

function UserGroupColumn({ userGroups, id }) {
    const { t } = useTranslation();
    const { visible, toggle } = useVisible();

    return (
        <>
            <span id={`user-groups-tooltip-${id}`}>
                {t("user-groups-count", {
                    count: userGroups?.length,
                })}
            </span>

            {userGroups?.length > 0 && (
                <Tooltip
                    isOpen={visible}
                    toggle={toggle}
                    trigger="hover"
                    target={`user-groups-tooltip-${id}`}
                    className="user-groups-tooltip"
                >
                    <div className="d-flex flex-column">
                        {userGroups?.map((group) => (
                            <span className="text-sm font-weight-bolder px-2 m-2">
                                {renderSettingsResourceLabel(group)}
                            </span>
                        ))}
                    </div>
                </Tooltip>
            )}
        </>
    );
}

export const useColumns = ({ verify, loadingVerify }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { groups } = useContext(OrgLevelGroupsContext);
    const { login, loading, userBeingImpersonated } = useImpersonate();

    const { roles } = useFetchRoles();

    const { hasAccess: canManageUsers } = useAccess("canManageUsers");

    const url = useMemo(() => {
        const query = new URLSearchParams();

        if (roles) {
            roles.forEach(
                (role) =>
                    role.permissions.canManageUsers &&
                    query.append("roleId", role.id)
            );
        }

        query.set("perPage", "20");
        query.set("includeSelf", "true");

        return `/users?${query.toString()}`;
    }, [roles]);

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("name", {
                enableColumnFilter: true,
                header: t("name"),
                cell: (info) => {
                    const { firstName, lastName } = info.row.original;
                    return (
                        firstName &&
                        lastName && (
                            <span
                                className="cursor-pointer text-primary"
                                onClick={() =>
                                    navigate(`/people/${info.row.original.id}`)
                                }
                            >
                                {renderUserName(info.row.original, false)}
                            </span>
                        )
                    );
                },
            }),
            columnHelper.accessor("employeeNumber", {
                enableColumnFilter: true,
                header: t("employee-number"),
            }),
            columnHelper.accessor("status", {
                enableColumnFilter: true,
                header: t("status"),
                cell: (info) => firstToUpper(info.getValue()),
                filterType: "select",
                Filter: (
                    <Select showSearch>
                        <Option value="active" key="active">
                            {t("active")}
                        </Option>

                        <Option value="inactive" key="inactive">
                            {t("inactive")}
                        </Option>

                        <Option value="suspended" key="suspended">
                            {t("suspended")}
                        </Option>

                        <Option value="leaveOfAbsence" key="leaveOfAbsence">
                            {t("leave-of-absence")}
                        </Option>

                        <Option value="retired" key="retired">
                            {t("retired")}
                        </Option>

                        <Option value="terminated" key="terminated">
                            {t("terminated")}
                        </Option>

                        <Option value="ready" key="ready">
                            {t("ready")}
                        </Option>

                        <Option value="unverified" key="unverified">
                            {t("unverified")}
                        </Option>
                    </Select>
                ),
            }),
            columnHelper.accessor("locations.locationId", {
                enableColumnFilter: true,
                header: t("location"),
                cell: (info) => {
                    const { locations } = info.row.original;
                    const location = getPrimaryActiveResource(locations);
                    return location && renderSettingsResourceLabel(location);
                },
                filterType: "resource-select",
                Filter: (
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath="/locations"
                        mode="multiple"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                        hasSearch
                    />
                ),
            }),
            columnHelper.accessor("jobs.jobId", {
                enableColumnFilter: true,
                header: t("job-title"),
                cell: (info) => {
                    const { jobs } = info.row.original;
                    const job = getPrimaryActiveResource(jobs);
                    return job && renderSettingsResourceLabel(job);
                },
                filterType: "resource-select",
                Filter: (
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath="/jobs"
                        mode="multiple"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                        hasSearch
                    />
                ),
            }),
            columnHelper.accessor("userGroups", {
                enableColumnFilter: true,
                header: t("groups"),
                cell: (info) => {
                    const { userGroups, id } = info.row.original;
                    return <UserGroupColumn userGroups={userGroups} id={id} />;
                },
                filterType: "resource-select",
                Filter: (
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath="/user-group"
                        mode="multiple"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                        hasSearch
                    />
                ),
            }),
            columnHelper.accessor("email", {
                enableColumnFilter: true,
                header: t("email"),
            }),
            columnHelper.accessor("phone", {
                enableColumnFilter: true,
                header: t("phone-number"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && formatNumber(value, "INTERNATIONAL");
                },
            }),
            columnHelper.accessor("supervisors.userId", {
                enableColumnFilter: true,
                header: t("supervisor"),
                cell: (info) => {
                    const { supervisors } = info.row.original;
                    const primarySupervisor = supervisors?.find(
                        (supervisor) => supervisor.primary
                    );
                    if (!primarySupervisor) {
                        return null;
                    }
                    return (
                        <div className="text-left">
                            {renderUserName(primarySupervisor)}
                        </div>
                    );
                },
                filterType: "resource-select",
                Filter: (
                    <ResourceSelect
                        labelPropName="firstName"
                        renderLabel={renderUserName}
                        resourcePath={url}
                        mode="multiple"
                        hasSearch
                        getSearchFilters={getUserSearchFilters}
                    />
                ),
            }),
        ];

        if (groups) {
            groups.forEach((group) => {
                const level = `${group.level}`.replace("_", "");
                const column = columnHelper.accessor(level, {
                    enableColumnFilter: true,
                    header: group.description,
                    cell: (info) => {
                        const value = info.getValue();
                        return value && renderOrgLevelResourceLabel(value);
                    },
                    filterType: "resource-select",
                    Filter: (
                        <ResourceSelect
                            labelPropName="description"
                            resourcePath={`${group.id}/org-levels`}
                            mode="multiple"
                            renderLabel={renderOrgLevelResourceLabel}
                            getSearchFilters={
                                getSettingsOrgResourceSearchFilters
                            }
                            valuePropName="id"
                            hasSearch
                        />
                    ),
                });
                columns.push(column);
            });
        }

        if (canManageUsers) {
            const actions = columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableColumnFilter: false,
                header: () => <div className="text-right">{t("actions")}</div>,
                cell: (info) => {
                    const { id, status } = info.row.original;

                    return (
                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                className="bg-transparent shadow-none border-0 text-primary p-0"
                                onClick={() =>
                                    login(id, () => navigate("/redirect"))
                                }
                                loading={userBeingImpersonated === id}
                                disabled={
                                    loadingVerify ||
                                    (userBeingImpersonated &&
                                        id !== userBeingImpersonated)
                                }
                            >
                                <i className="fa-solid fa-right-to-bracket mr-1" />
                                {t("log-in")}
                            </Button>

                            {status === "unverified" && verify && (
                                <Button
                                    className="bg-transparent shadow-none border-0 text-info p-0"
                                    onClick={() => verify(info.row.original)}
                                    loading={loadingVerify}
                                    disabled={userBeingImpersonated === id}
                                >
                                    <i className="fa-solid fa-circle-check mr-1" />
                                    {t("verify")}
                                </Button>
                            )}
                        </div>
                    );
                },
            });
            columns.push(actions);
        }

        return columns;
    }, [
        canManageUsers,
        groups,
        url,
        loadingVerify,
        login,
        verify,
        navigate,
        t,
        userBeingImpersonated,
    ]);
};
