import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getToastErrorMessage } from "../../../utils/helpers/errors";
import { formatTimeSheetParams } from "./helpers";

export const useBatchUpdateTimeSheets = (userId) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const { authPut } = useApi();

	const submit = useCallback(
		async ({ added, updated, filters }, onSuccess, onFailure) => {
			setLoading(true);
			try {
				const response = await authPut("/time-sheet/batch", {
					data: { ...updated, 0: added },
					params: formatTimeSheetParams({ userId, ...filters }),
				});
				await onSuccess?.(response);
				toast.success(t("timesheets-updated-successfully"));

			} catch (err) {
				await onFailure?.(err);
				toast.error(getToastErrorMessage(err));
			} finally {
				setLoading(false);
			}
		},
		[t, userId, setLoading, authPut]
	);

	return { submit, loading };
};
