export const hasAtLeastOneAccess = (map) => {
	for (const child of Object.values(map)) {
		switch (typeof child) {
			case "boolean": {
				if (child) {
					return true;
				}
				break;
			}
			case "object": {
				const value = hasAtLeastOneAccess(child);
				if (value) {
					return value;
				}
				break;
			}
			default:
				break;
		}
	}
	return false;
};

export const getViewPermissions = (map, basePath = "") => {
	const permissions = [];
	for (const key of Object.keys(map)) {
		const value = map[key];
		if (typeof value === "boolean" && key === "canView") {
			permissions.push(`${basePath}.${key}`);
		} else {
			const valuePermissions = getViewPermissions(
				value,
				`${basePath}.${key}`,
			);
			permissions.push(...valuePermissions);
		}
	}
	return permissions;
};
