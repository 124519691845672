import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useNameColumn } from "../../Table/useColumns/useNameColumn";
import { useEmployeeNumberColumn } from "../../Table/useColumns/useEmployeeNumberColumn";
import { usePayGroupColumn } from "../../Table/useColumns/usePayGroupColumn";
import { useGroupColumns } from "../../Table/useColumns/useGroupColumns";
import { useLocationsColumn } from "../../Table/useColumns/useLocationsColumn";
import { useJobsColumn } from "../../Table/useColumns/useJobsColumn";
import { usePaymentColumn } from "../../Table/useColumns/usePaymentColumn";
import { useEarningGroupsColumns } from "../../Table/useColumns/useEarningGroupsColumns";

export const useColumns = ({ response, loading }) => {
    const { t } = useTranslation();

    const nameColumn = useNameColumn({ viewProfile: undefined });
    const employeeNumberColumn = useEmployeeNumberColumn();
    const payGroupColumn = usePayGroupColumn();
    const groupColumns = useGroupColumns();
    const locationsColumn = useLocationsColumn();
    const jobsColumn = useJobsColumn();
    const earningGroupsColumns = useEarningGroupsColumns();

    const paymentColumn = usePaymentColumn();

    return useMemo(() => [
        nameColumn,
        employeeNumberColumn,
        payGroupColumn,
        ...groupColumns,
        locationsColumn,
        jobsColumn,
        ...earningGroupsColumns,
        paymentColumn,
        {
            Header: t("status"),
            accessor: "message",
            Cell: ({
                row: {
                    original: { id },
                },
            }) =>
                response ? (
                    <span
                        className={classNames(
                            response[id]?.status
                                ? "text-green"
                                : "text-danger",
                        )}
                    >
                        {response[id]?.message}
                    </span>
                ) : loading ? (
                    `${t("copying")}...`
                ) : (
                    t("ready-to-copy")
                ),
        },
    ], [
        nameColumn,
        employeeNumberColumn,
        payGroupColumn,
        groupColumns,
        locationsColumn,
        jobsColumn,
        earningGroupsColumns,
        paymentColumn,
        response,
        loading,
        t
    ]);
}