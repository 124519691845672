import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import BaseForm, { Field as BaseField } from "rc-field-form";
import { Label } from "reactstrap";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import Select from "../../../../../components/Inputs/Select";
import SendOptionSelect from "./SendOptionSelect";
import StatusField from "../../../../../components/Field/StatusField";
import StartAndEndFields from "./StartAndEndFields";
import Cron from "./Cron";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
	useCompanyTimeZone,
	useIsMasterCompany,
} from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { applyTimezone } from "../../../../../utils/helpers/date";
import ReportSelect from "./ReportSelect";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { renderUserName } from "../../../../../utils/helpers/user";
import { getUserSearchFilters } from "../../../../People/useColumns";

export const getReportsSearchFilters = (search) => ({
	"filter[name][method]": "contains",
	"filter[name][value]": search,
	fields: ["id", "name"],
	filterType: "or",
	withoutLookups: "true",
});

export const renderReportsLabel = ({ name }) => {
	return name ? name : "";
};

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = BaseForm.useForm();
	const [cronTimeConfig, setCronTimeConfig] = useState(
		values ? values?.cronTimeConfig : "* * * * *",
	);
	const timezone = useCompanyTimeZone();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.scheduling.reports");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(formValues) => {
			const data = {
				type: "report",
				description: formValues?.description,
				user: formValues?.user?.id,
				fileFormat: formValues?.fileFormat,
				config: {
					reportId: formValues?.reportId,
					sendVia: formValues?.sendVia,
					emails: formValues?.emails,
					subject: formValues?.subject,
					isSystem: formValues?.isSystem,
					body: formValues?.body,
					filters: {
						type: formValues?.filters,
						from: applyTimezone(
							formValues.from,
							timezone,
							true,
						).toISOString(true),
						to: applyTimezone(
							formValues.to,
							timezone,
							true,
						).toISOString(true),
					},
					protocol: formValues?.protocol,
					port: formValues?.port,
					host: formValues?.host,
					path: formValues?.path,
					ftpUsername: formValues?.ftpUsername,
					ftpPassword: formValues?.ftpPassword,
					filename: formValues?.filename,
				},
				status: formValues?.status,
				cronTimeConfig,
			};

			submit(data);
		},
		[submit, cronTimeConfig, timezone],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: values?.status || "active",
			description: values?.description,
			user: values?.user,
			fileFormat: values?.fileFormat,
			...values?.config,
			filters: values?.config?.filters?.type,
			start:
				values?.config?.filters?.from &&
				moment().diff(
					moment(values?.config?.filters?.from).format("YYYY-MM-DD"),
					"days",
				),
			end:
				values?.config?.filters?.to &&
				moment().diff(
					moment(values?.config?.filters?.to).format("YYYY-MM-DD"),
					"days",
				),
			...values?.cronTimeConfig,
		});
	}, [form, values]);

	useEffect(() => {
		const filters = form.getFieldValue("filters");
		if (filters === "custom") {
			const start = form.getFieldValue("start");
			const end = form.getFieldValue("end");

			if (start) {
				form.setFieldsValue({
					from: moment().subtract(Number(start), "days"),
				});
			}

			if (end) {
				form.setFieldsValue({
					to: moment().subtract(Number(end), "days"),
				});
			}
		}
	}, [form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], error);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<BaseForm
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Field
					name="description"
					label={t("description")}
					rules={[
						{
							required: true,
							message: t("required-description"),
						},
					]}
				>
					<Text placeholder={t("description")} disabled={disabled} />
				</Field>

				<Field
					name="reportId"
					label={t("report")}
					rules={[
						{
							required: true,
							message: t("required-report"),
						},
					]}
				>
					<ReportSelect
						form={form}
						placeholder={t("report")}
						disabled={disabled}
					/>
				</Field>

				<Field
					name="user"
					label={t("generate-as")}
					rules={[
						{
							required: true,
							message: t("generate-as-required"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/users?includeSelf=true"
						renderLabel={renderUserName}
						placeholder={t("generate-as")}
						hasSearch
						getSearchFilters={getUserSearchFilters}
					/>
				</Field>

				<BaseField name="isSystem" hidden={true} />

				<Field
					name="filters"
					label={t("filters")}
					rules={[
						{ required: true, message: t("required-filters") },
					]}
					dependencies={["reportId"]}
				>
					<Select placeholder={t("filters")} disabled={disabled}>
						<Select.Option value="previous-day">
							{t("previous-day")}
						</Select.Option>

						<Select.Option value="previous-week">
							{t("previous-week")}
						</Select.Option>

						<Select.Option value="custom">
							{t("custom")}
						</Select.Option>
					</Select>
				</Field>

				<BaseField shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const filters = getFieldValue("filters");

						return (
							filters === "custom" && (
								<StartAndEndFields
									form={form}
									disabled={disabled}
								/>
							)
						);
					}}
				</BaseField>

				<SendOptionSelect form={form} disabled={disabled} />

				<Label className="mb-1">{t("scheduled-on")}:</Label>

				<Cron
					cronTimeConfig={cronTimeConfig}
					setCronTimeConfig={setCronTimeConfig}
					disabled={disabled}
				/>

				<Field
					name="fileFormat"
					label={t("file-format")}
					rules={[
						{
							required: true,
							message: t("file-format-required"),
						},
					]}
				>
					<Select>
						<Select.Option value="json">{t("json")}</Select.Option>
						<Select.Option value="csv">{t("csv")}</Select.Option>
						<Select.Option value="excel">{t("excel")}</Select.Option>
					</Select>
				</Field>

				<StatusField disabled={disabled} />

				<BaseField name="from">
					<Text hidden />
				</BaseField>

				<BaseField name="to">
					<Text hidden />
				</BaseField>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</BaseForm>
	);
}

export default Form;
