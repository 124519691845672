import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { Field as BaseField } from "rc-field-form";
import CompensationSelect from "../../../../components/Inputs/CompensationSelect";
import MoneyInput from "../../../../components/Inputs/MoneyInput";
import CurrencySelect from "../../../../components/Inputs/CurrencySelect";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { renderCurrencySymbol } from "../../../../utils/helpers/job";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../utils/helpers/settings";
import Field from "../../../../components/Field";

function PayInformation() {
	const { t } = useTranslation();

	return (
		<Row>
			<Col sm={12} md={6}>
				<Field
					name="hourlyOrSalaried"
					label={t("hourly-or-salary")}
					rules={[
						{
							required: true,
							message: t("required-hourly-or-salary"),
						},
					]}
				>
					<CompensationSelect placeholder={t("hourly-or-salary")} />
				</Field>
			</Col>

			<Col sm={12} md={6}>
				<Field
					name="currency"
					label={t("currency")}
					rules={[
						{
							required: true,
							message: t("required-currency"),
						},
					]}
				>
					<CurrencySelect placeholder={t("currency")} />
				</Field>
			</Col>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue }) => {
					const compensation = getFieldValue("hourlyOrSalaried");

					switch (compensation) {
						case "H": {
							return (
								<Col sm={12} md={6}>
									<Field
										name="hourlyRate"
										dependency={["currency"]}
										label={t("hourly-rate")}
										rules={[
											{
												required: true,
												message: t(
													"required-hourly-rate"
												),
											},
										]}
									>
										<MoneyInput
											prefix={renderCurrencySymbol(
												getFieldValue("currency"),
											)}
											className="form-control"
											placeholder={t("hourly-rate")}
										/>
									</Field>
								</Col>
							);
						}
						case "S": {
							return (
								<Col sm={12} md={6}>
									<Field
										name="salary"
										label={t("salary")}
										dependency={["currency"]}
										rules={[
											{
												required: true,
												message:
													t("required-salary"),
											},
										]}
									>
										<MoneyInput
											prefix={renderCurrencySymbol(
												getFieldValue("currency"),
											)}
											className="form-control"
											placeholder={t("salary")}
										/>
									</Field>
								</Col>
							);
						}
					}
				}}
			</BaseField>

			<Col sm={12} md={6}>
				<Field
					name="employeeType"
					label={t("employee-type")}
					rules={[
						{
							required: true,
							message: t("required-employee-type"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/employee-type"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						placeholder={t("employee-type")}
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>
			</Col>
		</Row>
	);
}

export default PayInformation;
