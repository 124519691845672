import ColumnToggle from "./ColumnToggle";
import { useFilters } from "../../filtersContext";
import BulkActions from "./BulkActions";
import { useCallbackWithReset } from "../../hooks/useCallbackWithReset";
import { useFilters as useFiltersConfig } from "../Actions/useFilters";
import { useMemo } from "react";
import { useExport } from "../hooks/useExport";
import Download from "./Download";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConfigFilterColumns } from "../../../Settings/Configuration/hooks/useConfigFilterColumns";
import Filters from "../../../../components/Table/Actions/Filters";

const Actions = ({
    columns,
    selectedRowIds,
    viewSummary,
    ...props
}) => {
    const [filters, onFiltersChange] = useFilters();
    const setFiltersAndReset = useCallbackWithReset(onFiltersChange);

    const ids = useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);
    const columnKeys = useMemo(
        () => columns.map(({ id }) => id)?.filter((c) => !!c),
        [columns],
    );
    const { downloadSummary, downloadDetails } = useExport({
        ids,
        columns: columnKeys,
        filters,
    });

    const filterColumns = useConfigFilterColumns(
        "myTimesheet",
        useFiltersConfig(),
    );

    return (
        <div className="d-flex justify-content-between align-items-start mb-2">
            <div className="d-flex align-items-start">
                <BulkActions filters={filters} {...props} />
            </div>

            <div>
                <Filters
                    columns={filterColumns}
                    filters={filters}
                    setFilters={setFiltersAndReset}
                />

                {viewSummary && (
                    <FontAwesomeIcon
                        className="cursor-pointer mr-2"
                        style={{ fontSize: 15 }}
                        icon={faEye}
                        onClick={viewSummary}
                    />
                )}

                <Download
                    downloadSummary={downloadSummary}
                    downloadDetails={downloadDetails}
                />

                <ColumnToggle
                    prefix="timesheet-user"
                    columns={columns}
                />
            </div>
        </div>
    );
}

export default Actions;
