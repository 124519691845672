import moment from "moment-timezone";
import {useCallback} from "react";
import {getDurationInputValue} from "../../../../../../components/Inputs/DurationInput";
import {getMoneyInputValue} from "../../../../../../components/Inputs/MoneyInput";
import {combineDateAndTime} from "../../../../../../utils/helpers/date";
import {calculateRegularRate} from "../../../../../../utils/helpers/shift";
import {getPrimaryActiveResource, isCommonInUserActive} from "../../../../../../utils/helpers/user";
import {useAccess} from "../../../../../../utils/hooks/access";
import {useCompanyTimeZone} from "../../../../../../utils/hooks/company";
import {useGroups} from "../../../../../Settings/General/OrganisationLevelGroups/context";

const getCommonOrDefault = ({ resources, value, identifier }) => {
    let val = resources?.find((item) => {
        if (!isCommonInUserActive(item)) {
            return false;
        }
        return item[identifier] === value;
    });
    if (!val && resources?.length > 0) {
        val = getPrimaryActiveResource(resources)
    }
    return val;
}

export const useGenerateBulkTimeSheets = (users) => {
    const {groups} = useGroups();
    const timezone = useCompanyTimeZone();
	const { hasAccess: canUpdateRate } = useAccess("timeSheet.canUpdateRate");

    const getLevels = useCallback(({ user, values }) => {
        return groups?.reduce((total, group) => {
            const level = group?.level.replace("_", "");
            if (user[level]) {
                total[level] = user[level];
            }
            if (values[level]) {
                total[level] = values[level];
            }
            return total;
        }, {});
    }, [groups]);

    return useCallback((values) => {
        return users.reduce((total, user) => {
            if (!(user.id in total)) {
                total[user.id] = [];
            }
            const levels = getLevels({ user, values });
            const job = getCommonOrDefault({
                resources: user.jobs,
                value: values.job,
                identifier: "jobId",
            });
            const location = getCommonOrDefault({
                resources: user.locations,
                value: values.location,
                identifier: "locationId",
            });
            for (
                let date = moment(values.startDate);
                date.isSameOrBefore(values.endDate);
                date.add(1, "day")
            ) {
                const startTime = combineDateAndTime(date, moment(values.in), timezone);
                let endTime = combineDateAndTime(date, moment(values.out), timezone);
                if (endTime.isBefore(startTime)) {
                    endTime.add(1, "day");
                }
                const formattedDate = date.format("YYYY-MM-DD");
                const timeSheet = {
                    id: `${user.id}-${formattedDate}`,
                    user,
                    date: formattedDate,
                    startDate: startTime.toISOString(true),
                    endDate: endTime.toISOString(true),
                    payCode: values.payCode,
                    job,
                    location,
                    customFields: values.customFields,
                    ...levels,
                };
                switch (values.payCode?.hourType) {
                    case "unit": {
                        timeSheet.duration = getDurationInputValue(values.duration);
                        break;
                    }
                    case "amount": {
                        const rate = typeof values.rate === "string" ? getMoneyInputValue(values.rate) : values.rate;
                        const rateChangeManually = canUpdateRate && (Number(job?.hourlyRate) || 0) !== rate;
                        const newRegRate = calculateRegularRate({
                            rate,
                            payCode: values.payCode,
                        })

                        Object.assign(timeSheet, {
                            rate,
                            rateChangeManually,
                            regRate: newRegRate,
                        });
                        break;
                    }
                    default:
                        break;
                }
                total[user.id].push(timeSheet);
            }
            return total;
        }, {});
    }, [users, getLevels]);
}
