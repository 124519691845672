import moment from "moment-timezone";
import { isTerminated, isUserActive } from "../../../../../../utils/helpers/user";

export const isRowAfterTermination = (user, row) => {
    const terminated = isTerminated(user);
    const terminationDate = terminated && moment.parseZone(user.terminatedAt).endOf('day')
    if (!terminationDate) {
        return false;
    }
    return moment.utc(row.original.date).startOf('day').isAfter(terminationDate);
}

export const isRowBeforeHiring = (user, row) => {
    return !isUserActive(user, row.original.date);
}

export const isRowConfigured = (user, row) => {
    return !isUserActive(user, row.original.date);
}

export const isJobActive = (j, date) => {
    if (!j.endDate) {
        return moment.parseZone(j.startDate).startOf('day').isBefore(moment.parseZone(date).endOf('day'));
    } else {
        return moment.parseZone(j.startDate).startOf('day').isBefore(moment.parseZone(date).endOf('day')) &&
            moment.parseZone(date).startOf('day').isBefore(moment.parseZone(j.endDate).endOf('day'))
        ;
    }
}

export const getActiveJobs = (user, date) => {
    const jobs = user?.jobHistory || user?.jobs || [];
    return (jobs || []).filter(j => isJobActive(j, date));
}