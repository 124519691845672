import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
	useExpanded,
	useRowState,
	useMountedLayoutEffect,
} from "react-table";
import { isEqual, noop } from "lodash";
import { useInitialRowStateAccessor } from "./useInitialRowStateAccessor";
import { useInitialState } from "./useInitialState";

import { useConfigurationModule } from "../../../../../Settings/Configuration/hooks/useConfigurationModule";

const useExtendedExpandInstance = (instance) => {
	const {
		state: { globalFilter, pageIndex, pageSize, sortBy },
	} = instance;

	const resetExpandedRows = useCallback(() => {
		instance.toggleAllRowsExpanded(false);
	}, [instance]);

	useMountedLayoutEffect(() => {
		resetExpandedRows();
	}, [resetExpandedRows, globalFilter, pageIndex, pageSize, sortBy]);
};

export const useManagerViewTable = ({
	refetchUsers,
	columns,
	data,
	setData,
	totalItems,
	...props
}) => {
	const [pageCount, setPageCount] = useState(
		Math.ceil(totalItems / 100),
	);

	const updateUser = useCallback(
		(user) => {
			setData((prev) => {
				return prev.map((prevUser) => user?.id === prevUser?.id ? { ...prevUser, ...user } : prevUser);
			});
		},
		[setData],
	);

	const initialState = useInitialState({
		pageSize: useConfigurationModule("timesheetManagerView.defaultPageSize") || 100,
	});

	const initialRowStateAccessor = useInitialRowStateAccessor();

	const table = useTable(
		{
			columns,
			data,
			totalItems,
			initialState,
			autoResetHiddenColumns: false,
			initialRowStateAccessor,
			stateReducer: useCallback(
				(state, action, prevState) => {
					let newState = { ...state };
					if (action.type === "toggleRowExpanded") {
						newState.rowState[action.id] = {
							...newState.rowState[action.id],
							data: [],
							loading: false,
							totalItems: 0,
						};
					}
					if (action.type === "toggleAllRowsExpanded") {
						Object.keys(newState.expanded).forEach((id) => {
							newState.rowState[id] = {
								...newState.rowState[action.id],
								data: [],
								loading: false,
								totalItems: 0,
							};
						});
					}
					if (
						action.type === "setGlobalFilter" &&
						!isEqual(newState.globalFilter, prevState.globalFilter)
					) {
						newState.pageIndex = 0;
					}

					return newState;
				},
				[],
			),
			manualPagination: true,
			manualSortBy: true,
			manualFilters: true,
			manualGlobalFilter: true,
			autoResetExpanded: false,
			autoResetRowState: false,
			pageCount,
			defaultColumn: {
				hideable: true,
				alwaysVisible: false,
				disableFilters: true,
			},
			filterTypes: useMemo(() => ({}), []),
			getRowId: useCallback(({ id }) => id, []),
			...props,

			updateUser,
			refetchOverview: noop,
			refetchUsers,
		},
		(hooks) => {
			hooks.useInstance.push(useExtendedExpandInstance);
		},
		useFilters,
		useGlobalFilter,
		useExpanded,
		useRowState,
		usePagination,
	);

	useEffect(() => {
		setPageCount(Math.ceil(totalItems / table.state.pageSize));
	}, [setPageCount, totalItems, table.state.pageSize]);

	return table;
};
