import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isAddedTimeSheet } from "../helpers";

export const useOvernightColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("on"),
        accessor: "overnight",
        Cell: ({ value, row: { original } }) => {
            const { startDate, endDate } = original;
            if (!isAddedTimeSheet(original)) {
                return value;
            }

            let duration = 0;
            if (startDate && endDate) {
                const parsedStartDate = moment.parseZone(startDate);
                const parsedEndDate = moment.parseZone(endDate);
                if (parsedEndDate.date() !== parsedStartDate.date()) {
                    duration = parsedEndDate.date() - parsedStartDate.date();
                }
            }
            return `+${duration}`;
        },
    }), [t]);
};
