import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

function Layout({ children, step }) {
	const { t } = useTranslation();

	return (
		<div
			className="authentication page-header min-vh-100"
			style={{
				backgroundImage: `url(/assets/images/background.jpg)`,
				backgroundSize: "cover",
			}}
		>
			<Container>
				<Row className="justify-content-center pt-2">
					<Col lg={6} md={7} className="mx-auto text-center">
						<h2 className="fadeIn1 fadeInBottom text-dark">
							{t("let-set-up")}
						</h2>

						<p className="lead fadeIn2 fadeInBottom text-dark">
							{t("app-and-running")}
						</p>
					</Col>
				</Row>

				<Row className="">
					<Col className="col-12">
						<div className="multisteps-form">
							<Col lg={8} className="mx-auto my-4">
								<Card>
									<CardBody>
										<div className="multisteps-form__progress">
											<button
												className={classNames(
													step === 0 ||
														step === 1 ||
														step === 2 ||
														step === 3
														? "multisteps-form__progress-btn shadow-none hover:bg-transparent js-active"
														: "multisteps-form__progress-btn shadow-none hover:bg-transparent",
												)}
												title="Company Info"
											>
												<span>{t("company")}</span>
											</button>

											<button
												className={classNames(
													step === 1 ||
														step === 2 ||
														step === 3
														? "multisteps-form__progress-btn shadow-none hover:bg-transparent js-active"
														: "multisteps-form__progress-btn shadow-none hover:bg-transparent",
												)}
												title="Address"
											>
												{t("branding")}
											</button>

											<button
												className={classNames(
													step === 2 || step === 3
														? "multisteps-form__progress-btn shadow-none hover:bg-transparent js-active"
														: "multisteps-form__progress-btn shadow-none hover:bg-transparent",
												)}
												title="Socials"
											>
												{t("phone")}
											</button>

											<button
												className={classNames(
													"multisteps-form__progress-btn shadow-none",
													step === 4 && "js-active",
												)}
												title="Profile"
											>
												{t("employees")}
											</button>
										</div>
									</CardBody>
								</Card>
							</Col>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="col-12">
						<div className="multisteps-form mb-5">
							<Col lg={8} className="mx-auto my-4">
								{children}
							</Col>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Layout;
