import { useCallback, useMemo } from "react";
import { Card, CardHeader } from "reactstrap";
import useSWR from "swr";
import Topbar from "./Topbar";
import MySchedulerFilters from "./Filters";
import Scheduler from "../SchedulerComponent";
import { useDateFilters } from "../hooks/useDateFilters";
import { useColumns } from "../hooks/useColumns";
import { useHasCompany } from "../../../utils/hooks/company";
import { formatTimeSheetParams } from "../../TimeSheet/api/helpers";
import useApi from "../../../utils/api";
import { useFilters } from "../../TimeSheet/filtersContext";
import { prepareEventsForScheduler } from "../helpers/prepareEventsForScheduler";

function Content() {
	const { authGet } = useApi();
	const [filters, onFiltersChange] = useFilters();
	
	const columns = useColumns({});

	const { from, to } = useDateFilters();

	const params = useMemo(() => {
		return ({
			pageIndex: 0,
			pageSize: 100,
			...(formatTimeSheetParams({
				from,
				to,
				filters
			})),
		})
	}, [filters, from, to]);

	const hasCompany = useHasCompany();

	const {
		data: { data: { events: userEvents, resources } },
		isLoading,
		mutate
	} = useSWR(
		hasCompany ? {
			url: "/my/schedules",
			params: params,
		} : null,
		{ fallbackData: { data: { resources: [], events: [] }, meta: { total: 0 } } },
	);
	
	const fetchUserSchedules = useCallback(async (event, schedule) => {
		return authGet ("/my/schedules/events", {
			params
		}).then((response) => {
			const events = response.flat();
			mutate((prev) => {
				const data = {
					data: {
						resources: prev?.data?.resources,
						events,
						success: prev?.data?.success
					},
					meta: prev?.meta
				};
				return data;
			}, { revalidate: false });
			schedule?.refresh();
		}).catch((error) => {
			// getErrorMessage(error, t);
		});
	}, [authGet, params, mutate]);

	const filterComponent = useMemo(() => {
		return (
			<MySchedulerFilters
				filters={filters}
				setFilters={onFiltersChange}
			/>
		)
	}, [filters, onFiltersChange]);

	return (
		<Card className="team-scheduler px-0 m-0 h-100 position-static">
			<CardHeader>
				<Topbar params={params} />
			</CardHeader>

			<Scheduler
				mutate={mutate}
				resources={resources}
				events={prepareEventsForScheduler(userEvents || [])}
				columns={columns}
				isLoading={isLoading}
				fetchUserSchedules={fetchUserSchedules}
				params={params}
				filters={filterComponent}
			/>
		</Card>
		
	);
}

export default Content;
