import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { useProfileApi } from "../../../utils/api/profile";

export const useSetPersonalDetails = (token, company) => {
	const [user, setUser] = useState();
	const [loadingUser, setLoadingUser] = useState(false);
	const [loadingUserError, setLoadingUserError] = useState();
	const [loadingSetPassword, setLoadingSetPassword] = useState(false);
	const [setPasswordError, setSetPasswordError] = useState(null);

	const [submitting, setSubmitting] = useState(false);
	const [submittingError, setSubmittingError] = useState(null);
	const { changePersonalInformation } = useProfileApi();

	const { authGet, authPost } = useApi();

	const fetchUser = useCallback(async () => {
		setLoadingUser(true);
		setLoadingUserError(null);
		try {
			const response = await authGet("/check-token", {
				headers: { Authorization: `Bearer ${token}` },
			});
			if (response) {
				setUser(response.user);
			}
		} catch (err) {
			setLoadingUserError(err);
			if (typeof err === "string") {
				toast.error(err);
			}
		} finally {
			setLoadingUser(false);
		}
	}, [token, authGet, setUser, setLoadingUser, setLoadingUserError]);

	const submitPersonalDetails = useCallback(
		async (data) => {
			setSubmitting(true);
			setSubmittingError(null);
			try {
				const response = await changePersonalInformation(
					data,
					token,
					company,
				);
				if (response) {
					setUser({ ...response, status: "active" });
				}
			} catch (err) {
				setSubmittingError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setSubmitting(false);
			}
		},
		[
			setSubmitting,
			setSubmittingError,
			token,
			changePersonalInformation,
			company,
		],
	);

	const submitChangePassword = useCallback(
		async (data) => {
			setLoadingSetPassword(true);
			setSetPasswordError(null);

			try {
				const response = await authPost("/profile/initial-password", {
					data,
					headers: { Authorization: `Bearer ${token}` },
				});
				if (response) {
					setUser(response);
				}
			} catch (err) {
				setSetPasswordError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoadingSetPassword(false);
			}
		},
		[setLoadingSetPassword, setSetPasswordError, authPost, token],
	);

	useEffect(() => {
		fetchUser();
	}, [fetchUser]);

	return {
		user,
		loadingUser,
		loadingUserError,
		submitChangePassword,
		submitPersonalDetails,
		submitting,
		submittingError,
		loadingSetPassword,
		setPasswordError,
	};
};
