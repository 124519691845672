import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../components/Field";
import Switch from "../../../../../../components/Inputs/Switch";
import Checkbox from "../../../../../../components/Inputs/Checkbox";

const options = [
	{
		label: "15-min-rounding",
		value: 15,
		description: "15-min-rounding-description"
	},
	{
		label: "6-min-rounding",
		value: 6,
		description: "6-min-rounding-description"

	},
	{
		label: "5-min-rounding",
		value: 5,
		description: "5-min-rounding-description"

	},
];

function RoundingRules({ disabled, rounding }) {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(rounding ? rounding : 0);

	return (
		<div className="border-bottom mb-10">
			<div className="d-flex align-items-center mb-3">
				<h3 className="text-sm font-weight-bolder mb-0 mr-2">
					{t("rounding-rules")}
				</h3>

				<BaseField noStyle shouldUpdate>
					{({ }, { }, { getFieldValue, setFieldsValue }) => (
						<Switch
							size="sm"
							id="status"
							checked={getFieldValue("rounding") !== 0}
							onChange={(e) => {
								setFieldsValue({
									rounding: e.target.checked ? undefined : 0,
								});
							}}
							disabled={disabled}
						/>
					)}
				</BaseField>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldsValue, getFieldError }) => {
					const value = getFieldValue("rounding");
					const status = value !== 0;

					return status ? (
						<Field
							name="rounding"
							rules={[
								{
									required: !selected ? true : false,
									message: t("required-rounding-rules"),
								},
							]}
						>
							<>
								{options.map(
									({ label, value, description }) => (
										<div className="d-flex flex-column mb-2">
											<Checkbox
												id={value}
												checked={selected === value}
												onChange={() => {
													setSelected(value);
													setFieldsValue({
														rounding: value,
													});
												}}
												disabled={disabled}
											>
												{t(label)}
											</Checkbox>

											<p className="text-muted text-sm pl-4">
												{t(description)}
											</p>
										</div>
									),
								)}

								<p className="text-danger text-sm">
									{getFieldError("rounding")}
								</p>
							</>
						</Field>
					) : null;
				}}
			</BaseField>
		</div>
	);
}

export default RoundingRules;
