import { useCallback } from "react";
import moment from "moment-timezone";
import { useEventDragFeature } from "./useEventDragFeature";
import { useEventMenuFeature } from "./useEventMenuFeature";
import { eventRenderer } from "./eventRenderer";
import { useViewPreset } from "./useViewPreset";
import { useCellMenuFeature } from "./useCellMenuFeature";
// import { useEventTooltipFeature } from "./useEventTooltipTemplate";
import { scheduleTooltipFeature } from "./scheduleTooltipFeature";
import { timeRangesFeature } from "./timeRangesFeature";
import { onEventContextMenu } from "./onEventContextMenu";
import { catchAllEvents } from "../useEvents";
import { useCompany } from "../../../../utils/hooks/company";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import { usePayCodesMap } from "../../../Settings/PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";
import { useRegularPayCode } from "../../../Settings/PayPolicies/CalculationGroup/Form/hooks/useRegularPayCode";
import { useEventResizeFeature } from "./useEventResizeFeature";
import { useScheduleMenuFeature } from "./useScheduleMenuFeature";
import { useEventDragCreateFeature } from "./useEventDragCreateFeature";
import { timeAxisHeaderMenuFeature } from "./timeAxisHeaderMenuFeature";

// Ensure that the Tooltip shows nested events them in temporal order

export const useConfig = ({
	mode,
	from,
	to,
	isTeamScheduler,
	openCopyModal,
	openProfile,
	saveEventOnDb,
	openDeleteModal,
	openTransferModal,
	setSelectedEvents,
	openActionModal,
	openEditDrawer
}) => {
	const company = useCompany();
	const { allocatedGroups } = useGroups();
	const payCodesMap = usePayCodesMap();
	const regularPayCode = useRegularPayCode(payCodesMap);
	const viewPreset = useViewPreset({ mode });
	const eventResizeFeature = useEventResizeFeature({ mode });
	const eventDragCreateFeature = useEventDragCreateFeature({ isTeamScheduler });
	const scheduleMenuFeature = useScheduleMenuFeature({ isTeamScheduler });
	const cellMenuFeature = useCellMenuFeature({ 
		openProfile, 
		copy: openCopyModal, 
		transfer: openTransferModal,
		openActionModal,
		openDeleteModal,
		isTeamScheduler
	});
	const eventMenuFeature = useEventMenuFeature({ 
		openCopyModal,
		openTransferModal,
		openActionModal,
		isTeamScheduler,
		openEditDrawer
	});
	
	const eventDragFeature = useEventDragFeature({ mode, isTeamScheduler });
	// const eventTooltipFeature = useEventTooltipFeature();

	// const { startDate, endDate } = useMemo(() => {
	// 	return {
	// 		startDate: moment.parseZone(from).startOf("day").toISOString(true),
	// 		endDate: moment.parseZone(to).endOf("day").toISOString(true),
	// 	};
	// }, [from, to]);

	const getDefaultPayCode = useCallback((user) => {
		const employeeTypeCode = user?.employeeType?.code;
		const employeeTypeAsDefault =
			user?.calculationGroup?.useEmployeeTypeAsDefaultPayCode;
		const regularDefaultPayCode = user?.calculationGroup?.regularDefaultPayCode;
		if (payCodesMap) {
			if (employeeTypeAsDefault && employeeTypeCode in payCodesMap) {
				return payCodesMap[employeeTypeCode];
			}
			if (
				regularDefaultPayCode &&
				regularDefaultPayCode.code in payCodesMap
			) {
				return payCodesMap[regularDefaultPayCode.code];
			}
		}
		return regularPayCode;
	}, [payCodesMap, regularPayCode]);

	const onCatchAllEvents = useCallback((e) => {
		return catchAllEvents(e, {
			saveEventOnDb,
			openDeleteModal,
			mode,
			company,
			allocatedGroups,
			setSelectedEvents,
			defaultPayCode: getDefaultPayCode(e.resourceRecord),
		})
	}, [
		saveEventOnDb, 
		openDeleteModal, 
		mode, 
		company, 
		allocatedGroups, 
		setSelectedEvents, 
		getDefaultPayCode,
	]);

	return {
		weekStartDay: moment.parseZone(from).day(),
		stripeFeature: true,
		dependenciesFeature: false,

		// viewPreset,

		// startDate,
		// endDate,

		eventDragFeature,
		eventMenuFeature,
		eventTooltipFeature: false,
		scheduleTooltipFeature,
		scheduleMenuFeature,
		onEventContextMenu,
		timeRangesFeature,
		eventDragCreateFeature,
		timeAxisHeaderMenuFeature,

		eventCopyPasteFeature: { useNativeClipboard: true },
		allowOverlap: true,

		/* enabled by default features */
		eventFilterFeature: false,
		groupFeature: false,
		groupSummaryFeature: false,
		sortFeature: false,
		nestedEventsFeature: {
			eventLayout: 'stack',
			eventHeight: 35,
			headerHeight: 25,
			barMargin: 5,
			constrainDragToParent: true
		},
		cellMenuFeature,
		// eventResizeFeature,
		// ...useEvents,
		listeners: {
			catchAll: onCatchAllEvents
		}
	}
};
