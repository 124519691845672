import { useCallback, useState } from "react";
import { noop } from "lodash";
import { useTranslation } from "react-i18next";
import useApi from "..";
import { getErrorMessage } from "../../helpers/errors";

export const useNotes = () => {
	const { t } = useTranslation();
	const [myNote, setMyNote] = useState();
	const [allNotes, setAllNotes] = useState([]);
	const [loadingMyNote, setLoadingMyNote] = useState(false);
	const [loadingAllNotes, setLoadingAllNotes] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [totalItems, setTotalItems] = useState(0);
	const [error, setError] = useState(null);

	const { authGet, authPost, authPut } = useApi();

	const fetchMyNote = useCallback(
		async (id) => {
			setLoadingMyNote(true);

			try {
				const response = await authGet(`/note/user/${id}`);

				if (response) {
					setMyNote(response);
				}
			} catch (error) {
				getErrorMessage(error, t);
			} finally {
				setLoadingMyNote(false);
			}
		},
		[setLoadingMyNote, setMyNote, authGet, t],
	);

	const fetchAllNotes = useCallback(
		async (id, page, perPage) => {
			setLoadingAllNotes(true);

			try {
				const response = await authGet(`/note/${id}`, {
					params: {
						page,
						perPage,
					},
				});

				if (response) {
					setAllNotes(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoadingAllNotes(false);
			}
		},
		[setLoadingAllNotes, setAllNotes, setTotalItems, authGet, t],
	);

	const addNote = useCallback(
		async (data, onSuccess = noop) => {
			setSubmitting(true);
			try {
				const response = await authPost("/note", {
					data,
				});

				if (response) {
					onSuccess();
				}
			} catch (err) {
				setError(err);
			} finally {
				setSubmitting(false);
			}
		},
		[authPost, setError, setSubmitting],
	);

	const updateNote = useCallback(
		async (id, note, onSuccess = noop) => {
			setSubmitting(true);
			try {
				const response = await authPut(`/note/${id}`, {
					data: {
						value: note,
					},
				});

				if (response) {
					onSuccess();
				}
			} catch (err) {
				setError(err);
			} finally {
				setSubmitting(false);
			}
		},
		[authPut, setError, setSubmitting],
	);

	return {
		fetchAllNotes,
		fetchMyNote,
		addNote,
		updateNote,
		loadingMyNote,
		myNote,
		allNotes,
		loadingAllNotes,
		totalItems,
		error,
		submitting,
	};
};
