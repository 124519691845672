import { useCallback } from "react";
import Select from "../../../../../../components/Inputs/Select";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";

function ObjectSelect({
    value,
    onChange,
    options,
    propValue,
    ...props
}) {
    const componentValue = value?.code;
    const componentOnChange = useCallback(
        (_, option) => {
            onChange(option?.option);
        },
        [onChange],
    );

    return (
        <Select
            {...props}
            showSearch
            optionFilterProp="children"
            value={componentValue}
            onChange={componentOnChange}
            dropdownMatchSelectWidth={false}
        >
            {value && (
                <Select.Option value={value[propValue]} option={value}>
                    {renderSettingsResourceLabel(value)}
                </Select.Option>
            )}

            {options.filter(o => !value || o[propValue] !== value[propValue]).map((option) => (
                <Select.Option
                    key={option[propValue]}
                    value={option[propValue]}
                    option={option}
                >
                    {renderSettingsResourceLabel(option)}
                </Select.Option>
            ))}
        </Select>
    );
}

export default ObjectSelect;
