import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "reactstrap";

function TopbarAction({ id, children, loading, tooltip, color = "primary", ...props }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const colorStyles = {
        red: {
            backgroundColor: "#ffebee",
            color: "#d32f2f",
        },
        green: {
            backgroundColor: "#e8f5e9",
            color: "#388e3c",
        },
        blue: {
            backgroundColor: "#e3f2fd",
            color: "#1e88e5",
        },
        purple: {
            backgroundColor: "#f3e5f5",
            color: "#8e24aa",
        },
        orange: {
            backgroundColor: "#fff3e0",
            color: "#fb8c00",
        },
    };

    const buttonStyle = {
        backgroundColor: colorStyles[color]?.backgroundColor || "#e3f2fd",
        color: colorStyles[color]?.color || "#1e88e5",
        borderRadius: "0.25rem",
        border: "none",
        boxShadow: "none",
    };

    const iconStyle = {
        color: colorStyles[color]?.color || "#1e88e5"
    };

    return (
        <>
            <Button
                id={id}
                className="btn-icon"
                style={buttonStyle}
                size="sm"
                {...props}
            >
                {loading ? (
                    <div className="spinner-border spinner-border-sm text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    <span className="btn-inner--text" style={iconStyle}>{children}</span>
                )}
            </Button>
            {tooltip && (
                <Tooltip
                    isOpen={tooltipOpen}
                    target={id}
                    placement="top"
                    toggle={toggle}
                >
                    {tooltip}
                </Tooltip>
            )}
        </>
    );
}

export default TopbarAction;
