import { useMemo } from "react";
import { Tooltip } from "reactstrap";
import { useVisible } from "../../../utils/hooks/useVisible";

export const useHolidayTooltip = () => {
    const { visible, selected, open } = useVisible();

    const tooltip = useMemo (() => selected?.id && (
        <Tooltip
            isOpen={visible}
            target={selected.id}
            placement="right"
            className="holiday-tooltip"
        > 
            <ul className="content">
                {selected.dates.map((date) => (
                    <li>{date.name}</li>
                ))}
            </ul>
        </Tooltip>
    ), [visible, selected]);

    return { openTooltip: open, tooltip }
}