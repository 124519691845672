import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";
import Form from "./Form";
import SimpleTable from "../../../../../components/SimpleTable";
import { useRateConfiguration, useRoles } from "./api";
import { useColumns } from "./columns";
import { useActionsHook } from "./hooks";
import { useConfigurationModule } from "../../hooks/useConfigurationModule";
import { useDeleteModal } from "../../../helpers/useDeleteModal";

function Rate() {
	const { t } = useTranslation();
	const config = useConfigurationModule("rate");
	const { 
		mode, 
		selected, 
		goToCreate, 
		goToEdit 
	} = useActionsHook();

	const { 
		error, 
		loading, 
		submit,
	} = useRateConfiguration();

	const [data, setData] = useState([]);

	const { 
		data: roles, 
		fetch, 
	} = useRoles();

	const remove = useCallback((selected) => {
		if (data) {
			const values = data
					?.filter(({ role }) => role !== selected?.role);
			submit({
				config: values,
				entity: "company"
			}, 
			(response) => {
				setData(response);
			});
		}
	}, [data, submit, setData]);

	const { modal: deleteModal, open: openDeleteModal} = useDeleteModal({
		message: t("do-you-want-to-delete-rate-config"),
		onConfirm: remove,
		permission: "settings.configuration.canCreate"
	})

	const columns = useColumns({
		edit: goToEdit, 
		remove: openDeleteModal,
		roles
	});

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	useEffect(() => {
		if(config) {
			setData(config);
		}
	}, [setData, config]);

	return (
		<div className="d-flex h-100 configuration">
			<Card className="flex-fill mb-0 h-100">
				<CardHeader>
					<h3 className="mb-0 mr-5">{t("rate")}</h3>
				</CardHeader>

				<div
					className="p-4"
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						gridGap: "25px"
					}}
				>
					<Form
					    values={selected}
						mode={mode}
						error={error}
						submit={submit}
						close={goToCreate}
						roles={roles}
						config={data || []}
						setConfig={setData}
					/>

					<SimpleTable 
						data={data || []} 
						loading={loading}
						columns={columns}
						footer={false}
					/>
				</div>
			</Card>

			{deleteModal}
		</div>
	)
}

export default Rate;
