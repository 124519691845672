import { useCallback, useState } from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import classNames from "classnames";
import NumberInput from "../NumberInput";
import "./style.scss";

function InputNumberWithSuffix({
	onKeyPress,
	suffix,
	className,
	inputGroupClassName,
	hasError,
	...props
}) {
	const [isActive, setIsActive] = useState(false);

	const componentOnKeyPress = useCallback(
		(e) => {
			if (e.key !== "." && isNaN(Number(e.key))) {
				e.preventDefault();
				return;
			}
			if (onKeyPress) {
				onKeyPress(e);
			}
		},
		[onKeyPress],
	);

	const onFocus = useCallback(() => setIsActive(true), [setIsActive]);

	const onBlur = useCallback(() => setIsActive(false), [setIsActive]);

	return (
		<InputGroup className={classNames(inputGroupClassName, "number-input")}>
			<NumberInput
				onKeyPress={componentOnKeyPress}
				{...props}
				className={classNames(className, hasError && "is-invalid")}
				onBlur={onBlur}
				onFocus={onFocus}
			/>

			<InputGroupText
				className={classNames(
					hasError && "border-danger",
					"border-left-0",
					isActive && "border-primary",
				)}
			>
				{suffix}
			</InputGroupText>
		</InputGroup>
	);
}

export default InputNumberWithSuffix;
