import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../../utils/api";
import { getErrorMessage } from "../../../../../../utils/helpers/errors";

export const useUpdateFlyer = () => {
    const { t } = useTranslation();
    const { authPost } = useApi();
    const [loading, setLoading] = useState(false);

    const updateFlyer = useCallback(async (id, onSuccess) => {
        setLoading(true);
        try {
            const response = await authPost(`/flyer/user/${id}`, {
                data: { status: "read" }
            });
            if (response) {
                onSuccess?.(response);
            }
        } catch (err) {
            getErrorMessage(err, t)
        } finally {
            setLoading(false)
        }
    }, [authPost, setLoading, t]);

    return { updateFlyer, loading }
}