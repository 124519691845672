import WeeklyOvertime from "./WeeklyOvertime";
import DailyOvertime from "./DailyOvertime";
import DailyDoubleTime from "./DailyDoubleTime";
import DailyDoubleTimeHalf from "./DailyDoubleTimeHalf";
import ConsecutiveDay from "./ConsecutiveDay";
import AllHoursWorkedOn from "./AllHoursWorkedOn";
import SixConsecutiveDay from "./SixConsecutiveDay";
import "./style.scss";

function Rule({ type, disabled }) {
	switch (type) {
		case "weekly-overtime":
			return <WeeklyOvertime disabled={disabled} />;
		case "daily-overtime":
			return <DailyOvertime disabled={disabled} />;
		case "daily-double-time":
			return <DailyDoubleTime disabled={disabled} />;
		case "daily-double-time-and-half":
			return <DailyDoubleTimeHalf disabled={disabled} />;
		case "consecutive-day":
			return <ConsecutiveDay disabled={disabled} />;
		case "all-hours-worked-on":
			return <AllHoursWorkedOn disabled={disabled} />;
		case "six-consecutive-day":
			return <SixConsecutiveDay disabled={disabled} />;
		default:
			return <div>Rule {type} is not implemented yet</div>;
	}
}

export default Rule;
