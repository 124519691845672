import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import { useQrCodeClockIn } from "./context";
import { useNavigate, useParams } from "react-router-dom";
import { useIdleTimeout } from "./LogicProvider/useIdleTimeout";

function Success() {
	const { t } = useTranslation();
	const { company, dispatch } = useQrCodeClockIn();
	const { action } = useParams();

	const dateFormat = useMemo(() => {
		if (company?.settings?.dateFormat) {
			return company?.settings?.dateFormat;
		} else {
			return "MM/DD/YYYY";
		}
	}, [company?.settings?.dateFormat]);

	const timeFormat = useMemo(() => {
		if (company?.settings?.timeFormat) {
			return company?.settings?.timeFormat;
		} else {
			return "HH:mm:ss A";
		}
	}, [company?.settings?.timeFormat]);

	const text = useMemo(() => {
		switch (action) {
			case "start-break":
				return t("have-started-break");
			case "end-break":
				return t("have-ended-break");
			case "clock-out":
				return t("have-clocked-out");
			case "clock-in":
			default:
				return t("have-clocked-in");
		}
	}, [action, t]);

	const timeColor = useMemo(() => {
		switch (action) {
			case "start-break":
			case "end-break":
				return "text-orange";
			case "clock-out":
				return "text-red";
			case "clock-in":
			default:
				return "text-info";
		}
	}, [action]);

	const navigate = useNavigate();
	const { id } = useParams();
	useIdleTimeout({
		timeout: 2,
		onTimeout: useCallback(() => {
			dispatch({
				type: "set-clock-mode",
				payload: { clockMode: null },
			});
			navigate(`/qr-code-clock-in/${id}/clocks`);
		}, [dispatch, navigate, id]),
	});

	return (
		<div className="bg-transparent position-relative h-100 align-items-center d-flex flex-column justify-content-center">
			<h1 className="text-green font-weight-bolder">
				<i
					className="fa-regular fa-circle-check"
					style={{ fontSize: "70px" }}
				/>
			</h1>

			<h1 className="text-green display-1 font-weight-bolder">
				{t("success")} !
			</h1>

			<span className="text-muted font-weight-300 text-lg mb-4">
				{text}.
			</span>

			<span className="font-weight-500" style={{ fontSize: "22px" }}>
				{moment().format(dateFormat)}
			</span>

			<span
				className={classNames(timeColor, "font-weight-bolder")}
				style={{ fontSize: "27px" }}
			>
				{moment().format(timeFormat)}
			</span>
		</div>
	);
}

export default Success;
