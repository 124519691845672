import { useMemo } from "react";

export const RULE_TYPE_PAY_CODE_MAP = {
	"weekly-overtime": "OT",
	"daily-overtime": "OT",
	"daily-double-time": "DT",
	"daily-double-time-and-half": "DTH",
	"consecutive-day": "OT",
	"six-consecutive-day": "OT",
	"all-hours-worked-on": "OT",
};

export const useOvertimePayCodes = (map) => {
	return useMemo(() => {
		if (!map) {
			return;
		}

		return Object.entries(RULE_TYPE_PAY_CODE_MAP).reduce(
			(total, [rule, code]) => ({
				...total,
				[rule]: map[code],
			}),
			{},
		);
	}, [map]);
};
