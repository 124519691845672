import { Card, CardHeader, CardBody } from "reactstrap";
import Content from "./Content";
import Topbar from "../components/Topbar";
import { DateFiltersProvider } from "../hooks/useDateFilters";
import { OverviewProvider } from "../components/Topbar/context";
import { FiltersProvider } from "../filtersContext";
import { PayCodesProvider } from "../reusedResourcesContext";
import { useConfigInitialFilters } from "../../Settings/Configuration/hooks/useConfigInitialFilters";
import ConfigurationLoader from "../configurationLoader";

const Team = () => {
    const initialFilters = useConfigInitialFilters("timeSheetTeam", "employeeNumber");

    return (
        <PayCodesProvider>
            <ConfigurationLoader>
                <FiltersProvider
                    sessionKey="TEAM_IMESHEET_FILTERS"
                    initialValue={initialFilters}
                >
                    <DateFiltersProvider>
                        <OverviewProvider>
                            <Card className="time-sheet px-0 m-0 h-100 position-static">
                                <CardHeader>
                                    <Topbar page="team" />
                                </CardHeader>

                                <CardBody className="p-0 d-flex flex-column">
                                    <Content />
                                </CardBody>
                            </Card>
                        </OverviewProvider>
                    </DateFiltersProvider>
                </FiltersProvider>
            </ConfigurationLoader>
        </PayCodesProvider>
    );
};

export default Team;
