import { useCallback } from "react";
import { formatParams } from "../../../utils/api/format";

export const useOnExport = ({
    url,
    exportCall,
    queryFilters,
    visibleColumnKeys,
}) => {
    return useCallback(() => {
        const params = {
            columns: visibleColumnKeys,
            ...formatParams(queryFilters),
        };
        exportCall(url, params);
    }, [exportCall, queryFilters, visibleColumnKeys]);
}
