import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

const formatDateRange = (from, to) => ({
	startDate: from ? moment.parseZone(from).format("YYYY-MM-DD") : undefined,
	endDate: to ? moment.parseZone(to).format("YYYY-MM-DD") : undefined,
});

export const useStatistic = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	const { authGet } = useApi();

	const fetch = useCallback(
		async ({ from, to, ...filters }, controller) => {
			setLoading(true);

			try {
				const response = await authGet("/reports/dashboard", {
					params: {
						...filters,
						...formatDateRange(from, to),
					},
					signal: controller?.signal
				});

				if (response) {
					setData(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[t, setData, authGet, setLoading, getErrorMessage],
	);

	return { fetch, data, loading };
};
