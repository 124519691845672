import { useEffect, useCallback, useContext, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import {
	CardHeader,
	CardTitle,
	CardText,
	CardBody,
	Row,
	Col,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	ListGroupItemText,
} from "reactstrap";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import Button from "../../../components/Button";
import Pagination from "../../../components/Table/Pagination";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { Access, useAccess } from "../../../utils/hooks/access";
import { renderUserName } from "../../../utils/helpers/user";
import { useNotes } from "../../../utils/api/notes";
import { ProfileContext } from "../context";
import { getUploadUrl } from "../../../utils/helpers/upload";
import { initials } from "../../../utils/helpers/string";

const perPage = 10;

function Notes({ isOpenFromTimesheet = false }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const [page, setPage] = useState(0);

	const { isSelf, user } = useContext(ProfileContext);

	const {
		fetchAllNotes,
		fetchMyNote,
		addNote,
		updateNote,
		allNotes,
		myNote,
		loadingAllNotes,
		loadingMyNote,
		submitting,
		totalItems,
		error,
	} = useNotes();

	const { hasAccess: canManageAllNotes } = useAccess("people.manageAllNotes");
	const { hasAccess: canManageNote } = useAccess("people.manageNote");

	const onFinish = useCallback(() => {
		const values = form.getFieldsValue();

		if (user?.id) {
			if (!myNote) {
				const data = {
					value: values?.note,
					entityId: user?.id,
					entity: "user",
				};

				addNote(data, () => toast.success(t("note-is-saved")));
			} else {
				updateNote(myNote.id, values.note, () =>
					toast.success("note-is-updated"),
				);
			}
		}
	}, [t, user?.id, myNote, form, addNote, updateNote]);

	useEffect(() => {
		if (canManageAllNotes && user?.id) {
			fetchAllNotes(user.id, page, perPage);
		}
	}, [canManageAllNotes, fetchAllNotes, user?.id, page]);

	useEffect(() => {
		if (canManageNote) {
			if (user?.id) {
				fetchMyNote(user.id);
			}
		}
	}, [fetchMyNote, user?.id, canManageNote]);

	useEffect(() => {
		form.setFieldsValue({
			note: myNote?.value,
		});
	}, [form, myNote]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(["note"], error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<>
			{!isOpenFromTimesheet && (
				<CardHeader>
					<Row>
						<Col sm={12} md={6}>
							<CardTitle className="h3 mb-1">
								{t("notes")}
							</CardTitle>

							<CardText className="text-sm font-weight-bold text-muted">
								{t("notes-description")}
							</CardText>
						</Col>

						<Col sm={12} md={6} className="text-md-right">
							<Button
								type="button"
								disabled={loadingMyNote}
								loading={submitting}
								onClick={onFinish}
								form="note-form"
								className="border-dark px-3 btn-round btn-icon shadow-none btn btn-secondary btn-sm"
							>
								{t("save")}
							</Button>
						</Col>
					</Row>
				</CardHeader>
			)}

			<CardBody
				style={{ height: "calc(100% - 20px)", overflowY: "auto" }}
				className="profile-scroll"
			>
				{!isSelf && (
					<>
						<ListGroup className="mb-2">
							{loadingAllNotes ? (
								<div className="d-flex align-items-center justify-content-center">
									<div
										className="spinner spinner-border spinner-border-sm mr-2"
										role="status"
									/>
								</div>
							) : (
								allNotes &&
								allNotes.map((note) => (
									<ListGroupItem className="d-flex align-items-center m-0 px-5 py-1">
										{note?.user?.image ? (
											<img
												src={getUploadUrl(
													note?.user?.image,
												)}
												className="avatar rounded-circle mr-2"
												width={37}
												height={37}
											/>
										) : (
											<div
												className="avatar rounded-circle d-flex align-items-center mr-3 bg-primary"
												style={{ cursor: "auto" }}
											>
												<h3 className="text-white mb-0">
													{initials(note?.user)}
												</h3>
											</div>
										)}

										<div className="d-flex flex-column">
											<ListGroupItemHeading className="text-sm mb-1">
												{renderUserName(note?.user)}
											</ListGroupItemHeading>

											<ListGroupItemText className="mb-0">
												{note?.value}
											</ListGroupItemText>
										</div>
									</ListGroupItem>
								))
							)}
						</ListGroup>

						<Pagination
							page={page}
							perPage={10}
							pageCount={Math.ceil(totalItems / 10)}
							totalItems={totalItems}
							gotoPage={setPage}
							showOnSizeChange={false}
						/>
					</>
				)}

				<Access accessPath="people.manageNote">
					<Form form={form} layout="vertical" name="note-form">
						<Field name="note" label={t("note")}>
							<Text
								type="textarea"
								disabled={loadingMyNote}
								rows={5}
							/>
						</Field>
						{isOpenFromTimesheet && (
							<div className="w-100 text-right">
								<Button
									type="button"
									disabled={loadingMyNote}
									loading={submitting}
									onClick={onFinish}
									form="note-form"
									className="border-dark px-3 btn-round btn-icon shadow-none btn btn-secondary btn-sm"
								>
									{t("save")}
								</Button>
							</div>
						)}
					</Form>
				</Access>
			</CardBody>
		</>
	);
}

export default Notes;
