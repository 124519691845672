import { useMemo } from "react";
import { useAuthentication } from "../../features/Authentication/context";
import { getCommonInUsers } from "../helpers/user";

export const useUser = () => {
    const { user } = useAuthentication();
    return user;
};

export const useUserLocations = (allowedStatuses = ["active"]) => {
    const user = useUser();
    return useMemo(() => {
        return (user?.locations || []).filter(
            ({ status }) => allowedStatuses.includes(status)
        );
    }, [user?.locations, allowedStatuses])
}

export const useUserJobs = (allowedStatuses = ["active"]) => {
    const user = useUser();
    return useMemo(() => {
        return (user?.jobs || []).filter(
            ({ status }) => allowedStatuses.includes(status)
        );
    }, [user?.jobs, allowedStatuses])
}

export const useIsMasterCompany = () => {
    const { company } = useAuthentication();
    return company?.isMasterCompany || false;
};

export const useAccessMap = () => {
    const { accessMap } = useAuthentication();
    return accessMap;
};

export const useCommonInUsers = ({ users, property, identifier, formatter }) => {
    return useMemo(() => {
        return getCommonInUsers({ users, property, identifier, formatter });
    }, [users]);
}
