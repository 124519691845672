import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Row,
	Button as BaseButton,
	CardFooter,
} from "reactstrap";
import Form from "rc-field-form";
import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Inputs/Checkbox";
import Layout from "../Layout";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";

const options = [
	{
		image: "/assets/images/theme/people_employee.svg",
		type: "employee",
		description: "add-employee-description",
	},
	{
		image: "/assets/images/theme/people_contractor.svg",
		type: "contractor",
		description: "add-contractor-description"
	},
];

function Step1({ data, setStep, setData, error, step, close }) {
	const { t } = useTranslation();

	const [form] = Form.useForm();
	const [selectedType, setSelectedType] = useState();
	const [isInvited, setIsInvited] = useState(false);
	const [err, setError] = useState(null);

	const onFinish = useCallback(() => {
		if (selectedType) {
			setError(null);
			setData((prev) => ({ ...prev, type: selectedType, isInvited }));
			setStep(1);
		}
		setError(t("required-employee-type"));
	}, [setStep, setData, selectedType, isInvited, setError]);

	const onTypeChange = useCallback(
		(type) => {
			setSelectedType(type);
			setError(null);
		},
		[setSelectedType, setError],
	);

	const onCheckboxChange = useCallback(
		(e) => setIsInvited(e.target.checked),
		[setIsInvited],
	);

	useEffect(() => {
		form.setFieldsValue({
			isInvited: data.isInvited || false,
			type: data.type,
		});
	}, [form, data]);

	useEffect(() => {
		const fields = ["type", "isInvited"];
		const fieldErrors = generateErrorsConfigForForm(fields, error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Layout step={step}>
				<Card className="add-employee-step1 shadow-lg my-5">
					<CardHeader>
						<Row>
							<Col xs="8">
								<h3>{t("add-team-member")}</h3>
							</Col>

							<Col className="text-right" xs="4">
								<i
									className="fa-solid fa-xmark cursor-pointer"
									onClick={close}
								/>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="my-3">
						<Row>
							{options.map(({ type, image, description }) => (
								<Col
									sm={12}
									md={6}
									className="d-flex flex-column align-items-center"
								>
									<BaseButton
										active={selectedType === type}
										className="button mb-3 mx-0"
										onClick={() => onTypeChange(type)}
									>
										<img src={image} />
									</BaseButton>

									<div className="description">
										<h3 className="">
											{t(type)}
										</h3>
										<p className="text-muted text-sm mb-0 pb-0">
											{t(description)}
										</p>
									</div>
								</Col>
							))}
						</Row>

						<p className="text-danger text-sm">{err}</p>

						<div className="mx-3 text">
							<Checkbox id="invite" onChange={onCheckboxChange}>
								<span className="text-muted text-sm mb-2">
									{t("invite-to-enter-details")}
								</span>
							</Checkbox>
						</div>
					</CardBody>

					<CardFooter className="border-0 d-flex justify-content-between mx-3 mb-2">
						<Button className="mt-auto" color="default" type="submit">
							{t("save-and-continue")}
						</Button>

						<Button className="mt-auto" color="none" onClick={close}>
							{t("cancel")}
						</Button>
					</CardFooter>
				</Card>
			</Layout>
		</Form>
	);
}

export default Step1;
