import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import DateInput from "../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import { ProfileContext } from "../../../context";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import { useCompany } from "../../../../../utils/hooks/company";
import {
	applyTimezone,
	dateTimeParseZone,
} from "../../../../../utils/helpers/date";

function ChangeHiringInformation({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();

	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);

	const { user, submit, submitting, submittingError } =
		useContext(ProfileContext);
	const { changeHiringInformation: profileSubmit } = useProfileApi();
	const { changeHiringInformation: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(values) => {
			const data = {
				jobGroup: values?.jobGroup?.id,
				payGroup: values?.payGroup?.id,
				employeeType: values?.employeeType?.id,
				userGroups: values?.userGroups?.map(({ id }) => id) || [],
				union: values?.union?.id,
				project: values?.project?.id,
				hiringDate: applyTimezone(values?.hiringDate, timezone, true),
			};
			submit(profileSubmit, peopleSubmit, data, close);
		},
		[timezone, submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			jobGroup: user?.jobGroup,
			hiringDate:
				user?.hiringDate && dateTimeParseZone(user?.hiringDate, "date"),
			payGroup: user?.payGroup,
			employeeType: user?.employeeType,
			userGroups: user?.userGroups,
			union: user?.union,
			project: user?.project,
		});
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"startDate",
				"jobGroup",
				"hiringDate",
				"employeeType",
				"union",
				"payGroup",
				"project",
			],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
			className="d-flex flex-column justify-between"
		>
			<div>
				<Field
					name="date"
					label={t("effective-date")}
					rules={[
						{
							required: true,
							message: t("required-effective-date"),
						},
					]}
					className="d-flex flex-column"
				>
					<DateInput placeholderText={t("effective-date")} />
				</Field>

				<Field name="jobGroup" label={t("job-group")}>
					<ResourceSelect
						placeholder={t("job-group")}
						labelPropName="description"
						resourcePath="/job-groups"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						allowClear
					/>
				</Field>

				<Field
					name="hiringDate"
					label={t("hiring-date")}
					rules={[
						{
							required: true,
							message: t("required-hiring-date"),
						},
					]}
					className="d-flex flex-column"
				>
					<DateInput placeholderText={t("hiring-date")} />
				</Field>

				<Field
					name="employeeType"
					label={t("employee-type")}
					rules={[
						{
							required: true,
							message: t("required-employee-type"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/employee-type"
						placeholder={t("employee-type")}
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>

				<Field
					name="userGroups"
					label={t("user-groups")}
				>
					<ResourceSelect
						mode="multiple"
						resourcePath="/user-group"
						placeholder={t("user-groups")}
						hasSearch
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>

				<Field
					name="union"
					label={t("union")}
					// rules={[
					// 	{
					// 		required: true,
					// 		message: t("required-union"),
					// 	},
					// ]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/union"
						placeholder={t("union")}
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>

				<Field
					name="project"
					label={t("project")}
					// rules={[
					// 	{
					// 		required: true,
					// 		message: t("required-project"),
					// 	},
					// ]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/project"
						placeholder={t("project")}
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>

				<Field
					name="payGroup"
					label={t("pay-group")}
					rules={[
						{
							required: true,
							message: t("required-pay-group"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/pay-group"
						placeholder={t("pay-group")}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
					color="primary"
					type="submit"
					loading={submitting}
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeHiringInformation;
