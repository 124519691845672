import {useActionWithRefetch} from "../../hooks/useAction";

import {useApproveTimeSheet} from "../../api/useApproveTimeSheet";
import {useLockTimeSheet} from "../../api/useLockTimeSheet";

export const useDateActions = (refetch) => {
    const { approveMany, unapproveMany } = useApproveTimeSheet();
    const { lockMany, unlockMany } = useLockTimeSheet();

    const approve = useActionWithRefetch(approveMany, {
        permission: "timeSheet.canApproveMyTimesheet",
        onSuccess: refetch,
    });
    const unapprove = useActionWithRefetch(unapproveMany, {
        permission: "timeSheet.canApproveMyTimesheet",
        onSuccess: refetch,
    });
    const lock = useActionWithRefetch(lockMany, {
        permission: "timeSheet.canLockMyTimesheet",
        onSuccess: refetch,
    });
    const unlock = useActionWithRefetch(unlockMany, {
        permission: "timeSheet.canLockMyTimesheet",
        onSuccess: refetch,
    });

    return { approve, unapprove, lock, unlock };
}
