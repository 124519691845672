import moment from "moment-timezone";
import { Option } from "rc-select";
import Select from "../Select";

function TimeFormatSelect({ ...props }) {
	return (
		<Select {...props}>
			<Option value="HH:mm" key="HH:mm">
				{moment().format("HH:mm")}
			</Option>

			<Option value="HH:mm:ss" key="HH:mm:ss">
				{moment().format("HH:mm:ss")}
			</Option>

			<Option value="hh:mm A" key="hh:mm A">
				{moment().format("hh:mm A")}
			</Option>

			<Option value="hh:mm:ss A" key="hh:mm:ss A">
				{moment().format("hh:mm:ss A")}
			</Option>
		</Select>
	);
}

export default TimeFormatSelect;
