import { faCalendar, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Collapse, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import Button from "../../../../components/Button/index.js";
import DateInput from "../../../../components/Inputs/DateInput/index.js";
import { normalize } from "../../../../utils/helpers/normalize/index.js";
import AnalyticsTable from "../../components/Topbar/AnalyticsTable/index.jsx";
import { getEndDateFromStart, getStartDate } from "../../components/Topbar/Filters/helpers.js";
import { useOverview } from "../../components/Topbar/context.js";
import { useDateFilters } from "../../hooks/useDateFilters.js";

const hourTypes = [
    "regular",
    "overtime",
    "double-time",
    "pto",
    "amount",
    "unit",
    "holiday",
];

const Topbar = () => {
    const { t } = useTranslation();
    const { data: apiData, visible, toggle } = useOverview();

    const { mode, from, to, setFilters } = useDateFilters();
    const onChange = useCallback(([value]) => {
        setFilters({
            from: getStartDate(moment(value), mode),
            to: getEndDateFromStart(value, mode),
        });
    }, [setFilters]);

    const { data } = useMemo(() => {
        if (Array.isArray(apiData)) {
            return {
                data: apiData,
                budget: { hours: 0, amount: 0 },
            };
        }
        return { data: apiData.data, budget: apiData.budget };
    }, [apiData]);

    const config = useMemo(() => {
        return normalize(data || [], "hourType");
    }, [data]);

    return (
        <div className="timesheet-analytics">
            <Row>
                <Col xs="12" md="auto">
                    <Button
                        color="primary"
                        icon={<FontAwesomeIcon icon={visible ? faChevronUp : faChevronDown} />}
                        onClick={toggle}
                    >
                        {t("analytics")}
                    </Button>
                </Col>

                <Col xs='12' md='auto'>
                    <InputGroup className='input-group-alternative border shadow-none flex-nowrap'>
                        <DateInput
                            startDate={moment(from).toDate()}
                            endDate={moment(to).toDate()}
                            onChange={onChange}
                            selectsRange
                        />
                        <InputGroupAddon addonType='append'>
                            <InputGroupText>
                                <FontAwesomeIcon icon={faCalendar} />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>

            <Collapse isOpen={visible}>
                <div className="d-flex justify-content-start mt-3">
                    <AnalyticsTable
                        hourTypes={hourTypes}
                        config={config}
                        data={data}
                    />
                </div>
            </Collapse>
        </div>
    );
}

export default Topbar;
