import { useCallback, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NavLink } from "reactstrap";
import Button from "../../../components/Button";
import Field from "../../../components/Field";
import Password from "../../../components/Inputs/Password";
import { useAuthentication } from "../context";
import { useChangePassword } from "./api";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { useLogout } from "../api";
import { useImpersonate } from "../../People/api";
import { useUser } from "../../../utils/hooks/user";

function RequiredSetPassword() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const user = useUser();
	const { dispatch } = useAuthentication();
	const changePassword = useChangePassword();
	const { logout: impersonateLogout } = useImpersonate();

	const { logout } = useLogout();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const onFinish = useCallback(
		async (values) => {
			if (!user) {
				return;
			}
			setLoading(true);
			setError(null);
			try {
				await changePassword(
					{
						oldPassword: values.oldPassword,
						plainPassword: values.newPassword,
					},
					user,
				);

				dispatch({
					type: "set-user",
					payload: {
						user: { ...user, requirePasswordChange: false },
					},
				});
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[user, dispatch, changePassword, setLoading, setError],
	);

	const logOut = useCallback(
		(e) => {
			e.preventDefault();
			const impersonate = localStorage.getItem("IMPERSONATE");
			if (impersonate) {
				impersonateLogout();
			} else {
				logout();
			}
		},
		[impersonateLogout, logout],
	);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["oldPassword", "newPassword", "confrimNewPassword"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	if (user && user.requirePasswordChange === false) {
		return <Navigate to="/" />;
	}

	return (
		<Form autocomplete="off" form={form} onFinish={onFinish}>
			<Field
				name="oldPassword"
				rules={[
					{
						required: true,
						message: t("required-old-password"),
					},
				]}
			>
				<Password placeholder={t("old-password")} />
			</Field>

			<Field
				name="newPassword"
				rules={[
					{
						required: true,
						message: t("required-new-password"),
					},
				]}
			>
				<Password placeholder={t("new-password")} />
			</Field>

			<Field
				name="confirmNewPassword"
				rules={[
					{
						required: true,
						message: t("required-confirm-password"),
					},
				]}
			>
				<Password placeholder={t("confirm-password")} />
			</Field>

			<div className="d-flex justify-content-between text-sm mb-5">
				<div />

				<NavLink
					href="#"
					className="text-primary text-gradient font-weight-bold text-sm"
				>
					{t("trouble-while-signin")}
				</NavLink>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					color="default"
					disabled={loading}
					type="button"
					onClick={logOut}
				>
					{t("logout")}
				</Button>

				<Button color="primary" loading={loading} type="submit">
					{t("set-new-password")}
				</Button>
			</div>
		</Form>
	);
}

export default RequiredSetPassword;
