import { useMemo } from "react";

export const useFiltersDefaultValue = (filters) => {
    return useMemo(() => {
        return filters?.map((filter) => ({
            accessor: filter?.id,
            hide: false,
        }));
    }, [filters]);
}
