import { useState, useCallback } from "react";
import useApi from "../../../utils/api";

export const useMyTeam = () => {
	const { authGet } = useApi();

	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetch = useCallback(
		async (params, controller) => {
			setLoading(true);
			setError(null);

			try {
				const response = await authGet("/shift/my-team", {
					params,
					signal: controller?.signal,
				});
				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, setData, setTotalItems, authGet],
	);

	return { loading, error, data, fetch, totalItems, setData };
};
