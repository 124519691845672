import { useRef, useEffect } from "react";

export const useFocusOnElement = () => {
    const ref = useRef();

	useEffect(() => {
		if (ref.current) {
			return ref.current.focus();
		}
	}, [ref]);

    return ref;
}
