export const getSharedDateRowSpan = (dateIdMap, timeSheet) => {
	if (isAddedTimeSheet(timeSheet)) {
		return 1;
	}
	const date = timeSheet.date;

	if (!(date in dateIdMap)) {
		return 1;
	}

	const list = dateIdMap[date];
	const index = list.indexOf(timeSheet.id);
	if (index === 0) {
		return list.length;
	} else {
		return 0;
	}
};

export const isAddedTimeSheet = (timeSheet) => {
	return !!timeSheet?.isAdded;
};

export const getTimeSheetTimeZone = (timeSheet) => {
	if (isAddedTimeSheet(timeSheet)) {
		return timeSheet.location?.timezoneValue;
	}
	return timeSheet.location?.timezoneValue || timeSheet?.timezone;
};

export const isTimeInputDisabled = (timeSheet) => {
	return (
		timeSheet.payCode?.hourType === "amount" ||
		timeSheet.payCode?.hourType === "unit" ||
		(!isAddedTimeSheet(timeSheet) && timeSheet.locked)
	);
};

export const isInputDisabled = (timeSheet) => {
	return !isAddedTimeSheet(timeSheet) && timeSheet.locked;
};
