import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../components/Field";
import Text from "../../../../../../components/Inputs/Text";
import DateInput from "../../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import LevelSelect from "../../../../../TimeSheet/components/LevelSelect";
import Shifts from "./Shifts";
import ObjectSelect from "./ObjectSelect";
import TimeInputs from "./TimeInputs";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import {
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters
} from "../../../../../../utils/helpers/settings";
import { getCommonJobs, getCommonLocations, getHolidays } from "./helpers";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { getShifts } from ".";

function Content({ 
    form, 
    resource, 
    setIsAddedSegment, 
    setHasChanges, 
    eventRecord,
    defaultSegment,
    calculatedShifts,
}) {
    const { t } = useTranslation();
    const { allocatedGroups } = useGroups();
    const startDate = Form.useWatch("startDate", form);
	const endDate = Form.useWatch("endDate", form);
	const payCode = Form.useWatch("payCode", form);
	const eventType = Form.useWatch("eventType", form);

    const jobs = useMemo(() => {
		if (!resource) {
			return [];
		}
		return getCommonJobs([resource]);
	}, [resource]);

	const locations = useMemo(() => {
		if (!resource) {
			return [];
		}
		return getCommonLocations([resource]);
	}, [resource]);

	const holidays = useMemo(() => {
		if (!resource) {
			return []
		}
		return getHolidays(resource, startDate);
	}, [resource, startDate]);

    const onEarningGroupChange = useCallback((value) => {
		const isCreating = eventRecord?.isCreating;
		const duration = moment(endDate).diff(moment(startDate), "seconds");
		if (!value?.allowSegmentation) {
			const data = [{
				...defaultSegment,
				payCode,
				start: startDate,
				end: endDate,
				duration: renderDurationAsFormat(duration || 0, "HH:mm")
			}];
			form.setFieldValue("duration", data[0]?.duration);
			form.setFieldValue("shifts", getShifts(data, defaultSegment));
		} else {
			const data = isCreating ? [{
				...defaultSegment,
				start: startDate,
				end: endDate,
				duration: renderDurationAsFormat(duration || 0, "HH:mm"),
			}]: calculatedShifts;
			setIsAddedSegment(false);
			setHasChanges(true);
			form.setFieldValue("shifts", getShifts(data, defaultSegment));
		}
	}, [
		form, 
		startDate, 
		endDate, 
		defaultSegment, 
		payCode, 
		calculatedShifts, 
		eventRecord?.isCreating,
		setIsAddedSegment,
		setHasChanges
	]);

    return (
        <>
            <Field
				className="w-100 d-flex flex-column"
				name="date"
				label={t("shift-date")}
				rules={[
					{
						required: true,
						message: t("required-start-date"),
					},
				]}
			>
				<DateInput className="w-100" readOnly={true} autocomplete="off" />
			</Field>


			{holidays?.length > 0 && (
				<div 
					className="w-100 border border-warning p-3 rounded mb-4 text-warning"
					style={{
						color: "#fff5e6"
					}}
				>
					<FontAwesomeIcon icon={faWarning} className="mr-3" />

					{t("today-holiday", { 
						holiday: holidays.length === 1 
							? holidays[0].name
							: holidays.map((holiday) => holiday.name + ", ")
					})} 
				</div>
			)} 

			<Field
				name="resourceId"
				hidden={true}
			>
				<Text />
			</Field>

			{!eventType?.allowSegmentation && (
				<BaseField name="shifts" />
			)}

			<Field
				name="eventType"
				label={t("event-type")}
				rules={[
					{
						required: true,
						message: t("required-event-type")
					}
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/earning-group?forSchedule=true"
					mode="single"
					renderLabel={renderSettingsResourceLabel}
					getSearchFilters={getSettingsResourceSearchFilters}
					valuePropName="id"
					hasSearch
					onChange={onEarningGroupChange}
				/>
			</Field>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const eventType = getFieldValue("eventType");
					const filter = `filter[earningGroup][method]=exact
							&filter[earningGroup][value][]=${eventType?.id}
							&forSchedule=true`;
					return !eventType?.allowSegmentation && (
						<Field
							name="payCode"
							label={t("pay-code")}
							rules={[
								{
									required: true,
									message: t("required-pay-code")
								}
							]}
						>
							<ResourceSelect
								labelPropName="description"
								renderLabel={renderSettingsResourceLabel}
								valuePropName="code"
								placeholder={t("pay-code")}
								hasSearch
								getSearchFilters={getSettingsResourceSearchFilters}
								resourcePath={`/pay-code?${filter}`}
							/>
						</Field>
					)
				}}
			</BaseField>

			<TimeInputs
				form={form}
				defaultSegment={defaultSegment}
				setHasChanges={setHasChanges}
				automaticBreaks={resource?.calculationGroup?.breaks?.automatic?.status}
				setIsAddedSegment={setIsAddedSegment}
			/>

			<BaseField shouldUpdate>
				{({}, {}, { getFieldValue }) => {
					const eventType = getFieldValue("eventType");
					return eventType?.allowSegmentation ? (
						<Shifts 
							form={form}
							resource={resource}
							eventRecord={eventRecord}
							defaultSegment={defaultSegment}
							setHasChanges={setHasChanges}
							setIsAddedSegment={setIsAddedSegment}
						/>
					) : <>
							{locations.length > 0 && (
								<Field
									name="location"
									label={t("location")}
									rules={[
										{
											required: true,
											message: t("required-location"),
										},
									]}
								>
									<ObjectSelect propValue="id" options={locations} />
								</Field>
							)}
							{jobs.length > 0 && (
								<Field
									name="job"
									label={t("job")}
									rules={[
										{
											required: true,
											message: t("required-job"),
										},
									]}
								>
									<ObjectSelect propValue="id" options={jobs} />
								</Field>
							)}
							
							{allocatedGroups?.map((group) => (
								<BaseField shouldUpdate>
									{({ }, { }, { getFieldValue }) => {
										const location = getFieldValue("location");
										return (
											<Field
												key={group.id}
												name={group?.level?.replace("_", "")}
												label={renderOrgLevelResourceLabel(group)}
												rules={[
													{
														required: group?.isRequired,
														message: t("required-group", {
															group: renderOrgLevelResourceLabel(group)
														})
													}
												]}
											>
												<LevelSelect
													group={group.id}
													location={location}
													allowClear={!group.isRequired}
												/>
											</Field>
										);
									}}
								</BaseField>
							))}
						</>
				}}
			</BaseField>
        </>
    )
}

export default Content;
