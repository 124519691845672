import { useMemo } from "react";
import { useTable } from "react-table";
import { Table as BaseTable } from "reactstrap";
import moment from "moment-timezone";
import { useColumns } from "./useColumns";
import { useDateIdMap } from "../../../../components/TimeSheetTable/hooks/useDateIdMap";

export const enumerateDaysBetweenDates = (startDate, endDate) => {
	const now = startDate,
		dates = [];

	while (now.isSameOrBefore(endDate)) {
		dates.push(now.format("YYYY-MM-DD"));
		now.add(1, "days");
	}

	return dates;
};

function Table({ date, timesheet: baseTimeSheets, loading, response }) {
	const { from, to } = date;

	const days = useMemo(() => moment(to).diff(moment(from), "days"), [from]);

	const toDate = useMemo(
		() => moment(moment.parseZone(from)).add(days, "days"),
		[from, days],
	);

	const dates = useMemo(() => {
		let res = [];
		if (to) {
			res = enumerateDaysBetweenDates(
				moment.parseZone(from),
				moment.parseZone(toDate),
			);
		} else {
			res = [moment.parseZone(from)];
		}
		return res;
	}, [from, toDate]);

	const timeSheets = useMemo(() => {
		return dates?.flatMap((date) => {
			return JSON.parse(JSON.stringify(baseTimeSheets || []))?.map((item) => ({
				...item,
				id: `${item?.id}-${date}`,
				date,
			}));
		});
	}, [dates, baseTimeSheets]);

	const dateTimesheetMap = useDateIdMap({ data: timeSheets });

	const columns = useColumns({
		loading,
		dateTimesheetMap,
		response,
	});

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data: timeSheets });

	return (
		<BaseTable
			className="align-items-center table-flush"
			hover
			responsive
			{...getTableProps()}
		>
			<thead className="thead-light">
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps()}>
								{column.render("Header")}
							</th>
						))}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				{rows.length === 0 ? (
					<tr>
						<td colSpan="999">
							<div className="d-flex justify-content-center">
								No data
							</div>
						</td>
					</tr>
				) : (
					rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									const props =
										cell.column.getCellCustomProps?.(
											cell.row.original,
										) || {};
									if (props.rowSpan === 0) {
										return null;
									}
									return (
										<td {...cell.getCellProps(props)}>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})
				)}
			</tbody>
		</BaseTable>
	);
}

export default Table;
