import useSWRMutation from "swr/mutation";
import useApi from "../../../../../../utils/api";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { useDateFilters } from "../../../../hooks/useDateFilters";
import { useGetDependencyRowId } from "./internal/useGetDependencyRowId";
import axios from "axios";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { isNumber, mergeWith, omit } from "lodash";
import { toast } from "react-toastify";

export const useSubmit = ({
  user,
  data,
  onSuccess,
  onFailure,
}) => {
  const { t } = useTranslation()
  const { authPut } = useApi();
  const { from, to } = useDateFilters();
  const { allocatedGroups } = useGroups();
  const getDependencyRowId = useGetDependencyRowId();

  return useSWRMutation(
    axios.getUri({
      url: "/time-sheet/weekly/batch",
      params: {
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
        userId: user.id,
      },
    }),
    (url) => {
      let values = data
        .map((item) => {
          const base = {
            payCode: omit(item.payCode, ["_id"]),
            job: omit(item.job, ["_id"]),
            location: omit(item.location, ["_id"]),
            customFields: item.customFields,
            ...allocatedGroups.reduce((total, allocatedGroup) => {
              const level = allocatedGroup.level.replace("_", "");
              total[level] = omit(item[level], ["_id"]);
              return total;
            }, {})
          };
          base.dates = Object
            .keys(item.dates)
            .reduce((total, date) => {
              total[date] = {
                duration: item.dates[date].duration,
                amount: item.dates[date].amount,
              };
              return total;
            }, {});
          return base;
        })
        .reduce((total, item) => {
          const uniqueId = getDependencyRowId(item);
          if (uniqueId in total) {
            mergeWith(total[uniqueId].dates, item.dates, (a, b) => {
              if (isNumber(a)) {
                return a + b;
              }
            });
          } else {
            total[uniqueId] = item;
          }
          return total;
        }, {});
      values = Object.values(values);
      return authPut(url, { data: values });
    },
    {
      throwOnError: false,
      onSuccess: async (res) => {
        await onSuccess?.(res);
        toast.success(t("timesheets-updated-successfully"));
      },
      onError: async () => {
        onFailure?.();
        toast.error(t("could-not-update-timesheets"));
      },
    }
  );
}
