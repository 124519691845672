import {useCallback, useEffect, useMemo} from "react";
import throttle from "lodash/throttle";

const TIMEOUT_KEY = "_expiredTime";

export const useIdleTimeout = ({ key = TIMEOUT_KEY, timeout, onTimeout }) => {
	const setExpiredTime = useCallback(() => {
		localStorage.setItem(key, Date.now() + timeout * 1000);
	}, [timeout]);
    const debouncedSetExpiredTime = useMemo(() => {
        return throttle(setExpiredTime, 500);
    }, [setExpiredTime]);

	const clearExpiredTime = useCallback(() => {
		localStorage.removeItem(key);
	}, []);

	const addTrackers = useCallback(() => {
		window.addEventListener("mousemove", debouncedSetExpiredTime);
		window.addEventListener("scroll", debouncedSetExpiredTime);
		window.addEventListener("keydown", debouncedSetExpiredTime);
	}, [debouncedSetExpiredTime]);

	const removeTrackers = useCallback(() => {
		window.removeEventListener("mousemove", debouncedSetExpiredTime);
		window.removeEventListener("scroll", debouncedSetExpiredTime);
		window.removeEventListener("keydown", debouncedSetExpiredTime);
	}, [debouncedSetExpiredTime]);

	useEffect(() => {
        debouncedSetExpiredTime();
		const interval = setInterval(() => {
            let expiredTime = parseInt(localStorage.getItem(key), 10);
            if (isNaN(expiredTime) || expiredTime > Date.now()) {
                return;
            }
            onTimeout?.();
		}, 1000);
		addTrackers();
		return () => {
			removeTrackers();
			clearExpiredTime();
			clearInterval(interval);
		};
	}, [onTimeout, debouncedSetExpiredTime, clearExpiredTime]);
}
