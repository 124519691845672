import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useNonAuthApi } from "../../../utils/api";

export const useForgotPassword = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { nonAuthPost } = useNonAuthApi();

	const forgotPassword = useCallback(
		async ({ username, uniqueKey }, onSuccess) => {
			setLoading(true);
			setError(null);

			try {
				await nonAuthPost("/profile/forgot-password", {
					data: {
						username,
						uniqueKey,
					},
				});
				onSuccess();
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, nonAuthPost],
	);

	return { loading, error, forgotPassword };
};
