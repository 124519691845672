import { useMemo, useCallback } from "react";
import useSWR from "swr";
import { formatTimeSheetParams } from "./helpers";
import axios from "axios";

export const useTeam = (params) => {
	const url = useMemo(() => {
		if (!params || !params.from || !params.to) {
			return null;
		}
		const url = axios.getUri({
			url: "/time-sheet/my-team",
			params: formatTimeSheetParams(params),
		});
		return url;
	}, [params]);

	const requestConfig = useMemo(() => ({ url }), [url]);

	const {
		data: baseData,
		isLoading,
		isValidating,
		mutate,
	} = useSWR(requestConfig);

	const data = useMemo(() => {
		if (!baseData) {
			return { result: [], totalItems: 0 }
		}
		return baseData;
	}, [baseData])

    const setTotalItems = useCallback((argument) => {
        let value = argument;
		if (typeof argument === "function") {
			value = argument(data.totalItems);
		}
		mutate({
            result: data.result,
            totalItems: value,
        }, { revalidate: false });
    }, [data, mutate]);

	const setData = useCallback((argument) => {
        let value = argument;
		if (typeof argument === "function") {
			value = argument(data.result);
		}
		mutate({
            result: value,
            totalItems: data.totalItems,
        }, { revalidate: false });
	}, [data, mutate]);

	return {
		data,
		isLoading: isLoading || isValidating,
		mutate,
		setTotalItems,
		setData,
	};
};
