import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { debounce } from "lodash";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import Button from "../../../../../components/Button";
import { ProfileContext } from "../../../context";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
    renderSettingsResourceLabel,
    getSettingsResourceSearchFilters
} from "../../../../../utils/helpers/settings";
import { useResource } from "../../../../../components/Inputs/ResourceSelect/api";

function ChangeUserGroups({ close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [searchValue, setSearchValue] = useState("");
    const  { data, loading, fetch, hasMore } = useResource("/user-group?manageAbility=manual", true);
    const { user, submit, submitting, submittingError } = useContext(ProfileContext);

    const { changeUserGroups: profileSubmit } = useProfileApi();
    const { changeUserGroups: peopleSubmit } = usePeopleApi();

    const onFinish = useCallback(
        (values) => {
            // const userGroups = values?.userGroups?.map(({ id }) => id);
            submit(profileSubmit, peopleSubmit, values, close);
        },
        [submit, profileSubmit, peopleSubmit, close],
    );

    const userGroups = useMemo(() => {
        if (data && user?.userGroups) {
            const ids = user?.userGroups?.map(({ id }) => id);
            const uGroups = Object.values(data)
                ?.filter(({ id }) => !ids.includes(id));
            return [...uGroups, ...user?.userGroups];
        } else if (data && !user?.userGroups) {
            return Object.values(data);
        } else if (user?.userGroups) {
            return user.userGroups
        } else {
            return []
        }
    }, [data, user?.userGroups]);

    const debouncedSearch = useMemo(
		() =>
			getSettingsResourceSearchFilters
				? debounce(
					(value) => fetch(getSettingsResourceSearchFilters(value)),
					600,
				)
				: undefined,
		[fetch, getSettingsResourceSearchFilters],
	);

    const searchProps = useMemo(() => {
		if (hasMore === false) {
			return {
				searchValue,
				onSearch: setSearchValue,
				showSearch: true,
				optionFilterProp: "children",
			};
		}

		const onSearch = (value) => {
			setSearchValue(value);
			if (debouncedSearch) {
				debouncedSearch(value);
			}
		};

		return {
			showSearch: true,
			searchValue,
			filterOption: false,
			onSearch,
		};
	}, [
		hasMore,
		debouncedSearch,
		searchValue,
		setSearchValue,
	]);

    useEffect(() => {
        form.setFieldsValue({
            userGroups: user?.userGroups?.map(({ id }) => id)
        });
    }, [form, user?.userGroups]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["userGroups"],
            submittingError,
        );
        form.setFields(fieldErrors);
    }, [form, submittingError]);

    useEffect(() => {
		return () => debouncedSearch?.cancel();
	}, [debouncedSearch]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            className="d-flex flex-column justify-between"
        >
            <div>
                <Field
                    name="userGroups"
                    label={t("user-groups")}
                    rules={[
                        {
                            required: true,
                            message: t("required-user-groups")
                        }
                    ]}
                >
                   <Select 
                        placeholder={t("user-groups")}
                        mode="multiple"
                        notFoundContent={
                            loading ? (
                                <div className="flex justify-center">
                                    <div
                                        className="spinner spinner-border spinner-border-sm mr-2"
                                        role="status"
                                    />
                                </div>
                            ) : undefined
                        }
                        searchValue={searchValue}
                        {...searchProps}
                    >
                        {userGroups?.map((userGroup) => (
                            <Select.Option 
                                value={userGroup?.id} 
                                key={userGroup?.id}
                                disabled={userGroup?.manageAbility !== "manual"}
                            >
                                {renderSettingsResourceLabel(userGroup)}
                            </Select.Option>
                        ))}
                   </Select>
                </Field>
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    onClick={close}
                    disabled={submitting}
                >
                    {t("cancel")}
                </Button>

                <Button
                    className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
                    color="primary"
                    type="submit"
                    loading={submitting}
                >
                    {t("save")}
                </Button>
            </div>
        </Form>
    )
}

export default ChangeUserGroups;
