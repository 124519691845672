import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { CardBody, CardHeader } from "reactstrap";
import { useAccess } from "../../../utils/hooks/access";
import { useContext } from "react";
import { ProfileContext } from "../context";

function History() {
    const { t } = useTranslation();
    const { isSelf } = useContext(ProfileContext);

    const { hasAccess: canViewJobHistory } = useAccess(
        isSelf ? "profile.viewJobHistory" : "people.viewJobHistory"
    );
    return (
        <>
            <CardHeader className="border-0">
                {canViewJobHistory && (
                    <NavLink
                        className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
                        to="job"
                        activeClassName='active-people-nav'
                    >
                        {t("job")}
                    </NavLink>
                )}
            </CardHeader>

            <CardBody
				style={{ height: "calc(100%)", overflowY: "auto" }}
				className="profile-scroll"
			>
                <Outlet />
            </CardBody>
        </>
    );
}

export default History;
