import { useEffect, useMemo, useRef } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import "./style.scss";

export const getMoneyInputValue = (value) => {
    value = value
        .replace("$", "")
        .replace("£", "")
        .replace("€", "")
        .replace("CHF", "")
        .replaceAll(",", "");
    return Number(value) || 0;
}

const defaultMaskOptions = {
	suffix: "",
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ",",
	allowDecimal: true,
	decimalSymbol: ".",
	decimalLimit: 2,
	integerLimit: 7,
	allowNegative: false,
	allowLeadingZeroes: false,
};

function debounceChange(func, timeout = 300) {
	if (!func) {
		return;
	}
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

const MoneyInput = ({ prefix, onChange, className, value, ...inputProps }) => {
	const currencyMask = useMemo(
		() =>
			createNumberMask({
				prefix,
				...defaultMaskOptions,
				...inputProps,
			}),
		[prefix, inputProps],
	);
	const ref = useRef();

	// const componentOnChange = useMemo(() => {
	// 	if (!onChange) {
	// 		return;
	// 	}
	// 	return debounce(onChange, 300);
	// }, [onChange]);

	useEffect(() => {
		if (
			ref &&
			ref.current &&
			ref.current.textMaskInputElement &&
			typeof ref.current.textMaskInputElement.update === 'function' &&
			document.activeElement !== ref.current.inputElement
		) {
			ref.current.textMaskInputElement.update(value);
		}
	}, [ref, value])

	const processChange = debounceChange(val => onChange(getMoneyInputValue(val.target.value)));

	return (
		<MaskedInput
			ref={ref}
			mask={currencyMask}
			{...inputProps}
			className={`currency-input ${className}`}
			onChange={processChange}
			defaultValue={value}
		// value={value}
		/>
	);
};

export default MoneyInput;
