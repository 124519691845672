import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const columnHelper = createColumnHelper();

export const useColumns = ({ remove }) => {
    const { t } = useTranslation();

    return useMemo(() => [
        columnHelper.accessor("user", {
            header: t("user"),
            cell: (info) => {
                const { firstName, lastName } = info.row.original;
                return firstName + " " + lastName
            }
        }),
        columnHelper.accessor("employeeNumber", {
            header: t("employee-number"),
            cell: (info) => {
                const { employeeNumber } = info.row.original;
                return employeeNumber
            }
        }),
        columnHelper.display({
            header: t("unassign"),
            cell: (info) => {
                const { id } = info.row.original;

                return (
                    <FontAwesomeIcon
                        icon={faXmark}
                        className="text-danger cursor-pointer"
                        onClick={() => remove(id)}
                    />
                )
            }
        })
    ], [remove, t])
}