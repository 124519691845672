import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Form from "rc-field-form";
import { CardBody } from "reactstrap";
import Header from "../Layout/Header";
import FormContent from "../../PayPolicies/PayGroup/Form/Content";
import { useSettingsOnboarding } from "../context";
import useApi from "../../../../utils/api";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useCompany } from "../../../../utils/hooks/company";
import { useCalculateDates } from "../../PayPolicies/PayGroup/Form/useCalculateDates";
import { formatSubmittedData } from "../../PayPolicies/PayGroup/Form/format";

function PayGroup() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const { authPost } = useApi();
	const { submit, error } = useSettingsOnboarding();

	const call = useCallback(
		(values) => {
			if (!company?.id) {
				return;
			}
			return authPost("/pay-group", {
				data: { company: company.id, ...values },
			});
		},
		[company?.id, authPost],
	);

	const calculateDates = useCalculateDates(form);

	const onFinish = useCallback(
		(values) => {
			const data = formatSubmittedData(values);
			submit(true, call(data));
		},
		[submit, call],
	);

	const saveAndRepeat = useCallback(() => {
		const values = form.getFieldsValue();
		const data = formatSubmittedData(values);
		submit(false, call(data), () =>
			toast.success(t("pay-group-created")),
		);
		form.resetFields();
		form.setFieldsValue({
			status: "active",
		});
	}, [form, submit, call, t]);

	useEffect(() => {
		form.setFieldsValue({
			status: "active",
		});
	}, [form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Header saveAndRepeat={saveAndRepeat} />

			<CardBody>
				<FormContent form={form} calculateDates={calculateDates} />
			</CardBody>
		</Form>
	);
}

export default PayGroup;
