import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { getSharedDateRowSpan } from "../helpers";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

export const useScheduledHoursColumn = ({ dateIdMap, scheduledHours, view }) => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            id: "scheduled-hours",
            Header: t("scheduled-hours"),
            getCellCustomProps: (timesheet) => ({
                rowSpan: getSharedDateRowSpan(dateIdMap, timesheet),
            }),
            hideable: false,
            Cell: ({ row: { original }}) => {
                const duration = scheduledHours
                    ?.find((item) => moment(item?.date).isSame(moment(original?.date), "date"))
                    ?.total || 0;
                    
                return (
                    <div className="font-weight-bold text-center">
                        {duration > 0 ? (
                            <span>
                                {renderDurationAsFormat(duration, "HH:mm")}
                                <FontAwesomeIcon 
                                    className="cursor-pointer text-sm mx-2"
                                    icon={faCalendarDays}
                                    onClick={() => view({ ...original, menu: "schedule"})}
                                />
                            </span>
                        ) : "-"}
                    </div>
                );
            }
        }
    }, [t, dateIdMap, scheduledHours, view]);
}