import { useCallback, useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../components/Drawer";
import Action from "../../Actions/Action";
import Breaks from "./Breaks";
import Overtime from "./Overtime";
import TwoFactorAuth from "./TwoFactorAuth";
import ChangePassword from "./ChangePassword";
import ChangeLanguage from "./ChangeLanguage";
import { ProfileContext } from "../../context";
import { useAccess } from "../../../../utils/hooks/access";

function Actions() {
	const { t } = useTranslation();
	const { isSelf } = useContext(ProfileContext);

	const [action, setAction] = useState(null);

	const close = useCallback(() => setAction(null), [setAction]);

	const { hasAccess: canChangeCalculationGroupRules } = useAccess(
		isSelf
			? "profile.changeCalculationGroupRules"
			: "people.changeCalculationGroupRules",
	);

	const { hasAccess: changeTwoFactorAuth } = useAccess(isSelf
		? "profile.changeTwoFactorAuth"
		: "people.changeTwoFactorAuth"
	);

	const drawerContent = useMemo(() => {
		switch (action) {
			case "breaks":
				return <Breaks close={close} />;
			case "overtime":
				return <Overtime close={close} />;
			case "two-factor-auth":
				return <TwoFactorAuth close={close} />;
			case "change-password":
				return <ChangePassword close={close} />;
			case "change-language":
				return <ChangeLanguage close={close} />;
			default:
				return <></>;
		}
	}, [action, close]);

	return (
		<>
			{canChangeCalculationGroupRules && (
				<>
					<Action
						title={t("breaks")}
						onClick={() => setAction("breaks")}
						nonApiOnly={false}
					>
						{t("change")}
					</Action>

					<hr />

					<Action
						title={t("overtime")}
						onClick={() => setAction("overtime")}
						nonApiOnly={false}
					>
						{t("change")}
					</Action>
				</>
			)}

			{(canChangeCalculationGroupRules && changeTwoFactorAuth) && (
				<hr className="my-4" />
			)}


			{changeTwoFactorAuth && (
				<Action
					title={t("two-factor-auth")}
					onClick={() => setAction("two-factor-auth")}
					nonApiOnly={false}
				>
					{t("change")}
				</Action>
			)}

			{(isSelf && (changeTwoFactorAuth || canChangeCalculationGroupRules)) && (
				<hr className="my-4" />
			)}

			{isSelf && (
				<Action
					title={t("password")}
					onClick={() => setAction("change-password")}
				>
					{t("change")}
				</Action>
			)}

			{(isSelf && (canChangeCalculationGroupRules || changeTwoFactorAuth)) &&  (
				<hr className="my-4" />
			)}

			{isSelf && (
				<Action
				    title={t("language")}
					onClick={() => setAction("change-language")}
				>
					{t("change")}
				</Action>
			)}

			{(isSelf && (canChangeCalculationGroupRules || changeTwoFactorAuth)) &&  (
				<hr className="my-4" />
			)}

			<Drawer
				title={t(action)}
				open={action !== null}
				close={close}
				style={{
					maxWidth: "initial",
				}}
			>
				{drawerContent}
			</Drawer>
		</>
	);
}

export default Actions;
