import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

function VerifyModal({
	isOpen,
	close,
	user,
	verify,
	loading,
	setVerifiedUser,
	verifiedUser,
	setLoading,
	fetchData
}) {
	const { t } = useTranslation();

	const onVerifyUser = useCallback(() => {
		if (user) {
			verify(user.id, () => {
				toast.success(t("user-verified-successfully"))
				setLoading(false)
				fetchData()
			});
		}
	}, [user, verify, setLoading, fetchData, t]);

	const onClose = useCallback(
		() => {
			close();
			setVerifiedUser(undefined);
		},
		[close, setVerifiedUser]
	);

	return (
		<Modal isOpen={isOpen} close={onClose} centered>
			<div className="d-flex flex-column align-items-center my-5">
				<FontAwesomeIcon
					icon={verifiedUser ? faCheckCircle : faQuestionCircle}
					style={{ fontSize: "37px" }}
					className={classNames(
						verifiedUser ? "text-green" : "text-primary",
						"mb-3",
					)}
				/>

				{!verifiedUser ? (
					<div className="font-weight-bold text-lg">
						{t(
							"do-you-want-to-verify-users", {
							firstName: user?.firstName,
							lastName: user?.lastName
						}
						)}
					</div>
				) : (
					<span className="text-lg">
						{t(
							`${user?.firstName} ${user?.lastName} is verified and the password is`,
						)}{" "}
						<span className="text-dark font-weight-bold">
							{verifiedUser}
						</span>
						{"."}
					</span>
				)}
			</div>

			<div className="d-flex justify-content-between">
				{!verifiedUser ? (
					<Button
						color="primary"
						loading={loading}
						disabled={loading}
						onClick={onVerifyUser}
					>
						{t("ok")}
					</Button>
				) : (
					<div />
				)}
				<Button color="dark" disabled={loading} onClick={onClose}>
					{t("close")}
				</Button>
			</div>
		</Modal>
	);
}

export default VerifyModal;
