import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useWatch } from "rc-field-form";
import { useUser } from "../../../utils/hooks/user";
import { areBreaksPaid } from "../../../utils/helpers/shift";
import {
	combineDateAndTime,
	renderDurationAsFormat,
} from "../../../utils/helpers/date";

function PaidHours({ }) {
	const { t } = useTranslation();
	const user = useUser();
	const values = useWatch();

	const breaksPaid = areBreaksPaid(user?.calculationGroup?.breaks);

	let hours = "00:00:00";

	if (
		values?.start?.date &&
		values?.start?.time &&
		values?.end?.date &&
		values?.end?.time
	) {
		const start = combineDateAndTime(
			values?.start?.date,
			values?.start?.time,
		);
		const end = combineDateAndTime(values?.end?.date, values?.end?.time);

		let duration = end.diff(start, "seconds");

		if (!breaksPaid) {
			hours = renderDurationAsFormat(duration);
		} else {
			let breaks = 0;
			values?.breaks?.map(({ start, end }) =>
				start && end
					? (breaks += moment(end).diff(moment(start), "seconds"))
					: (breaks += 0),
			);
			duration = end.diff(start, "seconds") + breaks;
			hours = renderDurationAsFormat(duration);
		}
	}

	return (
		<div className="m-0 py-2 d-flex justify-content-between">
			<h4 className="text-primary mr-1">{t("total-paid-hours")}</h4>

			<h4 className="text-primary">{hours}</h4>
		</div>
	);
}

export default PaidHours;
