import {
	useState,
	useEffect,
	useRef,
	useMemo,
	Children,
	isValidElement,
	cloneElement,
} from "react";

const mapStyle = { flexGrow: 1, height: "350px" };

function Map({ options, children, area, position }) {
	const ref = useRef(null);
	const [map, setMap] = useState(null);

	const coords = useMemo(() => {
		if (area) {
			const coordinates = area[0]?.map((coordinate) => ({
				lat: coordinate[1],
				lng: coordinate[0],
			}));

			return coordinates?.slice(0, -1);
		}

		return undefined;
	}, [area]);

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map, setMap]);

	const polygon = useMemo(() => {
		if (coords) {
			return new window.google.maps.Polygon({
				paths: coords,
				fillColor: "black",
				fillOpacity: 0.2,
				strokeWeight: 5,
				editable: false,
				zIndex: 1,
				strokeColor: "black",
				draggable: false,
				geodesic: false,
			});
		}
		return null;
	}, [coords]);

	useEffect(() => {
		if (polygon && map) {
			polygon.setMap(map);
		}
	}, [polygon, map]);

	useEffect(() => {
		if (map && options) {
			map.setOptions(options);
		}
	}, [map, options]);

	useEffect(() => {
		if (!map) {
			return;
		}

		if (!!coords && !!polygon) {
			const bounds = new window.google.maps.LatLngBounds();
			coords.forEach((position) => bounds.extend(position));

			if (!!position) {
				bounds.extend(position);
			}

			map.fitBounds(bounds, 20);
		}
	}, [map, coords, polygon, position]);

	return (
		<>
			<div ref={ref} style={mapStyle} />
			{Children.map(
				children,
				(child) =>
					isValidElement(child) && cloneElement(child, { map }),
			)}
		</>
	);
}

export default Map;
