import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { useCompanyTimeFormat, useCompanyCurrency } from "../../../../../utils/hooks/company";
import { renderHourTypeHeader } from "../../../../TimeSheet/ManagerView/Table/SummaryTooltip/useColumns";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";

export const useColumns = ({ hourTypes, unpaidHourTypes }) => {
    const { t } = useTranslation();
    const timeFormat = useCompanyTimeFormat();
    const currency = useCompanyCurrency();

    const columnHelper = createColumnHelper();

    const workedHoursColumns = useMemo(() => {
        const columns = [
            columnHelper.accessor("payCode", {
                header: "",
                cell: ({ row: { original } }) => (
                    <span className="font-weight-bold">
                        {original?.payCode ? original.payCode : "--"}
                    </span>
                ),
                footer: (
                    <span className="font-weight-bolder text-dark">
                        {t("total")}
                    </span>
                ),
            }),
        ];

        hourTypes?.map((hourType) =>
            columns?.push(columnHelper.accessor(hourType, {
                header: renderHourTypeHeader(hourType),
                cell: ({ row: { original } }) => {
                    if (hourType === original?.hourType) {
                        return renderDurationAsFormat(
                            original?.duration,
                            timeFormat,
                        );
                    } else {
                        return renderDurationAsFormat(0, timeFormat);
                    }
                },
                footer: ({ table }) => {
                    const rows = table.getRowModel().rows;
                    const duration = rows?.reduce((total, row) => {
                        if (row?.original?.hourType === hourType) {
                            total += row?.original?.duration;
                        }
                        return total;
                    }, 0);

                    return (
                        <span className="text-dark font-weight-bold">
                            {renderDurationAsFormat(duration, timeFormat)}
                        </span>
                    );
                },
            })),
        );

        columns.push(columnHelper.accessor("total", {
            header: t("total"),
            cell: ({ row: { original }, table },) => {
                const data = table.getCoreRowModel().rows;
                let total = 0;
                data?.map((item) => {
                    if (item?.original?.payCode === original?.payCode) {
                        return (total += Number(item?.original?.duration));
                    }
                });
                return <>{renderDurationAsFormat(total, timeFormat)}</>;
            },
            footer: ({ table }) => {
                const rows = table.getRowModel().rows;
                const duration = rows?.reduce(
                    (total, row) => (total += row?.original?.duration),
                    0,
                );

                return (
                    <span className="text-info font-weight-bolder">
                        {renderDurationAsFormat(duration, timeFormat)}
                    </span>
                );
            },
        }));

        return columns;
    }, [t, hourTypes, timeFormat]);

    const paidAmountColumns = useMemo(() => {
        const columns = [
            {
                header: "",
                accessorKey: "payCode",
                cell: ({ row: { original } }) => (
                    <span className="font-weight-bold">
                        {original?.payCode ? original.payCode : "--"}
                    </span>
                ),
                footer: (
                    <span className="font-weight-bolder text-dark">
                        {t("total")}
                    </span>
                ),
            },
        ];

        hourTypes?.map((hourType) =>
            columns?.push({
                header: renderHourTypeHeader(hourType),
                accessorKey: hourType,
                cell: ({ row: { original } }) => {
                    if (hourType === original?.hourType) {
                        return currencyFormatter(original?.total, 2, currency);
                    } else {
                        return currencyFormatter(0, 2, currency);
                    }
                },
                footer: ({ table }) => {
                    const rows = table.getRowModel().rows;
                    const duration = rows?.reduce((total, row) => {
                        if (row?.original?.hourType === hourType) {
                            total += row?.original?.total;
                        }
                        return total;
                    }, 0);

                    return (
                        <span className="text-dark font-weight-bold">
                            {currencyFormatter(duration, 2, currency)}
                        </span>
                    );
                },
            }),
        );

        columns.push({
            header: t("total"),
            accessorKey: "total",
            cell: ({ row: { original }, table }) => {
                const data = table.getCoreRowModel().rows;
                let total = 0;
                data?.map((item) => {
                    if (item?.original?.payCode === original?.payCode) {
                        return (total += Number(item?.original?.total));
                    }
                });
                return <>{currencyFormatter(total, 2, currency)}</>;
            },
            footer: ({ table }) => {
                const rows = table.getRowModel().rows;
                const duration = rows?.reduce(
                    (total, row) => (total += row?.original?.total),
                    0,
                );

                return (
                    <span className="text-info font-weight-bolder">
                        {currencyFormatter(duration, 2, currency)}
                    </span>
                );
            },
        });

        return columns;
    }, [t, hourTypes, currency]);

    const unpaidHoursColumns = useMemo(() => {
        const columns = [
            {
                header: "",
                accessorKey: "payCode",
                cell: ({ row: { original } }) => (
                    <span className="font-weight-bold">
                        {original?.payCode ? original.payCode : "--"}
                    </span>
                ),
                footer: (
                    <span className="font-weight-bolder text-dark">
                        {t("total")}
                    </span>
                ),
            },
        ];

        unpaidHourTypes?.map((hourType) =>
            columns?.push({
                header: renderHourTypeHeader(hourType),
                accessorKey: hourType,
                cell: ({ row: { original } }) => {
                    if (hourType === original?.hourType) {
                        return renderDurationAsFormat(
                            original?.duration,
                            timeFormat,
                        );
                    } else {
                        return renderDurationAsFormat(0, timeFormat);
                    }
                },
                footer: ({ table }) => {
                    const rows = table.getRowModel().rows;
                    const duration = rows?.reduce((total, row) => {
                        if (row?.original?.hourType === hourType) {
                            total += row?.original?.duration;
                        }
                        return total;
                    }, 0);

                    return (
                        <span className="text-dark font-weight-bold">
                            {renderDurationAsFormat(duration, timeFormat)}
                        </span>
                    );
                },
            }),
        );

        columns.push({
            header: t("total"),
            accessorKey: "total",
            cell: ({ row: { original }, table }) => {
                const data = table.getCoreRowModel().rows;
                let total = 0;
                data?.map((item) => {
                    if (item?.original?.payCode === original?.payCode) {
                        return (total += Number(item?.original?.duration));
                    }
                });
                return <>{renderDurationAsFormat(total, timeFormat)}</>;
            },
            footer: ({ table }) => {
                const rows = table.getRowModel().rows;
                const duration = rows?.reduce(
                    (total, row) => (total += row?.original?.duration),
                    0,
                );

                return (
                    <span className="text-info font-weight-bolder">
                        {renderDurationAsFormat(duration, timeFormat)}
                    </span>
                );
            },
        });

        return columns;
    }, [t, unpaidHourTypes, timeFormat]);

    const unpaidAmountColumns = useMemo(() => {
        const columns = [
            {
                header: "",
                accessorKey: "payCode",
                cell: ({ row: { original } }) => (
                    <span className="font-weight-bold">
                        {original?.payCode ? original.payCode : "--"}
                    </span>
                ),
                footer: (
                    <span className="font-weight-bolder text-dark">
                        {t("total")}
                    </span>
                ),
            },
        ];

        unpaidHourTypes?.map((hourType) =>
            columns?.push({
                header: renderHourTypeHeader(hourType),
                accessorKey: hourType,
                cell: ({ row: { original } }) => {
                    if (hourType === original?.hourType) {
                        return currencyFormatter(original?.total, 2, currency);
                    } else {
                        return currencyFormatter(0, 2, currency);
                    }
                },
                footer: ({ table }) => {
                    const rows = table.getRowModel().rows;
                    const duration = rows?.reduce((total, row) => {
                        if (row?.original?.hourType === hourType) {
                            total += row?.original?.total;
                        }
                        return total;
                    }, 0);

                    return (
                        <span className="text-dark font-weight-bold">
                            {currencyFormatter(duration, 2, currency)}
                        </span>
                    );
                },
            }),
        );

        columns.push({
            header: t("total"),
            accessorKey: "total",
            cell: ({ row: { original }, table }) => {
                const data = table.getCoreRowModel().rows;
                let total = 0;
                data?.map((item) => {
                    if (item?.original?.payCode === original?.payCode) {
                        return (total += Number(item?.original?.total));
                    }
                });
                return <>{currencyFormatter(0, 2, currency)}</>;
            },
            footer: ({ table }) => {
                const rows = table.getRowModel().rows;
                const duration = rows?.reduce(
                    (total, row) => (total += row?.original?.total),
                    0,
                );

                return (
                    <span className="text-info font-weight-bolder">
                        {currencyFormatter(duration, 2, currency)}
                    </span>
                );
            },
        });

        return columns;
    }, [t, unpaidHourTypes, currency]);

    return {
        workedHoursColumns,
        paidAmountColumns,
        unpaidHoursColumns,
        unpaidAmountColumns,
    };
};