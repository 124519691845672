import { useCallback } from "react";
import { List } from "rc-field-form";
import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Text from "../../../../../../components/Inputs/Text";
import Switch from "../../../../../../components/Inputs/Switch";
import Field from "../../../../../../components/Field";
import Button from "../../../../../../components/Button";
import DateList from "./DateList";
import { Label } from "reactstrap";
import { firstToUpper } from "../../../../../../utils/helpers/string";
import "./style.scss";

function DaysList({ form, disabled }) {
    const { t } = useTranslation();

    const onSwitchChange = useCallback(
        (value, name) => {
            if (!value) {
                form.setFieldValue(["days", name, "date"]);
                form.setFieldValue(["days", name, "dates"], [""]);
            }
        },
        [form]
    );

    const repeatSwitchOnChange = useCallback(
        (value, name) => {
            form.setFieldValue(["days", name, "date"]);
            form.setFieldValue(["days", name, "dates"], [""]);
            form.setFieldValue(["days", name, "isSameDate"], true);
            if (!value) {
                form.setFieldValue(["days", name, "isSameDate"], false);
            }
        },
        [form]
    );

    return (
        <>
            <Label>{firstToUpper(t("days"))}<small className="text-danger ml-1">*</small></Label>
            <List name="days">
                {(fields, { add, remove }) => {
                    return (
                        <div className="days-list-container">
                            {fields.map(({ key, name }) => {
                                return (
                                    <div key={key} className="day-list-item">
                                        <div className="d-flex flex-column w-100">
                                            <Field
                                                name={[name, "name"]}
                                                label={t("name")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("required-name"),
                                                    },
                                                ]}
                                                className="d-flex flex-column w-100"
                                            >
                                                <Text placeholder={t("name")} disabled={disabled} />
                                            </Field>

                                            <div className="d-flex gap-6">
                                                <Field valuePropName="checked" name={[name, "repeatEveryYear"]}>
                                                    <Switch
                                                        disabled={disabled}
                                                        onChange={(e) => repeatSwitchOnChange(e.target.checked, name)}
                                                    >
                                                        {t("repeat-every-year")}
                                                    </Switch>
                                                </Field>

                                                <BaseField shouldUpdate>
                                                    {({}, {}, { getFieldValue }) => {
                                                        const repeatEveryYear = getFieldValue([
                                                            "days",
                                                            name,
                                                            "repeatEveryYear",
                                                        ]);

                                                        return (
                                                            repeatEveryYear && (
                                                                <Field
                                                                    name={[name, "isSameDate"]}
                                                                    valuePropName="checked"
                                                                >
                                                                    <Switch
                                                                        onChange={(e) =>
                                                                            onSwitchChange(e.target.checked, name)
                                                                        }
                                                                        disabled={disabled}
                                                                    >
                                                                        {t("is-same-date")}
                                                                    </Switch>
                                                                </Field>
                                                            )
                                                        );
                                                    }}
                                                </BaseField>
                                            </div>

                                            <DateList name={name} disabled={disabled} />
                                        </div>

                                        {fields.length > 1 && !disabled && (
                                            <FontAwesomeIcon
                                                className="text-md text-danger cursor-pointer"
                                                onClick={() => remove(name)}
                                                icon={faXmark}
                                            />
                                        )}
                                    </div>
                                );
                            })}

                            {!disabled && (
                                <Button
                                    type="button"
                                    className="w-100"
                                    color="primary"
                                    onClick={() => add({ repeatEveryYear: true, isSameDate: true }, fields?.length)}
                                >
                                    + {t("add")}
                                </Button>
                            )}
                        </div>
                    );
                }}
            </List>
        </>
    );
}

export default DaysList;
