import { List as BaseList } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../../../components/Button";
import List from "./List";
import Modal from "./List/Modal";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderOrgLevelResourceLabel } from "../../../../../../../utils/helpers/settings";
import { useVisible } from "../../../../../../../utils/hooks/useVisible";


function Shifts({ disabled, form, resource, defaultSegment, eventRecord }) {
    const { t } = useTranslation();
    const { allocatedGroups } = useGroups();

    const { 
        selected, 
        open: openModal, 
        visible,
        close
    } = useVisible();

    const removeShift = (name, remove) => {
        const shifts = form.getFieldValue("shifts");
        if(name === 0 && shifts.length === 1) {
            return;
        }
        remove(name);
        if (name === 0 && shifts.length > 1) {
            // need to remove first shift
            // change the start time of the next shift to the start time of the first shift
            const nextShift = shifts[1];
            form.setFieldValue('startDate', nextShift[0].start);

        } else if (name === shifts.length - 1) {
            const previousShift = shifts[name - 1];
            form.setFieldValue('endDate', previousShift[0].end);
        }
    }

    return (
        <>
            <BaseList name="shifts">
                {(data, { add, remove }) => {
                    let shifts = form.getFieldValue("shifts");
                    shifts = (shifts || []).flat();
                    return (
                        <>
                            <div className="d-flex align-items-center justify-content-between my-3">
                                <span>
                                    {t("shifts")}
                                    <small className="text-red mx-1">*</small>
                                </span>

                               {!disabled && (
                                    <Button
                                        className="border border-primary text-primary px-3 py-2"
                                        onClick={() => add([{
                                            ...defaultSegment,
                                            start: shifts[shifts?.length - 1]?.end,
                                            end: undefined,
                                            duration: undefined,
                                        }])}
                                    >
                                        {"+ "}{t("add-shift")}
                                    </Button>
                               )}
                            </div>

                            <Table
                                className="shifts-table align-items-center table-flush"
                                hover
                                responsive
                            >
                                <thead>
                                    {!disabled && <th style={{ width: "44px" }} />}
                                    <th style={{ width: "100px" }}>{t("time-code")}</th>
                                    <th style={{ width: "100px" }}>{t("start")} </th>
                                    <th style={{ width: "100px" }}>{t("end")}</th>
                                    <th style={{ width: "100px" }}> {t("duration")} </th>
                                    {allocatedGroups?.map((group) => (
                                        <th>
                                            {renderOrgLevelResourceLabel(group)}
                                        </th>
                                    ))}
                                    <th style={{ width: "40px" }} />
                                </thead>

                                <tbody>
                                    {data?.map(({ name }) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td colSpan={7} className="font-weight-bolder text-sm">
                                                        <FontAwesomeIcon 
                                                            icon={faTrash}
                                                            className={classNames(
                                                                data?.length === 1 ? "d-none" : "text-danger cursor-pointer", "mr-3"
                                                            )}
                                                            onClick={data?.length === 1 ? undefined : () => removeShift(name, remove)}
                                                        />
                                                        {t("shift") + ' ' + Number(name + 1)}
                                                    </td>
                                                </tr>
                                                
                                                <List 
                                                    form={form}
                                                    shiftName={name}
                                                    resource={resource}
                                                    disabled={disabled}
                                                    openModal={openModal}
                                                    defaultSegment={defaultSegment}
                                                    eventRecord={eventRecord}
                                                    removeShift={() => removeShift(name, remove)}
                                                />
                                            </>
                                        )}
                                    )}
                                </tbody>
                            </Table>
                       </>
                    )
                }}
            </BaseList>

            {visible && (
                <Modal
                    form={form}
                    isOpen={visible}
                    name={selected?.name}
                    shiftName={selected?.shiftName}
                    close={close}
                    disabled={disabled}
                    resource={resource}
                />
            )}
        </>
    )
}

export default Shifts;
