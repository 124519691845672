import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api";
import { useUser } from "../../../../../utils/hooks/user";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useRateConfiguration = () => {
	const { t } = useTranslation();
	const user = useUser();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { authPost } = useApi();

	const submit = useCallback(async(data, onSuccess) => {
		setLoading(true);
		try {
			if (user?.mainCompany) {
				const response = await authPost( `/preferencies/company/${user.mainCompany}/rate`, {
					data
				});

				if (response) {
					onSuccess?.(response?.config);
				}
			}
		} catch(err) {
			if (typeof err === "string") {
				getErrorMessage(err, t);
			}
			setError(err);
		} finally {
			setLoading(false);
		}
	}, [setLoading, setError, user?.mainCompany, authPost, t]);

	return { submit, error, loading };
}


export const useRoles = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const { authGet } = useApi();

	const fetch = useCallback(async(controller) => {
		try {
			const response = await authGet("/user-roles", {
				params: {
					pagination: false
				},
				signal: controller?.signal
			});

			if (response) {
				setData(response?.result);
			}
		} catch(err) {
			getErrorMessage(err, t);
		}
	}, [authGet, setData, t]);

	return { fetch, data };
}