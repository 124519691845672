import { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";

function ManageByOrganisationLevel({ manageAbility, locations, disabled }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const groupUrl = useMemo(() => {
		const query = new URLSearchParams();

		// locations?.map(({ id }) => query.append("locationId", id));

		if (
			manageAbility === "by-company-and-location-and-organisation-level"
		) {
			return `/org-levels?${query.toString()}`;
		} else {
			return "/org-levels";
		}
	}, [locations, manageAbility]);

	return (
		<>
			{groups
				.filter(({ hasHierarchy }) => hasHierarchy)
				.map(({ id, description }) => (
					<Field
						key={id}
						name="managedLevels"
						label={description}
						rules={
							manageAbility !== "custom" && [
								{
									required: true,
									message: t(
										"required-groups",
										{
											description,
										},
									),
								},
							]
						}
					>
						<ResourceSelect
							labelPropName="description"
							resourcePath={`/${id}${groupUrl}`}
							mode="multiple"
							placeholder={description}
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							valuePropName="id"
							hasSearch
							disabled={disabled}
						/>
					</Field>
				))}
		</>
	);
}

export default ManageByOrganisationLevel;
