import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useResetPassword = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { authPost } = useApi();

	const resetPassword = useCallback(
		async (id, onSuccess) => {
			setLoading(true);
			try {
				const response = await authPost(`/users/${id}/reset-password`);
				if (response) {
					onSuccess?.(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[authPost, setLoading, t],
	);

	return { resetPassword, loading };
};
