import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { forwardRef, useCallback, useState } from 'react';
import { Input as BaseInput, InputGroup } from 'reactstrap';
import './_style.scss';

function Password({ hasError, className, ...props }, ref) {
	const [isActive, setIsActive] = useState(false);
	const [passwordType, setPasswordType] = useState('password');

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const onFocus = useCallback(() => setIsActive(true), [setIsActive]);
	const onBlur = useCallback(() => setIsActive(false), [setIsActive]);

	return (
		<InputGroup className="password-input">
			<BaseInput
				innerRef={ref}
				className={classNames(
					className,
					hasError && 'is-invalid',
					isActive && 'border-primary'
				)}
				{...props}
				type={passwordType}
				onFocus={onFocus}
				onBlur={onBlur}
				autoComplete="new-password"
			/>
			<span
				className={classNames(
					'toggle d-flex align-items-center',
					isActive && 'border-primary'
				)}
				onClick={togglePassword}
			>
				<FontAwesomeIcon
					icon={passwordType === 'password' ? faEye : faEyeSlash}
				/>
			</span>
		</InputGroup>
	);
}

export default forwardRef(Password);
