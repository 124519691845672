import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../../utils/hooks/access";

export const useEventMenuFeature = ({ 
	openCopyModal, 
	openTransferModal, 
	openActionModal,
	isTeamScheduler,
	openEditDrawer
}) => {
	const { t } = useTranslation();

	const {
		hasAccess: canEdit
	} = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
	const {
		hasAccess: canCreate
	} = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
	const {
		hasAccess: canCreateRequest
	} = useAccess(!isTeamScheduler && "request.canCreate");
    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" :"schedule.canCopyMySchedule");
    const {
        hasAccess: canDelete
    } = useAccess(isTeamScheduler ? "schedule.canDelete" :"schedule.canDeleteMySchedule");
    const {
        hasAccess: canTransfer
    } = useAccess(isTeamScheduler ? "schedule.canTransfer" :"schedule.canTransferMySchedule");
    const {
        hasAccess: canLock
    } = useAccess(isTeamScheduler ? "schedule.canLock" :"schedule.canLockMySchedule");
	const {
        hasAccess: canUnlock
    } = useAccess(isTeamScheduler ? "schedule.canUnlock" :"schedule.canUnlockMySchedule");
    const {
        hasAccess: canApprove
    } = useAccess(isTeamScheduler ? "schedule.canApprove" :"schedule.canApproveMySchedule");

	return useMemo(
		() => {
			let items = {
				cutEvent: false,
				unassignEvent: false,
				splitEvent: false,
				renameSegment: false,
				copyEvent: false,
				editEvent: true
			};

			if (canCreate && !items.addEvent) {
				items.addEvent = {
					...items.editEvent,
					text: t("add-another-event"),
					icon: "b-icon b-icon-add",
					weight: -300,
					hidden: !canCreate,
					onItem: ({ eventRecord, resourceRecord, source }) => {
						source.createEvent(
							eventRecord.endDate,
							resourceRecord,
							source.getRowFor(resourceRecord)
						);
					},
				};
			}

			if (openCopyModal) {
				items = {
					...items,
					copyEvent: {
						text: t("copy-event"),
						icon: "b-fa b-fa-fw b-fa-copy",
						hidden: !canCopy,
						onItem({ eventRecord, resourceRecord }) {
							openCopyModal({
								event: eventRecord?.id,
								user: resourceRecord.data,
								type: "cell"
							});
						},
					},
				}
			}

			const eventMenu = {
				processItems({ eventRecord, items }) {
					items.editEvent = {
						...items.editEvent,
						text: t("edit"),
						hidden: !canEdit,
						disabled: eventRecord?.locked,
					}

					items.requestEvent = {
						icon: "fa-solid fa-pen",
						text: t("edit-request"),
						hidden: canEdit || !canCreateRequest,
						onItem({ eventRecord, resourceRecord }) {
							openEditDrawer({ eventRecord, resourceRecord })
						}
					}

					items.deleteEvent = {
						...items.deleteEvent,
						disabled: eventRecord?.locked,
						hidden: !canDelete
					}

					items.submitEvent = {
						text: t("submit"),
						icon: "fa-solid fa-check",
						disabled: eventRecord?.locked || ["approved", "submitted"].includes(eventRecord?.status),
						hidden: !canCreate || isTeamScheduler,
						onItem({ eventRecord, resourceRecord }) {
							openActionModal({
								type: "cell",
								eventId: eventRecord?.id,
								userId: resourceRecord?.id,
								action: "submit",
								isTeamScheduler,
							})
						}
					}
					
					if (openTransferModal) {
						items.transferEvent = {
							text: t("transfer-event"),
							icon: "fa-solid fa-arrow-down-up-across-line",
							disabled: eventRecord?.locked,
							hidden: !canTransfer,
							onItem({ eventRecord, resourceRecord }) {
								openTransferModal({
									type: "cell",
									id: eventRecord?.id,
									userId: resourceRecord?.id,
								})
							}
						}
					}

					if (openActionModal && isTeamScheduler) {
						items.approveEvent = {
							text: eventRecord?.status === "pending" 
								? t("publish")
								: t("unpublish"),
							icon: eventRecord?.status === "pending"
								? "fa-solid fa-circle-check"
								: "fa-solid fa-circle-xmark",
							disabled: eventRecord?.locked,
							hidden: !canApprove,
							onItem({ eventRecord, resourceRecord }) {
								openActionModal({
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action:  eventRecord?.status === "pending" ? "publish" : "unpublish"
								})
							}
						}

						items.lockEvent = {
							text: t("lock"),
							icon:  "fa-solid fa-lock",
							hidden: eventRecord?.locked || !canLock, 
							onItem({ eventRecord, resourceRecord }) {
								openActionModal({
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action: "lock"
								})
							}
						}

						items.unlockEvent = {
							text: t("unlock"),
							icon: "fa-solid fa-unlock",
							hidden: !eventRecord?.locked || !canUnlock, 
							onItem({ eventRecord, resourceRecord }) {
								openActionModal({
									type: "cell",
									eventId: eventRecord?.id,
									userId: resourceRecord?.id,
									action: "unlock"
								})
							}
						}
					}
				},
				items
			}

			return eventMenu;
		}, [
			canCreateRequest,
			canEdit,
			canDelete,
			canTransfer,
			canLock,
			canUnlock,
			canApprove,
			openCopyModal, 
			openTransferModal, 
			openActionModal, 
			openEditDrawer,
			isTeamScheduler,
			canCreate,
			canCopy,
			t
		])
};
