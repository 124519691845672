import { useCallback, useState, useEffect } from "react";
import { useVisible } from "../../../../../utils/hooks/useVisible";

const getKeyIdentifier = ({ id }) => {
    return id;
};

export const useColumnToggleUserPreferences = (baseColumns) => {
    const { visible, toggle, close } = useVisible();

    const getMap = useCallback((columns) => {
        return columns.reduce((total, column) => {
            const key = getKeyIdentifier(column);
            return {
                ...total,
                [key]: column.getIsVisible(),
            };
        }, {})
    }, []);

    const [visibleColumns, setVisibleColumns] = useState(getMap(baseColumns));

    const onVisibilityChange = useCallback((key) => {
        setVisibleColumns((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    }, [setVisibleColumns]);

    useEffect(() => {
        setVisibleColumns(getMap(baseColumns));
    }, [baseColumns, setVisibleColumns]);

    const onSave = useCallback(async ({ columns, submit }) => {
        const cols = columns.map((column) => {
            const key = getKeyIdentifier(column);
            return {
                accessor: key,
                hide: !visibleColumns[key],
            };
        });
        const values = {
            entity: "user",
            config: {
                columns: cols
            }
        }
        await submit(values);
        close();
    }, [visibleColumns, close]);

    return {
        visibleColumns,
        onVisibilityChange,
        getKeyIdentifier,
        onSave,
        visible,
        toggle,
    };
}
