import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Details from "./Details";
import OptionalSettings from "./OptionalSettings";
import EnrollEmployees from "./EnrollEmployees";
import Preview from "./Preview";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import "./style.scss";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [step, setStep] = useState(0);
	const [timeOffData, setTimeOffData] = useState(values || {});
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.timeOff");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const stepSubmit = useCallback(
		(data) => {
			if (step < 3) {
				setStep((prev) => prev + 1);
			}
			setTimeOffData((prev) => ({ ...prev, ...data }));
		},
		[setStep, setTimeOffData, step],
	);

	const goBack = useCallback(() => {
		setStep((prev) => prev - 1);
	}, [setStep]);

	const onFinish = useCallback(() => {
		const formattedData = {
			...timeOffData,
			employees: timeOffData.employees?.map(({ id }) => id) || [],
		};

		submit(formattedData);
	}, [timeOffData, submit]);

	const stepComponent = useMemo(() => {
		switch (step) {
			case 0: {
				return (
					<Details
						mode={mode}
						data={
							mode === "clone"
								? { ...timeOffData, code: "" }
								: timeOffData
						}
						submit={stepSubmit}
						submitting={loading}
						goBack={close}
						error={error}
						disabled={disabled || !isMasterCompany}
					/>
				);
			}
			case 1:
			case 2: {
				const Component = [OptionalSettings, EnrollEmployees][step - 1];
				return (
					<Component
						data={timeOffData}
						submit={stepSubmit}
						submitting={loading}
						goBack={goBack}
						error={error}
						mode={mode}
						disabled={disabled || !isMasterCompany}
					/>
				);
			}
			case 3:
				return (
					<Preview
						data={timeOffData}
						submit={onFinish}
						submitting={loading}
						goBack={goBack}
						mode={mode}
						disabled={disabled || !isMasterCompany}
					/>
				);
			default:
				return null;
		}
	}, [
		step,
		mode,
		timeOffData,
		loading,
		goBack,
		error,
		stepSubmit,
		close,
		disabled,
		isMasterCompany,
		onFinish,
	]);

	return (
		<>
			<div className="pay-policy-steps">
				<section class="step-wizard  overflow-hidden">
					<ul class="step-wizard-list">
						<li
							className={`step-wizard-item current-item col-3 ${step >= 0 ? "on-item" : " "
								}`}
						>
							<span class="progress-count">1</span>
							<span class="progress-label">
								{t("policy-details")}
							</span>
						</li>
						<li
							className={`step-wizard-item current-item col-3 ${step >= 1 ? "on-item" : " "
								}`}
						>
							<span class="progress-count">2</span>
							<span class="progress-label">
								{t("optional-levels")}
							</span>
						</li>
						<li
							className={`step-wizard-item current-item col-3 ${step >= 2 ? "on-item" : " "
								}`}
						>
							<span class="progress-count">3</span>
							<span class="progress-label">
								{t("enroll-employees")}
							</span>
						</li>
						<li
							className={`step-wizard-item current-item col-3 ${step === 3 ? "on-item" : " "
								}`}
						>
							<span class="progress-count">4</span>
							<span class="progress-label">
								{t("starting-balances")}
							</span>
						</li>
					</ul>
				</section>
			</div>

			<div>{stepComponent}</div>
		</>
	);
}

export default Form;
