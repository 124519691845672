import Filters from "../../../../components/Table/Actions/Filters";
import {useFilters} from "./useFilters";

function TeamSchedulerFilters({ filters, setFilters }) {
    const filterColumns = useFilters();

    return (
        <Filters
            columns={filterColumns}
            filters={filters}
            setFilters={setFilters}
        />
    );
}

export default TeamSchedulerFilters;
