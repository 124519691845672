import { useMemo } from "react";

export const useDateIdMap = ({ data }) => {
	return useMemo(() => {
		return data?.reduce((total, timeSheet) => {
			const date = timeSheet.date;
			if (!(date in total)) {
				total[date] = [];
			}
			total[date].push(timeSheet.id);
			return total;
		}, {});
	}, [data]);
};
