import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useParams } from "react-router-dom";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { OrgLevelGroupsContext } from "../OrganisationLevelGroups/context";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import NTable from "../../../../components/NTable";
import { useNewTable } from "../../../../components/NTable/hook";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useOnExport } from "../../helpers/useOnExport";
import { useImportModal } from "../../helpers/useImportModal";

function OrganisationLevels() {
	const { t } = useTranslation();
	const { id: groupId } = useParams();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook(`/${groupId}/org-levels`);

	const { groups } = useContext(OrgLevelGroupsContext);

	const canUseInClockIn = useMemo(() => {
		const group = groups?.find(({ useInClockIn }) => useInClockIn);
		if (group?.id === groupId) {
			return true;
		} else {
			return false;
		}
	}, [groups, groupId]);

	const { hasAccess: canAdd } = useAccess(
		"settings.general.organizationLevelGroup.organizationLevel.canCreate",
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t(
					"do-you-want-to-delete-organisation-levels", {
					count: data?.length
				}
				)
				: t(
					"do-you-want-to-delete-organisation-level"
				),
		onConfirm: (data) =>
			Array.isArray(data) ? batchRemove(data) : remove(data),
		permission: "settings.general.organizationLevelGroup.organizationLevel.canDelete",
	});
	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
		edit: useActionWithAccess(
			goToEdit,
			"settings.general.organizationLevelGroup.organizationLevel.canEdit",
		),
		remove: openDeleteModal,
		clone: useActionWithAccess(
			goToClone,
			"settings.general.organizationLevelGroup.organizationLevel.canCreate",
		),
		canUseInClockIn,
	});
	const table = useNewTable({
		data,
		columns,
		loading,
		totalItems,
		sessionFiltersKey: `group-${groupId}-orgLevels`,
	});

	useEffect(() => {
		table.reset();
	}, [groupId, table.reset]);

	const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-organisation-level"),
		create: t("add-organisation-level"),
		edit: t("edit-organisation-level"),
		clone: t("clone-organisation-level"),
	});

	useFetchResource({ fetch, state: table.getState() });

	const visibleColumnKeys = useVisibleColumnKeys(table);

	const onExport = useOnExport({
		url: `/export/${groupId}/org-levels`,
		exportCall: settingsExport,
		queryFilters,
		visibleColumnKeys
	});

	const { open: openImportModal, modal: importModal } = useImportModal({
		importUrl: `/${groupId}/org-levels/import`,
		exportUrl: `/export/${groupId}/org-levels`,
		setData,
		exportCall: settingsExport,
		loading: loading || loadingExport,
	});

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("organisation-levels")}
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
					<Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
					>
						<BulkActions
							rows={table.getState().rowSelection}
							batchRemove={openDeleteModal}
						/>
					</Actions>

					<NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			{importModal}

			{deleteModal}

			{unarchiveModal}
		</div>
	);
}

export default OrganisationLevels;
