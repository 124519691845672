import { useCallback, useMemo, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import useApi from "../../../utils/api";
import { initialState, reducer } from "../../Settings/hook/reducer";
import { ProfileContext } from "../context";
import { useCompany } from "../../../utils/hooks/company";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useRequestTimeOffApi = () => {
	const { t } = useTranslation();
	const { authGet, authPost, authDelete, authPut } = useApi();

	const company = useCompany();
	const { user } = useContext(ProfileContext);

	const [state, dispatch] = useReducer(reducer, initialState);

	const selected = useMemo(() => {
		const data = state.data;
		const selectedId = state.selectedId;
		return selectedId !== null && data !== null
			? data.find((resource) => resource.id === selectedId)
			: undefined;
	}, [state.data, state.selectedId]);

	const setMode = useCallback(
		(mode, selectedId = null) =>
			dispatch({
				type: "changeMode",
				payload: { mode, selectedId },
			}),
		[dispatch],
	);

	const goToView = useCallback(() => setMode("view"), [setMode]);

	const goToCreate = useCallback(() => setMode("create"), [setMode]);

	const goToEdit = useCallback((id) => setMode("edit", id), [setMode]);

	const goToClone = useCallback((id) => setMode("clone", id), [setMode]);

	const fetch = useCallback(
		async (controller) => {
			dispatch({ type: "requestFetch" });

			try {
				const response = await authGet(`/users/${user?.id}/time-off`, {
					signal: controller?.signal,
				});

				if (response) {
					dispatch({
						type: "fetchSucceeded",
						payload: {
							result: response,
							totalItems: response.length,
						},
					});
				}
			} catch (err) {
				dispatch({ type: "fetchFailed", payload: err });
				if (typeof err === "string") {
					toast.error(err);
				}
			}
		},
		[authGet, user?.id, dispatch],
	);

	const create = useCallback(
		async (data, onSuccess = noop, onFailure = noop) => {
			dispatch({ type: "requestCreate" });
			try {
				const response = await authPost(`/users/${user?.id}/time-off`, {
					data: { ...data, company: company?.id },
				});
				if (response) {
					dispatch({
						type: "createSucceeded",
						payload: response,
					});
					onSuccess(response);
					toast.success(t("resource-created"));
					goToView();
				}
			} catch (err) {
				dispatch({
					type: "createFailed",
					payload: err,
				});
				onFailure(err);

				if (typeof err === "string") {
					toast.error(err);
				}
			}
		},
		[authPost, user, dispatch],
	);

	const update = useCallback(
		async (id, data, onSuccess = noop, onFailure = noop) => {
			dispatch({ type: "requestUpdate" });

			try {
				const response = await authPut(
					`/users/${user?.id}/time-off/${id}`,
					{ data: { ...data, company: company?.id } },
				);
				if (response) {
					dispatch({
						type: "updateSucceeded",
						payload: response,
					});
					onSuccess(response);
					toast.success(t("resource-updated"));
					goToView();
				}
			} catch (err) {
				dispatch({ type: "updateFailed", payload: err });
				onFailure(err);
				getErrorMessage(err, t);
			}
		},
		[dispatch, user, authPut, t],
	);

	const remove = useCallback(
		async (id, onSuccess = noop, onFailure = noop) => {
			const loading = toast("deleting-resouce", {
				autoClose: false,
			});

			try {
				await authDelete(`/users/${user?.id}/time-off/${id}`);
				dispatch({ type: "delete", payload: id });
				onSuccess(id);
				toast.dismiss(loading);
				toast.success(t("resource-deleted"));
			} catch (err) {
				onFailure(err);
				toast.dismiss(loading);
				getErrorMessage(err, t);
			}
		},
		[dispatch, user, authDelete],
	);

	return {
		state,
		fetch,
		create,
		update,
		remove,
		goToClone,
		goToCreate,
		goToEdit,
		goToView,
		selected,
	};
};
