import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api"
import { getToastErrorMessage } from "../../../utils/helpers/errors";
import { formatTimeSheetParams, getFilterValue } from "./helpers";

export const useExportTimeSheets = () => {
    const { t } = useTranslation();
    const { call } = useApi();

    const downloadCall = useCallback((url, { filters, ...params }) => {
        const formattedFilters = (filters || []).reduce((total, filter) => {
            const { name } = filter;
            if (name === "") {
                return total;
            }
            return {
                ...total,
                [name]: getFilterValue(filter),
            };
        }, {});

        return call({
            url,
            params: {
                ...formatTimeSheetParams(params),
                filters: JSON.stringify(formattedFilters),
            },
            headers: {
                "Content-Type": "Content-Disposition",
            },
            responseType: "arraybuffer",
        }, true);
    }, [call]);

    const downloadFile = useCallback((response) => {
        const header = response.headers["content-disposition"];
        const parts = header.split(";");
        const filename = parts[1].split("=")[1];
        const downloadLink = document.createElement("a");
        const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const docUrl = URL.createObjectURL(blob);
        downloadLink.href = docUrl;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }, []);

    const downloadMySummary = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-summary"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/my/summary",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("summary-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadTeamSummary = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-summary"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/team/summary",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("summary-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadMyWeeklySummary = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-summary"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/my-weekly/summary",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("summary-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadWeeklySummary = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-summary"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/weekly/summary",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("summary-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadMyDetails = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-details"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/my/details",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("details-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadTeamDetails = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-details"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/team/details",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("details-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadMyWeeklyDetails = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-details"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/my-weekly/details",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("details-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    const downloadWeeklyDetails = useCallback(
        async (filters, onSuccess, onFailure) => {
            const loading = toast.loading(t("downloading-details"));
            try {
                const response = await downloadCall(
                    "/export/time-sheet/weekly/details",
                    filters,
                );
                downloadFile(response);
                toast.dismiss(loading);
                toast.success(t("details-downloaded-successfully"));
                onSuccess?.(response);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(getToastErrorMessage(err));
                onFailure?.(err);
            }
        },
        [t, downloadCall, downloadFile],
    );

    return {
        downloadMySummary,
        downloadTeamSummary,
        downloadMyWeeklySummary,
        downloadWeeklySummary,
        downloadMyDetails,
        downloadTeamDetails,
        downloadMyWeeklyDetails,
        downloadWeeklyDetails,
    };
}
