import { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import Button from "../../../../../components/Button";
import { useUser } from "../../../../../utils/hooks/user";
import { useClockIn } from "../../store";

const Actions = ({ loading }) => {
	const { t } = useTranslation();

	const [{ mode }, { setShiftAction }] = useClockIn();
	const user = useUser();

	const content = useMemo(() => {
		switch (mode) {
			case "shift": {
				return (
					<>
						<Button
							onClick={() => setShiftAction("start-break")}
							className="break-btn"
							hidden={
								!user?.calculationGroup?.breaks.manual.status
							}
							style={{ width: "33%" }}
							disabled={loading}
						>
							{t("take-break")}
						</Button>

						<Button
							onClick={() => setShiftAction("end-shift")}
							color="danger"
							className={classNames(
								"border-0",
								!user?.calculationGroup?.breaks.manual.status &&
								"w-50",
							)}
							style={{ width: "33%" }}
							disabled={loading}
						>
							{t("clock-out")}
						</Button>

						<Button
							onClick={() => setShiftAction("switch")}
							color="primary"
							className={classNames(
								"border-0",
								!user?.calculationGroup?.breaks.manual.status &&
								"w-50",
							)}
							style={{ width: "33%" }}
							disabled={loading}
						>
							{t("switch")}
						</Button>
					</>
				);
			}
			case "break": {
				return (
					<Button
						className="w-100 break-btn"
						onClick={() => setShiftAction("end-break")}
						disabled={loading}
					>
						{t("end-break")}
					</Button>
				);
			}
			case "end": {
				return (
					<Button
						className="w-100 bg-info border-0 text-white"
						onClick={() => setShiftAction("start-shift")}
						disabled={loading}
					>
						{t("start-new-shift")}
					</Button>
				);
			}
			default:
				return (
					<>
						<Button
							onClick={() => setShiftAction("start-break")}
							className="break-btn"
							hidden={
								!user?.calculationGroup?.breaks.manual.status
							}
							style={{ width: "33%" }}
							disabled={loading}
						>
							{t("take-break")}
						</Button>

						<Button
							onClick={() => setShiftAction("end-shift")}
							color="danger"
							className={classNames(
								"border-0",
								!user?.calculationGroup?.breaks.manual.status &&
								"w-50",
							)}
							style={{ width: "33%" }}
							disabled={loading}
						>
							{t("clock-out")}
						</Button>

						<Button
							color="primary"
							className={classNames(
								"border-0",
								!user?.calculationGroup?.breaks.manual.status &&
								"w-50",
							)}
							style={{ width: "33%" }}
							disabled={loading}
						>
							{t("switch")}
						</Button>
					</>
				);
		}
	}, [mode, t, user, setShiftAction]);

	return (
		<Row>
			<div className="col d-flex justify-content-between">{content}</div>
		</Row>
	);
};

export default Actions;
