import { useTranslation } from "react-i18next";
import classNames from "classnames";

function Topbar({ options, menu, setMenu }) {
    const { t } = useTranslation();

    return <div className="d-flex justify-content-start flex-wrap gap-2 px-4 py-3">
        {options.map((option) => <span
            className={classNames(menu === option
                ? "border border-primary text-white bg-primary font-weight-bold rounded px-3 py-2"
                : "border border-primary text-primary font-weight-bold rounded px-3 py-2",
                "cursor-pointer"
            )}
            onClick={() => setMenu(option)}
        >
            {t(option)}
        </span>)}
    </div>
}

export default Topbar;