import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFilters as useTimeSheetFilters } from "../../../../TimeSheet/Home/Actions/useFilters";

import { useEmployeeNumberFilter } from "../../../../TimeSheet/Team/Actions/useFilters/useEmployeeNumberFilter";
import { useProjectFilter } from "../../../../TimeSheet/Team/Actions/useFilters/useProjectFilter";
import { useUnionFilter } from "../../../../TimeSheet/Team/Actions/useFilters/useUnionFilter";
import { useCalculationGroupFilter } from "../../../../TimeSheet/Team/Actions/useFilters/useCalculationGroupFilter";

export const useFilters = () => {
    const { t } = useTranslation();

    const employeeNumberFilter = useEmployeeNumberFilter();
    const timeSheetFilters = useTimeSheetFilters();

    const levelFilters = useMemo(() => {
        return timeSheetFilters.filter(({ id }) => id.indexOf('level') === 0);
    }, [timeSheetFilters]);

    const otherTimesheetFilters = useMemo(() => {
        return timeSheetFilters.filter(({ id }) => !(id.indexOf('level') === 0));
    }, [timeSheetFilters])

    const projectFilter = useProjectFilter();
    const calculationGroupFilter = useCalculationGroupFilter();
    const unionFilter = useUnionFilter();

    return useMemo(() => [
        {
            Header: t("name"),
            accessor: "search",
            id: "search"
        },
        employeeNumberFilter,
        ...levelFilters,
        {
            id: "payGroup",
            Header: t("pay-group"),
            canFilter: true,
            filter: "resource-select",
            disableFilters: false,
        },
        projectFilter,
        calculationGroupFilter,
        unionFilter,
        ...otherTimesheetFilters,

    ], [
        employeeNumberFilter,
        levelFilters,
        projectFilter,
        calculationGroupFilter,
        unionFilter,
        otherTimesheetFilters,
        t
    ]);
}
