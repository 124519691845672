import {EventModel} from "@bryntum/schedulerpro";

export class ScheduleEventModel extends EventModel {
    static get fields() {
        return [
            'startDate',
            'endDate',
            { name: 'status', defaultValue: 'pending' },
            { name: 'resourceId', dataSource: 'user' },
            { name: 'oldResourceId', type: 'string'},
            { name: 'date', type: 'string'},
            { name: 'payCode', type: 'object' },
            { name: 'job', type: 'object' },
            { name: 'location', type: 'object' },
            { name: 'level1', type: 'object' },
            { name: 'level2', type: 'object' },
            { name: 'level3', type: 'object' },
            { name: 'level4', type: 'object' },
            { name: 'shifts', type: 'array' },
            { name: 'segments', type: 'array' },
            { name: 'breaks', type: 'array' },
            { name: 'durationUnit', defaultValue: 'second' },
            { name: 'duration', type: 'number' },
            { name: 'total', type: 'number' },
            { name: 'rate', type: 'number' },
            { name: 'timestamp', type: 'number' },
            { name: 'endTimestamp', type: 'number' },
            { name: 'locked', type: 'boolean'},
            { name: "eventType", type: "object" },
        ];
    }
}