import { createContext, useMemo, useContext } from "react";
import useSWR from "swr";
import { useCompany } from "../../utils/hooks/company";

export const EarningGroupsContext = createContext();

export const EarningGroupsProvider = ({ children }) => {
    const company = useCompany();

    const { data, isLoading } = useSWR(() => {
        if (!company) {
            return null;
        }
        return {
            url: "/earning-group",
            params: {
                pagination: "false",
                company: company.id,
            }
        }
    });

    const value = useMemo(
        () => ({ data: data?.result, isLoading }),
        [data?.result, isLoading],
    );

    return <EarningGroupsContext.Provider value={value}>
        {children}
    </EarningGroupsContext.Provider>
}

export const useEarningGroupsContext = () => {
    const context = useContext(EarningGroupsContext);
    if (context === undefined) {
        throw new Error("useEarningGroupsContext should be used within a provider");
    }
    return context;
}
