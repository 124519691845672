import FormElement from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Button from "../../../../components/Button";
import BaseSettingsFormContent from "../../../../components/Form/BaseSettingsFormContent";
import Field from "../../../../components/Field";
import Switch from "../../../../components/Inputs/Switch";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../helpers/useIsFieldDisabled";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.earningGroup");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			forSchedule: false,
			allowSegmentation: false,
			...values,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	const onFinish = useCallback((values) => {
		const data = {
			...values,
			allowSegmentation: typeof values?.allowSegmentation === "undefined" 
				? false
				: values?.allowSegmentation
		};
		submit(data);
	}, [submit]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<Field
					name="forSchedule"
					label={t("for-schedule")}
					valuePropName="checked"
				>
					<Switch />
				</Field>

				<BaseField shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const forSchedule = getFieldValue("forSchedule");

						return forSchedule ? (
							<Field
								name="allowSegmentation"
								label={t("allow-segmentation")}
								valuePropName="checked"
							>
								<Switch />
							</Field>
						) : null;
					}}
				</BaseField>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
