import Layout from "../Layout";
import Card from "../Layout/Card";
import DomainSelectForm from "./Form";

function DomainSelect() {
	return (
		<Layout
			image="/assets/images/landscape-1.jpg"
			text="No matter what people tell you, words and ideas can change the world."
			autor="Robin Williams"
		>
			<Card
				cardTitle="welcome-to-simplitime"
				cardText="enter-domain"
				cardFooter="dont-have-account"
				link={process.env.REACT_APP_ENABLE_SIGNUP === 'true' ? '/signup' : false}
				linkText="sign-up"
				cardBody={<DomainSelectForm />}
				className="text-center"
			/>
		</Layout>
	);
}

export default DomainSelect;
