import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
import Topbar from "./Topbar";
import { Bar } from "react-chartjs-2";
import Loading from "../../../../components/Loaders/Loading";
import { firstToUpper } from "../../../../utils/helpers/string";
import moment from "moment-timezone";

const options = {
	responsive: true,
	interaction: {
		mode: "index",
		intersect: false,
	},
	stacked: false,
	legend: {
		display: false,
	},
};

function Chart({
	data,
	visibleActual,
	visibleBudget,
	onFiltersChange,
	filters,
	setFilters,
	loading,
	group
}) {
	const { t } = useTranslation();

	const isAmount = useMemo(() => {
		return filters.type === "amount";
	}, [filters.type])

	const actualDataset = useMemo(
		() => {
			return data
				?.filter((d) => d.name === "Actual")
				?.map((item) => {
          if (isAmount) {
            return item.amount;
          }
          return Math.floor(moment.duration(item.hours, "s").asHours());
        });
		}, [data, isAmount],
	);

	const budgetDataSet = useMemo(
		() => {
			return data
				?.filter((d) => d.name === "Budget")
				?.map((item) => {
          if (isAmount) {
            return item.amount;
          }
          return Math.floor(moment.duration(item.hours, "s").asHours());
        });
		}, [data, isAmount],
	);

	const labels = useMemo(() => {
		const base = [];
		data?.forEach((item) => base.push(item.date));
		return base?.filter((item, i, array) => array?.indexOf(item) === i);
	}, [data]);

	const chartData = useMemo(
		() => ({
			labels,
			datasets: [
				{
					label: t("actual"),
					data: actualDataset,
					backgroundColor: "#010a63",
					hidden: visibleActual,
				},
				{
					label: isAmount ? t("budget") : firstToUpper(t("hours")),
					data: budgetDataSet,
					backgroundColor: isAmount ? "black": "#5428e0",
					hidden: visibleBudget,
				},
			],
		}), [
			isAmount, 
			labels, 
			actualDataset, 
			budgetDataSet, 
			visibleActual, 
			visibleBudget, 
			t
		],
	);

	return (
		<Card className="h-100">
			<CardHeader className="py-3">
				<Topbar
					onFiltersChange={onFiltersChange}
					setFilters={setFilters}
					filters={filters}
					group={group}
				/>
			</CardHeader>

			<CardBody>
				{loading ? (
					<div className="w-100 text-center">
						<Loading />
					</div>
				) : (
					<Bar data={chartData} options={options} height="100" />
				)}
			</CardBody>
		</Card>
	);
}

export default Chart;
