import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const { code, status } = useCommonColumns();

	return useMemo(() => {
		const columns = [
			code,
			columnHelper.accessor("externalCompanyId", {
				enableColumnFilter: true,
				header: t("external-company-id"),
			}),
			columnHelper.accessor("externalCompanyCode", {
				enableColumnFilter: true,
				header: t("external-company-code"),
			}),
			columnHelper.accessor("name", {
				enableColumnFilter: true,
				header: t("name"),
			}),
			columnHelper.accessor("settings.language", {
				header: t("language"),
				cell: (info) => t(info.getValue()),
			}),
			columnHelper.accessor("settings.timezone.text", {
				header: t("timezone"),
			}),
			columnHelper.accessor("settings.timeFormat", {
				header: t("time-format"),
			}),
			columnHelper.accessor("settings.dateFormat", {
				header: t("date-format"),
			}),
			columnHelper.accessor("settings.startDay", {
				header: t("start-day"),
			}),
			columnHelper.accessor("settings.currency", {
				header: t("currency"),
				cell: (info) => t(info.getValue()),
			}),
			status,
		];
		if (isMasterCompany && (edit || remove || clone)) {
			const column = columnHelper.display({
				id: "actions",
				enableHiding: false,
				enableColumnFilter: false,
				header: (
					<div className="text-right">
						{t("actions")}
					</div>
				),
				cell: (info) => {
					const { id, isDefault } = info.row.original;

					return (
                        <div className="actions text-primary text-right">
                            {!isDefault && edit && (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                    onClick={() => edit(id)}
                                    title={t("Edit")}
                                >
                                    <i className="fas fa-pen" />
                                </span>
                            )}

                            {!isDefault && remove && (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                    onClick={() => remove(id)}
                                    title={t("Delete")}
                                >
                                    <i className="fas fa-trash" />
                                </span>
                            )}

                            {clone && (
                                <span
                                    className="btn-inner--icon icon-sm cursor-pointer"
                                    onClick={() => clone(id)}
                                    title={t("Clone")}
                                >
                                    <i className="ni ni-single-copy-04" />
                                </span>
                            )}
                        </div>
                    );
				},
			});
			columns.push(column);
		}
		return columns;
	}, [t, isMasterCompany, code, status, edit, clone, remove]);
};
