import { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Select from "../../../../../../components/Inputs/Select";
import Field from "../../../../../../components/Field";
import { getUploadUrl } from "../../../../../../utils/helpers/upload";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const languages = ["es", "sq", "en"];

function UploadInput({ language, setLanguage, selectedImage, setSelectedImage }) {
    const { t, i18n } = useTranslation();
    const fileInputRef = useRef(null);

    const handleSelectedImage = useCallback(
        (e) => {
            setSelectedImage((prev) => {
                const prevItemsNames = selectedImage?.[language]
                    ? selectedImage[language]?.map(({ name }) => name)
                    : [];

                const newImages = Object.values(e.target.files).filter(
                    (img) => !prevItemsNames?.includes(img?.name)
                );
                const img = prev?.[language]
                    ? [...prev[language], ...newImages]
                    : newImages;
                return {
                    ...prev,
                    [language]: img,
                };
            });
        },
        [setSelectedImage, selectedImage, language]
    );

    const handleDelete = useCallback((index) => {
        setSelectedImage((prev) => {
            const updatedImages = prev[language]
                .filter((_, i) => i + language !== index);
            return {
                [language]: updatedImages,
            };
        });
    }, [setSelectedImage, language]);

    const handleImageUploadClick = useCallback(() => {
        fileInputRef.current.click();
    }, [fileInputRef]);

    return (
        <>
            <Field name="languages" label={t("languages")}>
                <Select mode="multiple">
                    {languages.map((value) => (
                        <Select.Option
                            key={value}
                            value={value}
                            disabled={value === i18n.language}
                        >
                            {t(value)}
                        </Select.Option>
                    ))}
                </Select>
            </Field>


            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const selectedLanguages = getFieldValue("languages");

                    return (
                        <>
                            <div className="mb-4">
                                {selectedLanguages?.map((value) => (
                                    <span
                                        className="px-3 py-1 cursor-pointer"
                                        style={{
                                            borderBottom:
                                                value === language
                                                    ? "2px solid #010a63"
                                                    : "1px solid #e9ecef",
                                        }}
                                        onClick={() => setLanguage(value)}
                                    >
                                        {t(value)}
                                    </span>
                                ))}
                            </div>

                            <div className="d-flex slider">
                                {selectedImage?.[language] &&
                                    Object.values(selectedImage?.[language]).map((img, index) => {
                                        let url = "";
                                        if (typeof img === "string") {
                                            url = getUploadUrl(img);
                                        } else {
                                            url = URL.createObjectURL(img);
                                        }

                                        return (
                                            <div className="position-relative" key={index + language}>
                                                <div
                                                    className="position-absolute right-0 m-4 cursor-pointer"
                                                    onClick={() => handleDelete(index + language)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        style={{ fontSize: "17px" }}
                                                        className="text-red"
                                                    />
                                                </div>
                                                <img src={url} alt="" className="slide" />
                                            </div>
                                        );
                                    })}

                                <div
                                    className="slide cursor-pointer"
                                    onClick={handleImageUploadClick}
                                >
                                    <span className="text-white">+</span>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        multiple
                                        onChange={handleSelectedImage}
                                    />
                                </div>
                            </div>
                        </>
                    );
                }}
            </BaseField>
        </>
    );
}

export default UploadInput;
