import { useTranslation } from "react-i18next";
import { List as BaseList, Field } from "rc-field-form";
import Row from "./Row";
import WarningModal from "./WarningModal";
import { useVisible } from "../../../../../../../../utils/hooks/useVisible";

function List({ 
    shiftName, 
    form, 
    resource, 
    disabled, 
    openModal,
    defaultSegment, 
    eventRecord,
    removeShift
}) {
    const { t } = useTranslation();
    const {
        visible,
        open: openWarningModal,
        close: closeWarningModal,
        selected: selectedShift
    } = useVisible();

    return (
        <BaseList name={shiftName}>
            {(data, { add }) => {
                return data?.map(({ name }) => {
                    const errors = form.getFieldsError([
                        ["shifts", shiftName, name, "type"],
                        ["shifts", shiftName, name, "start"],
                        ["shifts", shiftName, name, "end"],
                        ["shifts", shiftName, name, "payCode"],
                        ["shifts", shiftName, name, "level1"],
                        ["shifts", shiftName, name, "level2"],
                        ["shifts", shiftName, name, "level3"],
                        ["shifts", shiftName, name, "level4"],
                        ["shifts", shiftName, name, "job"],
                        ["shifts", shiftName, name, "location"],
                    ])?.map(({ errors }) => errors)?.flat();

                    return (
                        <>
                            <Row 
                                form={form}
                                name={name} 
                                shiftName={shiftName} 
                                resource={resource}
                                disabled={disabled}
                                openModal={openModal}
                                add={add}
                                defaultSegment={defaultSegment}
                                eventRecord={eventRecord}
                                openWarningModal={openWarningModal}
                                removeShift={removeShift}
                            />

                            <Field 
                                name={[name, "job"]} 
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-job")
                                    }
                                ]}
                            />

                            <Field 
                                name={[name, "location"]} 
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-location")
                                    }
                                ]}
                            />

                            <Field name={["shifts", shiftName, name, "type"]} />

                            {errors?.[0] && (
                                <tr className="text-danger">
                                    <td colSpan={7}>
                                        {errors[0]}
                                    </td>
                                 </tr>
                            )}

                            {visible && (
                                <WarningModal
                                    isOpen={visible}
                                    close={closeWarningModal}
                                    selected={selectedShift}
                                    form={form}
                                    resource={resource}
                                    defaultSegment={defaultSegment}
                                />
                            )}
                        </>
                    )
                })
            } }
        </BaseList>
    )
}

export default List;
