import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import moment from "moment-timezone";
import Text from "../Text";
import { checkTime } from "./checkTimes";
import { useCompanyTimeFormat } from "../../../utils/hooks/company";
import { dateTimeFormat } from "../../../utils/helpers/date";
import "./style.scss";

const setToLocalTz = (date) => {
	return moment.parseZone(date).tz(moment.tz.guess(), true);
}

function CustomTimeInput({
	timeFormat,
	value,
	onChange,
	disabled,
	className,
	placeholder,
	...props
}) {
	const { t } = useTranslation();
	placeholder = placeholder || t("time");
	const [time, setTime] = useState("");

	const baseFormat = useCompanyTimeFormat();
	const format = timeFormat || baseFormat;

	const onBlur = useCallback(
		(e) => {
			if (time === value ? dateTimeFormat(value, format) : "") {
				return;
			}

			var newvalue = e.target.value.replace(/\s/g, "").toLowerCase();
			var timeObj = checkTime(newvalue);

			setTime(typeof timeObj === "object" ? timeObj.format(format) : "");

			if (setToLocalTz(checkTime(newvalue)).isSame(setToLocalTz(value))) {
				return;
			}
			onChange(checkTime(newvalue));
		},
		[setTime, onChange, format, value, time],
	);

	const onInputChange = useCallback(
		(e) => setTime(e.target.value),
		[setTime],
	);

	useEffect(() => {
		setTime(value ? dateTimeFormat(value, format) : "");
	}, [value, setTime, format]);

	return (
		<Text
			value={time}
			placeholder={placeholder}
			className={classnames(className, "custom-time-input")}
			disabled={disabled}
			onBlur={onBlur}
			onChange={onInputChange}
			onKeyPress={(e) => {
				if (e.key === "Enter") {
					onBlur(e);
				}
			}}
			onFocus={(e) => {
				e.target.select();
			}}
			maxLength={11}
			{...props}
		/>
	);
}
export default CustomTimeInput;
