import { Row, Col, Container } from "reactstrap";
import "./style.scss";

function Layout({ children, image, text, autor }) {
	return (
		<Row className="layout justify-content-center h-100 m-0">
			<Col
				xl={6}
				lg={5}
				md={6}
				className="d-flex flex-column mx-lg-0 mx-auto"
			>
				<Container>
					<div className="header-body text-center mb-7 mt-4">
						<Row className="justify-content-center">
							<Col className="px-5" lg="6" md="8" xl="5">
								<div className="d-flex justify-content-center sticky-top">
									<img
										src="/assets/images/logo-horizontal.png"
										alt=""
										width="250"
									/>
								</div>
							</Col>
						</Row>
					</div>
				</Container>

				{children}
			</Col>

			<Col
				xl={6}
				lg={5}
				md={6}
				className="col-6 d-lg-flex d-none h-100 my-auto pe-0 text-center justify-content-center flex-column"
			>
				<div
					className="position-relative h-100 m-3 px-7 d-flex flex-column justify-content-center overflow-hidden image"
					style={{
						backgroundImage: `linear-gradient(87deg, rgba(94, 114, 228, 0.52), rgba(130, 94, 228, 0.73)), url(${image})`,
						backgroundSize: "cover",
					}}
				>
					<h4 className="mt-5 text-white font-weight-bolder position-relative text-center">
						"{text}"
					</h4>

					<p className="text-white position-relative text-center">
						- {autor}
					</p>
				</div>
			</Col>
		</Row>
	);
}

export default Layout;
