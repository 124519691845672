import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { getSettingsOrgResourceSearchFilters, getSettingsResourceSearchFilters, renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { useCalculationGroupFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useCalculationGroupFilter";
import { useEmployeeNumberFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useEmployeeNumberFilter";
import { useEmployeeTypeFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useEmployeeTypeFilter";
import { useFirstNameFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useFirstNameFilter";
import { useLastNameFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useLastNameFilter";
import { useProjectFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useProjectFilter";
import { useUnionFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useUnionFilter";
import { useUserGroupFilter } from "../../../../TimeSheet/Weekly/Actions/useFilters/useUserGroupFilter";
import { useGroups } from "../../../General/OrganisationLevelGroups/context";

export const useFilters = () => {
  const { t } = useTranslation();

  const { groups } = useGroups();

  const employeeNumberFilter = useEmployeeNumberFilter();
  const firstNameFilter = useFirstNameFilter();
  const lastNameFilter = useLastNameFilter();
  const employeeTypeFilter = useEmployeeTypeFilter();
  const projectFilter = useProjectFilter();
  const calculationGroupFilter = useCalculationGroupFilter();
  const unionFilter = useUnionFilter();
  const userGroupFilter = useUserGroupFilter();

  return useMemo(
    () => [
      employeeNumberFilter,
      firstNameFilter,
      lastNameFilter,
      {
        id: "payGroup",
        Header: t("pay-group"),
        canFilter: true,
        filter: "resource-select",
        Filter: (
          <ResourceSelect
            mode="multiple"
            resourcePath="/pay-group?pagination=off"
            renderLabel={renderSettingsResourceLabel}
            valuePropName="id"
            getSearchFilters={getSettingsResourceSearchFilters}
            hasSearch
          />
        ),
      },
      employeeTypeFilter,
      projectFilter,
      calculationGroupFilter,
      unionFilter,
      userGroupFilter,
      ...groups.map((group) => {
        const level = group.level.replace("_", "");
        return {
          id: `${level}Home`,
          Header: `${renderSettingsResourceLabel(group)} (Home)`,
          canFilter: true,
          filter: "resource-select",
          Filter: (
            <ResourceSelect
              mode="multiple"
              resourcePath={`/${group.id}/org-levels`}
              renderLabel={renderOrgLevelResourceLabel}
              valuePropName="id"
              getSearchFilters={getSettingsOrgResourceSearchFilters}
              hasSearch
            />
          ),
        };
      }),
    ],
    [
      t,
      userGroupFilter,
      employeeNumberFilter,
      firstNameFilter,
      lastNameFilter,
      employeeTypeFilter,
      projectFilter,
      calculationGroupFilter,
      unionFilter,
    ],
  );
};
