export const getSalaryFromHourly = (hourly) => hourly * 40 * 52;

export const getHourlyFromSalary = (salary) => salary / 52 / 40;

export const getJobRates = ({ hourlyRate, hourlyOrSalaried, salary }) => {
	switch (hourlyOrSalaried) {
		case "H": {
			return {
				hourlyOrSalaried,
				salary: getSalaryFromHourly(hourlyRate),
				hourlyRate: hourlyRate,
			};
		}
		case "S": {
			return {
				hourlyOrSalaried,
				salary: salary,
				hourlyRate: getHourlyFromSalary(salary),
			};
		}
		default:
			return;
	}
};

export const renderCurrencySymbol = (currency) => {
	switch (currency) {
		case "eur":
			return "€";
		case "gbp":
			return "£";
		case "cad":
		case "usd":
			return "$";
		case "chf":
			return "CHF";
		default:
			return "$";
	}
};


export const renderPaymentType = (hourlyOrSalaried) => {
	switch (hourlyOrSalaried) {
		case "H":
			return "hourly";
		case "S":
			return "salaried";
		default:
			return "";
	}
};
