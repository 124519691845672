import { formatParams } from "../../../utils/api/format";
import { formatDateRange } from "../../../utils/helpers/api";

export const getFilterValue = ({ name, value }) => {
	if (name === "payCode") {
		if (Array.isArray(value)) {
			return value.map(({ code }) => code);
		} else {
			return value?.code;
		}
	}
	if (Array.isArray(value)) {
		return value.map((item) => {
			if (typeof item === "object" && item !== null) {
				return item.id;
			} else {
				return item;
			}
		});
	} else if (typeof value === "object" && value !== null) {
		return value.id;
	}
	return value;
};

export const formatTimeSheetParams = ({ from, to, filters, ...params }) => {
	const formattedFilters = (filters || []).reduce((total, filter) => {
		const { name, value } = filter;
		if (name === "" || value === "") {
			return total;
		}
		return {
			...total,
			[name]: getFilterValue(filter),
		};
	}, {});
	return {
		...formatDateRange(from, to),
		...formatParams({
			...formattedFilters,
			...params,
		}),
	};
};
