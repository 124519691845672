import BulkActions from "./BulkActions";
import ColumnToggle from "./ColumnToggle";
import Filters from "../../../../components/Table/Actions/Filters";
import { useTableContext } from "../../../../components/Table/context";
import { useFilters as useFiltersConfig } from "../hooks/useFIlters";
import { useFilters } from "../../filtersContext";
import { useConfigFilterColumns } from "../../../Settings/Configuration/hooks/useConfigFilterColumns";

function Actions() {
	const [filters, setFilters] = useFilters();
	const { columns } = useTableContext();

	const filterColumns = useConfigFilterColumns(
		"timesheetManagerView",
		useFiltersConfig(),
	);

	return (
		<div className="d-flex justify-content-between align-items-center mb-2">
			<div>
				<BulkActions />
			</div>

			<div>
				<Filters
					columns={filterColumns}
					filters={filters}
					setFilters={setFilters}
				/>

				<ColumnToggle prefix="users-table" columns={columns} />
			</div>
		</div>
	);
}

export default Actions;
