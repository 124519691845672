import { useMemo } from "react";

export const useIsFieldDisabled = ({ mode, canCreate, canEdit }) => {
	return useMemo(() => {
		if (mode === "edit" && !canEdit) {
			return true;
		} else if ((mode === "create" || mode === "clone") && !canCreate) {
			return true;
		} else if (mode === "view") {
			return true;
		}
		return false;
	}, [mode, canEdit, canCreate]);
};
