import { useState } from "react";
import useSWR from "swr";
import useApi from "../../../utils/api";
import { useCompanyId } from "../../../utils/hooks/company";

export const useAllPayGroups = () => {
	const companyId = useCompanyId();
	const {authGet} = useApi();
	const [hasFetchedPayGroups, setHasFetchedPayGroups] = useState(false);
	const {data: payGroups} = useSWR(`/pay-group?company=${companyId}&pagination=false`, async (key) => {
		const response = await authGet(key);
		setHasFetchedPayGroups(true);
		return response.result;
	}, { fallbackData: [] });

	return { payGroups, hasFetchedPayGroups };
}
