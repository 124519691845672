import { useMemo } from "react";

export const useGetFooterData = (data) => {
	return useMemo(() =>
		Object.values(
			data?.reduce((all, { hourType, payCode, duration, total }) => {
				const key = `${hourType}-${payCode}`;
				if (!all[key]) {
					all[key] = { hourType, payCode, duration: 0, total: 0 };
				}
				all[key].duration += duration;
				all[key].total += total;
				return all;
			}, {}),
		),
	);
};
