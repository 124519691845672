import { useEffect, useCallback } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import Field from "../../../../components/Field";
import Layout from "../Layout";
import Content from "../Layout/Content";
import "react-phone-input-2/lib/style.css";

function Step3({ submitPhoneNumber, skip, values, goBack, loading, step }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const onFinish = useCallback(
		(value) => {
			submitPhoneNumber(value);
		},
		[submitPhoneNumber]
	);

	useEffect(() => {
		form.setFieldsValue({ phone: values?.phone });
	}, [form, values]);

	return (
		<Layout step={step}>
			<Content
				cardTitle="mobile-number"
				cardText="mobile-number-description"
				inialValues={values}
				goBack={goBack}
				skip={skip}
				onFinish={onFinish}
				loading={loading}
				step={step}
				form={form}
			>
				<Field name="phone" label={t("phone-number")}>
					<PhoneInput
						country="us"
						enableSearch
						inputClass="pl-5 w-100"
					/>
				</Field>
			</Content>
		</Layout>
	);
}

export default Step3;
