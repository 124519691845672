import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { List } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../components/Field";
import Button from "../../../../../../../components/Button";
import Tag from "../../../../../../../components/Inputs/TagInput/Tag";

function EmailsInput({
	name,
	label,
	buttonLabel,
	form,
	rules,
	disabled,
	...props
}) {
	const { t } = useTranslation();
	const inputRef = useRef();
	const [inputValue, setInputValue] = useState("");
	const [error, setError] = useState(null);
	const [inputVisible, setInputVisible] = useState(false);

	const getDataFromForm = useCallback(
		() => form.getFieldValue(name),
		[form, name],
	);
	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};
	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	return (
		<List name={name} rules={rules}>
			{(data, { add, remove }) => {
				if (
					inputValue &&
					!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i.test(
						String(inputValue).toLocaleLowerCase(),
					)
				) {
					setError(t("not-valid-email"));
				} else {
					setError(null);
				}

				const handleInputConfirm = () => {
					const allItems = getDataFromForm();
					if (
						!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i.test(
							String(inputValue).toLocaleLowerCase(),
						)
					) {
						setError(t("not-valid-email"));
					} else if (
						inputValue &&
						(allItems || []).indexOf(inputValue) === -1
					) {
						add(inputValue);
						setError(null);
					}
					setInputVisible(false);
					setInputValue("");
				};

				return (
					<div className="mb-3">
						<Field
							label={label}
							name="email"
							rules={
								(!getDataFromForm() ||
									getDataFromForm()?.length === 0) && [
									{
										required: true,
										message: t("required-emails"),
									},
								]
							}
						>
							<>
								{inputVisible && (
									<Input
										ref={inputRef}
										readOnly={disabled}
										type="email"
										onChange={handleInputChange}
										onBlur={handleInputConfirm}
										onPressEnter={handleInputConfirm}
										value={inputValue}
										{...props}
										style={{
											border: error
												? "1px solid #fb6340"
												: "1px solid #dee2e6",
										}}
										disabled={disabled}
									/>
								)}

								<div>
									{!inputVisible && (
										<Button
											color="primary"
											onClick={
												!disabled
													? showInput
													: undefined
											}
											className="d-flex align-items-center"
										>
											<FontAwesomeIcon
												icon={faPlus}
												className="mr-1"
											/>
											{t("new-button", { buttonLabel })}
										</Button>
									)}
								</div>

								<div className="invalid-feedback d-block">
									{error}
								</div>
							</>
						</Field>

						<div className="tag-input">
							{data?.map(({ key, name }) => {
								const allItems = getDataFromForm();

								if (allItems) {
									return (
										<Tag
											key={key}
											remove={
												!disabled
													? () => remove(name)
													: undefined
											}
											item={allItems[name]}
										/>
									);
								}
								return null;
							})}
						</div>
					</div>
				);
			}}
		</List>
	);
}

export default EmailsInput;
