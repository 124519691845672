import { useMemo } from "react";

export const useAllowedColumns = (columns) => {
    return useMemo(() => {
        return columns.filter((column) => {
            if (["selection", "actions"].includes(column.id)) {
                return false;
            }
            if (column.hideable === false) {
                return false;
            }
            return true;
        });
    }, [columns]);
}