import { useEffect, useCallback, useMemo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	faCircleCheck,
	faCircleXmark,
	faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form, { List, Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import Button from "../../../components/Button";
import Field from "../../../components/Field";
import Select from "../../../components/Inputs/Select";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Break from "./Break";
import TimeInputGroup from "./TimeInputGroup";
import PaidHours from "./PaidHours";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { dateTimeParseZone } from "../../../utils/helpers/date";
import {
	renderSettingsResourceLabel,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../utils/helpers/settings";
import {
	useUser,
	useUserJobs,
	useUserLocations,
} from "../../../utils/hooks/user";

const AddShiftForm = ({
	onClose,
	onFinish,
	values,
	loading,
	error,
	isTimesheetRequest = false,
	mode
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const user = useUser();
	const [selectedLocation, setSelectedLocation] = useState(
		values?.data?.location?.id,
	);

	const locations = useUserLocations();
	const jobs = useUserJobs();
	const { groups } = useContext(OrgLevelGroupsContext);

	const userLevels = useMemo(
		() => [user?.level1, user?.level2, user?.level3, user?.level4],
		[user],
	);

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const userUseInClockInGroup = useMemo(
		() =>
			userLevels?.find(
				(level) => level?.orgLevelGroup === useInClockInGroup?.id,
			),
		[userLevels, useInClockInGroup],
	);

	const clearBreakErrors = useCallback(
		(shiftBreaks) => {
			form.setFields(
				shiftBreaks
					.map(({ name }) => [
						{
							name: ["breaks", name, "start"],
							errors: [],
						},
						{
							name: ["breaks", name, "end"],
							errors: [],
						},
					])
					.flat(),
			);
		},
		[form],
	);

	const onLocationChange = useCallback(
		(value) => {
			setSelectedLocation(value);
		},
		[setSelectedLocation],
	);

	const groupUrl = useMemo(() => {
		const query = new URLSearchParams();

		if (selectedLocation) {
			const locationId = locations?.find(
				({ id }) => id === selectedLocation,
			)?.locationId;

			query.append("locationId", locationId);
		}

		return `/${useInClockInGroup?.id}/org-levels?${query.toString()}`;
	}, [useInClockInGroup, selectedLocation, locations]);

	useEffect(() => {
		if (!values) {
			return;
		}

		const data = values["data"];
		const breaks = [];

		data?.clocks?.forEach(({ mode, type, time }, i, clocks) => {
			if (mode !== "START" || type !== "BREAK") {
				return;
			}
			const next = clocks[i + 1];

			breaks.push({
				date: dateTimeParseZone(time, "date"),
				start: dateTimeParseZone(time, "time"),
				end: dateTimeParseZone(next.time, "time"),
			});
		});

		form.setFieldsValue({
			location: data?.location?.id,
			job: data?.job?.id,
			project:
				!isTimesheetRequest && data?.project?.id
					? data.project
					: userUseInClockInGroup,
			start: {
				date:
					data?.startDate &&
					dateTimeParseZone(data.startDate, "date"),
				time:
					data?.startDate &&
					dateTimeParseZone(data.startDate, "time"),
			},
			end: {
				date: data?.endDate && dateTimeParseZone(data.endDate, "date"),
				time: data?.endDate && dateTimeParseZone(data.endDate, "time"),
			},
			breaks,
			note: data.note,
		});
	}, [form, values, userUseInClockInGroup, isTimesheetRequest]);

	useEffect(() => {
		let _error;
		if (typeof error === "object" && error !== null) {
			_error = {
				...error,
				"start.time": error.start,
				"end.time": error.end,
			};
		} else {
			_error = error;
		}
		const fieldErrors = generateErrorsConfigForForm(
			["location", "job", "start", "end"],
			_error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form className="h-100" form={form} onFinish={onFinish}>
			<div style={{ height: "calc(100% - 70px)" }}>
				<div 
					className="p-4"
					style={{ 
						height: "calc(100% - 60px)", 
						overflowY: "auto", 
						overflowX: "hidden" 
					}}
				>
					<div className="content mb-3">
						<div>
							<Field
								name="location"
								label={t("location")}
								rules={[
									{
										required: true,
										message: t("required-location"),
									},
								]}
							>
								<Select placeholder={t("location")} disabled={!!mode}>
									{!locations.find((loc) => loc.id === values?.data?.location?.id) && (
										<Select.Option value={values.data.location.id}>
											{renderSettingsResourceLabel(values.data.location)}
										</Select.Option>
									)}
									{locations.map((location) => (
										<Select.Option
											key={location.id}
											onChange={onLocationChange}
											value={location.id}
										>
											{renderSettingsResourceLabel(location)}
										</Select.Option>
									))}
								</Select>
							</Field>

							<Field
								name="job"
								label={t("job")}
								rules={[
									{ required: true, message: t("required-job") },
								]}
							>
								<Select placeholder={t("job")} disabled={!!mode}>
									{!jobs.find((job) => job.id === values?.data?.job?.id) && (
										<Select.Option value={values.data.job.id}>
											{renderSettingsResourceLabel(values.data.job)}
										</Select.Option>
									)}
									{jobs.map((job) => (
										<Select.Option key={job.id}>
											{renderSettingsResourceLabel(job)}
										</Select.Option>
									))}
								</Select>
							</Field>

							{useInClockInGroup && !isTimesheetRequest && (
								<Field
									name="project"
									label={useInClockInGroup.description}
									rules={[
										{
											required: useInClockInGroup.isRequired,
											message: t("required-group", {
												group: useInClockInGroup.description
											}),
										},
									]}
								>
									<ResourceSelect
										className="w-44"
										labelPropName="description"
										resourcePath={groupUrl}
										renderLabel={renderOrgLevelResourceLabel}
										getSearchFilters={
											getSettingsOrgResourceSearchFilters
										}
										valuePropName="id"
										hasSearch
										placeholder={useInClockInGroup.description}
										disabled={!selectedLocation || !!mode}
									/>
								</Field>
							)}

							<div className="mb-4">
								<TimeInputGroup
									name="start"
									label={t("starts")}
									form={form}
									disabled={!!mode}
								/>
							</div>

							<BaseField shouldUpdate>
								{({ }, { }, { getFieldsError }) => {
									const errors = getFieldsError([
										["start", "date"],
										["start", "time"],
									]);
									const error = errors[0]?.errors[0]
										? errors[0]?.errors[0]
										: errors[1]?.errors[0];

									return (
										error && (
											<div className="invalid-feedback d-block">
												{error}
											</div>
										)
									);
								}}
							</BaseField>

							<div className="mb-4">
								<TimeInputGroup
									name="end"
									label={t("ends")}
									form={form}
									disabled={!!mode}
								/>
							</div>

							<BaseField shouldUpdate>
								{({ }, { }, { getFieldsError }) => {
									const errors = getFieldsError([
										["end", "date"],
										["end", "time"],
									]);

									const error = errors[0]?.errors[0]
										? errors[0]?.errors[0]
										: errors[1]?.errors[0];

									return (
										error && (
											<div className="invalid-feedback d-block">
												{error}
											</div>
										)
									);
								}}
							</BaseField>
						</div>

						<div>
							<Field
								name="note"
								label={t("attach-request-note")}
								className="h-75"
							>
								<Input
									id="exampleFormControlTextarea3"
									resize="none"
									type="textarea"
									className="h-100 "
									disabled={!!mode}
								/>
							</Field>
							<footer className="note-border  p-2 border-top-0 text-sm">
								*{t(
									"requests-send-for-manager-approval"
								)}
							</footer>
						</div>
					</div>

					<div className="my-3 pb-2">
						<List name="breaks" disabled={!!mode}>
							{(fields, { add, remove }) => (
								<div>
									<div className="mb-4">
										<Button
											className="btn-icon  mt-2 text-primary p-0 shadow-none"
											type="button"
											color="white"
											onClick={() => add({}, 0)}
											disabled={!!mode}
										>
											<FontAwesomeIcon icon={faPlus} />

											<span className="btn-inner--text">
												{t("add-another-break")}
											</span>
										</Button>
									</div>

									{fields.map(({ name, key }) => (
										<div className="d-flex mb-4" key={key}>
											<Break
												name={name}
												add={!!mode ? undefined : () =>{
													clearBreakErrors(fields);
													add({}, name + 1);
												}}
												remove={() => remove(name)}
												form={form}
											/>
										</div>
									))}
								</div>
							)}
						</List>
					</div>

				</div>
				<div className="border-top px-4 py-3">
					<PaidHours />
				</div>
			</div>

			{!mode && <div className="border-top pt-3 text-center align-items-center justify-content-center">
				<Button
					className="btn-icon text-muted"
					type="button"
					color="white"
					onClick={onClose}
					loading={loading}
				>
					<span className="btn-inner--icon">
						<FontAwesomeIcon icon={faCircleXmark} />
					</span>
					<span className="btn-inner--text">{t("cancel")}</span>
				</Button>

				<Button
					className="btn-icon text-info"
					type="submit"
					color="white"
					disabled={loading}
				>
					<span className="btn-inner--icon">
						<FontAwesomeIcon icon={faCircleCheck} />
					</span>

					<span className="btn-inner--text">
						{t("send-for-approval")}
					</span>
				</Button>
			</div>}
		</Form>
	);
};

export default AddShiftForm;
