import { useMemo } from "react";
import { useActionWithAccess } from "../../../utils/hooks/access";

export const useActionWithRefetch = (call, { permission, onSuccess }) => {
    const callWithAccess = useActionWithAccess(call, permission);
    return useMemo(() => {
        if (!callWithAccess) {
            return null;
        }
        return (...args) => {
            return callWithAccess(...args, onSuccess);
        };
    }, [callWithAccess, onSuccess])
}
