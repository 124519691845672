import { useContext, useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import DateInput from "../../../../components/Inputs/DateInput";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
    useCompanyCurrency,
    useIsMasterCompany,
} from "../../../../utils/hooks/company";
import {
    renderSettingsResourceLabel,
    renderOrgLevelResourceLabel,
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import { currencyFormatter } from "../../../../utils/helpers/currencyFormatter";
import { months } from "./Form/Content";
import { createColumnHelper } from "@tanstack/react-table";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();
    const { groups } = useContext(OrgLevelGroupsContext);
    const currency = useCompanyCurrency();

    const { actions } = useCommonColumns({ edit, remove, clone });

    const budgetGroup = useMemo(
        () => groups?.find((value) => value.hasBudget),
        [groups],
    );

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("locationId", {
                enableColumnFilter: true,
                header: t("location"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && renderSettingsResourceLabel(value);
                },
                filterType: "resource-select",
                Filter: (
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath="/locations"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                        hasSearch
                        className="w-100"
                        mode="multiple"
                    />
                ),
            }),
            columnHelper.accessor("year", {
                enableColumnFilter: true,
                header: t("year"),
                filterType: "select",
                Filter: (
                    <DateInput
                        showYearPicker
                        value={moment().format("YYYY")}
                        dateFormat="yyyy"
                    />
                ),
            }),
            ...months.map((month) => {
                return columnHelper.accessor(`months.${month}`, {
                    header: t(month),
                    enableSorting: false,
                    cell: (info) => {
                        const value = info.getValue() || {};
                        const amount = value.amount || 0;
                        const hours = value.hours || 0;

                        return (
                            <div className="d-flex align-items-center gap-2">
                                <span>
                                    {currencyFormatter(amount, 2, currency)}
                                </span>
                                |
                                <span>
                                    {renderDurationAsFormat(hours, "HH:mm")}
                                </span>
                            </div>
                        );
                    },
                });
            }),
        ];

        if (budgetGroup) {
            const groupColumn = columnHelper.accessor("levelId", {
                enableColumnFilter: true,
                header: budgetGroup.description,
                cell: (info) => {
                    const value = info.getValue();
                    return value && renderOrgLevelResourceLabel(value);
                },
                filterType: "resource-select",
                Filter: (
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath={`${budgetGroup.id}/org-levels`}
                        renderLabel={renderOrgLevelResourceLabel}
                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                        hasSearch
                        mode="multiple"
                    />
                ),
            });
            columns.splice(1, 0, groupColumn);
        }

        if (isMasterCompany && actions) {
            columns.push(actions);
        }
        return columns;
    }, [t, isMasterCompany, currency, actions, budgetGroup]);
};
