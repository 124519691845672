import { DateFiltersProvider } from "../../components/DateRangeFilters/useDateFilters";
import Content from "./Content";

function ReportDispaly({ data, goBack }) {
	return (
		<DateFiltersProvider>
			<Content data={data} goBack={goBack} />
		</DateFiltersProvider>
	);
}

export default ReportDispaly;
