import { useCallback, useState, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
	Row,
	Col,
	Button as BaseButton,
	Dropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from "reactstrap";
import Layout from "../Layout";
import Content from "../Layout/Content";
import "./style.scss";

const businessIndustryOptions = [
	{
		value: "cleaning",
		src: "/assets/images/icons/svg_cleaning.png",
	},
	{
		value: "security",
		src: "/assets/images/icons/svg_security.png",
	},
	{
		value: "healthcare",
		src: "/assets/images/icons/healthcare.png",
	},
	{
		value: "manufacturing",
		src: "/assets/images/icons/svg_manufacturing.png",
	},
	{
		value: "construction",
		src: "/assets/images/icons/svg_construction.png",
	},
	{
		value: "retail",
		src: "/assets/images/icons/svg_retail.png",
	},
	{
		value: "foods-beverage",
		src: "/assets/images/icons/svg_food.png",
	},
];

const otherIndustries = [
	"agricultural",
	"childcare",
	"consulting",
	"entertainment",
	"fitness-sports-recreation",
	"legal-government-police-fire",
	"for-personal-use",
	"reatil-sales",
	"other",
];

const employeeNumberOptions = [
	"1 - 10",
	"11 - 20",
	"21 - 50",
	"51 - 150",
	"151 - 300",
	"301 - 500",
	"501 - 1000",
	"1000 +",
];

function Step4({ submit, values, goBack, loading, step }) {
	const { t } = useTranslation();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const [businessIndustry, setBusinessIndustry] = useState(
		values?.settings?.businessIndustry || null,
	);

	const [employeeNumber, setEmployeeNumber] = useState(
		values?.settings?.employeeCount || null,
	);

	const isOtherButtonActive = useMemo(() => {
		switch (businessIndustry) {
			case "agricultural":
			case "agricultural":
			case "childcare":
			case "consulting":
			case "entertainment":
			case "fitness-sports-recreation":
			case "legal-government-police-fire":
			case "for-personal-use":
			case "reatil-sales":
			case "other":
				return true;
			default:
				return false;
		}
	}, [businessIndustry]);

	const onBusinessIndustryClick = useCallback(
		(value) => setBusinessIndustry(value),
		[setBusinessIndustry],
	);

	const onRadioButtonClick = useCallback(
		(value) => setEmployeeNumber(value),
		[setEmployeeNumber],
	);

	const toggle = useCallback(
		() => setIsDropdownOpen(!isDropdownOpen),
		[setIsDropdownOpen, isDropdownOpen],
	);

	const onFinish = useCallback(() => {
		const data = {
			settings: { employeeCount: employeeNumber, businessIndustry },
		};
		submit(data);
	}, [submit, employeeNumber, businessIndustry]);

	return (
		<Layout step={step}>
			<Content
				cardTitle={t("how-many-employees")}
				onFinish={onFinish}
				goBack={goBack}
				loading={loading}
				step={step}
			>
				<div className="employee-number-onboarding col-12 d-flex flex-wrap justify-content-center mb-5">
					{employeeNumberOptions?.map((value) => (
						<BaseButton
							className="m-2 employee shadow border"
							outline
							onClick={() => onRadioButtonClick(value)}
							active={employeeNumber === value}
							key={value}
						>
							{value}
						</BaseButton>
					))}
				</div>

				<h5 className="font-weight-bolder text-center p-2">
					{t("business-industry")}
				</h5>

				<div className="business-industry-onboarding col-12 d-flex flex-wrap justify-content-center">
					<Row>
						{businessIndustryOptions?.map(({ value, src }) => (
							<Col
								md={3}
								sm={6}
								key={value}
								className="col-6 mt-4 d-flex flex-column text-center align-items-center"
							>
								<BaseButton
									className="m-2 business-industry-item shadow border-0 bg-white"
									outline
									onClick={() =>
										onBusinessIndustryClick(value)
									}
									active={businessIndustry === value}
									key={value}
								>
									<img src={src} alt="" />
								</BaseButton>

								{t(value)}
							</Col>
						))}

						<Col
							md={3}
							sm={6}
							className="col-6 mt-4 d-flex flex-column text-center align-items-center"
						>
							<Dropdown
								isOpen={isDropdownOpen}
								toggle={toggle}
								direction="up"
							>
								<DropdownToggle
									className="m-2 business-industry-item shadow border-0"
									style={{
										backgroundColor: isOtherButtonActive
											? "#e4f2f3"
											: "#fff",
									}}
								>
									<img
										src="/assets/images/icons/others.png"
										alt=""
									/>
								</DropdownToggle>

								<DropdownMenu>
									{otherIndustries?.map((other) => (
										<DropdownItem
											key={other}
											onClick={() =>
												onBusinessIndustryClick(other)
											}
											className={classNames(
												businessIndustry === other &&
												"bg-secondary text-white font-weight-bold",
											)}
										>
											{t(other)}
										</DropdownItem>
									))}
								</DropdownMenu>
							</Dropdown>

							{t("other")}
						</Col>
					</Row>
				</div>
			</Content>
		</Layout>
	);
}

export default Step4;
