import classNames from "classnames";
import { useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbars";
import Sidebar from "../Sidebar";
import "./style.scss";

function Admin({ siderOpen = false }) {
	const [sidenavOpen, setSidenavOpen] = useState(true);
	const mainContentRef = useRef(null);

	const toggleSidenav = () => {
		if (document.body.classList.contains("g-sidenav-pinned")) {
			document.body.classList.remove("g-sidenav-pinned");
			document.body.classList.add("g-sidenav-hidden");
		} else {
			document.body.classList.add("g-sidenav-pinned");
			document.body.classList.remove("g-sidenav-hidden");
		}
		setSidenavOpen(!sidenavOpen);
	};

	/*
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContentRef.current.scrollTop = 0;
	}, []);
	*/

	return (
		<>
			<Sidebar toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />

			<div
				ref={mainContentRef}
				className={classNames("main-content", siderOpen && "sider-open")}
			>
				<Navbar
					toggleSidenav={toggleSidenav}
					sidenavOpen={sidenavOpen}
				/>

				<div className="content">
					<Outlet />
				</div>
			</div>
			{sidenavOpen ? (
				<div className="backdrop d-xl-none" onClick={toggleSidenav} />
			) : null}
		</>
	);
}

export default Admin;
