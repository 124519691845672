import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "../../../../../../utils/helpers/errors";
import useApi from "../../../../../../utils/api";

export const useImportHolidaysApi = () => {
    const { t } = useTranslation();
    const { authGet } = useApi();
    const [loading, setLoading] = useState(false);

    const submit = useCallback(
        async (params, onSuccess) => {
            setLoading(true);
            try {
                const response = await authGet("/default-holiday-date", {
                    params,
                });
                if (response) {
                    onSuccess?.(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    return { submit, loading };
};
