const SchedulerColors = {
  pending: 'yellow',
  submitted: 'blue',
  approved: 'green',
  REG: 'green',
  HOL: 'pink',
  SICK: 'purple',
  VAC: 'teal',
  OTHER: 'gray',
  rejected: 'red',
  Offsite: 'orange',
  'Leave of Absence': 'cyan',
  Break: 'gray',
  Unavailability: 'gray',
  released: 'released',
  'Scheduled Day Off': 'red-lines',
  'to-regular': 'gray-lines',
  'overtime-unpublished': 'red-lines',
  'overtime': 'overtime',
};

export default SchedulerColors;