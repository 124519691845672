import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Row, Col} from "reactstrap";
import Select from "../../../../../components/Inputs/Select";
import UnitDateFilters from "./UnitDateFilters";
import PayGroupDateFilters from "./PayGroupDateFilters";
import { useDateFilters } from "../../../hooks/useDateFilters";

const modes = [
    { label: "Date", value: "date" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
    { label: "Custom", value: "custom" },
    { label: "Pay group", value: "pay-group" },
];

function TeamSchedulerDateFilters() {
    const {t} = useTranslation();

    const { mode, setFilters } = useDateFilters();

	const onModeChange = useCallback(
		(mode) => {
			let from, to, payGroupPeriod;
            const now = moment();
			switch (mode) {
				case "date": {
					from = now.toISOString(true);
					to = now.toISOString(true);
					break;
				}
				case "week": {
					from = now.startOf("week").toISOString(true);
					to = now.endOf("week").toISOString(true);
					break;
				}
				case "month": {
					from = now.startOf("month").toISOString(true);
					to = now.endOf("month").toISOString(true);
					break;
				}
				case "custom": {
					from = undefined;
					to = undefined;
					break;
                }
				case "pay-group": {
					from = undefined;
					to = undefined;
                    payGroupPeriod = "current";
					break;
                }
                default: {
					from = undefined;
					to = undefined;
					break;
                }
			}
			setFilters({ mode, from, to, payGroup: undefined, payGroupPeriod });
		},
		[setFilters]
	);

    return (
        <Row>
            <Col xs='12' md="auto">
                <Select
                    placeholder={t("Mode")}
                    value={mode}
                    onChange={onModeChange}
                >
                    {modes.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                            {t(label)}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            <Col xs='12' md="auto">
                {mode === "pay-group" ? (
                    <PayGroupDateFilters />
                ) : (
                    <UnitDateFilters />
                )}
            </Col>
        </Row>
    );
}

export default TeamSchedulerDateFilters;
