import { useCallback, useContext, useEffect, useMemo } from "react";
import BaseForm from "rc-field-form";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import Field from "../../../../components/Field";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Text from "../../../../components/Inputs/Text";
import Button from "../../../../components/Button";
import StatusField from "../../../../components/Field/StatusField";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";
import "react-phone-input-2/lib/style.css";
import "./style.scss";

function Form({ values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = BaseForm.useForm();

	const { groups } = useContext(OrgLevelGroupsContext);

	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				timezone: values?.timezone?.id,
				location: values?.location?.id,
				project: values?.project?.id,
			};
			if (data.number && data.number.split("+").length === 1) {
				data.number = `+${data.number}`;
			}
			submit(data);
		},
		[submit],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"description",
				"status",
				"project",
				"location",
				"timezone",
				"number",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<BaseForm
			form={form}
			onFinish={onFinish}
			className="whitelist-number-form"
		>
			<Field
				label={t("phone-number")}
				name="number"
				rules={[
					{
						required: true,
						message: t("required-phone-number"),
					},
				]}
			>
				<PhoneInput
					country="us"
					enableSearch
					prefix="+"
					inputClass="pl-5 w-100"
				/>
			</Field>

			<Field label={t("description")} name="description">
				<Text placeholder={t("description")} />
			</Field>

			<Field label={t("location")} name="location">
				<ResourceSelect
					labelPropName="description"
					resourcePath="/locations"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("location")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
				/>
			</Field>

			<Field label={t("timezone")} name="timezone">
				<ResourceSelect
					labelPropName="text"
					resourcePath="/timezone?pagination=false"
					hasCompany={false}
					hasSearch
					placeholder={t("timezone")}
				/>
			</Field>

			{group && (
				<Field
					name="project"
					label={group.code + " - " + group.description}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath={`${group.id}/org-levels`}
						renderLabel={renderOrgLevelResourceLabel}
						placeholder={group.code + " - " + group.description}
						hasSearch
						getSearchFilters={getSettingsOrgResourceSearchFilters}
					/>
				</Field>
			)}

			<StatusField />

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				<Button
					type="submit"
					className="btn-dark btn-sm shadow-none"
					loading={loading}
				>
					{t("save")}
				</Button>
			</div>
		</BaseForm>
	);
}

export default Form;
