import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useFirstNameFilter = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        id: "firstName",
        Header: t("first-name"),
        filter: "text",
        canFilter: true,
    }), [t]);
}
