import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";

export const useLocationsFilter = () => {
	const { t } = useTranslation();
	return useMemo(
		() => ({
			id: "location",
			Header: t("locations-worked"),
			canFilter: true,
			filter: "resource-select",
			Filter: (
				<ResourceSelect
					resourcePath="/locations?pagination=off"
					mode="multiple"
					renderLabel={renderSettingsResourceLabel}
					getSearchFilters={getSettingsResourceSearchFilters}
					hasSearch
				/>
			),
		}),
		[t],
	);
};
