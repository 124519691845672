import Layout from "../Layout";
import Card from "../Layout/Card";
import SetNewPasswordForm from "./Form";

function SetNewPassword() {
	return (
		<Layout
			image="/assets/images/reset-password.jpg"
			text="It is during our darkest moments that we must focus to see the light."
			autor="Aristotle"
		>
			<Card
				cardTitle="set-new-password"
				cardText="set-new-password-description"
				cardFooter="need-help"
				link="#"
				linkText="help-center"
				cardBody={<SetNewPasswordForm />}
				className="text-center"
			/>
		</Layout>
	);
}

export default SetNewPassword;
