import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { CardBody, CardHeader } from "reactstrap";
import Form from "./Form";
import NTable from "../../../components/NTable";
import Drawer from "../../../components/Drawer";
import Button from "../../../components/Button";
import { useDeleteModal } from "../../Settings/helpers/useDeleteModal";
import { useNewTable } from "../../../components/NTable/hook";
import { useDrawerProps } from "../../Settings/helpers/useDrawerProps";
import { useColumns } from "./columns";
import { ProfileContext } from "../context";
import { useRequestTimeOffApi } from "./api";
import { useCompanyDateFormat } from "../../../utils/hooks/company";
import { differenceFromNow, dateTimeFormat } from "../../../utils/helpers/date";

const useCustomDrawer = (getChildren) => {
	const [wrapperElement, setWrapperElement] = useState(null);

	useLayoutEffect(() => {
		const element = document.getElementById("timeoff-drawer");
		setWrapperElement(element);
	}, [setWrapperElement]);

	if (wrapperElement === null) return null;

	return createPortal(getChildren(), wrapperElement);
};

function TimeOff({ isOpenFromTimesheet }) {
	const { t } = useTranslation();
	const { user } = useContext(ProfileContext);
	const dateFormat = useCompanyDateFormat();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		create,
		update,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
	} = useRequestTimeOffApi();

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: () => t("do-you-want-to-delete-timeoff"),
		onConfirm: (data) => remove(data),
	});

	const columns = useColumns({
		edit: goToEdit,
		remove: openDeleteModal,
		clone: goToClone,
		isOpenFromTimesheet,
	});
	const table = useNewTable({
		data,
		totalItems,
        loading,
		columns,
		enableRowSelection: false,
	});

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		create: t("add-timeoff"),
		edit: t("edit-timeoff"),
		clone: t("clone-timeoff"),
	});

	const drawer = useCustomDrawer(() => {
		return (
			<Drawer
				title={drawerTitle}
				open={drawerOpen}
				close={goToView}
				style={{
					maxWidth: "initial",
				}}
			>
				<Form
					mode={mode}
					error={error}
					loading={submitting}
					close={goToView}
					create={create}
					update={update}
					values={selected}
				/>
			</Drawer>
		);
	});

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return (
		<>
			{!isOpenFromTimesheet && (
				<CardHeader className="border-0">
					<div className="d-flex justify-content-between">
						<div className="d-flex flex-column">
							<h3>
								{t("length-of-service")}:{" "}
								{user?.hiringDate &&
									dateTimeFormat(user?.hiringDate, dateFormat)}
							</h3>

							<p>
								{user?.hiringDate &&
									differenceFromNow(user?.hiringDate)}
							</p>
						</div>

						<Button
							onClick={goToCreate}
							className="border-dark pr-3 btn-round btn-icon shadow-none btn btn-none btn-sm h-25"
						>
							{t("request-timeoff")}
						</Button>
					</div>
				</CardHeader>
			)}

			<CardBody
				className="p-0"
				style={{
					height: "calc(100% - 20px)",
					overflowY: "auto",
				}}
			>
                <NTable table={table} />
			</CardBody>

			{drawer}

			{deleteModal}
		</>
	);
}

export default TimeOff;
