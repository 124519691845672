import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {createColumnHelper} from "@tanstack/react-table";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const {
        code,
        description,
        status,
        actions,
    } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
			columnHelper.accessor("startDay", {
				header: t("start-day"),
			}),
			columnHelper.accessor("isMain", {
				header: t("is-default"),
				cell: (info) => {
                    const value = info.getValue();
                    return value ? t("yes") : t("no");
                },
			}),
			columnHelper.accessor("color", {
				header: t("color"),
				cell: (info) => (
					<span
						style={{
							backgroundColor: info.getValue(),
							borderRadius: "10px",
							display: "inline-block",
							border: "1px solid #dee2e6",
							height: "12px",
							width: "12px",
						}}
					/>
				),
			}),
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [
		t,
		isMasterCompany,
		code,
		description,
		status,
		actions,
	]);
};
