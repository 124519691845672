import {useState, useCallback} from "react";
import {useSearchParams} from "react-router-dom";
import { v4 as isUUID } from "is-uuid";

export const filterRegex = /filter\[([^\[\]]*)\]\[([^\[\]]*)\]/;
export const sortRegex = /sortBy\[([^\[\]]*)\]/;

export const unserializeUrlFilters = (searchParams) => {
    const filters = {
        filters: {},
    };
    for (let [name, value] of searchParams.entries()) {
        if (!name.startsWith("filter")) {
            if (["pageIndex", "pageSize"].includes(name)) {
                value = Number(value);
            } else if (sortRegex.test(name)) {
                const [_, field] = sortRegex.exec(name);
                name = "sortBy";
                value = [{
                    id: field,
                    desc: value === "desc",
                }];
            }
            filters[name] = value;
            continue;
        }
        const isMulti = name.endsWith("[]");
        const [_, field, method] = (filterRegex.exec(name) || []);

        if (!filters.filters[field]) {
            filters.filters[field] = {
                name: field,
                method,
                value: isMulti ? [] : undefined,
            };
        }
        if (isUUID(value)) {
            value = { id: value };
        }
        if (isMulti) {
            if (value) {
                filters.filters[field].value.push(value);
            } else {
                filters.filters[field].value = [];
            }
        } else {
            filters.filters[field].value = value;
        }
    }
    filters.filters = Object
        .keys(filters.filters)
        .map((name) => filters.filters[name]);
    return filters;
}

export const useTableURLFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialFilters] = useState(unserializeUrlFilters(searchParams));

    const onTableChange = useCallback((state) => {
        const {
            globalFilter: filters,
            pageIndex,
            pageSize,
            sortBy: [sortBy] = [],
        } = state;
        setSearchParams((prv) => {
            const prev = new URLSearchParams(prv);

            for (const [name] of prv.entries()) {
                if (
                    filterRegex.test(name)
                    || sortRegex.test(name)
                ) {
                    prev.delete(name);
                }
            }

            if (pageIndex !== undefined) {
                prev.set("pageIndex", pageIndex);
            } else {
                prev.delete("pageIndex");
            }
            if (pageSize !== undefined) {
                prev.set("pageSize", pageSize);
            } else {
                prev.delete("pageSize");
            }
            if (sortBy) {
                const { id, desc } = sortBy;
                prev.set(`sortBy[${id}]`, desc ? "desc" : "asc");
            }
            filters?.forEach(({ method, name, value }) => {
                const filterName = `filter[${name}][${method}]`;
                if (Array.isArray(value)) {
                    prev.delete(filterName);
                    prev.delete(`${filterName}[]`);
                    if (value.length > 0) {
                        value.forEach((item) => {
                            prev.append(
                                `${filterName}[]`,
                                typeof value === "object" && value !== null
                                    ? item.id
                                    : item,
                            );
                        });
                    } else {
                        prev.set(`${filterName}[]`, "");
                    }
                } else if (typeof value === "object" && value !== null) {
                    prev.set(filterName, value.id);
                } else {
                    prev.set(filterName, value);
                }
            });
            return prev.toString();
        });
    }, [setSearchParams]);

    return [initialFilters, onTableChange];
}
