import { motion } from "framer-motion";
import { Card, Row, Col } from "reactstrap";
import Overview from "./Overview";
import Steps from "./Steps";
import "./_style.scss";

function Layout({ title, description, children }) {
    return (
        <motion.div
            className="company-onboarding-layout drawer-absolute"
            initial={{ y: "50%", opacity: 0.4 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
        >
            <div className="d-flex justify-content-center bg-white position-relative py-5 h-100">
                <div className="d-flex flex-column col-12 col-md-10 overfllow-auto h-100">
                    <Steps />

                    <Row className="body flex-fill">
                        <Overview title={title} description={description} />

                        <Col className="h-100" sm="12" lg="8">
                            <Card className="mb-0 h-100">{children}</Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </motion.div>
	);
}

export default Layout;
