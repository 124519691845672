import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import SimpleTable from "../../../../../components/SimpleTable";
import { useColumns } from "./useColumns";
import { useGetFooterData } from "../../../../TimeSheet/ManagerView/Table/helpers/useGetFooterData";
import { useGetHourTypes } from "../../../../TimeSheet/ManagerView/Table/helpers/useGetHourTypes";

function DailyHoursTooltip({ isOpen, close, data }) {
    const { t } = useTranslation();
    const workedHoursHourType = useMemo(() => {
        return data?.data?.hourTypes?.filter((item) => item !== "unpaid");
    }, [data]);

    const hourTypes = useGetHourTypes(workedHoursHourType);

    const workedData = useMemo(() => {
        return (
            data?.data?.data?.filter((item) => item?.hourType !== "unpaid") || []
        );
    }, [data]);

    const unpaidHourTypes = useMemo(() => {
        return data?.data?.hourTypes?.filter((item) => item === "unpaid");
    }, [data]);

    const unpaidData = useMemo(() => {
        return (
            data?.data?.data?.filter((item) => item?.hourType === "unpaid") || []
        );
    }, [data]);

    const {
        workedHoursColumns,
        paidAmountColumns,
        unpaidHoursColumns,
        unpaidAmountColumns,
    } = useColumns({
        hourTypes,
        unpaidHourTypes,
    });

    return <Tooltip
        isOpen={isOpen}
        toggle={close}
        trigger="hover"
        autohide={false}
        target={`daily-hours-${data?.user?.id}-${data?.date}`}
        placement="right"
        className="summary-tooltip"
    >
        <div className="border-bottom mb-3 d-flex justify-content-between align-items-center">
            <h3 className="py-2">{t("worked-hours")}</h3>

            <i
                className="fa-solid fa-xmark cursor-pointer text-dark"
                onClick={close}
            />
        </div>

        <div className="d-flex align-items-center w-100 summary-table">
            <div className="w-100 mr-1">
                <SimpleTable columns={workedHoursColumns} data={useGetFooterData(
                    workedData?.filter(
                        (item) => item !== undefined,
                    ),
                )} />
            </div>
            <div className="w-100 ml-1">
                <SimpleTable columns={paidAmountColumns} data={useGetFooterData(
                    workedData?.filter(
                        (item) => item !== undefined,
                    ),
                )} />
            </div>
        </div>

        <h3 className="border-bottom py-2 px-0 mx-0 mb-3 text-left">
            {t("other")}
        </h3>

        <div className="d-flex align-items-center w-100 summary-table">
            <div className="w-100 mr-1">
                <SimpleTable
                    columns={unpaidHoursColumns}
                    data={useGetFooterData(
                        unpaidData?.filter((item) => item !== undefined),
                    )}
                />
            </div>

            <div className="w-100 ml-1">
                <SimpleTable
                    columns={unpaidAmountColumns}
                    data={useGetFooterData(
                        unpaidData?.filter((item) => item !== undefined),
                    )}
                />
            </div>
        </div>
    </Tooltip>
}

export default DailyHoursTooltip;