import { useCallback, useState, useEffect } from "react";
import Table from "../../../../components/Table";
import { TableContext } from "../../../../components/Table/context";
import { useEnhancedTable } from "../../../../components/Table/hook";
import { useReports } from "../api";
import ActionsModal from "./ActionsModal";
import { useColumns } from "./useColumns";
import { formatParams } from "../../../../utils/api/format";
import { DateFiltersProvider } from "../../components/DateRangeFilters/useDateFilters";
import ReportTableHeader from "./ReportTableHeader";


function ReportsTable({ setStep, setData, data, setMode }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalMode, setModalMode] = useState(null);


	const { fetchReports, reports, totalItems, loading } = useReports();

	const generate = useCallback(
		(value, mode) => {
			setData({
				id: value.id,
				name: value?.name,
				columns: value?.columns,
				// defaultFilters: value?.settings?.defaultFilters,
			});
			setIsModalOpen(true);
			setModalMode(mode);
		},
		[setIsModalOpen, setData, setModalMode],
	);

	const edit = useCallback(
		(value) => {
			setStep(1);
			setMode("edit");
			setData({
				id: value.id,
				name: value?.name,
				columns: value?.columns,
				// defaultFilters: value?.settings?.defaultFilters,
			});
		},
		[setStep, setData, setMode],
	);

	const clone = useCallback(
		(value) => {
			setStep(1);
			setMode("clone");
			setData({
				id: value.id,
				name: "",
				columns: value?.columns,
				// defaultFilters: value?.settings?.defaultFilters,
			});
		},
		[setStep, setData, setMode],
	);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		setModalMode(null);
	}, [setIsModalOpen, setModalMode]);

	const columns = useColumns({
		generate,
		edit,
		clone,
		setData,
		setStep,
	});

	const table = useEnhancedTable({
		hasURLBindedFilters: false,
		columns,
		data: reports,
		totalItems: totalItems,
		hasRowSelection: false,
	});

	const { pageIndex, pageSize, globalFilter: filters, sortBy } = table.state;

	useEffect(() => {
		const controller = new AbortController();

		fetchReports(
			formatParams({
				pageIndex,
				pageSize,
				filters,
				sortBy,
			}),
			controller,
		);
		return () => controller.abort();
	}, [fetchReports, pageIndex, pageSize, sortBy, filters]);

	return (
		<DateFiltersProvider>
			<TableContext.Provider value={table}>
				<ReportTableHeader />
				<Table loading={loading} />
			</TableContext.Provider>

			<ActionsModal
				isOpen={isModalOpen}
				close={closeModal}
				data={data}
				setStep={setStep}
				setData={setData}
				mode={modalMode}
			/>
		</DateFiltersProvider>
	);
}

export default ReportsTable;
