import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../../../components/Inputs/Select";
import { getActiveResources } from "../../../../../utils/helpers/user";
import { isAddedTimeSheet, isInputDisabled } from "../helpers";
import { calculateRate } from "../../../../../utils/helpers/shift";

export const useJobColumn = ({ canEdit }) => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("job"),
        accessor: "job",
        disableFilters: false,
        filter: "text",
        Cell: ({ value, row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original, activeJobs } = row;
            const disabled = isInputDisabled(original) || row.disabled || cell.disabled;
            const jobData = Array.isArray(activeJobs) ? activeJobs : getActiveResources(original.user?.jobs || []);
            return (
                <Select
                    className="select-input group-input w-100"
                    showSearch
                    value={value?.id}
                    disabled={disabled || !canEdit}
                    dropdownMatchSelectWidth={false}
                    onChange={(id) => {
                        const job = jobData.find(
                            (job) => job.id === id
                        );
                        if (!job) {
                            return;
                        }
                        const rate = calculateRate({ ...original, job });
                        const regRate = calculateRate({ ...original, job }, true);
                        if (isAddedTimeSheet(original)) {
                            updateAddedTimeSheet(original, { job, rate, regRate, rateChangeManually: false });
                        } else {
                            updateTimeSheet(original.id, { job, rate, regRate, rateChangeManually: false });
                        }
                    }}
                >
                    {value && (
                        <Select.Option value={value.id}>
                            {value.displayName}
                        </Select.Option>
                    )}

                    {jobData?.filter(({ id }) => id !== value?.id)
                        .map((job) => (
                            <Select.Option key={job.id} value={job.id}>
                                {job.displayName}
                            </Select.Option>
                        ))}
                </Select>
            );
        },
    }), [t, canEdit]);
};
