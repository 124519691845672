import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CardFooter,
	Dropdown,
	Pagination as BasePagination,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	Col,
} from "reactstrap";
import Item from "./Item";

const options = [10, 20, 30, 40, 50, 100];

function Pagination({
	page,
	pageCount,
	gotoPage,
	perPage,
	setPerPage,
	totalItems,
	showOnSizeChange = true,
	isListPagination = false,
}) {
	const { t } = useTranslation();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = useCallback(() => {
		setIsDropdownOpen(!isDropdownOpen);
	}, [isDropdownOpen, setIsDropdownOpen]);

	const onPageChange = useCallback(
		(value) => {
			setPerPage(value);
			gotoPage(0);
		},
		[setPerPage, gotoPage],
	);

	const firstElementOfPage = useMemo(() => page * perPage, [page, perPage]);

	const rangeStart = useMemo(() => {
		const range = firstElementOfPage + 1;
		if (range > totalItems && totalItems === 0) {
			return totalItems;
		} else {
			return range;
		}
	}, [firstElementOfPage, totalItems]);

	const rangeEnd = useMemo(() => {
		const rangeIfTotalFurfilled = firstElementOfPage + perPage;
		if (rangeIfTotalFurfilled > totalItems) {
			return totalItems;
		} else {
			return rangeIfTotalFurfilled;
		}
	}, [firstElementOfPage, perPage, totalItems]);

	const content = useMemo(() => {
		if (pageCount <= 5) {
			return new Array(pageCount).fill(null)?.map((_, i) => (
				<Item
					key={i.toString()}
					active={page === i}
					onClick={() => gotoPage(i)}
				>
					{i + 1}
				</Item>
			));
		} else if (page >= 4 && page < pageCount - 2 && pageCount > 5) {
			return (
				<>
					<Item
						key={0}
						active={page === 0}
						onClick={() => gotoPage(0)}
					>
						1
					</Item>

					<span className="pt-2 text-gray">...</span>

					<Item
						key={page - 2}
						active={page === page - 2}
						onClick={() => gotoPage(page - 2)}
					>
						{page - 1}
					</Item>

					<Item
						key={page - 1}
						active={page === page - 1}
						onClick={() => gotoPage(page - 1)}
					>
						{page}
					</Item>

					<Item
						key={page}
						active={page === page}
						onClick={() => gotoPage(page - 1)}
					>
						{page + 1}
					</Item>

					{page < pageCount && (
						<Item
							key={page + 1}
							active={page === page + 1}
							onClick={() => gotoPage(page + 1)}
						>
							{page + 2}
						</Item>
					)}

					{page < pageCount - 1 && (
						<Item
							key={page + 2}
							active={page === page + 2}
							onClick={() => gotoPage(page + 2)}
						>
							{page + 3}
						</Item>
					)}

					{page <= pageCount - 5 && (
						<>
							<span className="pt-2 text-gray">...</span>

							<Item
								key={pageCount - 1}
								active={page === pageCount - 1}
								onClick={() => gotoPage(pageCount - 1)}
							>
								{pageCount}
							</Item>
						</>
					)}
				</>
			);
		} else if (page >= pageCount - 2) {
			return (
				<>
					<Item
						key={0}
						active={page === 0}
						onClick={() => gotoPage(0)}
					>
						1
					</Item>

					<span className="pt-2 text-gray">...</span>

					<Item
						key={pageCount - 5}
						active={page === pageCount - 5}
						onClick={() => gotoPage(pageCount - 5)}
					>
						{pageCount - 4}
					</Item>

					<Item
						key={pageCount - 4}
						active={page === pageCount - 4}
						onClick={() => gotoPage(pageCount - 4)}
					>
						{pageCount - 3}
					</Item>

					<Item
						key={pageCount - 3}
						active={page === pageCount - 3}
						onClick={() => gotoPage(pageCount - 3)}
					>
						{pageCount - 2}
					</Item>

					<Item
						key={pageCount - 2}
						active={page === pageCount - 2}
						onClick={() => gotoPage(pageCount - 2)}
					>
						{pageCount - 1}
					</Item>

					<Item
						key={pageCount - 1}
						active={page === pageCount - 1}
						onClick={() => gotoPage(pageCount - 1)}
					>
						{pageCount}
					</Item>
				</>
			);
		} else if (pageCount > 5) {
			return (
				<>
					{new Array(5).fill("1")?.map((_, i) => (
						<Item
							key={i.toString()}
							active={page === i}
							onClick={() => gotoPage(i)}
						>
							{i + 1}
						</Item>
					))}

					<span className="pt-2 text-gray">...</span>

					<Item
						key={pageCount - 1}
						active={page === pageCount - 1}
						onClick={() => gotoPage(pageCount - 1)}
					>
						{pageCount}
					</Item>
				</>
			);
		}
	}, [page, pageCount]);

	return (
		pageCount > 0 && (
			<CardFooter className="p-3 border-top">
				<Row className="align-items-center">
					<Col
						className={classNames(
							isListPagination &&
							"d-flex justify-content-center mb-2",
						)}
					>
						<div className="font-weight-500 text-dark text-nowrap text-sm py-2">
							{t("displaying")}{" "}
							<span className="font-weight-bolder">
								{rangeStart}
							</span>
							-
							<span className="font-weight-bolder">
								{rangeEnd}
							</span>{" "}
							{t("of")}{" "}
							<span className="font-weight-bolder">
								{totalItems}
							</span>{" "}
							{t("records")}
						</div>
					</Col>

					<Col
						className={classNames(
							isListPagination && "d-flex justify-content-center",
						)}
					>
						<BasePagination
							className="pagination justify-content-end mb-0"
							listClassName="justify-content-end mb-0"
						>
							<Item
								onClick={() => gotoPage(page - 1)}
								disabled={page < 1}
							>
								<i className="fas fa-angle-left" />
								<span className="sr-only">{t("previous")}</span>
							</Item>

							{content}

							<Item
								onClick={() => gotoPage(page + 1)}
								disabled={page >= pageCount - 1}
							>
								<i className="fas fa-angle-right" />
								<span className="sr-only">{t("next")}</span>
							</Item>

							{showOnSizeChange && (
								<Dropdown
									isOpen={isDropdownOpen}
									toggle={toggleDropdown}
								>
									<DropdownToggle
										caret
										style={{
											height: "37px",
											padding: "0 9px",
										}}
										className="text-muted bg-secondary border border-gray bg-white shadow-none ml-2"
									>
										{perPage}
									</DropdownToggle>

									<DropdownMenu style={{ minWidth: "57px" }}>
										{options.map((option) => (
											<DropdownItem
												key={option}
												onClick={() => onPageChange(option)}
											>
												{option}
											</DropdownItem>
										))}
									</DropdownMenu>
								</Dropdown>
							)}
						</BasePagination>
					</Col>
				</Row>
			</CardFooter>
		)
	);
}

export default Pagination;
