import _ from "lodash/";
import { camelToSentenceCase } from "../../../../../../utils/helpers/string";

const getPath = (key, parentPath = "") =>
	parentPath === "" ? key : `${parentPath}.${key}`;

/**
 * updated the module depending on the checkedKeys array
 * this function mutates the module
 * @param module
 * @param checkedKeys
 */
const updatePermissions = (moduleMap, checkedKeys, parentPath = "") => {
	Object.keys(moduleMap).forEach((moduleKey) => {
		const module = moduleMap[moduleKey];
		const path = getPath(moduleKey, parentPath);

		if (typeof module === "boolean") {
			_.set(moduleMap, moduleKey, checkedKeys.includes(path));
		} else {
			updatePermissions(module, checkedKeys, path);
		}
	});
};

export const setAccessLevels = (accessLevels, checkedLevels) => {
	const newAccessLevels = JSON.parse(JSON.stringify(accessLevels));
	updatePermissions(newAccessLevels, checkedLevels);
	return newAccessLevels;
};

export const getSelectedKeysFromPermissions = (permissions, parentPath = "") =>
	Object.keys(permissions)
		.map((permissionKey) => {
			const permission = permissions[permissionKey];
			const path = getPath(permissionKey, parentPath);
			if (typeof permission === "boolean") {
				if (permission === true) {
					return [path];
				} else {
					return [];
				}
			} else {
				return getSelectedKeysFromPermissions(permission, path);
			}
		})
		.flat(Infinity);

export const accessLevelsToTree = (accessLevels, parentKey = "") =>
	Object.keys(accessLevels).map((moduleName) => {
		const module = accessLevels[moduleName];
		if (module === null) {
			throw new Error("null passed to accessLeveLModuleToTree");
		}
		const title = camelToSentenceCase(moduleName);
		const key = getPath(moduleName, parentKey);
		switch (typeof module) {
			case "object": {
				return {
					title,
					key,
					children: accessLevelsToTree(module, key),
				};
			}
			case "boolean": {
				return { title, key };
			}
			default: {
				throw new Error("Wrong type passed to accessLeveLModuleToTree");
			}
		}
	});
