import {useCallback, useMemo} from "react";
import {useExpanded, useTable} from "react-table";
import {Table} from "reactstrap";
import {useExpandColumn} from "../../../../../../../../components/Table/commonColumns/useExpandColumn";
import {useColumns} from "./useColumns";
import TimeSheetTablePreview from "../TimeSheetTable";
import {getTimeSheetDuration} from "../../../../../../components/TimeSheetTable/useColumns/useDurationColumn";
import moment from "moment-timezone";

const calculateOverall = (user, timeSheets) => {
    return timeSheets?.[user.id]?.reduce((total, timeSheet) => {
        const payCode = timeSheet.payCode;
        let hourType = payCode?.hourType;

        if (!hourType) {
            return total;
        }

        const duration = getTimeSheetDuration(timeSheet);
        const job = timeSheet.job;
        let payment = job?.hourlyRate ? (moment.duration(duration, "s").asHours() * job?.hourlyRate) : 0;
        if (payment !== undefined && payCode?.multiplier !== undefined) {
            payment = payment * payCode?.multiplier
        }
        switch (hourType) {
            case "double-time":
            case "double-time-and-half": {
                hourType = "overtime";
                break;
            }
            case "override": {
                hourType = "regular";
                break;
            }
            default: {
                break;
            }
        }

        if (!(hourType in total)) {
            total[hourType] = {
                hourType,
                duration: 0,
                payment: 0,
            };
        }

        total[hourType].duration += duration;
        if (payment) {
            total[hourType].payment += payment;
        }

        if (hourType !== "unpaid") {
            total.paid.duration += duration;
            if (payment) {
                total.paid.payment += payment;
            }
        }

        return total;
    }, { paid: { hourType: "paid", duration: 0, payment: 0 } });
}

const UserTable = ({ data, values, timeSheets }) => {
    const columns = useColumns();

    const {
        headerGroups,
        rows,
        getTableProps,
        getTableBodyProps,
        prepareRow,
    } = useTable(
        {
            data: useMemo(() => {
                return data?.map((user) => {
                    const overall = calculateOverall(user, timeSheets);
                    const payment = overall.paid.payment;
                    return { ...user, overall: Object.values(overall), payment };
                });
            }, [data, timeSheets]),
            columns,
			getRowId: useCallback(({ id }) => id, []),
        },
        useExpanded,
        useExpandColumn,
    );

    return (
        <Table
            className="align-items-center table-flush"
            hover
            responsive
            size="sm"
            {...getTableProps()}
        >
            <thead className="thead-light">
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <>
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>

                            {row.isExpanded && (
                                <tr>
                                    <td className="timesheet-table-container" colSpan={1000}>
                                        <TimeSheetTablePreview
                                            user={row.original}
                                            values={values}
                                            data={timeSheets[row.original.id]}
                                            // payGroupItem={payGroupItemsMap[row.original.payGroup.id]}
                                        />
                                    </td>
                                </tr>
                            )}
                        </>
                    );
                })}
            </tbody>
        </Table>
    );
}

export default UserTable;
