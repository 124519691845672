import { useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Button from "../../Button";
import { ProfileContext } from "../../../features/Profile/context";
import { useAccess, useAccessInList } from "../../../utils/hooks/access";

const notesPermissions = ["people.manageNote", "people.manageAllNotes"];

function Topbar({ menu, setMenu }) {
	const { t } = useTranslation();

	const { isSelf } = useContext(ProfileContext);

	const { hasAccess: canManageDocuments } = useAccess(
		isSelf ? "profile.manageDocuments" : "people.manageDocuments",
	);
	const { hasAccess: canManageNotes } = useAccessInList(notesPermissions);
	const { hasAccess: canManageTimeoff } = useAccess(
		isSelf ? "profile.manageTimeOff" : "people.manageTimeOff",
	);

	const { hasAccess: canViewHistory } = useAccess(
		isSelf ? "profile.viewHistory" : "people.viewHistory"
	);

	return (
		<div className="d-flex flex-wrap text-center w-100 h-unset mb-3">
			<Button
				className={classNames(
					"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border",
					menu === "job-pay" && "bg-primary text-white",
				)}
				onClick={() => setMenu("job-pay")}
			>
				{t("job-and-pay")}
			</Button>

			<Button
				className={classNames(
					"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border ",
					menu === "personal" && "bg-primary text-white",
				)}
				onClick={() => setMenu("personal")}
			>
				{t("personal")}
			</Button>

			{canManageTimeoff && (
				<Button
					className={classNames(
						"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border ",
						menu === "time-off" && "bg-primary text-white",
					)}
					onClick={() => setMenu("time-off")}
				>
					{t("time-off")}
				</Button>
			)}

			{canManageDocuments && (
				<Button
					className={classNames(
						"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border ",
						menu === "documents" && "bg-primary text-white",
					)}
					onClick={() => setMenu("documents")}
				>
					{t("documents")}
				</Button>
			)}

			{!isSelf && canManageNotes && (
				<Button
					className={classNames(
						"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border ",
						menu === "notes" && "bg-primary text-white",
					)}
					onClick={() => setMenu("notes")}
				>
					{t("notes")}
				</Button>
			)}

			<Button
				className={classNames(
					"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border ",
					menu === "settings" && "bg-primary text-white",
				)}
				onClick={() => setMenu("settings")}
			>
				{t("settings")}
			</Button>

			{canViewHistory && ( 
				<Button
					className={classNames(
							"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border ",
							menu === "history" && "bg-primary text-white",
						)}
						onClick={() => setMenu("history")}
				>
					{t("history")}
				</Button>
			)}
		</div>
	);
}

export default Topbar;
