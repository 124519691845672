import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import LanguageSelect from "../../../../../components/Inputs/LanguageSelect";
import { useUserPreferenceActions } from "../../../../Settings/Configuration/api/useUserPreferences";
import { useConfiguration } from "../../../../Settings/Configuration/api/useConfiguration";

function ChangeLanguage({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { submit, submitting } = useUserPreferenceActions("profileSettings");
    const { userPreferences, setUserPreferences} = useConfiguration();

	const onFinish = useCallback(async(values) => {
		const response = await submit({
			entity: "user",
			config: {
				...userPreferences?.profileSettings,
				language: values?.language
			}
		});
		if (response) {
			const data = {
				...userPreferences,
				profileSettings: {
					...userPreferences?.profileSettings,
					...response?.config
				}
			}
			setUserPreferences(data);
			close();
		}
	}, [userPreferences, submit, setUserPreferences, close]);

	useEffect(() => {
		form.setFieldsValue({
			language: userPreferences?.profileSettings?.language
		})
	}, [form,  userPreferences?.profileSettings?.language]);

	return (
		<Form
			layout="vertical"
			form={form}
			onFinish={onFinish}
		>
			<Field 
				name="language"
				label={t("language")}
				rules={[
					{
						required: true,
						message: t("required-language")
					}
				]}
			>
				<LanguageSelect placeholder={t("language")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	)
}

export default ChangeLanguage;
