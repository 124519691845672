import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Card, CardBody, Row, Col, CardTitle, CardFooter } from "reactstrap";
import Progress from "./Progress";
import Actions from "./Actions";
import { useTime } from "../../../../utils/hooks/time";
import { useQrCodeClockIn } from "../../context";
import { useMode, useLastShift, useWorkSummary } from "../store";
import {
	dateTimeFormat,
	renderDurationAsFormat,
} from "../../../../utils/helpers/date";
import { getBreakDuration } from "../../../../utils/helpers/shift";
import { getPaidHours } from "../../../../utils/helpers/shift";
import {
	renderSettingsResourceLabel,
	renderOrgLevelResourceLabel,
} from "../../../../utils/helpers/settings";

function Running() {
	const { t } = useTranslation();
	const time = useTime();
	const mode = useMode();
	const shift = useLastShift();
	const workSummary = useWorkSummary();
	const { user, company, qrCode, flyers } = useQrCodeClockIn();

	const canClockInOnQrcode = user?.permissions;

	const lastShiftClock = shift?.clocks[shift?.clocks.length - 1];

	const lastShiftDuration = useMemo(() => {
		if (!shift) {
			return;
		}
		const duration = moment(shift.endDate).diff(shift.startDate, "seconds");
		return renderDurationAsFormat(duration, "HH:mm:ss");
	}, [shift]);

	const lastClockDuration = useMemo(() => {
		if (!lastShiftClock) {
			return null;
		}
		let duration = moment(time).diff(lastShiftClock.time, "seconds");
		if (duration < 0) {
			duration = 1;
		}
		return renderDurationAsFormat(duration, "HH:mm:ss");
	}, [time, lastShiftClock]);

	const shiftStatusText = useMemo(() => {
		if (!user?.calculationGroup) {
			return;
		}

		if (lastShiftClock) {
			if (mode === "break") {
				const duration = getBreakDuration(user.calculationGroup.breaks);
				if (duration) {
					return t("duration-break-in-progress", {
						duration
					});
				} else {
					return t("break-in-progress");
				}
			} else if (
				(lastShiftClock?.mode === "START" &&
					lastShiftClock?.type === "SHIFT") ||
				(lastShiftClock?.mode === "END" &&
					lastShiftClock?.type === "BREAK")
			) {
				return t("in-progress");
			} else if (
				lastShiftClock?.mode === "END" &&
				lastShiftClock?.type === "SHIFT"
			) {
				const start = shift?.clocks?.filter(
					({ type, mode }) => type === "BREAK" && mode === "START",
				);

				const end = shift?.clocks?.filter(
					({ type, mode }) => type === "BREAK" && mode === "END",
				);

				let duration = 0;
				for (let i = 0; i < start.length; i++) {
					duration += moment(end[i]?.time).diff(start[i]?.time);
				}

				return (
					<div className="d-flex justify-content-between">
						<span className="mr-3">
							{duration !== 0 &&
								t("duration-break", {
									duration: moment
										.duration(duration)
										.humanize()
								}
								)}
						</span>

						<span>
							{dateTimeFormat(
								lastShiftClock?.time,
								company?.settings?.timeFormat,
							)}
						</span>
					</div>
				);
			}
		}
	}, [user?.calculationGroup, lastShiftClock, mode, t, shift, company]);

	const lastGeoLocation = useMemo(
		() => lastShiftClock?.geofence?.address,
		[lastShiftClock],
	);

	const sinceLastClock = useMemo(() => {
		if (lastShiftClock) {
			const duration = moment(time).diff(lastShiftClock.time);
			const humanDuration = moment.duration(duration).humanize();
			if (lastShiftClock.type === "SHIFT") {
				const mode = lastShiftClock.mode === "START" ? "in" : "out";
				return t("clocked-mode-duration", {
					mode, humanDuration
				});
			} else {
				const mode =
					lastShiftClock.mode === "START" ? "Started" : "Ended";
				return t("break-mode-duration", { mode, humanDuration });
			}
		}
	}, [lastShiftClock, time, t]);

	const cardClassname = useMemo(() => {
		if (
			(lastShiftClock?.type === "SHIFT" &&
				lastShiftClock?.mode === "START") ||
			(lastShiftClock?.type === "BREAK" && lastShiftClock?.mode === "END")
		) {
			return "bg-gradient-launch";
		} else if (
			lastShiftClock?.type === "BREAK" &&
			lastShiftClock?.mode === "START"
		) {
			return "bg-gradient-break";
		} else {
			return "bg-dark-blue";
		}
	}, [lastShiftClock]);

	return (
		<>
			<Card className={cardClassname}>
				<CardBody className="px-3">
					<Row>
						<div className="col">
							<CardTitle
								tag="h5"
								className="text-uppercase text-white mb-0"
							>
								{mode === "break"
									? t("break-duration")
									: t("todays-shift")}
							</CardTitle>

							<span className="h2 font-weight-bold mb-0 text-white">
								{mode === "end"
									? lastShiftDuration
									: lastClockDuration}
							</span>
						</div>

						<Col className="col-auto">
							<span className="text-white">
								{moment().format("dddd, MMMM DD, yyyy")}
							</span>
						</Col>
					</Row>

					<p className="my-3 mb-0 text-sm">
						<span className="text-nowrap text-white">
							{sinceLastClock}
						</span>
					</p>
				</CardBody>

				<CardFooter className="bg-gradient-launch m-0 px-3 py-2 d-flex justify-content-between border-white">
					<h4 className="text-white mr-1 text-xs">
						{t("total-paid-hours-for")}{" "}
						{moment().format("dddd, MMMM DD, YYYY")}
					</h4>

					<h4 className="text-white text-xs">
						{workSummary &&
							(user?.calculationGroup ||
								qrCode?.user?.calculationGroup) &&
							renderDurationAsFormat(
								getPaidHours({
									...workSummary,
									calculationGroup: user?.calculationGroup,
								}),
								"HH:mm:ss",
							)}
					</h4>
				</CardFooter>
			</Card>

			<Card
				style={{
					backgroundColor: "#f3f3f3",
				}}
			>
				<CardBody className="d-flex flex-column justify-content-between rounded ">
					<Row>
						<Col>
							<CardTitle className="text-dark mb-0 text-xs font-weight-bolder">
								{lastGeoLocation}
							</CardTitle>
						</Col>
					</Row>

					<Row className="mt-4">
						<Col>
							<Progress clocks={shift?.clocks} />
						</Col>
					</Row>

					<Row className="d-flex justify-content-between">
						<p className="col mb-0 text-xs">
							<span className="text-nowrap text-dark">
								{shift &&
									dateTimeFormat(
										shift?.startDate,
										user?.companies[0]?.settings.timeFormat,
									)}
							</span>
						</p>

						<p className="col mb-0 text-xs">
							<span className="text-nowrap text-dark">
								{shiftStatusText}
							</span>
						</p>
					</Row>
				</CardBody>
			</Card>

			<Row className="d-flex flex-column mb-3">
				<div className="col">
					<h5 className="text-info">{t("location")}</h5>
					<p className="text-dark text-sm">
						{shift?.location
							? renderSettingsResourceLabel(shift?.location)
							: "--"}
					</p>
				</div>

				<div className="col">
					<hr className="py-0 my-0 mb-3" />
					<h5 className="text-info">{t("job")}</h5>
					<p className="text-dark text-sm">
						{shift?.job
							? renderSettingsResourceLabel(shift?.job)
							: "--"}
					</p>
				</div>

				<div className="col">
					<hr className="py-0 my-0 mb-3" />
					<h5 className="text-info">
						{shift?.project?.orgLevelGroup?.description}
					</h5>
					<p className="text-dark text-sm">
						{shift?.project
							? renderOrgLevelResourceLabel(shift?.project)
							: "--"}
					</p>
				</div>
			</Row>

			{canClockInOnQrcode && (
				<div className="w-100 pb-5">
					<Actions qrCode={qrCode} />
				</div>
			)}
		</>
	);
}

export default Running;
