import classNames from "classnames";
import { Modal as BaseModal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./style.scss";

function Modal({ title, children, className, footer, ...props }) {
	return (
		<BaseModal
			backdrop={true}
			className={classNames("w-100 base-modal", className)}
			{...props}
		>
			{title && (
				<ModalHeader className="py-3 border-bottom w-100">
					{title}
				</ModalHeader>
			)}

			<ModalBody className="py-3 w-100">{children}</ModalBody>

            {footer && (
                <ModalFooter className="border-top">
                    {footer}
                </ModalFooter>
            )}
		</BaseModal>
	);
}

export default Modal;
