import { useCallback } from "react";
import moment from "moment-timezone";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../utils/helpers/date";

export const useCalculatedSegments = ({ baseSegments, defaultSegment, date }) => {
	const getCalculatedSegmentsFromEnd = useCallback((endDate, data) => {
		let segments = [];
		const seg = [
			...(data || baseSegments || [])
		];

		for (let i = 0; i < seg?.length; i++) {
			const segment = seg[i];
			const start = combineDateAndTime(moment(date), moment(segment?.start));
			let diff = moment(endDate).diff(moment(start), "seconds");

			if (diff >= 900) {
				const nextSegment = seg[i + 1];
				if (segment?.type === "break") {
					const amount = 900 + (Number(segment?.amount || 0) * 60);
					if (diff >= amount) {
						if (nextSegment) {
							const duration = moment(segment?.end).diff(moment(segment?.start), "seconds");
							segments.push({
								...segment,
								duration: renderDurationAsFormat(duration || 0, "HH:mm")
							});
						} else {
							const firstDuration = moment(segment?.end).diff(moment(segment?.start), "seconds");
							const secondDuration = moment(endDate).diff(moment(segment?.end), "seconds");
							const secondShiftEnd = secondDuration < 900 ? moment(segment?.end).clone().add(900, "seconds") : endDate;
							const secondShiftDuration = moment(secondShiftEnd).diff(moment(segment?.end), "seconds");
							segments.push(
								{ 
									...segment,
									duration: renderDurationAsFormat(firstDuration || 0, "HH:mm")
								},
								{
									...defaultSegment,
									type: "regular",
									start: segment?.end,
									end: secondShiftEnd,
									duration: renderDurationAsFormat(secondShiftDuration || 0, "HH:mm")
								}
							);
						}
					} else {
						const duration = moment(endDate).diff(moment(segments?.[i - 1]?.start), "seconds");
						segments[i - 1] = {
							...segments[i - 1],
							end: endDate,
							duration: renderDurationAsFormat(duration || 0, "HH:mm")
						}
					}
				} else {
					const end = i === seg?.length - 1 ? endDate : segment.end;
					const duration = moment(end).diff(moment(segment?.start), "seconds");
					segments.push({
						...segment,
						end,
						duration: renderDurationAsFormat(duration || 0, "HH:mm")
					});
				}
			} else {
				if (segments[i - 1]) {
					const duration = moment(endDate).diff(moment(segments[i - 1]?.start), "seconds");
					segments[i - 1] = {
						...segments[i - 1],
						end: endDate,
						duration: renderDurationAsFormat(duration || 0, "HH:mm")
					}
				}
			}
		}
		return segments;
	}, [baseSegments, defaultSegment, date]);

	const getCalculatedSegmentsFromStart = useCallback((startDate, data) => {
		let segments = [];
		const seg = data || baseSegments;

		for (let i = 0; i < seg?.length; i++) {
			const segment = seg[i];
			const end = combineDateAndTime(moment(date), moment(segment?.end));
			let diff = moment(end).diff(moment(startDate), "seconds");
			if (diff >= 900) {
				if (segment?.type === "break") {
					const amount = 900 + (Number(segment?.amount || 0) * 60);
					if (diff >= amount) {
						segments.push({
							...segment,
							duration: renderDurationAsFormat(amount || 0, "HH:mm")
						});
					}
				} else {
					const prevSegment = segments[segments?.length - 1];
					if (prevSegment) {
						if (prevSegment?.type !== segment?.type) {
							const duration = moment(segment?.end).diff(moment(segment?.start), "seconds");
							segments.push({
								...segment,
								duration: renderDurationAsFormat(duration, "HH:mm")
							});
						} else {
							const duration = moment(segment?.end).diff(moment(prevSegment?.start || startDate), "seconds");
							segments[i - 1] = {
								...prevSegment,
								start: prevSegment?.start || startDate,
								end: segment?.end,
								duration: renderDurationAsFormat(duration, "HH:mm")
							}
						}
					} else {
						const duration = moment(segment?.end).diff(moment(segment?.start), "seconds");
						segments.push({ 
							...segment, 
							start: startDate,
							duration: renderDurationAsFormat(duration, "HH:mm")
						})
					}
				}
			}
		}
		return segments;
	}, [baseSegments, date]);

	return {
		getCalculatedSegmentsFromEnd,
		getCalculatedSegmentsFromStart
	}
}