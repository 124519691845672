import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
    useCompanyDateFormat,
    useCompanyTimeFormat,
    useIsMasterCompany,
} from "../../../../utils/hooks/company";
import { dateTimeFormat } from "../../../../utils/helpers/date";
import moment from "moment-timezone";
import { firstToUpper } from "../../../../utils/helpers/string";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone, openCalendar }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();
    const timeFormat = useCompanyTimeFormat();
    const isMasterCompany = useIsMasterCompany();

    const { code, description, status } = useCommonColumns();

    return useMemo(() => {
        const columns = [
            code,
            description,
            status,
            columnHelper.accessor("frequency", {
                header: t("frequency"),
                cell: (info) => {
                    const value = info.getValue();
                    return value === "semi-monthly"
                        ? t("Semi monthly")
                        : firstToUpper(value);
                },
            }),
            columnHelper.accessor("periodStartDate", {
                header: t("period-start-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && dateTimeFormat(value, dateFormat);
                },
            }),
            columnHelper.accessor("periodEndDate", {
                header: t("period-end-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && dateTimeFormat(value, dateFormat);
                },
            }),
            columnHelper.accessor("timesheet-lock", {
                header: t("timesheet-lock"),
                enableSorting: false,
                columns: [
                    columnHelper.accessor("timesheetLockDate", {
                        header: t("date"),
                        cell: (info) => {
                            const { periodEndDate, timeSheetLockDate } =
                                info.row.original;
                            return (
                                periodEndDate &&
                                moment
                                    .parseZone(periodEndDate)
                                    .add(timeSheetLockDate, "day")
                                    .format(dateFormat)
                            );
                        },
                    }),
                    columnHelper.accessor("time", {
                        header: t("time"),
                        cell: (info) => {
                            const { time } = info.row.original;
                            return time && dateTimeFormat(time, timeFormat);
                        },
                    }),
                ],
            }),
        ];
        if (isMasterCompany && (edit || remove || clone)) {
            const actions = columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableColumnFilter: false,
                header: () => <div className="text-right">{t("actions")}</div>,
                cell: (info) => {
                    const { id, company, isDefault } = info.row.original;

                    return (
                        <div className="actions text-primary text-right">
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => openCalendar(info.row.original)}
                                title={t("Calendar")}
                            >
                                <i className="fas fa-calendar-days" />
                            </span>

                            {company && !isDefault && edit && (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                    onClick={() => edit(id)}
                                    title={t("Edit")}
                                >
                                    <i className="fas fa-pen" />
                                </span>
                            )}

                            {company && !isDefault && remove && (
                                <span
                                    className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                    onClick={() => remove(id)}
                                    title={t("Delete")}
                                >
                                    <i className="fas fa-trash" />
                                </span>
                            )}

                            {clone && (
                                <span
                                    className="btn-inner--icon icon-sm cursor-pointer"
                                    onClick={() => clone(id)}
                                    title={t("Clone")}
                                >
                                    <i className="ni ni-single-copy-04" />
                                </span>
                            )}
                        </div>
                    );
                },
            });
            columns.push(actions);
        }
        return columns;
    }, [
        t,
        isMasterCompany,
        openCalendar,
        edit,
        remove,
        clone,
        code,
        description,
        status,
        dateFormat,
        timeFormat,
    ]);
};
