import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDragDropManager, useDrop } from "react-dnd";
import { ReactSortable } from "react-sortablejs";
import Form, { Field as BaseField } from "rc-field-form";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import Button from "../../../../components/Button";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Menu from "./Menu";
import ColumnsList from "./ColumnsList";
import PreviewModal from "./PreviewModal";
import { useReports } from "../api";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { ReportColumnsContext } from "./context";
import { useAccess } from "../../../../utils/hooks/access";
import { useScroll } from "./useScroll";
import { useColumnsMap } from "../helpers";

function CreateReport({ onCancel, setStep, setData, data: reportData, mode }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const ref = useRef(null);

	const { hasAccess: canCreate } = useAccess("reports.canCreate");
	const { hasAccess: canEdit } = useAccess("reports.canEdit");

	const { columns: allColumns } = useContext(ReportColumnsContext);
	const { getColumns } = useColumnsMap();

	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

	const [columns, setColumns] = useState(
		getColumns(reportData?.columns) || [],
	);

	const { create, loading, error, update } = useReports();

	const [_, drop] = useDrop(() => ({
		accept: "item",
		drop: (item) => {
			setColumns((prev) => {
				const column = prev.find((col) => col.key === item.key);

				if (column) {
					return prev;
				} else {
					return [...prev, item];
				}
			});
		},
	}));

	const openPreviewModal = useCallback(
		() => setIsPreviewModalOpen(true),
		[setIsPreviewModalOpen],
	);

	const closePreviewModal = useCallback(() => {
		setIsPreviewModalOpen(false);
	}, [setIsPreviewModalOpen]);

	const formattedData = useCallback(
		(response) => {
			const columns = getColumns(response.columns);

			setData({
				columns,
				name: response.name,
				id: response.id,
			});
			setStep(2);
		},
		[allColumns, setStep, setData, getColumns],
	);

	const onFinish = useCallback(
		(values) => {
			const data = {
				name: values.name,
				status: "active",
				columns: columns
					?.filter(({ onlyFilter }) => !onlyFilter)
					?.map(({ key }) => key),
			};

			if (mode === "edit" && reportData?.id) {
				update(reportData.id, data, formattedData);
			} else {
				create(data, formattedData);
			}
		},
		[columns, create, update, formattedData, mode, reportData],
	);

	const cancel = useCallback(() => {
		onCancel();
		setColumns([]);
	}, [onCancel, setColumns]);

	useEffect(() => {
		form.setFieldsValue({
			name: reportData?.name ? reportData.name : "",
		});
	}, [form, reportData]);

	const title = useMemo(() => {
		switch (mode) {
			case "clone":
				return t("clone-report");
			case "edit":
				return t("edit-report");
			case "create":
			default:
				return t("create-report");
		}
	}, [mode, t]);

	const disabled = useMemo(() => {
		switch (mode) {
			case "edit": {
				if (canEdit) {
					return false;
				} else {
					return true;
				}
			}
			case "clone":
			case "create":
			default: {
				if (canCreate) {
					return false;
				} else {
					return true;
				}
			}
		}
	}, [mode, canEdit, canCreate]);

	const list = useMemo(() => {
		return columns
		?.filter(
			({ onlyFilter }) => !onlyFilter,
		)
	}, [columns]);

	useEffect(() => {
		const errors = generateErrorsConfigForForm(["name"], error);
		form.setFields(errors);
	}, [form, error]);

	const dragAndDropScroll = useDragDropManager();
	const monitor = dragAndDropScroll.getMonitor();

	const { updatePosition } = useScroll(ref);

	useEffect(() => {
		monitor.getClientOffset(({ offset }) => {
			updatePosition({ position: offset, isScrollAllowed: true });
		}, 1);
	}, [updatePosition, monitor]);

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<CardHeader className="d-flex justify-content-between align-items-center">
					<h3 className="mb-0">{title}</h3>

					<Button
						className="border border-dark text-primary"
						size="sm"
						onClick={cancel}
					>
						{t("cancel")}
					</Button>
				</CardHeader>

				<CardBody>
					<Form form={form} onFinish={onFinish}>
						<Row className="align-items-center mb-3">
							<Col sm={12} md={6} className="mb-2">
								<Field
									label={t("report-name")}
									name="name"
									rules={[
										{
											required: true,
											message: t(
												"required-report-name",
											),
										},
									]}
								>
									<Text
										placeholder={t("type-report-name")}
										disabled={disabled}
									/>
								</Field>
							</Col>

							<Col sm={12} md={6} className="text-sm-right">
								<BaseField>
									{({ }, { }, { getFieldValue }) => (
										<>
											<Button
												className="text-primary px-5"
												size="sm"
												hidden={
													columns?.length === 0 ||
													getFieldValue("name") ===
													"" ||
													getFieldValue("name") ===
													undefined
												}
												type="button"
												onClick={openPreviewModal}
												disabled={loading}
											>
												{t("preview")}
											</Button>

											<Button
												color="dark px-5"
												size="sm"
												hidden={
													disabled ||
													columns?.length === 0 ||
													getFieldValue("name") ===
													"" ||
													getFieldValue("name") ===
													undefined
												}
												type="submit"
												loading={loading}
											>
												{t(mode)}
											</Button>
										</>
									)}
								</BaseField>
							</Col>
						</Row>
					</Form>

					<Row
						style={{
							height: "calc(100% - 110px)",
						}}
					>
						<Col
							sm={12}
							md={3}
							className="mb-3 reports-scroll menu-scroll"
						>
							<Menu columns={columns} />
						</Col>

						<Col
							sm={12}
							md={9}
							style={{
								height: "calc(100% - 1rem)",
							}}
						>
							<h3>
								{t("drop-items-to-create-table")}
							</h3>

							<div
								ref={drop}
								className="border rounded w-100 reports-scroll columns-list-scroll"
								style={{
									overflowX: "auto",
								}}
							>
								<div ref={ref} className="h-100 p-4">
									<ReactSortable
										list={list}
										setList={setColumns}
										style={{
											display: "flex",
										}}
									>
										{columns
											?.filter(
												({ onlyFilter }) => !onlyFilter,
											)
											?.map((item, index) => (
												<ColumnsList
													item={item}
													setColumns={setColumns}
													index={index}
												/>
											))}
									</ReactSortable>
								</div>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>

			<PreviewModal
				isOpen={isPreviewModalOpen}
				columns={columns}
				closePreviewModal={closePreviewModal}
				reportTitle={form.getFieldValue("name")}
			/>
		</div>
	);
}

export default CreateReport;
