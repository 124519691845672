import { useCallback, useState } from "react";
import useApi from "..";

export const useRoleApi = () => {
	const { authGet } = useApi();

	const getRole = useCallback(
		(id) => authGet(`/user-roles/${id}`),
		[authGet]
	);

	const getRoleByCode = useCallback(
		(code) =>
			authGet("/user-roles", {
				params: { code },
			}),
		[authGet]
	);

	return { getRole, getRoleByCode };
};

export const useGetRoleByCode = () => {
	const { getRoleByCode } = useRoleApi();

	const [data, setData] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetch = useCallback(
		async (code) => {
			setLoading(true);
			setError(null);
			try {
				const response = await getRoleByCode(code);
				if (response && response.result.length > 0) {
					setData(response.result[0]);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, setData, getRoleByCode]
	);

	return { data, loading, error, fetch };
};
