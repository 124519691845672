import { isValidElement, cloneElement, useMemo } from "react";
import { Field as BaseField } from "rc-field-form";
import ErrorMessage from "./ErrorMessage";
import { FormGroup, Label } from "reactstrap";

const mergeProps = (base, next) => {
	return Object.entries(next).reduce((total, [key, value]) => {
		if (!(key in total)) {
			return { ...total, [key]: value };
		}
		switch (typeof value) {
			case "function": {
				return {
					...total,
					[key]: (...args) => {
						const baseEquivalent = base[key];
						if (typeof baseEquivalent === "function") {
							baseEquivalent(...args);
						}
						value(...args);
					},
				};
			}
			default: {
				return { ...total, [key]: value };
			}
		}
	}, base);
};

function Field({ className, name, label, children, hidden, ...props }) {
	if (!isValidElement(children)) {
		throw new Error("'Field' accepts only 1 child");
	}

	const required = useMemo(() => {
		if (!props.rules) {
			return false;
		}
		return props.rules.find(({ required }) => required);
	}, [props.rules]);

	return (
		<FormGroup className={className} hidden={hidden}>
			{(label && !hidden) && (
				<Label for={name}>
					{label}{" "}
					<small className="text-red">{required && "*"}</small>
				</Label>
			)}

			<BaseField name={name} {...props} hidden={hidden}>
				{(controll, form) => (
					<>
						{cloneElement(
							children,
							mergeProps(children.props, {
								id: name,
								name,
								...controll,
								hasError: form.errors.length > 0,
							}),
						)}

						{form.errors.length > 0 && (
							<ErrorMessage>{form.errors}</ErrorMessage>
						)}
					</>
				)}
			</BaseField>
		</FormGroup>
	);
}

export default Field;
