import { useState, useEffect, useCallback } from "react";
import { useQrCodeClockIn } from "../context";

export const useRevalidateOnFocus = () => {
	const { dispatch } = useQrCodeClockIn();
	const [hasFocus, setHasFocus] = useState(true);

	const onFocus = useCallback(
		() => setHasFocus(!hasFocus),
		[setHasFocus, hasFocus],
	);

	useEffect(() => {
		window.addEventListener("focus", onFocus);

		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, [onFocus]);

	useEffect(() => {
		if (!hasFocus) {
			dispatch({ type: "reset-for-validation" });
		}
	}, [hasFocus, dispatch]);
};
