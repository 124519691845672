import moment from "moment-timezone";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { currencyFormatter } from "../../../../../../utils/helpers/currencyFormatter";
import {
	renderDurationAsFormat,
} from "../../../../../../utils/helpers/date";
import {
	renderOrgLevelResourceLabel,
} from "../../../../../../utils/helpers/settings";
import {
	useCompanyCurrency,
	useCompanyDateFormat,
	useCompanyTimeFormat,
} from "../../../../../../utils/hooks/company";
import { OrgLevelGroupsContext } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderHourType } from "../../../../../Settings/PayPolicies/PayCode/columns";
import { getSharedDateRowSpan } from "../../../../components/TimeSheetTable/helpers";
import { getHourTypeColor } from "../../../../../../utils/helpers/hourType";

export const useColumns = ({ loading, dateTimesheetMap, response }) => {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
	const currency = useCompanyCurrency();
	const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();

	const allocatedByGroup = useMemo(
		() => groups?.find((group) => group?.allocatedBy),
		[groups],
	);

	return useMemo(() => {
		const columns = [
			{
				Header: t("date"),
				accessor: "date",
				headCustomProps: {
					className: "px-2",
				},
				getCellCustomProps: (timeSheet) => {
					return {
						rowSpan: getSharedDateRowSpan(
							dateTimesheetMap,
							timeSheet,
						),
					};
				},
				hideable: false,
				Cell: ({ value }) => value && moment(value).format(dateFormat),
			},
			{
				Header: t("in"),
				accessor: "startDate",
				Cell: ({ value, row: { original } }) => {
					// const timezone = getTimeSheetTimeZone(original);
					// let date = combineDateAndTime(
					// 	moment(original?.date, "YYYY-MM-DD"),
					// 	moment(value),
					// );
					// date = applyTimezone(date, timezone, true);
					// console.log(date.toISOString(true), timezone)
					return (
						<span className="text-white bg-green font-weight-bold p-2 rounded">
							{value && moment.parseZone(value).format(timeFormat)}
						</span>
					);
				},
			},
			{
				Header: t("out"),
				accessor: "endDate",
				Cell: ({ value, row: { original } }) => {
					// const timezone = getTimeSheetTimeZone(original);
					// let date = combineDateAndTime(
					// 	moment(original?.date, "YYYY-MM-DD"),
					// 	moment(value),
					// );
					// date = applyTimezone(date, timezone, true);
					// console.log(date.toISOString(true), timezone)
					return (
						<span className="text-white bg-red font-weight-bold p-2 rounded">
							{value && moment.parseZone(value).format(timeFormat)}
						</span>
					);
				},
			},
			{
				Header: t("time-code"),
				accessor: "payCode",
				Cell: ({ value }) =>
					value && (
						<span
							className="p-2 font-weight-bold rounded"
							style={{
								backgroundColor: getHourTypeColor(value.hourType),
								color: value?.hourType === "unpaid" ? "#00080e" : "white",
							}}
						>
							{value.code}
						</span>
					),
			},
			{
				Header: t("hour-type"),
				accessor: "hourType",
				Cell: ({ value }) => value && renderHourType(value),
			},
			{
				Header: t("duration"),
				accessor: "duration",
				Cell: ({ value }) =>
					value && renderDurationAsFormat(value, timeFormat),
			},
			{
				Header: t("total"),
				accessor: "total",
				Cell: ({ value }) =>
					value && currencyFormatter(value, 2, currency),
			},
			{
				Header: t("status"),
				accessor: "message",
				Cell: ({
					row: {
						original: { date },
					},
				}) =>
					response ? (
						<span
							className={classNames(
								response[date]?.status
									? "text-green"
									: "text-danger",
							)}
						>
							{response[date]?.message}
						</span>
					) : loading ? (
						`${t("copying")}...`
					) : (
						t("ready-to-copy")
					),
			},
		];

		if (allocatedByGroup) {
			columns?.splice(7, 0, {
				Header: allocatedByGroup.description,
				accessor: allocatedByGroup.level.replace("_", ""),
				Cell: ({ value }) =>
					value && typeof value === 'object' && Object.keys(value).includes('code') && renderOrgLevelResourceLabel(value),
			});
		}

		return columns;
	}, [
		response,
		allocatedByGroup,
		currency,
		timeFormat,
		dateFormat,
		loading,
		dateTimesheetMap,
		t,
	]);
};
