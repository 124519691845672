import { useCallback, useMemo, useState } from "react";
import { Card } from "reactstrap";
import { ReportColumnsProvider } from "./context";
import ReportDisplay from "./ReportDispaly";
import Home from "./Home";
import "./style.scss";

function CustomReports() {
	const [step, setStep] = useState(0);
	const [mode, setMode] = useState(null);
	const [data, setData] = useState({
		name: "",
		columns: [],
		columnLabels: {},
		id: undefined,
	});

	const onCancel = useCallback(() => {
		setStep(0);
		setData({
			name: "",
			columns: [],
			id: undefined,
			columnLabels: {},
		});
	}, [setStep, setData]);

	const content = useMemo(() => {
		switch (step) {
			case 1:
				return <ReportDisplay data={data} goBack={onCancel} />;
			case 0:
			default:
				return (
					<Home
						setStep={setStep}
						setData={setData}
						data={data}
						setMode={setMode}
					/>
				);
		}
	}, [step, data, setData, setStep, onCancel, mode]);

	return (
		<ReportColumnsProvider>
			<Card className="h-100">
				{content}
			</Card>
		</ReportColumnsProvider>
	);
}

export default CustomReports;
