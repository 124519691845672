import { Field } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../components/Inputs/Checkbox";

function OptionsTable({ fields }) {
    const { t } = useTranslation()

    return (
        <table border="0" className="d-block">
            <thead>
                <tr>
                    <th />

                    <th className="text-center px-3 py-2 text-dark">
                        {t("hide")}
                    </th>
                </tr>
            </thead>

            <tbody>
                {fields.map((field) => (
                    <tr key={field.name.toString()}>
                        <td className="pr-3 pb-2 font-weight-bold">
                            {field.label}
                        </td>

                        <td className="px-3 py-1">
                            <div className="d-flex align-items-center justify-content-center">
                                <Field name={field.name} className="mb-0" valuePropName="checked">
                                    <Checkbox id={field.name} labelClassName="px-2" disabled={field.disabled} />
                                </Field>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default OptionsTable;
