import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../components/Field";
import Select from "../../../../../../components/Inputs/Select";
import EmailOption from "./EmailOption";
import FtpOption from "./FtpOption";

function SendOptionSelect({ form, disabled }) {
	const { t } = useTranslation();

	const content = useCallback(
		(option) => {
			switch (option) {
				case "email": {
					return <EmailOption form={form} disabled={disabled} />;
				}
				case "ftp": {
					return <FtpOption disabled={disabled} />;
				}
				default: {
					return undefined;
				}
			}
		},
		[form, disabled],
	);

	return (
		<>
			<Field
				name="sendVia"
				label={t("send-via")}
				rules={[
					{
						required: true,
						message: t("required-send-option"),
					},
				]}
			>
				<Select placeholder={t("send-option")} disabled={disabled}>
					<Select.Option value="email">{t("email")}</Select.Option>
					<Select.Option value="ftp">{t("ftp-server")}</Select.Option>
				</Select>
			</Field>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const option = getFieldValue("sendVia");

					return content(option);
				}}
			</BaseField>
		</>
	);
}

export default SendOptionSelect;
