import { Button as BaseButton } from "reactstrap";
import classNames from "classnames";

function Button({ loading, icon, className, children, ...props }) {
    return (
        <BaseButton
            className={classNames(className, icon && "btn-icon")}
            {...props}
        >
            <span className='btn-inner--icon'>
                {loading ? (
                    <div
                        className="spinner spinner-border spinner-border-sm"
                        role="status"
                    />
                ) : icon}
            </span>

            <span className='btn-inner--text'>
                {children}
            </span>
        </BaseButton>
    );
}

export default Button;
