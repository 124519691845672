import { useState, useCallback, useEffect } from "react";
import Team from "../../TimeClock/MyTeam/Team";
import Map from "../../TimeClock/MyTeam/Map";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import { useMyTeam } from "../../TimeClock/MyTeam/api";
import Statistic from "./Statistic";
import TaskList from "./TaskList";
import { useVisible } from "../../../utils/hooks/useVisible";
import "./style.scss";

const perPage = 10;

function ManagerDashboard() {
	const [status, setStatus] = useState("clocked-in");
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState();
	const { visible, open, selected, close } = useVisible();

	const { data, loading, fetch, totalItems, setData } = useMyTeam();

	const onSearchChange = useCallback(
		(value) => {
			setSearch(value);

			if (page !== 1) {
				setPage(0);
			} else {
				fetch({ page, perPage, status, search: value });
			}
		},

		[status, setPage, page, fetch]
	);

	useEffect(() => {
		const controller = new AbortController();

		fetch({ status, page: page, perPage, search }, controller);

		return () => controller.abort();

	}, [fetch, status, page, search]);

	return (
		<>
			<div className="d-flex flex-wrap mb-2 w-100 manager-dashboard">
				<div className="col-12 col-lg-4 h-100 d-md-flex flex-md-column px-0 px-lg-2 filters">
					<TaskList />
				</div>

				<div className="col-12 col-lg-4 h-100 d-md-flex flex-md-column px-0 px-lg-2 filters  dashboard-timeclock-team team-card">
					<Team
						status={status}
						setStatus={setStatus}
						setPage={setPage}
						onSearchChange={onSearchChange}
						data={data}
						loading={loading}
						totalItems={totalItems}
						page={page}
						openProfileDrawer={open}
						setData={setData}
					/>
				</div>

				<div className="col-12 col-lg-4 h-100 d-md-flex flex-md-column px-0 px-lg-2">
					<Map data={data} />
				</div>
				<div className="w-100">
					<Statistic />
				</div>
				<ProfileDrawer user={selected} close={close} visible={visible} />
			</div>
		</>
	);
}

export default ManagerDashboard;
