import { useCallback, useEffect, useMemo, useState } from "react";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import pick from "lodash/pick";
import { selectionColumn } from "../commonColumns/selectionColumn";

import { useSessionFilters } from "./useSessionFilters";
import { useTableInitialState } from "./useTableInitialState";
import { isEqual } from "lodash";

const emptyData = [];

export const useNewTable = ({
	data = emptyData,
	totalItems = 0,
	columns: baseColumns,

	loading,
	sessionFiltersKey,

	rowIdProperty = "id",

	initialState,

	meta,
	getRowProps,

	state: _state,
	onStateChange: _onStateChange,

	...options
}) => {
	const columns = useMemo(() => {
		if (options.enableRowSelection === false) {
			return baseColumns;
		}
		return [selectionColumn, ...baseColumns];
	}, [baseColumns, options.enableRowSelection]);

	const [sessionFilters, onSessionFiltersChange] = useSessionFilters(sessionFiltersKey);

	const tableInitialState = useTableInitialState({
		sessionFilters: sessionFiltersKey ? sessionFilters : undefined,
		initialState,
		columns,
	});
	const [internalState, onInternalStateChange] = useState(tableInitialState);

	const [_initialState] = useState(_state || tableInitialState);
	const state = (_state && _onStateChange)
		? _state
		: internalState;
	const onStateChange = (_state && _onStateChange)
		? _onStateChange
		: onInternalStateChange;

	const table = useReactTable({
		initialState: _initialState,
		meta: useMemo(() => ({
			...meta,
			totalItems,
			loading,
			getRowProps,
		}), [meta, totalItems, loading, getRowProps]),
		defaultColumn: useMemo(() => ({
			enableColumnFilter: false,
			enableHiding: true,
			filterType: "text",
		}), []),
		columns,
		data,

		pageCount: Math.ceil(totalItems / state.pagination.pageSize),
		getCoreRowModel: getCoreRowModel(),

		state,
		onStateChange: useCallback((updater) => {
			onStateChange((prevState) => {
				const newState = typeof updater === "function"
					? updater(prevState)
					: updater;
				if (
					!isEqual(prevState.columnFilters, newState.columnFilters)
					|| !isEqual(prevState.sorting, newState.sorting)
					|| !isEqual(prevState.pagination.pageSize, newState.pagination.pageSize)
				) {
					return {
						...newState,
						pagination: {
							...newState.pagination,
							pageIndex: 0,
						},
					};
				}
				return newState;
			});
		}, [onStateChange]),

		getRowId: useCallback((row, _, parent) => {
			const id = row[rowIdProperty];
			if (parent) {
				return [parent.id, id].join('.');
			}
			return id;
		}, [rowIdProperty]),

		manualPagination: true,
		manualFiltering: true,
		manualSorting: true,

		enableSorting: true,
		enableFilters: true,
		enableRowSelection: true,
		enableHiding: true,
		...options,

		/*
		stateReducer: useCallback((state, action, prevState) => {
			let newState = { ...state };
			if (
				action.type === "setGlobalFilter"
				&& !isEqual(newState.globalFilter, prevState.globalFilter)
			) {
				newState.pageIndex = 0;
			}
			if (hasURLBindedFilters) {
				if (
					!isEqual(newState.pageIndex, prevState.pageIndex)
					|| !isEqual(newState.pageSize, prevState.pageSize)
					|| !isEqual(newState.sortBy, prevState.sortBy)
					|| !isEqual(newState.globalFilter, prevState.globalFilter)
				) {
					onTableChange(newState);
				}
			}
			return newState;
		}, [hasURLBindedFilters, onTableChange]),
	},
	(hooks) => {
		hasRowSelection &&
			hooks.visibleColumns.push((columns) => [
				{
					id: "selection",
					Header: ({
						getToggleAllRowsSelectedProps,
						toggleRowSelected,
						isAllPageRowsSelected,
						page,
					}) => {
						const modifiedOnChange = (event) => {
							page.forEach((row) => {
								!row.original.isDefault &&
									row.original.company !== "" &&
									toggleRowSelected(
										row.id,
										event.currentTarget.checked,
									);
							});
						};

						let selectableRowsInCurrentPage = 0;
						let selectedRowsInCurrentPage = 0;
						page.forEach((row) => {
							row.isSelected && selectedRowsInCurrentPage++;
							!row.original.isDefault &&
								row.original.company !== "" &&
								selectableRowsInCurrentPage++;
						});

						const disabled = selectableRowsInCurrentPage === 0;
						const checked =
							(isAllPageRowsSelected ||
								selectableRowsInCurrentPage ===
									selectedRowsInCurrentPage) &&
							!disabled;

						return (
							<Checkbox
								id="selection-all"
								{...getToggleAllRowsSelectedProps()}
								onChange={modifiedOnChange}
								checked={checked}
							/>
						);
					},
					Cell: ({ row }) => (
						<Checkbox
							id={`selection-${row.id}`}
							{...row.getToggleRowSelectedProps()}
							disabled={
								row.original.isDefault ||
								row.original.company === ""
							}
						/>
					),
				},
				...columns,
			]);
		getRowProps && hooks.getRowProps.push(getRowProps);
		getCellProps && hooks.getCellProps.push(getCellProps);
	*/
	});

	const tableState = table.getState();
	useEffect(() => {
		if (sessionFiltersKey) {
			const filters = pick(tableState, ["pagination", "columnFilters", "sorting"]);
			onSessionFiltersChange(filters);
		}
	}, [sessionFiltersKey, tableState, onSessionFiltersChange]);

	return table;
};
