import { useEffect, useState, useRef } from "react";

function getScrollDirection({
	position,
	rightBounds = Infinity,
	leftBounds = -Infinity,
	boundWidth = 0,
}) {
	if (position === undefined) {
		return "stable";
	}
	if (position > leftBounds - boundWidth) {
		return "left";
	}
	if (position < rightBounds + boundWidth) {
		return "right";
	}
	return "stable";
}

export const useScroll = (ref) => {
	const [config, setConfig] = useState({
		position: 0,
		isScrollAllowed: false,
	});

	const scrollTimer = useRef(null);

	const scrollSpeed = 1;
	const { position, isScrollAllowed } = config;

	const bounds = ref.current?.getBoundingClientRect();

	const direction = getScrollDirection({
		position,
		leftBounds: bounds?.left,
		rightBounds: bounds?.right,
		boundsWidth: bounds?.width,
	});

	useEffect(() => {
		if (direction !== "stable" && isScrollAllowed) {
			scrollTimer.current = setInterval(() => {
				ref.current?.scrollBy(
					scrollSpeed * (direction === "right" ? -1 : 1),
					0,
				);
			}, 1);
		}
		return () => {
			if (scrollTimer.current) {
				clearInterval(scrollTimer.current);
			}
		};
	}, [isScrollAllowed, direction, ref, scrollSpeed]);

	return { updatePosition: setConfig };
};
