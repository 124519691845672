import { useMemo, useCallback, useState } from "react";
import classNames from "classnames";
import { faImage, faMapLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { ListGroupItem, Tooltip } from "reactstrap";
import GeofenceTooltip from "./GeofenceTooltip";
import ImageTooltip from "./ImageTooltip";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../../../../utils/hooks/company";
import { dateTimeFormat } from "../../../../../../../utils/helpers/date";
import { renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../../../../utils/helpers/settings";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { useVisible } from "../../../../../../../utils/hooks/useVisible";
import { renderDeviceIcon } from "../../../../../../../utils/helpers/shift/clock";

const Clock = ({ clock: { type, mode, time, device, project, location, job, user, id, image, geofence } }) => {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
	const dateFormat = useCompanyDateFormat();

	const {
		visible: isOpenGeofenceTooltip,
		open: openGeofenceTooltip,
		close: closeGeofenceTooltip,
		selected: selectedGeofence
	} = useVisible();

	const {
		visible: isOpenImageTooltip,
		open: openImageTooltip,
		close: closeImageTooltip,
		selected: selectedImage
	} = useVisible();

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const { groups } = useGroups();

	const useInClockInGroup = useMemo(() => groups?.find(({ useInClockIn }) => useInClockIn), [groups])

	const toggle = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [setIsTooltipOpen, isTooltipOpen]);

	const itemType = useMemo(() => {
		if (type === "BREAK") {
			return "checklist-item-warning";
		} else if (type === "SHIFT" && mode === "START") {
			return "checklist-item-info";
		} else {
			return "checklist-item-danger";
		}
	}, [type, mode]);

	const name = useMemo(() => {
		if (type === "BREAK") {
			if (mode === "START") {
				return t("started-break");
			} else {
				return t("ended-break");
			}
		} else {
			if (mode === "START") {
				return t("clocked-in");
			} else {
				return t("clocked-out");
			}
		}
	}, [t, mode, type]);

	return (
		<ListGroupItem
			className={classNames(
				"checklist-entry flex-column align-items-center py-4 px-1",
			)}
		>
			<div className={classNames("checklist-item", itemType)}>
				<div className="checklist-info d-flex flex-column">
					<div className="d-flex align-items-center">
						<h4 className="checklist-title mx-1 mb-0">{name}</h4>
						<span className="checklist-title mx-1 mb-1" style={{fontSize: '13px'}}>{dateTimeFormat(time, dateFormat)} {" - "}{dateTimeFormat(time, timeFormat)}</span>
					</div>

					<span className="mb-0 mx-1">
						<i className="fa-solid fa-briefcase mr-2" />
						{job && renderSettingsResourceLabel(job)
						}
					</span>

					<span className="mb-0 mx-1">
						<i className="fa-solid fa-location-dot mr-2" />
						{location && renderSettingsResourceLabel(location)}
					</span>

					<span className="mb-0 mx-1">
						<span className="font-weight-bolder text-sm mr-2 mb-1">{useInClockInGroup && useInClockInGroup?.description}:</span>
						{project && renderOrgLevelResourceLabel(project)}
					</span>
				</div>

				<div>
					{geofence &&
						<small
							id={`clock${location?.code + id}`}
							className={classNames(itemType, "cursor-pointer mx-2 text-lg")}
						>
							<FontAwesomeIcon
								className="text-lg text-gray cursor-pointer"
								icon={faMapLocation}
								onMouseEnter={() => openGeofenceTooltip({
									code: location?.code,
									id,
									geofence,
								})}
							/>
						</small>
					}

					{image &&
						<small
							id={`clock${id}`}
							className={classNames(itemType, "cursor-pointer mx-2")}
						>
							<FontAwesomeIcon
								className="text-lg text-gray cursor-pointer"
								icon={faImage}
								onMouseEnter={() => openImageTooltip({
									id,
									image,
									user
								})}
							/>
						</small>
					}

					<small
						id={`clock${device}`}
						className={classNames(itemType, "cursor-pointer ml-2")}
					>
						{renderDeviceIcon(device)}
					</small>
				</div>

				<Tooltip
					target={`clock${device}`}
					isOpen={isTooltipOpen}
					toggle={toggle}
					placement="left"
				>
					<span className="text-sm font-weight-bolder px-2">
						{t(device)}
					</span>
				</Tooltip>

				{selectedGeofence && <GeofenceTooltip
					isOpen={isOpenGeofenceTooltip}
					close={closeGeofenceTooltip}
					data={selectedGeofence}
				/>}

				{selectedImage && <ImageTooltip
					isOpen={isOpenImageTooltip}
					close={closeImageTooltip}
					data={selectedImage}
				/>}
			</div>
		</ListGroupItem>
	);
};

export default Clock;
