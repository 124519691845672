import {
	faChildReaching,
	faDesktop,
	faMessage,
	faMobileScreen,
	faPhone,
	faQrcode,
	faTabletScreenButton,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const isStartShift = ({ mode, type }) =>
	type === "SHIFT" && mode === "START";

export const isEndShift = ({ mode, type }) =>
	type === "SHIFT" && mode === "END";

export const isStartBreak = ({ mode, type }) =>
	type === "BREAK" && mode === "START";

export const isEndBreak = ({ mode, type }) =>
	type === "BREAK" && mode === "END";

export const renderDeviceIcon = (value) => {
	let icon;
	switch (value) {
		case "web-application": {
			icon = faDesktop;
			break;
		}
		case "mobile-application":
		case "phone": {
			icon = faMobileScreen;
			break;
		}
		case "qr-code": {
			icon = faQrcode;
			break;
		}
		case "sms": {
			icon = faMessage;
			break;
		}
		case "phone-call": {
			icon = faPhone;
			break;
		}
		case "device":
		case "physical-clock": {
			icon = faTabletScreenButton;
			break;
		}
		case "request": {
			icon = faChildReaching;
			break;
		}
		case "bulk-clock": {
			icon = faUserGroup;
			break;
		}
		default:
			return "";
	}
	if (!icon) {
		return "";
	}
	return (
		<FontAwesomeIcon
			className="text-lg text-gray cursor-pointer"
			icon={icon}
		/>
	);
};
