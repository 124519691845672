import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Option } from "rc-select";
import DateInput from "../../../../../components/Inputs/DateInput";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import Select from "../../../../../components/Inputs/Select";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { ProfileContext } from "../../../context";

function ChangeStatus({ close }) {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { changeStatus: profileSubmit } = useProfileApi();
	const { changeStatus: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(values) => {
			submit(profileSubmit, peopleSubmit, values, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			status: user?.status,
		});
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["status"],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="status"
				label={t("status")}
				rules={[
					{
						required: true,
						message: t("required-status"),
					},
				]}
			>
				<Select placeholder={t("status")}>
					<Option value="active">{t("active")}</Option>
					<Option value="leaveOfAbsence">
						{t("leave-of-absence")}
					</Option>
				</Select>
			</Field>

			<Field
				name="startDate"
				label={t("start-date")}
				rules={[
					{
						required: true,
						message: t("required-start-date"),
					},
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={t("start-date")} />
			</Field>

			<Field name="reason" label={t("reason")}>
				<Text placeholder={t("reason")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeStatus;
