import { useMemo } from "react";
import { useEmployeeNumberFilter } from "../../Team/Actions/useFilters/useEmployeeNumberFilter";
import { useEmployeeTypeFilter } from "../../Team/Actions/useFilters/useEmployeeTypeFilter";
import { usePayGroupFilter } from "../../Team/Actions/useFilters/usePayGroupFilter";
import { useProjectFilter } from "../../Team/Actions/useFilters/useProjectFilter";
import { useUnionFilter } from "../../Team/Actions/useFilters/useUnionFilter";
import { useNameFilter } from "./useNameFilter";
import { useHourTypeFilter } from "../../Home/Actions/useFilters/useHourTypeFilter";
import { useJobFilter } from "../../Home/Actions/useFilters/useJobFilter";
import { usePayCodeFilter } from "../../Home/Actions/useFilters/usePayCodeFilter";
import { useStatusFilter } from "../../Home/Actions/useFilters/useStatusFilter";
import { useHomeFilters } from "../../Home/Actions/useFilters/useHomeFilters";
import { useCalculationGroupFilter } from "../../Team/Actions/useFilters/useCalculationGroupFilter";
import { useUserGroupFilter } from "../../Team/Actions/useFilters/useUserGroupFilter";

export const useFilters = () => {
	const payGroupFilter = usePayGroupFilter();
	const employeeNumberFilter = useEmployeeNumberFilter();
	const nameFilter = useNameFilter();
	const employeeTypeFilter = useEmployeeTypeFilter();
	const projectFilter = useProjectFilter();
	const calculationGroupFilter = useCalculationGroupFilter();
	const unionFilter = useUnionFilter();
	const hourTypeFilter = useHourTypeFilter();
	const jobFilter = useJobFilter();
	const payCodeFilter = usePayCodeFilter();
	const statusFilter = useStatusFilter();
	const homeFilters = useHomeFilters();
	const userGroupFilter = useUserGroupFilter();

	return useMemo(
		() => [
			nameFilter,
			employeeNumberFilter,
			...homeFilters,
			payGroupFilter,
			employeeTypeFilter,
			projectFilter,
			calculationGroupFilter,
			unionFilter,
			userGroupFilter,
			hourTypeFilter,
			jobFilter,
			payCodeFilter,
			statusFilter,
		],
		[
			userGroupFilter,
			nameFilter,
			employeeNumberFilter,
			payGroupFilter,
			homeFilters,
			hourTypeFilter,
			jobFilter,
			payCodeFilter,
			statusFilter,
			employeeTypeFilter,
			projectFilter,
			calculationGroupFilter,
			unionFilter,
		],
	);
};
