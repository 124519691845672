import { useMemo } from "react";
import { useAccess } from "../../../../../../utils/hooks/access";

export const usePermissions = () => {
  const { hasAccess: canEdit } = useAccess("timeSheet.canEdit");
  const { hasAccess: canViewHistory } = useAccess("timeSheet.canViewHistory");
  const { hasAccess: canLock } = useAccess("timeSheet.canLock");
  const { hasAccess: canApprove } = useAccess("timeSheet.canApprove");

  return useMemo(() => {
    return {
      canEdit,
      canViewHistory,
      canLock,
      canApprove,
    };
  }, [
    canEdit,
    canViewHistory,
    canLock,
    canApprove,
  ])
}
