import {
	createStore,
	createContainer,
	createStateHook,
	createHook,
} from "react-sweet-state";

const initialState = {
	workSummary: null,
	mode: "home",
	shiftAction: null,
};

const getLastShift = (workSummary) => {
	return workSummary?.shifts?.slice(-1)[0];
};

const getLastShiftClock = (workSummary) => {
	return getLastShift(workSummary)?.clocks.slice(-1)[0];
};

const getModeFromWorkSummary = (workSummary) => {
	if (
		!workSummary ||
		!Array.isArray(workSummary.shifts) ||
		workSummary.shifts.length === 0
	) {
		return "home";
	}
	const lastClock = getLastShiftClock(workSummary);
	if (lastClock) {
		if (lastClock.mode === "START" && lastClock.type === "BREAK") {
			return "break";
		}
		if (lastClock.mode === "END" && lastClock.type === "SHIFT") {
			return "end";
		}
	}
	return "shift";
};

const actions = {
	setWorkSummary:
		(workSummary) =>
		({ setState }) => {
			let mode;
			if (workSummary === null) {
				mode = "home";
			} else {
				mode = getModeFromWorkSummary(workSummary);
			}
			setState({ workSummary, mode });
		},
	setShiftAction:
		(action) =>
		({ setState }) => {
			setState({ shiftAction: action });
		},
	logout:
		() =>
		({ setState }) => {
			setState(initialState);
		},
};

const Store = createStore({ initialState, actions, name: "clock-in" });

export const QrCodeClockInContainer = createContainer(Store);

export const useClockIn = createHook(Store);

export const useWorkSummary = createStateHook(Store, {
	selector: ({ workSummary }) => workSummary,
});

export const useLastShift = createStateHook(Store, {
	selector: ({ workSummary }) => getLastShift(workSummary),
});

export const useLastClock = createStateHook(Store, {
	selector: ({ workSummary }) => getLastShiftClock(workSummary),
});

export const useMode = createStateHook(Store, {
	selector: ({ mode }) => mode,
});

export const useShiftAction = createHook(Store, {
	selector: ({ shiftAction }) => shiftAction,
});
