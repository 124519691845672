import { useEffect, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { ProfileContext } from "../../context";
import DateInput from "../../../../components/Inputs/DateInput";
import CurrencySelect from "../../../../components/Inputs/CurrencySelect";
import CompensationSelect from "../../../../components/Inputs/CompensationSelect";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import MoneyInput from "../../../../components/Inputs/MoneyInput";
import Field from "../../../../components/Field";
import Button from "../../../../components/Button";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../utils/helpers/settings";
import {
	getJobRates,
	renderCurrencySymbol,
} from "../../../../utils/helpers/job";
import { usePeopleApi } from "../../../../utils/api/people";
import { useProfileApi } from "../../../../utils/api/profile";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useCompany } from "../../../../utils/hooks/company";
import {
	applyTimezone,
	dateTimeParseZone,
} from "../../../../utils/helpers/date";

function SecondaryJobForm({ close, values, mode }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);
	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);

	const { changeJobsInformation: profileSubmit } = useProfileApi();
	const { changeJobsInformation: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		({ startDate, endDate, job, ...rest }) => {
			if (!user) {
				return;
			}

			const secondaryJob = {
				primary: false,
				jobId: job.id,
				code: job.code,
				description: job.description,
				displayName: renderSettingsResourceLabel(job),
				endDate: endDate && applyTimezone(endDate, timezone, true),
				startDate:
					startDate && applyTimezone(startDate, timezone, true),
				currency: rest.currency,
				...getJobRates({
					hourlyOrSalaried: rest.hourlyOrSalaried,
					salary: rest?.salary || 0,
					hourlyRate: rest?.hourlyRate || 0,
				}),
			};

			let data = user.jobs || [];

			if (mode === "edit") {
				secondaryJob.id = values?.id;
			}

			switch (mode) {
				case "create":
				case "clone": {
					data = data.concat(secondaryJob);
					break;
				}
				case "edit": {
					if (values) {
						data = data.map((job) =>
							job.id === secondaryJob.id ? secondaryJob : job,
						);
					}
					break;
				}
			}

			submit(profileSubmit, peopleSubmit, data, close);
		},
		[
			timezone,
			close,
			mode,
			user,
			values,
			profileSubmit,
			submit,
			peopleSubmit,
		],
	);

	const onlyView = mode === "view";

	useEffect(() => {
		form.setFieldsValue({
			job: {
				id: values?.jobId,
				code: values?.code,
				description: values?.description,
			},
			startDate:
				values?.startDate &&
				dateTimeParseZone(values?.startDate, "date"),
			hourlyOrSalaried: values?.hourlyOrSalaried || "H",
			currency: values?.currency,
			hourlyRate: values?.hourlyRate?.toFixed(2),
			salary: values?.salary?.toFixed(2),
			endDate:
				values?.endDate && dateTimeParseZone(values?.endDate, "date"),
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"startDate",
				"hourlyOrSalaried",
				"currency",
				"hourlyRate",
				"job",
				"salary",
			],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="startDate"
				label={t("effective-date")}
				rules={[
					{
						required: true,
						message: t("required-effective-date"),
					},
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={t("effective-date")} />
			</Field>

			<Field
				name="job"
				label={t("job")}
				rules={[
					{
						required: true,
						message: t("required-job"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/jobs"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("job")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={onlyView}
				/>
			</Field>

			<Field
				name="hourlyOrSalaried"
				label={t("hourly-or-salary")}
				rules={[
					{
						required: true,
						message: t("required-hourly-or-salary"),
					},
				]}
			>
				<CompensationSelect
					placeholder={t("hourly-or-salary")}
					disabled={onlyView}
				/>
			</Field>

			<Field
				name="currency"
				label={t("pay-currency")}
				rules={[
					{
						required: true,
						message: t("required-pay-currency"),
					},
				]}
			>
				<CurrencySelect
					placeholder={t("pay-currency")}
					disabled={onlyView}
				/>
			</Field>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue }) => {
					const compensation = getFieldValue("hourlyOrSalaried");

					switch (compensation) {
						case "H": {
							return (
								<Field
									name="hourlyRate"
									label={t("hourly-rate")}
									rules={[
										{
											required: true,
											message: t(
												"required-hourly-rate"
											),
										},
									]}
								>
									<MoneyInput
										prefix={renderCurrencySymbol(
											getFieldValue("currency"),
										)}
										className="form-control"
										placeholder={t("hourly-rate")}
									/>
								</Field>
							);
						}
						case "S": {
							return (
								<Field
									name="salary"
									label={t("salary")}
									rules={[
										{
											required: true,
											message: t("required-salary"),
										},
									]}
								>
									<MoneyInput
										prefix={renderCurrencySymbol(
											getFieldValue("currency"),
										)}
										className="form-control"
										placeholder={t("salary")}
									/>
								</Field>
							);
						}
					}
				}}
			</BaseField>

			<Field name="endDate" label={t("end-date")} className="d-flex flex-column">
				<DateInput disabled={onlyView} placeholderText={t("end-date")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					onClick={close}
					disabled={submitting}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{!onlyView && (
					<Button
						color="primary"
						type="submit"
						className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
					>
						{t("save")}
					</Button>
				)}
			</div>
		</Form>
	);
}

export default SecondaryJobForm;
