import { useCallback, useState } from "react";
import { Table, Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";

function ColumnsList({ index, setColumns, item }) {
	const { t } = useTranslation();

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const toggleTooltip = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [isTooltipOpen, setIsTooltipOpen]);

	return (
		<div>
			<Table
				className="align-items-center table-flush border rounded mr-2"
				hover
				responsive
				style={{
					width: "fit-content",
				}}
				key={index}
			>
				<thead className="thead-light">
					<th className="border-top-0">
						<div className="d-flex justify-content-between">
							<span className="mr-5">
								<i
									className={`fa-solid ${item.icon} mr-2 cursor-pointer`}
									id={`${item.name}-tooltip`}
								/>

								{item.label}
							</span>

							<i
								className="fa-solid fa-xmark cursor-pointer"
								onClick={() =>
									setColumns((col) =>
										col?.filter(
											(c) => c?.key !== item?.key,
										),
									)
								}
							/>
						</div>
					</th>
				</thead>

				<tbody>
					{Array(5)
						.fill(item.label)
						.map((item, i) => (
							<tr>
								<td>{`< ${item} ` + (i + 1) + ` >`}</td>
							</tr>
						))}
				</tbody>
			</Table>

			<Tooltip
				isOpen={isTooltipOpen}
				toggle={toggleTooltip}
				target={`${item.name}-tooltip`}
			>
				<span className="text-sm font-weight-bolder px-2">
					{t("data-from-collection", {
						name: item.name
					})}
				</span>
			</Tooltip>
		</div>
	);
}

export default ColumnsList;
