import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import Field from "../../../../../../../components/Field";
import Switch from "../../../../../../../components/Inputs/Switch";
import ResourceSelect from "../../../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../helper";

function Manual({ disabled: disabledField }) {
	const { t } = useTranslation();

	return (
		<div className="mb-4">
			<div className="d-flex  align-items-center">
				<BaseField shouldUpdate noStyle>
					{({ }, { }, { setFieldsValue }) => (
						<Field
							name={["breaks", "manual", "status"]}
							valuePropName="checked"
							noStyle
							className="mb-2"
						>
							<Switch
								size="sm"
								onChange={() =>
									setFieldsValue({
										breaks: {
											automatic: { status: false },
											add: { status: false },
											deduct: { status: false },
										},
									})
								}
								disabled={disabledField}
							>
								{t("manual-breaks")}:
							</Switch>
						</Field>
					)}
				</BaseField>
			</div>

			<div className="pl-5">
				<p className="text-xs text-muted font-weight-300">
					{t("manually-added-breaks")}
				</p>

				<div className="col-sm d-flex align-items-center px-0 mb-4 pay-code-select">
					<p className="mr-2 mb-0 text-dark font-weight-500 text-sm">
						{t("type")}
					</p>

					<BaseField shouldUpdate noStyle>
						{({ }, { }, { getFieldValue }) => {
							const disabled = !getFieldValue([
								"breaks",
								"manual",
								"status",
							]);

							return (
								<Field
									name={["breaks", "manual", "payCode"]}
									noStyle
									rules={
										!disabled
											? [
												{
													required: true,
													message:
														t("required-type"),
												},
											]
											: undefined
									}
									dependencies={[
										["breaks", "manual", "status"],
									]}
									className="mb-0"
								>
									<ResourceSelect
										disabled={disabled || disabledField}
										labelPropName="description"
										renderLabel={
											renderSettingsResourceLabel
										}
										resourcePath={`${getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}&forBreak=true`}
										size="small"
										hasSearch
										getSearchFilters={(search) => {
											return { ...getSettingsResourceSearchFilters(search) }
										}}
										valuePropName="code"
									/>
								</Field>
							);
						}}
					</BaseField>
				</div>

				<div className="row pl-3 mb-3">
					<div className="col-sm d-flex align-items-center px-1">
						<p className="mr-2 text-dark font-weight-500 text-sm mb-0">
							{t("mandatory")}
						</p>

						<BaseField shouldUpdate noStyle>
							{({ }, { }, { getFieldValue }) => {
								const disabled = !getFieldValue([
									"breaks",
									"manual",
									"status",
								]);

								return (
									<Field
										name={["breaks", "manual", "mandatory"]}
										valuePropName="checked"
										noStyle
										className="mb-0"
									>
										<Switch
											size="sm"
											disabled={disabled || disabledField}
										/>
									</Field>
								);
							}}
						</BaseField>
					</div>

					<BaseField shouldUpdate noStyle>
						{({ }, { }, { getFieldValue }) => {
							const mandatory = getFieldValue([
								"breaks",
								"manual",
								"mandatory",
							]);

							const disabled = !getFieldValue([
								"breaks",
								"manual",
								"status",
							]);

							if (!mandatory) {
								return (
									<div className="col-sm d-flex align-items-center mb-0" />
								);
							}

							return (
								<div className="col-sm d-flex align-items-center px-1">
									<p className="mr-2 text-dark font-weight-500 text-sm mb-0">
										{t("duration")}
									</p>

									<BaseField
										name={["breaks", "manual", "duration"]}
										noStyle
										rules={
											!disabled
												? [
													{
														required: mandatory,
														message: t("required-duration"),
													},
													{
														validator(
															_,
															value,
														) {
															if (
																value &&
																Number(
																	value,
																) <= 0
															) {
																return Promise.reject(
																	new Error(
																		t("duration-bigger-than-zero"),
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]
												: undefined
										}
										dependencies={[
											["breaks", "manual", "status"],
											["breaks", "manual", "mandatory"],
										]}
										className="mb-0"
									>
                    {({ value, onChange }) => {
                      return (
                        <Input
                          type="number"
                          size="sm"
                          className="input-number"
                          disabled={disabled || disabledField}
                          value={value}
                          onChange={(e) => {
                            onChange(Number(e.target.value));
                          }}
                        />
                      );
                    }}
									</BaseField>
									<p className="ml-2 text-dark font-weight-500 text-sm mb-0">
										{t("minutes")}
									</p>
								</div>
							);
						}}
					</BaseField>
				</div>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldsError }) => {
						const [error] = getFieldsError([
							["breaks", "manual", "payCode"],
							["breaks", "manual", "duration"],
						])
							.map(({ errors }) => errors)
							.flat();

						return (
							error && (
								<span className="invalid-feedback d-block mb-3">
									{error}
								</span>
							)
						);
					}}
				</BaseField>
			</div>
		</div>
	);
}

export default Manual;
