import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import Modal from "../../../../components/Modal";
import Form from "./Form";
import EditShift from "../EditShift";
import { useShiftAction } from "../store";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useAttestation } from "./api";
import { useUser } from "../../../../utils/hooks/user";
import "./_style.scss";

function ShiftSummary({ setRefetchTimesheet = noop }) {
    const { t } = useTranslation();
    const user = useUser();
    const [shiftAction, { setShiftAction }] = useShiftAction();
    const { fetch, data, loading} = useAttestation();

    const {
        visible,
        open: openEdit,
        close: closeEdit,
    } = useVisible();

    const closeShiftSummary = useCallback(() => {
        setShiftAction(null);
    }, [setShiftAction]);

    useEffect(() => {
        const controller = new AbortController();
        if(user?.id) {
            fetch(user.id, controller);
        }
        return () => controller.abort();
    }, [fetch, user?.id]);

    return (
        <>
            <Modal
                contentClassName='modal-dialog-centered shift-summary-modal'
                title={t("shift-summary")}
                isOpen={shiftAction === "end-shift"}
                toggle={shiftAction === "end-shift"  && closeShiftSummary }
                size="lg"
            >
                <Form 
                    openEdit={openEdit} 
                    setRefetchTimesheet={setRefetchTimesheet}
                    attestations={data}
                    loading={loading}
                /> 
            </Modal>

            <Modal
                contentClassName="shift-summary-modal px-2"
                title={t("edit-shift")}
                isOpen={visible}
                toggle={closeEdit}
                size="lg"
            >
                <EditShift 
                    close={closeEdit} 
                    setRefetchTimesheet={setRefetchTimesheet}
                    attestation={data?.length > 0}
                />
            </Modal>
        </>
    );
}

export default ShiftSummary;
