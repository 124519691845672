import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNotificationRedirect = () => {
	const navigate = useNavigate();
	return useCallback(
		(type, data) => {
			switch (type) {
				case "people:job":
				case "people:compensation":
				case "people:organization":
				case "people:status":
				case "people:hire":
				case "people:personal:information": {
					// accessMap && getAccessValue(accessMap, "people.canView")
					navigate("/people");
					break;
				}
				case "employee:clockin":
				case "employee:clockout":
				case "employee:start-break":
				case "employee:end-break": {
					// hasAllAccessInList(accessMap, ["clockin.canView", "canManageUsers"])
					navigate("/time-clock/my");
					break;
				}
				case "employee:request:shift":
				case "employee:request:absence":
				case "employee:request:edit-shift":
				case "employee:request:schedule": {
					// hasAllAccessInList(accessMap, ["request.canView", "canManageUsers"])
					navigate("/request/team");
					break;
				}
				case "employee:request:reject":
				case "employee:request:approve": {
					// hasAllAccessInList(accessMap, ["timeSheet.canView", "canManageUsers"])
					navigate("/request/my");
					break;
				}
				case "schedule:update":
				case "schedule:delete":
				case "schedule:approved":
				case "schedule:locked":
				case "schedule:unlocked":
				case "schedule:unapproved":
				case "schedule:submitted":
				case "schedule:reject":
					navigate(`/scheduler/my?mode=date&from=${data?.resourceDate}&to=${data?.resourceDate}`);
				break;
				default: 
					console.log("No redirect for notification type: ", type);
					break;
			}
		},
		[navigate]
	);
};
