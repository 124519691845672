import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../../components/Field";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";

const getSettingsResourceSearchFilters = (search) => ({
	"filter[code][method]": "contains",
	"filter[code][value]": search,
	"filter[description][method]": "contains",
	"filter[description][value]": search,
	fields: ["id", "code", "description", "hourType"],
	filterType: "or",
	withoutLookups: "true",
});

function PayCodeSelect({
	hourTypes = [],
	label = "eligible-pay-codes",
	...props
}) {
	const { t } = useTranslation();
	if (!Array.isArray(hourTypes) && hourTypes) {
		hourTypes = [hourTypes];
	}
	const url = useMemo(() => {
		let base = "pay-code";
		if (hourTypes.length > 0) {
			base += `?${hourTypes
				.map(
					(hourType) =>
						`filter[hourType][method][]=exact&filter[hourType][value][]=${hourType}`,
				)
				.join("&")}`;
		}
		return base;
	}, [hourTypes]);

	return (
		<Field label={t(label)} {...props}>
			<ResourceSelect
				labelPropName="description"
				renderLabel={renderSettingsResourceLabel}
				resourcePath={url}
				mode="multiple"
				placeholder={t("pay-codes")}
				disabled={props.disabled}
				hasSearch
				getSearchFilters={getSettingsResourceSearchFilters}
			/>
		</Field>
	);
}

export default PayCodeSelect;
