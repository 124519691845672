import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CardBody, CardHeader, Col, Row } from "reactstrap";
import Button from "../../../components/Button";
import { usePeopleApi } from "../../../utils/api/people";
import { useProfileApi } from "../../../utils/api/profile";
import { useVisible } from "../../../utils/hooks/useVisible";
import { ProfileContext } from "../context";
import InformationRow from "../InformationRow";
import QrCodeModal from "./QrCodeModal";
import VerifyModal from "./VerifyModal";

function Settings({ isOpenFromTimesheet = false }) {
	const { t } = useTranslation();
	const { user, submitting, submit } = useContext(ProfileContext);
	const { visible, open, close } = useVisible();
	const {
		visible: isOpenVerifyModal,
		open: openVerifyModal,
		close: closeVerifyModal
	} = useVisible();

	const { changeCalculationGroupRules: profileSubmit } = useProfileApi();
	const { changeCalculationGroupRules: peopleSubmit } = usePeopleApi();
	const clearOvertimeRules = useCallback(() => {
		const loading = toast.loading(t("clearing-overtime"));
		submit(
			profileSubmit,
			peopleSubmit,
			{ overtime: null },
			() => {
				toast.dismiss(loading);
				toast.success(t("overtime-cleared-successfully"));
			},
			() => {
				toast.dismiss(loading);
				toast.error(t("could-not-clear-overtime"));
			},
		);
	}, [submit, profileSubmit, peopleSubmit]);
	const clearBreaks = useCallback(() => {
		const loading = toast.loading(t("clearing-breaks"));
		submit(
			profileSubmit,
			peopleSubmit,
			{ breaks: null },
			() => {
				toast.dismiss(loading);
				toast.success(t("breaks-cleared-successfully"));
			},
			() => {
				toast.dismiss(loading);
				toast.error(t("could-not-clear-breaks"));
			},
		);
	}, [submit, profileSubmit, peopleSubmit]);

	const breakRules = useMemo(() => {
		const { automatic, manual } = user?.calculationGroupRules?.breaks || {};
		if (!automatic?.status && !manual?.status) {
			return t("no-break-settings");
		}
		let content;
		if (automatic.status) {
			content = t("automatic-breaks");
		}
		if (manual.status) {
			content = t("manual-breaks");
		}
		return (
			<div>
				<span>{content}</span>
				<button
					className="text-red ml-2 border-0 bg-transparent"
					onClick={clearBreaks}
					disabled={submitting}
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>
			</div>
		);
	}, [t, clearBreaks, user?.calculationGroupRules?.breaks]);

	const overtimeRules = useMemo(() => {
		if (
			!user?.calculationGroupRules?.overtime
			|| !Object.keys(user.calculationGroupRules.overtime).length
		) {
			return t("no-overtime-settings");
		}
		const rules = Object
			.keys(user.calculationGroupRules.overtime)
			.filter((key) => {
				return user.calculationGroupRules.overtime[key].status
			})
			.map((key) => t(key + "-rule"))
			.join(", ");
		return (
			<div>
				<span>{rules}</span>
				<button
					className="text-red ml-2 border-0 bg-transparent"
					onClick={clearOvertimeRules}
					disabled={submitting}
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>
			</div>
		);
	}, [t, clearOvertimeRules, user?.calculationGroupRules?.overtime]);

	if (!user) {
		return <>{t("user-not-found")}</>;
	}

	return (
		<>
			<CardHeader
				className={classNames(
					isOpenFromTimesheet && "profile-card-header",
				)}
			>
				<Row>
					<Col xs="6" className="">
						<h3 className="mb-0 text-uppercase">{t("settings")}</h3>
					</Col>
				</Row>
			</CardHeader>

			<CardBody
				style={{ height: "calc(100%)", overflowY: "auto" }}
				className="profile-scroll"
			>
				<h6 className="heading-small text-muted mb-4 remove-mb">
					{t("calculation-group-rules")}
				</h6>

				<div className="pl-lg-4 remove-mb">
					<InformationRow
						title={t("breaks")}
						value={breakRules}
					/>

					<InformationRow
						title={t("overtime")}
						value={overtimeRules}
					/>
				</div>

				<h6 className="heading-small text-muted mb-4 remove-mb">
					{t("two-factor-auth")}
				</h6>


				<div className="pl-lg-4 remove-mb">
					<Row className="w-100">
						<Col>
							<InformationRow
								title={t("status")}
								value={user?.twoFA?.status ? t(user?.twoFA?.status) : "--"}
							/>
							<InformationRow
								title={t("type")}
								value={user?.twoFA?.type ? t(user?.twoFA?.type) : "--"}
							/>
							<InformationRow
								title={t("email")}
								value={user?.twoFA?.email ? t(user?.twoFA?.email) : "--"}
							/>
						</Col>

						{user?.twoFA?.status === "in-verification" && (
							user?.twoFA?.type === "auth-app" ? (
								<Col xs="2">
									<Button color="primary" onClick={open}>
										{t("setup-2fa")}
									</Button>
								</Col>
							) : user?.twoFA?.type === "email" && (
								<Col xs="2">
									<Button color="primary" onClick={openVerifyModal}>
										{t("verify")}
									</Button>
								</Col>
							))}

					</Row>
				</div>
			</CardBody>

			{visible && (
				<QrCodeModal
					isOpen={visible}
					close={close}
				/>
			)}

			{isOpenVerifyModal && (
				<VerifyModal
					isOpen={isOpenVerifyModal}
					close={closeVerifyModal}
				/>
			)}
		</>
	);
}

export default Settings
