import { useTranslation } from "react-i18next";
import { Field } from "rc-field-form";
import Rule from "./Rule";

function Rules({ disabled }) {
	const { t } = useTranslation();

	return (
		<div className="border-bottom mb-4">
			<div className="mb-2">
				<h3 className="text-sm">{t("overtime-rules")}</h3>
			</div>

			<div className="mb-3">
				<Field shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const rules = getFieldValue("overtime");
						return Object.keys(rules || {}).map((type) => (
							<Rule key={type} type={type} disabled={disabled} />
						));
					}}
				</Field>
			</div>
		</div>
	);
}

export default Rules;
