import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Row, Col, Card } from "reactstrap";
import Button from "../../../components/Button";
import Actions from "../../../components/NTable/Actions";
import NTable from "../../../components/NTable";
import BulkActions from "./BulkActions";
import AddShift from "../AddShift";
import AddAbsence from "../AddAbsence";
import AddSchedule from "../AddSchedule";
import Drawer from "../../../components/Drawer";
import { useColumns } from "./columns";
import { useMyRequests } from "../api";
import { useDeleteModal } from "../../Settings/helpers/useDeleteModal";
import { formatParams } from "../../../utils/api/format";
import { useVisible } from "../../../utils/hooks/useVisible";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import { useEditDrawer } from "./useEditDrawer";
import { useNewTable } from "../../../components/NTable/hook";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useQueryFilters } from "../../Settings/helpers/useQueryFilters";
import { useActionWithAccess } from "../../../utils/hooks/access";
import "./styles.scss";

function MyRequest() {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
	const { visible, selected: addRequestType, open, close } = useVisible();

	const {
		data,
		setData,
		totalItems,
		loading,
		fetch,
		deleteRequest,
		batchRemove,
		update,
		error,
	} = useMyRequests();

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t("do-you-want-to-delete-requests", {
					count: data.length
				})
				: t("do-you-want-to-delete-request"),
		onConfirm: (data) =>
			Array.isArray(data)
				? batchRemove(data, () => {
					setData((prev) =>
						prev?.filter((item) => !data.includes(item?.id)),
					);
				})
				: deleteRequest(data, () =>
					setData((prev) =>
						prev?.filter((item) => item?.id !== data),
					),
				),
		permission: "request.canDelete"
	});

	const {
		visible: isEditOpen,
		open: openEdit,
		close: closeEdit,
		content: editDrawerContent,
	} = useEditDrawer({ update, error, loading, setData });

	const columns = useColumns({
		edit: useActionWithAccess(openEdit, "request.canEdit"),
		remove: useActionWithAccess(openDeleteModal, "request.canDelete"),
	});

	const table = useNewTable({
		data,
		columns,
		totalItems,
        loading,
        initialState: useInitialState(() => {
            const columnVisibility = groups
                .filter(({ allocatedBy }) => !allocatedBy)
                .reduce((total, group) => {
                    const level = group.level.replace("_", "");
                    return {
                        ...total,
                        [level]: false,
                    };
                }, {});
            return { columnVisibility };
        }),
        sessionFiltersKey: "myRequests",
	});

    const queryFilters = useQueryFilters(table);

	useEffect(() => {
		const controller = new AbortController();
		fetch(formatParams(queryFilters), controller);
		return () => controller.abort();
	}, [fetch, queryFilters]);

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<CardHeader className="border-0 mb-0 pb-1">
					<Row>
						<Col sm={12} md={6}>
							<h3>{t("request")}</h3>
						</Col>

						<Col sm={12} md={6} className="text-md-right">
							<Button
								onClick={() => open("add-shift")}
								className="border-dark px-3 btn-round mb-2 mb-md-2 btn btn-secondary btn-sm text-primary"
							>
								{t("add-shift-request")}
							</Button>

							<Button
								onClick={() => open("add-absence")}
								className="border-dark px-3 btn-round mb-2 mb-md-2 btn btn-secondary btn-sm text-primary ml-0"
							>
								{t("add-absence-request")}
							</Button>

							<Button
								onClick={() => open("add-schedule")}
								className="border-dark px-3 btn-round mb-2 mb-md-2 btn btn-secondary btn-sm text-primary ml-0"
							>
								{t("add-schedule-request")}
							</Button>
						</Col>
					</Row>
				</CardHeader>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    >
                        <BulkActions
							rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
				</Card>

				{deleteModal}
			</Card>

			<Drawer
				open={isEditOpen}
				close={closeEdit}
				title={t("edit-request")}
				className="edit-request-drawer"
			>
				{editDrawerContent}
			</Drawer>

			<AddShift
				isOpen={visible && addRequestType === "add-shift"}
				onClose={close}
				setData={setData}
			/>

			<AddAbsence
				isOpen={visible && addRequestType === "add-absence"}
				onClose={close}
				setData={setData}
			/>

			<AddSchedule 
				isOpen={visible && addRequestType === "add-schedule"}
				onClose={close}
				setData={setData}
			/>
		</div>
	);
}

export default MyRequest;
