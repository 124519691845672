export const calculateAnalyticsTotals = (data) => {
    return  data?.reduce((total, overviewItem) => ({
        totalDuration: overviewItem.hourType === "unpaid"
            ? total.totalDuration
            : total.totalDuration + overviewItem.duration,
        totalPayment: overviewItem.hourType === "unpaid"
            ? total.totalPayment
            : total.totalPayment + overviewItem.total,
    }), { totalDuration: 0, totalPayment: 0 });
}
