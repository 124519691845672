import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const useActionsColumn = ({ viewSummary }) => {
    const { t } = useTranslation();

    return useMemo(() => ({
        Header: t("actions"),
        id: "actions",
        hideable: false,
        Cell: ({ row: { original } }) => {
            return (
                <div className="text-center">
                    <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faEye}
                        onClick={() => viewSummary(original)}
                    />
                </div>
            );
        },
    }), [t, viewSummary]);
};
