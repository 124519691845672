import { QrCodeClockinProvider } from "../QrCodeClockin/context";
import LogicProvider from "./LogicProvider";
import "./style.scss";

function QrCodeClockin() {
    return (
        <QrCodeClockinProvider>
            <LogicProvider />
        </QrCodeClockinProvider>
    );
}

export default QrCodeClockin;
