import { useMemo } from "react";
import { normalizeById } from "../../../../../../../utils/helpers/normalize";

export const useMergedData = ({
  added,
  updated,
  removed,
  apiFormattedData,
}) => {
  return useMemo(() => {
    let data = Array.from(
      apiFormattedData.filter((item) => !removed.includes(item.id))
    );
    for (const [index, item] of Object.entries(added)) {
      data.splice(index, 0, item);
    }
    data = normalizeById(data);
    for (const [key, values] of Object.entries(updated)) {
      data[key] = {
        ...data[key],
        ...values,
      };
    }
    return Object.values(data);
  }, [added, updated, removed, apiFormattedData]);

}
