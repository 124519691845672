import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import { faInfo, faEye, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCompanyDateFormat } from "../../../../../utils/hooks/company";
import { getSharedDateRowSpan } from "../helpers";
import { Tooltip } from "reactstrap";
import { useVisible } from "../../../../../utils/hooks/useVisible";

const ErrorTooltip = ({ row, errors }) => {
  const error = useMemo(() => {
    if (!errors[row.id]) {
      return false;
    }
    return Object.values(errors[row.id]).find((err) => !!err)
  }, [errors[row.id]]);

  const { visible, toggle } = useVisible();

  if (!error) {
    return null;
  }

  const id = `timesheet-${row.original.user.id}-row-${row.id}-error`;

  return (
    <>
      <span id={id}>
        <FontAwesomeIcon
          className="cursor-pointer text-red"
          icon={faTriangleExclamation}
          size="lg"
        />
        <Tooltip target={id} isOpen={visible} toggle={toggle}>
          <span className="text-sm font-weight-bolder px-2 m-2">
            {error}
          </span>
        </Tooltip>
      </span>
    </>
  );
}

export const useDateColumn = ({ dateIdMap, dateTimesheetMap, view, viewSummary }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();

    return useMemo(() => ({
        Header: t("date"),
        accessor: "date",
        headCustomProps: { className: "input-column" },
        getCellCustomProps: (timeSheet) => ({
            rowSpan: getSharedDateRowSpan(dateIdMap, timeSheet),
        }),
        Cell: ({ value, row, errors }) => {
            const { isAdded } = row.original;
            const date = moment(row.original.date)
                .startOf("day")
                .toISOString(true);

            const manuallyUpdated = dateTimesheetMap?.[moment(date).format("YYYY-MM-DD")]?.find(
                ({ manuallyUpdated }) => manuallyUpdated);

            return (
                <div className="d-flex gap-x-2 align-items-center date-column">
                    <span>{moment(value, "YYYY-MM-DD").format(dateFormat)}</span>
                    <span style={{ width: '32px' }}>{moment(value, "YYYY-MM-DD").format(`ddd`).toUpperCase()}</span>


                    {view && (
                        <span
                            className="cursor-pointer"
                            onClick={() => view(row.original)}
                        >
                            <FontAwesomeIcon
                                icon={faInfo}
                                size="lg"
                                className={classNames(manuallyUpdated && "text-red")}
                            />
                        </span>
                    )}
          
                    {(!isAdded && viewSummary) && (
                        <span
                            className="cursor-pointer"
                            onClick={() => viewSummary(date)}
                        >
                            <FontAwesomeIcon icon={faEye} size="lg" />
                        </span>
                    )}

                    <ErrorTooltip row={row} errors={errors} />
                </div>
            );
        },
        alwaysVisible: true,
    }), [t, dateIdMap, viewSummary, view, dateFormat]);
};
