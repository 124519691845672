import { useCallback } from "react";
import { useApi } from "../../../utils/api";

export const useChangePassword = () => {
	const { authPost } = useApi();

	return useCallback(
		(data, user) => {
			const headers = user
				? {
						Company: user.companies[0],
						MainCompany: user.mainCompany,
				  }
				: undefined;
			return authPost("/profile/change-password", {
				data,
				headers,
			});
		},
		[authPost],
	);
};
