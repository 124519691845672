import { useCallback } from "react";
import { useExportTimeSheets } from "../../../api/useExportTimeSheets";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { useActionWithAccess } from "../../../../../utils/hooks/access";

export const useExport = ({ ids, columns, filters }) => {
    const { from, to } = useDateFilters();
    const {
        downloadMyWeeklySummary,
        downloadMyWeeklyDetails,
    } = useExportTimeSheets();

    const downloadSummary = useActionWithAccess(
        useCallback(() => {
            downloadMyWeeklySummary({ from, to, ids, filters, columns });
        }, [from, to, ids, columns, filters, downloadMyWeeklySummary]),
        "timeSheet.canExportMyTimesheet",
    );

    const downloadDetails = useActionWithAccess(
        useCallback(() => {
            downloadMyWeeklyDetails({ from, to, ids, filters, columns });
        }, [from, to, ids, columns, filters, downloadMyWeeklyDetails]),
        "timeSheet.canExportMyTimesheet",
    );

    return { downloadSummary, downloadDetails };
}
