import {
	createContext,
	useContext,
	useMemo,
	useState,
	useCallback,
} from "react";
import { noop } from "lodash";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import { useUser } from "../../../utils/hooks/user";
import { useUserProfile } from "./useUserProfile";
import { useAuthentication } from "../../Authentication/context";
import { toast } from "react-toastify";

export const ProfileContext = createContext();

export const ProfileProvider = ({ id, children, isSelf }) => {
	const { dispatch } = useAuthentication();
	const { user: apiUser, setUser } = useUserProfile(id);
	const self = useUser();
	const { groups } = useGroups();

	const hierarchyGroup = useMemo(
		() => groups?.find(({ hasHierarchy }) => hasHierarchy),
		[groups],
	);

	const [submitting, setSubmitting] = useState(false);
	const [submittingError, setSubmittingError] = useState(null);

	const user = useMemo(() => {
		if (id) {
			return apiUser;
		}
		return self;
	}, [id, self, apiUser]);

	const submit = useCallback(
		async (
			selfCall,
			employeeCall,
			data,
			onSuccess = noop,
			onFailure = noop,
		) => {
			setSubmitting(true);
			setSubmittingError(null);
			try {
				let response;
				if (id) {
					response = await employeeCall(id, data);
				} else {
					response = await selfCall(data);
				}
				if (response) {
					setUser(response);
					onSuccess(response);
					if (!id) {
						dispatch({
							type: "set-user",
							payload: { user: response },
						});
					}
				}
			} catch (err) {
				setSubmittingError(err);
				onFailure();
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setSubmitting(false);
			}
		},
		[setSubmitting, setSubmittingError, id, setUser, dispatch],
	);

	const contextValue = useMemo(
		() => ({
			isSelf,
			user,
			hierarchyGroup,
			submit,
			submitting,
			submittingError,
		}),
		[user, hierarchyGroup, submit, submitting, submittingError],
	);

	return (
		<ProfileContext.Provider value={contextValue}>
			{children}
		</ProfileContext.Provider>
	);
};

export const useProfile = () => {
	const context = useContext(ProfileContext);
	if (context === undefined) {
		throw new Error("useProfile should be used within a provider");
	}
	return context;
};
