import "./style.scss";

function Switch({ id, children, hasError, ...props }) {
	return (
		<div className="switch-component">
			<div className="form-check form-switch">
				<input
					className="form-check-input"
					type="checkbox"
					id={id}
					{...props}
				/>

				<label className="form-check-label" htmlFor={id}>
					{children}
				</label>
			</div>
		</div>
	);
}

export default Switch;
