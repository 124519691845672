import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import Field from "../../../../../../../components/Field";
import Text from "../../../../../../../components/Inputs/Text";
import EmailsInput from "./EmailsInput";

function EmailOption({ form, disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<EmailsInput
				buttonLabel={t("email")}
				name="emails"
				label={t("emails")}
				form={form}
				disabled={disabled}
				rules={[
					{
						required: true,
						message: t("required-emails"),
					},
				]}
			/>

			<Field
				name="subject"
				label={t("subject")}
				rules={[{ required: true, message: t("required-subject") }]}
			>
				<Text placeholder={t("subject")} disabled={disabled} />
			</Field>

			<Field
				name="body"
				label={t("body")}
				rules={[{ required: true, message: t("required-body") }]}
			>
				<Input
					type="textarea"
					rows={7}
					resize="none"
					id="body"
					placeholder={t("body")}
					disabled={disabled}
				/>
			</Field>

			<div className="d-flex flex-column text-sm">
				<span>
					{t("allowed-variables-for-subject-and-body-input")}:{" "}
					<span className="text-info">*</span>
				</span>

				<ol type="i" style={{ columns: 2, columnGap: 16 }}>
					<li>{t("current-date-option")}</li>
					<li>{t("current-time-option")}</li>
					<li>{t("timestamp-option")}</li>
					<li>{t("period-start-option")}</li>
					<li>{t("period-end-option")}</li>
					<li>{t("report-name-option")}</li>
				</ol>
			</div>
		</>
	);
}

export default EmailOption;
