import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import Button from "../../../components/Button";
import { ProfileContext } from "../context";
import { useProfileApi } from "../../../utils/api/profile";
import { usePeopleApi } from "../../../utils/api/people";
import { generateErrorsConfigForForm, getErrorMessage } from "../../../utils/helpers/errors";

function VerifyModal({ isOpen, close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [seconds, setSeconds] = useState(60);
    const { isSelf, submitting, submittingError, submit } = useContext(ProfileContext);
    const {
        authVerify: profileSubmit,
        generateToken: profileGenerateToken
    } = useProfileApi();
    const {
        authVerify: peopleSubmit,
        generateToken: peopleGenerateToken
    } = usePeopleApi();

    const onFinish = useCallback((values) => {
        submit(
            profileSubmit,
            peopleSubmit,
            values,
            () => {
                localStorage.setItem("OTP_VALIDATE", "true");
                close();
            });
    }, [submit, peopleSubmit, profileSubmit, close]);

    const resendEmail = useCallback(async () => {
        try {
            let response;
            if (isSelf) {
                response = await profileGenerateToken();
            } else {
                response = await peopleGenerateToken()
            }

            if (response) {
                setSeconds(60);
            }
        } catch (err) {
            getErrorMessage(err, t);
        }
    }, [isSelf, profileGenerateToken, peopleGenerateToken, setSeconds])

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["token"],
            submittingError,
        );
        form.setFields(fieldErrors);
    }, [form, submittingError]);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(
                () => {
                    setSeconds(prev => prev - 1)
                }, 1000);
            return () => clearInterval(interval);;
        }
    }, [seconds, setSeconds]);

    const percent = useMemo(() => {
        return (seconds / 60) * 100;
    }, [seconds]);

    return (
        <Modal
            title={(
                <div className="d-flex align-items-center justify-content-between">
                    {t("verify-email")}

                    <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faXmark}
                        onClick={close}

                    />
                </div>
            )}
            isOpen={isOpen}
            centered
        >
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Field
                    name="token"
                    rules={[
                        {
                            required: true,
                            message: t("required-auth-code")
                        }
                    ]}
                    className="my-4"
                >
                    <Text placeholder={t("auth-code")} />
                </Field>

                <div className="mx-2">
                    <span className="text-sm">
                        {t("dont-receive-email")}
                    </span>

                    <span
                        className="mx-2 text-primary text-sm font-weight-bold"
                        style={{
                            width: "30px",
                            height: "30px",
                            border: `1px dashed #010a63`,
                            borderRadius: "50%",
                            padding: "10px 12px",
                            background: `conic-gradient(#e6e6e6 ${percent}%,0,white )`
                        }}
                    >
                        {seconds}
                    </span>

                    <span className="text-sm mr-2">{t("seconds-then")}</span>

                    <span
                        className="cursor-pointer border-bottom border-primary px-1 text-primary font-weight-bold text-sm"
                        onClick={resendEmail}
                    >
                        {t("resend-e-mail")}
                    </span>
                </div>

                <div className="d-flex align-items-center justify-content-end my-3">
                    <Button
                        htmlType="button"
                        onClick={close}
                        disabled={submitting}
                    >
                        {t("close")}
                    </Button>

                    <Button
                        color="primary"
                        htmlType="submit"
                        loading={submitting}
                    >
                        {t("verify-and-activate")}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default VerifyModal;
