import { useMemo, useCallback, useEffect, useContext } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AddShift from "../AddShift/Form";
import AddAbsence from "../AddAbsence/Form";
import AddSchedule from "../AddSchedule/Form";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useUser } from "../../../utils/hooks/user";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { getClocksFromValues } from "../../TimeClock/ClockIn/helpers";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import { calculateFields } from "../../Scheduler/api/useSaveEvent";
import { prepareEventForDb } from "../../Scheduler/helpers/prepareEventForDb";
import { useCompany } from "../../../utils/hooks/company";

export const useEditDrawer = ({ update, error, loading, setData }) => {
	const { t } = useTranslation();
	const user = useUser();
	const company = useCompany();
	const { visible, selected, open, close } = useVisible();

	const { groups } = useContext(OrgLevelGroupsContext);

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const onEditSuccess = useCallback(
		(request) =>
			setData((prev) =>
				prev.map((prevRequest) =>
					prevRequest.id === request.id ? request : prevRequest,
				),
			),
		[setData],
	);

	const { ip, getIp } = useGetIpAddress();

	const requestData = useCallback(
		(values, type) => {
			switch (type) {
				case "absence": {
					const timezone = selected?.data?.location?.timezoneValue;

					let startDate;
					let endDate;
					if (values.allDay) {
						startDate = moment(values.startDate).startOf("day");
						endDate = moment(values.endDate).endOf("day");
					} else {
						startDate = combineDateAndTime(
							values.date,
							values.startDate,
						);
						endDate = combineDateAndTime(
							values.date,
							values.endDate,
						);
					}
					if (timezone) {
						startDate = startDate.tz(timezone, true);
						endDate = endDate.tz(timezone, true);
					}

					startDate = startDate.toISOString(true);
					endDate = endDate.toISOString(true);

					const val = {
						...values,
						location: selected.data.location,
						job: selected.data.job,
						startDate,
						endDate,
						ip: ip ? ip : "",
					};

					if (!values.allDay) {
						val.date = startDate;
					}

					return val;
				}
				case "shift":
				case "edit-shift": {
					const job = user?.jobs?.find(({ id }) => id === values.job);
					const location = user?.locations?.find(
						({ id }) => id === values.location,
					);

					const timezone = location?.timezoneValue;

					let start = combineDateAndTime(
						values.start.date,
						values.start.time,
						timezone,
					);
					let end = combineDateAndTime(
						values.end.date,
						values.end.time,
						timezone,
					);
					start = start.toISOString(true);
					end = end.toISOString(true);

					return {
						job: job,
						location: location,
						project: {
							id: values?.project?.id,
							code: values?.project?.code,
							description: values?.project?.description,
							glSegment: values?.project?.glSegment,
							orgLevelGroup: values?.project?.orgLevelGroup,
							level: useInClockInGroup?.level?.replace("_", ""),
						},
						startDate: start,
						endDate: end,
						clocks: getClocksFromValues(
							{
								...values,
								start: start,
								end: end,
								ip: ip ? ip : "",
							},
							location,
						),
						note: values.note,
					};
				}
				case "edit-timesheet": {
					const job = user?.jobs?.find(({ id }) => id === values.job);
					const location = user?.locations?.find(
						({ id }) => id === values.location,
					);

					const timezone = location?.timezoneValue;

					let start = combineDateAndTime(
						values.start.date,
						values.start.time,
						timezone,
					);
					let end = combineDateAndTime(
						values.end.date,
						values.end.time,
						timezone,
					);
					start = start.toISOString(true);
					end = end.toISOString(true);

					return {
						job: job,
						location: location,
						startDate: start,
						endDate: end,
						clocks: getClocksFromValues(
							{
								...values,
								start: start,
								end: end,
								ip: ip ? ip : "",
							},
							location,
						),
						note: values.note,
					};
				}
				case "edit-schedule":
				case "submit-schedule":
				case "schedule": {
					const date = moment(values?.date).format("YYYY-MM-DD");
					let startDate = combineDateAndTime(
						moment(date),
						moment(values.startDate),
						values?.location?.timezoneValue
					);
					let endDate = combineDateAndTime(
						moment(date),
						moment(values.endDate),
						values?.location?.timezoneValue
					);
					if (startDate && endDate.isBefore(startDate)) {
						endDate.add(1, "day");
					}

					const calcFields = {
						...calculateFields(values),
						date,
						startDate: startDate.toISOString(true),
						endDate: endDate.toISOString(true),
						duration: endDate.diff(startDate, 'seconds'),
						durationUnit: 'second',
						company
					};
				
					return prepareEventForDb(calcFields);
				}
				default: {
					return null;
				}
			}
		},
		[selected, user, ip, useInClockInGroup, company],
	);

	const onFinish = useCallback(
		(values) => {
			if (!selected?.id) {
				return;
			}

			const data = { 
				...requestData(values, selected.type),
				id: selected?.data?.id
			};

			update(selected.id, { data }, (updatedRequest) => {
				onEditSuccess(updatedRequest);
				close();
				toast.success(t("request-updated"));
			});
		},
		[selected, close, onEditSuccess, requestData, update, t],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	const content = useMemo(() => {
		if (!selected) {
			return null;
		}
		switch (selected.type) {
			case "absence": {
				return (
					<AddAbsence
						values={selected}
						onClose={close}
						error={error}
						loading={loading}
						onFinish={onFinish}
					/>
				);
			}
			case "shift":
			case "edit-shift":
			case "edit-timesheet": {
				return (
					<AddShift
						values={selected}
						onClose={close}
						error={error}
						loading={loading}
						onFinish={onFinish}
						isTimesheetRequest={
							selected?.type === "edit-timesheet" ? true : false
						}
					/>
				);
			}
			case "edit-schedule":
			case "submit-schedule":
			case "schedule": {
				return (
					<AddSchedule
					 	values={selected}
						onClose={close}
						error={error}
						loading={loading}
						onFinish={onFinish}
					/>
				)
			}
			default: {
				throw new Error(
					"unexpected-request-type", {
						type: selected.type
					}
				);
			}
		}
	}, [user, selected, onFinish, error, loading, close]);

	return { visible, content, selected, open, close };
};
