import { useCallback, useMemo } from "react";
import { useVisible } from "../../utils/hooks/useVisible";
import { useIsMasterCompany } from "../../utils/hooks/user";
import { useAccess } from "../../utils/hooks/access";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";

function BulkActions({ rows, batchRemove, assign }) {
	const { t } = useTranslation();

	const { visible, toggle } = useVisible();
	const isMasterCompany = useIsMasterCompany();

	const ids = useMemo(() => {
		return Object.keys(rows);
	}, [rows]);

	const { hasAccess: deleteAccess } = useAccess("people.canDelete");
	const { hasAccess: canAssignGroups } = useAccess("people.canAssignGroups");

	const disabledDeleteBtn = useMemo(() => {
		return ids.length === 0 || !deleteAccess || !isMasterCompany;
	}, [ids, deleteAccess, isMasterCompany]);

	const disabledAssignBtn = useMemo(() => {
		return ids.length === 0 /**|| !canAssignGroups */ || !isMasterCompany;
	}, [ids, canAssignGroups, isMasterCompany]);

	const remove = useCallback(() => {
		batchRemove(ids);
	}, [ids, batchRemove]);

	return (
		<div className="bulk-actions d-flex align-items-center">
			<span className="text-sm font-weight-600 text-dark mr-1 text-center">
				{t("selected")}{" "}
				<span
					className="text-sm rounded-circle py-1 px-2 mx-1"
					style={{ backgroundColor: "#ced4da" }}
				>
					{Object.keys(rows).length}
				</span>
			</span>
			<Button
				color="primary"
				size="sm"
				className="mr-2"
				onClick={assign}
				disabled={disabledAssignBtn}
			>
				{t("assign-groups")}
			</Button>
			<Button
				color="danger"
				size="sm"
				onClick={remove}
				disabled={disabledDeleteBtn}
			>
				{t("delete")}
			</Button>
		</div>
	);
}

export default BulkActions;
