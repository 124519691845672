import _, { noop } from "lodash";
import { useMemo } from "react";
import { Card, CardHeader } from "reactstrap";
import { useTableContext } from "../../../../../components/Table/context";
import { useConfigColumns } from "../../../../Settings/Configuration/hooks/newTableHooks/useConfigColumns";
import { useSyncColumnVisibilityWithConfig } from "../../../../Settings/Configuration/hooks/newTableHooks/useSyncColumnVisibilityWithConfig";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { useFetchUserTimeSheets } from "../../hooks/useFetchUserTimeSheets";
import Table from "./Table";
import ColumnToggle from "./ColumnToggle";

import { useColumns } from "./hooks/shared/useColumns";
import { useTable } from "./hooks/shared/useTable";

import { useDateActions } from "./hooks/useDateActions";
import { usePermissions } from "./hooks/usePermissions";
import { useSubmit } from "./hooks/useSubmit";

import "./_style.scss";

function TimeSheetTable({ user, state, openDaySummary, openCopyModal }) {
  const {
    setRowState,
    updateUser,
    refetchOverview,
  } = useTableContext();
  const { data, loading } = state;
  const setState = useMemo(() => {
    return setRowState.bind(null, user.id);
  }, [user, setRowState]);

  const { from, to } = useDateFilters();
  const { mutate: refetchTimeSheets } = useFetchUserTimeSheets({
    params: useMemo(() => ({ from, to }), [from, to]),
    user: user.id,
    setState,
  });

  const {
    approve,
    unapprove,
    lock,
    unlock,
    isMutating: isLoadingDateAction,
  } = useDateActions({
    userId: user.id,
    onSuccess: refetchTimeSheets,
    onFailure: noop,
  });

  const columns = useColumns({
    user,
    openCopyModal,
    openDaySummary,
  });

  const permissions = usePermissions();

  const table = useTable({
    user,
    columns: useConfigColumns("timeSheetWeekly.timeSheet", columns),
    data,

    permissions,
    lock, unlock, approve, unapprove, isLoadingDateAction,
  });
  const { reset } = table.options.meta;

  const { trigger, isMutating } = useSubmit({
    user,
    data: table.options.data,
    onSuccess: async (res) => {
      await Promise.all([
        refetchTimeSheets(),
        updateUser(res),
        refetchOverview(),
      ]);
      reset();
    },
    onFailure: noop,
  });

  useSyncColumnVisibilityWithConfig({
    table,
    configuration: "timeSheetWeekly.timeSheet",
    userPreferences: "weeklyTimeSheetUserTimeSheet",
  });

  return (
    <div className="timesheet-weekly">
      <Card className="mb-auto">
        <CardHeader className="border-top p-3">
          <div className="d-flex justify-content-end align-items-center">
            <ColumnToggle
              prefix={`timesheet-user-${user.id}`}
              columns={table.getAllColumns()}
            />
          </div>
        </CardHeader>
      </Card>

      <Table table={table} loading={loading} submit={trigger} isSubmitting={isMutating} />
    </div>
  )
}

export default TimeSheetTable;