import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../components/Field";
import {
	getSettingsOrgResourceSearchFilters,
	renderOrgLevelResourceLabel,
} from "../../../../../utils/helpers/settings";
import { firstToUpper } from "../../../../../utils/helpers/string";

function Group({ name, group }) {
	const { t } = useTranslation();

	return (
		<Field
			name={name}
			label={firstToUpper(group.description)}
			rules={[
				{
					required: group.isRequired,
					message: group.isRequired
						? t("required-group", { group: renderOrgLevelResourceLabel(group) })
						: "",
				},
			]}
		>
			<ResourceSelect
				labelPropName="description"
				resourcePath={`/${group.id}/org-levels`}
				placeholder={t("select-group", {
					group: renderOrgLevelResourceLabel(group),
				})}
				renderLabel={renderOrgLevelResourceLabel}
				allowClear={!group.isRequired}
				hasSearch
				getSearchFilters={getSettingsOrgResourceSearchFilters}
			/>
		</Field>
	);
}

export default Group;
