import { Card, CardBody, CardHeader } from "reactstrap";
import { OverviewProvider } from "../../components/Topbar/context";
import ConfigurationLoader from "../../configurationLoader";
import { FiltersProvider } from "../../filtersContext";
import { DateFiltersProvider } from "../../hooks/useDateFilters";
import { PayCodesProvider } from "../../reusedResourcesContext";
import Topbar from "./Topbar";
import Content from "./Content";

function Weekly() {
  return (
    <PayCodesProvider>
      <ConfigurationLoader>
        <FiltersProvider sessionKey="TIMESHEET_WEEKLY_HOME_FILTERS">
          <DateFiltersProvider>
            <OverviewProvider>
              <Card className="time-sheet px-0 m-0 h-100 position-static">
                <CardHeader>
                  <Topbar />
                </CardHeader>

                <CardBody className="p-0 d-flex flex-column">
                  <Content />
                </CardBody>
              </Card>
            </OverviewProvider>
          </DateFiltersProvider>
        </FiltersProvider>
      </ConfigurationLoader>
    </PayCodesProvider>
  );
}

export default Weekly;
