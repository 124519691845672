import { useCallback, useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../components/Drawer";
import Action from "../../Actions/Action";
import ChangeAddress from "./ChangeAddress";
import ChangeCompanyInformation from "./ChangeCompanyInformation";
import ChangeManagement from "./ChangeManagement";
import ChangePersonalInformation from "./ChangePersonalInformation";
import ChangeRole from "./ChangeRole";
import ChangeStatus from "./ChangeStatus";
import ChangePassword from "./ChangePassword";
import TerminateEmployee from "./TerminateEmployee";
import ChangeKeyDates from "./ChangeKeyDates";
import { ProfileContext } from "../../context";
import { hasManagingRole } from "../../../../utils/helpers/user";
import { useAccess } from "../../../../utils/hooks/access";
import { useUser } from "../../../../utils/hooks/user";

function Actions() {
	const { t } = useTranslation();
	const { user, hierarchyGroup, isSelf } = useContext(ProfileContext);
	const loggedUser = useUser();

	const [action, setAction] = useState(null);

	const close = useCallback(() => setAction(null), [setAction]);

	const { hasAccess: canChangePersonalInfo } = useAccess(
		isSelf
			? "profile.changePersonalInformation"
			: "people.changePersonalInformation",
	);
	const { hasAccess: canChangeCompanyInfo } = useAccess(
		isSelf
			? "profile.changeCompanyInformation"
			: "people.changeCompanyInformation",
	);
	const { hasAccess: canChangeAddress } = useAccess(
		isSelf ? "profile.changeAddress" : "people.changeAddress",
	);
	const { hasAccess: canChangeStatus } = useAccess(
		isSelf ? "profile.changeStatus" : "people.changeStatus",
	);
	const { hasAccess: canChangeKeyDates } = useAccess(
		isSelf ? "profile.changeKeyDates" : "people.changeKeyDates",
	);
	const { hasAccess: canChangeManagement } = useAccess(
		isSelf ? "profile.changeManagement" : "people.changeManagement",
	);
	const { hasAccess: canChangeRole } = useAccess("people.changeRole");
	const { hasAccess: canTerminateEmployee } = useAccess(
		"people.terminateEmployee",
	);

	const drawerContent = useMemo(() => {
		switch (action) {
			case "personal-information":
				return <ChangePersonalInformation close={close} />;
			case "company-information":
				return <ChangeCompanyInformation close={close} />;
			case "address":
				return <ChangeAddress close={close} />;
			case "management":
				return <ChangeManagement close={close} />;
			case "role":
				return <ChangeRole close={close} />;
			case "status":
				return <ChangeStatus close={close} />;
			case "key-dates":
				return <ChangeKeyDates close={close} />;
			case "terminate-employee":
				return <TerminateEmployee close={close} />;
			case "change-password":
				return <ChangePassword close={close} />;
			default:
				return <></>;
		}
	}, [action, close]);

	return (
		<>
			{canChangePersonalInfo && (
				<>
					<Action
						title={t("personal-information")}
						onClick={() => setAction("personal-information")}
					>
						{t("change")}
					</Action>

					<hr className="my-4" />
				</>
			)}

			{canChangeCompanyInfo && (
				<>
					<Action
						title={t("company-information")}
						onClick={() => setAction("company-information")}
					>
						{t("change")}
					</Action>

					<hr className="my-4" />
				</>
			)}

			{isSelf && (
				<>
					<Action
						title={t("password")}
						onClick={() => setAction("change-password")}
					>
						{t("change")}
					</Action>

					<hr className="my-4" />
				</>
			)}

			{hierarchyGroup &&
				hasManagingRole(loggedUser?.roles || []) &&
				canChangeManagement && (
					<>
						<Action
							title={t("management")}
							onClick={() => setAction("management")}
						>
							{t("change")}
						</Action>

						<hr className="my-4" />
					</>
				)}

			{canChangeAddress && (
				<>
					<Action
						title={t("address")}
						onClick={() => setAction("address")}
					>
						{t("change")}
					</Action>
					<hr className="my-4" />
				</>
			)}

			{canChangeStatus && (
				<>
					<Action
						title={t("status")}
						onClick={() => setAction("status")}
					>
						{t("change")}
					</Action>

					<hr className="my-4" />
				</>
			)}

			{canChangeKeyDates && (
				<>
					<Action
						title={t("Key dates")}
						onClick={() => setAction("key-dates")}
					>
						{t("Change")}
					</Action>

					<hr className="my-4" />
				</>
			)}

			{!isSelf && (
				<>
					{canChangeRole && (
						<>
							<Action
								title={t("roles")}
								onClick={() => setAction("role")}
							>
								{t("change")}
							</Action>

							<hr className="my-4" />
						</>
					)}

					{canTerminateEmployee && (
						<Action
							title={t("terminate-employee")}
							onClick={() => setAction("terminate-employee")}
							disabled={user?.isApi}
						>
							{t("terminate")}
						</Action>
					)}
				</>
			)}

			<Drawer
				title={t(action)}
				open={action !== null}
				close={close}
				style={{
					maxWidth: "initial",
				}}
			>
				{drawerContent}
			</Drawer>
		</>
	);
}

export default Actions;
