import {useState, useCallback, useEffect} from "react";
import DateInput from ".";

const RangeDateInput = ({
    startDate: parentStartDate,
    endDate: parentEndDate,
    onChange: parentOnChange,
    ...rest
}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onChange = useCallback((value) => {
        const [start, end] = value;
        if (end) {
            parentOnChange?.([start, end]);
        } else {
            setStartDate(start);
            setEndDate(end);
        }
    }, [parentOnChange, setStartDate, setEndDate]);

    useEffect(() => {
        setStartDate(parentStartDate);
        setEndDate(parentEndDate);
    }, [parentStartDate, parentEndDate, setStartDate, setEndDate]);

    return (
        <DateInput
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            selectsRange
            {...rest}
        />
    );
}

export default RangeDateInput;
