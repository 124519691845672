import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const { code, description, status, actions } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
			columnHelper.accessor("group", {
				header: t("group"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && renderOrgLevelResourceLabel(value);
                },
			}),
			columnHelper.accessor("family", {
				header: t("family"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && renderSettingsResourceLabel(value);
                },
			}),
			columnHelper.accessor("skills", {
				header: t("skills"),
                cell: (info) => (
					<div className="d-flex flex-wrap">
						{info.getValue()?.map((skill) => (
							<span
								key={skill}
								className="mr-1 border border-primary px-2 py-1 rounded bg-primary text-white front-weight-bolder mb-1"
							>
								{skill}
							</span>
						))}
					</div>
                )
			}),
			columnHelper.accessor("licenseCertificates", {
				header: t("license-certification"),
                cell: (info) => (
					<div className="d-flex flex-wrap">
						{info.getValue()?.map((license) => (
							<span
								key={license}
								className="mr-1 border border-primary px-2 py-1 rounded bg-primary text-white front-weight-bolder mb-1"
							>
								{license}
							</span>
						))}
					</div>
                ),
			}),
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [t, isMasterCompany, code, description, status, actions]);
};
