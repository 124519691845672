import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal";
import Form from "./Form";
import { useMyRequests } from "../api";
import { formatFormData } from "../../TimeClock/ClockIn/helpers";
import { useUser } from "../../../utils/hooks/user";
import { getPrimaryActiveResource } from "../../../utils/helpers/user";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import "./_style.scss";

function AddShift({ isOpen, onClose, setData }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const user = useUser();

	const { create, loading, error } = useMyRequests();

	const { ip, getIp } = useGetIpAddress();

	const job = useMemo(
		() => getPrimaryActiveResource(user?.jobs || []),
		[user?.jobs],
	);

	const location = useMemo(
		() => getPrimaryActiveResource(user?.locations || []),
		[user?.locations],
	);

	const values = useMemo(
		() => ({
			data: {
				location,
				job,
			},
		}),
		[location, job],
	);

	const onFinish = useCallback(
		(values) => {
			if (!user) {
				return;
			}

			const job = user.jobs?.find(({ id }) => id === values.job);
			const location = user.locations?.find(
				({ id }) => id === values.location,
			);

			const request = {
				user: user.id,
				type: "shift",
				data: formatFormData({
					...values,
					job: job,
					location: location,
					project: {
						id: values?.project?.id,
						code: values?.project?.code,
						description: values?.project?.description,
						glSegment: values?.project?.glSegment,
						orgLevelGroup: values?.project?.orgLevelGroup,
						level: useInClockInGroup?.level?.replace("_", ""),
					},
					ip: ip ? ip : "",
				}),
				device: "web-application",
				ip: ip ? ip : "",
			};

			create(request, (request) => {
				onClose();
				setData((prev) => prev.concat(request));
				toast.success(t("request-created-successfully"));
			});
		},
		[t, user, create, setData, onClose, ip, useInClockInGroup],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	return (
		<Modal
			className="add-shift-modal modal-dialog-centered modal-lg pb-0 shadow-none"
			title={t("add-shift-request")}
			isOpen={isOpen}
			toggle={onClose}
			size="lg"
			scrollable
		>
			<Form
				onClose={onClose}
				values={values}
				onFinish={onFinish}
				error={error}
				loading={loading}
			/>
		</Modal>
	);
}

export default AddShift;
