import {useEffect} from "react";
import {useQrCodeClockIn} from "../context";

export const usePosition = () => {
    const {dispatch} = useQrCodeClockIn();

	useEffect(() => {
        if ("geolocation" in navigator) {
            const id = navigator.geolocation.watchPosition(
                ({ coords: { latitude, longitude } }) => {
                    dispatch({
                        type: "set-position",
                        payload: {
                            position: {
                                lat: latitude,
                                lng: longitude,
                            },
                            isGeolocationEnabled: true,
                        },
                    });
                },
                (err) => {
                    dispatch({
                        type: "set-position",
                        payload: {
                            position: null,
                            isGeolocationEnabled: false,
                        },
                    });
                },
                {
                    enableHighAccuracy: false,
                    timeout: Infinity,
                    maximumAge: 0,
                },
            );
            return () => navigator.geolocation.clearWatch(id);
        } else {
            dispatch({
                type: "set-position",
                payload: {
                    position: null,
                    isGeolocationEnabled: false,
                },
            });
        }
	}, [dispatch]);
}
