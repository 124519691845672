import { useCallback, useState } from "react";
import { Option } from "rc-select";
import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Select from "../../../../../../components/Inputs/Select";
import Text from "../../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters } from "../../../../../../utils/helpers/settings";
import { useColumnsMap } from "../../../helpers";
import { firstToUpper } from "../../../../../../utils/helpers/string";
import { renderLabel } from "../../../CreateReport/ReportDispaly/useColumns";

function Item({ columns, name, form }) {
	const { t } = useTranslation();

	const { getColumns } = useColumnsMap();
	const [disabledFiltersMethod, setDisabledFiltersMethod] = useState(false);
	const [collection, setCollection] = useState();

	const getInput = useCallback(
		(value, endpoint, mode) => {
			const column = getColumns([value]);
			const type = column[0]?.type;

			switch (type) {
				case "resource-select": {
					const resourcePath =
						endpoint === "/companies"
							? "/companies?pagination=false"
							: endpoint;
					return (
						<ResourceSelect
							labelPropName="description"
							resourcePath={resourcePath}
							mode={mode}
							renderLabel={(item) =>
								renderLabel(column[0]?.renderLabel, item)
							}
							getSearchFilters={getSettingsResourceSearchFilters}
							hasSearch
							style={{ minWidth: "200px" }}
						/>
					);
				}
				case "select":
					return (
						<Select mode={mode} style={{ minWidth: "200px" }}>
							{column[0]?.options?.map((option) => (
								<Option value={option.value}>
									{option.label}
								</Option>
							))}
						</Select>
					);

				case "text":
				default:
					return <Text />;
			}
		},
		[getColumns],
	);

	const onChange = useCallback(
		(value) => {
			const column = getColumns([value]);
			const type = column[0].type;

			const collection = column[0]?.key === value ? column[0]?.name : "";

			setCollection(collection);

			if (type === "resource-select" || type === "select") {
				form.setFieldValue(["filters", name, "method"], "exact");
				setDisabledFiltersMethod(true);
			} else {
				form.setFieldValue(["filters", name, "method"], "contains");
				setDisabledFiltersMethod(false);
			}

			form.setFieldValue(["filters", name, "value"], undefined);
		},
		[form, name, getColumns, setDisabledFiltersMethod, setCollection],
	);

	return (
		<>
			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const hiddenOptions =
						getFieldValue("filters").map(
							(filter) => filter?.name,
						) || [];

					return (
						<>
							<div
								className="mb-4 mr-2 font-weight-bolder"
								style={{ width: 100 }}
							>
								{collection ? (
									firstToUpper(collection)
								) : (
									<span className="text-gray">
										{t("collection")}
									</span>
								)}
							</div>

							<Field name={[name, "name"]} className="mr-1">
								<Select
									placeholder={t("column")}
									onChange={onChange}
								>
									{columns?.map(
										(value) =>
											value?.type && (
												<Option
													key={value.id}
													value={value.key}
													hidden={hiddenOptions.includes(
														value.key,
													)}
												>
													{value.label}
												</Option>
											),
									)}
								</Select>
							</Field>

							<Field name={[name, "method"]} className="mr-1" hidden={true}>
								<Select
									placeholder={t("method")}
									disabled={disabledFiltersMethod}
								>
									<Option value="contains">
										{t("contains")}
									</Option>
									<Option value="exact">{t("exact")}</Option>
								</Select>
							</Field>
						</>
					);
				}}
			</BaseField>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) => {
					const column = getColumns([
						getFieldValue(["filters", name, "name"]),
					]);
					const endpoint = column[0]?.endpoint;

					return (
						<Field name={[name, "value"]}>
							{getInput(
								getFieldValue(["filters", name, "name"]),
								endpoint,
								column[0]?.multiselect ? "multiple" : "single",
							)}
						</Field>
					);
				}}
			</BaseField>
		</>
	);
}

export default Item;
