import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../../../../components/Field";
import Select from "../../../../../../../../../components/Inputs/Select";
import ResourceSelect from "../../../../../../../../../components/Inputs/ResourceSelect";
import PayCodeSelect from "../../../PayCodeSelect";
import HourTypeSelect from "../../../../../../../../../components/Inputs/HourTypeSelect";
import Checkbox from "../../../../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../../../../components/Button";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../../helper";
import { weekdays } from "../../../../../../../../../components/Inputs/WeekdaySelect";

function Details({ overtime, close, disabled }) {
	const { t } = useTranslation();
	const [hidden, setHidden] = useState(false);

	const onChange = useCallback(
		(e) => setHidden(!e.target.checked),
		[setHidden],
	);

	return (
		<Form
			name="all-hours-worked-on"
			onFinish={close}
			labelAlign="left"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 14, offset: 2 }}
			colon={false}
			initialValues={overtime}
			scrollToFirstError
		>
			<Field
				label={t("day")}
				name="value"
				rules={[
					{
						required: true,
						message: t("required-all-hours-worked-on"),
					},
				]}
			>
				<Select
					placeholder={t("day")}
					disabled={disabled}
					mode="multiple"
				>
					{weekdays.map((day) => (
						<Select.Option value={day}>{t(day)}</Select.Option>
					))}
				</Select>
			</Field>

			{/* <div className="all-hours-worked-on">
				<span className="text-center">{t("Work more than")}</span>

				<BaseField
					name="workedHours"
					rules={[
						{
							required: true,
							message: t("Worked hours are required"),
						},
						{
							validator(_, value) {
								if (Number(value) <= 0) {
									return Promise.reject(
										new Error(
											t(
												"Worked hours sholud be a number bigger than 0",
											),
										),
									);
								}
								return Promise.resolve();
							},
						},
					]}
				>
					<Text type="number" disabled={disabled} />
				</BaseField>

				<span className="ml-1">{t("Hours")}</span>

				<span className="text-center">{t("pay-code")}</span>

				<BaseField
					name="payCode"
					rules={[
						{
							required: true,
							message: t("Pay code is required"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
						placeholder={t("Select pay code")}
						hasSearch
						getSearchFilters={(search) => {
							return { ...getSettingsResourceSearchFilters(search) }
						}}
						disabled={disabled}
						allowClear
					/>
				</BaseField>
			</div> */}

			{/* <BaseField shouldUpdate>
				{({}, {}, { getFieldError }) => {
					const workedHoursError = getFieldError("workedHours");
					const payCodeError = getFieldError("payCode");

					return (
						<>
							{workedHoursError && (
								<span className="invalid-feedback d-block mb-3">
									{workedHoursError[0]}
								</span>
							)}

							{payCodeError && (
								<span className="invalid-feedback d-block mb-3">
									{payCodeError}
								</span>
							)}
						</>
					);
				}}
			</BaseField> */}

			<Field label={t("default-pay-code")} name="defaultPayCode">
				<ResourceSelect
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
					placeholder={t("default-pay-code")}
					hasSearch
					getSearchFilters={(search) => {
						return { ...getSettingsResourceSearchFilters(search) }
					}}
					disabled={disabled}
					allowClear
				/>
			</Field>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldsValue }) => {
					const payCodes = getFieldValue("payCodes");
					const onChange = (hourTypes) => {
						setFieldsValue({
							payCodes: payCodes?.filter((payCode) =>
								hourTypes.includes(payCode.hourType),
							),
						});
					};

					return (
						<Field
							label={t("eligible-hour-types")}
							name="hourTypes"
						>
							<HourTypeSelect
								mode="multiple"
								placeholder={t("eligible-hour-types")}
								onChange={onChange}
								disabled={disabled}
							/>
						</Field>
					);
				}}
			</BaseField>

			<div className="mb-3">
				<Checkbox
					checked={!hidden}
					onChange={onChange}
					id="show-pay-code"
					disabled={disabled}
				>
					{t("show-eligible-pay-code")}
				</Checkbox>
			</div>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) =>
					!hidden && (
						<PayCodeSelect
							hourTypes={getFieldValue("hourTypes")}
							name="payCodes"
							hidden={hidden}
							disabled={disabled}
						/>
					)
				}
			</BaseField>

			<div className="d-flex justify-content-between my-2">
				{disabled ? (
					<div />
				) : (
					<Button
						type="submit"
						color="primary"
						size="xs"
						className="bg-dark"
					>
						{t("save-changes")}
					</Button>
				)}

				<Button
					size="small"
					className="border-primary-color mr-1"
					onClick={close}
				>
					{t("close")}
				</Button>
			</div>
		</Form>
	);
}

export default Details;
