import { useCallback, useEffect } from "react";
import { api } from "../../../utils/api";
import { isCancelError } from "../../../utils/helpers/errors";
import {
	getBackendUrl,
	getSubDomainFromDomain,
} from "../../../utils/helpers/url";
import { useQrCodeClockIn } from "../context";

export const useAuthenticate = () => {
	const { token, domainValid, hasAuthenticated, dispatch } =
		useQrCodeClockIn();

	const authenticate = useCallback(
		async (controller) => {
			dispatch({ type: "authenticate" });
			try {
				const baseURL = getBackendUrl(
					getSubDomainFromDomain(window.location.hostname),
				);
				const response = await api.get("/check-token", {
					baseURL,
					signal: controller.signal,
					Authorization: `Bearer ${token}`,
					headers: {
						Authorization: `Bearer ${token}`,
					},
					Headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (response) {
					const { user } = response.data;
					const companyId = localStorage.getItem(
						"QR_CODE_CLOCK_COMPANY",
					);

					let company = user.companies[0];

					if (companyId) {
						company = user.companies.find(
							({ id }) => id === companyId,
						);
					}

					dispatch({
						type: "authenticate-succeeded",
						payload: { user, company },
					});
				}
			} catch (err) {
				if (isCancelError(err)) {
					dispatch({ type: "authenticate-cancel" });
					return;
				}

				dispatch({ type: "authenticate-failed" });
				localStorage.removeItem("QR_CODE_CLOCK_IN_TOKEN");
				localStorage.removeItem("QR_CODE_CLOCK_COMPANY");
			}
		},
		[dispatch, token],
	);

	useEffect(() => {
		const controller = new AbortController();
		if (domainValid && !hasAuthenticated && token) {
			authenticate(controller);
		}
		return () => controller.abort();
	}, [token, domainValid, hasAuthenticated, authenticate]);
};
