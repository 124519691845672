export const useMapNotifications = (data,t) => {
  
  return data.map((item) => {
    const title = t(`notifications::title::${item.type}`, {
      defaultValue: 'New notification recived',
      firstName: item?.createdBy?.firstName,
      lastName: item?.createdBy?.lastName
    });
    const body = t(`notifications::body::${item.type}`, {
      defaultValue: item.body,
      firstName: item?.createdBy?.firstName,
      lastName: item?.createdBy?.lastName
    });
    const createdAt = item.createdAt;
    const data = item.data || item?.data?.data;
    const read = item.read || item?.read;

    return {
      title,
      description: body,
      id: item.id,
      userId: item?.createdBy?.id,
      firstName: item?.createdBy?.firstName,
      lastName: item?.createdBy?.lastName,
      isRead: read,
      body,
      type: item.type,
      notType: item.type,
      data,
      createdAt
    };
  });
}