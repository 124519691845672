import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useRouteConfig } from "./config";

function Routing() {
	const config = useRouteConfig();
	const router = useMemo(() => {
		return createBrowserRouter(config)
	}, [config]);
	
	return <RouterProvider router={router} />;
}

export default Routing;
