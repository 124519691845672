// method for changing shift value 

import moment from "moment-timezone";
import { omit } from "lodash";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../utils/helpers/date";

export const __MIN_SHIFT_DURATION = 900;

export const recalculateShifts = (
  shifts,
  field,
  name,
  value,
  breakAmount = 0,
  defaultSegment,
  pushTimes = false,
  calcGroupBreaks,
  defaultPayCode
) => {
  // set and manipulate current shift
  shifts = updateCurrentShift(shifts, name, field, value, breakAmount);

  // check for shifts split
  shifts = checkShiftSplit(shifts, name, field, value, breakAmount);

  // check if there is an automatic break
  if(calcGroupBreaks?.automatic?.status === true) {
    // check all with paycode of break and return them to regular
    shifts = convertBreaksToRegular(shifts, defaultPayCode);
    // merge shifts
    // shifts = mergeShifts(shifts);
  }
  // Group shifts
  // shifts = checkShiftGroups(shifts)

  const currentShift = shifts[name];

  const _otherUpShifts = shifts.filter((s) => s?.group < currentShift?.group);
  const _otherDownShifts = shifts.filter((s, i) => s?.group > currentShift?.group);
  let newIndex = 0;
  const _continueShifts = [];
  shifts.forEach((s, i) => {
    if (i === name) {
      newIndex = _continueShifts.length;
      _continueShifts.push(s);
    } else if (s.group === currentShift?.group) {
      _continueShifts.push(s);
    }
  });
  const _continueCurrentShift = _continueShifts[newIndex];

  if (pushTimes) {
    // recalculate up chain
    shifts = recalculateUpShiftsByPushing(shifts, name, breakAmount);

    // recalculate down chain
    shifts = recalculateDownShiftsByPushing(shifts, name, breakAmount);
  } else {
    let upShifts = [];
    let downShifts = [];
    if (_continueShifts.length > 1) {
      // recalculate up chain
      upShifts = recalculateUpShifts(_continueShifts, newIndex, breakAmount);
      // recalculate down chain
      downShifts = recalculateDownShifts(_continueShifts, newIndex, breakAmount);
    }
    shifts = [..._otherUpShifts, ...upShifts, _continueCurrentShift, ...downShifts, ..._otherDownShifts];
  }
  // merge shifts
  shifts = mergeShifts(shifts);

  // check automatic break
  if(calcGroupBreaks?.automatic?.status === true) {
    // for each group check if automatic can be set
    const breaks = getAutomaticBreaks(calcGroupBreaks, shifts, defaultSegment);
    shifts = calculateShiftsWithAutomaticBreaks(shifts, breaks);
  }

  // check breaks
  shifts = checkBreaks(shifts, defaultSegment);
  // return shifts

  return shifts;
}

// update current shift
const updateCurrentShift = (shifts, name, field, value, breakAmount) => {
  const s = shifts[name];
  let minDuration = s.type === 'break' ? (breakAmount || __MIN_SHIFT_DURATION) : __MIN_SHIFT_DURATION;
  switch (field) {
    case 'duration':
      if (value < minDuration) {
        value = minDuration;
      }
      s.end = moment(s.start).clone().add(value, 'seconds');
      s.duration = renderDurationAsFormat(value, 'HH:mm');
      break;
    case 'start':
      s.start = value;
      if (moment(s.end).isBefore(s.start)) {
        s.end = moment(s.end).add(1, "day")
      }
      s.end = combineDateAndTime(moment(s.start), moment(s.end));
      
      if (s.end) {
        let segmentDuration = moment(s.end).diff(moment(s.start), 'seconds');
        if (segmentDuration < minDuration) {
          segmentDuration = minDuration;
        }
        s.duration = renderDurationAsFormat(segmentDuration, 'HH:mm')
        s.end = moment(s.start).clone().add(segmentDuration, 'seconds');
      } else {
        s.duration = '';
      }
      break;
    case 'end':
      s.end = value;
      s.start = combineDateAndTime(moment(s.end), moment(s.start));
      if (moment(s.end).isBefore(s.start)) {
        s.end = moment(s.end).add(1, "day")
      }

      if (s.start) {
        let segmentDuration = moment(s.end).diff(moment(s.start), 'seconds');
        if (segmentDuration < minDuration) {
          segmentDuration = minDuration;
        }
        s.duration = renderDurationAsFormat(segmentDuration, 'HH:mm')
        s.start = moment(s.end).clone().subtract(segmentDuration, 'seconds');
      } else {
        s.duration = '';
      }
      break;
    default:
      break;
  }
  shifts[name] = s;
  return shifts;
}

// check for shifts split
const checkShiftSplit = (shifts, name, field, value, breakAmount) => {
  const s = shifts[name];
  const previousShift = shifts[name - 1];
  const nextShift = shifts[name + 1];
  if (previousShift) {
    if (
      moment(s.start).unix() > moment(previousShift.start).unix() &&
      moment(s.start).unix() < moment(previousShift.end).unix() &&
      moment(s.end).unix() < moment(previousShift.end).unix() &&
      moment(s.end).unix() > moment(previousShift.start).unix()
    ) {
      // this is split
      const firstShift = {
        ...previousShift,
        end: s.start,
        duration: renderDurationAsFormat(moment(s.start).diff(moment(previousShift.start), 'seconds'), 'HH:mm'),
      };

      const secondShift = {
        ...previousShift,
        start: s.end,
        duration: renderDurationAsFormat(moment(previousShift.end).diff(moment(s.end), 'seconds'), 'HH:mm'),
      };

      shifts[name] = s;
      shifts[name - 1] = firstShift;
      if (name === shifts.length - 1) {
        shifts.push(secondShift);
      } else {
        shifts.splice(name + 1, 0, secondShift);
      }
    }
  }
  if (nextShift) {
    if (
      moment(s.start).unix() > moment(nextShift.start).unix() &&
      moment(s.start).unix() < moment(nextShift.end).unix() &&
      moment(s.end).unix() < moment(nextShift.end).unix() &&
      moment(s.end).unix() > moment(nextShift.start).unix()
    ) {
      // this is split
      const firstShift = {
        ...nextShift,
        end: s.start,
        duration: renderDurationAsFormat(moment(s.start).diff(moment(nextShift.start), 'seconds'), 'HH:mm'),
      };

      const secondShift = {
        ...nextShift,
        start: s.end,
        duration: renderDurationAsFormat(moment(nextShift.end).diff(moment(s.end), 'seconds'), 'HH:mm'),
      };
      shifts[name] = s;
      if (name === 0) {
        shifts.unshift(firstShift);
      } else {
        shifts.splice(name - 1, 0, firstShift);
      }
      shifts[name + 1] = secondShift;
    }
  }
  return shifts;
}

// method for updating up chain
const recalculateUpShiftsByPushing = (shifts, name, breakAmount) => {
  let currentShift = shifts[name];

  if (name > 0) {
    for (let i = name - 1; i >= 0; i--) {
      const s = shifts[i];
      if (moment(s.end).unix() !== moment(currentShift.start).unix()) {
        s.end = currentShift.start;
        let segmentDuration = moment(s.end).diff(moment(s.start), 'seconds');
        let minDuration = s.type === 'break' ? (breakAmount || __MIN_SHIFT_DURATION) : __MIN_SHIFT_DURATION;
        if (segmentDuration < minDuration) {
          segmentDuration = minDuration;
          s.start = moment(s.end).clone().subtract(minDuration, 'seconds');
        }
        s.duration = renderDurationAsFormat(segmentDuration, 'HH:mm');
        shifts[i] = s;
      }
      currentShift = s;
    }
  }

  return shifts;
}

const recalculateUpShifts = (shifts, name, breakAmount) => {
  let currentShift = shifts[name];
  const upShifts = [];

  if (name > 0) {
    for (let i = name - 1; i >= 0; i--) {
      const s = shifts[i];
      // delete if current start is equal or less than previous start
      if (moment(s.start).unix() >= moment(currentShift.start).unix()) {
        // this shift is not valid and should be deleted
        continue;
      }
      if ((
        (
          moment(s.start).unix() < moment(currentShift.start).unix() &&
          moment(s.end).unix() > moment(currentShift.start).unix()
        ) ||
        moment(s.end).unix() <= moment(currentShift.start).unix()
      )) {
        s.end = currentShift.start;
        let segmentDuration = moment(s.end).diff(moment(s.start), 'seconds');
        let minDuration = s.type === 'break' ? (breakAmount || __MIN_SHIFT_DURATION) : __MIN_SHIFT_DURATION;
        if (segmentDuration > 0 && (segmentDuration >= minDuration || s.type === 'overtime')) {
          s.duration = renderDurationAsFormat(segmentDuration, 'HH:mm');
          upShifts.unshift(s);
          currentShift = s;
        }
      } else {
        console.log('not valid, unreachable up chain')
        upShifts.unshift(s);
        currentShift = s;
      }
    }
  }

  return upShifts;
}

// method for updating down chain
const recalculateDownShiftsByPushing = (shifts, name, breakAmount) => {
  if (name < shifts.length - 1) {
    let currentShift = shifts[name];

    for (let i = name + 1; i < shifts.length; i++) {
      const s = shifts[i];
      if (moment(s.start).unix() !== moment(currentShift.end).unix()) {
        s.start = currentShift.end;
        let segmentDuration = moment(s.end).diff(moment(s.start), 'seconds');
        let minDuration = s.type === 'break' ? (breakAmount || __MIN_SHIFT_DURATION) : __MIN_SHIFT_DURATION;
        if (segmentDuration < minDuration) {
          segmentDuration = minDuration;
          s.end = moment(s.start).clone().add(minDuration, 'seconds');
        }
        s.duration = renderDurationAsFormat(segmentDuration, 'HH:mm');
        shifts[i] = s;
      }
      currentShift = s;
    }
  }
  return shifts;

}

const recalculateDownShifts = (shifts, name, breakAmount) => {
  const downShifts = [];
  if (name < shifts.length - 1) {
    let currentShift = shifts[name];

    for (let i = name + 1; i < shifts.length; i++) {
      const s = shifts[i];
      // delete if current end is equal or less than previous end
      if (moment(s.end).unix() <= moment(currentShift.end).unix()) {
        // this shift is not valid and should be deleted
        continue;
      }

      if ((
        (
          moment(s.start).unix() < moment(currentShift.end).unix() &&
          moment(s.end).unix() > moment(currentShift.end).unix()
        ) ||
        moment(s.start).unix() >= moment(currentShift.end).unix()
      )) {
        s.start = currentShift.end;
        let segmentDuration = moment(s.end).diff(moment(s.start), 'seconds');
        let minDuration = s.type === 'break' ? (breakAmount || __MIN_SHIFT_DURATION) : __MIN_SHIFT_DURATION;
        if (segmentDuration > 0 && (segmentDuration >= minDuration || s.type === 'overtime')) {
          s.duration = renderDurationAsFormat(segmentDuration, 'HH:mm');
          downShifts.unshift(s);
          currentShift = s;
        }
      } else {
        console.log('not valid, unreachable up chain')
        downShifts.unshift(s);
        currentShift = s;
      }
    }
  }
  return downShifts;

}

// check breaks
const checkBreaks = (shifts, defaultSegment) => {
  let newSegmentAtTop = undefined;
  let newSegmentAtEnd = undefined;
  if (shifts.length) {
    const firstSegment = shifts[0];
    const lastSegment = shifts[shifts.length - 1];
    if (firstSegment.type === 'break') {
      newSegmentAtTop = JSON.parse(JSON.stringify(defaultSegment));
      newSegmentAtTop.end = moment(firstSegment.start).clone();
      newSegmentAtTop.duration = renderDurationAsFormat(__MIN_SHIFT_DURATION, 'HH:mm');
      newSegmentAtTop.start = newSegmentAtTop.end.clone().subtract(__MIN_SHIFT_DURATION, 'seconds');
    }

    if (lastSegment.type === 'break') {
      newSegmentAtEnd = JSON.parse(JSON.stringify(defaultSegment));
      newSegmentAtEnd.start = moment(lastSegment.end).clone();
      newSegmentAtEnd.duration = renderDurationAsFormat(__MIN_SHIFT_DURATION, 'HH:mm');
      newSegmentAtEnd.end = newSegmentAtEnd.start.clone().add(__MIN_SHIFT_DURATION, 'seconds');

    }
  }

  if (newSegmentAtTop) {
    shifts.unshift(newSegmentAtTop);
  }
  if (newSegmentAtEnd) {
    shifts.push(newSegmentAtEnd);
  }

  return shifts;
}

// merge shifts
export const mergeShifts = (shifts) => {
  const newShifts = [];
  let currentShift = shifts[0];
  for (let i = 1; i < shifts.length; i++) {
    const s = shifts[i];

    if (!!areShiftsEqual(currentShift, s)) {
      currentShift.end = s.end;
      currentShift.duration = renderDurationAsFormat(
        moment(currentShift.end).diff(moment(currentShift.start), 'seconds'), 'HH:mm'
      );
    } else {
      newShifts.push(currentShift);
      currentShift = s;
    }
  }
  newShifts.push(currentShift);

  return newShifts;
}

// merge shifts
export const checkShiftGroups = (shifts) => {
  let group = 0;
  return shifts.map((s, i) => {
    if(i === 0) {
      s.group = group;
    } else {
      const previous = shifts[i - 1];
      if(!isContinueShift(previous, s)) {
        group +=1;
      }
      s.group = group;
    }
    return s;
  });
}

export const calculateShiftsWithAutomaticBreaks = (shifts, breaks) => {
  const firstShift = shifts?.[0]
  const startDate = firstShift?.start;
  const endDate = shifts?.[shifts?.length - 1]?.end;
  const baseSegmentData = omit(firstShift, ["start", "end", "type"]);
  const duration = moment(breaks?.length > 0 
    ? breaks[0]?.start 
    : endDate).diff(moment(startDate), "seconds");

  let segments = [{
    ...baseSegmentData,
    start: startDate,
    end: breaks?.length > 0 ? breaks[0]?.start : endDate,
    duration: renderDurationAsFormat(duration || 0, "HH:mm"),
    type: "regular",
  }];
  const hasBreak = shifts?.find(({ type }) => type === "break");
  if (breaks?.length === 0 && shifts?.length > 1 && !hasBreak) {
    segments = mergeShifts(shifts);
  }
  
  for (let i = 0; i < breaks.length; i++) {
    const generatedBreak = breaks[i];
    const nextGeneratedBreak = breaks[i + 1];
    segments.push(generatedBreak);
    if (nextGeneratedBreak) {
      const duration = moment(nextGeneratedBreak.start).diff(moment(generatedBreak.end), "seconds");
      segments.push({
        ...baseSegmentData,
        start: generatedBreak.end,
        end: nextGeneratedBreak.start,
        duration: renderDurationAsFormat(duration || 0, "HH:mm"),
        type: "regular",
      });
    }
  }
  if (breaks?.length > 0) {
    let otherSegments = [];
    if (segments?.length >= breaks?.length) {
      otherSegments = shifts?.filter((item, index) => (index > breaks?.length && item?.type !=="break"));
      if (otherSegments?.length > 0) {
        const _duration = moment(otherSegments[0]?.end).diff(
          moment(breaks[breaks.length - 1].end), 
          "seconds"
        );

        otherSegments[0] = {
          ...otherSegments[0],
          start: breaks[breaks.length - 1].end,
          duration: renderDurationAsFormat(_duration, "HH:mm")
        }
        otherSegments = mergeShifts(otherSegments);
        segments.push(...otherSegments);
      } else {
        const _duration = moment(endDate).diff(moment(breaks[breaks.length - 1].end), "seconds");
        segments.push({
          ...baseSegmentData,
          start: breaks[breaks.length - 1].end,
          end: endDate,
          type: "regular",
          duration: renderDurationAsFormat(_duration || 0, "HH:mm"),
        });
      }
    }
  }

  return segments;
}

export const convertBreaksToRegular = (shifts, defaultPayCode) => {
  return shifts?.map((shift) => {
    if (shift?.type === "break") {
      return {
        ...shift,
        type: "regular",
        payCode: defaultPayCode,
        hourtType: defaultPayCode?.hourtType
      }
    } else {
      return shift;
    }
  })
}

export const getAutomaticBreaks = (calcGroupBreaks, shifts, defaultSegment) => {
  let start, end, seconds = 0;
  const startDate = moment(shifts?.[0]?.start || defaultSegment?.start);
  const endDate = moment(shifts?.[shifts?.length - 1]?.end || defaultSegment?.end);
  const diff = moment(endDate).diff(moment(startDate), "seconds");

  if (!!calcGroupBreaks?.automatic?.hideBreakAndDeductTotal) {
    return calcGroupBreaks?.automatic?.breaks?.map(({ after, amount }) => {
      if ((Number(after) * 60) <= diff) {
        const start = moment(startDate).add(5, "hours");
        const end = moment(start).add(Number(amount), "minutes");

        return {
          ...defaultSegment,
          start,
          end,
          type: "break",
          payCode: calcGroupBreaks?.automatic?.payCode,
          duration: renderDurationAsFormat(Number(amount) * 60, "HH:mm"),
          after: 18000
        }
      }
    })?.filter((item) => item);
  } else {
    return calcGroupBreaks?.automatic?.breaks?.map(({ after, amount }, i) => {
      const minutes = Number(after) > 15 ? Number(after) : 15;
      if (i === 0) {
        start = moment(startDate).add(minutes, "minutes");
        end = moment(start).add(Number(amount), "minutes");
      } else if (i > 0) {
        start = moment(end).add(after, "minutes");
        end = moment(start).add(Number(amount), "minutes");
      }
      seconds += (Number(after || 0) + Number(amount || 0)) * 60;
      if (diff >= seconds + 900) {
        return {
          ...defaultSegment,
          start,
          end,
          after,
          duration: renderDurationAsFormat(Number(amount) * 60, "HH:mm"),
          type: "break",
          payCode: calcGroupBreaks?.automatic?.payCode
        }
      }
    })?.filter((item) => item);
  }
}

export const areShiftsEqual = (shift1, shift2) => {
  return shift1.type === shift2.type &&
    shift1?.payCode?.code === shift2?.payCode?.code &&
    shift1?.location?.locationId === shift2?.location?.locationId &&
    shift1?.job?.jobId === shift2?.job?.jobId &&
    shift1?.job?.hourlyOrSalaried === shift2?.job?.hourlyOrSalaried &&
    shift1?.job?.hourlyRate === shift2?.job?.hourlyRate &&
    shift1?.level1?.id === shift2?.level1?.id &&
    shift1?.level2?.id === shift2?.level2?.id &&
    shift1?.level3?.id === shift2?.level3?.id &&
    shift1?.level4?.id === shift2?.level4?.id &&
    isContinueShift(shift1, shift2);

}

export const isContinueShift = (shift1, shift2) => {
  const start1 = moment(shift1.start).unix();
  const end1 = moment(shift1.end).unix();
  const start2 = moment(shift2.start).unix();
  const end2 = moment(shift2.end).unix();

  if (!checkIsSameGroup(shift1, shift2)) {
    return false;
  }

  return start1 === end2 ||
    end1 === start2 ||
    start1 === start2 ||
    end1 === end2 ||
    (start1 < start2 && end1 > end2) ||
    (start1 > start2 && end1 < end2) ||
    (start1 < start2 && end1 < end2 && end1 > start2) ||
    (start1 > start2 && end1 > end2 && start1 < end2);
}

export const checkDateInShift = (shift, date, startOrEnd) => {
  const start = moment(shift.start).unix();
  const end = moment(shift.end).unix();

  const dateUnix = moment(date).unix();
  
  if (startOrEnd === 'start') {
    return dateUnix < end 
  }

  return dateUnix > start;
} 

export const checkIsSameGroup = (shift1, shift2) => {
  if (typeof shift1.group === "number" && typeof shift2.group === "number") {
    if (shift1.group !== shift2.group) {
      return false;
    }
  }
  return true;
}