import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import ResourceSelect from "../../components/Inputs/ResourceSelect";
import Field from "../../components/Field";
import { useAssignGroups } from "./api";
import { generateErrorsConfigForForm } from "../../utils/helpers/errors";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel
} from "../../utils/helpers/settings";

function AssignGroupsToUsers({ isOpen, users, close, setData }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { submit, loading, error } = useAssignGroups();

    const ids = useMemo(() => {
        return Object?.keys(users || []);
    }, [users]);

    const onFinish = useCallback((values) => {
        const data = {
            groups: values?.groups?.map(({ id }) => id),
            users: ids
        };
        submit(data, (response) => {
            setData((prev) => {
                const userIds = response?.map(({ id }) => id);
                return prev?.map((user) => {
                    if (userIds.includes(user?.id)) {
                        return response.find(({ id }) => id === user?.id);
                    } else {
                        return user
                    }
                });
            });
            close();
        });
    }, [submit, close, setData, ids]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(["users"], error);
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <Modal
            isOpen={isOpen}
            title={
                <div className="d-flex align-items-center justify-content-between">
                    {t("assign-groups-to-selected-users")}

                    <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faXmark}
                        onClick={close}
                    />
                </div>
            }
            centered
        >
            <Form form={form} onFinish={onFinish}>
                <Field name="groups" className="py-3">
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath="/user-group?manageAbility=manual"
                        renderLabel={renderSettingsResourceLabel}
                        mode="multiple"
                        placeholder={t("groups")}
                        hasSearch
                        getSearchFilters={getSettingsResourceSearchFilters}
                    />
                </Field>

                <BaseField shouldUpdate>
                    {({ }, { }, { getFieldValue }) => {
                        const users = getFieldValue("groups");
                        return (
                            <div className="d-flex align-items-center justify-content-end my-4">
                                <Button
                                    htmlType="button"
                                    onClick={close}
                                    disabled={loading}
                                >
                                    {t("close")}
                                </Button>

                                <Button
                                    color="primary"
                                    htmlType="submit"
                                    disabled={!users || users?.length === 0}
                                    loading={loading}
                                >
                                    {t("save")}
                                </Button>
                            </div>
                        );
                    }}
                </BaseField>
            </Form>
        </Modal>
    );
}

export default AssignGroupsToUsers;
