import { useCallback, useMemo } from "react";
import Tree from "rc-tree";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../.././../../../../components/Inputs/Checkbox";
import {
	accessLevelsToTree,
	getSelectedKeysFromPermissions,
	setAccessLevels,
} from "./transformators";
import "rc-tree/assets/index.css";
import "./style.scss";
import classNames from "classnames";

const getSvgIcon = (iStyle = {}, style = {}) => {
	return (
		<i style={iStyle}>
			<FontAwesomeIcon
				width="1em"
				height="1em"
				style={{
					verticalAlign: "-.125em",
					...style,
					lineHeight: "1.3rem",
					fontSize: "0.9375rem",
				}}
				icon={faChevronRight}
			/>
		</i>
	);
};

const switcherIcon = (obj) => {
	if (obj.isLeaf) {
		return getSvgIcon(
			{ cursor: "pointer", backgroundColor: "white" },
			{ transform: "rotate(270deg)", display: "none" },
		);
	}

	return getSvgIcon(
		{
			cursor: "pointer",
			backgroundColor: "white",
			color: obj?.expanded ? "rgba(94, 114, 228, 0.95" : "#dee2e6",
		},
		{ transform: `rotate(${obj.expanded ? 90 : 0}deg)` },
	);
};

function AccessLevelTree({ value, onChange, disabled }) {
	const onCheck = useCallback(
		(checked) => {
			const newValue = setAccessLevels(
				value,
				Array.isArray(checked) ? checked : checked.checked,
			);
			onChange(newValue);
		},
		[value, onChange],
	);

	const checkedKeys = useMemo(
		() => getSelectedKeysFromPermissions(value || {}),
		[value],
	);

	const treeData = useMemo(() => accessLevelsToTree(value || {}), [value]);

	return (
		<div className="permission-tree">
			<Tree
				checkable
				selectable={false}
				checkedKeys={checkedKeys}
				onCheck={onCheck}
				treeData={treeData}
				disabled={disabled}
				switcherIcon={switcherIcon}
				icon={(props) => (
					props.halfChecked && props?.data?.children ? (
						<Checkbox
							id="all"
							labelClassName="role-checkbox"
							{...props}
						/>
					) : (
						<Checkbox {...props} />
					)
				)}
			/>
		</div>
	);
}

export default AccessLevelTree;
