import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useAddEmplyeeApi } from "./api";

function AddEmployee() {
	const navigate = useNavigate();

	const { step, setStep, data, setData, loading, error, submit } =
		useAddEmplyeeApi();

	const close = useCallback(() => navigate("/people"), [navigate]);

	return (
		<>
			{step === 0 && (
				<Step1
					data={data}
					setStep={setStep}
					setData={setData}
					error={error}
					step={step}
					close={close}
				/>
			)}

			{step === 1 && (
				<Step2
					setStep={setStep}
					data={data}
					setData={setData}
					error={error}
					step={step}
				/>
			)}

			{step === 2 && (
				<Step3
					loading={loading}
					submit={submit}
					setStep={setStep}
					data={data}
					setData={setData}
					error={error}
					step={step}
					close={close}
				/>
			)}
		</>
	);
}

export default AddEmployee;
