import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useAttestation =() => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const { authGet } = useApi();

	const fetch = useCallback(async (id, controller) => {
		setLoading(true);
		try {
			const response = await authGet(`/attestation/user/${id}`, {
				params: {
					pagination: false
				},
				signal: controller?.signal
			});

			if(response) {
				setData(response);
			}
		} catch(err) {
			getErrorMessage(err, t)
		} finally {
			setLoading(false);
		}
	}, [setLoading, authGet, setData, t]);

	return { fetch, loading, data };
}

export const useUpdateShift = () => {
	const { t } = useTranslation();
	const { authPut } = useApi();
	const [loading, setLoading] = useState(false);

	const update = useCallback(async (id, data, onSuccess) => {
		setLoading(true);
		try {
			const response = await authPut(`/shift/${id}`, {
				data
			});

			if(response){
				t("added-successfully");
				onSuccess?.(response);
			}
		} catch(err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [setLoading, authPut, t]);

	return { update, loading }
}