import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useVisible } from "./useVisible";
import Button from "../../components/Button";

export const useModal = ({
    head,
    icon,
    message,
    color,
    onConfirm,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { visible, selected, open, close } = useVisible();

    const confirm = useCallback(async () => {
        setLoading(true);
        await onConfirm?.(selected);
        setLoading(false);
        close();
    }, [selected, onConfirm, close])

    const cancel = useCallback(() => {
        onCancel?.();
        close();
    }, [close, onCancel])

    const content = useMemo(() => {
        if (typeof message === "function") {
            return message(selected);
        }
        return message;
    }, [selected, message]);

    const modal = useMemo(() => (
        <Modal backdrop={true} isOpen={visible} centered={true}>
            <ModalHeader className="d-flex align-items-center justify-content-center py-5 w-100">
                {icon}

                {head}
            </ModalHeader>

            <ModalBody className="d-flex justify-content-center py-0 mb-3 text-center">
                {content}
            </ModalBody>

            <ModalFooter className="d-flex justify-content-center mb-3">
                <Button color="light" onClick={cancel} disabled={loading}>
                    {t("cancel")}
                </Button>

                <Button color={color} onClick={confirm} loading={loading}>
                    {t("confirm")}
                </Button>
            </ModalFooter>
        </Modal>
    ), [t, visible, cancel, confirm, head, icon, content, color, loading])

    return { visible, open, close, modal };
};
