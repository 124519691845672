import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../../../utils/helpers/settings";

function ManageByLocation({ setLocations, isCustom, disabled }) {
	const { t } = useTranslation();

	const onLocationChange = useCallback(
		(value) => {
			setLocations(value);
		},
		[setLocations],
	);

	return (
		<Field
			name="locations"
			label={t("locations")}
			rules={
				!isCustom && [
					{
						required: true,
						message: t("required-locations"),
					},
				]
			}
		>
			<ResourceSelect
				labelPropName="description"
				resourcePath="/locations"
				onChange={onLocationChange}
				placeholder={t("locations")}
				renderLabel={renderSettingsResourceLabel}
				hasSearch
				getSearchFilters={getSettingsResourceSearchFilters}
				mode="multiple"
				disabled={disabled}
			/>
		</Field>
	);
}

export default ManageByLocation;