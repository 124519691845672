import { useCallback, useContext, useMemo } from "react";
import { ReportColumnsContext } from "./CreateReport/context";

export const useColumnsMap = () => {
	const { columns: allColumns } = useContext(ReportColumnsContext);

	const allColumnsMap = useMemo(() => {
		return allColumns.reduce((total, { children, key, icon }) => {
			children?.forEach((item) => {
				if (Array.isArray(item.children)) {
					item.children.forEach((subItem) => {
						total[subItem.key] = {
							...subItem,
							name: key,
							icon,
						};
					});

					if (item.type) {
						total[item.key] = {
							...item,
							name: key,
							icon,
							onlyFilter: true,
						};
					}
				} else {
					total[item.key] = {
						...item,
						name: key,
						icon,
					};
				}
			});
			return total;
		}, {});
	}, [allColumns]);

	const getColumns = useCallback(
		(cols) =>
			cols
				?.reduce((all, key) => {
					if (key) {
						const [namespace, group, subgroup] = key?.split(".");
						if (
							subgroup && allColumnsMap[`${namespace}.${group}`] &&
							!all.find((c) => c.key === `${namespace}.${group}`)
						) {
							all.push(allColumnsMap[`${namespace}.${group}`]);
						}
						all.push(allColumnsMap[key]);
					}

					return all;
				}, [])
				?.filter((item) => item),
		[allColumnsMap],
	);

	return { allColumnsMap, getColumns };
};
