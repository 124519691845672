import { useTranslation } from "react-i18next";
import { NavLink } from "reactstrap";
import { useResendVerificationLink } from "./api";

function Result({ email }) {
	const { t } = useTranslation();

	const { loading, submit } = useResendVerificationLink();

	return (
		<>
			<h4 className="text-center mb-5">{email}</h4>

			<div className="text-center">
				{t("didnt-receive-email")}?{" "}
				<NavLink
					onClick={() => submit(email)}
					className="text-primary font-weight-bold cursor-pointer w-100"
				>
					{t("resend-email")}
				</NavLink>
				{t("check-folder")}{" "}
				<a
					href="mailto:support@simplitime.com"
					target="_blank"
					rel="noreferrer"
					className="text-primary font-weight-bold"
				>
					{t("contact-support")}
				</a>
			</div>
		</>
	);
}

export default Result;
