import axios from "axios";
import moment from "moment-timezone";
import Form, { Field as BaseField } from "rc-field-form";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useSWRMutation from "swr/mutation";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import DateInput from "../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Modal from "../../../../../components/Modal";
import SimpleOldTable from "../../../../../components/SimpleOldTable";
import useApi from "../../../../../utils/api";
import { renderUserName } from "../../../../../utils/helpers/user";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { getUserSearchFilters } from "../../../../People/useColumns";
import { useColumns } from "../../../Team/Actions/CopyTimesheet/useColumns";
import { getEndDateFromStart, getStartDate } from "../../../components/Topbar/Filters/helpers";
import { FormGroup, Label } from "reactstrap";

function A({ refetch, filters, visible, selected, close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const userSelectRef = useRef();
    const { user, date } = useMemo(() => {
        return {
            user: selected?.user,
            date: selected?.date,
        };
    }, [selected]);

    const initialValues = useMemo(() => {
        return {
            // date: date ? moment(date).toDate() : moment().toDate(),
            dates: [
              getStartDate(moment(), "week"),
              getEndDateFromStart(moment(), "week"),
            ],
            users: [],
        };
    }, []);

    const { authGet, authPut } = useApi();
    const { data, isMutating, trigger } = useSWRMutation(
        "/time-sheet/weekly/copy",
        (url, { arg }) => {
            let params = {};
            if (date) {
                params = {
                    startDate: date,
                    endDate: date,
                };
            } else {
                params = filters;
            }
            const data = {
                startDate: moment(arg.dates[0]).format("YYYY-MM-DD"),
                originUser: user.id,
                destinationUsers: arg.users.map(({ id }) => id),
            };

            url =           axios.getUri({
              url,
              params,
          });

            return authPut(url, { data });
        },
        {
            throwOnError: false,
            onSuccess: async (res) => {
                toast.success(t("timesheets-copied-successfully"));
                userSelectRef.current?.refetch?.();
                try {
                    const { result } = await authGet("/time-sheet/weekly/team", {
                        params: {
                            ...filters,
                            userId: Object.keys(res),
                        },
                    });
                    const users = form.getFieldValue("users").map((user) => {
                        const updated = result.find((item) => item.id === user.id);
                        return updated || user;
                    });
                    form.setFieldValue("users", users);
                } catch (err) {}
                await refetch();
            },
            onError: (err) => {
                toast.error(t(err));
            },
        }
    );

    const columns = useColumns({
        response: data,
        loading: isMutating,
    });

    const usersEndpoint = axios.getUri({
        url: "/time-sheet/weekly/team",
        params: filters,
    });

    return (
        <Modal
            title={<h2 className="mb-0 pb-0">{t("copy-timesheet")}</h2>}
            isOpen={visible}
            close={close}
            toggle={close}
            size="lg"
            centered
        >
            <Form form={form} initialValues={initialValues} onFinish={trigger}>
                <div className="d-flex gap-x-4">

                    <FormGroup>
                        <Label>
                            {t("start-date")} <small className="text-red">*</small>
                        </Label>
                        <BaseField name="dates">
                            {({ value, onChange }) => {
                                return (
                                    <DateInput
                                        disabled={!!date}
                                        startDate={moment(value[0]).toDate()}
                                        endDate={moment(value[1]).toDate()}
                                        onChange={([value]) => {
                                          onChange([
                                            getStartDate(value, "week"),
                                            getEndDateFromStart(value, "week"),
                                          ])
                                        }}
                                        selectsRange
                                    />
                                );
                            }}
                        </BaseField>
                    </FormGroup>

                    <Field name="users" label={t("users")} className="w-100">
                        <ResourceSelect
                            ref={userSelectRef}
                            labelPropName="firstName"
                            renderLabel={renderUserName}
                            resourcePath={usersEndpoint}
                            mode="multiple"
                            placeholder={t("users")}
                            hasSearch
                            getSearchFilters={getUserSearchFilters}
                            hasCompany={false}
                        />
                    </Field>
                </div>

                <div
                    className="mb-3"
                    style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                    }}
                >
                    <BaseField name="users">
                        {({ value }) => {
                            return <SimpleOldTable data={value} columns={columns} footer={false} />;
                        }}
                    </BaseField>
                </div>

                <div className="d-flex gap-x-4 mb-3">
                    <Button outline color="danger" className="w-100" onClick={close} disabled={isMutating}>
                        {t("close")}
                    </Button>

                    <Button className="w-100" color="primary" loading={isMutating}>
                        {t("submit")}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export const useCopyTimeSheet = ({ refetch, filters }) => {
    const { visible, selected, open, close } = useVisible();

    const modal = visible ? (
        <A filters={filters} refetch={refetch} visible={visible} selected={selected} close={close} />
    ) : null;

    return [open, modal];
};
