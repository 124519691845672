import axios from "axios";
import { useCallback, useEffect, useMemo } from "react";
import useSWR from "swr";
import { formatTimeSheetParams } from "../../../api/helpers";

export const useFetchUserTimeSheets = ({
	params,
	user,
	setState,
}) => {
	const config = useMemo(() => {
		if (!params.from || !params.to) {
			return null;
		}
		const url = axios.getUri({
			url: `/time-sheet/user/${user}`,
			params: {
				pagination: false,
				...formatTimeSheetParams(params),
			},
		});
		return { url };
	}, [params, user]);

	const {data, isLoading, mutate} = useSWR(config, {
		fallbackData: { result: [] },
	});

	useEffect(() => {
		setState((prev) => ({
			...prev,
			data: data.result,
			loading: isLoading,
		}));
	}, [data, isLoading, setState]);

	return useCallback(() => {
		return mutate();
	}, [mutate]);
};
