import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useAssignUsers = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { authPut } = useApi();

    const submit = useCallback(async (data, onSuccess) => {
        setLoading(true);
        try {
            const response = await authPut("/users/assign-groups", {
                data
            });

            if (response) {
                onSuccess?.(response);
            }
        } catch (err) {
            setError(err);
            if (typeof err === "string") {
                toast.error(err)
            }
        } finally {
            setLoading(false);
        }
    }, [setLoading, setError, authPut]);

    return { submit, loading, error };
}

export const useGetAssignedUsers = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const { authGet, authPut } = useApi();

    const fetch = useCallback(async (params, controller) => {
        setLoading(true);
        try {
            const response = await authGet(`/users`, {
                params,
                signal: controller?.signal
            });

            if (response) {
                setData(response?.result);
                setTotalItems(response?.totalItems);
            }
        } catch (err) {
            getErrorMessage(err, t);
        } finally {
            setLoading(false);
        }
    }, [setLoading, setTotalItems, setData, authGet, t]);

    const unassign = useCallback(async (data, onSuccess) => {
        setLoading(true);
        try {
            const response = await authPut("/users/unassign-groups", {
                data,
            });

            if (response) {
                onSuccess?.(response)
            }
        } catch (err) {
            getErrorMessage(err, t)
        } finally {
            setLoading(false);
        }
    }, [authPut, setLoading, t]);

    return {
        unassign,
        fetch,
        setData,
        setTotalItems,
        data,
        totalItems,
        loading
    };
}