import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Select from "../Select";

function HourTypeSelect({ ...props }) {
	const { t } = useTranslation();

	return (
		<Select {...props} showSearch>
			<Option value="regular" key="regular">
				{t("regular")}
			</Option>

			<Option value="overtime" key="overtime">
				{t("overtime")}
			</Option>

			<Option value="double-time">{t("double-time")}</Option>

			<Option value="double-time-and-half" key="double-time-and-half">
				{t("double-time-and-half")}
			</Option>

			<Option value="override" key="override">
				{t("override")}
			</Option>

			<Option value="amount" key="amount">
				{t("amount")}
			</Option>

			<Option value="unit" key="unit">
				{t("unit")}
			</Option>

			<Option value="unpaid" key="unpaid">
				{t("unpaid")}
			</Option>

			<Option value="pto" key="pto">
				{t("pto")}
			</Option>

			<Option value="holiday" key="holiday">
				{t("holiday")}
			</Option>
		</Select>
	);
}

export default HourTypeSelect;
