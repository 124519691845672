import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi, { useNonAuthApi } from "../../utils/api";
import {
	getErrorMessage,
} from "../../utils/helpers/errors";
import { useQrCodeClockIn } from "./context";
import { useShiftAction } from "../TimeClock/ClockIn/store";

export const useValidateQrCodeLocation = () => {
	const { nonAuthGet } = useNonAuthApi();
	const { id } = useParams();

	const validateLocation = useCallback(
		async (options) => {
			return nonAuthGet(`qrCode/${id}/geofence`, options);
		},
		[id, nonAuthGet],
	);

	return validateLocation;
};

export const useQrCodeLogin = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { nonAuthPost } = useNonAuthApi();
	const { dispatch, qrCode } = useQrCodeClockIn();

	const login = useCallback(
		async (data, onSuccess, onFailure) => {
			setLoading(true);
			setError(null);
			try {
				const response = await nonAuthPost("/login", {
					data,
					headers: {
						Platform: "qr-code",
						PLATFORM_ID: qrCode?.id,
					},
				});
				if (response) {
					const { token, user } = response;
					dispatch({
						type: "login",
						payload: {
							user,
							token,
							rememberMe: data?.rememberMe,
							company: user?.companies[0],
						},
					});

					localStorage.setItem("QR_CODE_CLOCK_IN_TOKEN", token);

					onSuccess?.();
				}
			} catch (err) {
				onFailure?.();
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[dispatch, nonAuthPost, setError, setLoading, qrCode],
	);

	return { login, loading, error };
};

export const useQrCodeLogout = () => {
	const { dispatch } = useQrCodeClockIn();

	// eslint-disable-next-line
	const [_, { logout: clockLogout }] = useShiftAction();

	const logout = useCallback(async () => {
		try {
			localStorage.removeItem("QR_CODE_CLOCK_IN_TOKEN");
			localStorage.removeItem("QR_CODE_CLOCK_COMPANY");
			clockLogout();
			dispatch({ type: "log-out" });
			// window.location.reload();
		} catch (error) {
			getErrorMessage(error);
		}
	}, [dispatch, clockLogout]);

	return { logout };
};
