import moment from "moment-timezone";

async function EventResizeEnd(
	{ eventRecord, source }, 
	allocatedGroups,
	defaultPayCode,
	saveEventOnDb,
) {
	const date = moment(eventRecord?.startDate).format("YYYY-MM-DD");
	const timestamp = moment(eventRecord?.startDate).set('millisecond', 0).set('seconds', 0).unix();
	const endTimestamp = moment(eventRecord?.endDate).set('millisecond', 0).set('seconds', 0).unix();
	const duration = endTimestamp - timestamp;

	const levels = allocatedGroups?.reduce((total, group) => {
		const level = group?.level.replace("_", "");
		if (eventRecord && eventRecord[level]) {
			return { ...total, [level]: eventRecord[level] };
		} else {
			return total;
		}
	}, {});

	const defaultShift = {
		type: "regular",
		payCode: defaultPayCode
	};

	const baseShifts = eventRecord?.shifts;
	const job = eventRecord?.job;
	const shifts = [];

	for(let i = 0; i < baseShifts?.length; i++) {
		if (
			(baseShifts[i]?.end > duration)
			|| (baseShifts[i]?.end < duration && baseShifts?.length - 1 === i)
		) {
			if (baseShifts[i]?.type !== "break") {
				const _shift = {
					...baseShifts[i],
					end: duration,
					duration: duration - baseShifts[i]?.start,
				};
				_shift.total = ((_shift.duration / 3600) * (_shift?.job?.hourlyRate || job?.hourlyRate || 0));
				shifts.push(_shift);
				break;
			} else {
				const _shift = {
					...defaultShift,
					start: baseShifts[i]?.end,
					end: duration,
					duration: duration - baseShifts[i]?.end
				};
				_shift.total = ((_shift.duration / 3600) * (_shift?.job?.hourlyRate || job?.hourlyRate || 0));
				
				shifts.push(
					{ ...baseShifts[i] },
					{..._shift},
				);
				break;
			}
		} else {
			shifts.push(baseShifts[i]);
		}
	}

	if (eventRecord) {
		await eventRecord.setAsync({
			date,
			startDate: eventRecord?.startDate,
			endDate: eventRecord?.endDate,
			job: eventRecord?.job,
			location: eventRecord?.location,
			resourceId: eventRecord?.resourceId,
			eventType: eventRecord?.eventType,
			duration,
			timestamp,
			endTimestamp,
			rate: eventRecord?.job?.hourlyRate || 0,
			total: ((duration / 3600) * (eventRecord?.job?.hourlyRate || 0)),
			shifts,
			...levels
		})

		saveEventOnDb(eventRecord, source);
	}

	return true;
}

export default EventResizeEnd;
