import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import Map from "./Map";
import AppLoader from "../../../components/Loaders/AppLoader";
import { useQrCodeClockIn } from "../context";

function ErrorPage() {
	const { t } = useTranslation();

	const { id } = useParams();

	const {
		qrCode,
		validatingLocationError,
		isGeolocationEnabled,
		position,
		area,
	} = useQrCodeClockIn();

	const hasPosition = !!position;

	const content = useMemo(() => {
		if (
			area
			&& area[0].length === 1
			&& area[0][0] === null
		) {
			return (
				<div className="bg-white text-center py-3 px-1 font-weight-bolder rounded w-100">
					<div className="d-flex flex-column mb-3">
						<div className="mb-3">
							{t("invalid-qr-code")}
						</div>
						<div className="text-red font-weight-bolder text-sm">
							*{" "}
							{t("ask-manager-set-qr-code-area")}
						</div>
					</div>
				</div>
			);
		}
		if (!isGeolocationEnabled) {
			return (
				<div className="text-start px-5 font-weight-600 text-sm bg-white mx-1 rounded p-5">
					<div className="font-weight-700 text-dark">{t("ios")}</div>
					<ol>
						<li>{t("go-to-settings")}</li>
						<li>{t("go-to-privacy")}</li>
						<li>{t("go-to-location-services")}</li>
						<li>
							{t("toggle-slider-right")}
						</li>
					</ol>
					<div className="font-weight-700 text-dark">
						{t("android")}
					</div>
					<ol>
						<li>{t("go-to-settings")}</li>
						<li>{t("go-to-location")}</li>
						<li>
							{t("toggle-slider-right")}
						</li>
					</ol>
					<div className="font-weight-700 mb-1 text-dark">
						{t("have-ios-cant-use-location")}
						:
					</div>
					<div>
						<ol>
							<li>{t("go-to-settings")}</li>
							<li>{t("go-to-privacy")}</li>
							<li>{t("go-to-location-services")}</li>
							<li>
								{t("scroll-down-find-browser")}
							</li>
						</ol>
					</div>
					<div className="text-red font-weight-bolder text-sm">
						*{" "}
						{t("dont-have-current-location")}
					</div>
				</div>
			);
		} else if (!qrCode) {
			return (
				<div className="bg-white text-center py-3 px-1 font-weight-bolder rounded w-100">
					<>
						{validatingLocationError ? (
							<div className="mb-3">{validatingLocationError}</div>
						) : (
							<div className="d-flex flex-column mb-3">
								<div className="mb-3">
									{t("invalid-location")}
								</div>
								<div className="text-red font-weight-bolder text-sm">
									*{" "}
									{t("dont-have-current-location")}
								</div>
							</div>
						)}

						<Map />
					</>
				</div>
			);
		} else if (isGeolocationEnabled && !hasPosition) {
			return (
				<div className="bg-white text-center py-3 px-1 font-weight-bolder rounded w-100">
					<div className="h-25 my-2">
						<AppLoader />
					</div>

					<div className="font-weight-bolder text-lg my-3">
						{t("wait-to-take-location")}...
					</div>

					<div className="text-red font-weight-bolder text-sm mb-3 px-2">
						*{" "}
						{t("dont-have-current-location")}
					</div>
				</div>
			);
		}
	}, [
		isGeolocationEnabled,
		qrCode,
		validatingLocationError,
		hasPosition,
		area,
		t,
	]);

	if (isGeolocationEnabled && qrCode && hasPosition) {
		return <Navigate to={`/qr-code-clock-in/${id}/login`} />;
	}

	return (
		<div
			className="position-relative h-100 align-items-center d-flex flex-column justify-content-center"
			style={{
				backgroundImage: `url(/assets/images/bg_2.webp)`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center center",
			}}
		>
			{content}
		</div>
	);
}

export default ErrorPage;
