import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../components/Field";
import DomainInput from "../../../components/Inputs/DomainInput";
import Checkbox from "../../../components/Inputs/Checkbox";
import Text from "../../../components/Inputs/Text";
import Button from "../../../components/Button";
import Password from "../../../components/Inputs/Password";
import { useSignUp } from "./api";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function SignUpForm({ setIsFinished, setEmail }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { signUp, loading, error } = useSignUp();

	const onFinish = useCallback(
		(values) => {
			signUp(values, () => {
				setIsFinished(true);
				setEmail(values.email);
			});
		},
		[signUp, setIsFinished, setEmail],
	);

	useEffect(() => {
		const formErrors = generateErrorsConfigForForm(
			["firstName", "lastName", "email", "password", "uniqueKey"],
			error,
		);
		form.setFields(formErrors);
	}, [form, error]);

	return (
		<Form autocomplete="off" form={form} onFinish={onFinish}>
			<Field
				name="firstName"
				rules={[
					{
						required: true,
						message: t("required-firstname"),
					},
				]}
			>
				<Text placeholder={t("first-name")} type="text" />
			</Field>

			<Field
				name="lastName"
				rules={[
					{ required: true, message: t("required-lastname") },
				]}
			>
				<Text placeholder={t("last-name")} type="text" />
			</Field>

			<Field
				name="email"
				rules={[{ required: true, message: t("required-email") }]}
			>
				<Text placeholder={t("email")} type="email" />
			</Field>

			<Field
				name="password"
				rules={[{ required: true, message: t("required-password") }]}
			>
				<Password placeholder={t("password")} />
			</Field>

			<Field
				name="uniqueKey"
				rules={[
					{ required: true, message: t("required-domain") },
					{ pattern: /^[a-zA-Z0-9-]+$/, message: t("domain-pattern") }
				]}
			>
				<DomainInput />
			</Field>

			<Field name="terms" valuePropName="checked">
				<Checkbox id="terms">
					{t("agree")}{" "}
					<span className="font-weight-bolder ml-2 mb-1">
						{t("term-and-conditions")}
					</span>
				</Checkbox>
			</Field>

			<Button
				className="btn btn-lg w-100"
				color="primary"
				type="submit"
				loading={loading}
			>
				{t("sign-up")}
			</Button>
		</Form>
	);
}

export default SignUpForm;
