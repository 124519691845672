import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../utils/api";
import { getErrorMessage } from "../../utils/helpers/errors";
import { useAuthenticateUser } from "../Authentication/api";

export const usePeopleApi = () => {
  const { t } = useTranslation();
  const { authGet, authDelete, authPost, call } = useApi();

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const fetch = useCallback(
    async (params, controller) => {
      setLoading(true);
      try {
        const response = await authGet("/users", {
          params,
          signal: controller?.signal,
        });
        setData(response.result);
        setTotalItems(response.totalItems);
      } catch (err) {
        if (typeof err === "string") {
          toast.error(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, authGet]
  );

  const addEmployee = useCallback(
    (data) => {
      return authPost("/users", {
        data,
      });
    },
    [authPost]
  );

  const removeCall = useCallback(
    (id) => authDelete(`/users/${id}`),
    [authDelete]
  );

  const batchRemove = useCallback(
    async (ids) => {
      const loading = toast(
        t("deleting-resources", {
          resourceLength: ids?.length,
        }),
        {
          autoClose: false,
        }
      );
      try {
        await Promise.all(ids?.map((id) => removeCall(id)));

        setData((prev) =>
          prev.filter((employee) => !ids?.includes(employee.id))
        );

        toast.dismiss(loading);
        toast.success(t("resources-deleted-successfully"));
      } catch (err) {
        toast.dismiss(loading);
        getErrorMessage(err, t);
      }
    },
    [removeCall, setData, t]
  );

  const peopleExport = useCallback(
    async (url, params) => {
      setLoadingExport(true);
      try {
        const response = await call(
          {
            url,
            params,
            headers: {
              "Content-Type": "Content-Disposition",
            },
            responseType: "arraybuffer",
          },
          true
        );

        if (response) {
          const header = response.headers["content-disposition"];
          const parts = header.split(";");
          const filename = parts[1].split("=")[1];
          const downloadLink = document.createElement("a");
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const docUrl = URL.createObjectURL(blob);
          downloadLink.href = docUrl;
          downloadLink.download = filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          toast.success(t("exported-successfully"));
          return { status: response.status };
        }
      } catch (err) {
        getErrorMessage(err, t);
      } finally {
        setLoadingExport(false);
      }
    },
    [call, setLoadingExport, t]
  );

  return {
    loading,
    loadingExport,
    data,
    setData,
    totalItems,
    setTotalItems,
    fetch,
    addEmployee,
    batchRemove,
    peopleExport,
  };
};

export const useFetchRoles = () => {
  const [roles, setRoles] = useState([]);
  const { authGet } = useApi();

  const fetch = useCallback(async () => {
    try {
      const response = await authGet("/user-roles?pagination=false");

      if (response) {
        setRoles(response.result);
      }
    } catch (err) {}
  }, [authGet, setRoles]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { roles };
};

export const useVerifyUser = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { authPost } = useApi();

  const verify = useCallback(
    async (id, onSuccess) => {
      setLoading(true);
      try {
        const response = await authPost(`/users/verify/${id}`);
        if (response) {
          setData(response);
          onSuccess?.();
        }
      } catch (err) {
        getErrorMessage(err, t);
      }
    },
    [authPost, setLoading, setData, t]
  );

  return { loading, data, verify, setLoading, setData };
};

export const useImpersonate = () => {
  const { t } = useTranslation();
  const [userBeingImpersonated, setUserBeingImpersonated] = useState(null);
  const [error, setError] = useState(null);
  const { authPost } = useApi();
  const authenticateUser = useAuthenticateUser();
  // const { firebaseToken } = useNotifications();

  const companyId = localStorage.getItem("COMPANY");

  const userCompany = useCallback(
    (companies) => {
      const isMasterCompany = companies?.find(
        ({ isMasterCompany }) => isMasterCompany
      );

      let company = isMasterCompany
        ? isMasterCompany
        : companies?.length === 1
        ? companies[0]
        : undefined;

      if (companyId) {
        company = companies?.find(({ id }) => id === companyId);

        if (company === undefined) {
          company = isMasterCompany
            ? isMasterCompany
            : companies?.length === 1
            ? companies[0]
            : undefined;
        }
      }

      return company?.id;
    },
    [companyId]
  );

  const login = useCallback(
    async (id, onSuccess) => {
      setUserBeingImpersonated(id);
      setError(null);
      try {
        const response = await authPost(`/users/impersonate/${id}`, {
          headers: {
            Platform: "web",
            // ...(firebaseToken ? { FbToken: firebaseToken } : {}),
          },
        });
        if (response) {
          const { token, user, parent } = response;
          localStorage.setItem("BEFORE_IMPERSONATE_COMPANY", companyId);
          sessionStorage.setItem(
            "BEFORE_IMPERSONATE_FILTERS",
            sessionStorage.getItem("filters")
          );
          sessionStorage.removeItem("filters");

          localStorage.setItem("IMPERSONATE", parent);
          localStorage.setItem("COMPANY", userCompany(user?.companies));

          authenticateUser(user, token, true);
          onSuccess?.();
        }
      } catch (err) {
        getErrorMessage(err, t);
      } finally {
        setUserBeingImpersonated(null);
      }
    },
    [
      authPost,
      authenticateUser,
      userCompany,
      setError,
      t,
      companyId,
      setUserBeingImpersonated,
      /**firebaseToken */
    ]
  );

  const logout = useCallback(
    async (onSuccess) => {
      const loading = toast.loading(t("logging-out"));
      try {
        const response = await authPost("/users/impersonate/exit");
        toast.dismiss(loading, { autoClose: 3000 });
        if (response) {
          localStorage.setItem(
            "COMPANY",
            localStorage.getItem("BEFORE_IMPERSONATE_COMPANY")
          );
          sessionStorage.setItem(
            "filters",
            sessionStorage.getItem("BEFORE_IMPERSONATE_FILTERS")
          );
          sessionStorage.removeItem("BEFORE_IMPERSONATE_FILTERS");
          const { token, user } = response;
          authenticateUser(user, token, true);
          localStorage.removeItem("IMPERSONATE");
          localStorage.removeItem("BEFORE_IMPERSONATE_COMPANY");
          onSuccess?.();
        }
      } catch (err) {
        getErrorMessage(err, t);
      }
    },
    [authPost, authenticateUser, t]
  );

  return { logout, login, error, userBeingImpersonated };
};

export const useAssignGroups = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { authPut } = useApi();

  const submit = useCallback(
    async (data, onSuccess) => {
      setLoading(true);
      try {
        const response = await authPut("/users/assign-groups", {
          data,
        });

        if (response) {
          onSuccess?.(response);
        }
      } catch (err) {
        setError(err);
        if (typeof err === "string") {
          toast.error(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, authPut]
  );

  return { submit, loading, error };
};
