import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import Option from "../../../Settings/PayPolicies/CalculationGroup/Form/Content/OtherOptions/Option";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import Text from "../../../../components/Inputs/Text";
import {
	renderSettingsResourceLabel,
	renderOrgLevelResourceLabel,
	getSettingsResourceSearchFilters,
	renderCompanyResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import { enumerateDaysBetweenDates } from "../../../TimeSheet/ManagerView/Table/useColumns";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../utils/helpers/currencyFormatter";

export const renderLabel = (type, item) => {
	switch (type) {
		case "group": {
			return renderOrgLevelResourceLabel(item);
		}
		case "company":
			return renderCompanyResourceLabel(item);
		default:
			return renderSettingsResourceLabel(item);
	}
};

export const useColumns = ({
	columns,
	columnLabels = {},
	payCodeColumns,
	hasDailyHours,
	openDailyHoursTooltip,
	from,
	to
}) => {
	if (!columnLabels) {
		columnLabels = {}
	}
	const days = useMemo(
		() => moment(to).diff(moment(from), "days"),
		[to, from],
	);

	const toDate = useMemo(
		() => moment(moment.parseZone(from)).add(days, "days"),
		[days, from],
	);

	const getFilterInput = useCallback((column) => {
		const mode = column?.multiselect ? "multiple" : "";

		switch (column?.type) {
			case "resource-select":
				const endpoint =
					column?.endpoint === "/companies"
						? "/companies?pagination=false"
						: column?.endpoint;
				return (
					<ResourceSelect
						labelPropName="description"
						resourcePath={endpoint}
						mode={mode}
						renderLabel={(item) =>
							renderLabel(column.renderLabel, item)
						}
						getSearchFilters={
							column.renderLabel === "group"
								? getSettingsOrgResourceSearchFilters
								: getSettingsResourceSearchFilters
						}
						hasSearch
					/>
				);
			case "select":
				return (
					<Select mode={mode}>
						{column?.options?.map((option) => (
							<Option value={option.value}>{option.label}</Option>
						))}
					</Select>
				);
			case "text":
			default:
				return <Text />;
		}
	}, []);

	const cols = useMemo(() => {
		const dates = enumerateDaysBetweenDates(
			moment.parseZone(from),
			moment.parseZone(toDate),
		);

		const displayColumns = [
			...columns
				?.filter(
					({ onlyFilter }) =>
						!(onlyFilter),
				)
				.reduce((allCols, column) => {
					if (column.isForExtraColumns) {
						const allExtraCols = payCodeColumns?.filter(i => i.groupBy === column?.key?.replace(`${column?.name}.`, ''));
						if (allExtraCols?.length) {
							allExtraCols?.forEach((item) => {
								allCols.push({
									Header: item.label,
									accessor: item.accessor,
									disableSortBy: true,
									userProps: (item.isTotal || item.isAmount)
										? {
											style: { backgroundColor: item.isTotal ? "#dadbdc" : "#dfdfdf" },
										}
										: {},
									Cell: ({ value }) => {
										return Number(value || 0) ? (
											item.isAmount ? currencyFormatter(Number(value || 0), 2) :
												renderDurationAsFormat(Number(value || 0), "HH:mm")
										) : ''
									}
								})
							});
						}
					} else {
						allCols.push({
							Header: columnLabels[column.key] || column.label,
							accessor: column.key,
							disableFilters: column?.type ? false : true,
							filter: column.type,
							disableSortBy: true,
							Filter: getFilterInput(column),
							Cell: ({ value }) => {
								if (column.formatType === 'money') {
									return Number(value || 0) ? currencyFormatter(Number(value || 0), 2) : '';
								}
								return value
							}
						});
					}
					return allCols;
				}, []),
		];

		if (hasDailyHours) {
			dates.map((date) =>
				displayColumns.push({
					Header: (
						<div className="d-flex flex-column">
							<span className="font-weight-bolder">
								{moment(date).format("ddd")}
							</span>
							<span>{moment(date).format("MM/DD")}</span>
						</div>
					),
					columnToggleLabel: moment(date).format("ddd, MMM DD, YYYY"),
					accessor: date,
					disableSortBy: true,
					Cell: ({ value, row: { original } }) => {
						return <span
							className="d-flex flex-column mr-3 mb-1"
							id={`daily-hours-${original?.user?.id}-${date}`}
							onMouseEnter={() =>
								openDailyHoursTooltip({
									data: value,
									user: original?.user,
									date
								})
							}
						>
							{value?.workedHours ? renderDurationAsFormat(value?.workedHours, "HH:mm") : ''}
						</span>
					}


				}))
		}

		if (payCodeColumns?.filter(i => !i.groupBy).length > 0) {
			payCodeColumns.filter(i => !i.groupBy).map((item) =>
				displayColumns.push({
					Header: item.label,
					accessor: item.accessor,
					disableSortBy: true,
					userProps: (item.isTotal || item.isAmount)
						? {
							style: { backgroundColor: item.isTotal ? "#dadbdc" : "#dddbdc" },
						}
						: {},
					Cell: ({ value }) => {
						return Number(value || 0) ? (
							item.isAmount ? currencyFormatter(Number(value || 0), 2) :
								renderDurationAsFormat(Number(value || 0), "HH:mm")
						) : ''
					}
				}),
			);
		}

		return displayColumns;
	}, [columns, payCodeColumns, getFilterInput]);

	const extraFilterCols = useMemo(
		() => [
			...columns
				?.filter((col) => col.onlyFilter)
				.map((column) => ({
					Header: columnLabels[column.key] || column.label,
					accessor: column.key,
					id: column.key,
					disableFilters: column?.type ? false : true,
					filter: column.type,
					canFilter: true,
					disableSortBy: true,
					Filter: getFilterInput(column),
				})),
		],
		[columns, getFilterInput],
	);

	return { cols, extraFilterCols };
};
