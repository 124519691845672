import { useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "./Map";
import Marker from "./Marker";
import { mapStyleConfig } from '../../../../../../config/mapStyleConfig';

function Map({ 
	level,
	marker, 
	center, 
	zoom, 
	area, 
	setArea, 
	setCenter, 
	setZoom,
	setLevel
}) {
	const mapOptions = useMemo(
		() => ({
			center,
			zoom,
			mapTypeControl: false,
			streetViewControl: false,
			...mapStyleConfig,
		}),
		[zoom, center],
	);

	return (
		<Wrapper
			apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
		>
			<GoogleMap
				level={level}
				options={mapOptions}
				setCenter={setCenter}
				area={area}
				setArea={setArea}
				setZoom={setZoom}
				setLevel={setLevel}
			>
				<Marker
					position={marker}
					icon={{
						path: window.google.maps.SymbolPath.CIRCLE,
						scale: 7,
						strokeColor: "red",
					}}
				/>
			</GoogleMap>
		</Wrapper>
	);
}

export default Map;
