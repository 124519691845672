import { useEffect, useState, useCallback } from "react";
import DateRangeFilters from "../../../components/DateRangeFilters";
import NTable from "../../../components/NTable";
import Actions from "../../../components/NTable/Actions";
import { useColumns } from "./useColumns";
import { useOriginalClocks } from "./api";
import { useDateFilters } from "../../../components/DateRangeFilters/useDateFilters";
import { formatParams } from "../../../utils/api/format";
import moment from "moment-timezone";
import { useNewTable } from "../../../components/NTable/hook";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useVisibleColumnKeys } from "../../Settings/helpers/useVisibleColumnKeys";

function OriginalClocksTable({
	openDetailsModal,
	setExportData,
	openProfileDrawer,
}) {
	const [hasChangedDateFilter, setHasChangedDateFilter] = useState(false);
	const { from, to } = useDateFilters();

	const columns = useColumns({
		openDetails: openDetailsModal,
		openProfileDrawer,
	});

	const { data, totalItems, loading, fetch } = useOriginalClocks();

	const table = useNewTable({
		columns,
		data,
		loading,
		totalItems,
		initialState: useInitialState({
			columnVisibility: {
				clockInIp: false,
				clockOutIp: false,
			},
		}),
		enableRowSelection: false,
		getRowProps: useCallback((row) => {
			const { out } = row.original;
			if (!out) {
				return {
					className: "missed-punch",
				};
			}
		}, []),
		sessionFiltersKey: "originalClocks",
	});

	const {
		pagination: { pageIndex, pageSize },
		columnFilters: filters,
		sorting: sortBy,
	} = table.getState();

	const visibleColumnKeys = useVisibleColumnKeys(table);

	useEffect(() => {
		const controller = new AbortController();

		const params = {
			...formatParams({
				pageIndex,
				pageSize,
				filters,
				sortBy,
			}),
			from: moment(from).format("YYYY-MM-DD"),
			to: moment(to).format("YYYY-MM-DD"),
		};

		fetch(params, controller);

		return () => controller.abort();
	}, [fetch, pageIndex, pageSize, filters, sortBy, from, to]);

	useEffect(() => {
		setExportData({
			from: moment(from).format("YYYY-MM-DD"),
			to: moment(to).format("YYYY-MM-DD"),
			columns: visibleColumnKeys,
			...formatParams({ filters }),
		});
	}, [setExportData, from, to, visibleColumnKeys, filters]);

	return (
		<>
			<Actions
				columns={table.getAllColumns()}
				filters={table.getState().columnFilters}
				setFilters={table.setColumnFilters}
			>
				<DateRangeFilters
					setHasChangedDateFilter={setHasChangedDateFilter}
				/>
			</Actions>

			<div style={{ height: "calc(100% - 98px)" }}>
				<NTable
					table={table}
					hasChangedDateFilter={hasChangedDateFilter}
					setHasChangedDateFilter={setHasChangedDateFilter}
				/>
			</div>
		</>
	);
}

export default OriginalClocksTable;
