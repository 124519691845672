import { useCallback } from "react";

export const useGetUniqueElements = () => {
	const getUniqueElemets = useCallback((arr1, arr2) => {
		let result = [];

		for (let i = 0; i < arr2.length; i++) {
			if (arr1.indexOf(arr2[i]) === -1) {
				result.push(arr2[i]);
			}
		}

		return result;
	}, []);

	return getUniqueElemets;
};
