import moment from "moment-timezone";

export const formatDateRange = (from, to) => {
	if (!from || !to) {
		return {};
	}
	return {
		startDate: moment(from).format("YYYY-MM-DD"),
		endDate: moment(to).format("YYYY-MM-DD"),
	};
};
