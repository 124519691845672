import classNames from "classnames";
import { useEffect, useMemo, useRef } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
	suffix: "",
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ",",
	allowDecimal: true,
	decimalSymbol: ".",
	decimalLimit: 2,
	integerLimit: 7,
	allowNegative: false,
	allowLeadingZeroes: false,
	prefix: "",
};

function debounceChange(func, timeout = 1000) {
	if (!func) {
		return;
	}
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

function NumberInput({ onChange, value, className, ...props }) {
	const currencyMask = useMemo(
		() =>
			createNumberMask({
				...defaultMaskOptions,
				...props,
			}),
		[props],
	);

	const ref = useRef();

	useEffect(() => {
		if (
			ref &&
			ref.current &&
			ref.current.textMaskInputElement &&
			typeof ref.current.textMaskInputElement.update === 'function' &&
			document.activeElement !== ref.current.inputElement
		) {
			ref.current.textMaskInputElement.update(value);
		}
	}, [ref, value])

	const processChange = debounceChange(val => onChange(val));

	return (
		<MaskedInput
			ref={ref}
			mask={currencyMask}
			onChange={processChange}
			{...props}
			className={classNames(className, "form-control")}
		/>
	);
}

export default NumberInput;
