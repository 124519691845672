import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import Button from "../../../components/Button";
import Layout from "../Layout";
import Card from "../Layout/Card";
import { useValidate } from "./api";
import { generateErrorsConfigForForm, getErrorMessage } from "../../../utils/helpers/errors";
import { useUser } from "../../../utils/hooks/user";
import { useProfileApi } from "../../../utils/api/profile";
import Redirect from "../../../Routing/Redirect";

function GoogleAuth() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const user = useUser();
    const { generateToken } = useProfileApi();
    const [seconds, setSeconds] = useState(60);

    const otpValidate = localStorage.getItem("OTP_VALIDATE");

    const { submit, loading, error } = useValidate();

    const onFinish = useCallback((values) => {
        submit(values);
    }, [submit]);

    const resendEmail = useCallback(async () => {
        try {
            const response = await generateToken();
            if (response) {
                setSeconds(60);
            }
        } catch (err) {
            getErrorMessage(err, t)
        }
    }, [generateToken, setSeconds, t]);

    useEffect(() => {
        if (user?.twoFA?.type === "email" && seconds > 0) {
            const interval = setInterval(
                () => {
                    setSeconds(prev => prev - 1)
                }, 1000);
            return () => clearInterval(interval);;
        }
    }, [user?.twoFA?.type, seconds, setSeconds]);

    const percent = useMemo(() => {
        return (seconds / 60) * 100;
    }, [seconds]);

    const cardBody = useMemo(() => {
        return (
            <>
                <Form form={form} onFinish={onFinish}>
                    <Field
                        name="token"
                        rules={[
                            {
                                required: true,
                                message: t("required-auth-code")
                            }
                        ]}
                    >
                        <Text placeholder={t("auth-code")} />
                    </Field>

                    <Button
                        color="primary"
                        className="w-100"
                        htmlType="submit"
                        loading={loading}
                    >
                        {t("authenticate")}
                    </Button>
                </Form>

                {user?.twoFA?.type === "email" && (
                    <div className="my-5 w-100 text-center">
                        <span className="text-sm my-2">
                            {t("dont-receive-email")}
                        </span>

                        <span
                            className="mx-2 text-primary text-sm font-weight-bold"
                            style={{
                                width: "30px",
                                height: "30px",
                                border: `1px dashed #010a63`,
                                borderRadius: "50%",
                                padding: "10px 12px",
                                background: `conic-gradient(#e6e6e6 ${percent}%,0,white )`
                            }}
                        >
                            {seconds}
                        </span>

                        <span className="text-sm mr-2">{t("seconds-then")}</span>

                        <span
                            className="cursor-pointer border-bottom border-primary px-1 text-primary font-weight-bold text-sm"
                            onClick={resendEmail}
                        >
                            {t("resend-e-mail")}
                        </span>
                    </div>
                )}
            </>
        );
    }, [loading, form, percent, onFinish, resendEmail, t]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["token"],
            error,
        );
        form.setFields(fieldErrors);
    }, [error, form]);


    if (otpValidate || user?.twoFA?.status !== "active") {
        return <Redirect to="/" />
    }

    return (
        <Layout
            image="/assets/images/landscape-1.jpg"
            text="No matter what people tell you, words and ideas can change the world."
            autor="Robin Williams"
        >
            <Card
                cardTitle="two-factor-auth"
                cardText="open-verification-app"
                cardBody={cardBody}
                link={false}
            />
        </Layout>
    );
}

export default GoogleAuth;
