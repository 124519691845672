import {createColumnHelper} from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const columnHelper = createColumnHelper();

export const useColumns = ({
	edit,
	remove,
	clone,
	isOpenFromTimesheet = false,
}) => {
	const { t } = useTranslation();

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("data", {
				header: t("plan"),
				cell: (info) => {
                    const { data } = info.row.original;
                    if (!data) {
                        return null;
                    }
                    return `${data.code} - ${data.description}`;
                },
			}),
			columnHelper.accessor("earned", {
				header: t("earned"),
			}),
			columnHelper.accessor("taken", {
				header: t("Taken"),
			}),
			columnHelper.accessor("available", {
				header: t("available"),
			}),
			columnHelper.accessor("totalPlan", {
				header: t("total-plan"),
			}),
		];
		if (!isOpenFromTimesheet) {
            const actionColumn = columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableColumnFilter: false,
                header: (
                    <div className="text-right">
                        {t("actions")}
                    </div>
                ),
				cell: (info) => {
                    const { id } = info.row.original;
                    return (
                        <div className="actions text-primary text-right">
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => edit(id)}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fas fa-pen" />
                            </span>

                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => remove(id)}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fas fa-trash" />
                            </span>

                            <span
                                className="btn-inner--icon icon-sm cursor-pointer"
                                onClick={() => clone(id)}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="ni ni-single-copy-04" />
                            </span>
                        </div>
                    );
                },
            });
			columns.push(actionColumn);
		}
		return columns;
	}, [remove, edit, clone, isOpenFromTimesheet, t]);
};
