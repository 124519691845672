import GeneralSettings from "./GeneralSettings";
import OrganisationLevel from "./OrganisationLevel";
import Job from "./Job";
import Location from "./Location";
import PayGroup from "./PayGroup";
import CalculationGroup from "./CalculationGroup";

export default [
	{
		name: "general-settings",
		description: "general-settings-description",
		content: GeneralSettings,
	},
	{
		name: "organisation-level",
		description: "organisation-level-description",
		content: OrganisationLevel,
	},
	{
		name: "location",
		description: "location-description",
		content: Location,
	},
	{
		name: "job",
		description: "job-description",
		content: Job,
	},
	{
		name: "pay-group",
		description: "pay-group-description",
		content: PayGroup,
	},
	{
		name: "calculation-group",
		description: "calculation-group-description",
		content: CalculationGroup,
	},
];
