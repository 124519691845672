import { useMemo } from "react";
import { useTranslation } from "react-i18next"
import Select from "../../../../components/Inputs/Select";

export const useStatusFilter = () => {
	const { t } = useTranslation();

	return useMemo(() => ({
		id: "status",
		Header: t("status"),
		filter: "select",
		canFilter: true,
		Filter: (
			<Select>
				<Select.Option value="publish">
					{t("publish")}
				</Select.Option>
				<Select.Option value="unpublish">
					{t("unpublish")}
				</Select.Option>
				<Select.Option value="lock">
					{t("locked")}
				</Select.Option>
				<Select.Option value="unlock">
					{t("unlocked")}
				</Select.Option>
			</Select>
		)
	}), [t])
}