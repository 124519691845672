import ManagerDashboard from "./Manager";
import EmployeeDashboard from "./Employee";
import { Access } from "../../utils/hooks/access";

function Home() {
	return (
		<Access accessPath="dashboard.canView">
			<Access
				accessPath="canManageUsers"
				fallback={<EmployeeDashboard />}
			>
				<ManagerDashboard />
			</Access>
		</Access>
	);
}

export default Home;
