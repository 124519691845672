import { useState, useCallback } from "react";
import { noop } from "lodash";
import useApi, { api } from "..";
import { useUser } from "../../hooks/user";
import { useAuthentication } from "../../../features/Authentication/context";
import { setCompanyStorageTokens } from "../../../features/Authentication/CompanySelect/Form";
import { adaptMomentToCompany } from "../../helpers/adaptMomentToCompany";

export const useValidateDomain = () => {
	return useCallback(async (subDommain, config) => {
		const res = await api.get(`/domain-validation/${subDommain}`, config);
		return res.data;
	}, []);
};

export const useCompanyApi = () => {
	const { authGet, authPut } = useApi();

	const getCompany = useCallback(
		(id, config) => authGet(`/companies/${id}`, config),
		[authGet],
	);

	const updateCompany = useCallback(
		(id, data) => authPut(`/companies/${id}`, { data }),
		[authPut],
	);

	return { getCompany, updateCompany };
};

export const useUpdateCompany = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { updateCompany } = useCompanyApi();
	const { dispatch, user } = useAuthentication();

	const submit = useCallback(
		async (id, data, onSuccess = noop, onFailure = noop) => {
			try {
				setError(null);
				setLoading(true);
				const response = await updateCompany(id, data);
				if (response) {
					adaptMomentToCompany(response, user);
					dispatch({
						type: "set-company",
						payload: {
							company: response,
						},
					});
					onSuccess();
					setCompanyStorageTokens(id);
				}
			} catch (err) {
				setError(err);
				onFailure();
			} finally {
				setLoading(false);
			}
		},
		[updateCompany, dispatch, setError, setLoading, user],
	);

	return { loading, error, submit };
};

export const useGetCompany = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const user = useUser();

	const { getCompany } = useCompanyApi();

	const fetch = useCallback(
		async (id, config) => {
			try {
				setError(null);
				setLoading(true);
				const response = await getCompany(id, config);
				if (response) {
					adaptMomentToCompany(response, user);
					setData(response);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, setData, getCompany, user],
	);

	return { loading, error, data, fetch };
};
