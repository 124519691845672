import moment from "moment-timezone";

export const prepareEventForDb = ({ id, company, ...event }) => {
	const timezone = event?.shifts?.[0]?.location?.timezoneValue || company?.timezone?.value;
	let data = {
		id,
		date: event?.date,
		startDate: timezone 
			? moment.parseZone(event?.startDate).tz(timezone, true).toISOString(true)
			: moment.parseZone(event?.startDate).toISOString(true),
		endDate: timezone 
			? moment.parseZone(event?.endDate).tz(timezone, true).toISOString(true)
			: moment.parseZone(event?.endDate).toISOString(true),
		duration: event?.duration,
		durationUnit: event?.durationUnit,
		total: event?.total,
		//   location: event?.location,
		eventType: event?.eventType,
		//   job: event?.job,
		shifts: event?.shifts || [],
		user: event?.user,
		//   level1: event?.level1,
		//   level2: event?.level2,
		//   level3: event?.level3,
		//   level4: event?.level4,
		endTimestamp: event.endTimestamp,
		timestamp: event.timestamp,
		status: event.status,
		note: event?.note
	};
	if (data.shifts.length > 0) {
		const endShift = data.shifts[data.shifts.length - 1];
		data.endDate = moment.parseZone(data.startDate).add(endShift.end, 'seconds').toISOString(true);
	}
	data.timestamp = moment.parseZone(data.startDate).unix();
	data.endTimestamp = moment.parseZone(data.endDate).unix();
	return data;
  };