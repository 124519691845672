import moment from "moment-timezone";
import { weekdays } from "../../components/Inputs/WeekdaySelect";
import { supportedLngs } from "../i18n";

export const adaptMomentToCompany = (company, user) => {
	let language = localStorage.getItem("i18nextLng");
	const startDay = user?.calculationGroup?.startDay
		? user?.calculationGroup?.startDay
		: company?.settings?.startDay;
	
	if (!supportedLngs.includes(language)) {
		language = 'en';
	}

	if (startDay && weekdays.includes(startDay?.toLowerCase())) {
		const dow = weekdays.indexOf(startDay?.toLowerCase());

		moment.updateLocale(language || "en", { week: { dow } });
	} else {
		moment.updateLocale(language || "en", { week: { dow: 0 } });
	}
};


