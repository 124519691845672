import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useCompanyCurrency } from "../../../../utils/hooks/company";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../utils/helpers/currencyFormatter";
import {
	DateHelper,
	DomSync,
	Rectangle,
	DomHelper
} from "@bryntum/schedulerpro";

const colors = {
	"regular": "#525f7f",
	"transfer": "#5428e0",
	"break": "#fe9700",
	"overtime": "#f8219c",
}

export const useEventDragFeature = ({ mode }) => {
	const { t } = useTranslation();
	const currency = useCompanyCurrency();
	const getIntervals = useCallback((segments) => {
		return segments?.map((segment) => {
			const duration = typeof segment?.end === "number"
				? segment?.end - segment?.start
				: moment(segment?.end).diff(moment(segment?.start), "seconds");

			return {
				type: segment?.type,
				duration
			}
		})
	}, []);


	const getIntervalWidth = useCallback(
		(intervals, shifts, duration) => {
			const totalDuration = intervals.reduce(
				(total, interval) => interval.duration + total, 0
			);

			const lastShift = shifts?.length === intervals?.length;

			if (lastShift) {
				return duration / totalDuration;
			}
			return duration / totalDuration / 2;
		},
		[],
	);

	return useMemo(
		() => ({
			constrainDragToResource: false,
			// validatorFn: ({ startDate, eventRecord }) => {
			// 	let valid = true,
			// 		message = "You can drop your event here";
			// 	const sDate = eventRecord?.originalData?.startDate;
			// 	const eDate = eventRecord?.originalData?.endDate;

			// 	if (!moment(startDate).isSame(moment(sDate), "date")) {
			// 		valid = false;
			// 		message = `This task is only for date ${moment(
			// 			sDate,
			// 		).format("YYYY-MM-DD hh:mm A")} to ${moment(eDate).format(
			// 			"YYYY-MM-DD hh:mm A",
			// 		)}`;
			// 	}
			// 	return {
			// 		valid,
			// 		message,
			// 	};
			// },
			tooltipTemplate: ({ eventRecord, dragData }) => {
				const intervals = getIntervals(eventRecord?.shifts);

				return (
					`<div style="height:100%; width:100%; margin:0px; padding: 15px 20px;">
						<div 
							style="
								height: 22px; 
								border-radius: 5px; 
								display: flex; 
								flex-direction: 
								row; margin-bottom: 10px;
								border: 1px solid #fff;
							"
						>
                            ${intervals.map(({ type, duration }, i) => {
						return `
                                    <span
                                        key="${type}-${duration}-${i}"
                                        style="
                                            width: ${getIntervalWidth(intervals, eventRecord?.shifts, duration) * 100}%;
                                            background-color: ${colors[type]};
                                            height: 20px;
                                            border-radius: ${i === intervals?.length - 1
								? "0px 5px 5px 0px"
								: i === 0 ? "5px 0px 0px 5px"
									: "0px"};
                                        "
                                    ></span>
                            `}).join("")}
                        </div>

						<table border="1">
							<thead>
								<th style="padding: 4px 7px;">${t("start")}</th>
								<th style="padding: 4px 7px;">${t("end")}</th>
								<th style="padding: 4px 7px;">${t("type")}</th>
								<th style="padding: 4px 7px;">${t("duration")}</th>
								<th style="padding: 4px 7px;">${t("total")}</th>
							</thead>

							<tbody>
								${eventRecord?.shifts?.map((shift) => {
										const startDate = mode === "date" ? dragData?.startDate : eventRecord?.startDate;
										const start = moment(startDate).add(shift.start, "seconds");
										const end = moment(startDate).add(shift.end, "seconds");

										return (
											`
											<tr style="margin-bottom: 5px;">
												<td style="padding: 4px 7px;">${moment(start).format("hh:mm A")}</td>
												<td style="padding: 4px 7px;">${moment(end).format("hh:mm A")}</td>
												<td style="padding: 4px 7px;">${shift.payCode?.code}</td>
												<td style="padding: 4px 7px;">${renderDurationAsFormat(shift.duration, "HH:mm")}</td>
												<td style="padding: 4px 7px;">
													${currencyFormatter(shift?.total || 0, 2, eventRecord?.job?.currency || currency)}
												</td>
											</tr>
										`
										)
									}
									).join("")}
							</tbody>
						</table>
                    </div>`
				)
			},
			constrainDragToTimeline: false,
			// eventDragCreate: false,
			// eventDragSelect: true,
			// multiDrag: true,
			// unifiedDrag: false,

			async updateAssignments(fromScheduler, toScheduler, context, copy) {
				var _a2;
				const me = this, { copyMode } = me, isCrossScheduler = fromScheduler !== toScheduler, { isVertical } = toScheduler, {
					assignmentStore: fromAssignmentStore,
					eventStore: fromEventStore
				} = fromScheduler, {
					assignmentStore: toAssignmentStore,
					eventStore: toEventStore
				} = toScheduler, fromResourceStore = fromScheduler.isVertical ? fromScheduler.resourceStore : fromScheduler.store, {
					eventRecords,
					assignmentRecords,
					timeDiff,
					initialAssignmentsState,
					newResource: toResource
					} = context,
					{ unifiedDrag } = me,
					useSingleAssignment = toEventStore.usesSingleAssignment || toEventStore.usesSingleAssignment !== false && fromEventStore.usesSingleAssignment,
					effectiveCopyMode = copyMode === "event" ? "event" : copyMode === "assignment" ? "assignment" : useSingleAssignment ? "event" : "assignment", event1Date = me.adjustStartDate(assignmentRecords[0].event.startDate, timeDiff), eventsToAdd = [], eventsToRemove = [], assignmentsToAdd = [], assignmentsToRemove = [], eventsToCheck = [], eventsToBatch = /* @__PURE__ */ new Set();
				fromScheduler.suspendRefresh();
				toScheduler.suspendRefresh();
				let updated = false, updatedEvent = false, indexDiff = me.getIndexDiff(context);
				if (isVertical) {
					eventRecords.forEach((draggedEvent, i) => {
						const eventBar = context.eventBarEls[i];
						delete draggedEvent.instanceMeta(fromScheduler).hasTemporaryDragElement;
						if (eventBar.dataset.transient) {
							eventBar.remove();
						}
					});
				}
				const eventBarEls = context.eventBarEls.slice(), addedEvents = [], copiedAssignmentsMap = {};
				for (let i = 0; i < assignmentRecords.length; i++) {
					const originalAssignment = assignmentRecords[i];
					let draggedEvent = originalAssignment.event, draggedAssignment;
					if (copy) {
						draggedAssignment = originalAssignment.copy();
						copiedAssignmentsMap[originalAssignment.id] = draggedAssignment;
					} else {
						draggedAssignment = originalAssignment;
					}
					if (!draggedAssignment.isOccurrenceAssignment && (!fromAssignmentStore.includes(originalAssignment) || !fromEventStore.includes(draggedEvent))) {
						eventBarEls[i].remove();
						eventBarEls.splice(i, 1);
						assignmentRecords.splice(i, 1);
						i--;
						continue;
					}
					const initialState = initialAssignmentsState[i], originalEventRecord = draggedEvent, originalStartDate = initialState.startDate, originalResourceRecord = initialState.resource, newStartDate = this.constrainDragToTimeSlot ? originalStartDate : unifiedDrag ? event1Date : me.adjustStartDate(originalStartDate, timeDiff);
					if (fromAssignmentStore !== toAssignmentStore) {
						const keepEvent = originalEventRecord.assignments.length > 1 || copy;
						let newAssignment;
						if (copy) {
							newAssignment = draggedAssignment;
						} else {
							newAssignment = draggedAssignment.copy();
							copiedAssignmentsMap[draggedAssignment.id] = newAssignment;
						}
						if (newAssignment.event && !useSingleAssignment) {
							newAssignment.event = newAssignment.event.id;
							newAssignment.resource = newAssignment.resource.id;
						}
						if (!copy) {
							assignmentsToRemove.push(draggedAssignment);
						}
						if (!keepEvent) {
							eventsToRemove.push(originalEventRecord);
						}
						if (copy && (copyMode === "event" || copyMode === "auto" && toEventStore.usesSingleAssignment) || !toEventStore.getById(originalEventRecord.id)) {
							draggedEvent = toEventStore.createRecord({
								...originalEventRecord.data,
								children: (_a2 = originalEventRecord.children) == null ? void 0 : _a2.map((child) => child.copy()),
								// If we're copying the event (not making new assignment to existing), we need to generate
								// phantom id to link event to the assignment record
								id: copy && (copyMode === "event" || copyMode === "auto") ? void 0 : originalEventRecord.id,
								// Engine gets mad if not nulled
								calendar: null
							});
							newAssignment.set({
								eventId: draggedEvent.id,
								event: draggedEvent
							});
							eventsToAdd.push(draggedEvent);
						}
						if (!useSingleAssignment) {
							assignmentsToAdd.push(newAssignment);
						}
						draggedAssignment = newAssignment;
					}
					let newResource = toResource, reassignedFrom = null;
					if (!unifiedDrag) {
						newResource = me.getNewResource(context, originalResourceRecord, indexDiff) || toResource;
					}
					const isCrossResource = draggedAssignment.resourceId !== newResource.$original.id;
					if (isCrossResource) {
						reassignedFrom = fromResourceStore.getById(draggedAssignment.resourceId);
						if (copy && fromAssignmentStore === toAssignmentStore) {
							draggedAssignment.setData({
								resource: null,
								resourceId: null
							});
							draggedAssignment.resource = newResource;
							draggedAssignment.event = toEventStore.getById(draggedAssignment.eventId);
							const shouldCopyEvent = copyMode === "event" || fromEventStore.usesSingleAssignment && copyMode === "auto";
							if (shouldCopyEvent) {
								draggedEvent = draggedEvent.copy();
								draggedEvent.meta.endDateCached = me.adjustStartDate(draggedEvent.endDate, timeDiff);
								draggedEvent.endDate = null;
								draggedAssignment.event = draggedEvent;
								if (toEventStore.usesSingleAssignment) {
									draggedEvent.resource = newResource;
									draggedEvent.resourceId = newResource.id;
								}
							}
							if (!toAssignmentStore.find((a) => a.eventId === draggedAssignment.eventId && a.resourceId === draggedAssignment.resourceId) && !assignmentsToAdd.find((r) => r.eventId === draggedAssignment.eventId && r.resourceId === draggedAssignment.resourceId)) {
								shouldCopyEvent && eventsToAdd.push(draggedEvent);
								assignmentsToAdd.push(draggedAssignment);
							}
						} else {
							draggedAssignment.resource = newResource;
						}
						draggedEvent.isEvent && eventsToBatch.add(draggedEvent);
						updated = true;
						if (draggedEvent.isOccurrence) {
							draggedEvent.set("newResource", newResource);
						}
						if (isCrossScheduler && useSingleAssignment) {
							draggedEvent.resourceId = newResource.id;
						}
					} else {
						if (copy && (copyMode === "event" || copyMode === "auto" && fromEventStore.usesSingleAssignment) && !eventsToAdd.includes(draggedEvent)) {
							draggedEvent = draggedEvent.copy();
							draggedEvent.meta.endDateCached = me.adjustStartDate(draggedEvent.endDate, timeDiff);
							draggedEvent.endDate = null;
							eventsToAdd.push(draggedEvent);
							draggedAssignment.event = draggedEvent;
							if (toEventStore.usesSingleAssignment) {
								draggedEvent.set({
									resource: newResource,
									resourceId: newResource.id
								});
							}
							assignmentsToAdd.push(draggedAssignment);
						}
					}
					if (!eventsToCheck.find((ev) => ev.draggedEvent === draggedEvent) && !DateHelper.isEqual(draggedEvent.startDate, newStartDate)) {
						while (!draggedEvent.isOccurrence && draggedEvent.isBatchUpdating) {
							draggedEvent.endBatch(true);
						}
						const shouldKeepStartDate = fromScheduler.viewPreset.data.id !== "hourAndDay";//copy && !isCrossScheduler && !useSingleAssignment && effectiveCopyMode === "assignment" && isCrossResource;
						if (!shouldKeepStartDate) {
							draggedEvent.startDate = newStartDate;
							eventsToCheck.push({ draggedEvent, originalStartDate });
						}
						draggedEvent.isEvent && eventsToBatch.add(draggedEvent);
						updatedEvent = true;
					}
					toScheduler.processEventDrop({
						eventRecord: draggedEvent,
						resourceRecord: newResource,
						element: i === 0 ? context.context.element : context.context.relatedElements[i - 1],
						context,
						toScheduler,
						reassignedFrom,
						eventsToAdd,
						addedEvents,
						draggedAssignment
					});
					toScheduler.trigger("processEventDrop", {
						originalAssignment,
						draggedAssignment,
						context,
						copyMode,
						isCopy: copy
					});
				}
				fromAssignmentStore.remove(assignmentsToRemove);
				fromEventStore.remove(eventsToRemove);
				toAssignmentStore.add(assignmentsToAdd);
				if (copy && fromAssignmentStore === toAssignmentStore) {
					const { syncIdMap } = fromScheduler.foregroundCanvas;
					Object.entries(copiedAssignmentsMap).forEach(([originalId, cloneRecord]) => {
						const element = syncIdMap[originalId];
						delete syncIdMap[originalId];
						syncIdMap[cloneRecord.id] = element;
					});
				}
				eventsToAdd.length && addedEvents.push(...toEventStore.add(eventsToAdd));
				addedEvents == null ? void 0 : addedEvents.forEach((added) => eventsToBatch.add(added));
				if (assignmentsToRemove.length || eventsToRemove.length || assignmentsToAdd.length || eventsToAdd.length) {
					updated = true;
				}
				if (updated || updatedEvent) {
					useSingleAssignment && eventsToBatch.forEach((eventRecord) => eventRecord.beginBatch());
					await Promise.all([
						toScheduler.project !== fromScheduler.project ? toScheduler.project.commitAsync() : null,
						fromScheduler.project.commitAsync()
					]);
					useSingleAssignment && eventsToBatch.forEach((eventRecord) => eventRecord.endBatch(false, true));
				}
				if (!updated) {
					updated = eventsToCheck.some(
						({ draggedEvent, originalStartDate }) => !DateHelper.isEqual(draggedEvent.startDate, originalStartDate)
					);
				}
				if (!me.constrainDragToTimeline && updated) {
					for (let i = 0; i < assignmentRecords.length; i++) {
						const assignmentRecord = copiedAssignmentsMap[assignmentRecords[i].id] || assignmentRecords[i], originalDraggedEvent = assignmentRecord.event, draggedEvent = (addedEvents == null ? void 0 : addedEvents.find((r) => r.id === originalDraggedEvent.id)) || originalDraggedEvent, eventBar = context.eventBarEls[i], element = i === 0 ? context.context.element : context.context.relatedElements[i - 1], inTimeAxis = toScheduler.isInTimeAxis(draggedEvent);
						delete draggedEvent.meta.endDateCached;
						if (!copy) {
							DomSync.removeChild(eventBar.parentElement, eventBar);
						}
						if (draggedEvent.resource && (isVertical || toScheduler.rowManager.getRowFor(draggedEvent.resource)) && inTimeAxis) {
							if (!draggedEvent.parent || draggedEvent.parent.isRoot) {
								const elRect = Rectangle.from(element, toScheduler.foregroundCanvas, true);
								DomHelper.setTopLeft(element, elRect.y, elRect.x);
								DomSync.addChild(toScheduler.foregroundCanvas, element, draggedEvent.assignments[0].id);
								isCrossScheduler && toScheduler.processCrossSchedulerEventDrop({
									eventRecord: draggedEvent,
									toScheduler
								});
							}
							element.classList.remove("b-sch-event-hover", "b-active", "b-drag-proxy", "b-dragging");
							element.retainElement = false;
						}
					}
				}
				toScheduler.resumeRefresh(false);
				fromScheduler.resumeRefresh(false);
				if (assignmentRecords.length > 0) {
					if (!updated) {
						context.valid = false;
					} else {
						eventBarEls.forEach((el) => delete el.lastDomConfig);
						toScheduler.refreshWithTransition();
						if (isCrossScheduler) {
							fromScheduler.refreshWithTransition();
							toScheduler.selectedEvents = addedEvents;
						}
					}
				}
			}
		}),
		[t, mode, currency, getIntervalWidth, getIntervals],
	);
};
