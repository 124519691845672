import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useNotes = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const { authPost, authPut } = useApi();

	const addNote = useCallback(
		async (data, onSuccess) => {
			setLoading(true);
			try {
				const response = await authPost("/note", {
					data,
				});

				if (response) {
					onSuccess?.(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[authPost, setLoading, t],
	);

	const updateNote = useCallback(
		async (date, note, onSuccess) => {
			setLoading(true);
			try {
				const response = await authPut(`/note`, {
					data: {
						value: note,
					},
					params: {
						date,
					},
				});

				if (response) {
					onSuccess?.(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[authPut, setLoading, t],
	);

	return { addNote, updateNote, loading };
};
