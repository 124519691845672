import { useState, useCallback, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Team from "./Team";
import Map from "./Map";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import { useMyTeam } from "./api";
import { useVisible } from "../../../utils/hooks/useVisible";
import "./style.scss";

const perPage = 10;

function MyTeam() {
	const [status, setStatus] = useState("clocked-in");
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState();
	const { visible, open, close, selected } = useVisible();

	const { data, loading, fetch, totalItems, setData } = useMyTeam();

	const onSearchChange = useCallback(
		(value) => {
			setSearch(value);

			if (page !== 1) {
				setPage(0);
			} else {
				fetch({ page, perPage, status, search: value });
			}
		},

		[status, setPage, page, setSearch, fetch],
	);

	useEffect(() => {
		const controller = new AbortController();

		fetch({ status, page: page + 1, perPage, search }, controller);

		return () => controller.abort();

	}, [fetch, status, page, search]);

	return (
		<>
			<Row className="timeclock-team h-100">
				<Col sm={12} md={6} className="h-100">
					<Team
						status={status}
						setStatus={setStatus}
						setPage={setPage}
						onSearchChange={onSearchChange}
						data={data}
						loading={loading}
						totalItems={totalItems}
						page={page}
						openProfileDrawer={open}
						setData={setData}
					/>
				</Col>

				<Col sm={12} md={6} className="h-100">
					<Map data={data} />
				</Col>
			</Row>

			<ProfileDrawer visible={visible} close={close} user={selected} />
		</>
	);
}

export default MyTeam;
