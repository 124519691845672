import { useMemo } from "react";
import { useActionsAccess } from "../../helpers/useActionsAccess";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useIsMasterCompany } from "../../../../utils/hooks/company";

export const useColumns = ({ edit, remove, clone }) => {
	const isMasterCompany = useIsMasterCompany();

	const hasActionAccess = useActionsAccess("settings.payPolicies.earningGroup");
	const { access } = useModuleAccess("settings.payPolicies.earningGroup");

	const { code, description, status, actions } = useCommonColumns({
		edit: access.canEdit && edit,
		remove: access.canDelete && remove,
		clone: access.canCreate && clone,
	});

	return useMemo(() => {
		const columns = [code, description, status];
		if (isMasterCompany && hasActionAccess) {
			columns.push(actions);
		}
		return columns;
	}, [isMasterCompany, hasActionAccess, code, description, status, actions]);
};
