import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { DateFiltersProvider } from "../hooks/useDateFilters";
import Content from "./Content";

function MySchedulerPage() {
	return (
		<FiltersProvider
			sessionKey="MY_SCHEDULER_FILTERS"
			initialValue={
				[
					{
						method: "exact",
						name: "hourType",
						value: [],
					}
				]
			}
		>
			<DateFiltersProvider>
				<Content />
			</DateFiltersProvider>
		</FiltersProvider>
	)
}

export default MySchedulerPage;
