import { useMemo } from "react";
import { useIsMasterCompany } from "../../../utils/hooks/company";

export const useDrawerProps = ({ mode, help, view, create, edit, clone }) => {
	const isMaster = useIsMasterCompany();

	const open = useMemo(() => {
		return mode !== "view";
	}, [mode]);

	const title = useMemo(() => {
		switch (mode) {
			case "help":
				return help;
			case "clone":
				return clone;
			case "edit":
				return isMaster ? edit : view;
			case "create":
				return create;
			default:
				return null;
		}
	}, [mode, isMaster, view, create, edit, clone, help]);

	return { title, open };
};
