import { useCallback, useEffect, useMemo } from "react";

export const useBindPayGroupWithTable = ({ filters, setFilters, mode, payGroup }) => {
    const tablePayGroupCodes = useMemo(() => {
        const pFilter = filters
            .find((filter) => filter.name === "payGroup");
        return pFilter && Array.isArray(pFilter.value) ? pFilter.value?.map(({ id }) => id) : [];
    }, [filters]);

    const setTablePayGroup = useCallback((payGroup) => {
        const hasExisting = !!filters
            .find((filter) => filter.name === "payGroup")
        let newValue;
        if (payGroup) {
            if (hasExisting) {
                newValue = filters.map((filter) => {
                    if (filter.name !== "payGroup") {
                        return filter;
                    }
                    return {
                        ...filter,
                        value: [{ id: payGroup }],
                    };
                });
            } else {
                const existingIndex = filters.findIndex(
                    (filter) => filter.value === ""
                );
                if (existingIndex === -1) {
                    newValue = [
                        {
                            method: "exact",
                            name: "payGroup",
                            value: [{ id: payGroup }],
                        },
                        ...filters,
                    ];
                } else {
                    newValue = [...filters];
                    newValue.splice(existingIndex, 1, {
                        method: "exact",
                        name: "payGroup",
                        value: [{ id: payGroup }],
                    });
                }
            }
        } else {
            newValue = filters.filter((filter) => {
                return filter.name !== "payGroup";
            });
        }
        setFilters(newValue);
    }, [filters, setFilters]);

    // on mode change to pay group, reset table pay group filter if it is set
    // be careful to change the table filter only when it is nesecary
    useEffect(() => {
        if (mode === "pay-group" && payGroup) {
            if (tablePayGroupCodes.length === 1) {
                if (tablePayGroupCodes[0] !== payGroup.id) {
                    setTablePayGroup(payGroup?.id);
                }
            } else {
                setTablePayGroup(payGroup?.id);
            }
        }
    }, [mode, tablePayGroupCodes, payGroup, setTablePayGroup]);
}
