import { Field } from "rc-field-form";
import { Row, Col, FormGroup } from "reactstrap";
import moment from "moment-timezone";
import CustomTimeInput from "../../../../components/Inputs/CustomTimeInput";
import DateInput from "../../../../components/Inputs/DateInput";
import { useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { useTranslation } from "react-i18next";
import { firstToUpper } from "../../../../utils/helpers/string";
import { combineDateAndTime } from "../../../../utils/helpers/date";

function TimeInputGroup({ name, label, form, disabled }) {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();

	return (
		<FormGroup className="mb-0">
			{label && <label className="form-control-label">{label}</label>}

			<Row>
				<Col md={6}>
					<Field
						name={
							Array.isArray(name)
								? [...name, "date"]
								: [name, "date"]
						}
						dependencies={
							name === "end" ? [["start", "date"]] : undefined
						}
						rules={[
							{
								required: true,
								message: t("required-timeinput-date", {
									name: firstToUpper(name)
								}),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									const startDate = getFieldValue([
										"start",
										"date",
									]);

									if (
										name === "end" &&
										value !== null &&
										startDate
									) {
										if (
											moment(value).isBefore(
												startDate,
												"date",
											)
										) {
											return Promise.reject(
												new Error(
													t(
														"end-date-bigger-than-start-date",
													),
												),
											);
										}
									}

									return Promise.resolve(true);
								},
							}),
						]}
					>
						<DateInput
							onChange={() => {
								form.setFields([
									{
										name: ["start", "date"],
										errors: [],
									},
									{
										name: ["start", "time"],
										errors: [],
									},
								]);
							}}
							placeholderText={t("date")}
							disabled={disabled}
						/>
					</Field>
				</Col>

				<Col md={6}>
					<Field
						name={
							Array.isArray(name)
								? [...name, "time"]
								: [name, "time"]
						}
						dependencies={
							name === "end"
								? [
									["start", "time"],
									["start", "date"],
									["end", "date"],
								]
								: undefined
						}
						rules={[
							{
								required: true,
								message: t(
									"required-timeinput-time", {
									name: firstToUpper(name)
								}
								),
							},
							{
								message: t("invalid-time-format"),
								transform: (value) => {
									if (typeof value === "object") {
										return value.format(timeFormat);
									} else {
										return value;
									}
								},
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									const startTime = getFieldValue([
										"start",
										"time",
									]);

									const startDate = getFieldValue([
										"start",
										"date",
									]);

									const endDate = getFieldValue([
										"end",
										"date",
									]);

									if (
										name === "end" &&
										value !== null &&
										startTime &&
										startDate &&
										endDate
									) {
										if (
											moment(startDate).isSame(
												moment(endDate),
												"date",
											) &&
											moment(
												combineDateAndTime(
													endDate,
													value,
												),
											).isSameOrBefore(
												moment(
													combineDateAndTime(
														startDate,
														startTime,
													),
												),
												"seconds",
											)
										) {
											return Promise.reject(
												new Error(
													t(
														"end-time-bigger-than-start-time",
													),
												),
											);
										}
									}

									return Promise.resolve(true);
								},
							}),
						]}
					>
						<CustomTimeInput className="form-control" disabled={disabled}/>
					</Field>
				</Col>
			</Row>
		</FormGroup>
	);
}

export default TimeInputGroup;
