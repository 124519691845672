import { useCallback } from "react";
import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Select from "../../../../../components/Inputs/Select";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsOrgResourceSearchFilters,
    renderCompanyResourceLabel,
    renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";

const manageAbilityOptions = ["by-calculation-groups", "by-pay-groups", "by-location", "by-company"];

function ManageAbilitySelect({ form, disabled }) {
    const { t } = useTranslation();

    const onManageAbilityChange = useCallback(() => {
        form.setFieldsValue({
            calculationGroups: [],
            locations: [],
            payGroups: [],
            companies: [],
        });
    }, [form]);

    return (
        <div>
            <Field
                name="manageAbility"
                label={t("manage-ability")}
                rules={[{ required: true, message: t("required-manage-ability") }]}
            >
                <Select
                    placeholder={t("manage-ability")}
                    disabled={disabled}
                    onChange={onManageAbilityChange}
                >
                    {manageAbilityOptions.map((value) => {
                        return (
                            <Select.Option key={value} value={value}>
                                {t(value)}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Field>
            <BaseField shouldUpdate>
                {({}, {}, { getFieldValue }) => {
                    const manageAbility = getFieldValue("manageAbility");

                    const calculationGroups = manageAbility === "by-calculation-groups";
                    const location = manageAbility === "by-location";
                    const payGroup = manageAbility === "by-pay-groups";
                    const companies = manageAbility === "by-company";

                    return (
                        <>
                            {calculationGroups && (
                                <Field
                                    name="calculationGroups"
                                    label={t("calculation-groups")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-calculation-group"),
                                        },
                                    ]}
                                >
                                    <ResourceSelect
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                                        mode="multiple"
                                        placeholder={t("calculation-groups")}
                                        resourcePath="/calculation-group"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}
                            {location && (
                                <Field
                                    name="locations"
                                    label={t("locations")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-location"),
                                        },
                                    ]}
                                >
                                    <ResourceSelect
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                                        mode="multiple"
                                        placeholder={t("location")}
                                        resourcePath="/locations"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}
                            {payGroup && (
                                <Field
                                    name="payGroups"
                                    label={t("pay-groups")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-pay-group"),
                                        },
                                    ]}
                                >
                                    <ResourceSelect
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                                        mode="multiple"
                                        resourcePath="/pay-group"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}
                            {companies && (
                                <Field
                                    name="companies"
                                    label={t("companies")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-companies"),
                                        },
                                    ]}
                                >
                                    <ResourceSelect
                                        renderLabel={renderCompanyResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                                        mode="multiple"
                                        resourcePath="/companies"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}
                        </>
                    );
                }}
            </BaseField>
        </div>
    );
}

export default ManageAbilitySelect;
