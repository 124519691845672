import moment from "moment-timezone";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import SchedulerColors from "../other/colors";
import { DateHelper } from "@bryntum/schedulerpro";
import { getSegmentsForDisplay } from "../EventEditDrawer/Drawer/Form/TimeInputs/Progress";

const renderDuration = (duration, shifts) => {
   let unpaidDuration = 0;
   for(let i = 0; i < shifts?.length; i++) {
        if(shifts[i]?.payCode?.hourType === "unpaid") {
            unpaidDuration += shifts[i]?.duration;
        }
   }
    
    let minutes = Math.floor(duration - unpaidDuration) / 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);

    return `${hours} h ${Math.floor(minutes)} m`;
};

const durationAsHoursAndMinsString = (endDate, startDate) => {
    let duration = moment.duration(moment(endDate).diff(moment(startDate)));
    return `${duration.hours() > 0 ? duration.hours() + "h" : ""} ${duration.minutes() > 0 ? duration.minutes() + "min" : ""
        }`;
}
const getShiftClass = (shift, eventRecord) => {
    switch (shift.type) {
        case "break":
        case "uat":
            return shift.type;
        case "overtime":
            return eventRecord?.status === "approved" ? "overtime" : "red-lines";
        default:
            return "";
        
    }
}
export function eventRenderer({ eventRecord, renderData }) {
    renderData.eventStyle = eventRecord?.locked ? "hollow" : "colored";
    const eventType = eventRecord.get('eventType');
    if (eventRecord?.status === "approved") {
        renderData.eventColor = SchedulerColors[eventType?.code] || SchedulerColors.approved;
    } else if (eventRecord?.status === "submitted") {
        renderData.eventColor = SchedulerColors.submitted;
    } else {
        renderData.eventColor = SchedulerColors.pending;
    }
    const eventStart = eventRecord.get('startDate');
    const level = eventRecord?.level1 || eventRecord?.level2 || eventRecord?.level3 || eventRecord?.level4;
    const duration = renderDuration(eventRecord.duration, eventRecord.shifts);
    const job = eventRecord.job
        ? (typeof eventRecord.job === "object" && eventRecord.job)
        : null;
    const _payCode = eventRecord.payCode
        ? (typeof eventRecord.payCode === "object" && eventRecord.payCode)
        : null;
    const startDate = moment(eventRecord.startDate).format("h:mm A").replace("M", "");
    const endDate = moment(eventRecord.endDate).format("h:mm A").replace("M", "");
    const levelLabel = level?.glSegment || level?.description || level?.code;

    const dateToPx = date => this.timeAxisViewModel.getDistanceForDuration(date - eventStart);

    const role = levelLabel || renderSettingsResourceLabel(job);
    const mode = this.viewPreset.id;
    renderData.cls.eventWithAgenda = 1;
    if (mode === 'hourAndDay' && eventRecord?.data?.shifts?.length > 0) {
        const eventStartUnix = moment(eventStart).unix()
        const segments = getSegmentsForDisplay((eventRecord?.data?.shifts || []).map(shift => {
            return {
                ...shift,
                start: moment(eventStart).clone().add(shift.start, 'seconds').toDate(),
                end: moment(eventStart).clone().add(shift.end, 'seconds').toDate()
            }
        }), moment(eventStart).format('YYYY-MM-DD'));
        const shifts = (segments || []).map(shift => {
            const s = {
                ...shift,
                start: moment(shift.start).unix() - eventStartUnix,
                end: moment(shift.end).unix() - eventStartUnix
            }
            s.duration = s.end - s.start;
            return s;
        });

        const nestedEventData = shifts.map((shift, _i) => {
            const
                position = dateToPx(DateHelper.add(eventStart, shift.start * 1000)),
                size = dateToPx(DateHelper.add(eventStart, shift.end * 1000)) - position,
                shiftStart = moment(eventStart).add(shift.start, 'seconds'),
                shiftEnd = moment(eventStart).add(shift.end, 'seconds');
            const extraClass = getShiftClass(shift);
            const eventData = {
                tag: 'span',
                id: `${eventRecord.id}-nested-${_i}`,
                class: `text-break white-space-normal nested ${extraClass}`,
                style: {
                    [this.isHorizontal ? 'left' : 'top']: `${position - 3}px`,
                    [this.isHorizontal ? 'width' : 'height']: `${size}px`
                },
            };
            if (shift.type === 'break') {
                const amount = shift.duration / 60;
                // eventData.html = `${shiftStart.format("h.ma").replace("M", "")}</br>${shiftEnd.format("h.ma").replace("M", "")}</br>${amount > 60 ? `${(amount - (amount % 60)) / 60}h ${amount % 60}m` : `${amount}m`}`;
                eventData.html = `Break </br>${amount > 60 ? `${(amount - (amount % 60)) / 60}h ${amount % 60}m` : `${amount}m`}`;
            } else if (shift.type === 'uat') {
                eventData.html = ``;
            } else {
                const payCode = shift?.payCode || _payCode;
                const hours = durationAsHoursAndMinsString(
                    shiftEnd.format("YYYY-MM-DD HH:mm"),
                    shiftStart.format("YYYY-MM-DD HH:mm"),
                );
                eventData.html = `
                    <span class="text-break white-space-normal font-size p-1 w-100 shift-detail">
                        <span class="text-wrap">
                            ${hours}  • ${payCode?.code}
                        </span>
                        <span class="text-wrap"> ${role}</span>
                    </span>
                `;
            }
            return eventData;
        });
        return nestedEventData;
    }

    let shiftTemplate = `<span class="text-wrap">
            ${startDate} - ${endDate} • ${duration}${eventRecord?.data?.shifts?.[0]?.payCode?.code ? ` • ${eventRecord?.data?.shifts?.[0]?.payCode?.code}` : ''}
        </span>
        <span class="text-wrap"> ${role} </span>
    `;
    if ((eventRecord?.data?.shifts || []).length > 1) {
        const shiftGroups = [];
        (eventRecord?.data?.shifts || []).forEach((shift, index) => {
            if(!shift.group) shift.group = 0;
            if(!shiftGroups[shift.group]) {
                shiftGroups[shift.group] = [];
            }
            shiftGroups[shift.group].push(shift);
        });
        const shiftRes = [];
        shiftGroups.forEach((_shifts) => {
            if (_shifts.length === 1) {
                const shift = _shifts[0];
                const shiftStart = moment(eventStart).clone().add(shift.start, 'seconds');
                const shiftEnd = moment(eventStart).clone().add(shift.end, 'seconds');
                const payCode = shift.payCode || _payCode;
                const hours = durationAsHoursAndMinsString(
                    shiftEnd.format("YYYY-MM-DD HH:mm"),
                    shiftStart.format("YYYY-MM-DD HH:mm"),
                );
                shiftRes.push(`<span class="text-wrap mt-1">
                    ${shiftStart.format('h:mmA').replace("M", "")} - ${shiftEnd.format('h:mmA').replace("M", "")} • ${hours} • ${payCode?.code}
                </span>`
                )
            } else {
                const { overtime, breakHours, regular } = (_shifts || []).reduce((t, shift) => {
                        if (shift.type === 'overtime') t.overtime += parseFloat(shift.duration || '0');
                        else if (shift.type === 'break') t.breakHours += parseFloat(shift.duration || '0');
                        else t.regular += parseFloat(shift.duration || '0');
                        return t;
                    },
                    { overtime: 0, breakHours: 0, regular: 0 },
                );
                const shiftStart = moment(eventStart).clone().add(_shifts[0].start, 'seconds');
                const shiftEnd = moment(eventStart).clone().add(_shifts[_shifts.length - 1].end, 'seconds');

                const hours = regular ? ` • REG: ${durationAsHoursAndMinsString(
                    shiftStart.clone().add(regular, 'seconds').format("YYYY-MM-DD HH:mm"),
                    shiftStart.format("YYYY-MM-DD HH:mm"),
                )}` : '';

                const ot = overtime ? ` • OT: ${durationAsHoursAndMinsString(
                    shiftStart.clone().add(overtime, 'seconds').format("YYYY-MM-DD HH:mm"),
                    shiftStart.format("YYYY-MM-DD HH:mm"),
                )}` : '';

                const brk = breakHours ? ` • BRK: ${durationAsHoursAndMinsString(
                    shiftStart.clone().add(breakHours, 'seconds').format("YYYY-MM-DD HH:mm"),
                    shiftStart.format("YYYY-MM-DD HH:mm"),
                )}` : '';


                shiftRes.push(`<span class="text-wrap mt-1">
                    ${shiftStart.format('h:mmA').replace("M", "")} - ${shiftEnd.format('h:mmA').replace("M", "")}${hours}${ot}${brk}
                </span>`
                )
            }

        });
        // shiftTemplate = (eventRecord?.data?.shifts || []).filter(shift => shift.type !== 'break').map((shift, _i) => {
        //     const shiftStart = moment(eventStart).clone().add(shift.start, 'seconds');
        //     const shiftEnd = moment(eventStart).clone().add(shift.end, 'seconds');
        //     const payCode = shift.payCode || _payCode;
        //     const hours = durationAsHoursAndMinsString(
        //         shiftEnd.format("YYYY-MM-DD HH:mm"),
        //         shiftStart.format("YYYY-MM-DD HH:mm"),
        //     );
        //     return `<span class="text-wrap mt-1">
        //             ${shiftStart.format('h:mm A').replace("M", "")} - ${shiftEnd.format('h:mm A').replace("M", "") } • ${hours} • ${payCode?.code}
        //         </span>`
        // }).join('');

        shiftTemplate = shiftRes.join('');
    }

    const mainEventData = {
        tag: 'span',
        id: eventRecord.id,
        class: `text-break white-space-normal nested week`,
        html: `
            <span class="text-break white-space-normal font-size p-1 w-100 shift-detail">
                ${shiftTemplate}
            </span>
        `
    };

    return [
        {
            children: [
                mainEventData
            ]
        },
        eventRecord?.locked && {
            tag: "i",
            class: "fa-solid fa-lock font-weight-bold pr-1 pt-1"
        }
    ]
}
// export const eventRenderer = ({ eventRecord, renderData, mode, schedulerInstance }) => {
//     renderData.eventStyle = "colored";
//     if (eventRecord.isParent) {
//         return renderToStaticMarkup(
//             <span className="slug">
//                 <i className="b-fa b-fa-film" />
//                 {eventRecord.name}
//             </span>
//         );
//     } else {
//         if (eventRecord?.status === "approved" && !eventRecord?.locked) {
//             // renderData.style = `
//             //     background-color: #b8f2bc;
//             //     color: #179b24;
//             //     border-left: 3px solid #179b24;
//             //     font-size: 12px!important;
//             // `;
//             // eventRecord.eventColor = SchedulerColors.approved;
//             // eventRecord.data.eventColor = SchedulerColors.approved;
//             renderData.eventColor = SchedulerColors.approved;
//         } else if (eventRecord?.status === "approved" && eventRecord?.locked) {
//             renderData.eventColor = SchedulerColors.approved;
//             renderData.eventStyle = "hollow";
//             // renderData.style = `
//             //     background-color: #fff; 
//             //     color: #179b24; 
//             //     border-left: 3px solid #179b24;
//             //     box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
//             //     font-size: 12px!important;
//             // `;
//         } else if (eventRecord?.status === "submitted") {
//             renderData.eventColor = SchedulerColors.submitted;
//             // renderData.style = `
//             //     background-color: #D9EDFD; 
//             //     color: #2497F3; 
//             //     border-left: 3px solid #2497F3;
//             //     box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
//             //     font-size: 12px!important;
//             // `;
//         } else {
//             renderData.eventColor = SchedulerColors.pending;
//         }
//         console.log(mode);
//         const duration = renderDuration(eventRecord.duration, eventRecord.shifts);
//         const job = eventRecord.job
//             ? (typeof eventRecord.job === "object" && eventRecord.job)
//             : null;
//         const startDate = moment(eventRecord.startDate).format("h.ma").replace("M", "");
//         const endDate = moment(eventRecord.endDate).format("h.ma").replace("M", "");
       
//         if (mode === "date" && eventRecord?.data?.shifts?.length > 1 && schedulerInstance) {

//             const dateToPx = (date) =>
//                 schedulerInstance.getCoordinateFromDate(date) - renderData.left;
//             const nestedEvents = [];
//             eventRecord?.data?.shifts?.sort((a, b) => a.start - b.start).forEach((shift, index) => {
//                 let event = {};
//                 if (!event.startDate) {
//                     event.startDate = moment(eventRecord.startDate).add(shift.start, 'seconds').toDate();
//                     event.endDate = moment(eventRecord.startDate).add(shift.end, 'seconds').toDate();
//                     event.startOffset = DateHelper.diff(moment(eventRecord.startDate).toDate(), event.startDate);
//                     event.endOffset = DateHelper.diff(event.startDate, event.endDate);
//                     const hours = durationAsHoursAndMinsString(
//                         moment(event.endDate).format("YYYY-MM-DD HH:mm"),
//                         moment(event.startDate).format("YYYY-MM-DD HH:mm"),
//                     );
//                     event.name = `
//                       <span class="text-break white-space-normal font-size">
//                         <span class="text-wrap">
//                             ${hours}
//                         </span>
//                          ${job && (
//                                 <div className="text-wrap">
//                                     {renderSettingsResourceLabel(job)}
//                                 </div>
//                             )}
//                         <span class="text-wrap">
//                             ${duration}
//                         </span>
//                       </span>
//                   `;
//                     event.class = "";
//                     nestedEvents.push(event);
//                 }
//             });
//             console.log("date", nestedEvents);
//             return nestedEvents
//                 .map((nestedEvent) => ({
//                     left: dateToPx(
//                         DateHelper.add(eventRecord.startDate, nestedEvent.startOffset)
//                     ),
//                     width: dateToPx(
//                         DateHelper.add(eventRecord.startDate, nestedEvent.endOffset)
//                     ),
//                     class: nestedEvent.class,
//                     name: nestedEvent.name,
//                 }))
//                 .map(
//                     (value) => `
//                 <div class="nested ${value.class}" style="left: ${value.left}px;width: ${value.width}px">
//                     ${value.name}
//                 </div>
//               `
//                 )
//                 .join("");
//         }

//         return [
//             {
//                 children: [
//                    { 
//                         class: "",
//                         html: renderToStaticMarkup(
//                             <span class="text-break white-space-normal">
//                                 <div className="text-wrap">
//                                     {startDate}&nbsp;-&nbsp;
//                                     {endDate}&nbsp;•&nbsp;
//                                     {duration}
//                                 </div> 
                
//                                 {job && (
//                                     <div className="text-wrap">
//                                         {renderSettingsResourceLabel(job)}
//                                     </div>
//                                 )}
//                             </span>
//                         )
//                     }
//                 ]
//             },
//             eventRecord?.locked && {
//                 tag: "i",
//                 class: "fa-solid fa-lock font-weight-bold"
//             }
//         ]
//     }
// };
