import { useContext, useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";
import classNames from "classnames";
import UploadInput from "../../../components/Inputs/Upload";
import Button from "../../../components/Button";
import ResetPasswordModal from "./ResetPasswordModal";
import { ProfileContext } from "../context";
import { useImpersonate } from "../../People/api";
import { getPrimaryActiveResource } from "../../../utils/helpers/user";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { usePeopleApi } from "../../../utils/api/people";
import { useProfileApi } from "../../../utils/api/profile";
import { useAvatarUpload } from "../../../utils/api/upload";
import { getUploadUrl } from "../../../utils/helpers/upload";
import { useDeleteModal } from "../../Settings/helpers/useDeleteModal";
import { useAccess } from "../../../utils/hooks/access";
import { initials } from "../../../utils/helpers/string";
import { useVisible } from "../../../utils/hooks/useVisible";
import "./style.scss";

const fileTypes = ["image/jpeg", "image/png"];
const fileSizeLimit = 2;

function Overview() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user, submit, isSelf } = useContext(ProfileContext);
	const [loading, setLoading] = useState(false);
	const { login, loading: impersonateLoading } = useImpersonate();
	const { visible, close, open } = useVisible();

	const { hasAccess: canChangePersonalInfo } = useAccess(
		isSelf
			? "profile.changePersonalInformation"
			: "people.changePersonalInformation",
	);

	const { hasAccess: canManageUsers } = useAccess("canManageUsers");

	const { upload: uploadAvatar, remove: removeAvatar } = useAvatarUpload();
	const { changePersonalInformation: profileSubmit } = useProfileApi();
	const { changePersonalInformation: peopleSubmit } = usePeopleApi();

	const primaryJob = useMemo(
		() => getPrimaryActiveResource(user?.jobs || []),
		[user?.jobs],
	);

	const onChange = useCallback(
		async (info) => {
			if (
				!info.target.files[0].type ||
				!fileTypes.includes(info.target.files[0].type)
			) {
				toast.error(t("jpg-png-file"));
				return false;
			}

			if (
				!info.target.files[0].size ||
				info.target.files[0].size > fileSizeLimit * 1024 * 1024
			) {
				toast.error(t("image-size-rule"));
				return false;
			}

			try {
				setLoading(true);

				const res = await uploadAvatar(info.target.files[0]);
				if (!res) {
					return;
				}

				const data = {
					image: res.path,
				};

				submit(profileSubmit, peopleSubmit, data);
				toast.success(t("image-uploaded"));
			} catch (err) {
				toast.error(t("image-upload-failed"));
			} finally {
				setLoading(false);
			}
		},
		[submit, t, uploadAvatar, peopleSubmit, profileSubmit, setLoading],
	);

	const deleteProfilePicture = useCallback(async () => {
		try {
			const response = await removeAvatar();
			if (response) {
				submit(profileSubmit, peopleSubmit, { image: "" });
				toast.success(t("image-removed"));
			}
		} catch (err) { }
	}, [removeAvatar, submit, profileSubmit, peopleSubmit, t]);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		permission: isSelf
			? "profile.changePersonalInformation"
			: "people.changePersonalInformation",
		message: () => t("do-you-want-to-delete-image"),
		onConfirm: () => deleteProfilePicture(),
	});

	return (
		<Card className="overview">
			<CardBody>
				{loading || !user ? (
					<div
						className="w-100 d-flex align-items-center justify-content-center"
						style={{ height: "140px", width: "140px" }}
					>
						<div
							className="spinner spinner-border spinner-border-sm"
							style={{ height: "50px", width: "50px" }}
							role="status"
						/>
					</div>
				) : (
					<div>
						{canChangePersonalInfo && (
							<UploadInput
								name="image"
								onChange={onChange}
								fileList={[]}
								label="e"
								multiple={false}
								fileTypes={fileTypes}
								fileSizeLimit={fileSizeLimit}
								className={classNames(
									!isSelf
										? "user-avatar-image"
										: "avatar-image",
								)}
							/>
						)}

						{user?.image ? (
							<img
								alt=""
								className={classNames(
									"avatar avatar-xl  rounded-circle img-center img-fluid shadow shadow-lg--hover",
									!canChangePersonalInfo && "my-4",
								)}
								src={getUploadUrl(user?.image)}
								width={140}
								height={140}
								style={{ cursor: "auto" }}
							/>
						) : (
							<div
								className="avatar avatar-xl rounded-circle img-center img-fluid bg-primary shadow shadow-lg--hover d-flex align-items-center"
								style={{ cursor: "auto" }}
							>
								<h1 className="display-1 text-white">
									{initials(user)}
								</h1>
							</div>
						)}

						<div
							className={classNames(
								user?.image && "delete-icon shadow",
							)}
						>
							{user?.image ? (
								<i
									className="fa-solid fa-trash cursor-pointer text-muted bg-transparent"
										onClick={() => openDeleteModal()}
								/>
							): null}
						</div>
					</div>
				)}

				<div className="pt-4 text-center">
					<h5 className="h3 title">
						<div className="d-flex align-items-center justify-content-center">
							<span className="d-block mb-1">
								{user && `${user?.firstName} ${user?.lastName}`}{" "}
							</span>

							{user?.isApi && (
								<span
									className="bg-red text-white px-2 text-sm text-center ml-2"
									style={{ borderRadius: "12px" }}
								>
									API
								</span>
							)}
						</div>

						<small className="h4 font-weight-light text-muted">
							{primaryJob &&
								renderSettingsResourceLabel(primaryJob)}
						</small>

						{!isSelf && user && canManageUsers && (
							<div className="d-flex align-items-center justify-content-center my-1 flex-column">
								<Button
									className="bg-transparent shadow-none border-0 text-primary p-0 my-2"
									onClick={() =>
										login(user?.id, () =>
											navigate("/redirect"),
										)
									}
									loading={impersonateLoading}
									disabled={loading}
								>
									<i className="fa-solid fa-right-to-bracket mr-1" />
									{t("log-in")}
								</Button>

								<Button
									className="bg-transparent shadow-none border-0 text-orange p-0"
									onClick={open}
								>
									<i className="fa-solid fa-repeat mr-1" />
									{t("reset-password")}
								</Button>
							</div>
						)}
					</h5>
				</div>
			</CardBody>

			<ResetPasswordModal user={user} isOpen={visible} close={close} />

			{deleteModal}
		</Card>
	);
}

export default Overview;
