import {useMemo} from "react";
import Select from "../../../../../../components/Inputs/Select";
import Text from "../../../../../../components/Inputs/Text";
import {useCustomFields} from "../../../../hooks/useCustomFields";
import {isAddedTimeSheet} from "../../helpers";

export const getCustomFieldInput = ({ config, value, disabled, onChange }) => {
    switch (config.type) {
        case "text":
        case "number": {
            return (
                <Text
                    value={value}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        }
        case "email": {
            return (
                <Text
                    type="email"
                    value={value}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        }
        case "select": {
            return (
                <Select
                    value={value}
                    disabled={disabled}
                    onChange={(value) => onChange(value)}
                >
                    {config.options.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            );
        }
    }
}

export const getCustomField = (
    canEdit,
    customField,
    { value, row, cell, updateAddedTimeSheet, updateTimeSheet },
) => {
    const {original} = row;
    const { locked, customFields } = original;
    const disabled = (!isAddedTimeSheet(original) && locked) || !canEdit || row.disabled || cell.disabled;
    const onChange = (value) => {
        const data = {
            customFields: {
                ...customFields,
                [customField.code]: value,
            },
        };
        if (isAddedTimeSheet(original)) {
            updateAddedTimeSheet(original, data);
        } else {
            updateTimeSheet(original.id, data);
        }
    }

    return getCustomFieldInput({ config: customField, value, disabled, onChange });
}

export const useCustomFieldsColumns = ({ canEdit }) => {
    const {data: customFields} = useCustomFields();
    return useMemo(() => customFields.map((field) => ({
        Header: field.description,
        accessor: `customFields.${field.code}`,
        Cell: getCustomField.bind(null, canEdit, field),
    })), [customFields, canEdit]);
};
