import { camelCase } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap";
import Button from "../../../../../../components/Button";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import { useModuleAccess } from "../../../../../../utils/hooks/access";
import { useUserPreferenceActions } from "../../../../../Settings/Configuration/api/useUserPreferences";
import { useColumnToggleUserPreferences } from "../../../../../Settings/Configuration/hooks/newTableHooks/useColumnToggleUserPreferences";

function ColumnToggle({ prefix, columns }) {
	const { t } = useTranslation();

	const { canCreate } = useModuleAccess("settings.userPreferences").access;

	const hideableColumns = useMemo(() => {
		return columns.filter((column) => {
			return column.getCanHide();
		});
	}, [columns]);

	const {
		visible,
		toggle,
		visibleColumns,
		onVisibilityChange,
		getKeyIdentifier,
		onSave,
	} = useColumnToggleUserPreferences(columns);

	const { submit, submitting } = useUserPreferenceActions(
		"weeklyTimeSheetUserTimeSheet"
	);
	const save = useCallback(() => {
		return onSave({ columns: hideableColumns, submit });
	}, [onSave, hideableColumns]);

	return (
		<Dropdown isOpen={visible} toggle={toggle}>
			<DropdownToggle
				size="sm"
				color="neutral"
				className="inline-flex items-center shadow-none mr-0"
			>
				<i
					className="fas fa-list text-dark"
					style={{ fontSize: "20px" }}
				/>
			</DropdownToggle>

			<DropdownMenu
				right
				style={{
					maxHeight: "300px",
					overflowY: "auto",
					minWidth: "14rem",
				}}
			>
				{hideableColumns.map((column) => {
					const {
						header,
						columnToggleLabel,
						alwaysVisible,
					} = column.columnDef;
					let id = "";
					let key = "";

					if (columnToggleLabel) {
						key = columnToggleLabel;
					} else {
						key = header;
					}

					if (columnToggleLabel) {
						id = `${camelCase(
							columnToggleLabel,
						)}-column-toggle`;
					} else {
						id = `${camelCase(header)}-column-toggle`;
					}

					if (prefix) {
						id = `${prefix}-${id}`;
					}

					const keyIdentifier = getKeyIdentifier(column);

					return (
						<DropdownItem key={key} toggle={false}>
							<Checkbox
								{...{
									id,
									checked: visibleColumns[keyIdentifier],
									disabled: alwaysVisible,
									onClick: () => {
										const value = !column.getIsVisible();
										column.toggleVisibility(value);
										onVisibilityChange(keyIdentifier);
									},
								}}
							>
								{columnToggleLabel
									? columnToggleLabel
									: header}
							</Checkbox>
						</DropdownItem>
					);
				})}

				<DropdownItem key="buttons">
					{canCreate && (
						<div className="d-flex align-items-center justify-content-center my-2">
							<Button
								className="px-3"
								color="primary"
								size="sm"
								loading={submitting}
								onClick={save}
							>
								{t("save")}
							</Button>

							<Button
								className="px-3 border"
								color="muted"
								size="sm"
								disabled={submitting}
								onClick={toggle}
							>
								{t("cancel")}
							</Button>
						</div>
					)}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

export default ColumnToggle;
