import { useCallback, useEffect } from "react";
import { isCancelError } from "../../../utils/helpers/errors";
import { getSubDomainFromDomain } from "../../../utils/helpers/url";
import { useValidateQrCodeLocation } from "../api";
import { useQrCodeClockIn } from "../context";

export const useValidateLocation = () => {
	const { domain, position, qrCode, area, isPointInsidePolygon, dispatch } =
		useQrCodeClockIn();
	const validateQrCodeLocation = useValidateQrCodeLocation();

	const validateLocation = useCallback(
		async (options) => {
			const subDomain = getSubDomainFromDomain(domain);

			dispatch({ type: "validate-location" });
			try {
				const response = await validateQrCodeLocation({
					...options,
					params: {
						...options?.params,
						uniqueKey: subDomain,
					},
				});
				const keyName = "QR_CODE_CLOCK_COMPANY";
				localStorage.setItem(keyName, response.company);
				sessionStorage.setItem(keyName, response.company);
				dispatch({
					type: "validate-location-success",
					payload: { qrCode: response },
				});
			} catch (err) {
				if (isCancelError(err)) {
					dispatch({ type: "validate-location-cancel" });
					return;
				}
				dispatch({
					type: "validate-location-failed",
					payload: { error: err },
				});
			}
		},
		[dispatch, domain, validateQrCodeLocation],
	);

	const hasArea = !!area;
	const hasQrCode = !!qrCode;
	useEffect(() => {
		const controller = new AbortController();
		if (
			!hasQrCode &&
			position?.lat &&
			position?.lng &&
			hasArea &&
			isPointInsidePolygon
		) {
			validateLocation({
				params: {
					lat: position.lat,
					lng: position.lng,
				},
				signal: controller.signal,
			});
		}
		return () => controller.abort();
	}, [
		hasQrCode,
		position?.lat,
		position?.lng,
		hasArea,
		isPointInsidePolygon,
		validateLocation,
	]);
};
