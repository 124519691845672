import { useCallback } from "react";
import useApi, { useUploadApi } from "..";

export const useCompanyLogoUpload = () => {
	const { uploadPost, uploadDelete } = useUploadApi();

	const upload = useCallback(
		(data, company) => {
			const formData = new FormData();
			formData.append("logo", data);

			return uploadPost("/upload/company/logo", {
				baseURL: "",
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					Company: company,
				},
			});
		},
		[uploadPost],
	);

	const uploadBackgroundImage = useCallback(
		(data, company) => {
			const formData = new FormData();
			formData.append("backgroundImage", data);

			return uploadPost("/upload/company/background-image", {
				baseURL: "",
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					Company: company,
				},
			});
		},
		[uploadPost],
	);

	const remove = useCallback(() => {
		return uploadDelete("/upload/company/logo", {
			baseURL: "",
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}, [uploadDelete]);

	const removeBackgroundImage = useCallback(() => {
		return uploadDelete("/upload/company/background-image", {
			baseURL: "",
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}, [uploadDelete]);

	return { upload, uploadBackgroundImage, remove, removeBackgroundImage };
};

export const useAvatarUpload = () => {
	const { uploadPost, uploadDelete } = useUploadApi();

	const upload = useCallback(
		(data) => {
			const formData = new FormData();
			formData.append("file", data);

			return uploadPost("/upload/avatar", {
				baseURL: "",
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		},
		[uploadPost],
	);

	const remove = useCallback(() => {
		return uploadDelete("/upload/avatar", {
			baseURL: "",
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}, [uploadDelete]);

	return { upload, remove };
};

export const useUserDocumentUpload = () => {
	const { uploadPost, uploadDelete } = useUploadApi();
	const { authPut } = useApi();

	const upload = useCallback(
		(id, data) => {
			const formData = new FormData();
			formData.append("file", data);

			return uploadPost(`/upload/user/${id}/documents`, {
				baseURL: "",
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		},
		[uploadPost],
	);

	const edit = useCallback(
		(id, data, documentId) => {
			return authPut(`/upload/user/${id}/documents/${documentId}`, {
				data,
			});
		},
		[authPut],
	);

	const remove = useCallback(
		(id, file) => {
			return uploadDelete(`/upload/user/${id}/documents`, {
				baseURL: "",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				params: { file },
			});
		},
		[uploadDelete],
	);

	return { upload, remove, edit };
};
