import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { isSupported, getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./config";

const FirebaseContext = createContext()

export const useOnFirebaseMessage = (callback) => {
  const { messaging } = useFirebase();
  useEffect(() => {
    if (messaging) {
      const unsubscribe = onMessage(messaging, callback);
      navigator.serviceWorker.addEventListener("message", (event) => callback(event.data, true));
      return () => {
        unsubscribe();
        navigator.serviceWorker.removeEventListener("message", callback);
      };
    }
  }, [messaging, callback]);
}

export const FirebaseProvider = ({ children }) => {
  const [app] = useState(initializeApp(firebaseConfig));
  const [messaging, setMessaging] = useState();
  const [firebaseToken, setFirebaseToken] = useState();
  useEffect(() => {
    isSupported().then((supported) => {
      if (supported) {
        setMessaging(getMessaging(app));
      }
    });
  }, [setMessaging, app]);

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted" && messaging) {
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
          })
          .then((token) => {
            setFirebaseToken(token);
          })
          .catch((err) => {
            console.error("Firebase Error: ", err);
          });
        }
      });
    }
  }, [messaging]);


  return (
    <FirebaseContext.Provider value={{
      app,
      messaging,
      firebaseToken,
    }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("You can not use useFirebase outside of FirebaseProvider");
  }
  return context;
}
