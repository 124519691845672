import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import Topbar from "../../components/Topbar";
import BulkActions from "../../components/BulkActions";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import AssignUsersToGroupModal from "./AssignUsersToGroupModal";
import useSettingsCRUDHook from "../../hook";
import { useNewTable } from "../../../../components/NTable/hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useColumns } from "./columns";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess } from "../../../../utils/hooks/access";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useOnExport } from "../../helpers/useOnExport";
import { useImportModal } from "../../helpers/useImportModal";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useVisible } from "../../../../utils/hooks/useVisible";

function UserGroup() {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
        settingsExport,
        loadingExport,
        setData
    } = useSettingsCRUDHook("/user-group");

    const { visible, open, close, selected: group } = useVisible();

    const { hasAccess: canAdd } = useAccess(
        "settings.general.userGroup.canCreate",
    );

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-user-groups", {
                    count: data?.length,
                })
                : t("do-you-want-to-delete-user-group"),
        onConfirm: (data) =>
            Array.isArray(data) ? batchRemove(data) : remove(data),
        permission: "settings.general.userGroup.canDelete"
    });

    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const columns = useColumns({
        edit: goToEdit,
        remove: openDeleteModal,
        clone: goToClone,
        assign: open
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "userGroups",
    });

    const queryFilters = useQueryFilters(table);

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-user-group"),
        create: t("add-user-group"),
        edit: t("edit-user-group"),
        clone: t("clone-user-group"),
    });

    useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/user-group",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/user-group/import",
        exportUrl: "/export/user-group",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

    return (
        <div className="d-flex h-100">
            <Card className="flex-fill mb-0">
                <Topbar
                    title={t("user-groups")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    importFile={openImportModal}
                    onExport={onExport}
                    loading={loading || loadingExport}
                />

                <Card
                    className="mb-auto"
                    style={{ maxHeight: "calc(100% - 156px)" }}
                >
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
                {isFormOpen ? (
                    <Form
                        {...formProps}
                        error={error}
                        loading={submitting}
                        close={goToView}
                    />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {visible && (
                <AssignUsersToGroupModal
                    isOpen={visible}
                    group={group}
                    close={close}
                />
            )}

            {importModal}

            {deleteModal}

            {unarchiveModal}
        </div>
    );
}

export default UserGroup;
