import { useMemo, useCallback } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "./Map";
import Marker from "./Marker";
import { mapStyleConfig } from '../../config/mapStyleConfig';

function Map({ marker, center, zoom, setSearch, changeMarker, options }) {
	const onClick = useCallback(
		({ latLng }) => {
			if (latLng === null) {
				return;
			}
			const { lat, lng } = latLng;
			changeMarker({ lat: lat(), lng: lng() });
			setSearch("");
		},
		[changeMarker, setSearch],
	);

	const mapOptions = useMemo(
		() => ({
			center,
			zoom,
			mapTypeControl: false,
			streetViewControl: false,
			...options,
			...mapStyleConfig,
		}),
		[zoom, center],
	);

	return (
		<Wrapper
			apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
		>
			<GoogleMap onClick={onClick} options={mapOptions}>
				<Marker position={marker} />
			</GoogleMap>
		</Wrapper>
	);
}

export default Map;
