export const getPropertyFromGeocoderResult = (result, property) =>
	result.address_components.find((addressComponent) =>
		addressComponent.types.includes(property)
	)?.short_name;

export const getGeocoderResultProperties = (result) => ({
	address1: result.formatted_address.split(",")[0],
	country: getPropertyFromGeocoderResult(result, "country"),
	state: getPropertyFromGeocoderResult(result, "administrative_area_level_1"),
	city: getPropertyFromGeocoderResult(result, "locality"),
	postalCode: getPropertyFromGeocoderResult(result, "postal_code"),
});
