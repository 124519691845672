import { Field as BaseField, List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../components/Field";
import DateInput from "../../../../../../components/Inputs/DateInput";
import moment from "moment-timezone";

function DateList({ name, disabled }) {
    const { t } = useTranslation();

    return (
        <BaseField shouldUpdate>
            {({}, {}, { getFieldValue, getFieldsValue }) => {
                const isSameDate = getFieldValue(["days", name, "isSameDate"]);
                const repeatEveryYear = getFieldValue(["days", name, "repeatEveryYear"]);

                return (
                        <>
                            {isSameDate || !repeatEveryYear ? (
                                <Field
                                    className="d-flex flex-column"
                                    name={[name, "date"]}
                                    label={t("date")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-date"),
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (moment(value).isBefore(moment(), "year")) {
                                                    return Promise.reject(t("new-date-year-must-be-bigger-than-the-current-date"));
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <DateInput disabled={disabled} />
                                </Field>
                            ) : (
                                <List name={[name, "dates"]}>
                                    {(fields, { add, remove }) => {
                                        return (
                                            <div>
                                                {fields.map(({ key, name: listName }, index) => {
                                                    return (
                                                        <div key={key}>
                                                            <div className="d-flex gap-5 align-items-center">
                                                                <Field
                                                                    name={[listName]}
                                                                    label={t("date")}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t("required-date"),
                                                                        },
                                                                        ({ getFieldValue }) => ({
                                                                            validator: (_, value) => {
                                                                                const previousDate = getFieldValue([
                                                                                    "days",
                                                                                    name,
                                                                                    "dates",
                                                                                    listName - 1,
                                                                                ]);
                                                                                if (
                                                                                    value &&
                                                                                    index > 0 &&
                                                                                    previousDate &&
                                                                                    moment(value).isSame(moment(previousDate), "year")
                                                                                ) {
                                                                                    return Promise.reject(
                                                                                        t("new-year-date-must-be-bigger-than-previous-year")
                                                                                    );
                                                                                } 
                                                                                // else if (moment(value).isBefore(moment(), "year")) {
                                                                                //     return Promise.reject(
                                                                                //         t("year-must-be-bigger-than-current-year")
                                                                                //     );
                                                                                // } 
                                                                                else if (moment(value).isBefore(moment(), "year")) {
                                                                                    return Promise.reject(t("new-date-year-must-be-bigger-than-the-current-date"));
                                                                                }
                                                                                return Promise.resolve();
                                                                            },
                                                                        }),
                                                                    ]}
                                                                    className="d-flex flex-column w-100"
                                                                >
                                                                    <DateInput placeholder={t("date")} disabled={disabled} />
                                                                </Field>

                                                                {!disabled && (
                                                                    <FontAwesomeIcon
                                                                        icon={faPlus}
                                                                        className="text-md text-primary cursor-pointer"
                                                                        onClick={() => add("", fields?.length)}
                                                                    />
                                                                )}

                                                                {!disabled && fields.length > 1 && (
                                                                    <FontAwesomeIcon
                                                                        className="text-md text-danger cursor-pointer"
                                                                        onClick={() => remove(listName)}
                                                                        icon={faXmark}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }}
                                </List>
                            )}
                        </>
                    )
            }}
        </BaseField>
    );
}

export default DateList;
