import { useMemo } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useOnboarding, useOnboardingStep } from "./api";
import { Navigate } from "react-router-dom";
import "../style.scss";
import { useIsMasterCompany } from "../../../utils/hooks/company";

function CompanyOnboarding() {
	const _workaround = useOnboardingStep();
	const props = useOnboarding();
	const isMasterCompany = useIsMasterCompany();

	const currentStep = useMemo(() => {
		const Step = [Step1, Step2, Step3, Step4][props.step];

		if (Step) {
			return <Step {...props} />;
		} else {
			return null;
		}
	}, [props]);

	if (_workaround === 4 || !isMasterCompany) {
		return <Navigate to="/" />;
	}

	return currentStep;
}

export default CompanyOnboarding;
