import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Drawer from "../../../../../components/Drawer";
import Button from "../../../../../components/Button";
import Loading from "../../../../../components/Loaders/Loading";
import EventForm from "./Form";
import { calculateFields } from "../../../api/useSaveEvent";
import { combineDateAndTime } from "../../../../../utils/helpers/date";
import { useAccess } from "../../../../../utils/hooks/access";
import { useMyRequests } from "../../../../Request/api";
import { useGetIpAddress } from "../../../../TimeClock/ClockIn/api";
import { prepareEventForDb } from "../../../helpers/prepareEventForDb";
import { getAutomaticBreaks, calculateShiftsWithAutomaticBreaks } from "./Form/Shifts/helper";
import { useCompany } from "../../../../../utils/hooks/company";
import { useAddedPayCode } from "../../../../TimeSheet/components/TimeSheetTable/hooks/useAddedPayCode";
import { getPrimaryActiveResource } from "../../../../../utils/helpers/user";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";

function EditEventDrawer({
	title,
	eventRecord,
	resourceRecord,
	close,
	visible,
	schedulerInstance,
	onEventSave,
	isTeamScheduler,
	isCreating,
	events,
	loading,
	mutate,
	saveButtonText
}) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const {
		hasAccess: canEdit
	} = useAccess(isTeamScheduler ? "schedule.canEdit" :"schedule.canEditMySchedule");
	const {
		hasAccess: canCreate
	} = useAccess(!isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
	const {
		hasAccess: canCreateRequest
	} = useAccess(!isTeamScheduler && "request.canCreate");

	const { create } = useMyRequests();
	const { ip, getIp } = useGetIpAddress();
	const company = useCompany();
	const { allocatedGroups } = useGroups();
	
	const resource = useMemo(() => {
		return resourceRecord?.originalData;
	}, [resourceRecord?.originalData]);

	const defaultPayCode = useAddedPayCode(resource);

	const primaryJob = useMemo(() => {
		return getPrimaryActiveResource(resource?.jobs);
	}, [resource?.jobs]);

	const primaryLocation = useMemo(() => {
		return getPrimaryActiveResource(resource?.locations);
	}, [resource?.locations]);

	const levels = useMemo(() => {
		return allocatedGroups?.reduce((total, group) => {
			const level = group?.level.replace("_", "");
			if (resource && resource[level]) {
				return { ...total, [level]: resource[level] };
			} else if (eventRecord?.shifts && eventRecord?.shifts[0][level]) {
				return { ...total, [level]: eventRecord?.shifts[0][level] };
			} else {
				return total;
			}
		}, {});
	}, [allocatedGroups, resource, eventRecord?.shifts]);

	const defaultSegment = useMemo(() => {
		const scheduleSettings = resource?.calculationGroup?.schedule;
		const companySettings = company?.settings?.schedule;

		const shiftStart = (scheduleSettings?.shiftStart && moment.parseZone(scheduleSettings?.shiftStart))
			|| (companySettings?.shiftStart && moment.parseZone(companySettings?.shiftStart))
			|| moment().startOf("day").hour(9);
		const shiftEnd = (scheduleSettings?.shiftEnd && moment.parseZone(scheduleSettings?.shiftEnd))
			|| (companySettings?.shiftEnd && moment.parseZone(companySettings?.shiftEnd))
			|| moment().startOf("day").hour(17);

		let start = moment(eventRecord.startDate);
		let end = moment(eventRecord.endDate);

		if (isCreating && schedulerInstance?.viewPreset?.data?.id !== "hourAndDay") {
			start = combineDateAndTime(moment(eventRecord.startDate), moment(shiftStart));
			end = combineDateAndTime(moment(eventRecord.startDate), moment(shiftEnd));
		}
		const duration = moment(end).diff(moment(start), "seconds");

		return {
			type: "regular",
			payCode: defaultPayCode,
			location: primaryLocation,
			job: primaryJob,
			duration: renderDurationAsFormat(duration || 0, "HH:mm"),
			start,
			end,
			...levels
		}
	}, [
		defaultPayCode, 
		resource, 
		eventRecord, 
		company, 
		schedulerInstance?.viewPreset?.data?.id,
		levels,
		primaryJob,
		primaryLocation,
		isCreating
	]);

	const calcGroupBreaks = useMemo(() => {
		return resource?.calculationGroup?.breaks;
	}, [resource?.calculationGroup?.breaks]);

	const shiftsWithAutomaticBreaks = useMemo(() => {
		let shifts = [defaultSegment]
		const breaks = getAutomaticBreaks(calcGroupBreaks, shifts, defaultSegment);
		if (breaks?.length > 0) {
			shifts = calculateShiftsWithAutomaticBreaks(shifts, breaks);
		}
		return shifts;
	}, [defaultSegment, calcGroupBreaks]);

	const onClose = useCallback(() => {
		if (schedulerInstance?.editingRecord?.isCreating) {
			schedulerInstance?.editingRecord.remove();
			delete schedulerInstance?.editingRecord;
		}
		close();
	}, [close, schedulerInstance]);

	const createRequest = useCallback((data) => {
		const request = {
			user: resourceRecord?.id,
			type: "edit-schedule",
			device: "web-application",
			ip: ip ? ip : "",
			data: prepareEventForDb({ ...data, company })
		}
		create(
			request, 
			() => {
				toast.success(t("request-created-successfully"));
				onClose();
			}, 
			(error) => {
				if (typeof error === "string") {
					toast.error(error);
				} 
				onClose();
			}
		);
	}, [company, resourceRecord?.id, ip, create, onClose, t]);

	const onSave = useCallback(async () => {
		try {
			await form.validateFields();
		} catch (err) {
			return;
		}
		const values = form.getFieldsValue();

		const date = moment(values.date).format('YYYY-MM-DD');
	
		let startDate = combineDateAndTime(
		  moment(date),
		  moment(values.startDate),
		);
	
		let endDate = combineDateAndTime(
		  moment(date),
		  moment(values.endDate),
		);

		if (startDate && endDate.isBefore(startDate)) {
		  endDate.add(1, "day");
		}

		const calcFields = calculateFields(values);
		// // Update record
		if (eventRecord && (canEdit || canCreate) && onEventSave) {
			await eventRecord.setAsync({
				...calcFields,
				date,
				startDate: startDate.toISOString(true),
				endDate: endDate.toISOString(true),
				duration: endDate.diff(startDate, 'seconds'),
				durationUnit: 'second',
				isCreating,
				id: isCreating ? eventRecord?.id : events?.[0]?.id
			});
			await onEventSave(eventRecord, schedulerInstance);
		} else if (canCreateRequest) {
			createRequest({
				id: !eventRecord?.id.includes("_generated") ? eventRecord?.id : undefined,
				...calcFields,
				date,
				startDate: startDate.toISOString(true),
				endDate: endDate.toISOString(true),
				duration: endDate.diff(startDate, 'seconds'),
				durationUnit: 'second',
			}) 
		}
		close();
	}, [
		close, 
		schedulerInstance, 
		eventRecord, 
		form, 
		onEventSave, 
		canEdit, 
		canCreate, 
		canCreateRequest, 
		isCreating,
		createRequest,
		events
	]);

	const drawerLoading = useMemo(() => {
		return loading || (!shiftsWithAutomaticBreaks?.length
			&& resource?.calculationGroup?.breaks?.automatic?.status === true)
	}, [
		loading,
		resource?.calculationGroup?.breaks?.automatic?.status,
		shiftsWithAutomaticBreaks?.length,
	]);

	const drawerTitle = useMemo(() => {
		return drawerLoading ? "" : ( 
			<div className="d-flex justify-content-between align-items-center text-primary">
				{title}
			</div> 
		)
	}, [drawerLoading, title]);

	const footer = useMemo(() => {
		return (
			<div className="d-flex justify-content-end m-2">
				<Button className="text-dark" color="link" onClick={onClose} disabled={false}>
					{t("cancel")}
				</Button>
				<Button color="primary" loading={false} onClick={onSave}>
					{saveButtonText}
				</Button>
			</div>
		)
	}, [saveButtonText, onClose, onSave, t]);

	useEffect(() => {
		if (canCreateRequest && !canEdit) {
			const controller = new AbortController();
			getIp(controller);
			return () => controller.abort();
		}
	}, [getIp, canCreateRequest, canEdit]);

	return (
		<Drawer
			size="md"
			title={drawerTitle}
			close={onClose}
			open={visible}
			scrollable
			centered
			className="scheduler-drawer"
			footer={footer}
		>
			{drawerLoading? (
				<div className="w-100 d-flex align-items-center justify-content-center h-100">
					<Loading />
				</div>
			) : (
				<EventForm
					form={form} 
					eventRecord={eventRecord} 
					resource={resource}
					schedulerInstance={schedulerInstance}
					isCreating={isCreating}
					events={events}
					shiftsWithAutomaticBreaks={shiftsWithAutomaticBreaks}
					breaks={calcGroupBreaks}
					defaultSegment={defaultSegment}
					primaryJob={primaryJob}
					primaryLocation={primaryLocation}
					levels={levels}
				/>
			)}
		</Drawer>
	);
}

export default EditEventDrawer;
