import {useCallback} from "react";
import {useExportTimeSheets} from "../../api/useExportTimeSheets";
import {useDateFilters} from "../../hooks/useDateFilters";
import { useActionWithAccess } from "../../../../utils/hooks/access";

export const useExport = ({ ids, columns, filters }) => {
    const {from, to} = useDateFilters();
    const {downloadMySummary, downloadMyDetails} = useExportTimeSheets();

    const downloadSummary = useActionWithAccess(
        useCallback(() => {
            downloadMySummary({ from, to, ids, filters, columns });
        }, [from, to, ids, columns, filters, downloadMySummary]),
        "timeSheet.canExportMyTimesheet",
    );

    const downloadDetails = useActionWithAccess(
        useCallback(() => {
            downloadMyDetails({ from, to, ids, filters, columns });
        }, [from, to, ids, columns, filters, downloadMyDetails]),
        "timeSheet.canExportMyTimesheet",
    );

    return { downloadSummary, downloadDetails };
}
