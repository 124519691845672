import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper()

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const {
		code,
		description,
		status,
		actions,
	} = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			columnHelper.accessor("level", {
				header: t("level")
			}),
			status
		];

		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [isMasterCompany, code, description, status, actions, t]);
};
