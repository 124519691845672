import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { useAuthentication } from "../../Authentication/context";

export const useUltiproApi = () => {
	const { t } = useTranslation();
	const { company, dispatch } = useAuthentication();
	const { authPut } = useApi();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const submit = useCallback(
		async (data, onSuccess, onFailure) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authPut(
					`/companies/${company.id}/ultipro`,
					{
						data,
					},
				);
				if (response) {
					dispatch({
						type: "set-ultipro",
						payload: response.ultipro,
					});
					onSuccess(response.ultipro);
					toast.success(t("ultipro-updated"));
				}
			} catch (err) {
				setError(err);
				onFailure(err);
			} finally {
				setLoading(false);
			}
		},
		[t, company?.id, setLoading, setError, authPut, dispatch],
	);

	return { loading, error, submit };
};

export const useUtaApi = () => {
	const { t } = useTranslation();
	const { company, dispatch } = useAuthentication();
	const { authPut } = useApi();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const submit = useCallback(
		async (data, onSuccess) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authPut(`/companies/${company.id}/uta`, {
					data,
				});
				if (response) {
					dispatch({
						type: "set-uta",
						payload: response.uta,
					});
					onSuccess?.(response.uta);
					toast.success(t("uta-updated"));
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[t, company?.id, setLoading, setError, authPut, dispatch],
	);

	return { loading, error, submit };
};
