import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Pagination from "../../../../../../components/NTable/Pagination";
import SimpleTable from "../../../../../../components/SimpleTable";
import { useGetAssignedUsers } from "../api";
import { useColumns } from "./useColumns";

const perPage = 10;

function Table({ group, response }) {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const {
        unassign,
        fetch,
        loading,
        data,
        totalItems,
        setData,
        setTotalItems
    } = useGetAssignedUsers();


    const remove = useCallback((id) => {
        const data = {
            users: [id],
            groups: [group?.id]
        }
        unassign(data, () => {
            toast.success(t("removed-successfully"));
            setData((prev) => {
                return prev?.filter((user) => user?.id !== id);
            })
        })
    }, [unassign, setData, group?.id, t])

    const columns = useColumns({ remove });

    useEffect(() => {
        const controller = new AbortController();
        const params = {
            userGroups: group?.id,
            page: page + 1,
            perPage
        };
        fetch(params, controller)
        return () => controller.abort();
    }, [group?.id, page, fetch]);

    useEffect(() => {
        if (response?.length > 0) {
            setData((prev) => {
                const userIds = prev?.map(({ id }) => id)
                const resData = response?.filter(({ id }) => !userIds?.includes(id));
                return [...prev, ...resData];
            });
        };
    }, [response, setData]);

    useEffect(() => {
        if (response?.length > 0) {
            setTotalItems(data?.length)
        }
    }, [data?.length, response?.length, setTotalItems])

    return (
        <div className="w-100">
            <SimpleTable
                loading={loading}
                columns={columns}
                data={data}
                footer={false}
            />

            <Pagination
                page={page}
                gotoPage={setPage}
                perPage={perPage}
                showOnSizeChange={false}
                pageCount={Math.ceil(totalItems / perPage)}
                totalItems={totalItems}
            />
        </div>
    )
}

export default Table;
