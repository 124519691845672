import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useImportModal } from "../../helpers/useImportModal";
import { useOnExport } from "../../helpers/useOnExport";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useNewTable } from "../../../../components/NTable/hook";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useVisible } from "../../../../utils/hooks/useVisible";
import AllocationResourcesTooltip from "./AllocationResourcesTooltip";
import DaysTooltip from "./DaysTooltip";

function Holiday() {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
        settingsExport,
        loadingExport,
        setData,
    } = useSettingsCRUDHook("/holiday");

    const { hasAccess: canAdd } = useAccess("settings.payPolicies.holidays.canCreate");

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-holidays", {
                      count: data?.length,
                  })
                : t("do-you-want-to-delete-holiday"),
        onConfirm: (data) => (Array.isArray(data) ? batchRemove(data) : remove(data)),
        permission: "settings.payPolicies.holidays.canDelete",
    });
    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const {
        visible: isAllocatedResourcesOpen,
        open: openAllocatedResourceTooltip,
        close: closeAllocatedResourceTooltip,
        selected: selectedAllocatedResource,
    } = useVisible();

    const {
        visible: isDaysTooltipOpen,
        open: openDaysTooltip,
        close: closeDaysTooltip,
        selected: selectedDaysTooltip,
    } = useVisible();

    const columns = useColumns({
        openAllocatedResourceTooltip,
        openDaysTooltip,
        edit: useActionWithAccess(goToEdit, "settings.payPolicies.holidays.canEdit"),
        remove: openDeleteModal,
        clone: useActionWithAccess(goToClone, "settings.payPolicies.holidays.canCreate"),
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "holidays",
    });

    const queryFilters = useQueryFilters(table);

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-holiday"),
        create: t("add-holiday-calendar"),
        edit: t("edit-holiday-calendar"),
        clone: t("clone-holiday-calendar"),
    });

    useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/holydays",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys,
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/holiday/import",
        exportUrl: "/export/holydays",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

    return (
        <div className="d-flex h-100">
            <Card className="flex-fill mb-0">
                <Topbar
                    title={t("holiday-calendar")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    // importFile={openImportModal}
                    // onExport={onExport}
                    loading={loading || loadingExport}
                />

                <Card className="mb-auto" style={{ maxHeight: "calc(100% - 156px)" }}>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions rows={table.getState().rowSelection} batchRemove={openDeleteModal} />
                    </Actions>

                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
                {isFormOpen ? (
                    <Form {...formProps} error={error} loading={submitting} close={goToView} />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {isAllocatedResourcesOpen && (
                <AllocationResourcesTooltip
                    isOpen={isAllocatedResourcesOpen}
                    close={closeAllocatedResourceTooltip}
                    data={selectedAllocatedResource}
                />
            )}

            {isDaysTooltipOpen && (
                <DaysTooltip
                    isOpen={isDaysTooltipOpen}
                    close={closeDaysTooltip}
                    data={selectedDaysTooltip}
                />
            )}

            {importModal}

            {deleteModal}

            {unarchiveModal}
        </div>
    );
}

export default Holiday;
