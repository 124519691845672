import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {InputGroup, InputGroupAddon, InputGroupText, Row, Col} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import RangeDateInput from "../../../../components/Inputs/DateInput/Range";
import DateInput from "../../../../components/Inputs/DateInput";
import Select from "../../../../components/Inputs/Select";
import {getEndDateFromStart, getStartDate} from "../../../TimeSheet/components/Topbar/Filters/helpers";
import { useDateFilters } from "../../hooks/useDateFilters";

const modes = [
    { label: "Date", value: "date" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
    { label: "Custom", value: "custom" },
];

function MySchedulerDateFilters() {
    const {t} = useTranslation();

    const { mode, from, to, setFilters } = useDateFilters();

	const onModeChange = useCallback(
		(mode) => {
			let from, to;
            const now = moment();
			switch (mode) {
				case "date": {
					from = now.toISOString(true);
					to = now.toISOString(true);
					break;
				}
				case "week": {
					from = now.startOf("week").toISOString(true);
					to = now.endOf("week").toISOString(true);
					break;
				}
				case "month": {
					from = now.startOf("month").toISOString(true);
					to = now.endOf("month").toISOString(true);
					break;
				}
				case "custom": {
					from = undefined;
					to = undefined;
					break;
                }
                default: {
					from = undefined;
					to = undefined;
					break;
                }
			}
			setFilters({ mode, from, to });
		},
		[setFilters]
	);

	const onDateChange = useCallback((val) => {
        if (!mode) {
            return;
        }
        const value = moment(val);
        const data = {
            from: getStartDate(value, mode),
        };
        if (mode !== "custom") {
            data.to = getEndDateFromStart(value, mode);
        }
        setFilters(data);
    }, [mode, setFilters]);

    const onWeekChange = useCallback(([value]) => {
        onDateChange(value);
    }, [onDateChange]);

    const onRangeChange = useCallback(([from, to]) => {
        setFilters({ from: moment(from), to: moment(to) });
    }, [setFilters]);

    return (
        <Row>
            <Col xs='12' md="auto">
                <Select
                    placeholder={t("Mode")}
                    value={mode}
                    onChange={onModeChange}
                >
                    {modes.map(({ label, value }) => (
                        <Select.Option key={value} value={value}>
                            {t(label)}
                        </Select.Option>
                    ))}
                </Select>
            </Col>

            <Col xs='12' md="auto">
                <InputGroup className='input-group-alternative border shadow-none flex-nowrap'>
                    {mode === "custom" ? (
                        <RangeDateInput
                            startDate={moment(from).toDate()}
                            endDate={moment(to).toDate()}
                            onChange={onRangeChange}
                        />
                    ) : mode === "week" ? (
                        <DateInput
                            startDate={moment(from).toDate()}
                            endDate={moment(to).toDate()}
                            onChange={onWeekChange}
                            selectsRange
                        />
                    ) : (
                        <DateInput
                            value={moment(from).toDate()}
                            showMonthYearPicker={mode === "month"}
                            dateFormat={mode === "month" ? "yyyy-MM" : undefined}
                            onChange={onDateChange}
                        />
                    )}
                    <InputGroupAddon addonType='append'>
                        <InputGroupText>
                            <FontAwesomeIcon icon={faCalendar} />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
        </Row>
    );
}

export default MySchedulerDateFilters;
