import { useCallback, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Card, CardHeader } from "reactstrap";
import Actions from "../../../components/NTable/Actions";
import NTable from "../../../components/NTable";
import { useNewTable } from "../../../components/NTable/hook";
import BulkActions from "./BulkActions";
import { useColumns } from "./columns";
import { useMyTeamRequests } from "../api";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import { formatParams } from "../../../utils/api/format";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useActionWithAccess } from "../../../utils/hooks/access";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useQueryFilters } from "../../Settings/helpers/useQueryFilters";
import { useViewDrawer } from "./useViewDrawer";
import "./styles.scss";

function MyTeam() {
	const { t } = useTranslation();
	const {
		data,
		setData,
		totalItems,
		loading,
		fetch,
		approveOne,
		batchApprove,
		denyOne,
		batchDeny,
	} = useMyTeamRequests();

	const { groups } = useGroups();

	const approve = useCallback(
		async (id) => {
			try {
				await approveOne(id);
				setData((prev) =>
					prev.map((prevReq) =>
						prevReq.id === id
							? {
								...prevReq,
								status: "approved",
							}
							: prevReq,
					),
				);
				toast.dismiss(t("confirming-request"));
				toast.success(t("request-confirmed-successfully"));
			} catch (err) {
				toast.dismiss(t("confirming-request"));
				getErrorMessage(err, t);
			}
		},
		[t, approveOne, setData, getErrorMessage],
	);

	const deny = useCallback(
		async (id) => {
			try {
				await denyOne(id);
				setData((prev) =>
					prev.map((prevReq) =>
						prevReq.id === id
							? {
								...prevReq,
								status: "denied",
							}
							: prevReq,
					),
				);
				toast.dismiss(t("denying-request"));
				toast.success(t("request-denied-successfully"));
			} catch (err) {
				toast.dismiss(t("denying-request"));
				getErrorMessage(err, t);
			}
		},
		[t, setData, denyOne, getErrorMessage],
	);

	const confirmBatch = useCallback(
		async (ids) => {
			const loading = toast.dismiss(t("confirming-request"));
			try {
				const res = await batchApprove(ids);
				if (res) {
					setData((prev) =>
						prev.map((request) => {
							const updated = res.find(
								(u) => request.id === u?.id,
							);
							if (updated) {
								return updated;
							}
							return request;
						}),
					);
					toast.dismiss(loading);
					toast.success(t("requests-are-confirmed"));
				}
			} catch (err) {
				toast.dismiss(loading);
				getErrorMessage(err, t);
			}
		},
		[batchApprove, t, setData, getErrorMessage],
	);

	const denyBatch = useCallback(
		async (ids) => {
			const loading = toast.dismiss(t("denying-requests"));
			try {
				const res = await batchDeny(ids);
				if (res) {
					setData((prev) =>
						prev.map((request) => {
							const updated = res.find(
								(u) => request.id === u?.id,
							);
							if (updated) {
								return updated;
							} else {
								return request;
							}
						}),
					);
					toast.dismiss(loading);
					toast.success(t("request-is-denied"));
				}
			} catch (err) {
				toast.dismiss(loading);
				getErrorMessage(err, t);
			}
		},
		[batchDeny, t, setData, getErrorMessage],
	);

	const { open: openViewDrawer, drawer } = useViewDrawer();

	const columns = useColumns({
		view: useActionWithAccess(openViewDrawer, "request.canViewTeamRequest"),
        approve: useActionWithAccess(approve, "request.canApproveTeamRequest"),
        deny: useActionWithAccess(deny, "request.canDenyTeamRequest"),
    });

	const table = useNewTable({
		data,
		totalItems,
        loading,
		columns,
        initialState: useInitialState(() => {
            const columnVisibility = groups.reduce((total, group) => {
                if (group.allocatedBy) {
                    return total;
                }
                const level = group.level.replace("_", "");
                total[level] = false;
                return total;
            }, {});

            return { columnVisibility };
        }),
        sessionFiltersKey: "teamRequests",
	});

    const queryFilters = useQueryFilters(table);

	useEffect(() => {
		const controller = new AbortController();

		fetch(formatParams(queryFilters), controller);
		return () => controller.abort();
	}, [fetch, queryFilters]);

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<CardHeader className="pb-2 mb-0">
					<h3>{t("team-requests")}</h3>
				</CardHeader>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchDeny={denyBatch}
                            batchApprove={confirmBatch}
                        />
                    </Actions>

                    <NTable table={table} />
				</Card>
			</Card>

			{drawer}
		</div>
	);
}

export default MyTeam;
