import { useCallback } from "react";
import { useBatchUpdateTimeSheets } from "../../api/useBatchUpdateTimeSheets";
import { isAddedTimeSheet } from "../../components/TimeSheetTable/helpers";
import { useDateFilters } from "../../hooks/useDateFilters";

export const useBatchUpdate = ({ refetch, filters }) => {
    const { from, to } = useDateFilters();
    const { submit, loading } = useBatchUpdateTimeSheets();

    const batchUpdate = useCallback(
        ({ addedTimeSheets, updatedTimeSheets }, onSuccess, onFailure) => {
            const data = {
                added: Object
                    .values(addedTimeSheets)
                    .flat()
                    .filter((timeSheet) => timeSheet.isTouched)
                    .filter((timeSheet) => {
                        if (!isAddedTimeSheet(timeSheet)) {
                            return;
                        }
                        return timeSheet.startDate && timeSheet.endDate;
                    }),
                updated: updatedTimeSheets,
                filters: { from, to, filters },
            };
            submit(data, () => {
                refetch()
                onSuccess?.();
            }, onFailure);
        },
        [submit, refetch, from, to, filters],
    );

    return { submit: batchUpdate, loading };
}
