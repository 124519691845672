import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

const TimeSheetRedirect = () => {
    const { hasAccess, authenticating } =
    useAccess("canManageUsers");
    if (authenticating) {
        return null;
    }
    if (hasAccess) {
        return (
            <Navigate to="/time-sheet/team" />
        );
    }
    return <Navigate to="/time-sheet/my" />;
}

export default TimeSheetRedirect;