import { useMemo } from "react";
import { useColumns as useWeeklyColumns } from "../../../../TimeSheet/Weekly/Table/useColumns";
import { useColumns as useTimeSheetTableColumns } from "../../../../TimeSheet/Weekly/Table/TimeSheetTable/hooks/shared/useColumns";
import { useGroups } from "../../../General/OrganisationLevelGroups/context";
import { useAllowedColumns } from "../hooks/useAllowedColumns";
import moment from "moment-timezone";

export const useHiddenColumns = () => {
  const { groups } = useGroups();
  return useMemo(() => [
    "jobs",
    "locations",
    "pb",
    "upto",
    "unpaid",
    ...groups.reduce((total, { allocatedBy, level }) => {
      if (!allocatedBy) {
        return total.concat(level.replace("_", ""));
      }
      return total;
    }, []),
  ], [groups]);
}

export const useColumns = () => {
  const columns = useWeeklyColumns({});
  return useAllowedColumns(columns);
}

export const useTimeSheetHiddenColumns = () => {
  const { groups } = useGroups();
  return useMemo(() => [
    ...groups.reduce((total, { allocatedBy, level }) => {
      if (!allocatedBy) {
        return total.concat(level.replace("_", ""));
      }
      return total;
    }, []),
  ], [groups]);
}

export const useTimeSheetColumns = () => {
  const columns = useTimeSheetTableColumns({
    dates: useMemo(() => {
      const from = moment();
      const to = moment(from).add(1, "week");
      return new Array(
        moment(to).diff(from, "day") + 1
      ).fill(null).map((_, i) => {
        return moment(from).add(i, "day").format("YYYY-MM-DD");
      });
    }, []),
  });
  return useAllowedColumns(columns);
}
