import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

const TimeClockRedirect = () => {
	const { hasAccess, authenticating } = useAccess("canManageUsers");
	if (authenticating) {
		return null;
	}
	if (hasAccess) {
		return <Navigate to="/time-clock/team" />;
	}
	return <Navigate to="/time-clock/my" />;
};

export default TimeClockRedirect;
