import { Navigate } from "react-router-dom";
import { useAccess } from "../../utils/hooks/access";

const RequestRedirect = () => {
	const { hasAccess, authenticating } = useAccess("canManageUsers");
	if (authenticating) {
		return null;
	}
	if (hasAccess) {
		return <Navigate to="/request/team" />;
	}
	return <Navigate to="/request/my" />;
};

export default RequestRedirect;
