export function saveSchedule(event, saveEventOnDb) {
  if (event.eventRecord) {
    // if (event.type === "eventresizeend") {
    //   const resource = event.eventRecord.resource.data;
    //   event.eventRecord.data.name =
    //     event.eventRecord.data.name ||
    //     (this.isUnavailability ||
    //       (this.props.isManager === false &&
    //         this.props.isMySchedule === true)
    //       ? "Unavailability"
    //       : "Regular");
    //   if (
    //     moment(event.eventRecord.data.endDate).isAfter(
    //       moment(event.eventRecord.data.startDate),
    //       "day"
    //     )
    //   ) {
    //     event.eventRecord.data.endDate = moment(
    //       `${moment(event.eventRecord.data.startDate).format(
    //         "YYYY-MM-DD"
    //       )} 23:59:00`
    //     ).toDate();
    //     event.eventRecord.endDate = moment(
    //       `${moment(event.eventRecord.data.startDate).format(
    //         "YYYY-MM-DD"
    //       )} 23:59:00`
    //     ).toDate();
    //   }
    //   if (
    //     breakController.breakRules[resource.locationState] &&
    //     event.eventRecord.data.name === SCHEDULE_MAPPED_EVENTS.REGULAR &&
    //     resource.salaryHourly === "H"
    //   ) {
    //     let sd = moment(event.eventRecord.data.startDate);
    //     const ed = moment(event.eventRecord.data.endDate);
    //     event.eventRecord = breakController.generateBreaksByRules(
    //       event.eventRecord,
    //       sd,
    //       ed,
    //       breakController.breakRules[resource.locationState]
    //     );
    //   } else {
    //     event.eventRecord = breakController.moveBreaks(event.eventRecord);
    //   }
    // }
    return saveEventOnDb(event.eventRecord, event.source);
  }
} 