import { useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {
	getPrimaryActiveResource,
	renderUserName,
} from "../../../../../../utils/helpers/user";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";

function Employee({ user, active, onClick: parentOnClick, disabled }) {
	const job = useMemo(
		() => getPrimaryActiveResource(user?.jobs || []),
		[user?.jobs]
	);

	const onClick = useCallback(
		() => parentOnClick(user),
		[parentOnClick, user]
	);

	return (
		<div className="d-flex justify-content-between align-items-center p-3 enroll-employees mb-2">
			<div className="d-flex align-items-center">
				<img
					alt=""
					className="avatar rounded-circle mr-2 border-none"
					src=""
				/>

				<div>
					<h5 className="text-xs font-weight-600">
						{renderUserName(user)}
					</h5>

					{job && (
						<h5 className="font-weight-300 text-xs">
							{renderSettingsResourceLabel(job)}
						</h5>
					)}
				</div>
			</div>

			<p
				className="font-weight-bolder mb-0"
				onClick={disabled ? undefined : onClick}
			>
				{!active ? (
					<FontAwesomeIcon
						icon={faPlus}
						className="text-primary cursor-pointer"
					/>
				) : (
					<FontAwesomeIcon
						icon={faMinus}
						className="text-danger cursor-pointer"
					/>
				)}
			</p>
		</div>
	);
}

export default Employee;
