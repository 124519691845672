import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Form from "rc-field-form";
import DateInput from "../../../components/Inputs/DateInput";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Text from "../../../components/Inputs/Text";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../utils/helpers/settings";
import { applyTimezone, dateTimeParseZone } from "../../../utils/helpers/date";
import { useCompany } from "../../../utils/hooks/company";
import { firstToUpper } from "../../../utils/helpers/string";

function TimeOffForm({ mode, close, values, error, loading, create, update }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();

	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);

	const onFinish = useCallback(
		(formValues) => {
			let data = {
				...formValues,
				to: applyTimezone(formValues?.to, timezone, true),
				from: applyTimezone(formValues?.from, timezone, true),
			};

			if (mode === "edit" && values) {
				update(values.id, { id: values.id, ...data }, close);
			} else {
				create(data, close);
			}
		},
		[close, update, create, values, mode, timezone],
	);

	useEffect(() => {
		form.setFieldsValue({
			from: values?.from && dateTimeParseZone(values?.from, "date"),
			to: values?.to && dateTimeParseZone(values?.to, "date"),
			data: values?.data,
			available: values?.available,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(["data"], error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="data"
				label={t("type")}
				rules={[
					{
						required: true,
						message: t("required-type"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/timeoff?status[]=active"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("type")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
				/>
			</Field>

			<Field
				name="from"
				label={t("from")}
				rules={[
					{
						required: true,
						message: t("required-from"),
					},
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={t("from")} />
			</Field>

			<Field
				name="to"
				dependencies={["form"]}
				label={firstToUpper(t("to"))}
				rules={[
					{
						required: true,
						message: t("required-to"),
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							const from = getFieldValue("from");

							if (from && value) {
								if (
									moment(value).isBefore(moment(from), "date")
								) {
									return Promise.reject(
										new Error(
											t("to-bigger-than-from"),
										),
									);
								}
							}

							return Promise.resolve();
						},
					}),
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={firstToUpper(t("to"))} />
			</Field>

			<Field
				name="available"
				label={t("available")}
				rules={[
					{
						required: true,
						message: t("required-available"),
					},
				]}
			>
				<Text type="number" placeholder={t("available")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={loading}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={loading}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default TimeOffForm;
