import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useClockInCustomFields =() => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const { authPost, authGet } = useApi();

	const fetch = useCallback(async (params, controller) => {
		setLoading(true);
		try {
			const response = await authGet("/custom-field/clock-in", {
				params,
				signal: controller?.signal
			});

			if(response) {
				setData(response?.result);
			}
		} catch(err) {
			getErrorMessage(err, t)
		} finally {
			setLoading(false);
		}
	}, [setLoading, authGet, setData, t]);


	const create = useCallback(async(data, onSuccess) => {
		setLoading(true);
		try {
			const response = await authPost("/custom-field/clock-in", {
				data
			});

			if(response) {
				onSuccess?.(response);
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [setLoading, authPost, t]);

	return { fetch, create, loading, data, setData };
}