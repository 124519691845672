export const compareVer = (verA, verB) => {
  const versA = verA.split(/\./g);
  const versB = verB.split(/\./g);
  while (versA.length || versB.length) {
    const a = Number(versA.shift());
    const b = Number(versB.shift());

    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};
