import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Select from "../Select";

function ManageAbilitySelect(props) {
	const { t } = useTranslation();

	return (
		<Select {...props}>
			<Option value="direct-users">{t("direct-users")}</Option>
			<Option value="manage-users">{t("manage-users")}</Option>
			<Option value="direct-users-and-organisation-level">
				{t("direct-users-and-organisation-level")}
			</Option>

			<Option value="direct-org-level-and-users">
				{t("direct-org-level-and-users")}
			</Option>

			<Option value="direct-users-and-location">
				{t("direct-users-and-location")}
			</Option>

			<Option value="direct-users-and-organisation-level-and-location">
				{t("direct-users-and-organisation-level-and-location")}
			</Option>

			<Option value="direct-company">
				{t("direct-company")}
			</Option>

			<Option value="direct-users-and-pay-groups">
				{t("direct-users-and-pay-groups")}
			</Option>

			<Option value="direct-users-and-calculation-group">
				{t("direct-users-and-calculation-group")}
			</Option>

			<Option value="direct-users-and-organisation-level-and-project">
				{t("direct-users-and-organisation-level-and-project")}
			</Option>

			<Option value="direct-project">
				{t("direct-project")}
			</Option>
		</Select>
	);
}

export default ManageAbilitySelect;
