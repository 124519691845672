import { useMemo } from "react";
import { useFilters as useTimeSheetFilters } from "../../../../TimeSheet/Home/Actions/useFilters";
import { useEmployeeNumberFilter } from "./useEmployeeNumberFilter";
import { useEmployeeTypeFilter } from "./useEmployeeTypeFilter";
import { useFirstNameFilter } from "./useFirstNameFilter";
import { useLastNameFilter } from "./useLastNameFilter";
import { usePayGroupFilter } from "./usePayGroupFilter";
import { useProjectFilter } from "./useProjectFilter";
import { useUnionFilter } from "./useUnionFilter";
import { useCalculationGroupFilter } from "./useCalculationgroupFilter";
import { useUserGroupsFilter } from "./useUserGroupsFilter";
import { useStatusFilter } from "../../../Home/Filters/useStatusFilter";

export const useFilters = () => {
	const employeeNumberFilter = useEmployeeNumberFilter();
	const firstNameFilter = useFirstNameFilter();
	const lastNameFilter = useLastNameFilter();
	const timeSheetFilters = useTimeSheetFilters();
	const payGroupFilter = usePayGroupFilter();
	const employeeTypeFilter = useEmployeeTypeFilter();
	const projectFilter = useProjectFilter();
	const unionFilter = useUnionFilter();
	const calculationGroupFilter = useCalculationGroupFilter();
	const userGroupsFilter = useUserGroupsFilter();
	const statusFilter = useStatusFilter();

	return useMemo(
		() => [
			employeeNumberFilter,
			firstNameFilter,
			lastNameFilter,
			payGroupFilter,
			employeeTypeFilter,
			projectFilter,
			unionFilter,
			calculationGroupFilter,
			userGroupsFilter,
			...(timeSheetFilters || [])?.filter((filter) => filter?.id !== "status"),
			statusFilter
		],
		[
			userGroupsFilter,
			calculationGroupFilter,
			employeeNumberFilter,
			payGroupFilter,
			firstNameFilter,
			lastNameFilter,
			timeSheetFilters,
			employeeTypeFilter,
			projectFilter,
			unionFilter,
			statusFilter
		],
	);
};
