import { useCallback } from "react";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { useCustomFields } from "../../../../../hooks/useCustomFields";
import get from "lodash/get";

export const useGetDependencyRowId = () => {
  const { allocatedGroups } = useGroups();
  const { data: customFields } = useCustomFields();

  return useCallback((item) => {
    const fields = [
      "payCode.code",
      "job.code",
      "location.code",
      ...allocatedGroups.map((group) => {
        const level = group.level.replace("_", "");
        return `${level}.code`;
      }),
      ...customFields.map((field) => {
        return `customFields.${field.code}`;
      })
    ];
    return fields.map((getter) => get(item, getter)).join("_");;
  }, [allocatedGroups, customFields]);
};