import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { firstToUpper } from "../helpers/string";
import Select from "../../components/Inputs/Select";

const columnHelper = createColumnHelper();

export const useCommonColumns = ({ edit, remove, clone } = {}) => {
    const { t } = useTranslation();

    return useMemo(
        () => ({
            code: columnHelper.accessor("code", {
                enableColumnFilter: true,
                header: t("code"),
                cell: (info) => {
                    if (!edit) {
                        return info.getValue();
                    }
                    return (
                        <b
                            className="text-info cursor-pointer"
                            onClick={() => edit(info.row.original.id)}
                            title={t("Edit")}
                        >
                            {info.getValue()}
                        </b>
                    );
                },
            }),
            description: columnHelper.accessor("description", {
                enableColumnFilter: true,
                header: t("description"),
            }),
            status: columnHelper.accessor("status", {
                enableColumnFilter: true,
                header: t("status"),
                cell: (info) => firstToUpper(info.getValue()),
                filterType: "select",
                Filter: (
                    <Select className="form-control-sm p-0" showSearch>
                        {["active", "inactive", "deleted"].map((option) => (
                            <Select.Option key={option} value={option}>
                                {t(option)}
                            </Select.Option>
                        ))}
                    </Select>
                ),
            }),
            actions: (edit || remove || clone)
                ? columnHelper.display({
                    id: "actions",
                    enableHiding: false,
                    enableColumnFilter: false,
                    header: (
                        <div className="text-right">
                            {t("actions")}
                        </div>
                    ),
                    cell: (info) => {
                        const { id, company, isDefault } = info.row.original;

                        return (
                            <div className="actions text-primary text-right">
                                {company && !isDefault && edit && (
                                    <span
                                        className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                        onClick={() => edit(id)}
                                        title={t("Edit")}
                                    >
                                        <i className="fas fa-pen" />
                                    </span>
                                )}

                                {company && !isDefault && remove && (
                                    <span
                                        className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                        onClick={() => remove(id)}
                                        title={t("Delete")}
                                    >
                                        <i className="fas fa-trash" />
                                    </span>
                                )}

                                {clone && (
                                    <span
                                        className="btn-inner--icon icon-sm cursor-pointer"
                                        onClick={() => clone(id)}
                                        title={t("Clone")}
                                    >
                                        <i className="ni ni-single-copy-04" />
                                    </span>
                                )}
                            </div>
                        );
                    },
                })
                : null,
        }),
        [t, edit, remove, clone],
    );
};
