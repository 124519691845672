import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const usePayGroupColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("pay-group"),
        accessor: "payGroup",
        Cell: ({ value }) => value?.description,
        disableSortBy: true
    }), [t]);
};
