import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import {
	faCircleXmark,
	faCircleCheck,
	faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { noop } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form, { List } from "rc-field-form";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useEndAndEditshift, useGetIpAddress } from "../api";
import { useLastShift } from "../store";
import Clocks from "./Clocks";
import Break from "./Break";
import { useUser } from "../../../../utils/hooks/user";
import { useGeolocated } from "../../../../utils/hooks/useGeolocated";
import { getAddress } from "../../../../utils/helpers/geocode";
import PaidHours from "./PaidHours";
import {
	dateTimeFormat,
	dateTimeParseZone,
} from "../../../../utils/helpers/date";

const EditShift = ({ close, setRefetchTimesheet = noop, attestation, setHasAttestation }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const user = useUser();

	const isGeofenceRequired = user?.calculationGroup?.geofenceLocation;

	const {
		coords,
		isGeolocationAvailable,
		isGeolocationEnabled,
		positionError,
	} = useGeolocated({
		positionOptions: {
			enableHighAccuracy: false,
		},
		userDecisionTimeout: 5000,
	});

	const { ip, getIp } = useGetIpAddress();

	const { submit, loading, error } = useEndAndEditshift();
	const shift = useLastShift();

	const onFinish = useCallback(
		async (values) => {
			const data = { ...values };
			if (ip) {
				data.ip = ip;
			}

			if (isGeofenceRequired && isGeolocationEnabled) {
				if (!coords?.latitude || !coords?.longitude) {
					toast.error(t("required-geofence"));
					return;
				}
				const address = await getAddress({
					lat: coords?.latitude,
					lng: coords?.longitude,
				});
				data.geofence = {
					lat: coords?.latitude,
					lng: coords?.longitude,
					address,
				};
			}

			submit(data, () => {
				if (attestation) {
					setHasAttestation(true);
				} else {
					close();
					setRefetchTimesheet((prev) => prev + 1);
				}
			});
		},
		[
			submit,
			isGeofenceRequired,
			isGeolocationEnabled,
			coords,
			close,
			setRefetchTimesheet,
			attestation,
			setHasAttestation,
			ip,
			t,
		],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);

		return () => controller.abort();
	}, [getIp]);

	useEffect(() => {
		const breaks = [];
		shift?.clocks?.forEach(({ mode, type, time }, i, clocks) => {
			if (mode !== "START" || type !== "BREAK") {
				return;
			}
			const next = clocks[i + 1];

			breaks.push({
				start: dateTimeParseZone(time, "time"),
				end: dateTimeParseZone(next.time, "time"),
			});
		});

		form.setFieldsValue({
			breaks,
			startDate: dateTimeParseZone(shift?.startDate, "time"),
			endDate: dateTimeParseZone(shift?.endDate, "time"),
		});
	}, [form, shift]);

	useEffect(() => {
		let _error;
		if (typeof error === "object" && error !== null) {
			_error = {
				...error,
				startDate: error.start,
				endDate: error.end,
			};
		} else {
			_error = error;
		}

		const fieldErrors = generateErrorsConfigForForm(
			["startDate", "endDate", "breaks"],
			_error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	useEffect(() => {
		if (!isGeolocationAvailable) {
			toast.error(t("browser-doesnt-support-geolocation"));
		} else if (!isGeolocationEnabled) {
			toast.error(t("enable-location"));
		} else if (positionError && typeof positionError === "object") {
			toast.error(positionError?.message);
		}
	}, [positionError, isGeolocationEnabled, isGeolocationAvailable, t]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Row className="mb-3">
				<Col
					className="d-flex flex-column justify-content-between"
					md="6"
				>
					<div>
						<div className="d-flex justify-content-between pl-1">
							<h5>{t("shift-date")}</h5>
							<small>
								{shift?.startDate &&
									dateTimeFormat(
										shift?.startDate,
										"dddd, MMMM DD, yyyy",
									)}
							</small>
						</div>

						<hr className="p-0 my-2" />

						<div
							className="px-3"
							style={{ maxHeight: 360, overflowY: "auto" }}
						>
							<Clocks timezone={shift?.location?.timezoneValue} />

							<div className="my-3 pb-2">
								<List name="breaks">
									{(fields, { add, remove }) => (
										<div>
											<div className="mb-4">
												<Button
													className="btn-icon mt-2 text-info p-0 shadow-none"
													type="button"
													color="white"
													onClick={() => add({}, 0)}
												>
													<FontAwesomeIcon
														icon={faCirclePlus}
														className="mr-1"
													/>

													{t("add-another-break")}
												</Button>
											</div>

											{fields.map(({ name, key }) => (
												<div
													className="d-flex mb-4"
													key={key}
												>
													<Break
														name={name}
														add={() =>
															add({}, name + 1)
														}
														remove={() =>
															remove(name)
														}
													/>
												</div>
											))}
										</div>
									)}
								</List>
							</div>
						</div>
					</div>

					<div className="d-flex justify-content-between border-top pt-3">
						<h5 className="text-primary">{t("total-paid-hours")}</h5>
						<h5 className="text-primary">
							<PaidHours />
						</h5>
					</div>
				</Col>

				<Col md="6">
					<Field
						name="note"
						label={t("attach-request-note")}
						className="mb-1"
					>
						<Text
							resize="none"
							rows="16"
							type="textarea"
							className="h-100"
						/>
					</Field>
					<footer className="note-border  p-2 border-top-0 text-sm">
						*
						{t(
							"requests-send-for-manager-approval",
						)}
					</footer>
				</Col>
			</Row>

			<div className="border-top text-center align-items-center justify-content-center pt-3">
				<Button
					className="btn-icon text-muted"
					type="button"
					color="white"
					disabled={loading}
					onClick={close}
				>
					<span className="btn-inner--icon mr-1 ">
						<FontAwesomeIcon icon={faCircleXmark} />
					</span>
					<span className="btn-inner--text ">{t("cancel")}</span>
				</Button>

				<Button
					className="btn-icon approve-btn"
					type="submit"
					color="white"
					loading={loading || !isGeolocationEnabled}
					disabled={!isGeolocationEnabled}
				>
					<span className="btn-inner--icon mr-1 ">
						<FontAwesomeIcon icon={faCircleCheck} />
					</span>
					<span className="btn-inner--text">{t("approve")}</span>
				</Button>
			</div>
		</Form>
	);
};

export default EditShift;
