import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, InputGroupAddon, InputGroupText, Row, Col } from "reactstrap";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import DateInput from "../../../../components/Inputs/DateInput";
import { useDateFilters } from "./useDateFilters";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../utils/helpers/settings";

const DateAndPayGroupFilters = ({ setHasChangedDateFilter }) => {
	const { t } = useTranslation();

	const { mode, from, to, payGroup, payGroupPeriod, setFilters } =
		useDateFilters();

	const onWeekChange = useCallback(
		([value]) => {
			if (!mode) {
				return;
			}

			setFilters({
				from: moment(value).startOf("week").toISOString(true),
				to: moment(value).endOf("week").toISOString(true),
			});

			setHasChangedDateFilter(true);
		},
		[setHasChangedDateFilter, setFilters, mode],
	);

	const onPayGroupChange = useCallback(
		({ code }) => {
			setFilters({ payGroup: code });
		},
		[setFilters],
	);

	const onPeriodChange = useCallback(
		(value) => {
			setFilters({ payGroupPeriod: value });
		},
		[setFilters],
	);

	const onModeChange = useCallback(
		(mode) => {
			let from;
			let to;
			let payGroup;
			let payGroupPeriod;
			switch (mode) {
				case "week": {
					from = moment().startOf("week").toISOString(true);
					to = moment().endOf("week").toISOString(true);
					break;
				}
				case "pay-group": {
					from = undefined;
					to = undefined;
					payGroup = undefined;
					payGroupPeriod = "current";
					break;
				}
				default: {
					from = undefined;
					to = undefined;
					break;
				}
			}
			setFilters({ mode, from, to, payGroup, payGroupPeriod });
			setHasChangedDateFilter(true);
		},
		[setFilters, setHasChangedDateFilter],
	);

	return (
		<Row>
			<Col xs="12" md="auto">
				<Select
					placeholder={t("mode")}
					value={mode}
					onChange={onModeChange}
					className="mr-1"
					showSearch
				>
					<Select.Option value="week">{t("week")}</Select.Option>
					<Select.Option value="pay-group">
						{t("pay-group")}
					</Select.Option>
				</Select>
			</Col>

			<Col xs="12" md="auto">
				{mode === "pay-group" ? (
					<div className="d-flex w-100">
						<ResourceSelect
							resourcePath="/pay-group?pagination=off"
							renderLabel={renderSettingsResourceLabel}
							hasSearch
							getSearchFilters={getSettingsResourceSearchFilters}
							onChange={onPayGroupChange}
							value={payGroup}
						/>

						<Select
							className="ml-1"
							value={payGroupPeriod}
							onChange={onPeriodChange}
						>
							<Select.Option value="past">{t("past")}</Select.Option>
							<Select.Option value="current">
								{t("current")}
							</Select.Option>
							<Select.Option value="next">{t("next")}</Select.Option>
						</Select>
					</div>
				) : (
					<InputGroup className="input-group-alternative border shadow-none flex-nowrap ml-1">
						<DateInput
							startDate={moment(from).toDate()}
							endDate={moment(to).toDate()}
							onChange={onWeekChange}
							selectsRange
						/>

						<InputGroupAddon addonType="append">
							<InputGroupText>
								<FontAwesomeIcon icon={faCalendar} />
							</InputGroupText>
						</InputGroupAddon>
					</InputGroup>
				)}
			</Col>
		</Row>
	);
};

export default DateAndPayGroupFilters;
