import {useMemo} from "react";

export const useTotals = (data) => {
	return useMemo(() => {
        return data?.reduce((total, overviewItem) => ({
            totalDuration: overviewItem.hourType === "unpaid"
                ? total.totalDuration
                : total.totalDuration + overviewItem.duration,
            totalPayment: overviewItem.hourType === "unpaid"
                ? total.totalPayment
                : total.totalPayment + overviewItem.total,
        }), { totalDuration: 0, totalPayment: 0 });
    }, [data]);
}
