import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import JobPay from "../../features/Profile/JobPay";
import Personal from "../../features/Profile/Personal";
import TimeOff from "../../features/Profile/TimeOff";
import Documents from "../../features/Profile/Documents";
import Notes from "../../features/Profile/Notes";
import Settings from "../../features/Profile/Settings";
import Job from "../../features/Profile/History/Job";
import History from "../../features/Profile/History";

export const useProfileRoute = (permissions) => {
    const profileUrl = useMemo(() => {
        if (permissions?.canView) {
            return "job-pay";
        } else if (permissions?.manageTimeOff) {
            return "time-off";
        } else if (permissions?.manageDocuments) {
            return "documents";
        } else if (permissions?.manageNote || permissions?.manageAllNotes) {
            return "notes";
        } else if (permissions?.viewHistory && permissions?.viewJobHistory) {
            return "history/job"
        } else {
            return "/redirect"
        }
    }, [permissions]);

    const historyUrl = useMemo(() => {
        if (permissions?.viewJobHistory) {
            return "job";
        } else {
            return "/redirect"
        }
    }, [permissions]);

    return useMemo(() => [
        { index: true, element: <Navigate to={profileUrl} />, access: permissions?.canView  },
        { path: "job-pay", element: <JobPay />, access: permissions?.canView },
        { path: "personal", element: <Personal />, access: permissions?.canView},
        { path: "settings", element: <Settings />, access: permissions?.canView },
        { path: "time-off", element: <TimeOff />, access: permissions?.manageTimeOff },
        { path: "documents", element: <Documents />, access: permissions?.manageDocuments },
        { path: "notes", element: <Notes />, access: permissions?.manageNote || permissions?.manageAllNotes },
        { 
            path: "history",
            element: <History />,
            access: permissions?.viewHistory,
            children: [
                { index: true, element: <Navigate to={historyUrl} />, access: permissions?.viewJobHistory },
                { path: "job", element: <Job />,  access: permissions?.viewJobHistory }
            ]
        }
    ], [permissions, profileUrl, historyUrl]);
}
