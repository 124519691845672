import { useEffect, useMemo } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import classNames from "classnames";
import { useCompany, useCompanyDateFormat } from "../../../utils/hooks/company";
import { useUser } from "../../../utils/hooks/user";
import sq from "date-fns/locale/sq";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import { weekdays } from "../WeekdaySelect";
import "./style.scss";

function DateInput({
	className,
	calendarClassName,
	hasError,
	value,
	dateFormat,
	...props
}) {
	const companyDateFormat = useCompanyDateFormat();

	const company = useCompany();
	const user = useUser();

	const language = localStorage.getItem("i18nextLng");

	const calendarStartDay = useMemo(() => {
		const startDay = user?.calculationGroup?.startDay
			? user?.calculationGroup?.startDay
			: company?.settings?.startDay
			   ? company?.settings?.startDay
			   : "sunday";

		// switch (startDay) {
		// 	case "Monday":
		// 	case "monday":
		// 		return 1;
		// 	case "Tuesday":
		// 	case "tuesday":
		// 		return 2;
		// 	case "Wednesday":
		// 	case "wednesday":
		// 		return 3;
		// 	case "Thursday":
		// 	case "thursday":
		// 		return 4;
		// 	case "Friday":
		// 	case "friday":
		// 		return 5;
		// 	case "Saturday":
		// 	case "saturday":
		// 		return 6;
		// 	case "Sunday":
		// 	case "sunday":
		// 	default:
		// 		return 0;
		// }
		return weekdays.indexOf((startDay || "sunday")?.toLowerCase())
	}, [company?.settings?.startDay, user?.calculationGroup?.startDay]);

	const format = useMemo(() => {
		return companyDateFormat !== null || !dateFormat
			? companyDateFormat?.replace("YYYY", "yyyy")?.replace("DD", "dd")
			: dateFormat;
	}, [companyDateFormat, dateFormat]);

	const lng = useMemo(() => {
		if (language === "sq") {
			return sq;
		} else if (language === "es") {
			return es;
		} else {
			return en
		}
	}, [language])

	useEffect(() => {
		registerLocale(language, lng);
	}, [language, lng])

	return (
		<DatePicker
			dateFormat={format}
			showFourColumnMonthYearPicker
			calendarStartDay={calendarStartDay}
			locale={lng}
			{...props}
			className={classNames(
				className,
				"form-control",
				hasError && "invalid-date-time-input",
			)}
			calendarClassName={classNames(
				calendarClassName,
				"custom-date-picker",
			)}
			selected={value}
			portalId="datepicker-portal"
		/>
	);
}

export default DateInput;
