import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import Field from "../../components/Field";
import { useQrCodeLogin } from "./api";
import { generateErrorsConfigForForm } from "../../utils/helpers/errors";
import { getSubDomainFromDomain } from "../../utils/helpers/url";
import { useQrCodeClockIn } from "./context";

const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function LogInForm() {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { domain } = useQrCodeClockIn();
	const { id } = useParams();
	const navigate = useNavigate();

	const [selectedField, setSelectedField] = useState("employeeNumber");
	const [employeeNumber, setEmployeeNumber] = useState("");
	const [ssn, setSsn] = useState("");
	const [errorFields, setErrorFields] = useState();

	const { loading, login, error } = useQrCodeLogin();

	const onButtonClick = useCallback(
		(value) => {
			if (selectedField === "ssn") {
				if (errorFields?.password) {
					setErrorFields({
						password: undefined,
						username: errorFields?.username,
					});
				}
				if (ssn.length < 4) {
					setSsn((prev) => prev.concat(value));
				}
			} else {
				if (errorFields?.username) {
					setErrorFields({
						username: undefined,
						password: errorFields?.password,
					});
				}
				setEmployeeNumber((prev) => prev.concat(value));
				if (employeeNumber.length === 5) {
					setSelectedField("ssn");
				}
			}
		},
		[
			errorFields,
			selectedField,
			setSelectedField,
			setErrorFields,
			setSsn,
			ssn,
			employeeNumber,
		],
	);

	const onDeleteClick = useCallback(() => {
		if (selectedField === "ssn") {
			setSsn((prev) => prev.slice(0, -1));
		} else {
			setEmployeeNumber((prev) => prev.slice(0, -1));
		}
	}, [selectedField, setSsn, setEmployeeNumber]);

	const onFinish = useCallback(
		(values) => {
			const subDomain = getSubDomainFromDomain(domain);

			const data = {
				...values,
				rememberMe: true,
				uniqueKey: subDomain,
			};

			login(data, () => navigate(`/qr-code-clock-in/${id}/clocks`));
		},
		[login, domain, navigate, id],
	);

	const onFinishFailed = useCallback(
		(e) => {
			if (
				e?.errorFields[0]?.errors?.length > 0 ||
				e?.errorFields[1]?.errors?.length > 0
			) {
				setErrorFields({
					password: e?.errorFields[1]?.name[0],
					username: e?.errorFields[0]?.name[0],
				});
			}
		},
		[setErrorFields],
	);

	useEffect(() => {
		const subDomain = getSubDomainFromDomain(domain);

		const data = {
			username: employeeNumber,
			password: ssn,
			rememberMe: true,
			uniqueKey: subDomain,
		};

		if (ssn.length === 4 && employeeNumber.length === 6) {
			login(
				data,
				() => navigate(`/qr-code-clock-in/${id}/clocks`),
				() => {
					setSsn("");
					setEmployeeNumber("");
				},
			);
		}
	}, [
		ssn,
		domain,
		employeeNumber,
		setSelectedField,
		setSsn,
		setEmployeeNumber,
		login,
		navigate,
		id,
	]);

	useEffect(() => {
		form.setFieldsValue({
			username: employeeNumber,
			password: ssn,
		});
	}, [ssn, employeeNumber, form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["username", "password"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	const employeeNumberClassName = useMemo(() => {
		if (selectedField === "employeeNumber") {
			if (employeeNumber === "") {
				return "border-info input-with-placeholder";
			} else {
				return "border-info active-input";
			}
		} else if (employeeNumber !== "") {
			return "input-with-value";
		} else {
			return "input";
		}
	}, [selectedField, employeeNumber]);

	const ssnClassName = useMemo(() => {
		if (selectedField === "ssn") {
			if (ssn === "") {
				return "border-info input-with-placeholder";
			} else {
				return "border-info active-input";
			}
		} else if (ssn !== "") {
			return "input-with-value";
		} else {
			return "input";
		}
	}, [selectedField, ssn]);

	return (
		<div
			className="position-relative h-100 align-items-center d-flex flex-column justify-content-center"
			style={{
				backgroundImage: `linear-gradient(87deg, rgba(255,255,255, 0.52), rgba(255,255,255, 0.5)), url(/assets/images/bg_2.webp)`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center center",
			}}
		>
			<Form
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				className="qr-login"
				form={form}
			>
				<div
					className="row align-items-center justify-content-center"
					style={{ width: "250px" }}
				>
					<div className="col-12">
						<Field
							name="username"
							rules={[
								{
									required: true,
									message: t("required-employee-number"),
								},
							]}
						>
							<div
								onClick={() =>
									setSelectedField("employeeNumber")
								}
								className={classNames(
									employeeNumberClassName,
									errorFields?.username &&
									"border-invalid-feedback",
								)}
							>
								{employeeNumber
									? employeeNumber
									: t("enter-employee-number")}
							</div>
						</Field>
					</div>

					<div className="col-12">
						<Field
							name="password"
							rules={[
								{
									required: true,
									message: t("required-ssn"),
								},
								{
									message: t(
										"ssn-length",
									),
									min: 4,
									max: 4,
								},
							]}
						>
							<div
								onClick={() => setSelectedField("ssn")}
								className={classNames(
									ssnClassName,
									errorFields?.password &&
									"border-invalid-feedback",
								)}
							>
								{ssn
									? ssn
									: t("enter-ssn-number")}
							</div>
						</Field>
					</div>
				</div>

				<div
					className="row align-items-center justify-content-center"
					style={{ width: "250px" }}
				>
					{buttons.map((button, i) => (
						<div className="col-4" key={`button-${i}`}>
							<Button
								type="button"
								className="btn"
								onClick={() => onButtonClick(button)}
							>
								{button}
							</Button>
						</div>
					))}

					<div className="col-4">
						<Button
							className="btn text-xl text-white bg-danger px-0"
							type="button"
							onClick={onDeleteClick}
						>
							{t("del")}
						</Button>
					</div>

					<div className="col-4">
						<Button
							type="button"
							className="btn"
							onClick={() => onButtonClick(0)}
							disabled={loading}
						>
							0
						</Button>
					</div>

					<div className="col-4">
						<Button
							type="submit"
							className="btn text-xl text-white bg-info text-uppercase"
							loading={loading}
						>
							{t("ok")}
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
}
export default LogInForm;
