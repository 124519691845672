import { CardHeader, Row, Col } from "reactstrap";

function Topbar({ title, children }) {
    return (
        <CardHeader className='border-0'>
            <Row>
                <Col xs='12' md='6' className="d-flex align-items-center">
                    <h3 className='mb-0'>{title}</h3>
                </Col>
                <Col
                    className='text-right d-flex flex-wrap justify-content-md-end align-items-center mt-3 mt-md-0'
                    xs='12'
                    md='6'
                >
                    {children}
                </Col>
            </Row>
        </CardHeader>
    );
}

export default Topbar;
