import { isNumber } from "lodash";
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useGetRateConfigMax } from "./rate";

const appliesForValidation = (row) => {
  return row.payCode && row.payCode?.hourType === "amount";
}

export const useTotalValidations = () => {
  const { t } = useTranslation();
  const getMax = useGetRateConfigMax();
 
  const isRequired = useCallback((_, row) => {
    if (!appliesForValidation(row)) {
      return null;
    }
    if (!isNumber(row.rate)) {
      return t("Total is required");
    }
    return null;
  }, [t]);

  const maxValue = useCallback((_, row) => {
    if (!appliesForValidation(row)) {
      return null;
    }
    const jobRate = row.job?.hourlyRate;
    const maxConfig = getMax(row.user.roles, "maxAmount");
    let max = isNumber(maxConfig) ? maxConfig : undefined;
    if (isNumber(max) && isNumber(jobRate) && jobRate > max) {
      max = jobRate;
    }

    if (!isNumber(max) || !isNumber(row.rate)) {
      return null;
    }

    if (row.rate > max) {
      if (isNumber(jobRate)) {
        return t("timesheet-amount-smaller-than-max-amount-or-job-rate", { rate: max, jobRate });
      }
      return t("timesheet-amount-smaller-than-max-amount", { rate: jobRate });
    }
    return null;
  }, [getMax]);

  return useMemo(() => [isRequired, maxValue], [isRequired, maxValue])
}
