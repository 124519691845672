export const formatSubmittedData = (company, values) => {
	return {
		...company,
		settings: {
			...company.settings,
			countries: company?.settings?.countries?.map((country) => country.id),	
			startShiftScheduleTime: values?.startShiftScheduleTime,
			endShiftScheduleTime: values?.endShiftScheduleTime,
		},
	};
};
