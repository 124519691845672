import { Card, Table as BaseTable } from "reactstrap";
import Loading from "../../../../components/Loaders/Loading";
import { useTableContext } from "../../../../components/Table/context";
import "./_style.scss";
import classNames from "classnames";

function Table({ loading, children }) {
	const {
		getTableProps,
		headerGroups,
		getTableBodyProps,
		rows,
		prepareRow,
	} = useTableContext();

	return (
		<Card className="my-timesheet-wrapper-table mb-0 position-static h-100">
			<BaseTable
				className="align-items-center table-flush"
				hover
				responsive
				{...getTableProps()}
			>
				<thead className="thead-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.headCustomProps)}
									style={{ color: "#000000b5" }}
								>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{(loading ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									<Loading />
								</div>
							</td>
						</tr>
					) : rows.map((row) => {
						prepareRow(row);

						const { misspunch, unapproved } = row.original;
						let className = classNames(
							"expanded",
							misspunch && "misspunch",
							unapproved && "unapproved",
						);

						return (
							<>
								<tr {...row.getRowProps({ className })}>
									{row.cells.map((cell) => {
										const props = cell.column.getCellCustomProps?.(cell.row.original) || {};
										if (props.rowSpan === 0) {
											return null;
										}
										return (
											<td {...cell.getCellProps(props)}>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>

								{row.isExpanded && (
									<tr>
										<td className="timesheet-table-container" colSpan={1000}>
											{children}
										</td>
									</tr>
								)}
							</>
						);
					}))}
				</tbody>
			</BaseTable>
		</Card>
	);
}

export default Table;
