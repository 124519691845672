import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { Card, CardHeader, Row, Col, CardBody } from "reactstrap";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Text from "../../../components/Inputs/Text";
import Switch from "../../../components/Inputs/Switch";
import Password from "../../../components/Inputs/Password";
import { useUltiproApi } from "./api";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { useAccess } from "../../../utils/hooks/access";
import { useCompany } from "../../../utils/hooks/company";

function UltiPro() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const { hasAccess: canEdit } = useAccess(
		"settings.integration.ultipro.canEdit",
	);

	const { loading, error, submit } = useUltiproApi();

	const onFinish = useCallback(
		(values) => {
			submit({
				...values,
				active: values.active,
			});
		},
		[submit],
	);

	useEffect(() => {
		form.setFieldsValue({
			active: company?.ultipro?.active,
			domain: company?.ultipro?.domain,
			clientId: company?.ultipro?.clientId,
			username: company?.ultipro?.username,
			password: company?.ultipro?.password,
		});
	}, [form, company?.ultipro]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["password", "domain", "username", "clientId"],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Form form={form} onFinish={onFinish}>
					<CardHeader>
						<Row>
							<Col xs="6" className="pl-4 d-flex">
								<h3 className="mb-0 mr-5">
									{t("ultipro")}
								</h3>{" "}
								<Field
									name="active"
									valuePropName="checked"
								>
									<Switch id="active" />
								</Field>
							</Col>

							<Col className="text-right" xs="6">
								<Button
									className="btn-dark px-3 btn-round btn-icon"
									size="sm"
									loading={loading}
									type="submit"
								>
									{t("save")}
								</Button>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="col-lg-9 col-md-12 general-settings">
						<BaseField shouldUpdate noStyle>
							{({ }, { }, { getFieldValue }) => {
								const status = getFieldValue("active");

								return (
									status && (
										<div className="grid grid-cols-2 gap-x-20">
											<Field
												name="domain"
												label={t("domain")}
												rules={[
													{
														required: true,
														message:
															t(
																"required-domain"
															),
													},
												]}
											>
												<Text
													placeholder={t(
														"domain",
													)}
													disabled={!canEdit}
												/>
											</Field>

											<Field
												name="username"
												label={t("username")}
												rules={[
													{
														required: true,
														message:
															t("required-username"),
													},
												]}
											>
												<Text
													placeholder={t(
														"username",
													)}
													disabled={!canEdit}
												/>
											</Field>

											<Field
												name="password"
												label={t("password")}
												rules={[
													{
														required: true,
														message: t(
															"required-password"
														),
													},
												]}
											>
												<Password
													disabled={!canEdit}
													placeholder={t(
														"password",
													)}
												/>
											</Field>

											<Field
												name="clientId"
												label={t("client-key")}
												rules={[
													{
														required: true,
														message: t(
															"required-client-key"
														),
													},
												]}
											>
												<Text
													placeholder={t(
														"client-key",
													)}
													disabled={!canEdit}
												/>
											</Field>
										</div>
									)
								);
							}}
						</BaseField>
					</CardBody>
				</Form>
			</Card>
		</div>
	);
}

export default UltiPro;
