import {createContext, useContext, useMemo, useEffect, useCallback, useState} from "react";
import useSWR from "swr";
import {useHasCompany} from "../../utils/hooks/company";
import { useFirebase, useOnFirebaseMessage } from "./firebaseContext";
import { useMapNotifications } from "./useMapNotifcations";
import { useTranslation } from "react-i18next";

const NotificationsContext = createContext()

export const NotificationsProvider = ({ children }) => {
    const hasCompany = useHasCompany();
    const { t } = useTranslation();

    const [page, setPage] = useState(0);
    const {
        data: { result: data, totalItems },
        isLoading,
        mutate,
    } = useSWR(
        hasCompany ? {
            url: "/notifications",
            params: { page, perPage: 5, read: false },
        } : null,
        { fallbackData: { result: [], totalItems: 0 } },
        );
    // 
    const [unreadNotifications, setUnreadNotifications] = useState(totalItems);

    useEffect(() => {
        setUnreadNotifications(totalItems);
    }, [totalItems]);

	const setData = useCallback((argument) => {
        let value = argument;
		if (typeof argument === "function") {
            value = argument({
                result: data,
                totalItems,
            });
        }
        setUnreadNotifications(value.totalItems);
        mutate({
            ...value,
        }, { revalidate: false });
	}, [data, totalItems, mutate]);

    const hasMore = useMemo(() => {
        return data.length < totalItems;
    }, [data.length, totalItems]);

	const fetchMore = useCallback(() => {
		setPage((prev) => prev + 1);
	}, [setPage]);

    const onMessage = useCallback((notification, isBacground = false) => {
        let notData = {};
        try {
            notData = JSON.parse(notification.data?.notification);
        } catch (err) {
        }
        if(notData && Object.keys(notData).length > 0) {
            setData(({
                result: prev,
                totalItems: prevTotalItems,
            }) => {
                const newData = [...(prev.filter((item) => item.id !== notData.id))];
                newData.unshift({
                    ...notData,
                    read: false,
                });
                if (newData.length !== prev.length) {
                    prevTotalItems += 1;
                }
                return {
                    result: newData,
                    totalItems: prevTotalItems,
                };
            });
        }
    }, [setData]);

    const notifications = useMapNotifications(data, t);

    useOnFirebaseMessage(onMessage);
    const { firebaseToken } = useFirebase();
    const value = useMemo(() => ({
        loading: isLoading,
        hasMore,
        totalItems,
        notifications,
        setNotifications: setData,
        fetchMore,
        firebaseToken,
        unreadNotifications,
        setUnreadNotifications,
    }), [
        notifications,
        setData,
        isLoading,
        hasMore,
        totalItems,
        fetchMore,
        firebaseToken,
        unreadNotifications,
        setUnreadNotifications,
    ]);

    return (
        <NotificationsContext.Provider value={value}>
            {children}
        </NotificationsContext.Provider>
    );
}

export const useNotifications = () => {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error("");
    }
    return context;
}
