import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function Title({ close, status }) {
	const { t } = useTranslation();

	return useMemo(() => {
		let color, text;
		switch (status) {
			case "clocked-out":
			case "not-active":
			case "all": {
				color = "#11cdef";
				text = "Bulk clock in";
				break;
			}
			case "on-break": {
				color = "#F99B16";
				text = "Bulk end break";
				break;
			}
			case "clocked-in": {
				color = "red";
				text = "Bulk clock out";
				break;
			}
			default:
				break;
		}

		return (
			<div className="d-flex justify-content-between align-items-center">
				<span style={{ color }} className="text-lg font-size-bold">
					{t(text)}
				</span>

				<FontAwesomeIcon
					icon={faXmark}
					className="cursor-pointer"
					style={{ color }}
					onClick={close}
					size="lg"
				/>
			</div>
		);
	}, [status, close, t]);
}

export default Title;
