import { createContext, useContext, useEffect, useMemo } from "react";
import { useGetColumns } from "../api";

export const ReportColumnsContext = createContext();

export const ReportColumnsProvider = ({ children }) => {
	const { fetch, loading, data } = useGetColumns();

	const contextValue = useMemo(
		() => ({
			columns: data,
			loading,
		}),
		[loading, data],
	);

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return (
		<ReportColumnsContext.Provider value={contextValue}>
			{children}
		</ReportColumnsContext.Provider>
	);
};

export const useColumns = () => {
	const context = useContext(ReportColumnsContext);
	if (context === undefined) {
		throw new Error("columns should be used within a provider");
	}
	return context;
};
