import { camelCase } from "lodash";
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import Checkbox from "../../../Inputs/Checkbox";
import { useTranslation } from "react-i18next";

function ColumnToggle({ prefix, columns }) {
	const { t } = useTranslation();

	return (
		<UncontrolledDropdown>
			<DropdownToggle
				size="sm"
				color="secondary"
				title={t("Column toggle")}
				className="inline-flex items-center bg-transparent border-0 shadow-none mr-0"
			>
				Columns
			</DropdownToggle>

			<DropdownMenu
				right
				style={{
					maxHeight: "300px",
					overflowY: "auto",
					minWidth: "14rem",
					marginTop: "0.5rem",
				}}
			>
				{columns
					.filter(({ getCanHide }) => getCanHide())
					.map(
						(column) => {
							const {
								header,
								columnToggleLabel,
								alwaysVisible,
							} = column.columnDef;
							let id = "";
							let key = "";

							if (columnToggleLabel) {
								key = columnToggleLabel;
							} else {
								key = header;
							}

							if (columnToggleLabel) {
								id = `${camelCase(
									columnToggleLabel,
								)}-column-toggle`;
							} else {
								id = `${camelCase(header)}-column-toggle`;
							}

							if (prefix) {
								id = `${prefix}-${id}`;
							}

							return (
								<DropdownItem key={key} toggle={false}>
									<Checkbox
										id={id}
										disabled={alwaysVisible}
										checked={column.getIsVisible()}
										onChange={() => {
											const value = !column.getIsVisible();
											column.toggleVisibility(value);
										}}
									>
										{columnToggleLabel
											? columnToggleLabel
											: header}
									</Checkbox>
								</DropdownItem>
							);
						},
					)}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}

export default ColumnToggle;
