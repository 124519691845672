import { firstToUpper } from "../../../../../../../../utils/helpers/string";

export const getSmallerThanRuleValidator =
	(t, field, comparionField) =>
		({ getFieldValue }) => ({
			validator(_, value) {
				const comparisonOvertime = getFieldValue([
					"overtime",
					comparionField,
				]);
				if (Number(value) <= 0) {
					const readableField = firstToUpper(field.replaceAll("-", " "));

					return Promise.reject(
						new Error(
							t("readableField-bigger-than-zero", { readableField }),
						),
					);
				} else if (
					value &&
					comparisonOvertime.status &&
					Number(comparisonOvertime.value) &&
					Number(comparisonOvertime.value) <= Number(value)
				) {
					const readableField = firstToUpper(field.replaceAll("-", " "));
					const readableComparisonField = comparionField.replaceAll(
						"-",
						" ",
					);

					return Promise.reject(
						new Error(
							t(
								"readableField-smaller-than-readableComparisonField", {
								readableComparisonField,
								readableField
							}
							),
						),
					);
				} else {
					return Promise.resolve();
				}
			},
		});

export const getBiggerThanRuleValidator =
	(t, field, comparionField) =>
		({ getFieldValue }) => ({
			validator(_, value) {
				const comparisonOvertime = getFieldValue([
					"overtime",
					comparionField,
				]);
				if (
					value &&
					comparisonOvertime.status &&
					Number(comparisonOvertime.value) &&
					Number(comparisonOvertime.value) >= Number(value)
				) {
					const readableField = firstToUpper(field.replaceAll("-", " "));
					const readableComparisonField = comparionField.replaceAll(
						"-",
						" ",
					);

					return Promise.reject(
						new Error(
							t(
								"readableField-bigger-than-readableComparisonField", {
								readableField,
								readableComparisonField
							}
							),
						),
					);
				}
				return Promise.resolve();
			},
		});
