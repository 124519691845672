import {ListGroupItem, Row, Col} from "reactstrap";
import classNames from "classnames";import {renderUserName} from "../../../utils/helpers/user";
import moment from "moment-timezone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

function Item({ notification, onClick }) {
    return (
        <ListGroupItem
            className={classNames(
                "notification list-group-item-action cursor-pointer",
                !notification.read && "unread",
            )}
            onClick={() => onClick(notification)}
            tag="a"
        >
            <Row className="align-items-center">
                <Col className="col-auto">
                    <FontAwesomeIcon icon={faUser} />
                    {/*
                        <img
                            alt="user avatar"
                            className="avatar rounded-circle"
                            src={notification.avatar}
                        />
                    */}
                </Col>

                <div className="col ml--2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h4 className="mb-0 text-sm">
                               {notification?.title}
                            </h4>
                        </div>

                        <div className="text-right text-muted">
                            <small>
                                {moment(notification?.createdAt).fromNow()}
                            </small>
                        </div>
                    </div>
                    <p className="text-sm mb-0">
                        {notification?.body}
                    </p>
                </div>
            </Row>
        </ListGroupItem>
    );
}

export default Item;
