import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Form, { Field as BaseField } from "rc-field-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import CustomTimeInput from "../../../../../../../../components/Inputs/CustomTimeInput";
import LevelSelect from "../../../../../../../TimeSheet/components/LevelSelect";
import DurationInput from "../../../../../../../../components/Inputs/DurationInput";
import PayCodeSelect from "../../PayCodeSelect";
import { useGroups } from "../../../../../../../Settings/General/OrganisationLevelGroups/context";
import { getCommonCompanies, getCommonPayGroup } from "../../helpers";
import { getHourTypeColor } from "../../../../../../../../utils/helpers/hourType";
import { getClockInputStyle } from "../../../../../../../TimeSheet/components/TimeSheetTable/useColumns/useInColumn";
import { useAddedPayCode } from "../../../../../../../TimeSheet/components/TimeSheetTable/hooks/useAddedPayCode";
import { renderDurationAsFormat } from "../../../../../../../../utils/helpers/date";
import { combineDateAndTime } from "../../../../../../../../utils/helpers/date";
import { getDurationInputValue } from "../../../../../../../../components/Inputs/DurationInput";
import { recalculateShifts, mergeShifts, checkDateInShift } from "../helper";
import { toast } from "react-toastify";

function Row({ 
    form,
    resource, 
    name, 
    shiftName, 
    disabled, 
    openModal,
    add,
    defaultSegment,
    eventRecord,
    openWarningModal,
    removeShift
}) {
    const { t } = useTranslation();
    const date = Form.useWatch("date", form);
    const shifts = Form.useWatch(["shifts", shiftName], form);
    let allShifts = Form.useWatch("shifts", form);
    if (!Array.isArray(allShifts)) {
        allShifts = [];
    }
    const defaultPayCode = useAddedPayCode(resource);
    const { allocatedGroups } = useGroups();
    const companies = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonCompanies([resource]);
    }, [resource]);
    const payGroup = useMemo(() => {
        if (!resource) {
            return null;
        }
        return getCommonPayGroup([resource]);
    }, [resource]);
    const calcGroupBreaks = useMemo(() => {
        return resource?.calculationGroup?.breaks;
    }, [resource?.calculationGroup?.breaks]);
    const isMandatory = (calcGroupBreaks?.manual?.status && calcGroupBreaks?.manual?.mandatory) ? true : false;
    const breakAmount = isMandatory && calcGroupBreaks?.manual?.mandatory
        ? Number(calcGroupBreaks?.manual?.duration || 0) : 0;
    
    const segmentType = Form.useWatch(["shifts", shiftName, name, "type"], form);
    const { sh, currentName } = useMemo(() => {
        const sh = allShifts?.flat();
        const currentName = (allShifts?.slice(0, shiftName)?.flat() || [])?.length + name;
        return { sh, currentName }
    }, [allShifts, name, shiftName]);

    const onFieldChange = useCallback((value, field) => {
        const start = form.getFieldValue(["shifts", shiftName, name, "start"]);
        const end = form.getFieldValue(["shifts", shiftName, name, "end"]);
        let duration = form.getFieldValue(["shifts", shiftName, name, "duration"]);
        duration = getDurationInputValue(duration || "00:00");
        allShifts = allShifts?.flat();
        if (start && (end || duration > 0)) {
            let shifts = form.getFieldValue(["shifts", shiftName]);
            shifts = shifts?.map((shift) => ({
                ...shift,
                start: combineDateAndTime(moment(date), moment.parseZone(shift?.start)),
                end: combineDateAndTime(moment(date), moment.parseZone(shift.end))
            }));
            const _shifts = recalculateShifts(
                shifts,
                field,
                name,
                value,
                breakAmount * 60,
                defaultSegment,
                true,
                calcGroupBreaks,
                defaultPayCode
            );
            if (_shifts.length === 0) {
                _shifts.push(defaultSegment);
            }
            // let startDate = allShifts[0].start;
            // let endDate = allShifts[allShifts.length - 1].end;
            // startDate = combineDateAndTime(moment(date), moment(startDate));
            // endDate = combineDateAndTime(moment(date), moment(endDate));
            // form.setFieldValue("startDate", startDate);
            // form.setFieldValue("endDate", endDate);
            form.setFieldValue(["shifts", shiftName], _shifts);
        }
    }, [
        form,
        name,
        breakAmount,
        defaultSegment,
        defaultPayCode,
        shiftName,
        date,
        allShifts
    ]);
    
    const onPayCodeChange = useCallback((value) => {
        const job = shifts?.[name]?.job || eventRecord?.shifts?.[name]?.job;
        const location = shifts?.[name]?.location || eventRecord?.shifts?.[name]?.location;
        const level1 = shifts?.[name]?.level1 || eventRecord?.shifts?.[name]?.level1;
        const level2 = shifts?.[name]?.level2 || eventRecord?.shifts?.[name]?.level2;
        const level3 = shifts?.[name]?.level3 || eventRecord?.shifts?.[name]?.level3;
        const level4 = shifts?.[name]?.level4 || eventRecord?.shifts?.[name]?.level4;

        const defaultData = {
            type: "regular",
            job,
            location,
            level1,
            level2,
            level3,
            level4,
            payCode: value,
            start: shifts[name]?.start ? shifts[name]?.start : (
                shifts[shifts?.length - 1]?.end ? moment(shifts[shifts?.length - 1]?.end) : undefined),
            end: shifts[name]?.end ? shifts[name]?.end : undefined,
        };

        const duration = (defaultData?.start && defaultData?.end)
            ? moment(defaultData?.end).diff(moment(defaultData?.start), "seconds")
            : 0;
        defaultData.duration = renderDurationAsFormat(duration, "HH:mm")

        const hasAutomaticBreaks = calcGroupBreaks?.automatic?.status;
        const isBreak = (value?.hourType === "unpaid" && value?.forBreak) ? true : false;
        const isOvertime = value?.hourType === "overtime"
            || value?.hourType === "double-time"
            || value?.hourType === "double-time-and-half";

        if (isBreak && !hasAutomaticBreaks) {
            let end = (isMandatory && Number(calcGroupBreaks?.manual?.duration) && shifts[name]?.start)
                ? moment(shifts[name]?.start).add(Number(calcGroupBreaks?.manual?.duration), "minutes")
                : undefined;

            const amount = isMandatory
                ? Number(calcGroupBreaks?.manual?.duration || 0)
                : (shifts[name]?.start && shifts[name]?.end) 
                    ? moment(shifts[name]?.end).diff(moment(shifts[name]?.start), "minutes")
                    : 0;
            end = (shifts[name]?.end && !isMandatory) ? shifts[name]?.end : end;

            const data = {
                ...defaultData,
                type: "break",
                end,
                duration: (shifts[name]?.end && shifts[name]?.start) 
                    ? renderDurationAsFormat(Number(amount) * 60 || 0, "HH:mm")
                    : ""
            }
            form.setFieldValue(["shifts", shiftName, name], data);
        } else if (isOvertime) {
            const data = {
                ...defaultData,
                type: "overtime",
            }
            form.setFieldValue(["shifts", shiftName, name], data);
        } else {
            form.setFieldValue(["shifts", shiftName, name], defaultData)
        }
    }, [
        form,
        name,
        shifts,
        eventRecord?.shifts,
        calcGroupBreaks,
        isMandatory,
        shiftName
    ]);

    const onStartChange = useCallback((value) => {
        const start = value ? combineDateAndTime(moment(date), moment(value)) : undefined;
        // const currentEnd = shifts?.[name]?.end;
        const downShifts = allShifts?.[shiftName - 1]?.flat() || [];
        const endShift = downShifts?.[downShifts?.length - 1];
        const hasError = endShift && start ? checkDateInShift(endShift, start, 'start') : false;
        if(start) {
            if (hasError) {
                openWarningModal({ 
                    field: "start",
                    value: start, 
                    shiftName, 
                    name, 
                    text: t("shift-warning-when-start-change"),
                });
            } else {
                if (name === 0 && start && shiftName === 0) {
                    form.setFieldValue("startDate", start);
                }
                onFieldChange(start, "start");
            }
        }
    }, [onFieldChange, date, openWarningModal, shiftName, name, allShifts, form, t]);

    const onEndChange = useCallback((value) => {
        const end = value ? combineDateAndTime(moment(date), moment(value)) : undefined;
        // const currentStart = shifts?.[name]?.start;
        const currentSegments = allShifts?.[shiftName]?.flat() || [];
        const upShifts = allShifts?.[shiftName + 1]?.flat() || [];
        const startShift = upShifts?.[0];
        const hasError = startShift && end ? checkDateInShift(startShift, end, 'end') : false;
        if(end) {
            if (hasError) {
                openWarningModal({ 
                    field: "end",
                    value: end, 
                    shiftName, 
                    name, 
                    text: "shift-warning-when-end-change",
                });
            } else {
                if (name === (currentSegments.length - 1) && end && upShifts.length === 0) {
                    form.setFieldValue("endDate", end);
                }
                onFieldChange(end, "end");
            }
        }
    }, [date, onFieldChange, shiftName, name, allShifts, openWarningModal]);

    const onDurationChange = useCallback((value) => {
        value = getDurationInputValue(value);
        const shift = form.getFieldValue(["shifts", shiftName, name]);
        if (value > 0 && value < 86400) {
            let end = shift?.start ? moment(shift?.start).add(value, "seconds") : undefined;
            if (end && moment(end).isSameOrBefore(moment(shift?.start), "seconds")) {
                end = moment(end).add(1, "day");
            }
            onEndChange(end);
        } else {
            let duration = 0;
            if (shift?.start && shift?.end) {
                duration = moment(shift?.end).diff(moment(shift.start), "seconds");
            }
            form.setFieldValue(
                ["shifts", shiftName, name, "duration"], 
                ''
            );
            setTimeout(() => {
                form.setFieldValue(
                    ["shifts", shiftName, name, "duration"], 
                    renderDurationAsFormat(duration, "HH:mm")
                );
            }, 100)
            toast.error(t("max-duration"))
        }
    }, [onEndChange, shiftName, name, form, t]);

    const addSegment = useCallback(() => { 
        add({
            ...defaultSegment,
            type: "regular",
            payCode: defaultPayCode,
            start: shifts?.[shifts?.length - 1]?.end
                ? moment(shifts[shifts?.length - 1]?.end) : undefined,
            end: undefined,
            duration: undefined

        });
    }, [add, defaultSegment, defaultPayCode]);

    const removeSegment = useCallback(() => {
        let newShifts = [];
        newShifts = shifts?.filter((item, index) => (index !== name && item?.start && item.end));
        if(newShifts?.length > 0) {
            newShifts = mergeShifts(newShifts);
            form.setFieldValue(["shifts", shiftName], newShifts);
        } else {
            removeShift();
        }
    }, [shifts, form, shiftName, name, shifts, removeShift]);

    return (
        <tr key={name}>
            {!disabled && (
                <td>
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon
                            className="text-info cursor-pointer text-sm"
                            icon={faPlus}
                            onClick={addSegment}
                        />
                        {sh?.length > 1 && (
                            <FontAwesomeIcon
                                className={classNames(
                                    (calcGroupBreaks?.automatic?.status && sh?.[currentName]?.type === "break" && currentName !== sh?.length - 1) 
                                        || (segmentType === "uat" && currentName < sh?.length - 1)
                                    ? "text-gray"
                                    : "text-danger cursor-pointer", "text-sm")}
                                icon={faMinus}
                                onClick={
                                    (calcGroupBreaks?.automatic?.status && sh?.[currentName]?.type === "break" && currentName !== sh?.length - 1) 
                                        || (segmentType === "uat" && currentName < sh?.length - 1)
                                    ? undefined : removeSegment
                                }
                            />
                        )}
                    </div>
                </td>
            )}

            <td>
                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue }) => { 
                        const baseShifts = getFieldValue(["shifts", shiftName]);
                        const shifts = baseShifts?.filter((_, index) => index > name);
                        const hasBreak = shifts?.find((shift) => shift?.type === "break");
                        const disable = (segmentType === "break" && calcGroupBreaks?.automatic?.status)
                            || (segmentType !== "break" && calcGroupBreaks?.automatic?.status 
                                && ((name > 0 && !!hasBreak) || baseShifts?.[name - 1]?.type === "break"))
                            || disabled || (segmentType === "uat" && name < baseShifts?.length);

                        return (
                            <BaseField
                                name={[name, "payCode"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-pay-code")
                                    },
                                    { 
                                        validator:(_, value) => {
                                            const hasAutomaticBreaks = calcGroupBreaks?.automatic?.status;
                                            const isBreak = (value?.hourType === "unpaid" && value?.forBreak) ? true : false;
                                        
                                            if (hasAutomaticBreaks && isBreak && segmentType !== "break") {
                                                return Promise.reject(
                                                    new Error(t("cannot-add-break"))
                                                )
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <PayCodeSelect
                                    companies={companies}
                                    payGroup={payGroup}
                                    disabled={disable}
                                    onChange={onPayCodeChange}
                                />
                            </BaseField>
                        )
                    }}
                </BaseField>
            </td>

            <td>
                <BaseField shouldUpdate>
                    {({ value }, { }, { getFieldValue }) => {
                        const payCode = getFieldValue(["shifts", shiftName, name, "payCode"]);
                        const baseShifts = getFieldValue(["shifts", shiftName]);
                        const shifts = baseShifts?.filter((_, index) => index > name);
                        const hasBreak = shifts?.find((shift) => shift?.type === "break");
                        const style = getClockInputStyle({
                            color: "#00998b",
                            backgroundColor: "#00998b1a",
                            border: `1px solid transparent`,
                            value,
                            original: { payCode }
                        });
                        const disable = (segmentType === "break" && calcGroupBreaks?.automatic?.status)
                            || (segmentType !== "break" && calcGroupBreaks?.automatic?.status 
                                && ((name > 0 && !!hasBreak) || baseShifts?.[name - 1]?.type === "break"))
                            || disabled || (segmentType === "uat" && name < baseShifts?.length);

                        return (
                            <BaseField
                                name={[name, "start"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-start")
                                    }
                                ]}
                            >
                                <CustomTimeInput
                                    className="current-time-input"
                                    style={!disable ? style : { backgroundColor: "#E9ECEF" }}
                                    onChange={onStartChange}
                                    disabled={disable}
                                />
                            </BaseField>
                        )
                    }}
                </BaseField>
            </td>
            <td>
                <BaseField shouldUpdate>
                    {({ value }, { }, { getFieldValue }) => {
                        const payCode = getFieldValue(["shifts", shiftName, name, "payCode"]);
                        const baseShifts = getFieldValue(["shifts", shiftName]);
                        const shifts = baseShifts?.filter((_, index) => index > name);
                        const hasBreak = shifts?.find((shift) => shift?.type === "break");
                        const style = getClockInputStyle({
                            color: "#ff6161",
                            backgroundColor: "#ff61611a",
                            border: `1px solid transparent`,
                            value,
                            original: { payCode }
                        });
                        const disable = (segmentType === "break" && calcGroupBreaks?.automatic?.status)
                            || (segmentType !== "break" && calcGroupBreaks?.automatic?.status 
                                && (name < shifts?.length - 1 || !!hasBreak))
                            || disabled || (segmentType === "uat" && name < baseShifts?.length - 1);

                        return (
                            <BaseField
                                name={[name, "end"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-end")
                                    },
                                ]}
                            >
                                <CustomTimeInput
                                    className="current-time-input"
                                    style={!disable ? style : { backgroundColor: "#E9ECEF" }}
                                    disabled={disable}
                                    onChange={onEndChange}
                                />
                            </BaseField>
                        )
                    }}
                </BaseField>
            </td>
            <td>
                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue }) => {
                        const baseShifts = getFieldValue(["shifts", shiftName]);
                        const shifts = baseShifts?.filter((_, index) => index > name);
                        const hasBreak = shifts?.find((shift) => shift?.type === "break");
                        const disable = (segmentType === "break" && calcGroupBreaks?.automatic?.status)
                            || (segmentType !== "break" && calcGroupBreaks?.automatic?.status 
                                && (name < shifts?.length - 1 || !!hasBreak))
                            || disabled || (segmentType === "uat" && name < baseShifts?.length - 1);
                        const hourTypeColor = getHourTypeColor(baseShifts[name]?.payCode?.hourType);

                        return (
                            <BaseField
                                name={[name, "duration"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-duration")
                                    }
                                ]}
                            >
                                <DurationInput
                                    onChange={onDurationChange}
                                    timeFormat="HH:mm"
                                    disabled={disable}
                                    style={{
                                        color: '#595959',
                                        backgroundColor: 'transparent',
                                        border: '1px solid #d9d9d9',
                                        fontSize: '0.9rem',
                                    }}
                                />
                            </BaseField>
                        )
                    }}
                </BaseField>
            </td>

            {allocatedGroups?.map((group) => (
                <td style={{ width: 200 }}>
                    <BaseField shouldUpdate
                        name={[name, group?.level?.replace("_", "")]}
                        rules={[
                            {
                                required: true,
                                message: t(`required-group`, {
                                    group: group?.description
                                })
                            }
                        ]}
                    >

                        <LevelSelect
                            className="w-100"
                            group={group.id}
                            allowClear={!group.isRequired}
                            disabled={disabled}
                        />
                    </BaseField>
                </td>
            ))}

            <td>
                <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faPen}
                    onClick={() => openModal({ shiftName, name })}
                />
            </td>
        </tr>
    )
}

export default Row;
