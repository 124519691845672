import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useConfirm } from "./api";

const modes = ["signup", "forgot-password", "employee-confirm-registration"];

function Confirm() {
	const { t } = useTranslation();
	const { search } = useLocation();
	const { submit } = useConfirm();

	const params = useMemo(() => {
		const queryParams = new URLSearchParams(search);
		return {
			token: queryParams.get("token"),
			mode: queryParams.get("mode"),
		};
	}, [search]);

	const mode = useMemo(() => params.mode, [params.mode]);

	const hasValidParams = useMemo(() => {
		const { token } = params;
		return token !== null && mode !== null && modes.includes(mode);
	}, [mode, params]);

	useEffect(() => {
		const controller = new AbortController();
		if (!hasValidParams) {
			toast.error(t("confirm-url-not-valid"));
			return;
		}
		submit(params, controller.signal);
		return () => controller.abort();
	}, [hasValidParams, t, submit, params]);

	return null;
}

export default Confirm;
