import { ListGroup } from "reactstrap";
import Clock from "./Clock";

const List = ({ clocks }) => {
	return (
		<ListGroup data-toggle="checklist" flush>
			{clocks.map((clock) => (
				<Clock key={clock?.id} clock={clock} />
			))}
		</ListGroup>
	);
};

export default List;
