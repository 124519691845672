import { useMemo } from "react";
import { useAddColumn } from "./useAddColumn";
import { useDateColumn } from "./useDateColumn";
import { useDateGroupActionColumn } from "./useDateGroupActionColumn";
import { useSelectionColumn } from "./useSelectionColumn";
import { useInColumn } from "./useInColumn";
import { useOutColumn } from "./useOutColumn";
import { useRateColumn } from "./useRateColumn";
import { useDurationColumn } from "./useDurationColumn";
import { usePayCodeColumn } from "./usePayCodeColumn";
import { useHourTypeColumn } from "./useHourTypeColumn";
import { useOvernightColumn } from "./useOvernightColumn";
import { useTotalColumn } from "./useTotalColumn";
import { useDateTotalColumn } from "./useDateTotalColumn";
import { useLocationColumn } from "./useLocationColumn";
import { useJobColumn } from "./useJobColumn";
import { useGroupsColumns } from "./useGroupsColumns";
import { useCustomFieldsColumns } from "./useCustomFieldsColumns";
import { useActionsColumn } from "./useActionsColumn";
import { useDateDurationColumn } from "./useDateDurationColumn";
import { useScheduledHoursColumn } from "./useScheduledHoursColumn";

export const useColumns = ({
	user,
	canCreate,
	canEdit,
	dateIdMap,
	dateTimesheetMap,
	viewSummary,
	edit,
	view,
	remove,
	approve,
	unapprove,
	lock,
	unlock,
	copy,
	scheduledHours
}) => {
	const addColumn = useAddColumn({ dateIdMap });
	const selectionColumn = useSelectionColumn({ user, dateIdMap });
	const dateGroupActionColumn = useDateGroupActionColumn({
		dateIdMap,
		dateTimesheetMap,
		approve,
		unapprove,
		lock,
		unlock,
		copy,
	});
	const dateColumn = useDateColumn({ dateIdMap, dateTimesheetMap, view, viewSummary });
	const inColumn = useInColumn({ user, canEdit });
	const outColumn = useOutColumn({ user, canEdit });
	const overnightColumn = useOvernightColumn();
	const rateColumn = useRateColumn({ canEdit });
	const payCodeColumn = usePayCodeColumn({ canEdit, user });
	const hourTypeColumn = useHourTypeColumn();
	const durationColumn = useDurationColumn({ canEdit });
	const dateDurationColumn = useDateDurationColumn({ dateIdMap });
	const dateTotalColumn = useDateTotalColumn({ dateIdMap });
	const totalColumn = useTotalColumn({ canEdit });
	const locationColumn = useLocationColumn({ canEdit });
	const jobColumn = useJobColumn({ canEdit });
	const groupColumns = useGroupsColumns({ canEdit });
	const customFieldsColumns = useCustomFieldsColumns({ canEdit });
	const actionsColumn = useActionsColumn({ edit, remove });
	const scheduledHoursColumn = useScheduledHoursColumn({ dateIdMap, scheduledHours, view });

	return useMemo(
		() =>
			[
				canCreate ? addColumn : null,
				selectionColumn,
				dateGroupActionColumn,
				dateColumn,
				inColumn,
				outColumn,
				overnightColumn,
				rateColumn,
				payCodeColumn,
				hourTypeColumn,
				durationColumn,
				dateDurationColumn,
				scheduledHoursColumn,
				totalColumn,
				dateTotalColumn,
				locationColumn,
				jobColumn,
				...groupColumns,
				...customFieldsColumns,
				(edit || remove) ? actionsColumn : null,
			]?.filter((c) => !!c),
		[
			canCreate,
			addColumn,
			selectionColumn,
			dateGroupActionColumn,
			dateColumn,
			inColumn,
			outColumn,
			rateColumn,
			durationColumn,
			payCodeColumn,
			hourTypeColumn,
			overnightColumn,
			totalColumn,
			dateTotalColumn,
			dateDurationColumn,
			locationColumn,
			jobColumn,
			groupColumns,
			customFieldsColumns,
			actionsColumn,
			scheduledHoursColumn
		],
	);
};
