import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Select from "../Select";

function LanguageSelect({ ...props }) {
	const { t } = useTranslation();

	return (
		<Select {...props}>
			<Option value="en" key="en">
				{t("en")}
			</Option>
		</Select>
	);
}

export default LanguageSelect;
