import { useState, useCallback } from "react";

export const useSortFilter = (key) => {
	const [sort, setSort] = useState(sessionStorage.getItem(key));

	const onSortClick = useCallback(() => {
		setSort((prev) => {
			if (prev) {
				sessionStorage.removeItem(key);
				return "";
			} else {
				sessionStorage.setItem(key, "eventStartDate")
				return "eventStartDate";
			}
		})
	}, [setSort, key]);

	return { sort, onSortClick };
}