import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import Select from "../../../../../components/Inputs/Select";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import DateInput from "../../../../../components/Inputs/DateInput";
import RangeDateInput from "../../../../../components/Inputs/DateInput/Range";
import { useCompany } from "../../../../../utils/hooks/company";
import {
	getStartDate,
	getEndDateFromStart,
} from "../../../../../components/DateRangeFilters/helpers";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { getStartDateFromEndDate, getEndDateFromStartDate } from "./helpers";

function DateRange({ filters, setFilters }) {
	const { t } = useTranslation();
	const company = useCompany();

	const [mode, setMode] = useState("week");
	const [payGroupPeriod, setPayGroupPeriod] = useState("current");
	const type = useMemo(() => filters?.type, [filters?.type]);

	const onModeChange = useCallback(
		(value) => {
			setMode(value);
			let from;
			let to;

			switch (value) {
				case "date": {
					from = moment().toISOString(true);
					to = moment().toISOString(true);
					break;
				}
				case "week": {
					from = moment().startOf("week").toISOString(true);
					to = moment().endOf("week").toISOString(true);
					break;
				}
				case "custom":
				case "month": {
					from = moment().startOf("month").toISOString(true);
					to = moment().endOf("month").toISOString(true);
					break;
				}
				case "pay-group": {
					from = undefined;
					to = undefined;
					break;
				}
				default: {
					from = undefined;
					to = undefined;
					break;
				}
			}
			setFilters({ from, to, payGroup: undefined, type });
		},
		[company, type, setMode, setFilters],
	);

	const onDateChange = useCallback(
		(val) => {
			if (!mode) {
				return;
			}
			const value = moment(val);
			let from, to;

			from = getStartDate(value, mode);

			if (mode !== "custom") {
				to = getEndDateFromStart(value, mode);
			}

			setFilters({ from, to, type });
		},
		[mode, setFilters, type],
	);

	const onWeekChange = useCallback(
		([value]) => {
			onDateChange(value);
		},
		[onDateChange],
	);

	const onRangeChange = useCallback(
		([from, to]) => {
			setFilters({ from: moment(from), to: moment(to), type });
		},
		[type, setFilters],
	);

	const onPeriodChange = useCallback(
		(value) => {
			setPayGroupPeriod(value);
			if (filters?.payGroup) {
				const { frequency, periodStartDate, periodEndDate } =
					filters.payGroup;

				if (value === "past") {
					setFilters({
						to: moment
							.parseZone(periodStartDate)
							.subtract(1, "day")
							.toISOString(true),
						from: getStartDateFromEndDate(
							moment
								.parseZone(
									moment
										.parseZone(periodStartDate)
										.subtract(1, "day"),
								)
								.toISOString(true),
							frequency,
						).toISOString(true),
						payGroup: filters.payGroup,
						type
					});
				} else if (value === "current") {
					setFilters({
						from: moment
							.parseZone(periodStartDate)
							.toISOString(true),
						to: moment.parseZone(periodEndDate).toISOString(true),
						payGroup: filters.payGroup,
						type
					});
				} else if (value === "next") {
					setFilters({
						from: moment
							.parseZone(periodEndDate)
							.add(1, "day")
							.toISOString(true),
						to: getEndDateFromStartDate(
							moment
								.parseZone(periodEndDate)
								.add(1, "day")
								.toISOString(true),
							frequency,
						).toISOString(true),
						payGroup: filters.payGroup,
						type
					});
				} else {
					setFilters({
						from: moment
							.parseZone(periodStartDate)
							.toISOString(true),
						to: moment.parseZone(periodEndDate).toISOString(true),
						payGroup: filters.payGroup,
						type
					});
				}
			}
		},
		[setPayGroupPeriod, filters, setFilters, type],
	);

	const onPayGroupChange = useCallback(
		(value) => {
			if (value && payGroupPeriod) {
				const { frequency, periodStartDate, periodEndDate } = value;
				if (payGroupPeriod === "past") {
					setFilters({
						to: moment
							.parseZone(periodStartDate)
							.subtract(1, "day")
							.toISOString(true),
						from: getStartDateFromEndDate(
							moment
								.parseZone(
									moment
										.parseZone(periodStartDate)
										.subtract(1, "day"),
								)
								.toISOString(true),
							frequency,
						).toISOString(true),
						payGroup: value,
						type
					});
				} else if (payGroupPeriod === "current") {
					setFilters({
						from: moment
							.parseZone(periodStartDate)
							.toISOString(true),
						to: moment.parseZone(periodEndDate).toISOString(true),
						payGroup: value,
						type
					});
				} else if (payGroupPeriod === "next") {
					setFilters({
						from: moment
							.parseZone(periodEndDate)
							.add(1, "day")
							.toISOString(true),
						to: getEndDateFromStartDate(
							moment
								.parseZone(periodEndDate)
								.add(1, "day")
								.toISOString(true),
							frequency,
						).toISOString(true),
						payGroup: value,
						type
					});
				} else {
					setFilters({
						from: moment
							.parseZone(periodStartDate)
							.toISOString(true),
						to: moment.parseZone(periodEndDate).toISOString(true),
						payGroup: value,
						type
					});
				}
			}
		},
		[setFilters, payGroupPeriod, type],
	);

	return (
		<div className="d-flex">
			<Select
				showSearch
				placeholder={t("mode")}
				value={mode}
				onChange={onModeChange}
				style={{ width: "150px" }}
				className="mr-1"
			>
				<Select.Option value="date">{t("date")}</Select.Option>
				<Select.Option value="week">{t("week")}</Select.Option>
				<Select.Option value="month">{t("month")}</Select.Option>
				<Select.Option value="custom">{t("custom")}</Select.Option>
				<Select.Option value="pay-group">
					{t("pay-group")}
				</Select.Option>
			</Select>

			{mode === "pay-group" ? (
				<div className="d-flex w-100">
					<ResourceSelect
						resourcePath="/pay-group?pagination=off"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						onChange={onPayGroupChange}
						value={filters?.payGroup?.code}
					/>

					<Select
						className="ml-1"
						value={payGroupPeriod}
						onChange={onPeriodChange}
					>
						<Select.Option value="past">{t("past")}</Select.Option>
						<Select.Option value="current">
							{t("current")}
						</Select.Option>
						<Select.Option value="next">{t("next")}</Select.Option>
					</Select>
				</div>
			) : (
				<InputGroup className="input-group-alternative border shadow-none flex-nowrap mr-1">
					{mode === "custom" ? (
						<RangeDateInput
							startDate={moment(filters?.from).toDate()}
							endDate={moment(filters?.to).toDate()}
							onChange={onRangeChange}
						/>
					) : mode === "week" ? (
						<DateInput
							dateFormat={undefined}
							startDate={moment(filters?.from).toDate()}
							endDate={moment(filters?.to).toDate()}
							onChange={onWeekChange}
							selectsRange
						/>
					) : (
						<DateInput
							value={moment(filters?.from).toDate()}
							showMonthYearPicker={mode === "month"}
							dateFormat={
								mode === "month" ? "yyyy-MM" : undefined
							}
							onChange={onDateChange}
						/>
					)}
					<InputGroupAddon addonType="append">
						<InputGroupText>
							<FontAwesomeIcon icon={faCalendar} />
						</InputGroupText>
					</InputGroupAddon>
				</InputGroup>
			)}
		</div>
	);
}

export default DateRange;
