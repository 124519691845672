import { pick } from "lodash";
import { getCommonInUsers } from "../../../../../../../utils/helpers/user";

export const getCommonPayGroup = (users) => {
	const payGroup = users.find(({ payGroup }) => !!payGroup)?.payGroup;
	const hasSamePayGroups = users.every(
		(user) => user.payGroup?.id === payGroup?.id,
	);
	if (!hasSamePayGroups) {
		return null;
	}
	return payGroup;
};

export const getCommonJobs = (users) => {
	return getCommonInUsers({
		users,
		property: "jobs",
		identifier: "jobId",
		formatter: (item) =>
			pick(item, ["id", "code", "description", "jobId", "primary"]),
	});
};

export const getCommonLocations = (users) => {
	return getCommonInUsers({
		users,
		property: "locations",
		identifier: "locationId",
		formatter: (item) =>
			pick(item, [
				"id",
				"code",
				"description",
				"timezoneId",
				"timezoneValue",
				"locationId",
				"primary",
			]),
	});
};

export const getCommonCompanies = (users) => {
	return getCommonInUsers({
		users,
		property: "companies",
		identifier: "id",
	});
};

export const getCommonLevels = (users, allocatedGroups) => {
	if (!users || users.length === 0) {
		return {};
	}
	let defaultLevels = allocatedGroups.reduce((total, group) => {
		const level = group.level.replace("_", "");
		total[level] = users[0][level];
		return total;
	}, {});
	return Object.keys(defaultLevels).reduce((total, level) => {
		const orgLevel = defaultLevels[level];
		const inAllUsers = users.every((user) => {
			return user[level]?.id === orgLevel?.id;
		});
		if (inAllUsers) {
			total[level] = orgLevel;
		}
		return total;
	}, {});
};
