import {
	createContext,
	useContext,
	useMemo,
} from "react";
import Loading from "../../../components/Loaders/Loading";
import { useCompany } from "../../../utils/hooks/company";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import useSWR from "swr";

const CustomFieldsContext = createContext();

export const CustomFieldsProvider = ({ children }) => {
	const company = useCompany();
	const {data: { result: data }, isLoading} = useSWR(
		company ? {
			url: "/custom-field/timesheet",
			params: { pagination: "false", company: company.id }
		} : null,
		{ fallbackData: { result: [] } },
	);

	const value = useMemo(
		() => ({ data, isLoading }),
		[data, isLoading],
	);

	const { loading: loadingGroups } = useGroups();

	if (!!company && (loadingGroups || isLoading)) {
		return (
			<div className="d-flex justify-content-center align-items-center h-100">
				<Loading />
			</div>
		);
	}

	return (
		<CustomFieldsContext.Provider value={value}>
			{children}
		</CustomFieldsContext.Provider>
	);
};

export const useCustomFields = () => {
	const context = useContext(CustomFieldsContext);
	if (context === undefined) {
		throw new Error("'useCustomFields' has to be used inside a provider");
	}
	return context;
};
