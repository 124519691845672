import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useNonAuthApi } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useAuthenticateUser } from "../api";

export const useConfirm = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const { nonAuthGet } = useNonAuthApi();
	const authenticateUser = useAuthenticateUser();

	const submit = useCallback(
		async (data, signal) => {
			setLoading(true);
			setError(null);
			const loading = toast(t("confirming-account"));

			try {
				const response = await nonAuthGet("/confirm", {
					params: data,
					signal,
				});

				toast.dismiss(loading);
				toast.success(t("account-confirmed"));

				if (response) {
					switch (data.mode) {
						case "forgot-password": {
							navigate(`/set-new-password?token=${data.token}`);
							break;
						}
						case "signup": {
							const { user, token } = response;
							authenticateUser(user, token);
							navigate("/company-onboarding");
							break;
						}
						case "employee-confirm-registration": {
							navigate(
								`/set-employee-personal-details?token=${response.token}&company=${response?.user?.mainCompany}`,
							);
							break;
						}
					}
				}
			} catch (err) {
				setError(err);
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[t, authenticateUser, nonAuthGet, navigate, setLoading, setError],
	);

	return { loading, error, submit };
};
