import classNames from "classnames";
import {Card} from "reactstrap";
import Loading from "../../Loaders/Loading";
import "./_style.scss";

function Statistic({ loading, label, value, type = "light", className }) {
    return (
        <Card className={classNames("analytic-statistic mb-0 mr-2", type, className)}>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <Loading />
                </div>
            ) : (
                <>
                    <h4 className="label">{label}</h4>
                    <h4 className="value">{value}</h4>
                </>
            )}
        </Card>
    );
}

export default Statistic;
