export const initialState = {
	mode: "view",
	selectedId: null,
	data: [],
	totalItems: 0,
	loading: false,
	submitting: false,
	error: null,
};

export const reducer = (state, { type, payload }) => {
	switch (type) {
		// fetch
		case "requestFetch": {
			return {
				...state,
				loading: true,
				error: null,
			};
		}
		case "fetchSucceeded": {
			return {
				...state,
				loading: false,
				data: payload.result,
				totalItems: payload.totalItems,
			};
		}
		case "fetchFailed": {
			return {
				...state,
				loading: false,
				error: payload,
			};
		}

		// create
		case "requestCreate": {
			return {
				...state,
				submitting: true,
				error: null,
			};
		}
		case "createSucceeded": {
			return {
				...state,
				data: state.data.concat(payload),
				totalItems: state.totalItems + 1,
				submitting: false,
			};
		}
		case "createFailed": {
			return {
				...state,
				submitting: false,
				error: payload,
			};
		}

		// update
		case "requestUpdate": {
			return {
				...state,
				submitting: true,
				error: null,
			};
		}
		case "updateSucceeded": {
			const exists =
				state.data.findIndex(({ id }) => id === payload.id) !== -1;
			return {
				...state,
				data: exists
					? state.data.map((item) =>
						item.id === payload.id ? payload : item,
					)
					: state.data.concat(payload),
				totalItems: state.totalItems + (exists ? 0 : 1),
				submitting: false,
			};
		}
		case "updateFailed": {
			return {
				...state,
				submitting: false,
				error: payload,
			};
		}

		// delete
		case "delete": {
			return {
				...state,
				data: state.data.filter((item) => item.id !== payload),
				totalItems: state.totalItems - 1,
			};
		}
		case "deleteBatch": {
			return {
				...state,
				data: state.data.filter((item) => !payload.includes(item.id)),
				totalItems: state.totalItems - payload.length,
			};
		}

		case "changeMode": {
			const { mode, selectedId } = payload;
			return {
				...state,
				mode,
				selectedId,
				error: null,
			};
		}

		case "setState": {
			return {
				...state,
				...payload,
			};
		}

		default: {
			throw new Error(`Action with type '${type}' is not managed`);
		}
	}
};
