import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HourTypeSelect from "../../../../../components/Inputs/HourTypeSelect";

export const useHourTypeFilter = () => {
	const { t } = useTranslation();
	return useMemo(
		() => ({
			id: "hourType",
			Header: t("hour-type"),
			canFilter: true,
			filter: "select",
			Filter: <HourTypeSelect mode="multiple" />,
		}),
		[t],
	);
};
