import moment from "moment-timezone";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";

export const useTaskListApi = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [nonApprovedTimesheets, setNonApprovedTimesheets] = useState(0);
	const [missedPunches, setMissedPunches] = useState(0);
	const [pendingRequests, setPendingRequests] = useState(0);
	const [overtime, setOvertime] = useState(0);
	const [mealViolation, setMealViolation] = useState(0);

	const { authGet } = useApi();

	const fetch = useCallback(
		async (controller) => {
			setLoading(true);
			try {
				const response = await authGet("/reports/task-list", {
					signal: controller?.signal,
					params: {
						from: moment().startOf("week").format("YYYY-MM-DD"),
						to: moment().endOf("week").format("YYYY-MM-DD"),
					},
				});
				if (response) {
					setNonApprovedTimesheets(response.nonApprovedTimesheets);
					setMissedPunches(response.missedPunches);
					setPendingRequests(response.pendingRequests);
					setOvertime(response.overtime);
					setMealViolation(response?.mealViolation || 0)
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[
			authGet,
			setNonApprovedTimesheets,
			setMissedPunches,
			setPendingRequests,
			setLoading,
			setMealViolation,
			setOvertime,
		],
	);

	return {
		nonApprovedTimesheets,
		missedPunches,
		pendingRequests,
		overtime,
		mealViolation,
		loading,
		fetch,
	};
};
