import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useAccess } from "../../../utils/hooks/access";

function BulkActions({ rows, batchRemove }) {
	const { t } = useTranslation();
	const { visible, toggle } = useVisible();

	const ids = useMemo(() => {
		return Object.keys(rows);
	}, [rows]);

	const { hasAccess: deleteAccess } = useAccess("request.canDelete");
	const disabled = useMemo(() => {
		return ids.length === 0 || !deleteAccess;
	}, [ids, deleteAccess]);

	const remove = useCallback(() => {
		batchRemove(ids);
	}, [ids, batchRemove]);

	return (
		<Dropdown isOpen={visible} toggle={toggle}>
			<span className="text-sm font-weight-600 text-dark mr-1 text-center">
				{t("selected")}{" "}
				<span
					className="text-sm rounded-circle py-1 px-2 mx-1"
					style={{ backgroundColor: "#ced4da" }}
				>
					{Object.keys(rows).length}
				</span>
			</span>

			<DropdownToggle
				className="btn-round btn-icon shadow-none border"
				size="sm"
			>
				{t("actions")}
			</DropdownToggle>

			<DropdownMenu>
				<DropdownItem onClick={remove} disabled={disabled || !batchRemove}>
					{t("delete")}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

export default BulkActions;
