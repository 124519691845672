import {useCallback, useMemo} from "react";
import Checkbox from "../../../../../components/Inputs/Checkbox";
import {getSharedDateRowSpan, isAddedTimeSheet} from "../helpers";

export const useSelectionColumn = ({ user, dateIdMap }) => {
    return useMemo(() => ({
        id: "selection",
        Header: ({
            getToggleAllRowsSelectedProps,
            rows,
            state: { selectedRowIds },
            toggleRowSelected,
            toggleAllRowsSelected,
        }) => {
            const selectedIds = useMemo(() => {
                return Object.keys(selectedRowIds);
            }, [selectedRowIds]);
            const selectableRows = useMemo(() => {
                return rows.filter(({ original: { isAdded }, disabled }) => {
                    return !isAdded && !disabled;
                });
            }, [rows]);
            const areAllSelected = useMemo(() => {
                if (selectableRows.length !== selectedIds.length) {
                    return false;
                }
                return selectableRows.some(({ id }) => {
                    return selectedIds.includes(id);
                });
            }, [selectableRows, selectedIds]);
            const indeterminate = useMemo(() => {
                return !areAllSelected && selectedIds.length > 0;
            }, [areAllSelected, selectedIds]);

            const onChange = useCallback(() => {
                if (areAllSelected) {
                    toggleAllRowsSelected(false);
                } else {
                    selectableRows.forEach((row) => {
                        toggleRowSelected(row.id, true);
                    });
                }
            }, [
                selectableRows,
                areAllSelected,
                toggleRowSelected,
                toggleAllRowsSelected,
            ]);

            return (
                <Checkbox
                    {...getToggleAllRowsSelectedProps({
                        id: `${user?.id}-selection-all`,
                        disabled: selectableRows.length === 0,
                        checked: areAllSelected,
                        onChange,
                        indeterminate,
                    })}
                />
            );
        },
        headCustomProps: {
            className: "px-2",
        },
        getCellCustomProps: (timeSheet) => ({
            rowSpan: getSharedDateRowSpan(dateIdMap, timeSheet),
            className: "px-2",
        }),
        hideable: false,
        Cell: ({ row, cell, toggleRowSelected }) => {
            if (isAddedTimeSheet(row.original) || row.disabled || cell.disabled) {
                return null;
            }
            const ids = dateIdMap[row.original.date];
            const onClick = () => {
                ids.forEach((id) => {
                    toggleRowSelected(id);
                })
            }
            const inputId = user?.id
                ? `${user?.id}-selection-${row.id}`
                : `selection-${row.id}`;
            return (
                <Checkbox {...row.getToggleRowSelectedProps({ id: inputId, onClick })} />
            );
        },
    }), [user?.id, dateIdMap]);
};
