import { useMemo } from "react";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import { useVisible } from "../../../utils/hooks/useVisible";

export const useProfileDrawer = () => {
	const { visible, open, close, selected } = useVisible();

	const drawer = useMemo(() => {
		return (
			<ProfileDrawer
				user={selected}
				visible={visible}
				close={close}
			/>
		);
	}, [selected, visible, close]);

	return { drawer, open };
}