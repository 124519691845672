import { useCallback, useState } from "react";

export const useActionsHook = () => {
	const [mode, setMode] = useState("create");
	const [selected, setSelected] = useState();

	const goToEdit = useCallback((values) => {
		setMode("edit");
		setSelected(values);
	}, [setMode, setSelected]);

	const goToCreate= useCallback(() => {
		setMode("create");
		setSelected();
	}, [setMode, setSelected]);

	return { mode, selected, goToCreate, goToEdit };
}