import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Form from "rc-field-form";
import { Card, CardBody, CardHeader, Modal, Row, Col } from "reactstrap";
import Button from "../../../../../components/Button";
import Filters from "./Filters";
import DateRangeFilters from "../../../components/DateRangeFilters";
import { useDateFilters } from "../../../components/DateRangeFilters/useDateFilters";
import { useReports } from "../../api";
import { useColumnsMap } from "../../helpers";
import { toast } from "react-toastify";
import { formatParams } from "../../../../../utils/api/format";

function ActionsModal({ isOpen, close, setStep, data, setData, mode }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { getColumns } = useColumnsMap();
	const { from, to } = useDateFilters();

	const { loading, exportReport } = useReports();

	const onClose = useCallback(() => {
		close();
		form.setFieldsValue({
			filters: [{ name: undefined, method: undefined, value: undefined }],
		});
	}, [form, close]);

	const onFinish = useCallback(
		(values) => {
			if (mode === "generate" && data?.id) {
				const columns = getColumns(data?.columns);
				setData({
					id: data?.id,
					name: data?.name,
					columns,
					filters: values?.filters,
					from: moment(from).format("YYYY-MM-DD"),
					to: moment(to).format("YYYY-MM-DD"),
					columnLabels: data?.columnLabels || {},
					//DUMMY
					extraColumns: data?.extraColumns,
					isDummyData: data?.isDummyData,
					hasDailyHours: data?.hasDailyHours,
					data: data?.data
				});
				setStep(1);
			} else if (mode === "export" && data?.id) {
				const formData = {
					...formatParams({ filters: values.filters }),
					from: moment(from).format("YYYY-MM-DD"),
					to: moment(to).format("YYYY-MM-DD"),
					excludedColumns: [],
					//DUMMY
					extraColumns: data?.extraColumns,
					isDummyData: data?.isDummyData,
					hasDailyHours: data?.hasDailyHours,
					data: data?.data
				};
				exportReport(data.id, formData, () => {
					toast.success(t("report-is-exported"));
					onClose();
				});
			}
		},
		[
			setData,
			setStep,
			getColumns,
			exportReport,
			onClose,
			data,
			mode,
			from,
			to,
			t,
		],
	);

	useEffect(() => {
		form.setFieldsValue({
			filters: [
				{
					name: undefined,
					method: "contains",
					value: undefined,
				},
			],
		});
	}, [form]);

	return (
		<Modal
			isOpen={isOpen}
			className="modal-dialog-centered modal-xl pb-0 shadow-none"
			style={{
				height: "300px",
			}}
		>
			<Card className="shadow-none">
				<CardHeader className="d-flex justify-content-between align-items-center">
					<h3>{data?.name}</h3>

					<i
						className="fa-solid fa-xmark cursor-pointer"
						onClick={onClose}
					/>
				</CardHeader>

				<CardBody>
					<Form form={form} onFinish={onFinish}>
						<div className="mb-3">
							<DateRangeFilters />
						</div>

						<div
							style={{
								overflowY: "auto",
								maxHeight: "270px",
							}}
							className="w-100"
						>
							<Filters columns={data?.columns} form={form} />
						</div>

						<div className="d-flex justify-content-end">
							<Button color="dark" loading={loading}>
								{mode === "generate"
									? t("generate")
									: t("export")}
							</Button>
						</div>
					</Form>
				</CardBody>
			</Card>
		</Modal>
	);
}

export default ActionsModal;
