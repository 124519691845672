import {useCallback, useState} from "react";
import useApi from "..";

export const usePayGroups = () => {
    const {authGet} = useApi();
    const [hasFetched, setHasFetched] = useState(false);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async (config) => {
        setLoading(true);
        try {
            const {result, totalItems} = await authGet("/pay-group", config);
            setData(result);
            setTotalItems(totalItems);
            setHasFetched(true);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [authGet, setLoading, setData, setTotalItems]);

    return {
        data,
        setData,
        loading,
        setLoading,
        totalItems,
        setTotalItems,
        hasFetched,
        fetch,
    };
}
