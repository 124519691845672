import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
} from "reactstrap";
import Checkbox from "../../../../components/Inputs/Checkbox";
import Button from "../../../../components/Button";
import { useUserPreferenceActions } from "../../../Settings/Configuration/api/useUserPreferences";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useColumnToggleUserPreferences } from "../../../Settings/Configuration/hooks/useColumnToggleUserPreferences";

function ColumnToggle({ prefix, columns }) {
    const { t } = useTranslation();

    const { canCreate } = useModuleAccess("settings.userPreferences").access;

    const hideableColumns = useMemo(() => {
        return columns.filter(({ hideable }) => {
            return hideable;
        });
    }, [columns]);

    const {
        visible,
        toggle,
        visibleColumns,
        onVisibilityChange,
        getKeyIdentifier,
        onSave,
    } = useColumnToggleUserPreferences(hideableColumns);

    const { submit, submitting } = useUserPreferenceActions("myTimesheet");
    const save = useCallback(() => {
        return onSave({ columns: hideableColumns, submit });
    }, [onSave, hideableColumns]);

    return (
        <Dropdown isOpen={visible} toggle={toggle}>
            <DropdownToggle
                size="sm"
                color="neutral"
                className="inline-flex items-center shadow-none mr-0"
            >
                <i
                    className="fas fa-list text-dark"
                    style={{ fontSize: "20px" }}
                />
            </DropdownToggle>

            <DropdownMenu
                right
                style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    minWidth: "14rem",
                }}
            >
                {hideableColumns.map((column) => {
                    const {
                        Header,
                        columnToggleLabel,
                        getToggleHiddenProps,
                        alwaysVisible,
                        accessor,
                        id: columnId
                    } = column;
                    let id = "";
                    let key = "";

                    if (columnToggleLabel) {
                        key = columnToggleLabel;
                    } else {
                        key = Header;
                    }

                    if (columnToggleLabel) {
                        id = `${camelCase(
                            columnToggleLabel,
                        )}-column-toggle`;
                    } else {
                        id = `${camelCase(Header)}-column-toggle`;
                    }

                    if (prefix) {
                        id = `${prefix}-${id}`;
                    }

                    const keyIdentifier = getKeyIdentifier({ accessor, id: columnId });

                    return (
                        <DropdownItem key={key} toggle={false}>
                            <Checkbox
                                {...getToggleHiddenProps({
                                    id,
                                    checked: visibleColumns[keyIdentifier],
                                    disabled: alwaysVisible,
                                    onClick: () => onVisibilityChange(keyIdentifier)
                                })}
                            >
                                {columnToggleLabel
                                    ? columnToggleLabel
                                    : Header}
                            </Checkbox>
                        </DropdownItem>
                    );
                })}

                {canCreate && (
                    <div className="d-flex align-items-center justify-content-center my-2">
                        <Button
                            className="px-3"
                            color="primary"
                            size="sm"
                            loading={submitting}
                            onClick={save}
                        >
                            {t("save")}
                        </Button>

                        <Button
                            className="px-3 border"
                            color="muted"
                            size="sm"
                            disabled={submitting}
                            onClick={toggle}
                        >
                            {t("cancel")}
                        </Button>
                    </div>
                )}
            </DropdownMenu>
        </Dropdown>
    );
}

export default ColumnToggle;
