import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteModal } from "../../../../../Settings/helpers/useDeleteModal";
import { useDeleteTimeSheet } from "../../../../api/useDeleteTimeSheet";
import useApi from "../../../../../../utils/api";
import { formatTimeSheetParams } from "../../../../api/helpers";
import { useDateFilters } from "../../../DateAndPayGroupFilters/useDateFilters";
import { useActionWithAccess } from "../../../../../../utils/hooks/access";

export const useRemoveActions = ({ refetch, filters, setState, userId }) => {
    const { t } = useTranslation();
    const { from, to } = useDateFilters();
    const { authGet } = useApi();

    const {
        remove: baseRemove,
        removeMany: baseRemoveMany,
    } = useDeleteTimeSheet();

    const removeOne = useActionWithAccess(
        useCallback((id) => {
            baseRemove(id, async (id) => {
                return authGet("/time-sheet/manager-view/", {
                    params: {
                        userId,
                        ...formatTimeSheetParams({
                            from,
                            to,
                            filters
                        }),
                    },
                }).then(async (response) => {
                    await refetch(response);
                    setState((prev) => ({
                        ...prev,
                        data: prev.data?.filter(
                            (prevTimeSheet) => id !== prevTimeSheet?.id
                        ),
                        totalItems: prev.totalItems - 1,
                    }))
                })
            })
        }, [baseRemove, setState, authGet, from, to, filters, userId]),
        "timeSheet.canDelete",
    );

    const removeMany = useActionWithAccess(
        useCallback((ids) => {
            baseRemoveMany(ids, async () => {
                return authGet("/time-sheet/manager-view/", {
                    params: {
                        userId,
                        ...formatTimeSheetParams({
                            from,
                            to,
                            filters
                        }),
                    },
                }).then(async (response) => {
                    await refetch(response);
                    setState((prev) => ({
                        ...prev,
                        data: prev.data?.filter(
                            (prevTimeSheet) => !ids.includes(prevTimeSheet?.id)
                        ),
                        totalItems: prev.totalItems - ids.length,
                    }))
                })
            });
        }, [baseRemoveMany, authGet, refetch, filters, from, to, setState, userId]),
        "timeSheet.canDelete",
    );

    const { modal, open } = useDeleteModal({
        message: (res) => {
            const { data } = res || {};
            const count = Array.isArray(data) ? data.length : 1;
            return t("do-you-want-to-delete-timesheets", { count });
        },
        permission: "timeSheet.canDeleteMyTimesheet",
        onConfirm: (res) => {
            const { data, dates, userId } = res || {};
            return Array.isArray(data)
                ? removeMany(data, dates, userId)
                : removeOne(data)
        },
    });

    const remove = useActionWithAccess(open, "timeSheet.canDelete");

    return { modal, remove };
}
