import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../components/Field";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import ManageAbilitySelect from "./ManageAbilitySelect";
import ManageByLocation from "./ManageByLocation";
import ManageByOrgLevel from "./ManageByOrgLevel";
import { 
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters
} from "../../../../../../utils/helpers/settings";
import { getCompaniesSearchFilters } from "../../../../../Profile/Personal/Actions/ChangeCompanyInformation";

function ManageAbility({ form, values, locations, setLocations, disabled }) {
	const { t } = useTranslation();

	return (
		<>
		    <ManageAbilitySelect
                form={form}
                values={values}
                setLocations={setLocations}
                disabled={disabled}
            />

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const manageAbility = getFieldValue("manageAbility");

                    const isCustom = manageAbility === "custom";

                    const manageByCompany =
                        manageAbility === "by-company" ||
                        manageAbility === "by-company-and-location" ||
                        manageAbility === "by-company-and-organisation-level" ||
                        manageAbility ===
                        "by-company-and-location-and-organisation-level" ||
                        manageAbility === "by-company-and-pay-groups" ||
                        isCustom;

                    const manageByLocation =
                        manageAbility === "by-location" ||
                        manageAbility === "by-company-and-location" ||
                        manageAbility ===
                        "by-company-and-location-and-organisation-level" ||
                        isCustom;

                    const manageByOrgLevel =
                        manageAbility === "by-organisation-level" ||
                        manageAbility === "by-company-and-organisation-level" ||
                        manageAbility ===
                        "by-company-and-location-and-organisation-level" ||
                        isCustom;

                    const manageByPayGroups =
                        manageAbility === "by-pay-groups" ||
                        manageAbility === "by-company-and-pay-groups" ||
                        manageAbility === "by-pay-group-and-employee-type" ||
                        manageAbility === "by-projects-and-pay-groups" ||
                        isCustom;

                    const manageByEmployeeType =
                        manageAbility === "by-pay-group-and-employee-type" || isCustom;

                    const manageByProjects =
                        manageAbility === "by-projects-and-pay-groups" || isCustom;

                    const manageByUnions = manageAbility === "by-unions" || isCustom;

                    return (
                        <>
                            {manageByCompany && (
                                <Field
                                    name="companies"
                                    label={t("companies")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-companies"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="name"
                                        resourcePath="/companies"
                                        placeholder={t("select-companies")}
                                        hasSearch
                                        getSearchFilters={getCompaniesSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByLocation && (
                                <ManageByLocation
                                    setLocations={setLocations}
                                    isCustom={isCustom}
                                    disabled={disabled}
                                />
                            )}

                            {manageByOrgLevel && (
                                <ManageByOrgLevel
                                    locations={locations}
                                    manageAbility={manageAbility}
                                    disabled={disabled}
                                />
                            )}

                            {manageByPayGroups && (
                                <Field
                                    name="payGroups"
                                    label={t("pay-groups")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-pay-groups"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/pay-group"
                                        placeholder={t("Select pay groups")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByProjects && (
                                <Field
                                    name="projects"
                                    label={t("projects")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-projects"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/project"
                                        placeholder={t("projects")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByEmployeeType && (
                                <Field
                                    name="employeeTypes"
                                    label={t("employee-types")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-employee-types"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/employee-type"
                                        placeholder={t("employee-types")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByUnions && (
                                <Field
                                    name="unions"
                                    label={t("unions")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-unions"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/union"
                                        placeholder={t("unions")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}
                        </>
                    );
                }}
            </BaseField>
		</>
	)
}

export default ManageAbility;
