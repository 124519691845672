import { useTranslation } from "react-i18next";
import { Card, CardBody, CardText, CardTitle, Col } from "reactstrap";

function Overview({ title, description }) {
	const { t } = useTranslation();

	return (
		<Col sm="12" lg="4" className="mb-md-none h-100">
			<Card className="h-100 mb-0">
				<CardBody className="h-100 overflow-auto">
					<CardTitle className=" pb-0  mb-0 text-info" tag="h2">
						{t(title)}
					</CardTitle>

					<small className="text-dark mb-3">
						{t("business-structure")}
					</small>

					<CardText className="mt-4 mb-4 text-dark">{t(description)}</CardText>

					<CardTitle className="mb-0 text-green" tag="h3">
						{t("watch-training-video")}
					</CardTitle>
				</CardBody>
			</Card>
		</Col>
	);
}

export default Overview;
