import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordForm from "./PasswordForm";
import PersonalDetailsForm from "./PersonalDetails";
import Finished from "./Finished";
import { useSetPersonalDetails } from "./api";

function EmployeePersonalDetails() {
	const { search } = useLocation();
	const navigate = useNavigate();

	const token = useMemo(() => {
		const params = new URLSearchParams(search);
		return params.get("token");
	}, [search]);

	const company = useMemo(() => {
		const params = new URLSearchParams(search);
		return params.get("company");
	}, [search]);

	const {
		user,
		loadingUser,
		submitChangePassword,
		submitPersonalDetails,
		submitting,
		submittingError,
		loadingSetPassword,
		setPasswordError,
	} = useSetPersonalDetails(token, company);

	useEffect(() => {
		if (!token) {
			navigate("/login");
		}
	}, [token, navigate]);

	if (loadingUser) {
		return (
			<div
				className="spinner spinner-border spinner-border-sm mr-2"
				role="status"
			/>
		);
	} else if (user === undefined) {
		return null;
	}

	switch (user?.status) {
		case "unverified": {
			return (
				<PasswordForm
					submit={submitChangePassword}
					submitting={loadingSetPassword}
					error={setPasswordError}
				/>
			);
		}
		case "ready": {
			return (
				<PersonalDetailsForm
					user={user}
					submit={submitPersonalDetails}
					submitting={submitting}
					error={submittingError}
				/>
			);
		}
		case "active":
			return <Finished />;
	}

	return null;
}

export default EmployeePersonalDetails;
