import { useCallback, useState } from "react";
import useApi from "../../../utils/api";

export const useCopyTimesheet = () => {
	const { authPost } = useApi();
	const [loading, setLoading] = useState(false);

	const create = useCallback(
		async (data, onSuccess, onFailure) => {
			setLoading(true);

			try {
				const response = await authPost("/time-sheet/copy", {
					data,
				});
				if (response) {
					onSuccess?.(response);
				}
			} catch (err) {
				onFailure?.();
			} finally {
				setLoading(false);
			}
		},
		[setLoading, authPost],
	);

	const teamCopy = useCallback(
		async (data, onSuccess, onFailure) => {
			setLoading(true);
			try {
				const response = await authPost("/time-sheet/team/copy", {
					data
				});
				if (response) {
					onSuccess?.(response)
				}
			} catch (err) {
				onFailure?.(err);
			} finally {
				setLoading(false);
			}
		}, [setLoading, authPost])

	return { create, teamCopy, loading };
};
