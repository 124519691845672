import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import Button from "../../../components/Button";
import UploadInput from "../../../components/Inputs/Upload";
import { useUserDocumentUpload } from "../../../utils/api/upload";
import { useProfileApi } from "../../../utils/api/profile";
import { usePeopleApi } from "../../../utils/api/people";
import { getUploadUrl } from "../../../utils/helpers/upload";
import { ProfileContext } from "../context";
import "./style.scss";

const fileTypes = [
	"doc",
	"application/pdf",
	".docx",
	"application/vnd.oasis.opendocument.text",
	"application/msword",
	" application/vnd.ms-excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const fileSizeLimit = 2;

function UploadDocumentForm({ close, values, mode }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { upload, edit } = useUserDocumentUpload();
	const { user, submitting, submit } = useContext(ProfileContext);
	const { uploadDocuments: profileSubmit } = useProfileApi();
	const { uploadDocuments: peopleSubmit } = usePeopleApi();

	const [document, setDocument] = useState(null);

	const [error, setError] = useState(null);

	const onCancel = useCallback(() => {
		form.resetFields();
		close();
		setDocument(null);
	}, [setDocument, close, form]);

	const onChange = useCallback(
		(info) => {
			if (
				!info.target.files[0].type ||
				!fileTypes.includes(info.target.files[0].type)
			) {
				toast.error(t("can-upload-files"));
				return false;
			}

			if (
				!info.target.files[0].size ||
				info.target.files[0].size > fileSizeLimit * 1024 * 1024
			) {
				toast.error(t("file-size"));
				return false;
			}

			setError(null);

			setDocument({
				document: info.target.files[0],
				name: info.target.files[0].name,
			});
		},
		[setDocument, setError, t],
	);

	const preview = useMemo(() => {
		if (!document?.document) {
			return null;
		}
		if (typeof document.document === "string") {
			return getUploadUrl(document.document);
		}

		return URL.createObjectURL(new Blob([document?.document]));
	}, [document, getUploadUrl]);

	const add = useCallback(
		async (values) => {
			if (!user?.id) {
				return;
			}

			if (!document?.document) {
				setError(t("should-upload-document"));
				return;
			}

			try {
				const response = await upload(user.id, document?.document);
				if (!response) {
					return;
				}
				const data = {
					document: response.path,
					name: values.name,
					createdAt: response?.createdAt,
				};

				submit(profileSubmit, peopleSubmit, data, () => {
					onCancel();
					toast.success(t("file-uploaded"));
				});
			} catch (err) {
				toast.error(t("file-uploaded-failed"));
				onCancel();
			}
		},
		[
			user?.id,
			upload,
			document,
			onCancel,
			profileSubmit,
			peopleSubmit,
			submit,
			t,
			setError,
		],
	);

	const onFinish = useCallback(
		async (formValues) => {
			if (mode === "edit") {
				edit(user?.id, { name: formValues?.name }, values?.id);
			} else {
				add(formValues);
			}
		},
		[user?.id, mode, values, edit, add, upload],
	);

	const deleteFile = useCallback(() => {
		setDocument(null);
		setError(null);
	}, [setDocument, setError]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			name: values?.name,
		});
		setDocument({ document: values?.document, name: values?.name });
	}, [form, setDocument, values]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<div>
				<UploadInput
					multiple={false}
					onChange={onChange}
					fileTypes={fileTypes}
					fileList={[]}
					fileSizeLimit={fileSizeLimit}
					insertText={t("upload-file")}
					label={t("source")}
					name="documents"
					inputClassname="upload-input"
					hidden={mode === "edit"}
				/>

				{document?.document && preview && (
					<div className="border border-primary rounded px-3 py-2 d-flex justify-content-between align-items-center mb-3">
						<div className="flex items-center">
							<i className="fa-solid fa-paperclip mr-2 text-muted" />

							<a href={preview} download={document.document}>
								{document.name}
							</a>
						</div>

						{mode !== "edit" && (
							<i
								className="fa-sharp fa-solid fa-trash cursor-pointer text-danger"
								onClick={deleteFile}
							/>
						)}
					</div>
				)}

				{error && <p className="text-red text-sm">{error}</p>}

				<Field
					name="name"
					label={t("document-name")}
					rules={[
						{
							required: true,
							message: t("required-document-name"),
						},
					]}
				>
					<Text placeholder={t("document-description")} />
				</Field>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={onCancel}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					type="submit"
					color="primary"
					loading={submitting}
					disabled={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("upload")}
				</Button>
			</div>
		</Form>
	);
}

export default UploadDocumentForm;
