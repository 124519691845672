import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import { Field as BaseField } from "rc-field-form";
import InputNumberWithSuffix from "../../../../../../components/Inputs/InputNumberWithSuffix";
import "./style.scss";

function HourlyOption({ disabled }) {
	const { t } = useTranslation();

	return (
		<BaseField className="mb-10" shouldUpdate>
			{({ }, { }, { getFieldValue, isFieldTouched }) => {
				const values = getFieldValue("accural");
				let error = null;
				let field = null;

				if (!values?.earned && isFieldTouched(["accural", "earned"])) {
					error = t("Hours earned are required");
					field = "earned";
				} else if (
					!values?.worked &&
					isFieldTouched(["accural", "earned"])
				) {
					error = t("Hours worked are required");
					field = "worked";
				}

				return (
					<>
						<h5 className="my-3">{t("Rate")}</h5>

						<div className="d-flex align-items-center">
							<Field
								name={["accural", "earned"]}
								rules={[
									{
										required: true,
										message: "",
									},
								]}
								validateStatus={
									field === "earned" ? "error" : "validating"
								}
								noStyle
							>
								<InputNumberWithSuffix
									suffix="hr"
									className="border-right-0"
									inputGroupClassName="input-number-group"
									disabled={disabled}
								/>
							</Field>

							<p className="mx-2 text-sm nowrap">
								{t("earned-per")}
							</p>

							<Field
								name={["accural", "worked"]}
								rules={[
									{
										required: true,
										message: "",
									},
								]}
								validateStatus={
									field === "worked" ? "error" : "validating"
								}
								noStyle
							>
								<InputNumberWithSuffix
									className="border-right-0"
									suffix="hr"
									inputGroupClassName="input-number-group"
									disabled={disabled}
								/>
							</Field>

							<p className="ml-2">{t("worked")}</p>
						</div>

						{error && (
							<div className="text-danger text-sm">{error}</div>
						)}
					</>
				);
			}}
		</BaseField>
	);
}

export default HourlyOption;
