import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Finished() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate("/redirect");
		}, 5000);
	}, [navigate]);

	return (
		<div
			className="finished-page d-flex flex-column justify-content-center align-items-center w-100 h-100"
			style={{
				backgroundImage: `url(/assets/images/background.jpg)`,
				backgroundSize: "cover",
			}}
		>
			<i
				className="fa-sharp fa-solid fa-circle-check text-green mb-5"
				style={{ fontSize: "150px" }}
			/>
			<h2 className="text-dark font-weight-bolder display-3">
				{t("sucessfully-filled-personal-information")}
			</h2>
			<p className="text-muted font-weight-500 display-4">
				{t("wait-for-account-to-activate")}
			</p>
		</div>
	);
}

export default Finished;
