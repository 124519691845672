import { useMemo } from "react";
import ColumnToggle from "./ColumnToggle";
import Filters from "../../../../components/Table/Actions/Filters";
import Download from "../../Home/Actions/Download";
import BulkActions from "./BulkActions";
import { useTableContext } from "../../../../components/Table/context";
import { useExport } from "../hooks/useExport";
import { useFilters as useFiltersConfig } from "./useFilters";
import { useFilters } from "../../filtersContext";
import { useConfigFilterColumns } from "../../../Settings/Configuration/hooks/useConfigFilterColumns";

const Actions = (props) => {
    const [filters, onFiltersChange] = useFilters();
    const {
        state: { selectedRowIds },
        columns,
    } = useTableContext();

    const ids = useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);
    const columnKeys = useMemo(
        () => columns.map(({ id }) => id)?.filter((c) => !!c),
        [columns],
    );

    const { downloadSummary, downloadDetails } = useExport({
        ids,
        columns: columnKeys,
        filters,
    });

    const filterColumns = useConfigFilterColumns(
        "timeSheetTeam",
        useFiltersConfig(),
    );

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-start">
                    <BulkActions {...props} />
                </div>

                <div>
                    <Filters
                        columns={filterColumns}
                        filters={filters}
                        setFilters={onFiltersChange}
                    />

                    <Download
                        downloadSummary={downloadSummary}
                        downloadDetails={downloadDetails}
                    />

                    <ColumnToggle
                        prefix="users-table"
                        columns={columns}
                    />
                </div>
            </div>
        </>
    );
}

export default Actions;
