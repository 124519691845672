import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import { getPrimaryActiveResource } from "../../../../../utils/helpers/user";

export const usePaymentColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("payment"),
        accessor: "payment",
        Footer: (value) => {
            let sum = 0;
            value?.data?.forEach((val) => sum += val?.payment)
            return currencyFormatter(sum)
        },
        Cell: ({ value, row: { original } }) => {
            const job = getPrimaryActiveResource(original.jobs);
            return currencyFormatter(value || 0, 2, job?.currency);
        },
    }), [t]);
};
