import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { useActionsApi } from "../api/useActionsApi";
import useApi from "../../../utils/api";
import { useVisible } from "../../../utils/hooks/useVisible";
// import { getErrorMessage } from "../../../utils/helpers/errors";

const icon = (
    <FontAwesomeIcon
        icon={faCheckCircle}
        className="text-info w-100 mb-5"
        fontSize={70}
    />
);

export const useActionModal = ({ mutate, params, isTeamScheduler, setSelectedEvents, resetCount }) => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const { 
		submitEvent, 
		submitUserEvents, 
		bulkSubmit, 
		loading 
	} = useActionsApi({ isTeamScheduler });
	const { visible, open, close, selected } = useVisible();

	const head = useMemo(() => (
        <h2 className="mb-0 text-center font-weight-bolder">
            {t("are-you-sure")}
        </h2>
    ), [t]);

	const onSuccess = useCallback(async(userId) => {
		resetCount();
		if (userId) {
			const endpoint = isTeamScheduler ? `/schedules/${userId}/events` : "/my/schedules/events";
			return await authGet(endpoint, { 
				params
			}).then((response) => {
				const events = response.flat();
				mutate((prev) => {
					const prevEvents  = (prev?.data?.events || [])?.filter(
							(event) => event?.user !== userId
						);

					const data = {
						data: {
							resources: prev?.data?.resources,
							events: [
								...events,
								...prevEvents
							],
							success: prev?.data?.success
						},
						meta: prev?.meta
					};

					return data;
				}, { revalidate: false });
				close();
			}).catch((err) => {
				// getErrorMessage(err, t);
			})
		} else {
			mutate();
			close();
		}
	}, [isTeamScheduler, authGet, mutate, params, close, resetCount]);

	const onConfirm = useCallback(() => {
		if(selected?.type === "bulk") {
			const data = {
				from: params?.startDate,
				to: params?.endDate,
				ids: selected?.ids,
				filters: params
			}
			bulkSubmit(selected?.action, data, () => onSuccess());
		} else if (selected?.type === "row") {
			const data = {
				from: params?.startDate,
				to: params?.endDate,
				filters: params
			}
			submitUserEvents(
				selected?.userId,
				selected?.action, 
				data, 
				() => onSuccess(selected?.userId)
			);
		} else {
			submitEvent(
				selected?.eventId, 
				selected?.action,
				() => onSuccess(selected?.userId)
			)
		}
		setSelectedEvents([]);
	}, [
		submitEvent,
		submitUserEvents,
		bulkSubmit,
		onSuccess,
		setSelectedEvents,
		params,
		selected
	]);

	const modal = useMemo(() => {
		return (
			<Modal
				isOpen={visible}
				centered
			>
				<div className="m-5 d-flex flex-column align-items-center justify-content-center">
					<div>
						{icon}
						{head}
					</div>

					<div className="my-4">
						{t("do-you-want-to-submit-event", {
							action: selected?.action 
						})}
					</div>

					<div className="d-flex align-items-center gap-2 justify-content-center">
						<Button color="light" onClick={close} disabled={loading}>
							{t("cancel")}
						</Button>

						<Button color="info" onClick={onConfirm} loading={loading}>
							{t("confirm")}
						</Button>
					</div>
				</div>
			</Modal>
		)
	}, [visible, head, close, onConfirm, loading, selected?.action, t])

	return { open, modal };
}