import { useMemo } from "react";

export const useColumnFiltersConfig = (columns) => {
	return useMemo(() => {
		return columns
			.filter(({ getCanFilter }) => getCanFilter())
			.map(({ id, columnDef: { header, filterType, Filter, columnToggleLabel } }) => ({
				name: id.replace("_", "."),
				label: columnToggleLabel || header,
				type: filterType,
				component: Filter,
				disabled: false,
			}));
	}, [columns]);
}
