import { useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import PayCodeSelect from "../../PayCodeSelect";
import { applyTimezone } from "../../../../../utils/helpers/date";
import { isAddedTimeSheet } from "../helpers";
import { calculateRate, calculateRegularRate } from "../../../../../utils/helpers/shift";

export const usePayCodeColumn = ({ canEdit, user }) => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("time-code"),
        accessor: "payCode",
        alwaysVisible: true,
        disableFilters: false,
        filter: "text",
        headCustomProps: {},
        Cell: ({ row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original } = row;
            const { date, location, payCode, locked, job } = original;
            const disabled = (!isAddedTimeSheet(original) && locked)
                || row.disabled
                || cell.disabled;

            return (
                <div className="text-left">
                    <PayCodeSelect
                        value={payCode}
                        disabled={disabled || !canEdit}
                        companies={user?.companies}
                        payGroup={user?.payGroup}
                        placeholder="--"
                        onChange={(val) => {
                            const data = { payCode: val };
                            let rate = 0;
                            if (val?.hourType === "amount") {
                                rate = original.rate;
                            } else if (payCode?.hourType === "amount") {
                                rate = calculateRate({ ...original, ...data, rateChangeManually: false }, data?.payCode?.hourType === "regular");
                            } else if (val && job) {
                                rate = calculateRate({ ...original, ...data }, data?.payCode?.hourType === "regular");
                            }
                            data.rate = rate;
                            data.regRate = calculateRegularRate({ rate, payCode: val });
                            if (isAddedTimeSheet(original)) {
                                if (["amount", "unit"].includes(val?.hourType)) {
                                    const timeZone = location?.timezoneValue;
                                    let baseDate = applyTimezone(moment(date), timeZone, true)
                                        .startOf("date")
                                        .toISOString(true);
                                    data.startDate = baseDate;
                                    data.endDate = baseDate;
                                    if (val?.hourType === "unit") {
                                        data.duration = 0;
                                    }
                                }
                                updateAddedTimeSheet(original, data);
                            } else {
                                updateTimeSheet(original.id, data);
                            }
                        }}
                    />
                </div>
            );
        },
    }), [t, canEdit]);
};
