import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Select from "../Select";

function CompensationSelect(props) {
	const { t } = useTranslation();

	return (
		<Select {...props}>
			<Option value="H">{t("hourly")}</Option>

			<Option value="S">{t("salary")}</Option>
		</Select>
	);
}

export default CompensationSelect;
