import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupText } from 'reactstrap';
import Text from '../Text';
import './style.scss';

const suffix = process.env.REACT_APP_FRONTEND_BASE_DOMAIN;

function DomainInput({ className, hasError, ...props }) {
	const { t } = useTranslation();

	return (
		<InputGroup
			className={classNames('domain-input', hasError && 'is-invalid')}
		>
			<InputGroupText className="http-input">https://</InputGroupText>

			<Text
				type="text"
				placeholder={t('domain')}
				hasError={hasError}
				{...props}
			/>

			<InputGroupText className="suffix-input">{suffix}</InputGroupText>
		</InputGroup>
	);
}

export default DomainInput;
