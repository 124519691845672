import { useState, useCallback } from "react";
import { getLatLng, geocodeByAddress } from "react-places-autocomplete";
import { useGetLocationFields } from "../../../../utils/hooks/useGetLocationFields";
import { getGeocoderResultProperties } from "../../../../components/Map/helpers";
import { fromLatLng } from "../../../../utils/helpers/geocode";

const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };

export const useSearch = (changeMarker, setCoordinates) => {
	const [search, setSearch] = useState("");

	const handleSelect = useCallback(
		async (value) => {
			const [result] = await geocodeByAddress(value);
			const marker = await getLatLng(result);

			const { address1 } = getGeocoderResultProperties(result);
			changeMarker(marker);
			setSearch(address1);
			setCoordinates(undefined);
		},
		[setSearch, changeMarker, setCoordinates],
	);

	return { search, setSearch, handleSelect };
};

export const useLocation = (form) => {
	const [marker, setMarker] = useState(null);
	const [zoom, setZoom] = useState(11);
	const [center, setCenter] = useState(defaultCenter);
	const getFields = useGetLocationFields();

	const changeMarker = useCallback(
		async (value) => {
			setCenter(value);
			setMarker(value);
			setZoom(11);

			const response = await fromLatLng(value);
			if (!response) {
				return;
			}

			const {
				results: [result],
			} = response;

			const geocodeValues = getGeocoderResultProperties(result);
			const fields = await getFields(geocodeValues);

			form.setFieldsValue({ ...geocodeValues, ...fields });
		},
		[form, getFields, setCenter, setZoom, setMarker],
	);

	return {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	};
};
