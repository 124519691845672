import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import UploadInput from "../../../../../components/Inputs/Upload";
import StatusField from "../../../../../components/Field/StatusField";
import TimeFormatSelect from "../../../../../components/Inputs/TimeFormatSelect";
import CurrencySelect from "../../../../../components/Inputs/CurrencySelect";
import LanguageSelect from "../../../../../components/Inputs/LanguageSelect";
import DateFormatSelect from "../../../../../components/Inputs/DateFormatSelect";
import WeekdaySelect from "../../../../../components/Inputs/WeekdaySelect";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { getUploadUrl } from "../../../../../utils/helpers/upload";
import "./style.scss";

const fileTypes = ["image/jpeg", "image/png"];
const fileSizeLimit = 2;

function Content({ values, mode, images, setImages, form }) {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.general.companies");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onLogoChange = useCallback(
		(info) => {
			if (
				!info.target.files[0].type ||
				!fileTypes.includes(info.target.files[0].type)
			) {
				toast.error(t("jpg-png-file"));
				// setError(t("jpg-png-file"));
				return false;
			} else if (
				!info.target.files[0].size ||
				info.target.files[0].size > fileSizeLimit * 1024 * 1024
			) {
				toast.error(t("image-size-rule"));
				// setError(t("image-size-rule"));
				return false;
			} else {
				setImages({
					...images,
					logo: info.target.files[0],
				});
			}
		},
		[setImages, images, t],
	);

	const onBackgroundImageChange = useCallback(
		(info) => {
			if (
				!info.target.files[0].type ||
				!fileTypes.includes(info.target.files[0].type)
			) {
				toast.error(t("jpg-png-file"));
				// setError(t("jpg-png-file"));
				return false;
			} else if (
				!info.target.files[0].size ||
				info.target.files[0].size > fileSizeLimit * 1024 * 1024
			) {
				toast.error(t("image-size-rule"));
				// setError(t("image-size-rule"));
				return false;
			} else {
				setImages({
					...images,
					backgroundImage: info.target.files[0],
				});
			}
		},
		[setImages, images, t],
	);

	const deleteLogo = useCallback(() => {
		form.setFieldsValue({ ...images, logo: null });
		setImages({
			...images,
			logo: null,
		});
	}, [setImages, images, form]);

	const logoPreview = useMemo(() => {
		if (!images?.logo) {
			return null;
		}
		if (typeof images?.logo === "string") {
			return getUploadUrl(images?.logo);
		} else {
			return URL.createObjectURL(images?.logo);
		}
	}, [images?.logo]);

	const deleteBackgroundImage = useCallback(() => {
		form.setFieldsValue({ ...images, backgroundImage: null });
		setImages({
			...images,
			backgroundImage: null,
		});
	}, [setImages, images, form]);

	const backgroundImagePreview = useMemo(() => {
		if (!images?.backgroundImage) {
			return null;
		}
		if (typeof images?.backgroundImage === "string") {
			return getUploadUrl(images?.backgroundImage);
		} else {
			return URL.createObjectURL(images?.backgroundImage);
		}
	}, [images?.backgroundImage]);

	return (
		<>
			<Field label={t("external-id")} name="externalCompanyId">
				<Text
					placeholder={t("external-id")}
					disabled={
						disabled ||
						(mode === "edit" && values?.externalCompanyId)
					}
				/>
			</Field>

			<Field label={t("external-code")} name="externalCompanyCode">
				<Text
					placeholder={t("external-code")}
					disabled={
						disabled ||
						(mode === "edit" && values?.externalCompanyCode)
					}
				/>
			</Field>

			<Field label={t("code")} name="code">
				<Text placeholder={t("code")} disabled />
			</Field>

			<Field
				name="name"
				label={t("company-name")}
				rules={[
					{
						required: true,
						message: t("required-company-name"),
					},
				]}
			>
				<Text
					placeholder={t("company-name")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<StatusField />

			<Field
				name={["settings", "language"]}
				label={t("default-language")}
				rules={[
					{
						required: true,
						message: t("required-language"),
					},
				]}
			>
				<LanguageSelect
					placeholder={t("default-language")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<Field
				name={["settings", "timezone"]}
				label={t("timezone")}
				rules={[
					{
						required: true,
						message: t("required-timezone"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="text"
					resourcePath="/timezone?pagination=false"
					hasCompany={false}
					hasSearch
					placeholder={t("timezone")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<Field
				name={["settings", "timeFormat"]}
				label={t("time-format")}
				rules={[
					{
						required: true,
						message: t("required-time-format"),
					},
				]}
			>
				<TimeFormatSelect
					placeholder={t("time-format")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<Field
				name={["settings", "dateFormat"]}
				label={t("date-format")}
				rules={[
					{
						required: true,
						message: t("required-date-format"),
					},
				]}
			>
				<DateFormatSelect
					placeholder={t("date-format")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<WeekdaySelect
				name={["settings", "startDay"]}
				label={t("start-day")}
				rules={[
					{
						required: true,
						message: t("required-start-day"),
					},
				]}
				placeholder={t("start-day")}
				disabled={disabled || !isMasterCompany}
			/>

			<Field
				name={["settings", "currency"]}
				label={t(" currency")}
				rules={[
					{
						required: true,
						message: t("required-currency"),
					},
				]}
			>
				<CurrencySelect
					placeholder={t("currency")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<Field
				name={["settings", "maxShiftLength"]}
				label={t("max-shift-length")}
				rules={[
					{
						required: true,
						message: t("required-shift-length"),
					},
					{
						validator(_, value) {
							if (Number(value) < 0) {
								return Promise.reject(new Error(
									t("shift-length-pattern")
								))
							}
							return Promise.resolve()
						}
					},

				]}
			>
				<Text placeholder={t("max-shift-length")} />
			</Field>

			<Field
				name="executivePayrollEmailAddress"
				label={t("executive-payroll-email")}
				rules={[
					{
						required: true,
						message: t("required-executive-payroll-email"),
					},
					{
						type: "email",
						message: t("invalid-email"),
					},
				]}
			>
				<Text
					type="email"
					placeholder={t("executive-payroll-email")}
					disabled={disabled || !isMasterCompany}
				/>
			</Field>

			<div className="w-100 companies-image-upload-input">
				{logoPreview ? (
					<div className="d-flex flex-column">
						<span className="logo-label">{t("logo")}</span>

						<div className="border rounded px-3 py-2 d-flex justify-content-between align-items-center">
							<img
								alt=""
								src={logoPreview}
								width={100}
								height={100}
								className="logo-bg-image"
							/>

							<FontAwesomeIcon
								icon={faTrash}
								onClick={deleteLogo}
								className="cursor-pointer text-red ml-5"
							/>
						</div>
					</div>
				) : (
					<UploadInput
						name="logo"
						label={t("logo")}
						onChange={onLogoChange}
					/>
				)}

				{backgroundImagePreview ? (
					<div className="d-flex flex-column">
						<span className="logo-label">
							{t("background-image")}
						</span>

						<div className="border rounded px-3 py-2 d-flex justify-content-between align-items-center">
							<img
								alt=""
								src={backgroundImagePreview}
								width={100}
								height={100}
								className="logo-bg-image"
							/>

							<FontAwesomeIcon
								icon={faTrash}
								onClick={deleteBackgroundImage}
								className="cursor-pointer text-red ml-5"
							/>
						</div>
					</div>
				) : (
					<UploadInput
						name="backgroundImage"
						label={t("background-image")}
						onChange={onBackgroundImageChange}
					/>
				)}
			</div>
		</>
	);
}

export default Content;
