import { useCallback, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import DateInput from "../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { ProfileContext } from "../../../context";
import {
	getSettingsOrgResourceSearchFilters,
	renderOrgLevelResourceLabel,
} from "../../../../../utils/helpers/settings";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";

function ChangeOrganisation({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const {
		user,
		submit,
		submitting: updateUserLoading,
		submittingError,
	} = useContext(ProfileContext);

	const { changeOrganisation: profileSubmit } = useProfileApi();
	const { changeOrganisation: peopleSubmit } = usePeopleApi();

	const organisationLevelGroups = useMemo(
		() => user?.orgLevelGroups?.filter((group) => group?.status !== "deleted"),
		[user?.orgLevelGroups],
	);

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				level1: values?.level1?.id,
				level2: values?.level2?.id,
				level3: values?.level3?.id,
				level4: values?.level4?.id,
			};
			submit(profileSubmit, peopleSubmit, data, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			level1: user?.level1,
			level2: user?.level2,
			level3: user?.level3,
			level4: user?.level4,
		});
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["level1", "level2", "level3", "level4"],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="startDate"
				label={t("effective-date")}
				rules={[
					{
						required: true,
						message: t("required-effective-date"),
					},
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={t("effective-date")} />
			</Field>

			<div>
				{organisationLevelGroups &&
					organisationLevelGroups.map(
						({
							id,
							description,
							isRequired,
							code,
							level,
							glSegment,
						}) => (
							<Field
								key={id}
								name={`${level}`.replace("_", "")}
								label={renderOrgLevelResourceLabel({
									code,
									description,
									glSegment,
								})}
								rules={[
									{
										required: isRequired,
										message: t(
											"required-group", {
											group: renderOrgLevelResourceLabel({
												code,
												description,
												glSegment,
											})
										}
										),
									},
								]}
							>
								<ResourceSelect
									key={id}
									labelPropName="description"
									resourcePath={`${id}/org-levels`}
									renderLabel={renderOrgLevelResourceLabel}
									placeholder={renderOrgLevelResourceLabel({
										code,
										description,
										glSegment,
									})}
									hasSearch
									getSearchFilters={
										getSettingsOrgResourceSearchFilters
									}
								/>
							</Field>
						),
					)}
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={updateUserLoading}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
					loading={updateUserLoading}
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeOrganisation;
