import { useCallback } from "react";
import { useApi } from "..";

export const useGetCountry = () => {
	const { authGet } = useApi();

	return useCallback(
		async (filters) => {
			const response = await authGet("/countries", {
				params: filters,
			});
			return response?.result[0];
		},
		[authGet]
	);
};
