import { useCallback, useEffect, useState } from "react";
import FormElement from "rc-field-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useCompanyLogoUpload } from "../../../../../utils/api/upload";

function Form({
	values,
	error,
	loading,
	submit,
	close,
	mode,
	edit,
	isUploading,
	setIsUploading,
}) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();

	const [images, setImages] = useState({
		logo: values?.logo || null,
		backgroundImage: values?.backgroundImage || null,
	});

	const { upload: uploadLogo, uploadBackgroundImage } =
		useCompanyLogoUpload();

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
		});
	}, [form, values]);

	const onCreateSuccess = useCallback(
		async (company) => {
			let logoUrl = "",
				backgroundImageUrl = "";

			if (images?.logo === null) {
				logoUrl = "";
			} else if (typeof images?.logo === "string") {
				logoUrl = images.logo;
			} else if (images?.logo !== company?.logo) {
				setIsUploading(true);
				try {
					const res = await uploadLogo(images?.logo, company.id);
					if (res) {
						logoUrl = res.path;
					}
				} catch (err) {
					toast.error(t("logo-upload-failed"));
				}
			}

			if (images?.backgroundImage === null || !images?.backgroundImage) {
				backgroundImageUrl = "";
			} else if (typeof images?.backgroundImage === "string") {
				backgroundImageUrl = images.backgroundImage;
			} else if (images?.backgroundImage !== company?.backgroundImage) {
				try {
					setIsUploading(true);
					const res = await uploadBackgroundImage(
						images?.backgroundImage,
						company.id,
					);
					if (res) {
						backgroundImageUrl = res.path;
					}
				} catch (err) {
					toast.error(t("background-image-upload-failed"));
				}
			}

			if (
				logoUrl !== "" &&
				logoUrl === company?.logo &&
				backgroundImageUrl !== "" &&
				backgroundImageUrl === company?.backgroundImage
			) {
				setIsUploading(false);
			} else {
				const data = {
					logo: logoUrl,
					backgroundImage: backgroundImageUrl,
					settings: {
						...company?.settings,
						timezone: company?.settings?.timezone?.id,
					},
					code: company?.code,
					company: company?.company,
					executivePayrollEmailAddress:
						company?.executivePayrollEmailAddress,
					externalCompanyCode: company?.externalCompanyCode,
					externalCompanyId: company?.externalCompanyId,
					name: company?.name,
					status: company?.status,
				};

				edit(company.id, data, () => setIsUploading(false));
			}
		},
		[
			images,
			uploadLogo,
			uploadBackgroundImage,
			setIsUploading,
			isUploading,
			edit,
		],
	);

	const onFinish = useCallback(
		(formValues) => {
			const data = {
				settings: {
					...formValues?.settings,
					timezone: formValues?.settings?.timezone?.id,
				},

				code: formValues?.code,
				company: formValues?.company,
				executivePayrollEmailAddress:
					formValues?.executivePayrollEmailAddress,
				externalCompanyCode: formValues?.externalCompanyCode,
				externalCompanyId: formValues?.externalCompanyId,
				name: formValues?.name,
				status: formValues?.status,
			};
			setIsUploading(true);

			submit(
				data,
				(company) => onCreateSuccess(company),
				() => setIsUploading(false),
			);
		},
		[submit, onCreateSuccess, setIsUploading],
	);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"code",
				"name",
				"status",
				"executivePayrollEmailAddress",
				"language",
				"timezone",
				"timeFormat",
				"dateFormat",
				"startDay",
				"currency",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Content
					values={values}
					mode={mode}
					form={form}
					images={images}
					setImages={setImages}
				/>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{isMasterCompany && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading || isUploading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
