import { useMemo } from "react";
import { CardHeader } from "reactstrap";
import ColumnToggle from "../../../../../../components/Table/Actions/ColumnToggle";
import Filters from "../../../../../../components/Table/Actions/Filters";
import { useTableContext } from "../../../../../../components/Table/context";
import { useAdditionalColFilters } from "../../../../useAdditionalColFilters";

function Actions({ extraFilterCols = [] }) {
	const {
		state: { globalFilter },
		columns,
		setGlobalFilter,
	} = useTableContext();
	const additionalColFilters = useAdditionalColFilters();
	const additionalFilterIds = additionalColFilters.map(a => a.id);

	const hasUser = useMemo(() => {
		return columns.some(c => (c.id || '.').split('.')[0] === 'user')
	}, [columns]);

	const filterColumns = useMemo(
		() => [
			...(hasUser ? additionalColFilters : []),
			...([...columns, ...extraFilterCols].filter(c => !additionalFilterIds.includes(c.id)) || !hasUser)],
		[extraFilterCols, columns, additionalFilterIds, hasUser],
	);
	// const filterColumns = useMemo(
	// 	() => [...columns, ...extraFilterCols],
	// 	[extraFilterCols, columns],
	// );

	return (
		<CardHeader className="border-top p-3">
			<div className="d-flex justify-content-end">
				<Filters
					columns={filterColumns}
					filters={globalFilter}
					setFilters={setGlobalFilter}
				/>

				<ColumnToggle columns={columns} />
			</div>
		</CardHeader>
	);
}

export default Actions;
