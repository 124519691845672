import { useTranslation } from "react-i18next";
import Option from "./Option";

function OtherOptions({ disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<Option
				title={t("enable-gps-location")}
				name="gpsLocation"
				disabled={disabled}
			/>

			<Option
				title={t("enable-geofence-location")}
				name="geofenceLocation"
				disabled={disabled}
			/>

			<Option
				title={t("enable-signature")}
				name="signature"
				disabled={disabled}
			/>

			<Option
				title={t("enable-webcam-mobile-clocking")}
				name="webcamOnMobile"
				disabled={disabled}
			/>
		</>
	);
}

export default OtherOptions;
