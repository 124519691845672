import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useApi from "../../../utils/api";
import { makeCalculations } from "../hooks/useCalculations";
import { prepareEventForDb } from "../helpers/prepareEventForDb";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { getDurationInputValue } from "../../../components/Inputs/DurationInput";
import { useCompany } from "../../../utils/hooks/company";
import { combineDateAndTime } from "../../../utils/helpers/date";

export const calculateFields = ({
  date,
  startDate,
  endDate,
  // payCode,
  job,
  // location,
  rate = 0,
  user,
  resourceId,
  eventType,
  shifts,
  status,
  ...otherData
}) => {
  date = moment(date).format('YYYY-MM-DD');
  const start = moment(startDate).set('millisecond', 0).set('seconds', 0).unix();
  const end = moment(endDate).set('millisecond', 0).set('seconds', 0).unix();
  const duration = end - start;
  shifts = shifts?.map((shift, i) => {
    return shift?.map((sh) => ({
      ...sh, 
      group: i,
    }))
  });
  shifts = shifts?.flat();
  const segments = shifts?.map((shift, i) => {
    const s = combineDateAndTime(moment(date), moment(shift?.start));
    const e = combineDateAndTime(moment(date), moment(shift?.end));
    const shiftStart = typeof shift.start === 'number' 
      ? shift.start 
      : moment(shift.start).set('millisecond', 0).set('seconds', 0).unix();
    const shiftEnd = typeof shift.end === 'number' 
      ? shift.end 
      : moment(shift.end).set('millisecond', 0).set('seconds', 0).unix();
    
    const shStart = Math.abs(shiftStart - start);
    const shEnd = Math.abs(shiftEnd - start);
  
    if (shift?.payCode?.hourType !== 'amount') {
      rate = shift?.job?.hourlyRate || 0;
    } 

    const res = {
      group: shift?.group,
      payCode: shift?.payCode,
      location: shift?.location,
      job: shift?.job,
      rate,
      type: shift?.type,
      start: shStart,
      end: shEnd,
      duration: typeof shift?.duration === "number" 
        ? shift?.duration 
        : getDurationInputValue(shift?.duration || 0),
    };
    
    ["level1", "level2", "level3", "level4"]?.forEach(l => {
      if (shift?.[l]) {
        const level = shift?.[l];
        if (level) {
          res[l] = {
            code: level?.code,
            description: level?.description,
            id: level?.id,
            orgLevelGroup: typeof level?.orgLevelGroup === "string" 
              ? level?.orgLevelGroup
              : level?.orgLevelGroup?.id,
            glSegment: level?.glSegment,
          }
        }
      }
    });

    return makeCalculations(res);
  });

  const total = segments?.reduce((t, current) => {
    return t + current.total;
  }, 0);

  const res = {
    date,
    startDate,
    endDate,
    rate: rate || job?.hourlyRate,
    endTimestamp: end,
    timestamp: start,
    status: status || 'pending',
    duration,
    total,
    // location,
    eventType: {
      id: eventType?.id,
      code: eventType?.code,
      description: eventType?.description,
      allowSegmentation: eventType?.allowSegmentation,
      forSchedule: eventType?.forSchedule
    },
    // job,
    user: user || resourceId,
    shifts: segments,
    note: otherData?.note
  };

  // ["level1", "level2", "level3", "level4"]?.forEach(l => {
  //   if (otherData?.[l]) {
  //     const level = otherData?.[l];
  //     if (level) {
  //       res[l] = {
  //         code: level?.code,
  //         description: level?.description,
  //         id: level?.id,
  //         orgLevelGroup: typeof level?.orgLevelGroup === "string" 
  //           ? level?.orgLevelGroup
  //           : level?.orgLevelGroup?.id,
  //         glSegment: level?.glSegment,
  //       }
  //     }
  //   }
  // });

  return res;
}

export const useCreateEvent = ({ isTeamScheduler }) => {
  const { t } = useTranslation();
  const { authPost } = useApi();
  const [loading, setLoading] = useState(false);

  const create = useCallback(async(event, source, onSuccess, onFailure) => {
    const { id, ...data } = prepareEventForDb(event);
    setLoading(true);
    try {
      const response = await authPost(isTeamScheduler ? '/schedules' : '/my/schedules', { data });
      
      if (response) {
        await onSuccess?.(response, source)
      }
    } catch(err) {
      getErrorMessage(err, t);
      onFailure?.();
    } finally {
      setLoading(false);
    }
  }, [authPost, isTeamScheduler, setLoading]);
  return { create, loading };
}

export const useSaveEvent = ({ isTeamScheduler }) => {
  const { t } = useTranslation();
  const company = useCompany();
  const { authPut, authPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submit = useCallback(async (event, source, onSuccess, onFailure) => {
    setLoading(true);
    setError(null);
    const { id, ...data } = prepareEventForDb({ ...event, company });
    try {
      let newData = {};
      if (id.indexOf('_generated') > -1) {
        // create
        newData = await authPost(isTeamScheduler ? '/schedules' : '/my/schedules', { data });
      } else {
        // update
        newData = await authPut(isTeamScheduler ? `/schedules/${id}` : `/my/schedules/${id}`, { data });
      }
      await onSuccess?.(newData, source);
    } catch (err) {
      getErrorMessage(err, t);
      setError(err);
      onFailure?.();
    } finally {
      setLoading(false);
    }
  }, [t, authPut, authPost, setLoading, setError, isTeamScheduler, company]);

  return { submit, loading, error };
}
