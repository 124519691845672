import { useState } from "react";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { useCustomFields } from "../../../../hooks/useCustomFields";

export const useInitialState = () => {
    const { groups } = useGroups();
    const { data: customFields } = useCustomFields();
    const [initialState] = useState({
        hiddenColumns: [
            "status",
            "job",
            "location",
            ...groups.reduce((total, { allocatedBy, level }) => {
                if (!allocatedBy) {
                    return total.concat(level.replace("_", ""));
                }
                return total;
            }, []),
            ...customFields
                .filter(({ showByDefault }) => !showByDefault)
                .map(({ code }) => `customFields.${code}`),
        ],
    });
    return initialState;
};