import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useNameFilter = () => {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			id: "search",
			Header: t("name"),
			filter: "text",
			canFilter: true,
		}),
		[t],
	);
};
