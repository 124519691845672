import Content from "./Content";
import { DateFiltersProvider } from "../../../../TimeSheet/hooks/useDateFilters";

function Weekly() {
  return (
    <DateFiltersProvider>
      <Content />
    </DateFiltersProvider>
  );
}

export default Weekly;
