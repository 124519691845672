import { useMemo } from "react";
import Form from "rc-field-form";
import moment from "moment-timezone";
import { renderDurationAsFormat } from "../../../../../../../utils/helpers/date";

export const calculateShifts = (shifts, startDate) => {
    return shifts?.map((shift) => {
        const start = moment.parseZone(startDate).add(shift?.start, "seconds");
        const end = moment.parseZone(startDate).add(shift?.end, "seconds");
        return {
            ...shift,
            duration: typeof shift?.duration === "number"
                ? renderDurationAsFormat(shift?.duration, "HH:mm")
                : shift?.duration,
            start,
            end,
        }
    })
};

export const useCalculatedShifts = ({ 
    form, 
    eventRecord, 
    schedulerInstance, 
    events, 
    defaultSegment,
    segments
}) => {
    const startTime = Form.useWatch("startDate", form);
	const endTime = Form.useWatch("endDate", form);

    const calculatedShifts = useMemo(() => {
		let startDate = startTime || eventRecord?.startDate;
        const baseShifts = calculateShifts(eventRecord?.shifts, startDate) || segments || [defaultSegment];
        let shifts = baseShifts;
		
        if (schedulerInstance?.viewPreset?.data?.id === "hourAndDay" && events?.length > 0 && eventRecord?.isCreating) {
            const lastShift = baseShifts?.[baseShifts?.length - 1];
            const calculatedEventShifts = calculateShifts(events[0]?.shifts, moment.parseZone(events[0]?.startDate));
            const event = calculatedEventShifts?.[0];

            if (moment(event?.start).isAfter(moment(lastShift?.end), "seconds")) {
                shifts = [
                    ...shifts,
                    ...calculatedEventShifts
                ];
            } else {
                shifts = [
                    ...calculatedEventShifts,
                    ...shifts
                ]
            }
        }

        return shifts;
	}, [
        eventRecord, 
        startTime, 
        endTime, 
        schedulerInstance?.viewPreset?.data?.id,
        events,
        defaultSegment,
        segments,
    ]);

    return calculatedShifts;
}