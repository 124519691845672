import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderUserName } from "../../../../../utils/helpers/user";
import { Badge } from "reactstrap";
import SimpleTable from "../../../../../components/SimpleTable";

function ResponseTable({ response }) {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      columnHelper.accessor("user", {
        header: t("name"),
        cell: ({ getValue }) => renderUserName(getValue()),
      }),
      columnHelper.accessor("status", {
        header: t("status"),
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <Badge color={value === "success" ? "success" : "danger"}>
              {t(value)}
            </Badge>
          );
        },
      }),
      columnHelper.accessor("message", {
        header: t("message"),
        cell: ({ getValue, row }) => {
          const { status } = row.original;
          return (
            <div className={status === "success" ? "text-success" : "text-danger"}>{getValue()}</div>
          )
        }
      }),
    ];
  }, [t]);
 
  return (
    <div>
      <SimpleTable columns={columns} data={response} />
    </div>
  );
}

export default ResponseTable;
