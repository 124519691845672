import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";

const columnHelper = createColumnHelper();

export const useColumns = () => {
	const { t } = useTranslation();

	return useMemo(
		() => [
			columnHelper.accessor("cron.config", {
				header: t("job"),
				cell: (info) => {
					const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
				},
			}),
			columnHelper.accessor("status", {
				header: t("status"),
				cell: (info) => {
					const value = info.getValue();
					let color;
					switch (value) {
						case "Failed": {
							color = "red";
							break;
						}
						case "Success": {
							color = "green";
							break;
						}
						default:
							break;
					}
					return (
						value && (
							<span
								className="text-center px-3 py-1"
								style={{
									border: `1px solid ${color}`,
									color: color,
									borderRadius: "20px",
								}}
							>
								{t(value)}
							</span>
						)
					);
				},
			}),
			columnHelper.accessor("message", {
				header: t("message"),
				cell: (info) => {
					const value = info.getValue();
					return value && (
						<span className="text-sm">{value}</span>
					);
				},
			}),
		],
		[t],
	);
};
