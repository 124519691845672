import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { DateFiltersProvider } from "../hooks/useDateFilters";
import Content from "./Content";

function SchedulerTeam() {
	return (
		<FiltersProvider
			sessionKey="SCHEDULER_TEAM_FILTERS"
			initialValue={
				[{
					name: "employeeNumber",
					method: "contains",
					value: undefined,
				}]
			}
		>
			<DateFiltersProvider>
				<Content />
			</DateFiltersProvider>
		</FiltersProvider>
	);
}

export default SchedulerTeam;