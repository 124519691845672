import { useEffect, useMemo, useState } from "react";
import Form from "rc-field-form";
import moment from "moment-timezone";
import Content from "./Content";
import { PayCodesProvider } from "./PayCodeSelect/usePayCodes";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { useEarningGroupsContext } from "../../../../../TimeSheet/earningGroupsContext";
import { useCalculatedShifts } from "./hooks/useCalculatedShifts";
import { checkIsSameGroup } from "./Shifts/helper";

export const getShifts = (calculatedShifts, defaultSegment) => {
	let group = 0;
	let shifts = {
		[group]: [{
			...calculatedShifts[0] || defaultSegment,
			payCode: calculatedShifts?.[0]?.payCode || defaultSegment?.payCode,
			group
		}]
	};
	for (let i = 1; i < calculatedShifts?.length; i++) {
		const shift = calculatedShifts[i];
		const prevShift = calculatedShifts?.[i - 1];
		if (prevShift) {
			const duration = moment(shift?.start).unix() - moment(prevShift?.end).unix();
			if (duration > 0 || !checkIsSameGroup(shift, prevShift)) {
				group += 1;
				shifts = {
					...shifts,
					[group]: [{ ...shift, group }]
				}
			} else {
				shifts[group] = [
					...(shifts?.[group] || []),
					{ ...shift, group }
				]
			}
		} else {
			shifts[group] = [
				...(shifts?.[group] || []),
				{ ...shift, group }
			]
		}
	}
	
	return Object?.values(shifts || {});
};

function EventForm({ 
	form, 
	eventRecord, 
	events, 
	resource, 
	schedulerInstance, 
	isCreating, 
	shiftsWithAutomaticBreaks,
	breaks,
	primaryJob,
	primaryLocation,
	defaultSegment,
	levels
}) {
	const [hasChanges, setHasChanges] = useState(false);
	const [isAddedSegment, setIsAddedSegment] = useState(false);

	const { data: earningGroups } = useEarningGroupsContext();

	const defaultEarningGroup = useMemo(() => {
		return earningGroups?.find((group) => group?.code === "REG");
	}, [earningGroups]);

	const segments = useMemo(() => {
		if (resource?.calculationGroup?.breaks?.automatic?.status) {
			return shiftsWithAutomaticBreaks
		} else {
			return [defaultSegment]
		}
	}, [
		shiftsWithAutomaticBreaks, 
		defaultSegment, 
		resource?.calculationGroup?.breaks?.automatic?.status
	]);

	const calculatedShifts = useCalculatedShifts({
		form,
		eventRecord,
		resource,
		schedulerInstance,
		events,
		defaultSegment,
		isCreating,
		segments
	});
	
	const calcShifts = useMemo(() => {
		return getShifts(calculatedShifts, defaultSegment);
	}, [calculatedShifts, defaultSegment]);

	const initialValues = useMemo(() => {
		const allShifts = calcShifts?.flat();
		const startDate = (breaks?.automatic?.status && eventRecord?.isCreating && events?.length > 0)
				? moment(eventRecord?.startDate) 
				: allShifts?.[0]?.start 
					? moment(allShifts[0]?.start) 
					: moment(defaultSegment?.start);
		const endDate = (breaks?.automatic?.status && eventRecord?.isCreating && events?.length > 0)
				? moment(eventRecord?.endDate)
				: allShifts?.[allShifts?.length - 1]?.end 
					? moment(allShifts[allShifts?.length - 1]?.end) 
					: moment(defaultSegment?.end);
		const duration = moment(endDate).diff(moment(startDate), "seconds");
		
		return {
			date: moment(eventRecord?.startDate).toDate(),
			resourceId: eventRecord?.user || eventRecord?.resourceId,
			job: allShifts?.[0]?.job || primaryJob,
			location: allShifts?.[0]?.location || primaryLocation,
			startDate,
			endDate,
			shifts: calcShifts,
			duration: renderDurationAsFormat(duration, "HH:mm"),
			payCode: allShifts?.[0]?.payCode,
			eventType: eventRecord?.eventType || defaultEarningGroup,
			...levels
		}
	}, [
		eventRecord,
		defaultSegment,
		primaryJob,
		primaryLocation,
		levels,
		defaultEarningGroup,
		events?.length,
		isCreating,
		breaks?.automatic?.status,
		events,
		calcShifts,
	]);

	const startDate = Form.useWatch("startDate", form);
	const endDate = Form.useWatch("endDate", form);
	const payCode = Form.useWatch("payCode", form);
	const eventType = Form.useWatch("eventType", form);
	const location = Form.useWatch("location", form);
	const job = Form.useWatch("job", form);
	const level1 = Form.useWatch("level1", form);
	const level2 = Form.useWatch("level2", form);
	const level3 = Form.useWatch("level3", form);
	const level4 = Form.useWatch("level4", form);
	let shifts = Form.useWatch("shifts", form);

	useEffect(() => {
		shifts = shifts?.flat();
		if (shifts?.length > 0) {
			if (shifts?.[0]?.start) {
				form.setFieldValue("startDate", shifts[0].start)
			};
			if (shifts?.[shifts?.length - 1]?.end) {
				form.setFieldValue("endDate", shifts[shifts.length - 1]?.end);
			}
		}
	}, [shifts, form]);

	useEffect(() => {
		if (!eventType?.allowSegmentation && payCode && startDate && endDate) {
			const duration =  moment(endDate).diff(moment(startDate), "seconds");
			const data = [{
				...defaultSegment,
				payCode,
				start: startDate,
				end: endDate,
				level1,
				level2,
				level3,
				level4,
				location,
				job,
				duration: renderDurationAsFormat(duration, "HH:mm")
			}];
			form.setFieldValue("shifts", getShifts(data, defaultSegment));
		}
	}, [
		eventType?.allowSegmentation, 
		payCode, 
		defaultSegment, 
		startDate, 
		endDate,
		location,
		job,
		level1,
		level2,
		level3,
		level4,
		form
	]);

	return (
		<PayCodesProvider>
			<Form form={form} initialValues={initialValues}>
				<Content
					form={form}
					resource={resource}
					setIsAddedSegment={setIsAddedSegment}
					setHasChanges={setHasChanges}
					eventRecord={eventRecord}
					defaultSegment={defaultSegment}
					calculatedShifts={calculatedShifts}
				/>
			</Form>
		</PayCodesProvider>
	);
}

export default EventForm;
