import { useContext, useState, useCallback, useMemo, useEffect } from "react";
import moment from "moment-timezone";
import AppLoader from "../../../../components/Loaders/AppLoader";
import WageTracker from "./WageTracker";
import Chart from "./Chart";
import { useStatistic } from "./api";
import { OrgLevelGroupsContext } from "../../../Settings/General/OrganisationLevelGroups/context";

function Statistic() {
	const { groups } = useContext(OrgLevelGroupsContext);
	const [filters, setFilters] = useState({
		from: moment().startOf("week").toISOString(true),
		to: moment().endOf("week").toISOString(true),
		payGroup: undefined,
		type: "amount"
	});

	const [visibleBudget, setVisibleBudget] = useState(false);
	const [visibleActual, setVisibleActual] = useState(false);

	const [group, setGroup] = useState();

	const onFiltersChange = useCallback(
		(value) => {
			setGroup(value);
		},
		[setGroup],
	);

	const groupWithBudget = useMemo(
		() => groups?.find(({ hasBudget }) => hasBudget),
		[groups],
	);

	const { fetch, data, loading } = useStatistic();

  const { from, to, payGroup } = filters;
	useEffect(() => {
		const controller = new AbortController();
		const params = {
			from,
			to,
			payGroup: payGroup?.id,
			budgetBy:
				groupWithBudget && `${groupWithBudget.level}`.replace("_", ""),
			[group?.field]: group?.value,
		};

		fetch(params, controller);
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetch, from, to, payGroup, group]);

	return (
		<div style={{ position: "relative" }} className="d-lg-flex">
			{loading && (
				<div
					className="w-100 h-100 position-absolute"
					style={{
						zIndex: 5,
						backgroundColor: "#eeeeeec2",
					}}
				>
					<div className="position-relative" style={{ top: "40%" }}>
						<AppLoader />
					</div>
				</div>
			)}
			<div className="col-12 col-lg-4 d-md-flex flex-md-column px-0 px-lg-2">
				<WageTracker
					data={data}
					visibleActual={visibleActual}
					visibleBudget={visibleBudget}
					setVisibleActual={setVisibleActual}
					setVisibleBudget={setVisibleBudget}
					type={filters?.type}
				/>
			</div>

			<div className="col-12 col-lg-8 d-md-flex flex-md-column px-0 px-lg-2">
				<Chart
					data={data?.data}
					visibleActual={visibleActual}
					visibleBudget={visibleBudget}
					onFiltersChange={onFiltersChange}
					setFilters={setFilters}
					filters={filters}
					group={group}
				/>
			</div>
		</div>
	);
}

export default Statistic;
