import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { getShortHourType } from "../../../../../utils/helpers/hourType";
import { useTotals } from "../hooks/useTotals";
import "./_style.scss";
import { useRenderPayment } from "../../../../../utils/hooks/useRenderPayment";

const AnalyticsTable = ({ hourTypes, config, data }) => {
    const { t } = useTranslation();
    const renderPayment = useRenderPayment();
    const { totalDuration, totalPayment } = useTotals(data);

    return (
        <Card className="analytics-table mb-0 mr-2">
            <div className="inner-container">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    {t("type")}
                                </div>
                            </th>

                            {hourTypes.map((hourType) => (
                                <th>
                                    <div>
                                        {t(getShortHourType(hourType))}
                                    </div>
                                </th>
                            ))}

                            <th>
                                <div>
                                    {t("totals")}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    {t("hourly")}
                                </div>
                            </td>

                            {hourTypes.map((hourType) => (
                                <td>
                                    <div>
                                        {renderDurationAsFormat(config[hourType]?.duration || 0, "HH:mm")}
                                    </div>
                                </td>
                            ))}

                            <td className="total">
                                <div>
                                    {renderDurationAsFormat(totalDuration, "HH:mm")}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>
                                    {t("amount")}
                                </div>
                            </td>

                            {hourTypes.map((hourType) => (
                                <td>
                                    <div>
                                        {renderPayment(config[hourType]?.total || 0)}
                                    </div>
                                </td>
                            ))}

                            <td className="total">
                                <div>
                                    {renderPayment(totalPayment)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
}

export default AnalyticsTable;
