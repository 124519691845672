import { useCallback } from "react";
import { useInitialState } from "../../../utils/hooks/useInitialState";

export const useSessionFilters = (key) => {
    const initial = useInitialState(() => {
        let filters;
        try {
            filters = JSON.parse(sessionStorage.getItem("filters"));
            filters = filters[key];
        } catch (err) {}
        return filters;
    });

    const onChange = useCallback((value) => {
        let filters;
        try {
            filters = JSON.parse(sessionStorage.getItem("filters")) || {};
        } catch (err) {
            filters = {};
        }
        filters[key] = value;
        sessionStorage.setItem("filters", JSON.stringify(filters));
    }, [key]);

    return [initial, onChange];
}
