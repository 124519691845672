import moment from "moment-timezone";

export const getStartDate = (date, frequency) => {
    switch (frequency) {
        case "monthly": {
            return moment(date).startOf("month");
        }
        case "semi-monthly": {
            if (moment(date).date() < 16) {
                return moment(date).startOf("month");
            } else {
                return moment(date).date(16);
            }
        }
        default:
            return date;
    }
};

export const getEndDate = (date, frequency) => {
    switch (frequency) {
        case "monthly": {
            return moment(date).endOf("month");
        }
        case "semi-monthly": {
            if (moment(date).date() < 16) {
                return moment(date).date(15);
            } else {
                return moment(date).endOf("month");
            }
        }
        default: {
            return date;
        }
    }
};
