import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import { useColumns } from "./columns";
import NTable from "../../../../components/NTable";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { OrgLevelGroupsContext } from "./context";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import {useNewTable} from "../../../../components/NTable/hook";
import {useQueryFilters} from "../../helpers/useQueryFilters";
import {useVisibleColumnKeys} from "../../helpers/useVisibleColumnKeys";
import {useOnExport} from "../../helpers/useOnExport";
import {useImportModal} from "../../helpers/useImportModal";

function OrganisationLevelGroups() {
	const { t } = useTranslation();
	const { setGroups } = useContext(OrgLevelGroupsContext);

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/org-level-groups");

	const { hasAccess: canAdd } = useAccess(
		"settings.general.organizationLevelGroup.canCreate",
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t("do-you-want-to-delete-organisation-level-groups", {
					count: data?.length
				}
				)
				: t("do-you-want-to-delete-organisation-level-group"),
		onConfirm: (data) => {
			if (Array.isArray(data)) {
				batchRemove(data, () => {
					setGroups((prev) => ({
						result: prev.result.filter((item) => !data.includes(item.id)),
						totalItems: prev.totalItems - data.length,
					}));
				});
			} else {
				remove(data, (id) => {
					setGroups((prev) => ({
						result: prev.result.filter((item) => item.id !== id),
						totalItems: prev.totalItems - 1,
					}));
				});
			}
		},
        permission: "settings.general.organizationLevelGroup.canDelete",
	});
	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
		edit: useActionWithAccess(
			goToEdit,
			"settings.general.organizationLevelGroup.canEdit",
		),
		remove: openDeleteModal,
		clone: useActionWithAccess(
			goToClone,
			"settings.general.organizationLevelGroup.canCreate",
		),
	});
    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "orgLevelGroups",
    });

    const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-organisation-level-group"),
		create: t("add-organisation-level-group"),
		edit: t("edit-organisation-level-group"),
		clone: t("clone-organisation-level-group"),
	});

	useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/org-level-groups",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/org-level-groups/import",
        exportUrl: "/export/org-level-groups",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("organisation-level-groups")}
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    >
                        <BulkActions
							rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

            {importModal}

			{deleteModal}

			{unarchiveModal}
		</div>
	);
}

export default OrganisationLevelGroups;
