import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { CardHeader, CardBody } from "reactstrap";
import Button from "../../../Button";
import Job from "../../../../features/Profile/History/Job";
import { ProfileContext } from "../../../../features/Profile/context";
import { useAccess } from "../../../../utils/hooks/access";

function History() {
	const { t } = useTranslation();
    const { isSelf } = useContext(ProfileContext);

    const { hasAccess: canViewJobHistory } = useAccess(
        isSelf ? "profile.viewJobHistory" : "people.viewJobHistory"
    );

	const [menu, setMenu] = useState("job");

	const content = useMemo(() => {
		switch(menu) {
			case "job": {
				return <Job />
			}
			default: {
				return;
			}
		}
	}, [menu]);

	return (
		<>
			<CardHeader className="border-0 mb-5">
                {canViewJobHistory && (
                    <Button
						className={classNames(
							"text-primary px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border",
							menu === "job" && "bg-primary text-white",
						)}
						onClick={() => setMenu("job")}
					>
						{t("job")}
					</Button>
                )}
            </CardHeader>

			<CardBody
				style={{ height: "calc(100%)", overflowY: "auto" }}
				className="profile-scroll my-7"
			>
               {content}
            </CardBody>
		</>
	)
}

export default History;
