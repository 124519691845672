import { useCallback, useState } from "react";
import useApi from "../../../../../../utils/api";
import { formatTimeSheetParams } from "../../../../api/helpers";
import { useBatchUpdateTimeSheets } from "../../../../api/useBatchUpdateTimeSheets";
import { isAddedTimeSheet } from "../../../../components/TimeSheetTable/helpers";
import { useDateFilters } from "../../../../hooks/useDateFilters";

export const useBatchUpdate = ({ user, refetch, filters, from: globalFrom, to: globalTo }) => {
    const [loading, setLoading] = useState(false);
    const { from, to } = useDateFilters();
    const { submit } = useBatchUpdateTimeSheets(user?.id);
    const { authGet } = useApi();

    const batchUpdate = useCallback(
        ({ addedTimeSheets, updatedTimeSheets }, onSuccess, onFailure) => {
            setLoading(true);
            const data = {
                added: Object
                    .values(addedTimeSheets)
                    .flat()
                    .filter((timeSheet) => timeSheet.isTouched)
                    .filter((timeSheet) => {
                        if (!isAddedTimeSheet(timeSheet)) {
                            return;
                        }
                        return timeSheet.startDate && timeSheet.endDate;
                    }),
                updated: updatedTimeSheets,
                filters: { from, to, filters },
            };
            submit(data, async ({ id }) => {
                return authGet("/time-sheet/manager-view/", {
                    params: {
                        userId: id,
                        ...formatTimeSheetParams(
                            { from: globalFrom, to: globalTo, filters }
                        ),
                    },
                })
                    .then((response) => {
                        refetch(response);
                        onSuccess?.(response);
                    }).finally(() => {
                        setLoading(false);
                    });
            }, onFailure);
        },
        [submit, refetch, filters, globalFrom, globalTo, from, to, authGet, setLoading],
    );

    return { submit: batchUpdate, loading };
}
