import { useCallback, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone, viewUsers }) => {
    const { t } = useTranslation();

    const { status } = useCommonColumns({ edit, remove, clone });

    const renderPlace = useCallback((value) => {
        switch (value) {
            case "end-break": {
                return t("e-break")
            }
            case "end-shift": {
                return t("e-shift")
            }
            case "start-break": {
                return t("s-break")
            }
            default:
                return t(value)
        }
    }, [t]);

    return useMemo(() => [
        columnHelper.accessor("code", {
            enableColumnFilter: true,
            header: t("code"),
        }),
        columnHelper.accessor("description", {
            enableColumnFilter: true,
            header: t("description"),
        }),
        columnHelper.accessor("place", {
            enableColumnFilter: true,
            header: t("place"),
            cell: (info) => renderPlace(info.getValue()),
        }),
        columnHelper.accessor("frequency", {
            enableColumnFilter: true,
            header: t("frequency"),
            cell: (info) => t(info.getValue()),
        }),
        columnHelper.accessor("manageAbility", {
            enableColumnFilter: true,
            header: t("manage-ability"),
            cell: (info) => t(info.getValue()),
        }),
        status,
        columnHelper.display({
            id: "actions",
            enableHiding: false,
            enableColumnFilter: false,
            header: (
               <div className="text-right">
                    {t("actions")}
                </div>
            ),
            cell: (info) => {
                const { id, company, isDefault } = info.row.original;

                return (
                    <div className="actions text-primary text-right">
                        {viewUsers && (
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => viewUsers(id)}
                                title={t("View users")}
                            >
                                <i className="fas fa-users" />
                            </span>
                        )}

                        {company && !isDefault && edit && (
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => edit(id)}
                                title={t("Edit")}
                            >
                                <i className="fas fa-pen" />
                            </span>
                        )}

                        {company && !isDefault && remove && (
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => remove(id)}
                                title={t("Delete")}
                            >
                                <i className="fas fa-trash" />
                            </span>
                        )}

                        {clone && (
                            <span
                                className="btn-inner--icon icon-sm cursor-pointer"
                                onClick={() => clone(id)}
                                title={t("Clone")}
                            >
                                <i className="ni ni-single-copy-04" />
                            </span>
                        )}
                    </div>
                );
                },
            })
        ], [clone, edit, remove, status, renderPlace, viewUsers, t]);
};
