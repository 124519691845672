import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";
import { toast } from "react-toastify";
import useApi from "../../../../utils/api";
import {
	getToastErrorMessage,
	isCancelError,
} from "../../../../utils/helpers/errors";
import {useVisible} from "../../../../utils/hooks/useVisible";
import { formatTimeSheetParams } from "../../api/helpers";

const OverviewContext = createContext();

export const OverviewProvider = ({ children }) => {
	const { call } = useApi();

    const {visible, toggle} = useVisible();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const fetch = useCallback(
		async ({ params, ...config }) => {
            if (!visible) {
                return;
            }
			if (!params.from || !params.to) {
				return;
			}

			setLoading(true);
			try {
				const response = await call({
					...config,
					method: "GET",
					params: formatTimeSheetParams(params),
				});
				setData(response);
			} catch (err) {
				if (!isCancelError(err)) {
					toast.error(getToastErrorMessage(err));
				}
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setData, call, visible],
	);

	const value = useMemo(
		() => ({ loading, data, fetch, visible, toggle }),
		[loading, data, fetch, visible, toggle],
	);

	return (
		<OverviewContext.Provider value={value}>
			{children}
		</OverviewContext.Provider>
	);
};

export const useOverview = () => {
	const context = useContext(OverviewContext);
	if (context === undefined) {
		throw new Error("useOverview should be used within a provider");
	}
	return context;
};
