import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useGetColumns = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const { authGet } = useApi();

	const fetch = useCallback(
		async (controller, onFailure = noop) => {
			setLoading(true);
			try {
				const response = await authGet("/reports/config", {
					signal: controller?.signal,
				});
				if (response) {
					setData(response);
				}
			} catch (err) {
				if (typeof err === "string") {
					toast.error(err);
				}
				onFailure?.();
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setData, authGet],
	);

	return { fetch, data, loading };
};

export const useReports = () => {
	const { t } = useTranslation();
	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [error, setError] = useState(null);
	const [totalItems, setTotalItems] = useState(0);
	const { authGet, authPost, authPut, call } = useApi();

	const fetchReports = useCallback(
		async (params, controller) => {
			setLoading(true);
			try {
				const response = await authGet("/reports", {
					params,
					signal: controller?.signal,
				});

				if (response) {
					setReports(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[t, setLoading, authGet, setReports, setTotalItems],
	);

	const create = useCallback(
		async (data, onSuccess = noop) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authPost("/reports", { data });

				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPost],
	);

	const generate = useCallback(
		async (id, params, onSuccess = noop, controller, onFailure = noop) => {
			setGenerateLoading(true);
			setError(null);
			try {
				const response = await authGet(`/reports/generate/${id}`, {
					params,
					signal: controller?.signal,
				});

				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				setError(err);
				onFailure()
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setGenerateLoading(false);
			}
		},
		[setGenerateLoading, setError, authGet],
	);

	const update = useCallback(
		async (id, data, onSuccess = noop) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authPut(`/reports/${id}`, { data });

				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPut],
	);

	const fetchReportByColumns = useCallback(
		async (columns, filters, onSuccess, controller) => {
			setLoading(true);

			try {
				const response = await authGet("/reports/preview", {
					params: {
						...filters,
						page: 1,
						perPage: 5,
						columns,
					},
					signal: controller?.signal,
				});
				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, authGet, t],
	);

	const exportReport = useCallback(
		async (id, params, onSuccess) => {
			setLoading(true);
			try {
				const response = await call(
					{
						url: `/export/report/${id}`,
						params,
						headers: {
							"Content-Type": "Content-Disposition",
						},
						responseType: "arraybuffer",
					},
					true,
				);

				if (response) {
					const header = response.headers["content-disposition"];
					const parts = header.split(";");
					const filename = parts[1].split("=")[1];
					const downloadLink = document.createElement("a");
					const blob = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					const docUrl = URL.createObjectURL(blob);
					downloadLink.href = docUrl;
					downloadLink.download = filename;
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);

					onSuccess();
					return { status: response.status };
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[call, t, setLoading],
	);

	return {
		create,
		update,
		fetchReports,
		generate,
		fetchReportByColumns,
		exportReport,
		reports,
		totalItems,
		loading,
		error,
		generateLoading,
	};
};
