import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const { code, description, status, actions } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
			columnHelper.accessor("isRequired", {
				header: t("is-required"),
                cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
			columnHelper.accessor("allocatedBy", {
				header: t("allocated-by"),
                cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
			columnHelper.accessor("hasBudget", {
				header: t("has-budget"),
                cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
			columnHelper.accessor("hasHierarchy", {
				header: t("has-hierarchy"),
                cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
			columnHelper.accessor("useInClockIn", {
				header: t("use-in-clock-in"),
                cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
			columnHelper.accessor("inclueLocationHierarchy", {
				header: t("location-hierarchy"),
                cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [t, isMasterCompany, edit, remove, clone, code, description, status, actions]);
};
