import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Col, Row } from "reactstrap";
import ManageAbilitySelect from "../../../../components/Inputs/ManageAbilitySelect";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Field from "../../../../components/Field";
import {
	getSettingsOrgResourceSearchFilters,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
} from "../../../../utils/helpers/settings";
import {
	canManageLevels,
	canManageLocations,
	hasManagingRole,
} from "../../../../utils/helpers/user";
import { OrgLevelGroupsContext } from "../../../Settings/General/OrganisationLevelGroups/context";

function Managing() {
	const { t } = useTranslation();

	const { groups } = useContext(OrgLevelGroupsContext);

	const organisationLevelGroup = useMemo(
		() => groups?.find(({ hasHierarchy }) => hasHierarchy),
		[groups],
	);

	if (!organisationLevelGroup) {
		return null;
	}

	return (
		<BaseField shouldUpdate noStyle className="w-100">
			{({ }, { }, { getFieldValue }) => {
				const roles = getFieldValue("roles");
				const manageAbility = getFieldValue("manageAbility");

				if (!hasManagingRole(roles)) {
					return;
				}

				const manageLevels = canManageLevels(manageAbility);

				const manageLocations = canManageLocations(
					manageAbility,
					organisationLevelGroup,
				);

				const managePayGroups =
					manageAbility === "direct-users-and-pay-groups";

				const manageCalcGroups =
					manageAbility === "direct-users-and-calculation-group";

				const manageProjects =
					manageAbility === "direct-users-and-organisation-level-and-project";

				const manageOnlyProjects =
					manageAbility === "direct-project";

				return (
					<div className="border-top  my-3  py-4 w-100">
						<h5 className="mb-3 text-muted font-weight-400 text-uppercase">
							{t("managing")}
						</h5>

						<div className="px-4">
							<Row>
								<Col sm={12} md={6}>
									<Field
										name="manageAbility"
										label={t("manage-ability")}
										rules={[
											{
												required: true,
												message: t(
													"required-manage-ability",
												),
											},
										]}
									>
										<ManageAbilitySelect
											placeholder={t("manage-ability")}
										/>
									</Field>
								</Col>

								{manageLevels && (
									<Col sm={12} md={6}>
										<Field
											name="managedLevels"
											label={t("manage-levels")}
											rules={[
												{
													required: true,
													message: t("required-manage-levels"),
												},
											]}
										>
											<ResourceSelect
												labelPropName="description"
												resourcePath={`/${organisationLevelGroup?.id}/org-levels`}
												renderLabel={
													renderOrgLevelResourceLabel
												}
												mode="multiple"
												placeholder={t(
													"organisation-levels",
												)}
												hasSearch
												getSearchFilters={
													getSettingsOrgResourceSearchFilters
												}
											/>
										</Field>
									</Col>
								)}

								{manageLocations && (
									<Col sm={12} md={6}>
										<Field
											name="managedLocations"
											label={t("manage-locations")}
											rules={[
												{
													required: true,
													message: t(
														"required-manage-locations",
													),
												},
											]}
										>
											<ResourceSelect
												labelPropName="description"
												resourcePath="/locations"
												renderLabel={
													renderSettingsResourceLabel
												}
												mode="multiple"
												placeholder={t("locations")}
												hasSearch
												getSearchFilters={
													getSettingsResourceSearchFilters
												}
											/>
										</Field>
									</Col>
								)}

								{managePayGroups && (
									<Col sm={12} md={6}>
										<Field
											name="managedPayGroups"
											label={t("manage-pay-groups")}
											rules={[
												{
													required: true,
													message: t(
														"required-manage-pay-group",
													),
												},
											]}
										>
											<ResourceSelect
												mode="multiple"
												labelPropName="description"
												resourcePath="/pay-group"
												placeholder={t("pay-groups")}
												renderLabel={
													renderSettingsResourceLabel
												}
												hasSearch
												getSearchFilters={
													getSettingsResourceSearchFilters
												}
											/>
										</Field>
									</Col>
								)}

								{manageCalcGroups && (
									<Col sm={12} md={6}>
										<Field
											name="managedCalcGroups"
											label={t("manage-calculation-group")}
											rules={[
												{
													required: true,
													message: t(
														"required-manage-calculation-group"
													),
												},
											]}
										>
											<ResourceSelect
												mode="multiple"
												labelPropName="description"
												resourcePath="/calculation-group"
												placeholder={t("calculation-group")}
												renderLabel={
													renderSettingsResourceLabel
												}
												hasSearch
												getSearchFilters={
													getSettingsResourceSearchFilters
												}
											/>
										</Field>
									</Col>
								)}

								{(manageProjects || manageOnlyProjects) && (
									<Col sm={12} md={6}>
										<Field
											name="managedProjects"
											label={t("manage-projects")}
											rules={[
												{
													required: true,
													message: t(
														"required-manage-projects"
													),
												},
											]}
										>
											<ResourceSelect
												labelPropName="description"
												resourcePath="/project"
												renderLabel={
													renderSettingsResourceLabel
												}
												mode="multiple"
												placeholder={t("projects")}
												hasSearch
												getSearchFilters={
													getSettingsResourceSearchFilters
												}
											/>
										</Field>
									</Col>
								)}

								<Col sm={12} md={6}>
									<Field
										name="additionalLevels"
										label={t("additional-levels")}
									>
										<ResourceSelect
											labelPropName="description"
											resourcePath={`/${organisationLevelGroup?.id}/org-levels`}
											renderLabel={
												renderOrgLevelResourceLabel
											}
											mode="multiple"
											placeholder={t(
												"organisation-levels",
											)}
											hasSearch
											getSearchFilters={
												getSettingsOrgResourceSearchFilters
											}
										/>
									</Field>
								</Col>
							</Row>
						</div>
					</div>
				);
			}}
		</BaseField>
	);
}

export default Managing;
