import { useTranslation } from "react-i18next";
import Field from "../../../../components/Field";
import Select from "../../../../components/Inputs/Select";
import Text from "../../../../components/Inputs/Text";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";

function Attestation ({ attestations }) {
	const { t } = useTranslation();

	return (
		<div className="w-100 h-100" style={{ overflowY: "auto" }}>
			{attestations?.length > 0 && attestations?.map((attestation) => (
				<div className="shadow border my-3 p-4 rounded">
					<div className="font-weight-bold mb-3">
						{renderSettingsResourceLabel(attestation)}
					</div>
					
					{attestation?.customFields?.map((field) => ( 
						<Field 
							name={["customFields", attestation?.code, field?.code]}
							label={field?.description}
							rules={[
								{
									required: field.isRequired,
									message: t("required-field")
								}
							]}
						>
							{field?.type === "select" ? (
								<Select placeholder={field?.description} allowClear={!field?.isRequired}>
									{field?.options?.map(({ label, value }) => (
										<Select.Option value={value} key={value}>
											{label}
										</Select.Option>
									))}
								</Select>
							) : (
								<Text type={field?.type} placeholder={field?.description}/>
							)}
						</Field>
					))}
				</div>
			))}
		</div>
	)
}

export default Attestation; 
