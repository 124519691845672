import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Option } from "rc-select";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import Checkbox from "../../../../../components/Inputs/Checkbox";
import BaseSettingsFromContent from "../../../../../components/Form/BaseSettingsFormContent";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";

function FormContent({ mode, form }) {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess(
		"settings.general.organizationLevelGroup",
	);
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onIsRequiredChange = useCallback(() => {
		form.setFieldsValue({
			hasHierarchy: false,
			inclueLocationHierarchy: false,
		});
	}, [form]);

	return (
		<>
			<BaseSettingsFromContent
				mode={mode}
				disabled={disabled || !isMasterCompany}
			/>

			<Field
				name="level"
				label={t("level")}
				rules={[
					{
						required: true,
						message: t("required-level"),
					},
				]}
			>
				<Select
					disabled={disabled || mode === "edit" || !isMasterCompany}
				>
					<Option value="level_1">{t("level1")}</Option>
					<Option value="level_2">{t("level2")}</Option>
					<Option value="level_3">{t("level3")}</Option>
					<Option value="level_4">{t("level4")}</Option>
				</Select>
			</Field>

			<Field name="allocatedBy" valuePropName="checked">
				<Checkbox
					id="allocatedBy"
					disabled={disabled || !isMasterCompany}
				>
					{t("allocated-by")}
				</Checkbox>
			</Field>

			{/* <BaseField noStyle shouldUpdate>
				{({}, {}, { getFieldError }) => (
					<> */}
			<Field
				name="hasBudget"
				valuePropName="checked"
				disabled={disabled || !isMasterCompany}
			>
				<Checkbox id="hasBudget" disabled={disabled}>
					{t("has-budget")}
				</Checkbox>
			</Field>

			{/* <p className="text-danger text-sm">
							{getFieldError("hasBudget")}
						</p>  </>
				)}
			</BaseField> */}

			<Field name="useInClockIn" valuePropName="checked">
				<Checkbox id="useInClockIn">
					{t("use-in-clock-in")}
				</Checkbox>
			</Field>

			<Field name="isRequired" valuePropName="checked">
				<Checkbox
					id="isRequired"
					onChange={onIsRequiredChange}
					disabled={disabled || !isMasterCompany}
				>
					{t("required-to-have-level-set")}
				</Checkbox>
			</Field>

			<BaseField>
				{({ }, { }, { getFieldValue }) => {
					const isRequired = getFieldValue("isRequired");

					return (
						<>
							<Field
								name="hasHierarchy"
								valuePropName="checked"
								hidden={!isRequired}
							>
								<Checkbox
									id="hasHierarchy"
									hidden={!isRequired}
									disabled={disabled || !isMasterCompany}
								>
									{t("has-hierarchy")}
								</Checkbox>
							</Field>

							{/* <p className="text-danger text-sm">
								{getFieldError("hasHierarchy")}
							</p> */}
						</>
					);
				}}
			</BaseField>

			<BaseField>
				{({ }, { }, { getFieldValue }) => {
					const isRequired = getFieldValue("isRequired");
					const hasHierarchy = getFieldValue("hasHierarchy");

					return (
						<>
							<Field
								name="inclueLocationHierarchy"
								valuePropName="checked"
							>
								<Checkbox
									id="inclueLocationHierarchy"
									hidden={!isRequired || !hasHierarchy}
									disabled={disabled || !isMasterCompany}
								>
									{t("include-location-hierarchy")}
								</Checkbox>
							</Field>

							{/* <p className="text-danger text-sm">
								{getFieldError("inclueLocationHierarchy")}
							</p> */}
						</>
					);
				}}
			</BaseField>
		</>
	);
}

export default FormContent;
