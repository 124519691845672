import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardBody } from "reactstrap";
import List from "./List";

const Clocks = ({ clocks, isShiftView }) => {
	const { t } = useTranslation();

	if (!clocks) {
		return null;
	}

	return (
		<div className="col-12 col-lg-4 d-md-flex flex-md-column px-0 px-lg-2 h-100">
			<Card className="mb-0 h-100 mx-3 mx-lg-0">
				<CardHeader>
					<h5 className="h3 mb-0">{t("my-shift")}</h5>
				</CardHeader>

				<CardBody
					style={{
						maxHeight: "inherit",
						overflowY: "auto",
					}}
					className="scroll"
				>
					<List clocks={clocks} isShiftView={isShiftView} />
				</CardBody>
			</Card>
		</div>
	);
};

export default Clocks;
