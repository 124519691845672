import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { useDateFilters } from "../../../hooks/useDateFilters";

export const usePayGroupFilter = () => {
	const { t } = useTranslation();
	const { mode, payGroup, payGroupPeriod } = useDateFilters();

	const disableFilters = mode === "pay-group"
		&& payGroup !== undefined
		&& payGroupPeriod;

	return useMemo(
		() => ({
			id: "payGroup",
			Header: t("pay-group"),
			canFilter: true,
			filter: "resource-select",
			disableFilters,
			Filter: (
				<ResourceSelect
					mode="multiple"
					resourcePath={"/pay-group?pagination=off"}
					renderLabel={renderSettingsResourceLabel}
					valuePropName="id"
					getSearchFilters={getSettingsResourceSearchFilters}
					hasSearch
				/>
			),
		}),
		[t, disableFilters],
	);
};
