import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    ListGroup,
    ListGroupItem,
    Badge,
} from "reactstrap";
import { useUser } from "../../../utils/hooks/user";
import { useNotifications } from "../../../features/Notifications/context";
import { useNotificationRedirect } from "../../../features/Notifications/useNotificationRedirect";
import { useReadNotifications } from "../../../features/Notifications/useReadNotifications";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loaders/Loading";
import Item from "./Item";
import classNames from "classnames";

function Notifications() {
    const { t } = useTranslation();
    const user = useUser();
    const navigate = useNavigate();

    const { hasMore, fetchMore, notifications, setNotifications, loading, totalItems, unreadNotifications } =
        useNotifications();
    const { readOne, readAll } = useReadNotifications();

    const redirectNotification = useNotificationRedirect();
    const openNotification = useCallback(
        async (notification) => {
            if (!notification.read) {
                await readOne(notification.id);
                setNotifications(({ result: prev, totalItems: prevTotalItems }) => {
                    return {
                        result: prev.map((n) => (n.id === notification.id ? { ...n, read: true } : n)),
                        totalItems: prevTotalItems - 1,
                    };
                });
            }

            redirectNotification(notification.type, notification.data);
        },
        [readOne, setNotifications, redirectNotification]
    );

    const openAll = useCallback(async () => {
        await readAll();
        setNotifications(({ result: prev, totalItems: prevTotalItems }) => {
            return {
                result: prev.map((item) => ({
                    ...item,
                    read: true,
                })),
                totalItems: 0,
            };
        });
    }, [readAll, setNotifications]);

    return (
        <UncontrolledDropdown flip end right nav>
            <div className="position-relative">
                <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                    <i className="ni ni-bell-55" />
                </DropdownToggle>

                {unreadNotifications > 0 && (
                    <Badge className="position-absolute top--2 right--1" pill color="danger">
                        {unreadNotifications}
                    </Badge>
                )}
            </div>

            <DropdownMenu
                className={classNames(
                    "notification-dropdown-width py-0 overflow-hidden",
                    user?.companies?.length > 1 ? "notification-dropdown-menu" : "employee-notification-menu"
                )}
            >
                <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                        {t("count-notifications", {
                            count: unreadNotifications,
                        })}
                    </h6>
                </div>

                <ListGroup flush>
                    {false && hasMore && (
                        <ListGroupItem className="notification list-group-item-action" tag="a">
                            <h4
                                className="text-center text-info font-weight-bold m-0 cursor-pointer"
                                onClick={fetchMore}
                            >
                                {t("fetch-more")}
                            </h4>
                        </ListGroupItem>
                    )}

                    {notifications.map((notification) => (
                        <Item key={notification.id} notification={notification} onClick={openNotification} />
                    ))}

                    {loading && (
                        <ListGroupItem className="notification list-group-item-action" tag="a">
                            <div className="d-flex justify-content-center">
                                <Loading />
                            </div>
                        </ListGroupItem>
                    )}
                </ListGroup>

                <div className="d-flex gap-5 justify-content-center align-items-center px-3 py-3 mr-2">
                    {!loading && totalItems > 0 && (
                        <h4 className="text-info font-weight-bold m-0 cursor-pointer" onClick={openAll}>
                            {t("read-all")}
                        </h4>
                    )}
                    <div
                        className="d-flex align-items-center justify-content-center"
                        onClick={() => navigate("/notifications")}
                    >
                        <h4 className="text-info cursor-pointer m-0">{t("view-all")}</h4>
                    </div>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default Notifications;
