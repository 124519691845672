export const isEmail = (text) => /^\S+@\S+\.\S+$/.test(text);

export const firstToUpper = (str) =>
	str && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : "";

export const camelToSentenceCase = (str) => {
	const result = str
		.replace(/([A-Z])/g, " $1")
		.toLowerCase()
		.trim();
	return result.charAt(0).toUpperCase() + result.slice(1);
};

export const includesInsensitive = (s, needle) =>
	s.toLowerCase().includes(needle.toLowerCase());

export const initials = (user) => {
	const value = user?.firstName + " " + user?.lastName;

	let names = value.split(" "),
		initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}