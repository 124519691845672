import { noop } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader } from "reactstrap";
import Button from "../../../../components/Button";
import { useSettingsOnboarding } from "../context";

function Header({ canSkipRegardless, onSkip = noop, saveAndRepeat }) {
	const { t } = useTranslation();

	const {
		step,
		goBack,
		skip: skipStep,
		canSkip,
		loading,
	} = useSettingsOnboarding();

	const skip = useCallback(async () => {
		await onSkip();
		skipStep();
	}, [skipStep, onSkip]);

	return (
		<CardHeader className="actions d-flex align-items-center">
			<div className="d-flex justify-content-between w-100">
				<div>
					{step > 0 && (
						<Button
							className="font-weight-light shadow-none outline-none p-0"
							color="none"
							onClick={goBack}
							disabled={loading}
							type="button"
						>
							{t("back")}
						</Button>
					)}

					{(canSkipRegardless || canSkip) && (
						<Button
							className="font-weight-light shadow-none outline-none p-0"
							color="none"
							onClick={skip}
							disabled={loading}
							type="button"
						>
							{t("skip")}
						</Button>
					)}
				</div>

				<div>
					{saveAndRepeat && (
						<Button
							className="btn-icon border-default"
							color="none"
							size="sm"
							loading={loading}
							onClick={saveAndRepeat}
							type="button"
						>
							<span className="btn-inner--icon mr-1 ">
								<i className="ni ni-fat-add" />
							</span>
							<span className="btn-inner--text">
								{t("save-and-add")}
							</span>
						</Button>
					)}

					<Button
						className="btn-dark px-3 btn-round btn-icon"
						color="default"
						type="submit"
						name="continue"
						size="sm"
						loading={loading}
					>
						<span className="btn-inner--text">
							{t("save-and-continue")}
						</span>
					</Button>
				</div>
			</div>
		</CardHeader>
	);
}

export default Header;
