import {
	useState,
	useEffect,
	useRef,
	Children,
	isValidElement,
	cloneElement,
} from "react";

const mapStyle = { flexGrow: 1, height: "350px" };

function Map({ onClick, options, children }) {
	const ref = useRef(null);
	const [map, setMap] = useState(null);

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map, setMap]);

	useEffect(() => {
		if (map && options) {
			map.setOptions(options);
		}
	}, [map, options]);

	useEffect(() => {
		if (map) {
			window.google.maps.event.clearListeners(map, "click");

			if (onClick) {
				map.addListener("click", onClick);
			}
		}
	}, [map, onClick]);

	return (
		<>
			<div ref={ref} style={mapStyle} />
			{Children.map(
				children,
				(child) => isValidElement(child) && cloneElement(child, { map })
			)}
		</>
	);
}

export default Map;
