import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { NavLink } from "reactstrap";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Switch from "../../../components/Inputs/Switch";
import Text from "../../../components/Inputs/Text";
import Password from "../../../components/Inputs/Password";
import { useAuthentication } from "../context";
import { useLogin } from "./api";
import { getSubDomainFromDomain } from "../../../utils/helpers/url";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function LogInForm() {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { domain } = useAuthentication();

	const { login, loading, error } = useLogin();

	const onFinish = useCallback(
		(values) => {
			const subDomain = getSubDomainFromDomain(domain);
			login({ ...values, rememberMe: true, uniqueKey: subDomain });
			localStorage.setItem("i18nextLng", "en")
		},
		[domain, login],
	);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["username", "password"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form autocomplete="off" form={form} onFinish={onFinish}>
			<Field
				name="username"
				rules={[
					{
						required: true,
						message: t("required-employee-number-email"),
					},
				]}
			>
				<Text placeholder={t("employee-number")} />
			</Field>

			<Field
				name="password"
				rules={[
					{
						required: true,
						message: t("required-password"),
					},
				]}
			>
				<Password placeholder={t("password")} />
			</Field>

			<div className="d-flex justify-content-between">
				<BaseField name="rememberMe" valuePropName="checked">
					{(control) => (
						<Switch id="rememberMe" {...control}>
							{t("remember-me")}
						</Switch>
					)}
				</BaseField>

				<NavLink
					href="/reset-password"
					className="text-primary text-gradient font-weight-bold text-sm"
				>
					{t("reset-password")}
				</NavLink>
			</div>

			<Button
				color="primary"
				type="submit"
				className="btn btn-lg btn-lg w-100 mt-4 mb-0"
				loading={loading}
			>
				{t("sign-in")}
			</Button>
		</Form>
	);
}

export default LogInForm;
