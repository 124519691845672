import { useCallback } from "react"

export const useInitialRowStateAccessor = () => {
    return useCallback(() => {
        return {
            data: [],
            loading: false,
            totalItems: 0,
            date: null,
        };
    }, []);
}