import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useResetPassword } from "./api";

function ResetPasswordModal({ isOpen, user, close }) {
	const { t } = useTranslation();
	const [plainPassword, setPlainPassword] = useState();
	const { resetPassword, loading } = useResetPassword();

	const onResetPassword = useCallback(() => {
		if (user?.id) {
			resetPassword(user.id, (response) => {
				setPlainPassword(response);
				toast.success(t("employee-password-reseted"));
			});
		}
	}, [resetPassword, setPlainPassword, user?.id, t]);

	const onClose = useCallback(() => {
		setPlainPassword(undefined);
		close();
	}, [setPlainPassword, close]);

	return (
		<Modal isOpen={isOpen} centered toggle={onClose}>
			<div className="d-flex flex-column align-items-center my-5">
				<FontAwesomeIcon
					icon={plainPassword ? faCheckCircle : faQuestionCircle}
					style={{ fontSize: "37px" }}
					className={classNames(
						plainPassword ? "text-green" : "text-primary",
						"mb-3",
					)}
				/>

				{!plainPassword ? (
					<div className="font-weight-bold text-lg">
						{t(
							"do-you-want-to-reset-user-password", {
							user: user?.firstName + " " + user?.lastName
						}
						)}
					</div>
				) : (
					<span className="text-lg">
						{t(
							"user-new-password", {
							user: user?.firstName + " " + user?.lastName
						}
						)}{" "}
						<span className="text-dark font-weight-bold">
							{plainPassword}
						</span>
						{"."}
					</span>
				)}
			</div>

			<div className="d-flex justify-content-between">
				{!plainPassword ? (
					<Button
						color="primary"
						loading={loading}
						disabled={loading}
						onClick={onResetPassword}
					>
						{t("ok")}
					</Button>
				) : (
					<div />
				)}
				<Button color="dark" disabled={loading} onClick={onClose}>
					{t("close")}
				</Button>
			</div>
		</Modal>
	);
}

export default ResetPasswordModal;
