import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dateTimeFormat } from "../../../utils/helpers/date";
import { useCompanyDateFormat } from "../../../utils/hooks/company";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, isOpenFromTimesheet = false }) => {
	const { t } = useTranslation();
	const dateFormat = useCompanyDateFormat();

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("name", {
				header: t("document-name"),
				cell: (info) => {
					const { document, name } = info.row.original;
					return (
						<a
							href={`${process.env.REACT_APP_CDN_URL}/${document}`}
							className="text-primary"
						>
							{name}
						</a>
					);
				},
			}),
			columnHelper.accessor("createdAt", {
				header: t("created-at"),
				cell: (info) => dateTimeFormat(info.getValue(), dateFormat),
			}),
		];

		if (!isOpenFromTimesheet) {
			const actionsColumn = columnHelper.display({
				id: "actions",
				enableHiding: false,
				enableColumnFilter: false,
				header: (
					<div className="text-right">
						{t("actions")}
					</div>
				),
				cell: ({ row: { original } }) => (
					<div className="actions text-primary text-right">
						<span
							className="btn-inner--icon mr-3 icon-sm cursor-pointer"
							onClick={() => edit(original)}
							style={{ cursor: "pointer" }}
						>
							<i className="fas fa-pen" />
						</span>

						<span
							className="btn-inner--icon mr-3 icon-sm cursor-pointer"
							onClick={() => remove(original)}
							style={{ cursor: "pointer" }}
						>
							<i className="fas fa-trash" />
						</span>
					</div>
				),
			});
			columns.push(actionsColumn);
		}
		return columns;
	}, [remove, edit, dateFormat, isOpenFromTimesheet, t]);
};
