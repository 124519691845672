import {useMemo} from "react";
import {useTranslation} from "react-i18next";

export const useLastNameFilter = () => {
    const {t} = useTranslation();
    return useMemo(() => ({
        id: "lastName",
        Header: t("Last name"),
        filter: "text",
        canFilter: true,
    }), [t]);
}
