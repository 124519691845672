import { Tooltip } from "reactstrap";
import { renderCompanyResourceLabel, renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import "./style.scss";

const AllocationResourcesTooltip = ({ isOpen, close, data }) => {
    let allocatedResource, values;

    if (data.manageAbility === "by-calculation-groups") {
        allocatedResource = "Calculation Groups";
        values = data.calculationGroups.map((calcGroup) => calcGroup);
    }
    if (data.manageAbility === "by-location") {
        allocatedResource = "Locations";
        values = data.locations.map((calcGroup) => calcGroup);
    }
    if (data.manageAbility === "by-pay-groups") {
        allocatedResource = "Pay Groups";
        values = data.payGroups.map((calcGroup) => calcGroup);
    }
    if (data.manageAbility === "by-company") {
        allocatedResource = "Companies";
        values = data.companies.map((calcGroup) => calcGroup);
    }

    return (
        <Tooltip
            isOpen={isOpen}
            toggle={close}
            trigger="hover"
            target={"allocatedResources" + data.id}
            placement="right"
            className="allocated-resources-tooltip"
        >
            <div className="allocated-resources-container">
                <h4 className="text-primarykur ke pare ben po">{allocatedResource}</h4>
                <div className="d-flex flex-column">
                    {values?.map((value) => {
                        return (
                            <span>
                                {data?.manageAbility === "by-company"
                                    ? renderCompanyResourceLabel(value)
                                    : renderSettingsResourceLabel(value)}
                            </span>
                        );
                    })}
                </div>
            </div>
        </Tooltip>
    );
};

export default AllocationResourcesTooltip;
