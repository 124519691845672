import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import {
	Card,
	CardBody,
	CardHeader,
	CardText,
	CardTitle,
	CardFooter,
} from "reactstrap";
import Button from "../../../../../components/Button";

function Content({
	children,
	cardTitle,
	cardText,
	form,
	onFinish,
	initialValues,
	skip,
	goBack,
	loading,
	step,
}) {
	const { t } = useTranslation();

	return (
		<Card
			className="multisteps-form pt-3 border-radius-xl bg-white js-active"
			data-animation="FadeIn"
		>
			<CardHeader className="d-flex justify-content-center flex-column align-items-center p-2">
				<CardTitle className="font-weight-bolder mb-0 h5">
					{t(cardTitle)}
				</CardTitle>

				<CardText className="mb-0 text-sm">{t(cardText)}</CardText>
			</CardHeader>

			<Form
				form={form}
				onFinish={onFinish}
				initialValues={initialValues}
				id={step}
				className="multisteps-form__content"
			>
				<CardBody>{children}</CardBody>

				<CardFooter className="d-flex flex-column">
					{(step === 1 || step === 2) && (
						<div className="d-flex justify-content-between mb-5 border-bottom">
							<p className="text-muted text-sm p-0 m-0 skip-step-hide">
								{t("change-any-time")}
							</p>

							<p
								className="p-0 m-0 text-end text-uppercase cursor-pointer"
								onClick={skip}
							>
								{t("skip-step")}
							</p>
						</div>
					)}

					<div className="d-flex justify-content-between">
						{step === 0 ? (
							<div />
						) : (
							<Button
								className="btn bg-gradient-light mb-0 "
								onClick={goBack}
							>
								{t("prev")}
							</Button>
						)}

						<Button
							type="submit"
							className="btn bg-gradient-dark ms-auto mb-0 "
							loading={loading}
						>
							{t("next")}
						</Button>
					</div>
				</CardFooter>
			</Form>
		</Card>
	);
}

export default Content;
