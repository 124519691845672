import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from "reactstrap";
import { useTableContext } from "../../../../components/Table/context";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { formatTimeSheetParams } from "../../api/helpers";
import { useDateFilters } from "../../hooks/useDateFilters";

function BulkActions({ approve, unapprove, lock, unlock, remove, userId, filters }) {
	const { t } = useTranslation();

	const { visible, toggle } = useVisible();
	const {
		state: { selectedRowIds },
		selectedFlatRows,
	} = useTableContext();

	const { from, to } = useDateFilters();

	const ids = useMemo(() => {
		return Object.keys(selectedRowIds);
	}, [selectedRowIds]);

	const selectedDates = useMemo(() => {
		return [...(new Set((selectedFlatRows || []).map(row => row.original.date)))];
	}, [selectedFlatRows]);

	const onApprove = useCallback(() => {
		if (ids?.length > 0) {
			approve(ids);
		} else {
			const data = { ...formatTimeSheetParams({ from, to, filters }) };

			approve({ ...data, userId });
		}
	}, [ids, approve, from, to, filters, userId]);

	const onUnapprove = useCallback(() => {
		if (ids?.length > 0) {
			unapprove(ids);
		} else {
			const data = { ...formatTimeSheetParams({ from, to, filters }) };
			unapprove({ ...data, userId });
		}
	}, [ids, unapprove, from, to, filters, userId]);

	const onLock = useCallback(() => {
		if (ids?.length > 0) {
			lock(ids);
		} else {
			const data = { ...formatTimeSheetParams({ from, to, filters }) };

			lock({ ...data, userId });
		}
	}, [ids, lock, from, to, filters, userId]);

	const onUnlock = useCallback(() => {
		if (ids?.length > 0) {
			unlock(ids);
		} else {
			const data = { ...formatTimeSheetParams({ from, to, filters }) };

			unlock({ ...data, userId });
		}
	}, [ids, unlock, from, to, filters, userId]);

	return (
		<Dropdown isOpen={visible} toggle={toggle}>
			<span className="text-sm font-weight-600 text-dark mr-1 text-center">
				{t("selected")}{" "}
				<span
					className="text-sm rounded-circle py-1 px-2 mx-1"
					style={{ backgroundColor: "#ced4da" }}
				>
					{selectedFlatRows.length}
				</span>
			</span>

			<DropdownToggle
				className="btn-round btn-icon shadow-none border"
				size="sm"
			>
				{t("actions")}
			</DropdownToggle>

			<DropdownMenu>
				<DropdownItem onClick={onApprove} disabled={!approve}>
					{t("approve")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
				</DropdownItem>

				<DropdownItem onClick={onUnapprove} disabled={!unapprove}>
					{t("unapproved")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
				</DropdownItem>

				<DropdownItem onClick={onLock} disabled={!lock}>
					{t("lock")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
				</DropdownItem>

				<DropdownItem onClick={onUnlock} disabled={!unlock}>
					{t("unlock")} {" "} {ids?.length > 0 ? `(${ids?.length})` : `(${t("all")})`}
				</DropdownItem>

				<DropdownItem
					onClick={() => remove({ data: ids, dates: selectedDates, userId})}
					disabled={!remove || ids.length === 0}
				>
					{t("delete")} {" "} {ids?.length > 0 ? `(${ids?.length})` : ``}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

export default BulkActions;
