import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Text from "../../../components/Inputs/Text";
import { useAuthentication } from "../context";
import { useForgotPassword } from "./api";
import { getSubDomainFromDomain } from "../../../utils/helpers/url";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function ResetPasswordForm({ onNext }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { forgotPassword, loading, error } = useForgotPassword();

	const { domain } = useAuthentication();

	const onFinish = useCallback(
		(values) => {
			const subDomain = getSubDomainFromDomain(domain);
			forgotPassword(
				{ username: values.email, uniqueKey: subDomain },
				onNext
			);
		},
		[domain, getSubDomainFromDomain, forgotPassword, onNext]
	);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(["email"], error);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form autocomplete="off" form={form} onFinish={onFinish}>
			<Field
				name="email"
				rules={[
					{
						required: true,
						message: t("required-email"),
					},
				]}
			>
				<Text placeholder={t("email")} type="email" />
			</Field>

			<Button
				color="primary"
				type="submit"
				loading={loading}
				className="btn btn-lg btn-lg w-100 mt-4 mb-0"
			>
				{t("send")}
			</Button>
		</Form>
	);
}

export default ResetPasswordForm;
