import Form from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Modal from "../../../../components/Modal";
import { useTableContext } from "../../../../components/Table/context";

function AddTimesheetNote({ visible, close, values, submitting, onFinish }) {
	const id = values?.id;
	const isAdded = values?.isAdded;

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { updateAddedTimeSheet } = useTableContext();

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close])

	const localSubmit = useCallback((data) => {
		updateAddedTimeSheet(values, { note: data?.note });
		onClose();
	}, [values, updateAddedTimeSheet, onClose]);

	const submit = useCallback(
		(data) => {
			if (!id) {
				return;
			}
			if (values?.isAdded) {
				localSubmit(data);

			} else {
				onFinish(values?.id, {
					note: data?.note,
				}, onClose);
			}
		},
		[onFinish, id, isAdded, localSubmit, onClose],
	);

	useEffect(() => {
		form.setFieldsValue({ note: values?.note });
	}, [form, values?.note]);

	return (
		<Modal
			title={
				<div className="d-flex justify-content-between align-items-center text-primary">
					{t("add-note")}
					<i
						className="fa-solid fa-close cursor-pointer"
						onClick={onClose}
					/>
				</div>
			}
			toggle={close}
			isOpen={visible}
			size="sm"
			scrollable
			centered
		>
			<Form form={form} onFinish={submit}>
				<Field name="note" label={t("attach-request-note")}>
					<Text
						resize="none"
						rows="5"
						type="textarea"
						className="h-100"
					/>
				</Field>

				<footer className="note-border  p-2 border-top-0 text-sm">
					*{t("requests-send-for-manager-approval")}
				</footer>

				<div className="text-right">
					<Button color="primary" loading={submitting}>
						{t("save")}
					</Button>
				</div>
			</Form>
		</Modal>
	);
}

export default AddTimesheetNote;
