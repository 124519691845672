import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderSettingsResourceLabel } from "../../../../../../../utils/helpers/settings";

export const useLevelsValidations = () => {
  const { t } = useTranslation();
  const { groups, allocatedGroups } = useGroups();
  const isRequired = useCallback((level, value) => {
    if (!value) {
      return t(`${level} is required`);
    }
    return null;
  }, [t]);

  return useMemo(() => {
    if (!allocatedGroups) {
      return {};
    }
    return allocatedGroups?.reduce((total, group) => {
      const level = group.level.replace("_", "");
      total[level] = [isRequired.bind(null, renderSettingsResourceLabel(group))];
      return total;
    }, {});
  }, [groups, allocatedGroups, isRequired])
}
