import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../../components/Modal";
import { getUploadUrl } from "../../../../../../utils/helpers/upload";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { useUpdateFlyer } from "./api";

function FlyerModal({ hasAttestation, flyers, setFlyers }) {
    const { t, i18n: { language } } = useTranslation();
    const [page, setPage] = useState(0);
    const { updateFlyer, loading } = useUpdateFlyer();
    const [isOpen, setIsOpen] = useState(flyers?.length > 0);

    const images = useMemo(() => {
        return flyers[0]?.pages[language || "en"] || [];
    }, [flyers[0]?.pages, language]);

    const src = useMemo(() => {
        return getUploadUrl(images[page]);
    }, [images, page]);

    const onPrevClick = useCallback(() => {
        setPage((prev) => prev - 1)
    }, [setPage]);

    const onNextClick = useCallback(() => {
        if (images?.length > 0 && page === images?.length - 1 && flyers?.length > 1) {
            updateFlyer(flyers[0]?.id, (response) => {
                setFlyers((prev) => prev
                    ?.filter(({ id }) => id !== response?.flyerId));
                setPage(0);
            });
        } else if (page === images?.length - 1) {
            updateFlyer(flyers[0]?.id, (response) => {
                setFlyers((prev) => prev
                    ?.filter(({ id }) => id !== response?.flyerId));
                setIsOpen(false);
            });
        } else {
            setPage((prev) => prev + 1);
        }
    }, [
        images?.length,
        flyers[0]?.id,
        page,
        updateFlyer,
        setPage,
        setIsOpen,
        setFlyers
    ]);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    
    useEffect(() => {
        setIsOpen(flyers?.length > 0 && !hasAttestation);
    }, [setIsOpen, flyers?.length, hasAttestation]);

    return (
        <Modal
            isOpen={isOpen}
            title={
                <div className="d-flex justify-content-between">
                    <span>
                        {flyers[0] && renderSettingsResourceLabel(flyers[0])}
                    </span>

                    <FontAwesomeIcon
                        icon={faXmark}
                        className="cursor-pointer"
                        onClick={onClose}
                    />
                </div>
            }
            centered
            style={{ width: "fit-content" }}
        >
            <div className="w-100 mb-5">
                <img
                    className="w-100"
                    alt=""
                    src={src}
                    style={{ maxHeight: "400px", objectFit: "contain" }}
                />
            </div>

            <div className="d-flex align-items-center justify-content-center">
                <span
                    className="bg-primary text-white px-3 py-2 rounded text-center cursor-pointer mr-2 font-weight-bold"
                    style={{
                        fontSize: "15px"
                    }}
                    onClick={(page <= 0 || loading) ? undefined : onPrevClick}
                >
                    {t("prev")}
                </span>

                <span
                    className="bg-primary text-white px-3 py-2 rounded text-center cursor-pointer mr-2 font-weight-bold"
                    style={{
                        fontSize: "15px",
                        maxWidth: "fit-content",
                    }}
                >
                    {page + 1}
                </span>

                <span
                    className="bg-primary text-white px-3 py-2 rounded text-center cursor-pointer font-weight-bold"
                    style={{
                        fontSize: "15px",
                    }}

                    onClick={loading ? undefined : onNextClick}
                >
                    {page + 1 === images?.length
                        ? t("continue")
                        : t("next")}
                </span>
            </div>
        </Modal>
    );
}

export default FlyerModal;