import { useState, useCallback } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getGeocoderResultProperties } from "../helpers";
import { useGetLocationFields } from "../../../../../../utils/hooks/useGetLocationFields";
import { fromLatLng } from "../../../../../../utils/helpers/geocode";

const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };
const defaultZoom = 11;

export const useSearch = (changeMarker, setArea) => {
	const [search, setSearch] = useState("");

	const handleSelect = useCallback(
		async (value) => {
			const [result] = await geocodeByAddress(value);
			const marker = await getLatLng(result);

			const { address1 } = getGeocoderResultProperties(result);
			changeMarker(marker);
			setSearch(address1);
			setArea(undefined);
		},
		[setSearch, changeMarker, setArea],
	);

	return { search, setSearch, handleSelect };
};

export const useLocation = (form) => {
	const [marker, setMarker] = useState(null);
	const [zoom, setZoom] = useState(defaultZoom);
	const [center, setCenter] = useState(defaultCenter);
	const getFields = useGetLocationFields();

	const changeMarker = useCallback(
		async (marker) => {
			setMarker(marker);
			setCenter(marker);
			setZoom(17);

			const response = await fromLatLng(marker);
			if (!response) {
				return;
			}

			const {
				results: [result],
			} = response;

			const geocodeValues = getGeocoderResultProperties(result);
			const fields = await getFields(geocodeValues);

			form.setFieldsValue({ ...geocodeValues, ...fields });
		},
		[form, getFields, setMarker, setCenter, setZoom],
	);

	return {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	};
};
