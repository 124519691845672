import { Navigate } from "react-router-dom";
import { useAuthentication } from "../features/Authentication/context";

function Redirect() {
	const { token, authenticated, company, isMainDomain, user } =
		useAuthentication();

	const impersonate = localStorage.getItem("IMPERSONATE");
	const otpValidate = localStorage.getItem("OTP_VALIDATE");

	if (!token) {
		if (isMainDomain) {
			return <Navigate to="/domain-select" />;
		}
		return <Navigate to="/login" />;
	}

	if (
		user?.twoFA?.status === "active" &&
		!impersonate &&
		otpValidate === null
	) {
		return <Navigate to="/login/validate" />;
	}

	if (
		authenticated && !company &&
		((user?.twoFA?.status !== "active" &&
			(user?.twoFA?.type ||
				user?.twoFA?.type === null ||
				!user?.twoFA?.type)) ||
			otpValidate !== null)
	) {
		return <Navigate to="/company-selection" />;
	}

	if (user?.requirePasswordChange) {
		return <Navigate to="/required-set-password" />;
	}

	return <Navigate to="/" />;
}

export default Redirect;
