import _, { noop } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { Card, CardHeader } from "reactstrap";
import { useDateFilters } from "../../hooks/useDateFilters";

import { useColumns as useTeamColumns } from "../../Team/Table/useColumns";
import TimeSheetTable from "../../Weekly/Table/TimeSheetTable/Table";
import ColumnToggle from "../../Weekly/Table/TimeSheetTable/ColumnToggle";
import { useExpanded, useTable as useTeamTable } from "react-table";

import { useColumns } from "../../Weekly/Table/TimeSheetTable/hooks/shared/useColumns";
import { useTable } from "../../Weekly/Table/TimeSheetTable/hooks/shared/useTable";

import { useDateActions } from "../../Weekly/Table/TimeSheetTable/hooks/useDateActions";
import { usePermissions } from "./hooks/usePermissions";
import { useSubmit } from "../../Weekly/Table/TimeSheetTable/hooks/useSubmit";

import "../../Weekly/Table/TimeSheetTable/_style.scss";
import Table from "../Table";

import { useUser } from "../../../../utils/hooks/user";
import useApi from "../../../../utils/api";
import { formatTimeSheetParams } from "../../api/helpers";
import useSWR from "swr";
import axios from "axios";
import moment from "moment-timezone";
import { useOverview } from "../../components/Topbar/context";
import { useElementHeight } from "../../../../utils/hooks/useElementHeight";
import { useSummary } from "../../Weekly/hooks/useSummary";
import { useCopyTimeSheet } from "../../Weekly/Actions/useCopyTimeSheet";
import Summary from "../Actions/Summary";
import Loading from "../../../../components/Loaders/Loading";
import { TableContext } from "../../../../components/Table/context";
import Download from "../Actions/Download";
import { useExport } from "./hooks/useExport";

const filters = {};

const useMyTimeSheetUserOverall = (filters) => {
	const user = useUser();
	const { authGet } = useApi();

	const key = useMemo(() => {
		if (!user?.id) {
			return null;
		}
		return {
			url: "/time-sheet/weekly/team",
			params: formatTimeSheetParams({
				...filters,
				userId: user?.id,
			}),
		};
	}, [user?.id, filters]);

	const fetcher = useCallback(async ({ url, params }) => {
		const { result } = await authGet(url, { params });
		return result[0];
	}, [authGet]);

	const {
    data,
    isLoading,
    mutate,
  } = useSWR(key, fetcher);

	const refetch = useCallback(() => {
		mutate();
	}, [mutate]);

  return { data, isLoading, refetch };
}

const useTimesheets = () => {
  const { from, to } = useDateFilters();
  const { authGet } = useApi();
  const {
    data,
    isMutating,
    mutate,
  } = useSWR(
    axios.getUri({
      url: "/time-sheet",
      params: {
        pagination: false,
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
        hourType: "unit",
      },
    }),
    async (url) => {
      const response = await authGet(url);
      return response.result;
    },
  );
  const refetch = useCallback(() => {
    mutate();
  }, [mutate]);

  return { data, isMutating, refetch };
}

function Content({
  user,
  loadingUser,
  refetchUser,
}) {
	const { ref: actionsRef, height: actionsHeight } = useElementHeight();

  const { from, to } = useDateFilters();

	const { fetch: baseFetchOverview } = useOverview();
	const fetchOverview = useCallback(
		(config) => {
			baseFetchOverview({
        url: "/time-sheet/overview",
				...config,
			});
		},
		[baseFetchOverview],
	);
  const refetchOverview = useCallback(() => {
    fetchOverview({
      params: { from, to },
    });
  }, [fetchOverview, from, to]);

  const {
    data,
    isMutating: loadingTimeSheet,
    refetch: refetchTimeSheets,
  } = useTimesheets();

  const {
    approve,
    unapprove,
    lock,
    unlock,
    isMutating: isLoadingDateAction,
  } = useDateActions({
    userId: user?.id,
    onSuccess: refetchTimeSheets,
    onFailure: noop,
  });

  const [openCopyModal, copyModal] = useCopyTimeSheet({
    filters: useMemo(() => {
      return {
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
      };
    }, [from, to]),
    refetch: useCallback(() => {
      return Promise.all([
        refetchOverview(),
        refetchUser(),
        refetchTimeSheets(),
      ]);
    }, [refetchOverview, refetchUser, refetchTimeSheets]),
  });

  const {
    visible: summaryVisible,
    selected: summaryData,
    openDaySummary,
    close: closeSummary,
  } = useSummary();

  const columns = useColumns({
    user,
    // openCopyModal,
    openDaySummary,
  });

  const permissions = usePermissions();

  const table = useTable({
    user,
    columns,
    data: useMemo(() => data || [], [data]),

    permissions,
    lock, unlock, approve, unapprove, isLoadingDateAction,
  });
  const { reset } = table.options.meta;

  const { trigger, isMutating } = useSubmit({
    user,
    data: table.options.data,
    onSuccess: async () => {
      await Promise.all([
        refetchTimeSheets(),
        refetchUser(),
        refetchOverview(),
      ]);
      reset();
    },
    onFailure: noop,
  });

	useEffect(() => {
		const controller = new AbortController();
		fetchOverview({
			params: { from, to },
			signal: controller.signal,
		});
		return () => controller.abort();
	}, [fetchOverview, from, to]);

	const teamTable = useTeamTable(
		{
			data: useMemo(() => {
				if (!user) {
					return [];
				}
				return [user];
			}, [user]),
			columns: useTeamColumns({}),
			defaultColumn: {
				hideable: true,
				alwaysVisible: false,
			},
			getRowId: useCallback(({ id }) => id, []),
			autoResetExpanded: false,
		},
		useExpanded,
	);
  useEffect(() => {
    teamTable.toggleAllRowsExpanded(true);
  }, [teamTable]);

  const { downloadSummary, downloadDetails } = useExport({
    // ids: [user.id],
    columns: teamTable.columns.map(({ id }) => id),
    filters: [],
  });

  return (
    <>
      <Card innerRef={actionsRef} className="mb-0 position-static">
        <CardHeader className="border-top p-3">
          <div className="d-flex justify-content-end align-items-center">
            <Download
              downloadSummary={downloadSummary}
              downloadDetails={downloadDetails}
            />
            <ColumnToggle
              prefix={`timesheet-user-${user.id}`}
              columns={table.getAllColumns()}
            />
          </div>
        </CardHeader>
      </Card>

      <div style={{ maxHeight: `calc(100% - ${actionsHeight}px)` }}>
				<TableContext.Provider value={teamTable}>
          <Table loading={loadingUser}>
            <div className="timesheet-weekly">
              <TimeSheetTable
                table={table}
                loading={loadingTimeSheet}
                submit={trigger}
                isSubmitting={isMutating}
              />
            </div>
          </Table>
				</TableContext.Provider>
      </div>

      {summaryVisible && (
        <Summary
          open={summaryVisible}
          close={closeSummary}
          user={summaryData?.user}
          range={summaryData?.range}
          filters={filters}
        />
      )}

      {copyModal}
    </>
  )
}

export default function() {
  const { from, to } = useDateFilters();
	const {
		data: user,
		isLoading: loadingUser,
		refetch: refetchUser,
	} = useMyTimeSheetUserOverall({ from, to });

  if (!user) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <Content user={user} loadingUser={loadingUser} refetchUser={refetchUser} />
  );
};