import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from "reactstrap";

const config = [
	{ label: "All members", value: "all", color: "transparent" },
	{ label: "Clocked in", value: "clocked-in", color: "#5428e0" },
	{ label: "Clocked out", value: "clocked-out", color: "red" },
	{ label: "On break", value: "on-break", color: "#F99B16" },
	{ label: "Not active", value: "not-active", color: "#6B7280" },
	{ label: "Time off", value: "time-off", color: "#FB7185" },
];

function Menu({ setStatus, setPage, setSelectedUsers }) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const onItemClick = useCallback(
		(value) => {
			setStatus(value);
			setIsOpen(false);
			setPage(0);
		},
		[setStatus, setIsOpen, setPage],
	);

	return (
		<ButtonDropdown
			isOpen={isOpen}
			toggle={() => {
				setIsOpen(!isOpen);
			}}
			direction="right"
		>
			<DropdownToggle className="shadow-none border-0 bg-transparent p-2">
				<i
					className="fa-solid fa-filter text-muted"
					style={{
						fontSize: "14px"
					}}
				/>
			</DropdownToggle>

			<DropdownMenu>
				{config.map(({ label, value, color }) => (
					<DropdownItem
						key={value}
						onClick={() => {
							onItemClick(value);
							setSelectedUsers([]);
						}}
						className="item"
					>
						<i
							className="fa-solid fa-check text-white icon"
							style={{
								backgroundColor: color,
							}}
						/>

						{t(label)}
					</DropdownItem>
				))}
			</DropdownMenu>
		</ButtonDropdown>
	);
}

export default Menu;
