import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import { useTranslation } from "react-i18next";

function PrintComponent({ id, location, description, project }) {
	const { t } = useTranslation();
	const handlePrint = useReactToPrint({
		content: () => document.getElementById(`qr-content-${id}`),
	});

	return (
		<>
			<span
				className="btn-inner--icon mr-3 icon-sm cursor-pointer"
				onClick={handlePrint}
				title={t("QR code")}
			>
				<i className="fas fa-qrcode" />
			</span>

			<span className="d-none">
				<ComponentToPrint
					id={id}
					location={location}
					description={description}
					project={project}
				/>
			</span>
		</>
	);
}

export default PrintComponent;
