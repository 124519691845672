import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Form from "rc-field-form";
import { FormGroup } from "reactstrap";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import DomainInput from "../../../components/Inputs/DomainInput";
import { useValidateDomain } from "../../../utils/api/company";
import { getSubDomainRedirect } from "../../../utils/helpers/url";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function DomainSelectForm() {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const validateDomain = useValidateDomain();

	const onFinish = useCallback(
		async (values) => {
			setLoading(true);
			setError(null);

			try {
				const res = await validateDomain(values.domain);

				if (res?.status) {
					const url = getSubDomainRedirect(values.domain);
					window.location.replace(url);
				} else {
					setError(t("domain-not-found"));
					toast.error(t("domain-not-found"));
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[validateDomain, getSubDomainRedirect, setLoading, setError, t]
	);

	useEffect(() => {
		const formErrors = generateErrorsConfigForForm(["domain"], error);
		form.setFields(formErrors);
	}, [form, error]);

	return (
		<Form noValidate form={form} onFinish={onFinish}>
			<FormGroup>
				<Field
					name="domain"
					rules={[
						{
							required: true,
							message: t("required-domain"),
						},
					]}
				>
					<DomainInput />
				</Field>
			</FormGroup>

			<Button
				color="primary"
				type="submit"
				loading={loading}
				className="btn btn-lg btn-lg w-100 mt-4 mb-0"
			>
				{t("continue")}
			</Button>
		</Form>
	);
}

export default DomainSelectForm;
