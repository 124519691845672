import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderReportsLabel } from "../Form";
import { createColumnHelper } from "@tanstack/react-table";
import { systemReports } from "../../../../Reports/SystemReports/Table/systemReports";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import moment from "moment-timezone";

const columnHelper = createColumnHelper();

export const useColumns = () => {
	const { t } = useTranslation();

	const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();

	return useMemo(
		() => [
			columnHelper.accessor("cron.config", {
				header: t("report"),
				cell: (info) => {
					const value = info.getValue();
					if (value.isSystem) {
						return systemReports.find(({ id }) => id === value.report).name;
					}
					return value.report && renderReportsLabel(value.report);
				},
			}),
			columnHelper.accessor("createdAt", {
				header: t("date"),
				cell: (info) => {
					return moment(info.getValue()).format(`${dateFormat} ${timeFormat}`)
				},
			}),
			columnHelper.accessor("sendVia", {
				header: t("send-via"),
				cell: ({ getValue, row: { original } }) => {
					const value = getValue();
					const id = value === "email"
						? `email-${original?.id}`
						: `ftp-${original?.id}`;
					return value && (
						<span id={id}>
							{value === "ftp" ? t("ftp") : t("email")}
						</span>
					);
				},
			}),
			columnHelper.accessor("status", {
				header: t("status"),
				cell: (info) => {
					const value = info.getValue();
					let color;
					switch (value) {
						case "Failed": {
							color = "red";
							break;
						}
						case "Success": {
							color = "green";
							break;
						}
						default:
							break;
					}
					return (
						value && (
							<span
								className="text-center px-3 py-1"
								style={{
									border: `1px solid ${color}`,
									color: color,
									borderRadius: "20px",
								}}
							>
								{t(value)}
							</span>
						)
					);
				},
			}),
			columnHelper.accessor("message", {
				header: t("message"),
				cell: (info) => {
					const value = info.getValue();
					return value && (
						<span className="text-sm">{value}</span>
					);
				},
			}),
		],
		[t],
	);
};
