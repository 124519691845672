import { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import SearchInput from "./SearchInput";
import Select from "../Inputs/Select";
import Notifications from "./Notifications";
import { useAuthentication } from "../../features/Authentication/context";
import { useImpersonate } from "../../features/People/api";
import { useLogout } from "../../features/Authentication/api";
import { useUser } from "../../utils/hooks/user";
import { getUploadUrl } from "../../utils/helpers/upload";
import { initials } from "../../utils/helpers/string";
import { useAccess } from "../../utils/hooks/access";
import { renderCompanyResourceLabel } from "../../utils/helpers/settings";
import { adaptMomentToCompany } from "../../utils/helpers/adaptMomentToCompany";
import { useCompany } from "../../utils/hooks/company";
import getEnTranslate from "../../utils/helpers/moment/getEnTranslate";
import getSqTranslate from "../../utils/helpers/moment/getSqTranslate";
import getEsTranslate from "../../utils/helpers/moment/getEsTranslate";
import "./_style.scss";
import useApi from "../../utils/api";
import { getErrorMessage } from "../../utils/helpers/errors";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { hasAccess: canViewProfile } = useAccess("profile.canView");
  const user = useUser();
  const company = useCompany();
  const { logout } = useLogout();
  const { dispatch } = useAuthentication();
  const { logout: impersonateLogout } = useImpersonate();
  const { authPut } = useApi();

  const impersonate = localStorage.getItem("IMPERSONATE");

  const setUserLng = useCallback(async (lng) => {
    try {
      return await authPut("/profile/set-language", {
        data: {
          language: lng
        }
      })      
    } catch(error) {
      getErrorMessage(error, t)
    }
  }, [authPut, t])

  let language = localStorage.getItem("i18nextLng");
  const changeLanguage = useCallback(
    (lng) => {
      localStorage.setItem("i18nextLng", lng);
      setUserLng(lng);
      i18n.changeLanguage(lng);
    },
    [i18n, setUserLng]
  );

  const languageIcon = useMemo(() => {
    let icon = "fi fi-us";
    switch (language) {
      // case "sq":
      // 	icon = "fi fi-al";
      // 	break;
      case "es":
      	icon = "fi fi-es";
      	break;
      case "en":
        icon = "fi fi-us";
        break;
      default:
	break;
    }

    return <span className={icon} />;
  }, [language]);

  const onCompanyChange = useCallback(
    (id) => {
      const company = user?.companies.find((company) => company.id === id);
      if (company) {
        localStorage.setItem("COMPANY", company.id);
        adaptMomentToCompany(company, user);
        dispatch({ type: "set-company", payload: { company } });
        navigate("/");
      }
    },
    [dispatch, user, navigate]
  );

  const logOut = useCallback(
    (e) => {
      e.preventDefault();

      if (impersonate) {
        impersonateLogout(() => navigate("/"));
      } else {
        logout();
        localStorage.removeItem("i18nextLng");
        localStorage.removeItem("OTP_VALIDATE");
        if (company) {
          adaptMomentToCompany(company, user);
        }
      }
    },
    [impersonate, company, user, impersonateLogout, logout, navigate]
  );

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  const isDemoEnvironment = process.env.REACT_APP_IS_DEMO === "true";
  const isTestEnvironment = process.env.REACT_APP_ENV === "development" && !isDemoEnvironment;

  let navbarClass = isTestEnvironment
    ? "navbar-dark bg-purple"
    : theme === "dark"
      ? "navbar-dark bg-info"
      : theme === "light"
        ? "navbar-light bg-secondary"
        : "";

  return (
    <>
      <Navbar
        className={classnames(
          "admin-navbar navbar-top navbar-expand",
          navbarClass
        )}
      >
        <Container fluid style={{ zIndex: 2 }}>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >
              <SearchInput close={closeSearch} />
            </Form>

            {(isTestEnvironment) && (
              <div
                className="text-white font-bold ml-auto"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {t("test-environment")}
              </div>
            )}

            <Nav
              className="align-items-center ml-md-auto navbarResponsive"
              navbar
            >
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    {
                      "sidenav-toggler-dark": theme === "dark",
                    }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>

              <NavItem className="search-bar-icon">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>

              {user?.companies && user?.companies?.length > 1 && (
                <Select
                  value={company?.id}
                  onChange={onCompanyChange}
                  className="company-selector"
                  showSearch
                  optionFilterProp="children"
                >
                  {user?.companies?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {renderCompanyResourceLabel(item)}
                    </Select.Option>
                  ))}
                </Select>
              )}

              <Notifications />
            </Nav>

            <Nav
              className="align-items-center ml-auto ml-md-0 position-relative"
              navbar
            >
              <UncontrolledDropdown flip end right nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    {user?.image ? (
                      <img
                        src={getUploadUrl(user?.image)}
                        className="avatar rounded-circle"
                        width={37}
                        height={37}
                        alt={`${user?.firstName} ${user?.lastName} profile`}
                      />
                    ) : (
                      <div
                        className="avatar rounded-circle d-flex align-items-center bg-muted"
                        style={{ cursor: "auto" }}
                      >
                        <h3 className="text-white mb-0">{initials(user)}</h3>
                      </div>
                    )}

                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {`${user?.firstName} ${user?.lastName}`}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>

                <DropdownMenu right className="profile-dropdown">
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{t("welcome")}!</h6>
                  </DropdownItem>

                  {canViewProfile && (
                    <DropdownItem
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/job-pay");
                      }}
                    >
                      <i className="ni ni-single-02" />
                      <span>{t("my-profile")}</span>
                    </DropdownItem>
                  )}

                  {/* <DropdownItem
										href="#pablo"
										onClick={(e) => e.preventDefault()}
									>
										<i className="ni ni-settings-gear-65" />
										<span>Settings</span>
									</DropdownItem> */}

                  {/* <DropdownItem
										href="#pablo"
										onClick={(e) => e.preventDefault()}
									>
										<i className="ni ni-calendar-grid-58" />
										<span>Activity</span>
									</DropdownItem> */}

                  {/* <DropdownItem
										href="#pablo"
										onClick={(e) => e.preventDefault()}
									>
										<i className="ni ni-support-16" />
										<span>Support</span>
									</DropdownItem> */}

                  <DropdownItem divider />

                  <DropdownItem onClick={logOut}>
                    <i className="ni ni-user-run" />
                    <span>{t("logout")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>

            <Nav className="align-items-center position-relative" navbar>
              <UncontrolledDropdown direction="left" nav>
                <DropdownToggle className="nav-link pr-2" tag="a">
                  <Media className="align-items-center">
                    <Media className="text-white text-sm font-weight-bold text-uppercase">
                      {languageIcon}
                      <i className="fa-solid fa-chevron-down ml-1 cursor-pointer" />
                    </Media>
                  </Media>
                </DropdownToggle>

                <DropdownMenu className=" language-select py-0 overflow-hidden">
                  <DropdownItem
                    onClick={() => {
                      changeLanguage("en");
                      moment.updateLocale("en", getEnTranslate());
                    }}
                  >
                    {t("en")}
                  </DropdownItem>
                  {/* <DropdownItem onClick={() => {
										changeLanguage("sq");
										moment.updateLocale("sq", getSqTranslate());
									}}>
										{t("sq")}
									</DropdownItem> */}
									<DropdownItem onClick={() => {
										changeLanguage("es");
										moment.updateLocale("es", getEsTranslate());
									}}>
										{t("es")}
									</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>

        <div
          className={classnames("navbar-bottom-background", {
            "bg-purple": isTestEnvironment,
          })}
        />
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
