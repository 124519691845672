import { useContext, useEffect, useMemo, useState } from "react";
import SimpleTable from "../../../../components/SimpleTable";
import { useJobHistory } from "./api";
import { useColumns } from "./useColumns";
import Pagination from "../../../../components/NTable/Pagination";
import { ProfileContext } from "../../context";
import { useAccess } from "../../../../utils/hooks/access";

function Job() {
	const { isSelf } = useContext(ProfileContext);

	const { hasAccess: canViewJobHistory } = useAccess(
		isSelf ? "profile.viewJobHistory" : "people.viewJobHistory"
	);
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(20);
	const { 
		fetch, 
		data, 
		totalItems, 
		loading 
	} = useJobHistory();
	const columns = useColumns();

	const pageCount = useMemo(() => {
		return Math.ceil(totalItems / perPage);
	}, [totalItems, perPage]);

	useEffect(() => {
		const controller = new AbortController();
		if (canViewJobHistory) {
			const params = { page: page + 1, perPage }
			fetch(params, controller);
		}
		return () => controller.abort();
	}, [fetch, page, perPage, canViewJobHistory]);

	return canViewJobHistory && (
		<>
			<SimpleTable 
				data={data} 
				columns={columns} 
				loading={loading}
			/>

			<Pagination
				page={page}
				perPage={perPage}
				totalItems={totalItems}
				gotoPage={setPage}
				setPerPage={setPerPage}
				pageCount={pageCount}
			/>
		</>
	)
}

export default Job;
