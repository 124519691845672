import Layout from "../Layout";
import Card from "../Layout/Card";
import LogInForm from "./Form";

function LogIn() {
	return (
		<Layout
			image="/assets/images/alexander-the-great.jpg"
			text="There is nothing impossible to they who will try."
			autor="Alexander the Great"
		>
			<Card
				cardTitle="sign-in"
				cardText="enter-email-password"
				cardFooter="dont-have-account"
				link={false}
				linkText="sign-up"
				className="text-start"
				cardBody={<LogInForm />}
			/>
		</Layout>
	);
}

export default LogIn;
