import { applyTimezone } from "../../../../utils/helpers/date";

export const formatSubmittedData = (company, values) => {
	const {
		name,
		employeeNumberLength,
		defaultEmployeeNumber,
		executivePayrollEmailAddress,
		settings,
		logo,
		backgroundImage,
		badgeIdLength,
	} = values;

	return {
		...company,
		badgeIdLength,
		name: name,
		employeeNumberLength,
		defaultEmployeeNumber,
		executivePayrollEmailAddress,
		logo,
		backgroundImage,
		settings: {
			...company.settings,
			...settings,
			countries: settings.countries.map((country) => country.id),
			timezone: settings.timezone.id,
			maxShiftLength: Number(settings?.maxShiftLength),
			schedule: {
				...settings?.schedule,
				shiftStart: applyTimezone(
					values?.settings?.schedule?.shiftStart, 
					company?.settings?.timezone?.value,
				    true
				).toISOString(true),
				shiftEnd: applyTimezone(
					values?.settings?.schedule?.shiftEnd, 
					company?.settings?.timezone?.value,
					true
				).toISOString(true)
			}
		},
	};
};
