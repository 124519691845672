import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import Field from "../../../../../../../components/Field";
import SubSection from "./SubSection";
import { getCustomFieldInput } from "../../../../../components/TimeSheetTable/useColumns/useCustomFieldsColumns";
import { useCustomFields } from "../../../../../hooks/useCustomFields";

const CustomFieldInput = ({ config, value, onChange }) => {
    return getCustomFieldInput({ config, value, onChange });
}

function CustomFields() {
    const { t } = useTranslation();
    const { data: customFields, loading: loadingCustomFields } = useCustomFields();

    return (
        customFields?.length > 0 && (
            <SubSection title={t("custom-fields")} loading={loadingCustomFields}>
                <Row>
                    {customFields?.map((customField, i) => {
                        const isOdd = customFields.length % 2 !== 0;
                        const isLast = (customFields.length - 1) === i;
                        const size = isOdd && isLast ? 12 : 6;

                        return (
                            <Col md={size} key={customField.id}>
                                <Field
                                    key={customField.code}
                                    name={["customFields", customField.code]}
                                    label={customField.description}
                                >
                                    <CustomFieldInput config={customField} />
                                </Field>
                            </Col>
                        );
                    })}
                </Row>
            </SubSection>
        )
    );
}

export default CustomFields;
