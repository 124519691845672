import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";

export const manageAbilityOptions = [
	{ value: "custom", label: "custom" },
	{ value: "by-company", label: "By company" },
	{ value: "by-location", label: "By locations" },
	{ value: "by-pay-groups", label: "By pay groups" },
	{ value: "by-unions", label: "By unions" },
	{
		value: "by-projects-and-pay-groups",
		label: "By projects and pay groups",
	},
	{
		value: "by-pay-group-and-employee-type",
		label: "By pay group and employee type",
	},
	{ value: "by-organisation-level", label: "By organisation level" },
	{ value: "by-company-and-location", label: "By company and locations" },
	{
		value: "by-company-and-organisation-level",
		label: "By company and organisation level groups",
	},
	{
		value: "by-company-and-location-and-organisation-level",
		label: "By company, locations and organisation level groups",
	},
	{ value: "by-company-and-pay-groups", label: "By company and pay groups" },
];

function ManageAbilitySelect({ form, setLocations, values, disabled }) {
	const { t } = useTranslation();

	const onManageAbilityChange = useCallback(() => {
		setLocations(values?.locations || []);
		form.setFieldsValue({
			companies: [],
			locations: [],
			managedLevels: [],
			payGroups: [],
			employeeTypes: [],
			projects: [],
			unions: [],
		});
	}, [form, values, setLocations]);

	return (
		<Field
			name="manageAbility"
			label={t("manage-ability")}
		>
			<Select
				placeholder={t("manage-ability")}
				onChange={onManageAbilityChange}
				disabled={disabled}
			>
				{manageAbilityOptions.map(({ value, label }) => (
					<Select.Option key={value} value={value}>
						{t(label)}
					</Select.Option>
				))}
			</Select>
		</Field>
	);
}

export default ManageAbilitySelect;
