import { useState, useCallback } from "react";
import useApi from "../../../utils/api";

export const useUpdateTimeSheet = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { authPut } = useApi();

	const submit = useCallback(
		async (id, data, onSuccess, onFailure) => {
			setLoading(true);
			setError(error);
			try {
				const response = await authPut(`/time-sheet/${id}`, { data });
				await onSuccess?.(response);
			} catch (err) {
				await onFailure?.(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPut, error]
	);

	return { submit, loading, error };
};
