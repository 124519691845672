import { useTranslation } from "react-i18next";
import Modal from "../../../../../../components/Modal";

function NoteModal({ note, close, visible, toggle }) {
    const { t } = useTranslation();

    return <Modal
        title={
            <div className="d-flex justify-content-between align-items-center">
                {t("note")}
                <i className="fa-solid fa-close cursor-pointer" onClick={close} />
            </div>
        }
        toggle={toggle}
        isOpen={visible}
        size="sm"
        scrollable
        centered
    >
        <div className="text-dark d-flex flex-column p-2">
            {note ? (
                note
            ) : (
                <>
                    <span className="text-center border border-bottom-0 py-2 w-100">
                        {t("no-note")}
                    </span>
                    <i className="fa-solid fa-text-slash text-center border border-top-0 py-2" />
                </>
            )}
        </div>
    </Modal>
}

export default NoteModal;