import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNonAuthApi } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useSignUp = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { nonAuthPost } = useNonAuthApi();

	const signUp = useCallback(
		async (data, onSuccess) => {
			setLoading(true);
			setError(null);
			try {
				await nonAuthPost("/register", {
					data: { ...data, plainPassword: "to be removed" },
				});
				onSuccess();
			} catch (err) {
				if (err === "Email already in use") {
					setError({ email: [err] });
				} else {
					setError(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[nonAuthPost],
	);

	return { signUp, loading, error };
};

export const useResendVerificationLink = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { nonAuthPost } = useNonAuthApi();

	const submit = useCallback(
		async (email) => {
			setLoading(true);
			setError(null);

			const resetLoadingMessage = toast(
				t("resending-email"),
			);

			try {
				await nonAuthPost("/resend-verfication-link", {
					data: { email },
				});

				resetLoadingMessage();

				toast.success(t("email-sent-successfully"));
			} catch (err) {
				resetLoadingMessage();
				getErrorMessage(err, t);
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[t, setLoading, setError, nonAuthPost, getErrorMessage],
	);

	return { submit, loading, error };
};
