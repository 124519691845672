import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { omit } from "lodash";
import Analytics from "../../../../components/Analytics";
import { renderDurationAsNumber } from "../../../../utils/helpers/date";
import { useFetchMyScheduleAnalytics } from "../../api/useFetchAnalytics";

const hourTypes = [
    "regular",
    "overtime",
    "double-time",
    "pto",
    "holiday",
];

function MySchedulerAnalytics({ params }) {
    const { t } = useTranslation();
    const { data, isLoading } = useFetchMyScheduleAnalytics({
        filters: omit(params, "startDate", "endDate", "pageIndex", "pageSize"),
        startDate: moment(params?.startDate).format("YYYY-MM-DD"),
        endDate: moment(params?.endDate).format("YYYY-MM-DD"),
    });

    const renderDuration = useCallback((value) => {
        return renderDurationAsNumber(value);
    }, []);

    const breaks = useMemo(() => {
        return data.find(({ hourType }) => hourType === "break")
            ?.duration || 0;
    }, [data]);

    const statistics = useMemo(() => [
        {
            label: t("break-hours"),
            value: renderDuration(breaks, "HH:mm"),
        },
    ], [t, breaks]);

    return (
        <Analytics
            hourTypes={hourTypes}
            data={data}
            loading={isLoading}
            statistics={statistics}
        />
    );
}

export default MySchedulerAnalytics;
