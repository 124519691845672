import { useMemo } from "react";
import { useColumns as useTimesheetColumns } from "../../../../TimeSheet/components/TimeSheetTable/useColumns"
import { useGroups } from "../../../General/OrganisationLevelGroups/context";
import { useCustomFields } from "../../../../TimeSheet/hooks/useCustomFields";
import { useAllowedColumns } from "../hooks/useAllowedColumns";

export const useHiddenColumns = () => {
    const { groups } = useGroups();
    const { data: customFields } = useCustomFields();
    return useMemo(() => [
        "status",
        "job",
        "location",
        ...groups.reduce((total, { allocatedBy, level }) => {
            if (!allocatedBy) {
                return total.concat(level.replace("_", ""));
            }
            return total;
        }, []),
        ...customFields
            .filter(({ showByDefault }) => !showByDefault)
            .map(({ code }) => `customFields.${code}`),
    ], [customFields, groups]);
}

export const useColumns = () => {
    const columns = useTimesheetColumns({ user: undefined });
    return useAllowedColumns(columns);
}
