import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table as BaseTable, Card } from "reactstrap";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loaders/Loading";
import { useTableContext } from "../../../../components/Table/context";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useBeforeUnload } from "../../hooks/useBeforeUnload";
import { useWindowBeforeUnload } from "../../hooks/useWindowBeforeUnload";
import CancelModal from "./CancelModal";
import "./_style.scss";

function Table({ loading }) {
	const { t } = useTranslation();
	const { visible: isCancelModalOpen, close, open } = useVisible();
	const {
		getTableProps,
		headerGroups,
		getTableBodyProps,
		rows,
		prepareRow,
		addedTimeSheets,
		updatedTimeSheets,
		hasChanges,
		reset,
		batchUpdate,
		submitting,
		errors,
		setErrors,
	} = useTableContext();

	const hasErrors = useMemo(() => {
		return Object
			.values(errors)
			.some((fields) => {
				if (!fields) {
					return false;
				}
				return Object.values(fields).some((err) => !!err);
			});
	}, [errors]);

	const submit = useCallback(() => {
		const onSuccess = () => reset()
		const onFailure = (err) => {
			const { 0: added, ...updated } = err;
			const allErrors = {};
			const assignErrors = (items) => {
				for (const [id, errors] of Object.entries(items)) {
					for (const error of errors) {
						if (!(id in allErrors)) {
							allErrors[id] = {};
						}
						allErrors[id][error.field] = Object.values(error.constraints)[0];
					}
				}
			}
			assignErrors(added);
			assignErrors(updated);
			setErrors(allErrors);
		}
		batchUpdate({ addedTimeSheets, updatedTimeSheets }, onSuccess, onFailure);
	}, [batchUpdate, addedTimeSheets, updatedTimeSheets, reset, setErrors]);

	useWindowBeforeUnload({ when: hasChanges, message: "" });

	useBeforeUnload(
		hasChanges ? t("changes-may-not-be-saved") : false,
	);

	return (
		<Card className="timesheet-table mb-0 h-100 position-static">
			<BaseTable
				className="align-items-center table-flush"
				hover
				responsive
				{...getTableProps()}
			>
				<thead className="thead-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								return (
									<th
										{...column.getHeaderProps({
											...column.headCustomProps,
										})}
										style={{ color: "#000000b5" }}
									>
										{column.render("Header")}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{loading ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									<Loading />
								</div>
							</td>
						</tr>
					) : rows.length === 0 ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									{t("no-data")}
								</div>
							</td>
						</tr>
					) : (
						rows.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										const props =
											cell.column.getCellCustomProps?.(
												cell.row.original,
											) || {};
										if (props.rowSpan === 0) {
											return null;
										}
										return (
											<td {...cell.getCellProps(props)}>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})
					)}
				</tbody>
			</BaseTable>

			{batchUpdate && rows.length > 0 && (
				<div className="timesheet-table-footer border-top">
					<div className="bulk-actions">
						<div className="p-4 d-flex justify-content-end align-items-center">
							<Button
								color="danger"
								disabled={!hasChanges}
								onClick={open}
							>
								{t("cancel")}
							</Button>

							<Button
								type="submit"
								color="primary"
								disabled={!hasChanges || hasErrors}
								loading={submitting}
								onClick={submit}
							>
								{t("save-changes")}
							</Button>
						</div>
					</div>
				</div>
			)}

			<CancelModal
				isOpen={isCancelModalOpen}
				close={close}
				reset={reset}
			/>
		</Card>
	);
}

export default Table;
