import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import DateInput from "../../../../../components/Inputs/DateInput";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { ProfileContext } from "../../../context";
import pick from "lodash/pick";
import moment from "moment-timezone";

function ChangeKeyDates({ close }) {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { changeKeyDates: profileSubmit } = useProfileApi();
	const { changeKeyDates: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(values) => {
			const data = {
				birthDate: moment(values.birthDate).format("YYYY-MM-DD"),
				dateOfSeniority: moment(values.dateOfSeniority).format("YYYY-MM-DD"),
				hiringDate: moment(values.hiringDate).format("YYYY-MM-DD"),
				lastHireDate: moment(values.lastHireDate).format("YYYY-MM-DD")
			};
			submit(profileSubmit, peopleSubmit, data, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		let dates = pick(user, [
			"birthDate",
			"hiringDate",
			"lastHireDate",
			"dateOfSeniority",
		]);
		dates = Object
			.entries(dates)
			.reduce((total, [key, value]) => ({
				...total,
				[key]: value && new Date(value),
			}), {});
		form.setFieldsValue(dates);
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["birthDate", "hiringDate", "lastHireDate", "dateOfSeniority"],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field name="birthDate" label={t("date-of-birth")} className="d-flex flex-column">
				<DateInput placeholder={t("date-of-birth")} />
			</Field>

			<Field name="hiringDate" label={t("original-hire-date")} className="d-flex flex-column">
				<DateInput placeholder={t("original-hire-date")} />
			</Field>

			<Field name="lastHireDate" label={t("last-hire-date")} className="d-flex flex-column">
				<DateInput placeholder={t("last-hire-date")} />
			</Field>

			<Field name="dateOfSeniority" label={t("seniority-date")} className="d-flex flex-column">
				<DateInput placeholder={t("seniority-date")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeKeyDates;
