import { useMemo } from "react";
import { get } from "lodash";
import { useConfiguration } from "../api/useConfiguration";

export const useConfigurationModule = (module) => {
    const { data } = useConfiguration();
    return useMemo(() => {
        return get(data, module);
    }, [data, module]);
}
