import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import Button from "../../../../../components/Button";
import Select from "../../../../../components/Inputs/Select";
import { useDateFilters } from "../../../hooks/useDateFilters";
import DateFilters from "./DateFilters";
import PayGroupFilters from "./PayGroupFilters";

const Filters = ({ page, isStatisticsOpen, toggleStatistics }) => {
    const { t } = useTranslation();

    const { mode, from, to, setFilters } = useDateFilters();

    const onModeChange = useCallback(
        (mode) => {
            let from, to, payGroupPeriod;
            const now = moment();
            switch (mode) {
                case "date": {
                    from = now.toISOString(true);
                    to = now.toISOString(true);
                    break;
                }
                case "week": {
                    from = now.startOf("week").toISOString(true);
                    to = now.endOf("week").toISOString(true);
                    break;
                }
                case "month": {
                    from = now.startOf("month").toISOString(true);
                    to = now.endOf("month").toISOString(true);
                    break;
                }
                case "custom": {
                    from = undefined;
                    to = undefined;
                    break;
                }
                case "pay-group": {
                    from = undefined;
                    to = undefined;
                    payGroupPeriod = "current";
                    break;
                }
                default: {
                    from = undefined;
                    to = undefined;
                    break;
                }
            }
            setFilters({ mode, from, to, payGroup: undefined, payGroupPeriod });
        },
        [setFilters]
    );

    return (
        <Row className='mb-3'>
            <Col xs="12" md="auto">
                <Button
                    color="primary"
                    icon={<FontAwesomeIcon icon={isStatisticsOpen ? faChevronUp : faChevronDown} />}
                    onClick={toggleStatistics}
                >
                    {t("analytics")}
                </Button>
            </Col>

            <Col xs='12' md="auto">
                <Select
                    placeholder={t("mode")}
                    value={mode}
                    onChange={onModeChange}
                    showSearch
                >
                    <Select.Option value="date">
                        {t("date")}
                    </Select.Option>
                    <Select.Option value="week">
                        {t("week")}
                    </Select.Option>
                    <Select.Option value="month">
                        {t("month")}
                    </Select.Option>
                    <Select.Option value="custom">
                        {t("custom")}
                    </Select.Option>
                    {page === "team" && (
                        <Select.Option value="pay-group">
                            {t("pay-group")}
                        </Select.Option>
                    )}
                </Select>
            </Col>

            <Col xs='12' md='auto'>
                {mode === "pay-group" ? (
                    <PayGroupFilters />
                ) : (
                    <DateFilters
                        from={from}
                        to={to}
                        mode={mode}
                        setFilters={setFilters}
                    />
                )}
            </Col>
        </Row>
    );
}

export default Filters;
