import moment from "moment-timezone";

export const UserActiveStatus = ['active', 'ready', 'leave'];

export const getInactiveResources = (resources) =>
    resources.filter(({ status }) => status !== "active");

export const getInactiveCurrentResources = (resources, date = moment()) =>
	resources.filter(({ status, endDate }) => {
        return status !== "active"
            && endDate ? moment.parseZone(endDate).isSameOrBefore(date) : false;
    });

export const getActiveResources = (resources) =>
    resources.filter(({ status }) => status === "active");

export const getActiveCurrentResources = (resources, date = moment()) =>
	resources.filter(({ status, endDate }) => {
        return status === "active"
            && endDate ? moment.parseZone(endDate).isAfter(date) : true;
    });

export const getJobsBasedOnDate = (resources, date) =>
    resources.filter(({ startDate, endDate, status }) => {
        if (startDate) {
            const dateUnix = moment(date).startOf('day').unix();
            if (endDate) {
                return moment(endDate).startOf('day').unix() > dateUnix &&
                    dateUnix > moment(startDate).startOf('day').unix();
            }
            return dateUnix > moment(startDate).startOf('day').unix();
        }
        return status === "active"
    });

export const getPrimaryResources = (resources) =>
    resources.find(({ primary }) => primary);

export const getSecondaryResources = (resources) =>
    resources.filter(({ primary }) => !primary);

export const getPrimaryActiveResource = (resources) =>
    resources?.find(({ primary, status }) => primary && status === "active");

export const getSecondaryActiveResource = (resources) =>
    resources.find(({ primary, status }) => !primary && status === "active");

export const getPrimaryInactiveResource = (resources) =>
    resources.find(({ primary, status }) => primary && status !== "active");

export const getSecondaryInactiveResource = (resources) =>
    resources.find(({ primary, status }) => !primary && status !== "active");

export const renderUserName = (user, withMiddleName = true) =>
    `${user?.firstName} ${withMiddleName && user?.middleName ? `${user?.middleName} ` : ""
    }${user?.lastName}`;

export const canManageLevels = (manageAbility) =>
    [
        "direct-users-and-organisation-level",
        "direct-users-and-organisation-level-and-location",
        "direct-users-and-organisation-level-and-project",
        "direct-org-level-and-users",
    ].includes(manageAbility);

export const canManageLocations = (manageAbility, group) =>
    [
        "direct-users-and-location",
        "direct-users-and-organisation-level-and-location",
    ].includes(manageAbility) && group.inclueLocationHierarchy;

export const hasManagingRole = (roles) =>
    roles?.findIndex((role) => role.permissions.canManageUsers) !== -1;

export const isResourceInAllUsers = ({ users, property, identifier, item }) => {
    return users.every((user) => {
        return !!user[property]?.find((userResourceItem) => {
            return item[identifier] === userResourceItem[identifier];
        });
    });
}

export const isCommonInUserActive = (common, date = moment()) => {
    const endDate = moment.parseZone(common.endDate);
    return endDate?.isAfter(date) || true;
}

export const getCommonInUsers = ({ users, property, identifier, formatter }) => {
    const now = moment();
    if (!users) {
        return [];
    }
    const allResources = users.reduce((total, user) => {
        user[property]?.forEach((item) => {
            const isActive = isCommonInUserActive(item, now);
            const exists = !!total.find((totalItem) => totalItem[identifier] === item[identifier]);
            if (isActive && !exists) {
                total.push(item);
            }
        });
        return total;
    }, []);
    let commonResources = allResources
        .filter((item) => isResourceInAllUsers({ users, property, identifier, item }));
    if (formatter) {
        commonResources = commonResources.map((item) => formatter(item));
    }
    return commonResources;
}

export const isTerminated = (user) => {
    return user?.status === "terminated";
}

export const isUserActive = (user, date = moment().format('YYYY-MM-DD')) => {
    if (user.status === 'terminated' && user.terminatedAt && moment.utc(date).startOf('day').isBefore(moment(user.terminatedAt).endOf('day'))) { return true; }
    if (
        UserActiveStatus.includes(user.status) &&
        (
            (user.statusStartDate && moment.utc(date).endOf('day').isAfter(moment(user.statusStartDate).startOf('day'))) ||
            (user.hiringDate && moment.utc(date).endOf('day').isAfter(moment(user.hiringDate).startOf('day')))
        )
    ) { return true; }
    return false;
}