import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Field from "../../../../components/Field";
import Button from "../../../../components/Button";
import CustomTimeInput from "../../../../components/Inputs/CustomTimeInput";
import { useAccess } from "../../../../utils/hooks/access";
import { useUpdateCompany } from "../../../../utils/api/company";
import { useCompany, useCompanyTimeZone } from "../../../../utils/hooks/company";
import { formatSubmittedData } from "./format";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { applyTimezone } from "../../../../utils/helpers/date";

function Scheduler() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const timezone = useCompanyTimeZone();

	const { hasAccess: canEdit } = useAccess("settings.general.canEdit");

	const { loading, submit, error } = useUpdateCompany();

	const onFinish = useCallback((values) => {
		let startShiftScheduleTime = values?.startShiftScheduleTime, 
		    endShiftScheduleTime = values?.endShiftScheduleTime;

		if (timezone) {
			startShiftScheduleTime = values?.startShiftScheduleTime 
				&& applyTimezone(values?.startShiftScheduleTime, timezone, true)
					.toISOString(true);
			endShiftScheduleTime = values?.endShiftScheduleTime 
				&& applyTimezone(values?.endShiftScheduleTime, timezone, true)
					.toISOString(true);
		}

		const updatedCompany = formatSubmittedData(company, {
			startShiftScheduleTime,
			endShiftScheduleTime
		});

		if (startShiftScheduleTime) {
			submit(company.id, updatedCompany, () =>
				toast.success(t("updated-successfully")),
			);
		}
	}, [timezone, company, submit, t]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"startShiftScheduleTime",
				"endShiftScheduleTime",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	useEffect(() => {
		form.setFieldsValue({
			startShiftScheduleTime: moment.parseZone(company?.settings?.startShiftScheduleTime),
			endShiftScheduleTime: moment.parseZone(company?.settings?.endShiftScheduleTime)
		})
	}, [company?.settings])

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Form form={form} onFinish={onFinish}>
					<CardHeader>
						<Row>
							<Col xs="6" className="pl-4 d-flex">
								<h3 className="mb-0 mr-5">{t("scheduler")}</h3>
							</Col>

							<Col className="text-right" xs="6">
								<Button
									className="btn-dark px-3 btn-round btn-icon"
									size="sm"
									loading={loading}
									type="submit"
									disabled={!canEdit}
								>
									{t("save")}
								</Button>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="col-lg-9 col-md-12 general-settings">
						<Field 
							name="startShiftScheduleTime"
							label={t("start-of-day")}
						>
							<CustomTimeInput 
								disabled={!canEdit}
								placeholder={t("start-of-day")}
							/>
						</Field>

						<Field 
							name="endShiftScheduleTime"
							label={t("end-of-day")}
							rules={[
								({ getFieldValue }) => ({
									validator(_, value) {
										const startShiftScheduleTime = getFieldValue(
											"startShiftScheduleTime"
										);
										const startTime = company?.settings?.startShiftScheduleTime
												? applyTimezone(startShiftScheduleTime, timezone)
								                : startShiftScheduleTime;
										const endTime = company?.settings?.endShiftScheduleTime
												? applyTimezone(value, timezone, true)
												: value;
										
										if ((!startShiftScheduleTime || startShiftScheduleTime === "") && value) {
												return Promise.reject( 
													new Error(t("start-of-day-should-not-be-empty"))
												);
										} else if (
											moment(endTime).isSameOrBefore(
												moment(startTime),
												"minutes",
											)
										) {
											return Promise.reject(
												new Error(
													t("end-of-day-bigger-than-start-of-day"),
												),
											);
										} else {
											return Promise.resolve();
										}
									},
								}),
							]}
							dependencies={["startShiftScheduleTime"]}
						>
							<CustomTimeInput 
								disabled={!canEdit} 
								placeholder={t("end-of-day")}
							/>
						</Field>
					</CardBody>
				</Form>
			</Card>
		</div>
	);
}

export default Scheduler;
