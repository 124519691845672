import { useCallback, useMemo, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import useSWR from "swr";
import Topbar from "./Topbar";
import TeamSchedulerFilters from "./Filters";
import Scheduler from "../SchedulerComponent";
import Pagination from "../../../components/NTable/Pagination";
import { useColumns } from "../hooks/useColumns";
import { useDateFilters } from "../hooks/useDateFilters";
import { useHasCompany } from "../../../utils/hooks/company";
import useApi from "../../../utils/api";
import { formatTimeSheetParams } from "../../TimeSheet/api/helpers";
import { useFilters } from "../../TimeSheet/filtersContext";
import { prepareEventsForScheduler } from "../helpers/prepareEventsForScheduler";
import { useSortFilter } from "../hooks/useSortFilter";

function Content() {
	const { authGet } = useApi();
	const [filters, onFiltersChange] = useFilters();
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(100);

	const { sort, onSortClick } = useSortFilter("schedulerTeamSort");
	const { from, to } = useDateFilters();

	const params = useMemo(() => {
		return ({
			pageIndex: page,
			pageSize: perPage,
			...(formatTimeSheetParams({
				from,
				to,
				filters
			})),
			sort
		})
	}, [page, perPage, filters, from, to, sort]);

	const hasCompany = useHasCompany();
	const {
		data: { data: { resources, events }, meta: { total } },
		isLoading,
		isValidating,
		mutate,
	} = useSWR(
		hasCompany ? {
			url: "/schedules",
			params: params,
		} : null,
		{ fallbackData: { data: { resources: [], events: [] }, meta: { total: 0 } } },
	);

	const fetchUserSchedules = useCallback(async(event, schedule) => {
		const ids = [event?.resourceId];
		if (event?.oldResourceId) {
			ids.push(event?.oldResourceId);
		}

		return await Promise.all(ids.map((id) => {
			return authGet(`/schedules/${id}/events`, { 
				params
			});
		})).then((response) => {
			const events = response.flat();
			mutate((prev) => {
				const data = {
					data: {
						resources: prev?.data?.resources,
						events: [
							...events,
							...(prev?.data?.events || []).filter(event => !ids.includes(event.user))
						],
						success: prev?.data?.success
					},
					meta: prev?.meta
				};

				return data;
			}, { revalidate: false });
			schedule?.refresh();
		}).catch((error)=> {
			// getErrorMessage(error, t);
		});
	}, [authGet, params, mutate]);

	const columns = useColumns();

	const filterComponent = useMemo(() => {
		return (
			<TeamSchedulerFilters
				filters={filters}
				setFilters={onFiltersChange}
			/>
		);
	}, [filters, onFiltersChange]);

	const pagination = useMemo(() => {
		const pageCount =  Math.ceil(total / perPage);
		
		return (
			<Pagination
				page={page}
				gotoPage={setPage}
				perPage={perPage}
				setPerPage={setPerPage}
				totalItems={total}
				pageCount={pageCount}
			/>
		);
	}, [page, perPage, setPage, setPerPage, total]);

	return (
		<Card className="team-scheduler px-0 m-0 h-100 position-static">
			<CardHeader>
				<Topbar params={params} />
			</CardHeader>

			<Scheduler
				mutate={mutate}
				resources={resources}
				events={prepareEventsForScheduler(events)}
				columns={columns}
				isTeamScheduler={true}
				isLoading={isLoading || isValidating}
				fetchUserSchedules={fetchUserSchedules}
				params={params}
				filters={filterComponent}
				pagination={pagination}
				onSortClick={onSortClick}
			/>
		</Card>
	);
}

export default Content;
