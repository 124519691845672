import React from "react";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import { useTranslation } from "react-i18next";

const placeOptions = [
  {
    value: "start-shift",
    label: "Start shift",
  },
  {
    value: "start-break",
    label: "Start break",
  },
  {
    value: "end-break",
    label: "End break",
  },
  {
    value: "end-shift",
    label: "End shift",
  },
];

const Place = () => {
  const { t } = useTranslation();

  return (
    <Field
      name="place"
      label={t("place")}
      rules={[
        {
          required: true,
          message: t("required-place"),
        },
      ]}
    >
      <Select placeholder={t("place")}>
        {placeOptions.map(({ value, label }) => {
          return (
            <Select.Option key={value} value={value}>
              {t(label)}
            </Select.Option>
          );
        })}
      </Select>
    </Field>
  );
};

export default Place;
