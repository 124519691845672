import { useMemo } from "react";
import { normalize } from "../../../../../utils/helpers/normalize";
import { useConfigurationModule } from "../useConfigurationModule";

export const useConfigColumns = (feature, columns) => {
	const configColumns = useConfigurationModule(`${feature}.columns`);

	const map = useMemo(() => {
		if (!configColumns) {
			return {};
		}
		return normalize(configColumns, "accessor");
	}, [configColumns]);

	return useMemo(() => {
		return columns
			.filter((column) => {
				const key = column.accessorKey || column.id;
				if (map[key]?.hideCompletely) {
					return false;
				}
				return true;
			})
			.map((column) => {
				const key = column.accessorKey || column.id;
				if (map[key]?.hideSort) {
					return {
						...column,
						enableSorting: false,
					};
				}
				return column;
			});
	}, [columns, map]);
}
