import { useCallback } from "react";
import { useGetCountry } from "../api/countries";

export const useGetLocationFields = () => {
	const getCountry = useGetCountry();

	return useCallback(
		async (geocodeValues) => {
			let country;
			let state;
			let city;

			if (geocodeValues.country) {
				try {
					country = await getCountry({
						iso2: geocodeValues.country,
					});

					if (country && geocodeValues.state) {
						state = geocodeValues.state;
					}

					if (country && state && geocodeValues.city) {
						city = geocodeValues.city;
					}
				} catch (err) {}
			}

			return { country, state, city };
		},
		[getCountry]
	);
};
