import moment from "moment";

export const getStartDate = (date, mode) => {
	switch (mode) {
		case "date": {
			return moment(date).startOf("day").toISOString(true);
		}
		case "week": {
			return moment(date).startOf("week").toISOString(true);
		}
		case "month": {
			return moment(date).startOf("month").toISOString(true);
		}
		default: {
			return date;
		}
	}
};

export const getEndDateFromStart = (date, mode) => {
	switch (mode) {
		case "date": {
			return moment(date).endOf("day").toISOString(true);
		}
		case "week": {
			return moment(date).endOf("week").toISOString(true);
		}
		case "month": {
			return moment(date).endOf("month").toISOString(true);
		}
	}
};
