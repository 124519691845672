import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Row, Col } from "reactstrap";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Filters from "../../../../components/NTable/Actions/Filters";
import ColumnToggle from "../../../../components/NTable/Actions/ColumnToggle";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import {useColumnFiltersConfig} from "../../../../components/NTable/hook/useColumnFiltersConfig";

function Actions({
    filters,
    setFilters,
    columns,
    children,
    group,
}) {
	const config = useColumnFiltersConfig(columns);

	const { t } = useTranslation();

    const extendedConfig = useMemo(() => {
        if (!group) {
            return config;
        }
        const project = {
            name: "project",
            label: group?.description,
            type: "resource-select",
            component: (
                <ResourceSelect
                    labelPropName="description"
                    resourcePath={`${group.id}/org-levels`}
                    renderLabel={renderOrgLevelResourceLabel}
                    mode="multiple"
                    hasSearch
                    getSearchFilters={
                        getSettingsOrgResourceSearchFilters
                    }
                />
            ),
        };
        return [...config, project];
    }, [config, group, t]);

	return (
		<CardHeader className="border-top p-3">
			<Row className="align-items-center">
				<Col md={3}>{children}</Col>

				<Col md={children ? 9 : 12}>
					<div className="d-flex align-items-start">
						<Filters
							config={extendedConfig}
							filters={filters}
							setFilters={setFilters}
						/>

						<ColumnToggle columns={columns} />
					</div>
				</Col>
			</Row>
		</CardHeader>
	);
}

export default Actions;
