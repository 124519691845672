import { useMemo } from "react";

export const useUnallocatedPayCode = (map) => {
	return useMemo(() => {
		if (!map) {
			return;
		}

		return map["UAT"];
	}, [map]);
};
