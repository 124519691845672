import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Table as BaseTable } from "reactstrap";
import classNames from "classnames";
import Pagination from "./Pagination";
import Loading from "../Loaders/Loading";
import { useTableContext } from "./context";

function Table({
	loading,
	hasChangedDateFilter,
	setHasChangedDateFilter,
	pagination = true
}) {
	const { t } = useTranslation();

	const {
		getTableProps,
		headerGroups,
		getTableBodyProps,
		page,
		prepareRow,
		pageCount,
		state: { pageIndex, pageSize },
		gotoPage,
		setPageSize,
		totalItems,
	} = useTableContext();

	useEffect(() => {
		if (hasChangedDateFilter) {
			gotoPage(0);
			setHasChangedDateFilter(false);
		}
	}, [hasChangedDateFilter, gotoPage, setHasChangedDateFilter]);

	return (
		<Card className="mb-0 h-100">
			<BaseTable
				className="align-items-center table-flush"
				hover
				responsive
				{...getTableProps()}
			>
				<thead className="thead-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...(column.id === "selection"
										? column.getHeaderProps()
										: column.getHeaderProps(
											column.getSortByToggleProps(),
										))}
									style={{ color: "#000000b5" }}
								>
									{column.render("Header")}

									{column.id !== "selection" &&
										column.canSort && (
											<i
												className={classNames(
													"ml-1 fa-solid",
													column.isSorted
														? column.isSortedDesc
															? "fa-sort-down"
															: "fa-sort-up"
														: "fa-sort",
												)}
											/>
										)}
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{loading ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									<Loading />
								</div>
							</td>
						</tr>
					) : totalItems === 0 ? (
						<tr>
							<td colSpan="999">
								<div className="d-flex justify-content-center">
									{t("no-data")}
								</div>
							</td>
						</tr>
					) : (
						page.map((row, i) => {
							prepareRow(row);
							return (
								<>
									{row.original?.summaryColumns?.length > 0 ||
										row.original?.footerColumns?.length > 0 ? (
										<tr
											className={classNames(
												"w-100 text-white font-weight-bolder",
												row.original.class
													? row.original.class
													: row.original
														?.summaryColumns
														?.length > 0
														? "bg-info"
														: "bg-gray",
											)}
										>
											{(
												row.original.summaryColumns ||
												row.original.footerColumns
											).map((r) => {
												return (
													<td
														className="text-sm"
														colSpan={r.colSpan}
														key={r.accessor}
													>
														{r.value}
													</td>
												);
											})}
										</tr>
									) : (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => (
												<td {...cell.getCellProps()}>
													{cell.render("Cell")}
												</td>
											))}
										</tr>
									)}
								</>
							);
						})
					)}
				</tbody>
			</BaseTable>

			{pagination && <Pagination
				page={pageIndex}
				gotoPage={gotoPage}
				pageCount={pageCount}
				setPerPage={setPageSize}
				perPage={pageSize}
				totalItems={totalItems}
			/>}
		</Card>
	);
}

export default Table;
