import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { calculateRate, calculateRegularRate } from "../../../../../utils/helpers/shift";
import { useAccess } from "../../../../../utils/hooks/access";
import { isAddedTimeSheet } from "../helpers";
import { getHourTypeColor } from "../../../../../utils/helpers/hourType";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { useCompanyCurrency } from "../../../../../utils/hooks/company";
import { useGetRateConfigMax } from "../hooks/useTable/validations/rate";

export function InfoTooltip({ column, row, children }) {
	const { user } = row.original;
	const { visible, toggle } = useVisible();

	const id = `user-${user.id}-row-${row.id}-${column.id}`;

	return (
		<>
			<span id={id} className="ml-2">
				<FontAwesomeIcon
					className="cursor-pointer text-sm"
					icon={faInfo}
				/>
			</span>

			<Tooltip
				isOpen={visible}
				toggle={toggle}
				trigger="hover"
				placement="auto"
				target={id}
			>
				<span className="text-sm font-weight-bolder px-2 m-2">
					{children}
				</span>
			</Tooltip>
		</>
	);
}

function RateInfoTooltip({ column, row }) {
	const { t } = useTranslation();
	const currency = useCompanyCurrency();
	const getMax = useGetRateConfigMax();
	const maxRate = getMax(row.original.user.roles, "maxRate");

	const message = t("max-rate")
		+ ": "
		+ currencyFormatter(maxRate || 0, 2, currency);
	return (
		<InfoTooltip row={row} column={column}>
			{message}
		</InfoTooltip>
	);
}

export const useRateColumn = ({ canEdit }) => {
	const { t } = useTranslation();
	const { hasAccess: canUpdateRate } = useAccess("timeSheet.canUpdateRate");

	return useMemo(
		() => ({
			Header: t("rate"),
			accessor: "rate",
			Cell: ({
				row,
				column,
				cell,
				updateAddedTimeSheet,
				updateTimeSheet,
				errors,
			}) => {
				const { original } = row;
				const { job, payCode, locked, regRate } = original;
				const error = errors[row.id]?.rate;
				let rate = 0;
				if (payCode?.hourType === "amount") {
					rate = original.rate;
				} else if (payCode && job) {
					rate = calculateRate(original, original?.payCode?.hourType === "regular");
				}
				const disabled = (!isAddedTimeSheet(original) && locked)
					|| row.disabled
					|| cell.disabled;

				if (typeof regRate === 'undefined' && payCode) {
					const newReg = calculateRegularRate({ rate, payCode })
					if (isAddedTimeSheet(original)) {
						updateAddedTimeSheet(original, {
							regRate: newReg,
						});
					} else {
						updateTimeSheet(original.id, {
							regRate: newReg,
						});
					}
				}

				if (!job) {
					return null;
				}
				const hourTypeColor = getHourTypeColor(payCode?.hourType);

				if (payCode?.hourType !== "amount" && canUpdateRate) {
					return (
						<>
							<MoneyInput
								className={classNames("money-input")}
								prefix={renderCurrencySymbol(job.currency)}
								value={rate?.toFixed(2)}
								disabled={disabled || !canEdit}
								style={payCode?.hourType === "amount" ? {
									borderColor: error ? "red" : hourTypeColor,
									borderWidth: "0.17rem",
									borderStyle: 'solid',
									borderRadius: '0.375rem'
								} : error ? {
									borderColor: "red",
									color: "red",
								} : undefined}
								onChange={(newValue) => {
									const rateChangeManually = canUpdateRate && (Number(job?.hourlyRate) || 0) !== newValue;
									const newRegRate = calculateRegularRate({ rate: newValue, payCode })

									const data = {
										rate: newValue,
										rateChangeManually,
										regRate: newRegRate,
									};
									if (isAddedTimeSheet(original)) {
										updateAddedTimeSheet(original, data);
									} else {
										updateTimeSheet(original.id, data);
									}
								}}
							/>

							<RateInfoTooltip
								row={row}
								column={column}
							/>
						</>
					);
				}
				if (payCode?.hourType === "amount") {
					return "--";
				}
				return currencyFormatter(rate || 0, 2, job?.currency);
			},
		}),
		[t, canEdit, canUpdateRate],
	);
};
