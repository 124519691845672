import {
	Children,
	cloneElement,
	isValidElement,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import Marker from "./Marker";

const mapStyle = { flexGrow: 1, height: "350px" };

function GoogleMap({ options, children }) {
	const ref = useRef(null);
	const [map, setMap] = useState(null);

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map, setMap]);

	useEffect(() => {
		if (map && options) {
			map.setOptions(options);
		}
	}, [map, options]);

	return (
		<>
			<div ref={ref} style={mapStyle} />
			{Children.map(
				children,
				(child) =>
					isValidElement(child) && cloneElement(child, { map }),
			)}
		</>
	);
}

function Map({ center, marker }) {
	const mapOptions = useMemo(
		() => {
			const obj = {
				zoom: 11,
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
				zoomControl: false,
			}
			if (center && Number.isFinite(center.lat) && Number.isFinite(center.lng)) {
				obj.center = center;
			} else {
				obj.center = {
					lat: 40.6908804,
					lng: -73.9680626
				}
			}
			return obj;
		},
		[center],
	);

	return (
		<Wrapper
			apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
		>
			<GoogleMap options={mapOptions}>
				<Marker position={marker} />
			</GoogleMap>
		</Wrapper>
	);
}

export default Map;
