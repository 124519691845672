import moment from "moment-timezone";
import { useCallback } from "react";
import { useVisible } from "../../../../utils/hooks/useVisible";

export const useSummary = () => {
	const { visible, selected, open, close } = useVisible();

	const openDaySummary = useCallback(
		(user, date) => {
			open({
				user,
				range: {
					from: moment(date).startOf("day").toISOString(true),
					to: moment(date).endOf("day").toISOString(true),
				},
			});
		},
		[open],
	);

	return { visible, selected, openDaySummary, close };
};
