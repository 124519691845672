import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isAddedTimeSheet } from "../../../helpers";

export const useStartDateValidations = () => {
  const { t } = useTranslation();
  const isRequired = useCallback((value, row) => {
    if (isAddedTimeSheet(row) && !row.endDate) {
      return null;
    }
    if (!value) {
      return t("Start date is required");
    }
    return null;
  }, [t]);
  return useMemo(() => [isRequired], [isRequired])
}
