import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Button from "../../../../../../components/Button";
import Field from "../../../../../../components/Field";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import { useImportHolidaysApi } from "./api";
import "./style.scss";

const ImportHoliday = ({ form, disabled }) => {
    const { t } = useTranslation();
    const [canImport, setCanImport] = useState(false);
    const { submit, loading } = useImportHolidaysApi();

    const cancel = useCallback(() => {
        setCanImport((prev) => !prev);
        form.setFieldValue("country", undefined);
        form.setFieldValue("state", undefined);
    }, [setCanImport, form]);

    const onSuccess = useCallback((response) => {
        let days = form.getFieldValue("days");
        days = days?.filter((day) => day?.name || day?.date || day?.dates?.length);
        days = days?.map((day) => {
            if (day?.date && day?.isSameDate) {
                const haveSameDate = response?.find(
                    (item) => item?.isSameDate && moment(item?.date).isSame(moment(day?.date), "date")
                );
                if (!haveSameDate) {
                    return day;
                }

            } else {
                return day;
            }
        });
        days = days?.filter((day) => !!day);
        days = [...days, ...response]?.map((day) => ({
            name: day?.name,
            isSameDate: day?.isSameDate,
            repeatEveryYear: day?.repeatEveryYear,
            date: (day?.date && day?.isSameDate) ? moment(day.date).toDate() : undefined,
            dates: !day?.isSameDate && day?.dates?.length
                ? day?.dates?.map((date) => moment(date).toDate())
                : []
        }))
        if (days?.length > 0) {
            form.setFieldValue("days", days)
        }
        cancel();
    }, [form, cancel]);

    const onFinish = useCallback(() => {
        const country = form.getFieldValue("country");
        const params = {
            country: country?.iso2?.toLowerCase(),
        };
        if (country?.id) {
            submit(params, onSuccess);
        } else {
            const error = {
                country: [t("required-country")],
            };
            const fieldErrors = generateErrorsConfigForForm(["country"], error);
            form.setFields(fieldErrors);
        }
    }, [form, submit, onSuccess, t]);

    return (
        <div>
            {!canImport && (
                <Button className="w-100 mb-3" color="dark" type="button" onClick={cancel}>
                    {t("import-holidays")} <FontAwesomeIcon icon={faUpload} />
                </Button>
            )}

            {canImport && (
                <Card className="p-4 border shadow-none import-holiday-card">
                    <Field
                        name="country"
                        label={t("country")}
                        rules={[
                            {
                                required: true,
                                message: t("required-country"),
                            },
                        ]}
                    >
                        <ResourceSelect
                            labelPropName="name"
                            resourcePath="/countries?pagination=false"
                            hasCompany={false}
                            placeholder={t("country")}
                            hasSearch
                            disabled={disabled}
                            onChange={() => form.setFieldValue("state", undefined)}
                        />
                    </Field>

                    <div className="d-flex gap-2 justify-content-end">
                        <Button
                            type="button"
                            disabled={disabled}
                            color="muted"
                            onClick={cancel}
                        >
                            {t("cancel")}
                        </Button>

                        <Button
                            type="button"
                            color="primary"
                            loading={loading}
                            onClick={onFinish}
                        >
                            {t("import")}
                        </Button>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default ImportHoliday;
