import pick from "lodash/pick";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { getPrimaryActiveResource } from "../../../../../../../utils/helpers/user";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { getActiveJobs } from "../../../../../components/TimeSheetTable/hooks/useTable/helpers";
import { generateUniqueRowId } from "./generateUniqueRowId";
import { useDates } from "./useDates";

const useGenerateUserTableEntry = (user) => {
  const { allocatedGroups } = useGroups();
  const dates = useDates();

  return useCallback(() => {
    let payCode = user.calculationGroup.regularDefaultPayCode;
    if (payCode?.hourType !== "unit") {
      payCode = null;
    }
    const job = getActiveJobs(user, moment().format("YYYY-MM-DD"))
      .find(({ primary }) => primary);
    const location = getPrimaryActiveResource(user?.locations || []);
    const levels = pick(user, allocatedGroups.map(({ level }) => level.replace("_", "")));

    return {
      id: generateUniqueRowId(true),
      user,
      payCode,
      job,
      location,
      ...levels,
      dates: dates.reduce((total, date) => {
        total[date] = {
          duration: 0,
          amount: 0,
          approved: false,
          locked: false,
        };
        return total;
      }, {}),
    }
  }, [user, dates, allocatedGroups]);
};

export const useTableState = ({ user, apiFormattedData }) => {
  const generateUserTableEntry = useGenerateUserTableEntry(user);

  const [added, setAdded] = useState({});
  const [updated, setUpdated] = useState({});
  const [removed, setRemoved] = useState([]);

  const addRow = useCallback((index) => {
    setAdded((prev) => {
      const updatedKeys = Object
        .keys(prev)
        .map((key) => parseInt(key))
        .filter((key) => index <= key);
      const n = { ...prev };
      for (const key of updatedKeys.reverse()) {
        n[key + 1] = n[key];
        delete n[key];
      }
      n[index] = generateUserTableEntry(user);
      n[index].isTouched = true;
      return n;
    });
  }, [setAdded, generateUserTableEntry]);

  const removeRow = useCallback((id, index) => {
    setUpdated((prev) => {
      const n = { ...prev };
      delete n[id];
      return n;
    });
    setAdded((prev) => {
      const n = { ...prev };
      delete n[index];

      const affected = Object
        .keys(prev)
        .filter((key) => key > index);
      for (const key of affected) {
        n[key - 1] = n[key];
        delete n[key];
      }

      return n;
    });

    const isAdded = id.startsWith("added_");
    if (!isAdded) {
      setRemoved((prev) => [...prev, id]);
    }
  }, [setAdded, setUpdated, setRemoved]);

  const updateRow = useCallback((id, data) => {
    const isAdded = id.startsWith("added_");
    if (isAdded) {
      setAdded((prev) => {
        const index = Object
          .keys(prev)
          .find((index) => {
            const item = prev[index];
            return item.id === id;
          });
        if (index === undefined) {
          return prev;
        }
        return {
          ...prev,
          [index]: {
            ...prev[index],
            ...data,
          },
        };
      });
      return;
    }
    setUpdated((prev) => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          isTouched: true,
          ...data,
        },
      };
    });
  }, [setUpdated]);

  const initAdded = useCallback(() => {
    if (apiFormattedData.length > 0) {
      setAdded({});
      return;
    }
    const item = generateUserTableEntry();
    item.isTouched = false;
    setAdded({ 0: item });
  }, [apiFormattedData, setAdded, generateUserTableEntry]);

  const reset = useCallback(() => {
    setRemoved([]);
    initAdded();
    setUpdated({});
  }, [initAdded, setUpdated, setRemoved]);

  useEffect(() => {
    initAdded();
  }, [initAdded]);

  return { added, updated, removed, addRow, updateRow, removeRow, reset };
}
