import Form, { Field } from "rc-field-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, Col, Row } from "reactstrap";
import Button from "../../../../../components/Button";
import { useConfigurationActions } from "../../api/useConfiguration";
import ColumnsTable from "../../components/ColumnsTable";
import Topbar from "../../components/Topbar";
import { useMergeConfigurations } from "../../helpers";
import { useConfigurationModule } from "../../hooks/useConfigurationModule";
import { useColumnsDefaultValue } from "../hooks/useColumnsDefaultValue";
import { useFiltersDefaultValue } from "../hooks/useFiltersDefaultValue";
import Settings from "./Settings";
import ColumnsNewTable from "./components/ColumnsTable";
import FiltersTable from "./components/FiltersTable";
import {
    useColumns,
    useHiddenColumns,
    useTimeSheetColumns,
    useTimeSheetHiddenColumns,
} from "./useColumns";
import { useFilters } from "./useFilters";
import "../style.scss";

const options = [
    "user-columns",
    "user-filters",
    "timesheet-columns",
    "settings"
];

function Content() {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const config = useConfigurationModule("timeSheetWeekly");

    const [menu, setMenu] = useState("user-columns")

    const columns = useColumns();
    const timeSheetColumns = useTimeSheetColumns();
    const filters = useFilters();

    const content = useMemo(() => {
        switch (menu) {
            case "user-columns":
                return <ColumnsTable config={columns} />;
            case "timesheet-columns": {
                return (
                    <ColumnsNewTable
                        config={timeSheetColumns.filter(({ id }) => !["add"].includes(id))}
                        fieldName={["timeSheet", "columns"]}
                    />
                );
            }
            case "user-filters":
                return <FiltersTable config={filters} form={form} />;
            case "settings":
                return <Settings form={form} columns={columns} />;
        }
    }, [menu, form, columns, timeSheetColumns, filters]);

    const { submit, submitting } = useConfigurationActions("timeSheetWeekly");
    const onFinish = useCallback(async (formValues) => {
        try {
            await submit({
                entity: "company",
                config: formValues,
            });
        } catch (err) { }
    }, [submit]);

    const defaultColumnsValue = useMergeConfigurations(
        useColumnsDefaultValue({
            columns,
            hiddenColumns: useHiddenColumns(),
        }),
        config?.columns,
    );
    const defaultTimesheetColumnsValue = useMergeConfigurations(
        useColumnsDefaultValue({
            columns: timeSheetColumns,
            hiddenColumns: useTimeSheetHiddenColumns(),
        }),
        config?.timeSheet?.columns,
    );
    const defaultFiltersValue = useMergeConfigurations(
        useFiltersDefaultValue(filters),
        config?.filters,
    );
    useEffect(() => {
        form.setFieldsValue({
            columns: defaultColumnsValue,
            filters: defaultFiltersValue,
            defaultSort: config?.defaultSort,
            defaultPageSize: config?.defaultPageSize || 20,
            defaultFilter: config?.defaultFilter,

            hideUserProfile: config?.hideUserProfile || false,

            timeSheet: {
                columns: defaultTimesheetColumnsValue,
                hideDailySummary: config?.timeSheet?.hideDailySummary || false,
            },
        })
    }, [
        form,
        config,
        defaultColumnsValue,
        defaultFiltersValue,
        defaultTimesheetColumnsValue,
    ]);

    return (
        <div className="d-flex h-100 configuration">
            <Card className="flex-fill mb-0 h-100">
                <Form form={form} onFinish={onFinish} style={{ height: "calc(100% - 160px)" }}>
                    <CardHeader>
                        <Row>
                            <Col xs="6" className="pl-4 d-flex">
                                <h3 className="mb-0 mr-5">{t("weekly")}</h3>
                            </Col>

                            <Col className="text-right" xs="6">
                                <Button
                                    className="btn-dark px-3 btn-round btn-icon"
                                    size="sm"
                                    loading={submitting}
                                    type="submit"
                                >
                                    {t("save")}
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <Topbar
                        options={options}
                        menu={menu}
                        setMenu={setMenu}
                    />

                    <div
                        className="w-100 d-flex h-100 flex-wrap gap-20 scroll p-4"
                        style={{ overflow: "auto" }}
                    >
                        <Field name="columns" />
                        <Field name="filters" />
                        <Field name="defaultSort" />
                        <Field name="defaultPageSize" />
                        <Field name="defaultFilter" />

                        <Field name="hideUserProfile" />

                        <Field name={["timeSheet", "columns"]} />
                        <Field name={["timeSheet", "hideDailySummary"]} />

                        {content}
                    </div>
                </Form>
            </Card>
        </div>
    );
}

export default Content;
