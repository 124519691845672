import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getToastErrorMessage } from "../../../utils/helpers/errors";
import { formatTimeSheetParams } from "./helpers";

export const useApproveTimeSheet = () => {
	const { t } = useTranslation();
	const { authPut } = useApi();

	const approve = useCallback(
		async (id, onSuccess, onFailure) => {
			const loading = toast.loading(t("approving-timesheet"));
			try {
				const response = await authPut(`/time-sheet/${id}/approve`);
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheet-approved-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(
					getToastErrorMessage(err, t("could-not-approve-timesheet")),
				);
			}
		},
		[authPut, t],
	);

	const approveMany = useCallback(
		async (ids, onSuccess, onFailure) => {
			const loading = toast.loading(
				ids.length > 0 ? t("approving-timesheets", { count: ids.length }) : t("approving-all-timesheets"),
			);
			try {
				const response = await authPut("/time-sheet/approve", {
					data: ids,
				});
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheets-approved-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(
					getToastErrorMessage(err, t("could-not-approve-timesheets")),
				);
			}
		},
		[authPut, t],
	);

	const unapprove = useCallback(
		async (id, onSuccess, onFailure) => {
			const loading = toast.loading(t("unapproving-timesheet"));
			try {
				const response = await authPut(`/time-sheet/${id}/unapprove`);
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheet-unapproved-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(
					getToastErrorMessage(err, t("could-not-unapprove-timesheet")),
				);
			}
		},
		[authPut, t],
	);

	const unapproveMany = useCallback(
		async (ids, onSuccess, onFailure) => {
			const loading = toast.loading(
				ids?.length > 0 ? t("unapproving-timesheets", { count: ids.length }) : t("unapproving-all-timesheets"),
			);
			try {
				const response = await authPut("/time-sheet/unapprove", {
					data: ids,
				});
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheets-unapproved-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(
					getToastErrorMessage(
						err,
						t("could-not-unapprove-timesheets")
					),
				);

			}
		},
		[authPut, t],
	);

	const approveUsers = useCallback(
		async (ids, filters, onSuccess, onFailure) => {
			const loading = toast.loading(
				ids?.length > 0 ? t("approving-user-timesheets", { count: ids.length }) : t("approving-all-user-timesheets"),
			);
			try {
				const response = await authPut("/time-sheet/user/approve", {
					data: ids,
					params: formatTimeSheetParams(filters),
				});
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("user-timesheets-approved-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(
					getToastErrorMessage(
						err,
						t("could-not-approve-user-timesheets")
					),
				);
			}
		},
		[authPut, t],
	);

	const unapproveUsers = useCallback(
		async (ids, filters, onSuccess, onFailure) => {
			const loading = toast.loading(
				ids?.length > 0 ? t("unapproving-user-timesheets", { count: ids.length }) : t("unapproving-all-user-timesheets"),
			);
			try {
				const response = await authPut("/time-sheet/user/unapprove", {
					data: ids,
					params: formatTimeSheetParams(filters),
				});
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("user-timesheets-unapproved-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(
					getToastErrorMessage(
						err,
						t("could-not-unapprove-user-timesheets")
					),
				);
			}
		},
		[authPut, t],
	);

	return {
		approve,
		approveMany,
		unapprove,
		unapproveMany,
		approveUsers,
		unapproveUsers,
	};
};
