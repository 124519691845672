import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useTable,
	useFilters,
	useGlobalFilter,
	useSortBy,
	usePagination,
	useRowSelect,
	useExpanded,
	useRowState,
	useMountedLayoutEffect,
} from "react-table";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import { useInitialState } from "./useInitialState";

import { useConfigurationModule } from "../../../../../Settings/Configuration/hooks/useConfigurationModule";

const useExtendedExpandInstance = (instance) => {
	const {
		state: { globalFilter, pageIndex, pageSize, sortBy },
	} = instance;

	const resetExpandedRows = useCallback(() => {
		instance.toggleAllRowsExpanded(false);
	}, [instance]);

	useMountedLayoutEffect(() => {
		resetExpandedRows();
	}, [resetExpandedRows, globalFilter, pageIndex, pageSize, sortBy]);
};

export const useTeamTable = ({
	columns,
	data,
	setData,
	totalItems,
	refetchOverview,
	refetchUsers,
	openCancelModal,
}) => {
	const defaultSort = useConfigurationModule("timeSheetTeam.defaultSort");
	const initialSortBy = useMemo(() => {
		if (!defaultSort) {
			return;
		}
		const { field, type } = defaultSort;
		return [{
			id: field,
			desc: type === "desc",
		}];
	}, [defaultSort]);

	const initialState = useInitialState({
		pageSize: useConfigurationModule("timeSheetTeam.defaultPageSize"),
		sortBy: initialSortBy,
	});

	const [pageCount, setPageCount] = useState(Math.ceil(totalItems / 50));

	const updateUser = useCallback(
		(user) => {
			setData((prev) => {
				return prev.map((prevUser) =>
					user?.id === prevUser?.id ? user : prevUser,
				);
			});
		},
		[setData],
	);

	const table = useTable(
		{
			columns,
			data,
			totalItems,
			initialState,
			autoResetHiddenColumns: false,
			stateReducer: useCallback(
				(state, action, prevState) => {
					let newState = { ...state };
					if (action.type === "toggleRowExpanded") {
						newState.rowState[action.id] = {
							data: [],
							loading: false,
							totalItems: 0,
						};
					}
					if (action.type === "toggleAllRowsExpanded") {
						Object.keys(newState.expanded).forEach((id) => {
							newState.rowState[id] = {
								data: [],
								loading: false,
								totalItems: 0,
							};
						});
					}
					if (
						action.type === "setGlobalFilter" &&
						!isEqual(newState.globalFilter, prevState.globalFilter)
					) {
						newState.pageIndex = 0;
					}

					return newState;
				},
				[],
			),
			manualPagination: true,
			manualSortBy: true,
			manualFilters: true,
			manualGlobalFilter: true,
			autoResetExpanded: false,
			autoResetRowState: false,
			pageCount,
			defaultColumn: {
				hideable: true,
				alwaysVisible: false,
				disableFilters: true,
			},
			filterTypes: useMemo(() => ({}), []),
			getRowId: useCallback(({ id }) => id, []),
			updateUser,
			refetchOverview,
			refetchUsers,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		useRowState,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				{
					id: "expander",
					Header: ({
						getToggleAllRowsExpandedProps,
						isAllRowsExpanded,
						state,
					}) => {
						const props = getToggleAllRowsExpandedProps();
						const onClick = (...args) => {
							const hasChanges = Object.values(
								state.rowState,
							).some((row) => row.hasChanges);
							if (hasChanges) {
								openCancelModal(
									() => () => props.onClick(...args),
								);
							} else {
								props.onClick(...args);
							}
						};

						return (
							<span {...props} onClick={onClick}>
								<FontAwesomeIcon
									icon={
										isAllRowsExpanded
											? faChevronUp
											: faChevronDown
									}
								/>
							</span>
						);
					},
					Cell: ({ row }) => {
						const props = row.getToggleRowExpandedProps();
						const onClick = (...args) => {
							if (row.state.hasChanges) {
								openCancelModal(
									() => () => props.onClick(...args),
								);
							} else {
								props.onClick(...args);
							}
						};

						return (
							<span {...props} onClick={onClick}>
								<FontAwesomeIcon
									icon={
										row.isExpanded
											? faChevronUp
											: faChevronDown
									}
								/>
							</span>
						);
					},
				},
				{
					id: "selection",
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<Checkbox
							id="selection-all"
							{...getToggleAllRowsSelectedProps()}
						/>
					),
					Cell: ({ row }) => (
						<Checkbox
							id={`selection-${row.id}`}
							{...row.getToggleRowSelectedProps()}
						/>
					),
				},
				...columns,
			]);
		},
		(hooks) => {
			hooks.getRowProps.push((props = {}, { row }) => {
				const { isExpanded } = row;
				const { misspunch, unapproved } = row.original;

				props.className = classNames(
					props.className,
					isExpanded && "expanded",
					misspunch && "misspunch",
					unapproved && "unapproved",
				);
				return props;
			});
		},
		(hooks) => {
			hooks.useInstance.push(useExtendedExpandInstance);
		},
	);

	useEffect(() => {
		setPageCount(Math.ceil(totalItems / table.state.pageSize));
	}, [setPageCount, totalItems, table.state.pageSize]);

	return table;
};
