import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";

export const manageAbilityOptions = [
    "all",
    "custom", 
    "by-company",
    "by-location",
    "by-users",
    "by-pay-groups",
    "by-unions",
    "by-user-groups",
    "by-projects-and-pay-groups",
    "by-pay-group-and-employee-type",
    "by-organisation-level", 
    "by-company-and-location", 
    "by-company-and-organisation-level",
    "by-company-and-location-and-organisation-level",
    "by-company-and-pay-groups",
];

function ManageAbilitySelect({ form, setLocations, values, disabled }) {
  const { t } = useTranslation();

  const onManageAbilityChange = useCallback(() => {
    setLocations(values?.locations || []);
    form.setFieldsValue({
      companies: [],
      locations: [],
      managedLevels: [],
      payGroups: [],
      employeeTypes: [],
      projects: [],
      unions: [],
      users: [],
    });
  }, [form, values, setLocations]);

  return (
      <Field 
        name="manageAbility" 
        label={t("manage-ability")} 
        rules={[
          {
            required: true,
            message: t("required-manage-ability")
          }
        ]}
      >
      <Select
        placeholder={t("manage-ability")}
        onChange={onManageAbilityChange}
        disabled={disabled}
      >
        {manageAbilityOptions.map((value) => (
          <Select.Option key={value} value={value}>
            {t(value)}
          </Select.Option>
        ))}
      </Select>
    </Field>
  );
}

export default ManageAbilitySelect;
