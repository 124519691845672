import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Field from "../../Field";
import Select from "../Select";

export const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function WeekdaySelect({ name, label, rules, ...props }) {
	const { t } = useTranslation();

	return (
		<Field name={name} label={label} rules={rules}>
			<Select {...props}>
				{weekdays.map((day) => (
					<Option key={day} value={day}>
						{t(day)}
					</Option>
				))}
			</Select>
		</Field>
	);
}

export default WeekdaySelect;
