import { useEffect, useMemo } from "react";

export const useFetchResource = ({ fetch, state }) => {
    const {
        pagination: { pageIndex, pageSize },
        sorting: sortBy,
        columnFilters,
    } = state;

    const filters = useMemo(
        () =>
            columnFilters.filter((filter) => {
                if (Array.isArray(filter.value) && filter.value.length === 0) {
                    return false;
                }
                if (filter.value === "") {
                    return false;
                }
                return true;
            }),
        [columnFilters]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetch({ pageIndex, pageSize, filters, sortBy }, controller);
        return () => controller.abort();
    }, [fetch, pageIndex, pageSize, filters, sortBy]);
};
