import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../components/Button";
import {
	getPrimaryActiveResource,
	renderUserName,
} from "../../../../../../utils/helpers/user";
import { getUploadUrl } from "../../../../../../utils/helpers/upload";
import { dateTimeFormat } from "../../../../../../utils/helpers/date";
import { createColumnHelper } from "@tanstack/react-table";
import { useCompanyDateFormat } from "../../../../../../utils/hooks/company";
import SimpleTable from "../../../../../../components/SimpleTable";

const columnHelper = createColumnHelper();

function Preview({ data, goBack, submit, disabled }) {
	const { t } = useTranslation();

	const dateFormat = useCompanyDateFormat();

	const columns = useMemo(
		() => [
			columnHelper.accessor("user", {
				header: t("name"),
				cell: ({ row: { original } }) => (
					<div className="d-flex align-items-center">
						<img
							alt=""
							className="avatar rounded-circle mr-2 border-none"
							src={original?.image && getUploadUrl(original?.image)}
						/>
						{renderUserName(original)}
					</div>
				),
			}),
			columnHelper.accessor("jobs", {
				header: t("employee-start-date"),
				cell: (info) => {
					const value = info.getValue();
					const startDate = getPrimaryActiveResource(
						value || [],
					)?.startDate;
					return startDate
						? dateTimeFormat(startDate, dateFormat)
						: null;
				},
			}),
			columnHelper.accessor("balance-hours", {
				header: t("balance-hours"),
			}),
		],
		[t, dateFormat],
	);

	return (
		<div className="d-flex flex-column justify-content-between h-100">
			<div className="mb-8">
				<div className="mb-2">
					<h3>{t("starting-balances")}</h3>
				</div>

				<p className="mb-5 text-sm">
					{t("starting-balances-description")}
				</p>

				<SimpleTable data={data?.employees} columns={columns} />
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border"
					size="sm"
					onClick={goBack}
				>
					{t("back")}
				</Button>

				<Button
					onClick={submit}
					className="btn-round btn-icon shadow-none border px-3"
					color="dark"
					size="sm"
					hidden={disabled}
				>
					{t("save")}
				</Button>
			</div>
		</div>
	);
}

export default Preview;
