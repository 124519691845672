import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import { useMemo } from "react";
import OptionsTable from "../../components/OptionsTable";
import SortInput from "../../components/SortInput";

const pageSizes = [10, 20, 30, 40, 50, 100];

function Settings({ form, columns }) {
    const { t } = useTranslation();

    const options = useMemo(() => {
        return [
            { label: t("daily-summary"), name: ["timeSheet", "hideDailySummary"] },
            { label: t("weekly-summary"), name: "hideWeeklySummary" },
            { label: t("user-profile"), name: "hideUserProfile" },
        ];
    }, [t]);

    return (
        <div className="w-100 row">
            <div className="col-6">
                <OptionsTable fields={options} />
            </div>

            <div className="col-6">
                <Field name="defaultPageSize" label={t("default-page-size")}>
                    <Select allowClear>
                        {pageSizes.map((pageSize) => (
                            <Select.Option key={pageSize} value={pageSize}>
                                {pageSize}
                            </Select.Option>
                        ))}
                    </Select>
                </Field>

                <SortInput form={form} columns={columns} />
            </div>
        </div>
    );
}

export default Settings;
