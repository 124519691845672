import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import { Progress as BaseProgress } from "reactstrap";
import { useTime } from "../../../../utils/hooks/time";
import { getIntervals } from "../../../../utils/helpers/shift";

function Progress({ clocks }) {
	const time = useTime();

	const intervals = useMemo(() => {
		const lastClock = clocks[clocks?.length - 1];
		const isShiftFinished =
			lastClock?.type === "SHIFT" && lastClock?.mode === "END";

		const intervals = getIntervals(clocks).reduce(
			(total, [start, stop]) => {
				let type = "SHIFT";
				if (
					start.type === "BREAK" &&
					start.mode === "START" &&
					stop.type === "BREAK" &&
					stop.mode === "END"
				) {
					type = start.type;
				}
				return total.concat({
					type,
					duration: moment(stop.time).diff(start.time, "seconds"),
				});
			},
			[],
		);
		if (!isShiftFinished) {
			let type = "SHIFT";
			if (lastClock?.type === "BREAK" && lastClock?.mode === "START") {
				type = "BREAK";
			}
			const duration = moment(time).diff(lastClock?.time, "seconds");
			intervals.push({ type, duration });
		}
		return intervals;
	}, [clocks, time]);

	const totalDuration = useMemo(
		() =>
			intervals.reduce((total, interval) => interval.duration + total, 0),
		[intervals],
	);

	const getIntervalWidth = useCallback(
		(duration) => {
			const lastClock = clocks[clocks?.length - 1];
			const isShiftFinished =
				lastClock?.type === "SHIFT" && lastClock?.mode === "END";
			if (isShiftFinished) {
				return duration / totalDuration;
			}
			return duration / totalDuration / 2;
		},
		[clocks, totalDuration],
	);

	return (
		<BaseProgress multi style={{ height: "10px" }}>
			{intervals.map(({ type, duration }, i) => (
				<BaseProgress
					key={`${type}-${duration}-${i}`}
					style={{
						width: `${getIntervalWidth(duration) * 100}%`,
						backgroundColor:
							type === "SHIFT" ? "#5428e0" : "#FE9700",
						height: "10px",
						borderRadius: "5px",
					}}
				/>
			))}
		</BaseProgress>
	);
}

export default Progress;
