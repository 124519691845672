import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { CardBody } from "reactstrap";
import FormContent from "../../General/Locations/Form/Content";
import Header from "../Layout/Header";
import { useSettingsOnboarding } from "../context";
import useApi from "../../../../utils/api";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useCompany } from "../../../../utils/hooks/company";
import { useLocation } from "../../General/Locations/helpers";

const formatSubbmittedFields = (values) => ({
	...values,
	country: values?.country?.id,
	state: values?.state,
	city: values?.city,
	timezone: values?.timezone?.id,
	level1: values?.level1?.map((level) => level?.id),
	level2: values?.level2?.map((level) => level?.id),
	level3: values?.level3?.map((level) => level?.id),
	level4: values?.level4?.map((level) => level?.id),
});

const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };
const defaultZoom = 11;

function Location() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const { authPost } = useApi();
	const { submit, error } = useSettingsOnboarding();

	const {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	} = useLocation(form);

	const call = useCallback(
		(values) => {
			if (!company?.id) {
				return;
			}
			return authPost("/locations", {
				data: { company: company.id, ...values },
			});
		},
		[company?.id, authPost],
	);

	const onFinish = useCallback(
		(values) => {
			const data = {
				...formatSubbmittedFields(values),
				marker,
				mapCenter: center,
				mapZoom: zoom,
			};

			submit(true, call(data));
		},
		[submit, marker, center, zoom, call],
	);

	const saveAndRepeat = useCallback(() => {
		const values = form.getFieldsValue();
		submit(false, call(values), () =>
			toast.success(t("location-created")),
		);
		form.resetFields();
		form.setFieldsValue({
			status: "active",
		});
	}, [form, submit, call, t]);

	useEffect(() => {
		form.setFieldsValue({
			status: "active",
		});
		setCenter(defaultCenter);
		setZoom(defaultZoom);
		setMarker(null);
	}, [form, setCenter, setZoom, setMarker]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Header saveAndRepeat={saveAndRepeat} />

			<CardBody>
				<FormContent
					changeMarker={changeMarker}
					marker={marker}
					zoom={zoom}
					center={center}
				/>
			</CardBody>
		</Form>
	);
}

export default Location;
