import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FormGroup, Input } from "reactstrap";
import Field from "../../../components/Field";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Switch from "../../../components/Inputs/Switch";
import DateInput from "../../../components/Inputs/DateInput";
import CustomTimeInput from "../../../components/Inputs/CustomTimeInput";
import Button from "../../../components/Button";
import PaidHours from "./PaidHours";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../utils/helpers/settings";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { firstToUpper } from "../../../utils/helpers/string";

const AddAbsenceForm = ({ onFinish, onClose, values, error, loading, mode }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const onAllDayChange = useCallback(() => {
		form.setFieldsValue({
			date: undefined,
			startDate: undefined,
			endDate: undefined,
		});
	}, [form]);

	useEffect(() => {
		if (!values) {
			return;
		}

		const data = values["data"];

		form.setFieldsValue({
			type: data.type,
			allDay: data.allDay,
			startDate:
				data.startDate &&
				(data.allDay
					? moment.parseZone(data?.startDate).local(true).toDate()
					: moment.parseZone(data?.startDate)),
			endDate:
				data.endDate &&
				(data.allDay
					? moment.parseZone(data?.endDate).local(true).toDate()
					: moment.parseZone(data?.endDate)),
			date:
				!data.allDay &&
				moment.parseZone(data?.startDate).local(true).toDate(),
			note: data.note,
		});
	}, [form, values]);

	useEffect(() => {
		let _error;
		if (typeof error === "object" && error !== null) {
			_error = {
				...error,
				startDate: error.start,
				endDate: error.end,
			};
		} else {
			_error = error;
		}
		const fieldErrors = generateErrorsConfigForForm(
			["date", "startDate", "endDate"],
			_error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form form={form} onFinish={onFinish} className="h-100">
			<div className="p-4 content"
				style={{ 
					height: "calc(100% - 70px)", 
					overflowY: "auto", 
					overflowX: "hidden" 
				}}>
				<div className="mt-2">
					<Field
						name="type"
						label={t("absence")}
						rules={[
							{
								required: true,
								message: t("required-absence"),
							},
						]}
					>
						<ResourceSelect
							labelPropName="description"
							renderLabel={renderSettingsResourceLabel}
							valuePropName="code"
							resourcePath="/pay-code?forAbsence=true&withoutLookups=true"
							placeholder={t("absence")}
							hasSearch
							getSearchFilters={getSettingsResourceSearchFilters}
							disabled={!!mode}
						/>
					</Field>

					<Field
						name="allDay"
						label={t("all-day")}
						valuePropName="checked"
					>
						<Switch onChange={onAllDayChange} disabled={!!mode}/>
					</Field>

					<BaseField>
						{({ }, { }, { getFieldValue }) => {
							const allDay = getFieldValue("allDay");
							return allDay ? (
								<>
									<Field
										name="startDate"
										label={t("from")}
										rules={[
											{
												required: true,
												message: t("required-from"),
											},
										]}
										className="d-flex flex-column"
									>
										<DateInput placeholderText={t("from")} disabled={!!mode} />
									</Field>

									<Field
										name="endDate"
										dependencies={["startDate"]}
										label={firstToUpper(t("to"))}
										rules={[
											{
												required: true,
												message: t("required-to"),
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													const from =
														getFieldValue(
															"startDate",
														);

													if (
														moment(value).isBefore(
															moment(from),
															"date",
														)
													) {
														return Promise.reject(
															new Error(
																t("to-bigger-than-from"),
															),
														);
													}

													return Promise.resolve();
												},
											}),
										]}
										className="d-flex flex-column"
									>
										<DateInput placeholderText={firstToUpper(t("to"))} disabled={!!mode} />
									</Field>
								</>
							) : (
								<>
									<Field
										name="date"
										label={t("date")}
										rules={[
											{
												required: true,
												message: t("required-date"),
											},
										]}
										className="d-flex flex-column"
									>
										<DateInput disabled={!!mode} />
									</Field>

									<Field
										name="startDate"
										label={t("from")}
										rules={[
											{
												required: true,
												message: t("required-from"),
											},
										]}
									>
										<CustomTimeInput
											placeholder={t("from")}
											disabled={!!mode}
										/>
									</Field>

									<Field
										name="endDate"
										label={firstToUpper(t("to"))}
										dependencies={["startDate"]}
										rules={[
											{
												required: true,
												message: t("required-to"),
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													const from =
														getFieldValue(
															"startDate",
														);

													if (
														moment(
															value,
														).isSameOrBefore(
															moment(from),
															"seconds",
														)
													) {
														return Promise.reject(
															new Error(
																t("to-bigger-than-from"),
															),
														);
													}

													return Promise.resolve();
												},
											}),
										]}
									>
										<CustomTimeInput
											placeholder={firstToUpper(t("to"))}
											disabled={!!mode}
										/>
									</Field>
								</>
							);
						}}
					</BaseField>

					<hr />

					<PaidHours />
				</div>

				<FormGroup>
					<label
						className="form-control-label "
						htmlFor="exampleFormControlTextarea3"
					>
						{t("attach-request-note")}
					</label>

					<Field name="note">
						<Input
							id="exampleFormControlTextarea3"
							resize="none"
							rows="7"
							type="textarea"
							className="h-100 "
							disabled={!!mode}
						/>
					</Field>

					<footer className="note-border  p-2 border-top-0 text-sm">
						*{t("requests-send-for-manager-approval")}
					</footer>
				</FormGroup>
			</div>

			{!mode && ( 
				<div className="border-top py-3 text-center align-items-center justify-content-center">
					<Button
						className="text-muted"
						type="button"
						color="white"
						onClick={onClose}
						disabled={loading}
						icon={<FontAwesomeIcon icon={faCircleXmark} />}
					>
						{t("cancel")}
					</Button>
					<Button
						className="btn-icon text-info"
						type="submit"
						color="white"
						loading={loading}
						icon={<FontAwesomeIcon icon={faCircleCheck} />}
					>
						{t("send-for-approval")}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default AddAbsenceForm;
