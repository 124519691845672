import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Modal from "../../../../../components/Modal";
import { getEndDateFromStartDate } from "../Form/helpers";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { dateTimeFormat } from "../../../../../utils/helpers/date";
import { getStartDate, getEndDate } from "./helpers";
import SimpleTable from "../../../../../components/SimpleTable";
import { createColumnHelper } from "@tanstack/react-table";
import { useCompanyDateFormat } from "../../../../../utils/hooks/company";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import startCase from "lodash/startCase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import useApi from "../../../../../utils/api";
import classNames from "classnames";
import { Badge } from "reactstrap";

const getPayGroupCalendar = (payGroup) => {
    const periodStartDate = getStartDate(
        moment.parseZone(payGroup?.periodStartDate).format("YYYY-MM-DD"),
        payGroup?.frequency,
    );
    const periodEndDate = getEndDate(
        moment.parseZone(payGroup?.periodEndDate).format("YYYY-MM-DD"),
        payGroup?.frequency,
    );
    const periodLockDate = moment(periodEndDate).add(
        payGroup.timeSheetLockDate,
        "day",
    );
    const calendar = [
        {
            id: "current",
            periodStartDate,
            periodEndDate,
            periodLockDate,
            status: "current",
        },
    ];

    for (let i = 0; i < 5; i++) {
        const previousEndDate = calendar[i]?.periodEndDate
            ? moment(calendar[i].periodEndDate).format("YYYY-MM-DD")
            : undefined;
        const endDate = previousEndDate
            ? moment(previousEndDate).add(1, "day")
            : undefined;

        const periodStartDate = endDate
            ? getStartDate(endDate, payGroup?.frequency)
            : undefined;
        const periodEndDate = getEndDateFromStartDate(
            endDate,
            payGroup?.frequency,
        );
        const periodLockDate = moment(periodEndDate).add(
            payGroup.timeSheetLockDate,
            "day",
        );

        calendar.push({
            id: `future-${i + 1}`,
            periodStartDate,
            periodEndDate,
            periodLockDate,
            status: "future",
        });
    }

    return calendar;
};

const columnHelper = createColumnHelper();

function Calendar({ visible, close, payGroup }) {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();

    const { data, isLoading, mutate } = useSWR(() => {
        if (!payGroup) {
            return null;
        }
        return {
            url: `/pay-group/${payGroup.id}/items`,
            params: {
                page: 1,
                perPage: 5,
                "orderBy[lockTimestamp]": "desc",
            },
        };
    });

    const { authPut } = useApi();
    const { trigger, isMutating } = useSWRMutation(
        "lock-pay-group-item",
        useCallback(
            async (_, { arg }) => {
                const { id, status } = arg;
                let call;
                if (status === "locked") {
                    call = () => authPut(`/pay-group-item/${id}/unlock`);
                } else {
                    call = () => authPut(`/pay-group-item/${id}/lock`);
                }
                const res = await call();
                return mutate(
                    (prev) => {
                        return {
                            ...prev,
                            result: prev.result.map((item) => {
                                if (item.id === id) {
                                    return res;
                                }
                                return item;
                            }),
                        };
                    },
                    { revalidate: false },
                );
            },
            [authPut],
        ),
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor("periodStartDate", {
                header: t("period-start-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && dateTimeFormat(value, dateFormat);
                },
            }),
            columnHelper.accessor("periodEndDate", {
                header: t("period-end-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && dateTimeFormat(value, dateFormat);
                },
            }),
            columnHelper.accessor("periodLockDate", {
                header: t("period-lock-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value && dateTimeFormat(value, dateFormat);
                },
            }),
            columnHelper.accessor("status", {
                header: t("status"),
                cell: (info) => {
                    const value = startCase(info.getValue());
                    let color;
                    switch (info.getValue()) {
                        case "locked": {
                            color = "danger";
                            break;
                        }
                        case "active": {
                            color = "success";
                            break;
                        }
                        case "current": {
                            color = "warning";
                            break;
                        }
                    }
                    return <Badge color={color}>{value}</Badge>;
                },
            }),
            columnHelper.group({
                id: "actions",
                header: t("actions"),
                cell: ({ row }) => {
                    const { status } = row.original;
                    const locked = status === "locked";

                    if (["future", "current"].includes(status)) {
                        return null;
                    }
                    if (locked && ![3, 4].includes(row.index)) {
                        return null;
                    }
                    return (
                        <FontAwesomeIcon
                            className={classNames(!isMutating && "cursor-pointer")}
                            icon={locked ? faLock : faLockOpen}
                            onClick={() => {
                                if (isMutating) {
                                    return;
                                }
                                trigger(row.original);
                            }}
                        />
                    );
                },
            }),
        ],
        [t, dateFormat, trigger, isMutating],
    );

    const dataSource = useMemo(() => {
        if (data?.result) {
            return [
                ...[...data.result].reverse(),
                ...getPayGroupCalendar(payGroup),
            ];
        }
        return getPayGroupCalendar(payGroup);
    }, [data?.result, payGroup]);

    const title = useMemo(
        () => (
            <div className="d-flex justify-content-between align-items-center w-100">
                <div className="text-primary font-weight-bolder">
                    {payGroup &&
                        t(`${renderSettingsResourceLabel(payGroup)} timesheet`)}
                </div>

                <i
                    className="fas fa-close text-primary cursor-pointer"
                    onClick={close}
                />
            </div>
        ),
        [payGroup, close, t],
    );

    return (
        <Modal
            className="pay-group-calendar"
            title={title}
            isOpen={visible}
            close={close}
            size="lg"
            centered
        >
            <SimpleTable
                loading={isLoading}
                columns={columns}
                data={dataSource}
            />
        </Modal>
    );
}

export default Calendar;
