import { useMemo } from "react";
import useApi from "./api"
import {SWRConfig as SWRConfigProvider} from "swr";

export const SWRConfig = ({ children }) => {
    const {call} = useApi();
    const config = useMemo(() => ({
        fetcher: async (config) => {
            return call({
                method: "GET",
                ...config,
            });
        },
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    }), [call]);

    return (
        <SWRConfigProvider value={config}>
            {children}
        </SWRConfigProvider>
    );
}