export const getPropertyFromGeocoderResult = (result, property) =>
	result.address_components.find((addressComponent) =>
		addressComponent.types.includes(property),
	)?.short_name;

export const getGeocoderResultProperties = (result) => ({
	address1: result.formatted_address.split(",")[0],
	country: getPropertyFromGeocoderResult(result, "country"),
	state: getPropertyFromGeocoderResult(result, "administrative_area_level_1"),
	city: getPropertyFromGeocoderResult(result, "locality"),
	postalCode: getPropertyFromGeocoderResult(result, "postal_code"),
});

export const getAddress = async (coordinate) => {
	const response = await fromLatLng(coordinate);
	if (response) {
		const { house_number, road } = response.address;
		if (!house_number) {
			return road;
		}
		return [house_number, road].join(" ");
	}
};

export const fromLatLng = async ({ lat, lng }) => {
	const url = new URL(
		"/reverse.php",
		"https://nominatim.openstreetmap.org/",
	);
	url.searchParams.set("lat", lat);
	url.searchParams.set("lon", lng);
	url.searchParams.set("zoom", 22);
	url.searchParams.set("format", "jsonv2");

	try {
		const response = await fetch(url.toString());
		return response.json();
	} catch (err) {
		Promise.reject(new Error("Error getting the location."));
	}
};
