import moment from "moment-timezone";
import { Option } from "rc-select";
import Select from "../Select";

function DateFormatSelect({ ...props }) {
	return (
		<Select {...props}>
			<Option value="YYYY/MM/DD" key="YYYY/MM/DD">
				{moment().format("YYYY/MM/DD")}
			</Option>

			<Option value="YYYY-MM-DD" key="YYYY-MM-DD">
				{moment().format("YYYY-MM-DD")}
			</Option>

			<Option value="MM/DD/YYYY" key="MM/DD/YYYY">
				{moment().format("MM/DD/YYYY")}
			</Option>

			<Option value="MM/DD/YYYY MMM" key="MM/DD/YYYY MMM">
				{moment().format("MM/DD/YYYY MMM")}
			</Option>
		</Select>
	);
}

export default DateFormatSelect;
