import { useCallback, useState, useEffect } from "react";
import moment from "moment-timezone";
import Text from "../Text";
import { renderDurationAsFormat } from "../../../utils/helpers/date";

export const getDurationInputValue = (value) => {
	let duration = 0;
	const [hours, minutes] = value.split(":");
	if (hours && minutes) {
		duration += Number(minutes) * 60;
		duration += Number(hours) * 60 * 60;
	}
	return duration;
}

function DurationInput({ value, onChange, className, ...props }) {
	const [time, setTime] = useState("");
	const onBlur = useCallback(
		(e) => {
			let val = e.target.value;
			val = val.replace(/,/g, ".");

			if (isNaN(Number(val))) {
				val = val.replace(/([^0-9:]+)/, "");
				val = val.replace(/^(0|\:)/, "");

				const match = /(\d{0,7})[^:]*((?:\:\d{0,2})?)/g.exec(val);

				let hours = match[1];
				let minutes = match[2];

				if (
					!minutes.includes(":") &&
					hours.length > 0 &&
					hours.length < 8 &&
					hours !== ""
				) {
					minutes = ":00";
				}

				if (minutes === ":00" && hours.length >= 7) {
					minutes = ":" + e.target.value.substr(e.target.value.length);
				}
				onChange(hours + minutes);
				setTime(hours + minutes);
			} else {
				const milliseconds = moment
					.duration(parseFloat(val), "hour")
					.asSeconds();
				val = renderDurationAsFormat(milliseconds, "HH:mm", false);
				onChange(val);
				setTime(val);
			}

			if (!value) {
				setTimeout(() => {
					e.target.setSelectionRange(1, 1);
				}, 0);
			}
		},
		[value, onChange, setTime],
	);

	const onInputChange = useCallback(
		(e) => setTime(e.target.value),
		[setTime],
	);

	useEffect(() => {
		setTime(value ? value : "");
	}, [value, setTime]);

	return (
		<Text
			value={time}
			onBlur={onBlur}
			onKeyPress={(e) => {
				if (e.key === "Enter") {
					onBlur(e);
				}
			}}
			onChange={onInputChange}
			onFocus={(e) => {
				e.target.select();
			}}
			className={className}
			{...props}
		/>
	);
}

export default DurationInput;
