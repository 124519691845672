import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Card } from "reactstrap";
import { normalize } from "../../../../utils/helpers/normalize";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { useOverview } from "./context";
import Filters from "./Filters";
import Loading from "../../../../components/Loaders/Loading";
import AnalyticsTable from "./AnalyticsTable";
import { useTotals } from "./hooks/useTotals";
import { useRenderPayment } from "../../../../utils/hooks/useRenderPayment.js";
import "./_style.scss";

const hourTypes = [
    "regular",
    "overtime",
    "double-time",
    "pto",
    "amount",
    "unit",
    "holiday",
];

const Topbar = ({ page }) => {
    const { t } = useTranslation();
    const { data: apiData, loading, visible, toggle } = useOverview();

    const { data, budget } = useMemo(() => {
        if (Array.isArray(apiData)) {
            return {
                data: apiData,
                budget: { hours: 0, amount: 0 },
            };
        }
        return { data: apiData.data, budget: apiData.budget };
    }, [apiData]);

    const { totalPayment, totalDuration } = useTotals(data);

    const config = useMemo(() => {
        return normalize(data || [], "hourType");
    }, [data]);

    const breaks = useMemo(() => {
        return data.find(({ hourType }) => hourType === "break")
            ?.duration || 0;
    }, [data]);

    const renderPayment = useRenderPayment();
    const durationVar = useMemo(() => {
        let diff = budget.hours - totalDuration;
        let sign = "";
        const isNegative = diff < 0;
        if (isNegative) {
            diff = diff * -1;
            sign = "-";
        }
        return sign + renderDurationAsFormat(diff, "HH:mm")
    }, [budget.hours, totalDuration]);

    return (
        <div className="timesheet-analytics">
            <Filters
                page={page}
                isStatisticsOpen={visible}
                toggleStatistics={toggle}
            />

            <Collapse isOpen={visible}>
                <div className="d-flex justify-content-between">
                    <AnalyticsTable
                        hourTypes={hourTypes}
                        config={config}
                        data={data}
                    />

                    <div className="d-flex">
                        <Card className="small-statistic mb-0 mr-2 light">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loading />
                                </div>
                            ) : (
                                <>
                                    <h4 className="title">{t("break-hours")}</h4>
                                    <h4 className="value">{renderDurationAsFormat(breaks, "HH:mm")}</h4>
                                </>
                            )}
                        </Card>

                        {page === "team" && (
                            <>
                                <div className="d-flex flex-column mr-2">
                                    <Card className="small-statistic mb-2 light">
                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <Loading />
                                            </div>
                                        ) : (
                                            <>
                                                <h4 className="title">{t("budget-hours")}</h4>
                                                <h4 className="value">{renderDurationAsFormat(budget.hours, "HH:mm")}</h4>
                                            </>
                                        )}
                                    </Card>

                                    <Card className="small-statistic mb-0 dark">
                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <Loading />
                                            </div>
                                        ) : (
                                            <>
                                                <h4 className="title">{t("hours-var")}</h4>
                                                <h4 className="value">{durationVar}</h4>
                                            </>
                                        )}
                                    </Card>
                                </div>

                                <div className="d-flex flex-column">
                                    <Card className="small-statistic mb-2 light">
                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <Loading />
                                            </div>
                                        ) : (
                                            <>
                                                <h4 className="title">{t("budget-amount")}</h4>
                                                <h4 className="value">{renderPayment(budget.amount)}</h4>
                                            </>
                                        )}
                                    </Card>

                                    <Card className="small-statistic mb-0 dark">
                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <Loading />
                                            </div>
                                        ) : (
                                            <>
                                                <h4 className="title">{t("amount-var")}</h4>
                                                <h4 className="value">{renderPayment(budget.amount - totalPayment)}</h4>
                                            </>
                                        )}
                                    </Card>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default Topbar;
