import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	faExclamationTriangle,
	faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";

function CancelModal({ isOpen, close, reset }) {
	const { t } = useTranslation();

	const onReset = useCallback(() => {
		reset?.();
		close();
	}, [close, reset]);

	return (
		<Modal
			title={
				<div className="d-flex justify-content-between align-items-center mx-3 py-2">
					<span className="d-flex align-items-center">
						<FontAwesomeIcon
							icon={faExclamationTriangle}
							className="text-primary pr-3"
							style={{ fontSize: 20 }}
						/>
						<h2 className="m-0">
							{t("have-unsaved-changes")}!
						</h2>
					</span>

					<FontAwesomeIcon
						icon={faXmark}
						className="cursor-pointer"
						onClick={close}
					/>
				</div>
			}
			footer={
				<div className="d-flex justify-content-end mx-3 my-0 py-0">
					<Button size="sm" onClick={close} className="px-5 py-2">
						{t("no")}
					</Button>

					<Button
						size="sm"
						color="primary"
						onClick={onReset}
						className="px-5 py-2"
					>
						{t("yes")}
					</Button>
				</div>
			}
			isOpen={isOpen}
			className="cancel-modal"
			centered
		>
			<div className="d-flex flex-column my-4 mx-3">
				<h3 className="font-weight-500 pb-2">
					{t("if-proceed-lose-changes")}.
				</h3>

				<h3 className="font-weight-500 pb-2">
					{t("are-you-sure-to-discard-changes")}?
				</h3>
			</div>
		</Modal>
	);
}

export default CancelModal;
