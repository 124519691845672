import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useEmployeeNumberColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("employee-number"),
        accessor: "employeeNumber",
    }), [t]);
};
