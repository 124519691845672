import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputGroup, InputGroupText, Nav, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { renderUserName } from "../../../../../../utils/helpers/user";
import { usePeople } from "../../../../../../utils/api/people";
import classNames from "classnames";

function EmployeeSelect({ locations, selected, onSelect, disabled }) {
	const { t } = useTranslation();

	const { data, loading, fetch } = usePeople();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const filteredData = useMemo(
		() =>
			data.filter((user) => {
				return (
					selected.findIndex(
						(selectedUser) => user.id === selectedUser.id,
					) === -1
				);
			}),
		[data, selected],
	);

	const [search, setSearch] = useState("");

	const onSearchChange = useCallback(
		(e) => {
			setSearch(e.target.value);
		},
		[setSearch],
	);

	const menu = useMemo(() => {
		let content;
		if (loading) {
			content = (
				<NavItem
					key="loading"
					className="d-flex justify-content-center align-items-center px-4 py-1"
				>
					<div
						className="spinner spinner-border spinner-border-sm mr-2"
						role="status"
					/>
				</NavItem>
			);
		} else if (filteredData.length === 0) {
			content = (
				<NavItem
					key="nothing-found"
					className="d-flex justify-content-center px-4 py-1"
					disabled
				>
					{t("no-employee-found")}
				</NavItem>
			);
		} else {
			content = filteredData.map((user) => (
				<NavItem
					key={user.id}
					onClick={!disabled ? () => onSelect(user) : undefined}
					className="px-4 py-1 cursor-pointer"
				>
					{renderUserName(user)}
				</NavItem>
			));
		}

		return (
			<Nav
				className={classNames(
					"user-search card border-radius-lg shadow ml-3 w-100",
					!isMenuOpen && "d-hidden",
				)}
			>
				{content}
			</Nav>
		);
	}, [t, isMenuOpen, filteredData, loading, onSelect, disabled]);

	useEffect(() => {
		const filters = {
			[`locations.locationId`]: locations,
		};

		if (search) {
			filters.firstName = search;
		}
		fetch(filters);
	}, [locations, search, fetch]);

	return (
		<>
			<InputGroup>
				<Input
					value={search}
					onChange={onSearchChange}
					onClick={() => setIsMenuOpen(true)}
					className={classNames(
						"border-right-0",
						isMenuOpen && "employee-select",
					)}
					// onBlur={() => setIsMenuOpen(false)}
					disabled={disabled}
				/>
				<InputGroupText
					className={classNames(
						"border-left-0",
						isMenuOpen && "employee-select",
					)}
				>
					<FontAwesomeIcon
						onClick={
							disabled
								? undefined
								: () => setIsMenuOpen(!isMenuOpen)
						}
						icon={isMenuOpen ? faChevronDown : faChevronRight}
					/>
				</InputGroupText>
			</InputGroup>

			{isMenuOpen && menu}
		</>
	);
}

export default EmployeeSelect;
