import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";
import { getUploadUrl } from "../../../../utils/helpers/upload";
import { useUpdateFlyerStatus } from "./api";
import { useQrCodeClockIn } from "../../context";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";

function FlyerModal() {
    const { t, i18n: { language } } = useTranslation();
    const updateFlyerStatus = useUpdateFlyerStatus();
    const {
        updateFlyerLoading,
        dispatch,
        flyers: baseFlyers,
        clockMode
    } = useQrCodeClockIn();
    const [page, setPage] = useState(0);

    const place = useMemo(() => {
        if (clockMode === "start-shift-modal") {
            return "start-shift";
        } else if (clockMode === "start-break-modal") {
            return "start-break";
        } else if (clockMode === "end-break-modal") {
            return "end-break";
        } else if (clockMode === "end-shift-modal") {
            return "end-shift";
        }
    }, [clockMode]);

    const flyers = useMemo(() => {
        return baseFlyers
            ?.filter((item) => item?.place === place);
    }, [baseFlyers, place]);

    const [isOpen, setIsOpen] = useState(flyers?.length > 0);

    const images = useMemo(() => {
        return flyers[0]?.pages[language || "en"] || [];
    }, [flyers[0]?.pages, language]);

    const src = useMemo(() => {
        return getUploadUrl(images[page]);
    }, [images, page]);

    const onPrevClick = useCallback(() => {
        setPage((prev) => prev - 1)
    }, [setPage]);


    const mode = useMemo(() => {
        if (clockMode === "start-shift-modal") {
            return "clock-in";
        } else if (clockMode === "start-break-modal") {
            return "start-break";
        } else if (clockMode === "end-break-modal") {
            return "end-break";
        } else if (clockMode === "end-shift-modal") {
            return "clock-out";
        }
    }, [clockMode]);

    const onNextClick = useCallback(() => {
        if (images?.length > 0 && page === images?.length - 1 && flyers?.length > 1) {
            updateFlyerStatus(flyers[0]?.id, () => {
                setPage(0);
            });
        } else if (page === images?.length - 1) {
            updateFlyerStatus(flyers[0]?.id, () => {
                dispatch({
                    type: "set-clock-mode",
                    payload: { clockMode: mode }
                })
            });
        } else {
            setPage((prev) => prev + 1);
        }
    }, [mode, flyers, page, setPage, images?.length, updateFlyerStatus, dispatch]);

    const onClose = useCallback(() => {
        dispatch({
            type: "set-clock-mode",
            payload: { clockMode: mode }
        })
    }, [setIsOpen, dispatch, mode]);

    return (
        <Modal
            isOpen={isOpen}
            title={
                <div className="d-flex justify-content-between">
                    <span>
                        {renderSettingsResourceLabel(flyers[0])}
                    </span>

                    <FontAwesomeIcon
                        icon={faXmark}
                        className="cursor-pointer"
                        onClick={onClose}
                    />
                </div>
            }
            centered
        >
            <div className="w-100 mb-5">
                <img
                    className="w-100"
                    alt=""
                    src={src}
                    style={{ maxHeight: "400px", objectFit: "contain" }}
                />
            </div>

            <div className="d-flex align-items-center justify-content-center">
                <span
                    className="border border-1 px-3 py-2 rounded border-primary text-center cursor-pointer mr-2 font-weight-bold"
                    style={{
                        fontSize: "15px"
                    }}
                    onClick={(page <= 0 || updateFlyerLoading) ? undefined : onPrevClick}
                >
                    {t("prev")}
                </span>

                <span
                    className="border border-1 px-3 py-2 rounded border-primary text-center cursor-pointer mr-2 font-weight-bold"
                    style={{
                        width: "65px",
                        fontSize: "15px",
                        maxWidth: "fit-content",
                    }}
                >
                    {page + 1}
                </span>

                <span
                    className="border border-1 px-3 py-2 rounded border-primary text-center cursor-pointer font-weight-bold"
                    style={{
                        fontSize: "15px",
                    }}
                    onClick={updateFlyerLoading ? undefined : onNextClick}
                >
                    {page === images?.length - 1
                        ? t("continue")
                        : t("next")}
                </span>
            </div>
        </Modal>
    );
}

export default FlyerModal;
