import Content from "./Content";
import { DateFiltersProvider } from "./DateAndPayGroupFilters/useDateFilters";
import { FiltersProvider } from "../filtersContext";
import { PayCodesProvider } from "../reusedResourcesContext";
import { useConfigInitialFilters } from "../../Settings/Configuration/hooks/useConfigInitialFilters";
import ConfigurationLoader from "../configurationLoader";

function ManagerView() {
    const initialFilters = useConfigInitialFilters("timesheetManagerView", "employeeNumber");

    return (
        <PayCodesProvider>
            <ConfigurationLoader>
                <FiltersProvider
                    sessionKey="MANAGER_IMESHEET_FILTERS"
                    initialValue={initialFilters}
                >
                    <DateFiltersProvider>
                        <Content />
                    </DateFiltersProvider>
                </FiltersProvider>
            </ConfigurationLoader>
        </PayCodesProvider>
    );
}

export default ManagerView;
