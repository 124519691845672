import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { renderHourType } from "../../../../Settings/PayPolicies/PayCode/columns";

export const useHourTypeColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("hour-type"),
        accessor: "hourType",
        alwaysVisible: true,
        disableFilters: false,
        filter: "text",
        Cell: ({ row: { original: { payCode } } }) => payCode && (
            renderHourType(payCode.hourType).toUpperCase()
        ),
    }), [t]);
};
