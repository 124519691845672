import {useMemo} from "react";
import {useVisible} from "../../../../../utils/hooks/useVisible"
import AddBulkModal from "./Modal";

export const useAddBulk = ({ refetch, filters }) => {
    const {
        selected: users,
        visible,
        open,
        close,
    } = useVisible();

    const modal = useMemo(() => (
        <AddBulkModal
            users={users}
            visible={visible}
            close={close}
            refetch={refetch}
            filters={filters}
        />
    ), [users, visible, close, refetch, filters]);

    return [open, modal];
}
