import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Modal from "../../../components/Modal";
import Form from "./Form";
import { useUser } from "../../../utils/hooks/user";
import { useMyRequests } from "../api";
import { getPrimaryActiveResource } from "../../../utils/helpers/user";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import "./_style.scss";

function AddAbsence({ isOpen, onClose, setData }) {
	const { t } = useTranslation();

	const user = useUser();
	const { ip, getIp } = useGetIpAddress();

	const { loading, error, create } = useMyRequests();

	const onRequestSuccess = useCallback(
		(request) => setData((prev) => prev.concat(request)),
		[setData],
	);

	const onFinish = useCallback(
		(values) => {
			if (!user?.id) {
				return;
			}
			const timezone = getPrimaryActiveResource(
				user.locations || [],
			)?.timezoneValue;

			let startDate;
			let endDate;
			if (values.allDay) {
				startDate = combineDateAndTime(
					values.startDate,
					moment().startOf("day"),
				);
				endDate = combineDateAndTime(
					values.endDate,
					moment().endOf("day"),
				);
			} else {
				startDate = combineDateAndTime(values.date, values.startDate);

				endDate = combineDateAndTime(values.date, values.endDate);
			}
			if (timezone) {
				startDate.tz(timezone, true);
				endDate.tz(timezone, true);
			}

			const data = {
				type: values.type,
				allDay: values.allDay,
				note: values.note,
				startDate: startDate.toISOString(true),
				endDate: endDate.toISOString(true),
			};

			const request = {
				type: "absence",
				user: user.id,
				data,
				device: "web-application",
				ip: ip ? ip : "",
			};

			create(request, (request) => {
				onClose();
				onRequestSuccess(request);
				toast.success(t("request-created-successfully"));
			});
		},
		[user?.id, user?.locations, ip, create, onClose, onRequestSuccess, t],
	);

	const values = { data: { allDay: true } };

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	return (
		<Modal
			className="add-absence-modal modal-dialog-centered modal-lg pb-0 shadow-none"
			title={t("add-absence")}
			isOpen={isOpen}
			onClose={onClose}
			size="lg"
			scrollable
		>
			<Form
				onClose={onClose}
				values={values}
				onFinish={onFinish}
				error={error}
				loading={loading}
			/>
		</Modal>
	);
}

export default AddAbsence;
