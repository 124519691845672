import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import Button from "../../../../components/Button";
import NTable from "../../../../components/NTable";
import Form from "./Form";
import EmailFtpTooltip from "./EmailFtpTooltip";
import LogModal from "./LogModal";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useVisible } from "../../../../utils/hooks/useVisible";
import {useNewTable} from "../../../../components/NTable/hook";

function Reports() {
	const { t } = useTranslation();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
	} = useSettingsCRUDHook("/scheduling/report");

	const {
		open: openEmailFtpTooltip,
		visible: isEmailFtpTooltipOpen,
		close: closeEmailFtpTooltip,
		selected: selectedEmailFtp,
	} = useVisible();

	const {
		open: openLogs,
		visible: isLogsModalVisible,
		close: closeLogModal,
	} = useVisible();

	const { hasAccess: canAdd } = useAccess(
		"settings.scheduling.reports.canCreate",
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t("do-you-want-to-delete-items", { count: data?.length })
				: t("do-you-want-to-delete-item"),
		onConfirm: (data) =>
			Array.isArray(data) ? batchRemove(data) : remove(data),
        permission: "settings.scheduling.reports.canDelete",
	});

	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
		edit: useActionWithAccess(
            goToEdit,
            "settings.scheduling.reports.canEdit",
        ),
		remove: openDeleteModal,
		clone: useActionWithAccess(
            goToClone,
            "settings.scheduling.reports.canCreate",
        ),
		openEmailFtpTooltip,
	});

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "scheduling-reports",
    });

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-report-schedule"),
		create: t("add-report-schedule"),
		edit: t("edit-report-schedule"),
		clone: t("clone-report-schedule"),
	});

	useFetchResource({ fetch, state: table.getState() });

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("reports")}
					add={canAdd && goToCreate}
					help={goToHelp}
					loading={loading}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    >
                        <div className="d-flex flex-row alig-items-center">
                            <BulkActions
                                rows={table.getState().rowSelection}
                                batchRemove={openDeleteModal}
                            />

                            <Button
                                type="button"
                                size="sm"
                                className="btn-round btn-icon shadow-none border"
                                onClick={openLogs}
                            >
                                {t("show-logs")}
                            </Button>
                        </div>
                    </Actions>

                    <NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			{selectedEmailFtp && (
				<EmailFtpTooltip
					close={closeEmailFtpTooltip}
					isOpen={isEmailFtpTooltipOpen}
					selected={selectedEmailFtp}
				/>
			)}

			{isLogsModalVisible && (
				<LogModal isOpen={isLogsModalVisible} close={closeLogModal} />
			)}

			{deleteModal}

			{unarchiveModal}
		</div>
	);
}

export default Reports;
