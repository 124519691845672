import { useMemo } from "react";
import Filters from "../../../../components/Table/Actions/Filters";
import { useFilters as useTimeSheetFilters } from "../../../TimeSheet/Home/Actions/useFilters";
import { useStatusFilter } from "./useStatusFilter";

function MySchedulerFilters({ filters, setFilters }) {
    const filterColumns = useTimeSheetFilters();
    const statusFilter = useStatusFilter();

    const columns = useMemo(() => [
        ...(filterColumns || [])?.filter((filter) => filter?.id !== "status"),
        statusFilter
    ], [filterColumns, statusFilter]);

    return (
        <Filters
            columns={columns}
            filters={filters}
            setFilters={setFilters}
        />
    );
}

export default MySchedulerFilters;
