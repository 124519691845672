import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import moment from "moment-timezone";
import Analytics from "../../../../components/Analytics";
import { renderDurationAsNumber } from "../../../../utils/helpers/date";
import { useRenderPayment } from "../../../../utils/hooks/useRenderPayment";
import { useTotals } from "../../../TimeSheet/components/Topbar/hooks/useTotals";
import { useFetchTeamScheduleAnalytics } from "../../api/useFetchAnalytics";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";

const hourTypes = [
    "regular",
    "overtime",
    "double-time",
    "pto",
    "holiday",
];

function TeamSchedulerAnalytics({ params }) {
    const { t } = useTranslation();
    const { data, isLoading } = useFetchTeamScheduleAnalytics({
        filters: omit(params, "startDate", "endDate", "pageIndex", "pageSize"),
        startDate: moment(params?.startDate).format("YYYY-MM-DD"),
        endDate: moment(params?.endDate).format("YYYY-MM-DD"),
    });

    const renderPayment = useRenderPayment();
    const renderDuration = useCallback((value) => {
        return renderDurationAsNumber(value);
    }, []);

    const { totalPayment, totalDuration } = useTotals(data?.data);
    const breaks = useMemo(() => {
        return data.data.find(({ hourType }) => hourType === "break")
            ?.duration || 0;
    }, [data.data]);

    const durationVar = useMemo(() => {
        let diff = data.budget.hours - totalDuration;
        let sign = "";
        const isNegative = diff < 0;
        if (isNegative) {
            diff = diff * -1;
            sign = "-";
        }
        return sign + renderDurationAsFormat(diff, "HH:mm")
    }, [data.budget.hours, totalDuration]);

    const statistics = useMemo(() => [
        {
            label: t("break-hours"),
            value: renderDuration(breaks, "HH:mm"),
        },
        [
            {
                label: t("budget-hours"),
                value: renderDurationAsFormat(data.budget.hours, "HH:mm")
            },
            {
                label: t("hours-var"),
                value: durationVar,
                type: "dark"
            }
        ],
        [
            {
                label: t("budget-amount"),
                value: renderPayment(data.budget.amount),
            },
            {
                label: t("amount-var"),
                value: renderPayment(data.budget.amount - totalPayment),
                type: "dark",
            },
        ],
    ], [t, data.budget, breaks, totalPayment, durationVar]);

    return (
        <Analytics
            hourTypes={hourTypes}
            data={data.data}
            loading={isLoading}
            statistics={statistics}
        />
    );
}

export default TeamSchedulerAnalytics;
