import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import Select from "../../../../components/Inputs/Select";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone, canUseInClockIn }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const { code, description, status, actions } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
            columnHelper.accessor("glSegment", {
                enableColumnFilter: true,
                header: t("gl-segment"),
            }),
			columnHelper.accessor("manageAbility", {
                enableColumnFilter: true,
                header: t("manage-ability"),
				cell: (info) => {
					const value = info.getValue();
					return value && t(value)
				},
				filterType: "select",
				Filter: (
					<Select>
						<Select.Option value="all" key="all">
							{t("all")}
						</Select.Option>

						<Select.Option value="custom" key="custom">
							{t("custom")}
						</Select.Option>

						<Select.Option value="by-company" key="by-company">
							{t("by-company")}
						</Select.Option>

						<Select.Option value="by-pay-groups" key="by-pay-groups">
							{t("by-pay-groups")}
						</Select.Option>

						<Select.Option value="by-unions" key="by-unions">
							{t("by-unions")}
						</Select.Option>
					</Select>
				),
            }),
			status,
		];

		if (canUseInClockIn) {
			columns.push(
                columnHelper.accessor("address", {
                    header: t("address1"),
                }),
				columnHelper.accessor("country.name", {
					header: t("country"),
				}),
				columnHelper.accessor("state", {
					header: t("state"),
				}),
				columnHelper.accessor("city", {
					header: t("city"),
				}),
				columnHelper.accessor("postalCode", {
					header: t("postal-code"),
				}),
			);
		}

		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [
		t,
		isMasterCompany,
        edit,
        remove,
        clone,
		code,
		description,
		status,
		actions,
		canUseInClockIn,
	]);
};
