import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppLoader from "../components/Loaders/AppLoader";
import { useAuthentication } from "../features/Authentication/context";

function DomainValidation({ domain, isSecure = false }) {
	const navigate = useNavigate();
	const {
		isMainDomain,
		hasValidatedDomain,
		validatingDomain,
		authenticated,
		token,
	} = useAuthentication();

	useEffect(() => {
		if (
			(domain === "main" && !isMainDomain) ||
			(domain === "sub" && isMainDomain)
		) {
			navigate("/redirect");
		}

		if (!isSecure && token) {
			navigate("/redirect");
		}
	}, [
		navigate,
		domain,
		token,
		isSecure,
		isMainDomain,
		validatingDomain,
		authenticated,
	]);

	if (!hasValidatedDomain || validatingDomain) {
		return <AppLoader />;
	}

	return <Outlet />;
}

export default DomainValidation;
