import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { firstToUpper } from "../../../../utils/helpers/string";
import {createColumnHelper} from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const {
        code,
        description,
        status,
        actions,
    } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
            { ...code, header: t("name") },
			description,
			status,
            columnHelper.accessor("timeOffStatus", {
				header: t("time-off-status"),
				cell: (info) => {
                    const value = info.getValue();
					return value === "pto"
                        ? value.toUpperCase()
                        : firstToUpper(value);
                },
            }),
			columnHelper.accessor("accural.method", {
				header: t("method"),
				cell: (info) => firstToUpper(info.getValue()),
			}),
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [t, isMasterCompany, code, description, status, actions]);
};
