import { useMemo } from "react";
import { useConfigurationModule } from "./useConfigurationModule";

export const useConfigFilterColumns = (feature, filters) => {
    const configFilters = useConfigurationModule(`${feature}.filters`);

    const hidden = useMemo(() => {
        if (!configFilters) {
            return [];
        }
        filters = configFilters
            .filter(({ hide }) => hide)
            .map(({ accessor }) => accessor);
        return filters || [];
    }, [configFilters, feature]);

    return useMemo(() => {
        return filters.filter(({ id, accessor }) => {
            return (
                !hidden.includes(accessor)
                && !hidden.includes(id)
            );
        });
    }, [filters, hidden]);
};
