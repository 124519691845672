import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import Text from "../../../../../components/Inputs/Text";
import Switch from "../../../../../components/Inputs/Switch";
import { ProfileContext } from "../../../context";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";

function TwoFactorAuth({ close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { user, submitting, submittingError, submit } =
        useContext(ProfileContext);

    const { twoFactorAuth: profileSubmit } = useProfileApi();
    const { twoFactorAuth: peopleSubmit } = usePeopleApi();

    const onFinish = useCallback((values) => {
        const data = {
            status: (values?.status && values?.other) || (values?.status && values?.type)
                ? "in-verification"
                : "inactive",
            type: values?.type,
            email: values?.other ? values?.other : values?.email,
            other: values?.email === "other"
        };

        submit(profileSubmit, peopleSubmit, data, close);
    }, [submit, peopleSubmit, profileSubmit, close]);

    useEffect(() => {
        form.setFieldsValue({
            status: user?.twoFA?.status === "active" ||
                user?.twoFA?.status === "in-verification"
                ? true
                : false,
            type: user?.twoFA?.type,
            email: user?.twoFA?.other ? "other" : user?.twoFA?.email,
            other: user?.twoFA?.other ? user?.twoFA?.email : undefined,
        });
    }, [user, form]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            [
                "status",
                "email",
                "type",
                "other"
            ],
            submittingError,
        );
        form.setFields(fieldErrors);
    }, [form, submittingError]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
        >
            <Field
                name="status"
                valuePropName="checked"
                label={t("enable-two-factor-auth")}
            >
                <Switch id="status" />
            </Field>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const status = getFieldValue("status");

                    return status && (
                        <Field
                            name="type"
                            label={t("auth-type")}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-auth-type")
                                }
                            ]}
                        >
                            <Select placeholder={t("auth-type")}>
                                <Select.Option value="auth-app" key="auth-app">
                                    {t("auth-app")}
                                </Select.Option>
                                <Select.Option value="email" key="email">
                                    {t("email")}
                                </Select.Option>
                            </Select>
                        </Field>
                    );
                }}
            </BaseField>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const type = getFieldValue("type");
                    const status = getFieldValue("status");

                    return type === "email" && status && (
                        <Field
                            name="email"
                            label={t("email")}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-email")
                                }
                            ]}
                            dependencies={["type", "status"]}
                        >
                            <Select placeholder={t("email")} allowClear >
                                {user?.email && (
                                    <Select.Option value={user?.email} key={user?.email}>
                                        {user?.email}
                                    </Select.Option>
                                )}

                                {user?.alternateEmail && (
                                    <Select.Option value={user?.alternateEmail} key={user?.alternateEmail}>
                                        {user?.alternateEmail}
                                    </Select.Option>
                                )}
                                <Select.Option value="other" key="other">
                                    {t("other")}
                                </Select.Option>
                            </Select>
                        </Field>
                    );
                }}
            </BaseField>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const otherEmail = getFieldValue("email");
                    const status = getFieldValue("status");
                    const type = getFieldValue("type");

                    return otherEmail === "other" && status && type === "email" && (
                        <Field
                            name="other"
                            label={t("other")}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-email")
                                },
                                {
                                    type: "email",
                                    message: t("invalid-email")
                                }
                            ]}
                            dependencies={["email", "status", "type"]}
                        >
                            <Text placeholder={t("other")} />
                        </Field>
                    );
                }}
            </BaseField>

            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    onClick={close}
                    disabled={submitting}
                >
                    {t("cancel")}
                </Button>

                <Button
                    color="primary"
                    type="submit"
                    loading={submitting}
                    className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
                >
                    {t("save")}
                </Button>
            </div>
        </Form>
    )
}

export default TwoFactorAuth;
