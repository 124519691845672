import {useMemo} from "react";
import {renderSettingsResourceLabel} from "../../../../../utils/helpers/settings";
import {useGroups} from "../../../../Settings/General/OrganisationLevelGroups/context";
import LevelSelect from "../../LevelSelect";
import {isAddedTimeSheet} from "../helpers";

export const useGroupsColumns = ({ canEdit }) => {
    const { groups } = useGroups();

    return useMemo(() => groups.map((group) => ({
        Header: group?.description,
        accessor: group?.level.replace("_", ""),
        alwaysVisible: group.allocatedBy,
        disableFilters: false,
        filter: "text",
        Cell: ({ value, row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const {original} = row;
            const { locked, user } = original;
            const disabled = (!isAddedTimeSheet(original) && locked)
                || row.disabled
                || cell.disabled;

            if (!group.allocatedBy) {
                return value && renderSettingsResourceLabel(value);
            }

            return (
                <LevelSelect
                    className="select-input group-input w-100"
                    group={group.id}
                    location={original.location?.id}
                    value={value}
                    disabled={disabled || !canEdit}
                    onChange={(val) => {
                        const level = group.level.replace("_", "");
                        const data = { [level]: val };
                        if (isAddedTimeSheet(original)) {
                            updateAddedTimeSheet(original, data);
                        } else {
                            updateTimeSheet(original.id, data);
                        }
                    }}
                    payGroup={user.payGroup}
                    union={user.union}
                />
            );
        },
    })), [groups, canEdit]);
};
