import { createContext, useCallback, useContext, useMemo } from "react";
import useSWR from "swr";
import { useCompany } from "../../../../utils/hooks/company";

export const OrgLevelGroupsContext = createContext({
	groups: [],
	allocatedGroups: [],
	setGroups: () => { },
	loading: false,
});

export const OrgLevelGroupProvider = ({ children }) => {
	const hasCompany = !!useCompany();
	const {
		data: { result: groups },
		isLoading: loading,
		mutate,
	} = useSWR(
		() => {
			if (!hasCompany) {
				return null;
			}
			return {
				url: "/org-level-groups",
				params: {
					status: ["active", "inactive"],
					page: 1,
					perPage: 10,
				}
			};
		},
		{ fallbackData: { result: [] } },
	);
	const setData = useCallback((argument) => {
		mutate(argument, {
			revalidate: false
		});
	}, [groups, mutate]);

	const allocatedGroups = useMemo(() => {
		return groups.filter(({ allocatedBy }) => allocatedBy);
	}, [groups]);

	const contextValue = useMemo(
		() => ({
			groups,
			allocatedGroups,
			loading,
			setGroups: setData,
		}),
		[groups, setData, allocatedGroups, loading],
	);

	return (
		<OrgLevelGroupsContext.Provider value={contextValue}>
			{children}
		</OrgLevelGroupsContext.Provider>
	);
};

export const useGroups = () => {
	const context = useContext(OrgLevelGroupsContext);
	if (context === undefined) {
		throw new Error("useGroups should be used within a provider");
	}
	return context;
};
