import { useCallback, useMemo } from "react";
import { Navigate } from "react-router-dom";
import DomainValidation from "../DomainValidation";
import Authentication from "../Authentication";
import Redirect from "../Redirect";
import GlobalLayout from "../../components/GlobalLayout";

import DomainSelect from "../../features/Authentication/DomainSelect";
import SignUp from "../../features/Authentication/SignUp";
import LogIn from "../../features/Authentication/LogIn";
import Home from "../../features/Home";
import Profile from "../../features/Profile";
import ResetPassword from "../../features/Authentication/ResetPassword";
import CompanySelect from "../../features/Authentication/CompanySelect";
import CompanyOnboarding from "../../features/Authentication/CompanyOnboarding";
import SetNewPassword from "../../features/Authentication/SetNewPassword";
import RequiredSetPassword from "../../features/Authentication/RequiredSetPassword";
import Confirm from "../../features/Authentication/Confirm";
import People from "../../features/People";
import TimeClockClockIn from "../../features/TimeClock/ClockIn";
import TimeClockTeam from "../../features/TimeClock/MyTeam";

import TimeSheetHome from "../../features/TimeSheet/Home";
import TimeSheetTeam from "../../features/TimeSheet/Team";
import TimeSheetWeekly from "../../features/TimeSheet/Weekly";

import RequestHome from "../../features/Request/Home";
import RequestTeam from "../../features/Request/Team";

import AddEmployee from "../../features/People/AddEmployee";
import EmployeePersonalDetails from "../../features/People/EmployeePersonalDetails";
import Onboarding from "../../features/Settings/Onboarding";
import { useSettingsRoutes } from "./settings";
import { useProfileRoute } from "./profile";

import QrCodeClockin from "../../features/QrCodeClockin/routing";

import OriginalClocks from "../../features/TimeClock/OriginalClocks";
import TimeSheetRedirect from "../../features/TimeSheet/Redirect";
import RequestRedirect from "../../features/Request/Redirect";
import TimeClockRedirect from "../../features/TimeClock/Redirect";
import ManagerView from "../../features/TimeSheet/ManagerView";
import SchedulerRedirect from "../../features/Scheduler/Redirect";
import SchedulerHome from "../../features/Scheduler/Home";
import SchedulerTeam from "../../features/Scheduler/Team";
import SystemReports from "../../features/Reports/SystemReports";
import CustomReports from "../../features/Reports/CustomReports";
import Reports from "../../features/Reports";
import GoogleAuth from "../../features/Authentication/GoogleAuth";
import { useUser } from "../../utils/hooks/user";
import { useAccessInList } from "../../utils/hooks/access";
import { getViewPermissions } from "../../utils/helpers/access";
import { useAuthentication } from "../../features/Authentication/context";

export const useRouteConfig = () => {
	const user = useUser();
	const settings = useSettingsRoutes(user?.permissions);
	const userProfile = useProfileRoute(user?.permissions?.people);
	const profile = useProfileRoute(user?.permissions?.profile);
	
	const timeClockPermissions = useMemo(
		() =>
		  user?.permissions?.clockin
			? getViewPermissions(user?.permissions?.clockin, "clockin")
			: [],
		[user?.permissions?.clockin]
	  );
	const { hasAccess: canViewClockin } = useAccessInList(timeClockPermissions);

	const getRoutes = useCallback((config) => {
		let routes = [];
		if (Array.isArray(config)) {
			config?.forEach((element, i) => {
				if (!element?.children && element?.access) {
					routes.push(element);
				} else if (element?.children && (element?.access || typeof element?.access === "undefined")) {
					routes.push({
						...element,
						children: getRoutes(element?.children)
					})
				}
			});
		}
		return routes;
	}, []);

	const routes = useMemo(() => {
		const hasUser = !!user;
		const permissions = user?.permissions;
		
		return [
				{
					path: "/",
					element: <DomainValidation domain="main" isSecure={false} />,
					children: [
						{
							path: "domain-select",
							element: <DomainSelect />,
							access: true,
						},
						{
							path: "signup",
							element: <SignUp />,
							access: true,
						},
					],
				},
				QrCodeClockin,
				{
					path: "/",
					element: <DomainValidation domain="sub" isSecure={false} />,
					children: [
						{
							path: "login",
							element: <LogIn />,
							access: true,
						},
						{
							path: "confirm",
							element: <Confirm />,
							access: true,
						},
						{
							path: "reset-password",
							element: <ResetPassword />,
							access: true,
						},
						{
							path: "set-new-password",
							element: <SetNewPassword />,
							access: true,
						},
						{
							path: "set-employee-personal-details",
							element: <EmployeePersonalDetails />,
							access: true,
						},
					],
				},
				{
					path: "/",
					element: <Authentication />,
					children: [
						{ path: "login/validate", element: <GoogleAuth />, access: hasUser },
						{ path: "company-selection", element: <CompanySelect />, access: hasUser },
						{ path: "company-onboarding", element: <CompanyOnboarding />, access: hasUser },
						{ path: "required-set-password", element: <RequiredSetPassword />, access: hasUser },
						{ path: "settings/onboarding", element: <Onboarding />, access: hasUser },
						{
							element: <GlobalLayout />,
							children: [
								{ index: true, element: <Home />, access: permissions?.dashboard?.canView },
								{
									path: "people",
									access: permissions?.people?.canView,
									children: [
										{ index: true, element: <People />, access: permissions?.people?.canView },
										{ path: "add-employee", element: <AddEmployee />,  access: permissions?.people?.canCreate },
										{
											path: ":id",
											element: <Profile isSelf={false} />,
											access: permissions?.people?.canView,
											children: userProfile,
										},
									],
								},
								{
									path: "time-clock",
									access: canViewClockin,
									children: [
										{
											index: true,
											element: <TimeClockRedirect />,
											access: permissions?.canManageUsers 
												? permissions?.canManageUsers 
												: (permissions?.clockin?.canView || permissions?.clockin?.canViewOriginalClock)
										},
										{ path: "my", element: <TimeClockClockIn />, access: permissions?.clockin?.canView   },
										{ path: "team", element: <TimeClockTeam />, access: permissions?.canManageUsers },
										{
											path: "original-clocks",
											element: <OriginalClocks />,
											access: permissions?.clockin?.canViewOriginalClock
										},
									],
								},
								{
									path: "time-sheet",
									access: permissions?.timeSheet?.canView || permissions?.timeSheet?.canViewMyTimesheet,
									children: [
										{
											index: true,
											element: <TimeSheetRedirect />,
											access: permissions?.timeSheet?.canView || permissions?.timeSheet?.canViewMyTimesheet,
										},
										{ path: "my", element: <TimeSheetHome />, access: permissions?.timeSheet?.canViewMyTimesheet },
										{ path: "team", element: <TimeSheetTeam />, access: permissions?.timeSheet?.canView },
										{ path: "weekly", element: <TimeSheetWeekly />, access: permissions?.timeSheet?.canView },
										{ path: "manager-view", element: <ManagerView />, access: permissions?.timeSheet?.canView },
									],
								},
								{
									path: "scheduler",
									access: permissions?.schedule?.canView || permissions?.schedule?.canViewMySchedule,
									children: [
										{
											index: true,
											element: <SchedulerRedirect />,
											access: permissions?.schedule?.canView || permissions?.schedule?.canViewMySchedule,
										},
										{ path: "my", element: <SchedulerHome />, access: permissions?.schedule?.canViewMySchedule },
										{ path: "team", element: <SchedulerTeam />, access: permissions?.schedule?.canView },
									],
								},
								{
									path: "request",
									access: permissions?.request?.canView || permissions?.request?.canViewTeamRequest,
									children: [
										{ 
											index: true, 
											element: <RequestRedirect />, 
											access: permissions?.request?.canView || permissions?.request?.canViewTeamRequest 
										},
										{ path: "my", element: <RequestHome />, access: permissions?.request?.canView },
										{ path: "team", element: <RequestTeam />, access: permissions?.request?.canViewTeamRequest },
									],
								},
								{
									path: "profile",
									element: <Profile isSelf={true} />,
									access: permissions?.profile?.canView,
									children: profile,
								},
								{
									path: "reports",
									access: permissions?.reports?.canView,
									children: [
										{ index: true, element: <Reports />, access: permissions?.reports?.canView, },
										{ path: "system-reports", element: <SystemReports />, access: permissions?.reports?.canView },
										{ path: "custom-reports", element: <CustomReports />, access: permissions?.reports?.canView },
									]
								},
								settings,
							],
						},
					],
				},
				{
					path: "/redirect",
					element: <Redirect />,
					access: true,
				},
				{
					path: "*",
					element: <Navigate to="/redirect" />,
					access: true
				},
		];
	}, [
		user?.permissions, 
		settings, 
		userProfile, 
		profile, 
		canViewClockin,
	]);

  const { hasAuthenticated } = useAuthentication();
  
	const validRoutes = useMemo(() => {
		if (!hasAuthenticated) {
			return routes;
		} else {
			return getRoutes(routes);
		}
	}, [getRoutes, routes, hasAuthenticated]);

	return validRoutes;
}
