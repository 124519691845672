import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Form from "rc-field-form";
import { NavLink } from "reactstrap";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Password from "../../../components/Inputs/Password";
import { useChangePassword } from "./api";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function SetNewPasswordForm() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();

	const { loading, error, changePassword } = useChangePassword();
	const token = useMemo(() => {
		return searchParams.get("token");
	}, [searchParams]);

	const onFinish = useCallback(
		(values) => {
			if (!token) {
				return;
			}
			const { newPassword } = values;
			changePassword({ newPassword, token });
		},
		[token, changePassword]
	);

	useEffect(() => {
		const formErrors = generateErrorsConfigForForm(
			["newPassword", "confirmNewPassword"],
			error
		);
		form.setFields(formErrors);
	}, [error]);

	return (
		<Form autocomplete="off" form={form} onFinish={onFinish}>
			<Field
				name="newPassword"
				rules={[
					{
						required: true,
						message: t("input-your-password"),
					},
				]}
			>
				<Password placeholder={t("new-password")} />
			</Field>

			<Field
				name="confirmNewPassword"
				rules={[
					{
						required: true,
						message: t("confirm-your-password"),
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							const newPassword = getFieldValue("newPassword");
							if (
								!value ||
								!newPassword ||
								newPassword === value
							) {
								return Promise.resolve();
							}
							return Promise.reject(
								new Error(
									t(
										"passwords-dont-match"
									)
								)
							);
						},
					}),
				]}
			>
				<Password
					placeholder={t("confirm-your-password")}
				/>
			</Field>

			<div className="d-flex justify-content-between text-sm mb-5">
				{/* <div className="d-flex">
					<label className="custom-toggle mr-1">
						<Input type="checkbox" size="small" />
						<span className="custom-toggle-slider" />
					</label>

					{t("remember-me")}
				</div> */}
				<div />

				<NavLink
					href="#"
					className="text-primary text-gradient font-weight-bold text-sm"
				>
					{t("trouble-while-signin")}
				</NavLink>
			</div>

			<div className="d-flex justify-content-end">
				<Button color="primary" loading={loading} type="submit">
					{t("set-new-password")}
				</Button>
			</div>
		</Form>
	);
}

export default SetNewPasswordForm;
