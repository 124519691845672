export const currencyFormatter = (value, fixedValue, currency) => {
	// const formatter = new Intl.NumberFormat("en-US", {
	// 	style: "currency",
	// 	currency: currency || "usd",
	// 	minimumFractionDigits: fixedValue,
	// 	maximumFractionDigits: fixedValue,
	// });

	// return formatter.format(value);

	return value?.toLocaleString("en-US", {
		style: "currency",
		currency: currency || "USD",
	});
};
