import { useMemo } from "react";

export const useMergeConfigurations = (base, current) => {
    return useMemo(() => {
        if (!base) {
            return;
        }
        if (!current) {
            return base;
        }
        const currentColumnsMap = current.reduce((total, column) => {
            const key = column.accessor || column.id;
            total[key] = column;
            return total;
        }, {});
        return base.map((column) => {
            const { id, accessor } = column;
            const currentColumn = currentColumnsMap[accessor || id];
            if (currentColumn) {
                return {
                    ...column,
                    ...currentColumn,
                };
            }
            return column;
        })
    }, [base, current]);
}
