import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function Tag({ remove, key, item }) {
	return (
		<div
			className="tag d-flex align-items-center border-primary border"
			key={key}
		>
			{item}

			<FontAwesomeIcon
				icon={faClose}
				onClick={remove}
				className="cursor-pointer text-primary ml-2 text-sm"
			/>
		</div>
	);
}

export default Tag;
