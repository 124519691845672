import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "libphonenumber-js";
import { createColumnHelper } from "@tanstack/react-table";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit }) => {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const budgetGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const { description, status, actions } = useCommonColumns({ edit });

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("number", {
				enableColumnFilter: true,
				header: t("number"),
				cell: (info) => formatNumber(info.getValue(), "INTERNATIONAL"),
			}),
			description,
			status,
			columnHelper.accessor("location", {
				enableColumnFilter: true,
				header: t("location"),
				cell: (info) => {
					const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
				},
				filter: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/locations"
						renderLabel={renderSettingsResourceLabel}
						mode="multiple"
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			}),
			columnHelper.accessor("timezone.text", {
				header: t("timezone"),
			}),
		];

		if (budgetGroup) {
			const budgetGroupColumn = columnHelper.accessor("project", {
				enableColumnFilter: true,
				header: budgetGroup.description,
				cell: (info) => {
					const value = info.getValue();
					return value && renderOrgLevelResourceLabel(value);
				},
				filter: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath={`${budgetGroup.id}/org-levels`}
						renderLabel={renderOrgLevelResourceLabel}
						placeholder={budgetGroup.code + " - " + budgetGroup.description}
						mode="multiple"
						hasSearch
						getSearchFilters={getSettingsOrgResourceSearchFilters}
					/>
				),
			});
			columns.push(
				budgetGroupColumn,
				columnHelper.accessor("project.code", {
					header: t("code"),
				}),
				columnHelper.accessor("project.glSegment", {
					header: t("gl-segment"),
				}),
			);
		}

		if (actions) {
			columns.push(actions);
		}
		return columns;
	}, [t, description, status, budgetGroup, actions]);
};
