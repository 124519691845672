import moment from "moment-timezone";
import { useMemo } from "react";
import { DateFiltersContext } from "../../../../../hooks/useDateFilters";
import UserTable from "./UserTable";

const Preview = ({ users, values, timeSheets }) => {
    const { startDate, endDate } = values;
    const dateFiltersContextValue = useMemo(() => {
        return {
            mode: "custom",
            from: moment(startDate).toISOString(),
            to: moment(endDate).toISOString(),
        };
    }, [startDate, endDate]);

    return (
        <DateFiltersContext.Provider value={dateFiltersContextValue}>
            {users && timeSheets && (
                <UserTable data={users} timeSheets={timeSheets} />
            )}
        </DateFiltersContext.Provider>
    );
}

export default Preview;
