import { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../Layout";
import Card from "../Layout/Card";
import ResetPasswordForm from "./Form";

function ResetPassword() {
	const { t } = useTranslation();
	const [step, setStep] = useState(1);

	const onNext = useCallback(() => {
		setStep((prev) => prev + 1);
	}, [setStep]);

	const cardTitle = useMemo(() => {
		switch (step) {
			case 1:
				return t("reset-password");
			case 2:
				return t("email-sent");
			default:
				break;
		}
	}, [t, step]);

	const cardText = useMemo(() => {
		switch (step) {
			case 1:
				return t("receive-reset-link");
			case 2:
				return t("email-sent-description");
			default:
				break;
		}
	}, [t, step]);

	return (
		<Layout
			image="/assets/images/reset-password.jpg"
			text="It is during our darkest moments that we must focus to see the light."
			autor="Aristotle"
		>
			<Card
				cardTitle={cardTitle}
				cardText={cardText}
				cardFooter={step === 1 ? "go-back" : ""}
				link={step === 1 && "/login"}
				linkText={step === 1 ? "sign-in" : ""}
				cardBody={step === 1 && <ResetPasswordForm onNext={onNext} />}
				className={step === 2 && "text-center"}
			/>
		</Layout>
	);
}

export default ResetPassword;
