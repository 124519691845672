import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Form from "./Form";
import useSettingsCRUDHook from "../../hook";
import Drawer from "../../../../components/Drawer";
import Topbar from "../../components/Topbar";
import Actions from "./Actions";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useColumns } from "./columns";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useGroups } from "../../General/OrganisationLevelGroups/context";
import {useQueryFilters} from "../../helpers/useQueryFilters";
import {useNewTable} from "../../../../components/NTable/hook";
import NTable from "../../../../components/NTable";
import {useVisibleColumnKeys} from "../../helpers/useVisibleColumnKeys";
import {useOnExport} from "../../helpers/useOnExport";
import {useImportModal} from "../../helpers/useImportModal";

function Device() {
	const { t } = useTranslation();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/devices");

	const { groups } = useGroups();
	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const { hasAccess: canAdd } = useAccess("device.canCreate");

	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
        edit: useActionWithAccess(goToEdit, "device.canCreate")
    });
    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "devices",
        enableRowSelection: false,
    });

    const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-device"),
		create: t("add-new-device"),
		edit: t("edit-your-device"),
	});

	useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/device",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/devices/import",
        exportUrl: "/export/device",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title="Devices"
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
                        group={group}
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    />

                    <NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			{importModal}

			{unarchiveModal}
		</div>
	);
}

export default Device;
