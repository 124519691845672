import { camelCase } from "lodash";
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import Checkbox from "../../../Inputs/Checkbox";

function ColumnToggle({ prefix, columns }) {
	return (
		<UncontrolledDropdown>
			<DropdownToggle
				size="sm"
				color="neutral"
				className="inline-flex items-center shadow-none p-0 mr-0"
			>
				<i
					className="fas fa-list text-dark"
					style={{ fontSize: "16px" }}
				/>
			</DropdownToggle>

			<DropdownMenu
				right
				style={{
					maxHeight: "300px",
					overflowY: "auto",
					minWidth: "14rem",
				}}
			>
				{columns
					.filter(({ hideable }) => hideable)
					.map(
						({
							Header,
							columnToggleLabel,
							getToggleHiddenProps,
							alwaysVisible,
						}) => {
							let id = "";
							let key = "";

							if (columnToggleLabel) {
								key = columnToggleLabel;
							} else {
								key = Header;
							}

							if (columnToggleLabel) {
								id = `${camelCase(
									columnToggleLabel,
								)}-column-toggle`;
							} else {
								id = `${camelCase(Header)}-column-toggle`;
							}

							if (prefix) {
								id = `${prefix}-${id}`;
							}

							return (
								<DropdownItem key={key} toggle={false}>
									<Checkbox
										{...getToggleHiddenProps({
											id,
											disabled: alwaysVisible,
										})}
									>
										{columnToggleLabel
											? columnToggleLabel
											: Header}
									</Checkbox>
								</DropdownItem>
							);
						},
					)}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}

export default ColumnToggle;
