import { useMemo, useState } from "react";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";

export const useInitialState = ({ pageSize, sortBy, hiddenColumns: defaultHiddenColumns }) => {
    const { groups } = useGroups();

    const hiddenColumns = useMemo(() => {
        if (defaultHiddenColumns?.length >= 0) {
            return defaultHiddenColumns
        } else {
            return [
                "jobs",
                "locations",
                "pb",
                "upto",
                "unpaid",
                ...groups.reduce((total, { allocatedBy, level }) => {
                    if (!allocatedBy) {
                        return total.concat(level.replace("_", ""));
                    }
                    return total;
                }, []),
            ]
        }
    }, [groups, defaultHiddenColumns])

    const [initialState] = useState(({
        pageIndex: 0,
        pageSize: pageSize || 50,
        sortBy: sortBy || [{ id: "name", asc: true }],
        hiddenColumns
    }));
    return initialState;
};
