import { useTranslation } from "react-i18next";
import { CardBody } from "reactstrap";
import List from "./List";

const Clocks = ({ clocks }) => {
	const { t } = useTranslation();

	if (!clocks || clocks?.length === 0) {
		return (
			<div className="w-100 d-flex flex-column justify-content-center align-items-center p-7">
				<i
					className="fa-solid fa-inbox text-muted"
					style={{ fontSize: "20px" }}
				/>

				<span className="text-gray font-weight-400 text-xs">
					{t("no-data")}
				</span>
			</div>
		);
	}

	return (
		<div className="d-md-flex flex-md-column px-0 px-lg-2 h-100">
			<CardBody
				style={{
					maxHeight: "inherit",
					overflowY: "auto",
				}}
				className="clocks-scroll"
			>
				<List clocks={clocks} />
			</CardBody>
		</div>
	);
};

export default Clocks;
