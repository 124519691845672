import { useState, useCallback, useEffect } from "react";
import { usePeopleApi } from "../../../utils/api/people";

export const useUserProfile = (id) => {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

	const { getEmployee } = usePeopleApi();

	const fetch = useCallback(
		async (id) => {
			setLoading(true);
			setError(null);
			try {
				const employee = await getEmployee(id);
				if (employee) {
					setUser(employee);
				}
			} catch (err) {
				const error = err;
				setError(error);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, setUser, getEmployee]
	);

	useEffect(() => {
		if (id) {
			fetch(id);
		} else {
			setUser(undefined);
		}
	}, [id, fetch, setUser]);

	return { user, setUser, loading, error };
};
