import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Col } from "reactstrap";
import Field from "../../Field";
import ResourceSelect from "../ResourceSelect";
import Select from "../Select";
import { useFetchRoles } from "../../../features/People/api";

const getUserName = (user) =>
	`${user.firstName} ${user.middleName ? `${user.middleName} ` : ""}${user.lastName
	}`;

export const getUserSearchFilters = (search) => ({
	search
});

function ManagersSelect({ form, isSelf, disabled, sm, md, className }) {
	const { t } = useTranslation();

	const { roles } = useFetchRoles();

	const onResourceWithPrimaryChange = useCallback(
		(primaryFieldName, value) => {
			if (Array.isArray(value)) {
				const primaryField = form.getFieldValue(primaryFieldName);

				if (value.length === 1) {
					form.setFieldsValue({
						[primaryFieldName]: value[0].id,
					});
				} else {
					if (primaryField) {
						const hasPrimaryField =
							value.findIndex(
								(item) => item.id === primaryField,
							) !== -1;
						if (!hasPrimaryField) {
							form.setFieldsValue({
								[primaryFieldName]: null,
							});
						}
					}
				}
			}
		},
		[form],
	);

	const url = useMemo(() => {
		const query = new URLSearchParams();

		if (roles) {
			roles.forEach(
				(role) =>
					role.permissions.canManageUsers &&
					query.append("roleId", role.id),
			);
		}

		query.set("perPage", "10");

		if (!isSelf) {
			query.set("includeSelf", "");
		}

		return `/users?${query.toString()}`;
	}, [roles, isSelf]);

	return (
		<BaseField dependencies={["supervisors"]} noStyle>
			{({ }, { }, { getFieldValue }) => {
				const supervisors = getFieldValue("supervisors");
				const showPrimary = supervisors && supervisors.length >= 2;

				return (
					<>
						<Col
							sm={sm ? sm : 12}
							md={md ? md : 6}
							className={className}
						>
							<Field name="supervisors" label={t("managers")}>
								<ResourceSelect
									labelPropName="firstName"
									renderLabel={getUserName}
									resourcePath={url}
									mode="multiple"
									placeholder={t("managers")}
									onChange={onResourceWithPrimaryChange.bind(
										null,
										"primarySupervisor",
									)}
									disabled={disabled}
									hasSearch
									getSearchFilters={getUserSearchFilters}
								/>
							</Field>
						</Col>

						<Col
							sm={sm ? sm : 12}
							md={md ? md : 6}
							hidden={!showPrimary}
							className={className}
						>
							<Field
								name="primarySupervisor"
								label={t("primary-manager")}
							>
								<Select
									placeholder={t("primary-manager")}
									disabled={disabled}
								>
									{supervisors?.map((supervisor) => (
										<Select.Option
											key={supervisor.id}
											value={supervisor.id}
										>
											{supervisor.firstName}{" "}
											{supervisor.lastName}
										</Select.Option>
									))}
								</Select>
							</Field>
						</Col>
					</>
				);
			}}
		</BaseField>
	);
}

export default ManagersSelect;
