export default function getEsTranslate() {
    return ({
        everyText: 'cada',
        emptyMonths: 'cada mes',
        emptyMonthDays: 'todos los días del mes',
        emptyMonthDaysShort: 'día del mes',
        emptyWeekDays: 'cada día de la semana',
        emptyWeekDaysShort: 'día de la semana',
        emptyHours: 'cada hora',
        emptyMinutes: 'cada minuto',
        emptyMinutesForHourPeriod: 'cada',
        yearOption: 'año',
        monthOption: 'mes',
        weekOption: 'semana',
        dayOption: 'día',
        hourOption: 'hora',
        minuteOption: 'minuto',
        prefixPeriod: 'Cada',
        prefixMonths: 'en',
        prefixMonthDays: 'en',
        prefixWeekDays: 'en',
        prefixWeekDaysForMonthAndYearPeriod: 'and',
        prefixHours: 'en',
        prefixMinutes: ':',
        prefixMinutesForHourPeriod: 'en',
        suffixMinutesForHourPeriod: 'minuto(s)',
        errorInvalidCron: 'Expresión cron no válida',
        clearButtonText: 'Borrar',
        weekDays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split("_"),
        // Order is important, the index will be used as value
        altWeekDays: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb'.split('_'),
        // Order is important, the index will be used as value
        altMonths: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
    })
}