import { useCallback } from "react"
import useApi from "../../../../utils/api"
import { useQrCodeClockIn } from "../../context";

export const useUpdateFlyerStatus = () => {
    const { dispatch, user, token } = useQrCodeClockIn();
    const { authPost } = useApi();

    return useCallback(async (id, onSuccess) => {
        dispatch({ type: "update-flyer-request" });
        try {
            const response = await authPost(`/flyer/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Company: user?.companies[0]?.id,
                    MainCompany: user?.mainCompany,
                },
                data: { status: "read" }
            });
            if (response) {
                dispatch({ type: "update-flyer-success", payload: response });
                onSuccess?.();
            }
        } catch (error) {
            dispatch({ type: "update-flyer-failed", payload: { error } })
        }
    }, [dispatch, authPost, token, user])
}