import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api"
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useActionsApi = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { authPut } = useApi();

	const submitEvent = useCallback(async(id, action, onSuccess) => {
		setLoading(true);
		try {
			const endpoint = isTeamScheduler ? `/schedules/${action}/${id}` : `/my/schedules/${action}/${id}`;
			const response = await authPut(endpoint);
			if (response) {
				onSuccess?.();
				toast.success(t("event-action-successfully", { action }))
			}
		} catch(err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [isTeamScheduler, authPut, setLoading, t]);

	const submitUserEvents = useCallback(async(userId, action, data, onSuccess) => {
		setLoading(true);
		try {
			const endpoint = isTeamScheduler ? `/schedules/${action}/user/${userId}` : `/my/schedules/${action}/user`;
			const response = await authPut(endpoint, { data });
			if (response) {
				onSuccess?.();
				const data = response?.filter(({ status }) => status);
				if (action === 'approve') {
					action = 'published';
				}
				toast.success(t("count-events-are-action", { count: data?.length || 0, action }))
			}
		} catch(err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [isTeamScheduler, authPut, setLoading, t]);

	const bulkSubmit = useCallback(async(action, data, onSuccess) => {
		setLoading(true);
		try {
			const endpoint = isTeamScheduler ? `/schedules/${action}/bulk`: `/my/schedules/${action}/bulk`
			const response = await authPut(endpoint, { data });
			if (response) {
				onSuccess?.(response);
				const data = response?.filter(({ status }) => status);
				if (action === 'approve') {
					action = 'published';
				}
				toast.success(t("count-events-are-action", { count: data?.length || 0, action }))
			}
		} catch(err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [isTeamScheduler, authPut, setLoading, t]);

	return { submitEvent, submitUserEvents, bulkSubmit, loading };
}