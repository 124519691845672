import { forwardRef } from "react";
import classNames from "classnames";
import { Input as BaseInput } from "reactstrap";

function Input({ hasError, className, ...props }, ref) {
	return (
		<BaseInput
			innerRef={ref}
			className={classNames(className, hasError && "is-invalid")}
			{...props}
			autoComplete="new-password"
		/>
	);
}

export default forwardRef(Input);
