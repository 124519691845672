import { useCallback, useEffect, useMemo, useState } from "react";
import { useEndDateValidations } from "./endDate";
import { useJobValidations } from "./job";
import { useLevelsValidations } from "./levels";
import { useLocationValidations } from "./location";
import { usePayCodeValidations } from "./payCode";
import { useRateValidations } from "./rate";
import { useStartDateValidations } from "./startDate";
import { useTotalValidations } from "./total";

export const useValidationConfig = () => {
  const startDate = useStartDateValidations();
  const endDate = useEndDateValidations();
  const rate = useRateValidations();
  const job = useJobValidations();
  const location = useLocationValidations();
  const payCode = usePayCodeValidations();
  const levels = useLevelsValidations();
  const total = useTotalValidations();
  return useMemo(() => {
    return {
      startDate,
      endDate,
      rate,
      job,
      location,
      payCode,
      ...levels,
      total,
    };
  }, [
    startDate,
    endDate,
    rate,
    job,
    location,
    payCode,
    levels,
    total,
  ]);
}

export const useValidations = (config, data) => {
  const [errors, setErrors] = useState({});

  const validate = useCallback((row, field, data) => {
    const value = row[field];
    if (!config[field]) {
      return null;
    }
    if (!Array.isArray(config[field])) {
      throw new Error("configuration error");
    }
    for (const validator of config[field]) {
      const error = validator(value, row, data);
      if (error) {
        return error;
      }
    }
  }, [config]);

  useEffect(() => {
    const errors = {};
    for (const row of data) {
      if (!row.isTouched) {
        errors[row.id] = null;
        continue;
      }
      const rowErrors = Object
        .keys(config)
        .reduce((total, field) => {
          const error = validate(row, field, data)
          total[field] = error;
          return total;
        }, {});
      errors[row.id] = rowErrors;
    }
    setErrors(errors);
  }, [data, config, validate]);

  return { errors, setErrors };
}
