import moment from "moment-timezone";
import FormElement from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import {
	useCompany,
	useIsMasterCompany,
} from "../../../../../utils/hooks/company";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import Content from "./Content";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const company = useCompany();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.holidays");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(formValues) => {
			const { country, state, ...values } = formValues;
			const data = {
				...values,
				calculationGroups: values?.calculationGroups?.map((calculationGroup) => calculationGroup.id),
				locations: values?.locations?.map((location) => location.id),
				payGroups: values?.payGroups?.map((payGroup) => payGroup.id),
				companies: values?.companies?.map((company) => company.id),
				days: values.days.map((day) => ({
					...day,
					date: day?.date && moment(day.date).format("YYYY-MM-DD"),
					dates: day?.dates && day.dates.map((date) => moment(date).format("YYYY-MM-DD"))
				}))
			}
			submit(data);
		},
		[submit],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			days: values?.days ? values.days.map((day) => ({
				...day,
				date: day?.date && moment(day.date).toDate(),
				dates: day?.dates ? day.dates.map((date) => moment(date).toDate()) : []
			})) : [{
				name: undefined,
				repeatEveryYear: true,
				isSameDate: true,
			}],
		});
	}, [form, values, company]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"code",
				"description",
				"status",
				"days"
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Content
					mode={mode}
					form={form}
					disabled={disabled || !isMasterCompany}
				/>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
