import {
	createContext,
	useReducer,
	useEffect,
	useCallback,
	useContext,
} from "react";
import { api } from "../../utils/api";
import { useValidateDomain } from "../../utils/api/company";
import {
	getBackendUrl,
	isMainSubDomain,
	redirectToMainDomain,
} from "../../utils/helpers/url";
import { getSubDomainFromDomain } from "../../utils/helpers/url";
import config from "../../config/authentication.config.json";
import { isCancelError } from "../../utils/helpers/errors";
import { adaptMomentToCompany } from "../../utils/helpers/adaptMomentToCompany";

export const removeCompanyStorageToken = () => {
	localStorage.removeItem(config.LOGGED_COMPANY_ID);
};

const AuthenticationContext = createContext();

export const authenticationInitialState = (() => {
	const isMainDomain = isMainSubDomain();
	return {
		hasValidatedDomain: isMainDomain,
		domainValid: isMainDomain,
		validatingDomain: false,

		hasAuthenticated: false,
		authenticated: false,
		authenticating: false,

		token: localStorage.getItem(config.TOKEN_STORAGE_KEY),
		user: null,
		company: null,
		companyName: null,
		accessMap: null,

		domain: window.location.hostname,
		isMainDomain,
	};
})();

export const authenticationReducer = (state, action) => {
	switch (action.type) {
		case "validate-domain": {
			return {
				...state,
				domainValid: false,
				validatingDomain: true,
			};
		}
		case "validate-domain-succeeded": {
			return {
				...state,
				domainValid: true,
				validatingDomain: false,
				hasValidatedDomain: true,
				companyName: action.payload,
			};
		}
		case "validate-domain-failed": {
			return {
				...state,
				domainValid: false,
				validatingDomain: false,
				hasValidatedDomain: true,
			};
		}
		case "validate-domain-cancel": {
			return {
				...state,
				domainValid: authenticationInitialState.domainValid,
				validatingDomain: authenticationInitialState.validatingDomain,
				hasValidatedDomain:
					authenticationInitialState.hasValidatedDomain,
			};
		}
		case "authenticate": {
			return {
				...state,
				authenticated: false,
				authenticating: true,
			};
		}
		case "authenticate-succeeded": {
			return {
				...state,
				authenticated: true,
				authenticating: false,
				hasAuthenticated: true,
				user: action.payload.user,
				accessMap: action.payload.user.permissions,
				company: action.payload.company,
			};
		}
		case "authenticate-failed": {
			return {
				...state,
				authenticated: false,
				authenticating: false,
				hasAuthenticated: true,
				token: null,
				accessMap: null,
				company: null,
			};
		}
		case "authenticate-cancel": {
			return {
				...state,
				authenticated: authenticationInitialState.authenticated,
				authenticating: authenticationInitialState.authenticating,
				hasAuthenticated: authenticationInitialState.hasAuthenticated,
				user: authenticationInitialState.user,
				accessMap: authenticationInitialState.accessMap,
				company: authenticationInitialState.company,
			};
		}
		case "login": {
			return {
				...state,
				user: action.payload.user,
				accessMap: action.payload.user.permissions,
				token: action.payload.token,
				company: action.payload.company,
				authenticated: true,
				hasAuthenticated: true,
			};
		}
		case "set-company": {
			return {
				...state,
				company: action.payload.company,
			};
		}
		case "set-user": {
			return {
				...state,
				user: action.payload.user,
			};
		}
		case "set-ultipro": {
			return {
				...state,
				company: {
					...state.company,
					ultipro: action.payload,
				},
			};
		}
		case "set-uta": {
			return {
				...state,
				company: {
					...state.company,
					uta: action.payload,
				},
			};
		}
		case "log-out": {
			return {
				...authenticationInitialState,
				user: null,
				token: null,
				company: null,
			};
		}
		default: {
			throw new Error(
				`Authentication reducer case "${action.type}" not handled`,
			);
		}
	}
};

export const AuthenticationProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		authenticationReducer,
		authenticationInitialState,
	);

	const validateDomain = useValidateDomain();

	const validate = useCallback(
		async (controller) => {
			dispatch({ type: "validate-domain" });
			try {
				const subDommain = getSubDomainFromDomain(state.domain);

				const { status, name } = await validateDomain(subDommain, {
					signal: controller.signal,
				});
				if (!status) {
					dispatch({ type: "validate-domain-failed" });
					redirectToMainDomain();
				}
				dispatch({ type: "validate-domain-succeeded", payload: name });
			} catch (err) {
				if (isCancelError(err)) {
					dispatch({ type: "validate-domain-cancel" });
					return;
				}
				dispatch({ type: "validate-domain-failed" });
			}
		},
		[state.domain, dispatch, validateDomain],
	);

	const authenticate = useCallback(
		async (controller) => {
			dispatch({ type: "authenticate" });
			adaptMomentToCompany(state?.company, state?.user);
			try {
				const baseURL = getBackendUrl(
					getSubDomainFromDomain(window.location.hostname),
				);
				const response = await api.get("/check-token", {
					baseURL,
					signal: controller.signal,
					Authorization: `Bearer ${state.token}`,
					Headers: {
						Authorization: `Bearer ${state.token}`,
					},
					headers: {
						Authorization: `Bearer ${state.token}`,
					},
				});

				if (response) {
					const { user } = response.data;
					const companyId = localStorage.getItem("COMPANY");

					let company =
						user.companies.length === 1
							? user.companies[0]
							: undefined;
					if (companyId) {
						company = user.companies.find(
							({ id }) => id === companyId,
						);
						if (company === undefined) {
							removeCompanyStorageToken();
						}
					}

					dispatch({
						type: "authenticate-succeeded",
						payload: { user, company },
					});
					adaptMomentToCompany(company, user);
				}
			} catch (err) {
				if (isCancelError(err)) {
					dispatch({ type: "authenticate-cancel" });
					return;
				}
				dispatch({ type: "authenticate-failed" });
				localStorage.removeItem("TOKEN");
				localStorage.removeItem("COMPANY");
			}
		},
		[dispatch, state.token, state?.company, state?.user],
	);

	useEffect(() => {
		const controller = new AbortController();
		if (!state.hasValidatedDomain) {
			validate(controller);
		}
		return () => controller.abort();
	}, [state.hasValidatedDomain, validate]);

	useEffect(() => {
		const controller = new AbortController();
		if (state.domainValid && !state.hasAuthenticated && state.token) {
			authenticate(controller);
		}
		return () => controller.abort();
	}, [state.token, state.domainValid, state.hasAuthenticated, authenticate]);

	return (
		<AuthenticationContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthenticationContext.Provider>
	);
};

export const useAuthentication = () => {
	const context = useContext(AuthenticationContext);
	if (context === undefined) {
		throw new Error("useAuthentication should be used within a provider");
	}
	return context;
};
