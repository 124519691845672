import { Tooltip } from "reactstrap";
import { getUploadUrl } from "../../../../../../../../utils/helpers/upload";
import "./style.scss";

function ImageTooltip({ data, close, isOpen, placement = "left" }) {
    return <Tooltip
        target={`clock${data?.id}`}
        isOpen={isOpen}
        toggle={close}
        trigger="hover"
        autohide={false}
        placement={placement}
        className="geofence-tooltip"
    >
        {data?.image ? (
            <img
                src={getUploadUrl(data?.image)}
                className="rounded shadow"
                width={310}
                height={310}
                alt=""
            />
        ) : (
            <div
                className="avatar d-flex align-items-center flex-column bg-muted m-0 p-0"
                style={{ cursor: "auto", width: "310px", height: "310px" }}
            >
                <i className="fa-solid fa-image text-white text-lg" />
                <h2 className="text-white mb-0 text-lg">
                    {data?.user?.firstName + "  " + data?.user?.lastName}
                </h2>
            </div>
        )}
    </Tooltip>
}

export default ImageTooltip