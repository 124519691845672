import React from "react";
import Form from "./Form/index";
import { Card } from "reactstrap";
import NTable from "../../../../components/NTable";
import { useColumns } from "./columns";
import Topbar from "../../components/Topbar";
import { useTranslation } from "react-i18next";
import { useNewTable } from "../../../../components/NTable/hook";
import useSettingsCRUDHook from "../../hook";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import Actions from "../../../../components/NTable/Actions";
import BulkActions from "../../components/BulkActions";
import Drawer from "../../../../components/Drawer";
import UsersModal from "./UsersModal";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useVisible } from "../../../../utils/hooks/useVisible";
import "./style.scss";

function Flyers() {
    const { t } = useTranslation();

    const {
        state: { data, mode, totalItems, loading, submitting, error },
        selected,
        goToCreate,
        goToView,
        goToEdit,
        createSubmit,
        updateSubmit,
        remove,
        batchRemove,
        goToClone,
        fetch,
    } = useSettingsCRUDHook("/flyer");

    const {
        visible: isUsersModalOpen,
        open: openUsersModal,
        selected: flyerId,
        close: closeUsersModal
    } = useVisible();

    const { hasAccess: canCreate } = useAccess("settings.flyer.canCreate");

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-flyers", {
                    count: data?.length
                })
                : t("do-you-want-to-delete-flyer"),
        onConfirm: (data) => {
            Array.isArray(data) ? batchRemove(data) : remove(data);
        },
        permission: "settings.flyer.canDelete",
    });

    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const columns = useColumns({
        edit: useActionWithAccess(
            goToEdit,
            "settings.flyer.canView"
        ),
        remove: openDeleteModal,
        clone: useActionWithAccess(
            goToClone,
            "settings.flyer.canView"
        ),
        viewUsers: openUsersModal
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "flyers",
    });

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-flyer"),
        create: t("add-flyer"),
        edit: t("edit-flyer"),
        clone: t("clone-flyer"),
    });

    useFetchResource({ fetch, state: table.getState() });

    return (
        <>
            <Card className="h-100">
                <Topbar title={t("flyers")} add={canCreate && goToCreate} />
                <Card>
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
                {isFormOpen ? (
                    <Form
                        {...formProps}
                        error={error}
                        loading={submitting}
                        close={goToView}
                    />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {isUsersModalOpen && (
                <UsersModal 
                    id={flyerId}
                    isOpen={isUsersModalOpen}
                    close={closeUsersModal}
                />
            )}

            {unarchiveModal}

            {deleteModal}
        </>
    );
}

export default Flyers;
