import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { useModal } from "../../../utils/hooks/useModal";
import { noop } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const icon = (
    <FontAwesomeIcon
        icon={faCircleQuestion}
        className="text-info w-100 mb-5"
        fontSize={70}
    />
);

export const useUnarchiveModal = ({
    message,
    onConfirm = noop(),
    onCancel = noop,
}) => {
    const { t } = useTranslation();

    const head = useMemo(() => (
        <h2 className="mb-0 text-center font-weight-bolder">
            {t("are-you-sure")}
        </h2>
    ), [t])

    return useModal({
        head,
        icon,
        message,
        color: "info",
        onConfirm,
        onCancel,
    });
}
