import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import classNames from "classnames";
import { Nav, NavItem, Tooltip } from "reactstrap";
import { ReportColumnsContext } from "../context";

const Item = ({
	id,
	name,
	label,
	accessor,
	icon,
	type,
	className,
	isForExtraColumns,
}) => {
	const [{ isDragging }, dragRef] = useDrag({
		type: "item",
		item: { name, label, key: accessor, icon, type, isForExtraColumns },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	return (
		<div
			key={accessor}
			className={classNames(
				"d-flex cursor-pointer py-1 mb-1 text-dark",
				className,
			)}
			ref={dragRef}
			id={id}
		>
			{label}
			{isDragging && <i className="fa-solid fa-right bg-red" />}
		</div>
	);
};

function Menu({ columns }) {
	const { t } = useTranslation();
	const { columns: data, loading } = useContext(ReportColumnsContext);

	const [selectedKey, setSelectedKey] = useState();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [selectedChild, setSelectedChild] = useState();

	const map = useMemo(() => {
		return data.reduce(
			(total, { canBeJoinned, key }) => {
				canBeJoinned?.forEach((mergeKey) => {
					total[mergeKey].push(key);
				});
				return total;
			},
			data.reduce((total, { key }) => ({ ...total, [key]: [key] }), {}),
		);
	}, [data]);

	const getCombinationAvailability = useCallback(
		(clickedGroupKey) => {
			const activeGroupKeys = [
				...new Set(columns.map(({ name }) => name)),
			];
			return activeGroupKeys.every((activeGroupKey) => {
				const activeGroup = map[activeGroupKey];
				return activeGroup.includes(clickedGroupKey);
			});
		},
		[map, columns],
	);

	const onClick = useCallback(
		(key) => {
			if (key === selectedKey) {
				return setSelectedKey();
			} else {
				return setSelectedKey(key);
			}
		},
		[selectedKey, setSelectedKey],
	);

	const onChildClick = useCallback(
		(key) => {
			if (key === selectedChild) {
				return setSelectedChild();
			} else {
				return setSelectedChild(key);
			}
		},
		[selectedChild, setSelectedChild],
	);

	return (
		<Nav className="nav-sm flex-column">
			{loading ? (
				<div className="w-100 text-center m-2">
					<div className="spinner spinner-border spinner-border-sm" />
				</div>
			) : (
				data.map((item) => {
					const disabled =
						selectedKey === item.key
							? false
							: !getCombinationAvailability(item.key);
					return (
						<>
							<NavItem
								key={item.key}
								className={classNames(
									"d-flex justify-content-between pr-5 pl-3 py-2 align-items-center mb-1",
									!disabled && "cursor-pointer",
									selectedKey === item.key && "text-primary",
								)}
								onClick={
									!disabled
										? () => onClick(item.key)
										: undefined
								}
								style={{
									backgroundColor: disabled
										? "#e9ecef"
										: "#fff",
								}}
								id={disabled ? "nav-item" : ""}
							>
								<div className="d-flex align-items-center">
									<i
										className={`fa-solid ${item.icon} mr-3 text-sm`}
									/>

									<span className="mb-0">{item.label}</span>
								</div>

								{selectedKey !== item.key ? (
									<i className="fa-solid fa-chevron-down text-sm" />
								) : (
									<i className="fa-sharp fa-solid fa-chevron-up" />
								)}
							</NavItem>

							{disabled && (
								<Tooltip
									target="nav-item"
									isOpen={isTooltipOpen}
									toggle={() =>
										setIsTooltipOpen(!isTooltipOpen)
									}
									placement="top"
								>
									<span className="text-sm font-weight-bolder px-2">
										{t("cant-get-columns-from-collection")}
									</span>
								</Tooltip>
							)}

							{selectedKey === item.key && (
								<>
									<NavItem className="nav-sm flex-column ml-5">
										{item.children.map((i) => {
											if (i?.children) {
												return (
													<>
														<NavItem
															key={i.key}
															className={classNames(
																"d-flex justify-content-between pr-5 py-2 align-items-center mb-1",
																selectedChild ===
																i.key &&
																"text-primary",
															)}
															onClick={() =>
																onChildClick(
																	i.key,
																)
															}
														>
															<span className="mb-0">
																{i.label}
															</span>

															{selectedChild !==
																i.key ? (
																<i className="fa-solid fa-chevron-down text-sm" />
															) : (
																<i className="fa-sharp fa-solid fa-chevron-up" />
															)}
														</NavItem>

														{selectedChild ===
															i.key && (
																<NavItem className="ml-2">
																	{i.children.map(
																		(child) => (
																			<Item
																				accessor={
																					child.key
																				}
																				name={
																					item.key
																				}
																				label={
																					child.label
																				}
																				columns={
																					columns
																				}
																				icon={
																					item.icon
																				}
																				type={
																					child.type
																				}
																				isForExtraColumns={
																					child.isForExtraColumns
																				}
																				className="bg-secondary pl-4 border-secondary rounded py-1"
																			/>
																		),
																	)}
																</NavItem>
															)}
													</>
												);
											} else {
												return (
													<Item
														accessor={i.key}
														name={item.key}
														label={i.label}
														columns={columns}
														icon={item.icon}
														type={i.type}
														isForExtraColumns={
															i.isForExtraColumns
														}
													/>
												);
											}
										})}
									</NavItem>
								</>
							)}
						</>
					);
				})
			)}
		</Nav>
	);
}

export default Menu;
