import moment from "moment-timezone";
import {useCallback} from "react";
import {useVisible} from "../../../../utils/hooks/useVisible"
import {useDateFilters} from "../../hooks/useDateFilters";

export const useSummary = () => {
    const {from, to} = useDateFilters();
    const { visible, selected, open, close } = useVisible();

    const openDaySummary = useCallback((date) => {
        open({
            from: moment(date).startOf("day").toISOString(true),
            to: moment(date).endOf("day").toISOString(true),
        });
    }, [open]);

    const openAllSummary = useCallback(() => {
        open({ from, to });
    }, [open, from, to]);

    return { visible, selected, openDaySummary, openAllSummary, close };
}
