import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";

export const useUserGroupFilter = () => {
    const { t } = useTranslation();

    return useMemo(
        () => ({
            id: "userGroups",
            Header: t("group"),
            filter: "resource-select",
            canFilter: true,
            Filter: (
                <ResourceSelect
                    labelPropName="description"
                    renderLabel={renderSettingsResourceLabel}
                    resourcePath="/user-group"
                    hasSearch
                    mode="multiple"
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            ),
        }),
        [t],
    );
};
