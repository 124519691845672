import {
	useContext,
	useEffect,
	useLayoutEffect,
	useState,
	useCallback,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import {
	CardBody,
	CardHeader,
	CardTitle,
	CardText,
	Row,
	Col,
} from "reactstrap";
import Form from "./Form";
import NTable from "../../../components/NTable";
import Drawer from "../../../components/Drawer";
import Button from "../../../components/Button";
import { useDeleteModal } from "../../Settings/helpers/useDeleteModal";
import { useDrawerProps } from "../../Settings/helpers/useDrawerProps";
import { useColumns } from "./columns";
import { ProfileContext } from "../context";
import { useProfileApi } from "../../../utils/api/profile";
import { usePeopleApi } from "../../../utils/api/people";
import { useUserDocumentUpload } from "../../../utils/api/upload";
import { useNewTable } from "../../../components/NTable/hook";

const useCustomDrawer = (getChildren) => {
	const [wrapperElement, setWrapperElement] = useState(null);

	useLayoutEffect(() => {
		const element = document.getElementById("documents-drawer");
		setWrapperElement(element);
	}, [setWrapperElement]);

	if (wrapperElement === null) return null;

	return createPortal(getChildren(), wrapperElement);
};

function Documents({ isOpenFromTimesheet = false }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { user, submit } = useContext(ProfileContext);
	const [mode, setMode] = useState("view");
	const [selected, setSelected] = useState();

	const data = useMemo(() => user?.documents || [], [user?.documents]);

	const { deleteDocuments: profileDelete } = useProfileApi();
	const { deleteDocuments: peopleDelete } = usePeopleApi();
	const { remove: removeDocument } = useUserDocumentUpload();

	const goToCreate = useCallback(() => {
		setSelected(undefined);
		setMode("create");
	}, [setSelected, setMode]);

	const goToEdit = useCallback(
		(values) => {
			setSelected(values);
			setMode("edit");
		},
		[setSelected, setMode],
	);

	const goToView = useCallback(() => {
		setSelected(undefined);
		setMode("view");
	}, [setSelected, setMode]);

	const remove = useCallback(
		async (document) => {
			setLoading(true);

			if (!user?.id) {
				return;
			}

			try {
				const response = await removeDocument(
					user.id,
					document.document,
				);

				if (response) {
					submit(profileDelete, peopleDelete, document);
					toast.success(t("document-is-deleted"));
				}
			} catch (err) {
			} finally {
				setLoading(false);
			}
		},
		[
			submit,
			peopleDelete,
			profileDelete,
			removeDocument,
			user?.id,
			t,
			setLoading,
		],
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: () => t("do-you-want-to-delete-document"),
		onConfirm: (data) => remove(data),
	});

	const columns = useColumns({
		edit: goToEdit,
		remove: openDeleteModal,
		isOpenFromTimesheet,
	});

	const table = useNewTable({
		data,
		totalItems: data?.length || 0,
		columns,
        loading,
        enableRowSelection: false,
	});

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		create: t("add-document"),
		edit: t("edit-document"),
	});

	const drawer = useCustomDrawer(() => {
		return (
			<Drawer
				title={drawerTitle}
				open={drawerOpen}
				close={goToView}
				style={{
					maxWidth: "initial",
				}}
			>
				<Form values={selected} close={goToView} mode={mode} />
			</Drawer>
		);
	});

	return (
		<>
			{!isOpenFromTimesheet && (
				<CardHeader>
					<Row className="d-flex justify-content-between">
						<Col sm={12} md={6}>
							<CardTitle className="h3 mb-1">
								{t("employee-documents")}
							</CardTitle>

							<CardText className="text-sm mb-2">
								{t(
									"documents-shared-between-user-and-admin", {
									username: user?.firstName + " " + user?.lastName
								}
								)}
							</CardText>
						</Col>

						<Col sm={12} md={6} className="text-md-right">
							<Button
								onClick={goToCreate}
								className="border-dark pr-3 btn-round btn-icon shadow-none btn btn-none btn-sm"
							>
								{t("upload-document")}
							</Button>
						</Col>
					</Row>
				</CardHeader>
			)}

			<CardBody
				style={{ height: "calc(100% - 20px)", overflowY: "auto" }}
				className="profile-scroll m-0 p-0"
			>
                <NTable table={table} />
			</CardBody>

			{drawer}

			{deleteModal}
		</>
	);
}

export default Documents;
