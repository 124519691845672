import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import Modal from "../../../../../components/Modal"
import Button from "../../../../../components/Button";
import SimpleTable from "../../../../../components/SimpleTable";
import Pagination from "../../../../../components/NTable/Pagination";
import Text from "../../../../../components/Inputs/Text";
import { useGetUsers } from "./api";

const columnHelper = createColumnHelper();
const PER_PAGE = 10;

function UsersModal({ id, isOpen, close }){
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const { fetch, data, totalItems, loading } = useGetUsers();
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState();

	const columns = useMemo(() => [
		columnHelper.accessor("user.fullName", {
			header: t("name")
		}),
		columnHelper.accessor("user.employeeNumber", {
			header: t("employee-number")
		})
	], [t]);

	const onSearchChange = useCallback((e) => {
		setSearch(e.target.value)
	}, [setSearch]);

	const onSearch = useCallback(() => {
		setFilter(search);
	}, [setFilter, search]);

	const clear = useCallback(() => {
		setFilter(undefined);
		setSearch("")
	}, [setFilter, setSearch]);

	useEffect(() => {
		const controller = new AbortController();
		const params = {
			page: page + 1,
			perPage: PER_PAGE,
			filter
		};
		fetch(id, params, controller)
		return () => controller.abort();
	}, [fetch, id, page, filter]);

	return (
		<Modal isOpen={isOpen} centered>
			<div>
				<div className="my-4 d-flex align-items-center">
					<Text value={search} onChange={onSearchChange} />

					<Button 
						color="primary" 
						className="mx-2" 
						disabled={!search}
						onClick={onSearch}
					>
						{t("search")}
					</Button>

					<Button 
						color="primary" 
						disabled={!search}
						onClick={clear}
					>
						x
					</Button>
				</div>

               <SimpleTable 
					data={data || []}
					columns={columns}
					loading={loading}
					footer={false}
			   />

			   <Pagination
			   		page={page}
					perPage={PER_PAGE}
					totalItems={totalItems}
					showOnSizeChange={false}
					gotoPage={setPage}
					pageCount={Math.ceil(totalItems / PER_PAGE)}
			   />
			</div>

			<div className="w-100 text-right my-4">
				<Button
					type="button"
					className="bg-gray text-white border-0"
					onClick={close}
				>
					{t("close")}
				</Button>
			</div>
		</Modal>
	)
}

export default UsersModal;
