import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Drawer from "./Drawer";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../utils/hooks/access";
import "./style.scss";

export const useEditEvent = ({ isTeamScheduler, mutate }) => {
	const { t } = useTranslation();
	const {
		hasAccess: canEdit
	} = useAccess(!isTeamScheduler && "schedule.canEditMySchedule");

	const {
		hasAccess: canCreateRequest
	} = useAccess(!isTeamScheduler && "request.canCreate");

	const {
		hasAccess: canCreate
	} = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");

	const {
		selected,
		visible,
		open,
		close,
	} = useVisible();

	const schedulerInstance = useMemo(() => {
		return selected?.source;
	}, [selected?.source]);

	const eventRecord = useMemo(() => {
		return selected?.eventRecord;
	}, [selected?.eventRecord]);

	const events = useMemo(() => {
		return selected?.resourceRecord?.events
			?.filter((event) => !event?.isCreating 
				&& moment(eventRecord?.date).isSame(moment(event?.date), "date"));
	}, [selected?.resourceRecord?.events, eventRecord]);

	const isCreating = useMemo(() => {
		if (selected?.eventRecord?.isCreating && events?.length > 0) {
			return false;
		} else {
			return selected?.eventRecord?.isCreating
		}
	}, [selected?.eventRecord?.isCreating, events?.length]);

	const title = useMemo(() => {
		if (selected?.eventRecord?.isCreating && canCreate && isCreating) {
			return t("create-schedule");
		} else if (canCreateRequest && !canEdit) {
			return t("create-schedule-request")
		} else {
			return t("edit-schedule")
		}
	}, [selected?.eventRecord?.isCreating, isCreating, canCreateRequest, canEdit, canCreate, t]);

	const saveButtonText = useMemo(() => {
		if (canCreateRequest && !canEdit) {
			return t("send-for-approval")
		} else {
			return t("save")
		}
	}, [canCreateRequest, canEdit, t]);

	const drawer = useMemo(() => {
		return selected ? (
			<Drawer
				eventRecord={eventRecord}
				resourceRecord={selected?.resourceRecord}
				visible={visible}
				close={close}
				schedulerInstance={schedulerInstance}
				onEventSave={selected.onEventSave}
				title={title}
				isTeamScheduler={isTeamScheduler}
				mutate={mutate}
				isCreating={isCreating}
				events={events}
				loading={!selected?.resourceRecord}
				saveButtonText={saveButtonText}
			/>
		) : null
	}, [
		selected, 
		visible, 
		close, 
		mutate, 
		isCreating, 
		events, 
		schedulerInstance,
		eventRecord,
		isTeamScheduler,
		title,
		saveButtonText
	]);

	return [drawer, open, close];
}
