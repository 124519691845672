import { useCallback } from "react";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useUpdateTimeSheet } from "../../api/useUpdateTimeSheet";
import { useActionWithAccess } from "../../../../utils/hooks/access";

export const useEdit = ({ refetch }) => {
	const { visible, open, close, selected } = useVisible();

	const {
		submit: updatedTimeSheetSubmit,
		loading: updatedTimeSheetLoading,
		error: updatedTimeSheetError,
	} = useUpdateTimeSheet();

	const onSubmitSuccess = useCallback(() => {
		refetch();
		close();
	}, [refetch, close]);

	const submitEdit = useActionWithAccess(
		useCallback(
			(id, data) => {
				updatedTimeSheetSubmit(
					id,
					{ note: data?.note },
					onSubmitSuccess,
				);
			},
			[updatedTimeSheetSubmit, onSubmitSuccess],
		),
		"timeSheet.canEditMyTimesheet",
	);

	return {
		visible,
		open,
		close,
		selected,
		loading: updatedTimeSheetLoading,
		error: updatedTimeSheetError,
		submit: submitEdit,
	};
};
