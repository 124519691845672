import { useMemo } from "react"
import { useNameColumn } from "../../../../../../Team/Table/useColumns/useNameColumn";
import { useEmployeeNumberColumn } from "../../../../../../Team/Table/useColumns/useEmployeeNumberColumn";
import { useEarningGroupsColumns } from "../../../../../Table/useColumns/useEarningGroupsColumns";
import { usePaymentColumn } from "../../../../../Table/useColumns/usePaymentColumn";
import { usePayGroupColumn } from "../../../../../Table/useColumns/usePayGroupColumn";

export const useColumns = () => {
    const nameColumn = useNameColumn({})
    const employeeNumberColumn = useEmployeeNumberColumn()
    const payGroupColumn = usePayGroupColumn()
    const earningGroupsColumns = useEarningGroupsColumns();
    const paymentColumn = usePaymentColumn()

    return useMemo(() => [
        nameColumn,
        employeeNumberColumn,
        payGroupColumn,
        ...earningGroupsColumns,
        paymentColumn,
    ], [
        nameColumn,
        employeeNumberColumn,
        payGroupColumn,
        earningGroupsColumns,
        paymentColumn,
    ]);
}

