import { useEffect } from "react";
import { getStartDateFromEndDate } from "./helpers";
import moment from "moment-timezone";
import Form from "rc-field-form";

export const useCalculateDates = (form) => {
    const endDate = Form.useWatch("periodEndDate");
    const frequency = Form.useWatch("frequency");

    useEffect(() => {
        if (!frequency) {
            form.setFieldsValue({ periodStartDate: undefined });
            return;
        }

        switch (frequency) {
            case "weekly": {
                form.setFieldValue(
                    "periodStartDate",
                    getStartDateFromEndDate(endDate, frequency),
                );
                break;
            }
            case "biweekly": {
                form.setFieldValue(
                    "periodStartDate",
                    getStartDateFromEndDate(endDate, frequency),
                );
                break;
            }
            case "semi-monthly": {
                const newEndDate =
                    moment().date() < 15
                        ? moment().date(15)
                        : moment().endOf("month");

                form.setFieldValue(
                    "periodStartDate",
                    getStartDateFromEndDate(newEndDate, frequency),
                );
                form.setFieldValue("periodEndDate", newEndDate.toDate(), true);
                break;
            }
            case "monthly": {
                form.setFieldsValue({
                    periodStartDate: moment().startOf("month").toDate(),
                    periodEndDate: moment().endOf("month").toDate(),
                });
                break;
            }
            default:
                break;
        }
    }, [form, frequency, endDate]);
};
