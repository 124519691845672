import { useState, useCallback } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal";
import RangeDateInput from "../../../../../components/Inputs/DateInput/Range";
import Button from "../../../../../components/Button";
import Table from "./Table";
import { useCopyTimesheet } from "../../../api/useCopyTimesheet";
import { getErrorMessage } from "../../../../../utils/helpers/errors";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { formatTimeSheetParams } from "../../../api/helpers";
import "./style.scss";

function CopyTimesheetModal({
	isOpen,
	close,
	timesheet,
	user,
	refetch,
	dateTimesheetMap,
	filters,
}) {
	const { t } = useTranslation();
	const { from, to } = useDateFilters();
	const [date, setDate] = useState({
		from: moment().format("YYYY-MM-DD"),
		to: moment().format("YYYY-MM-DD"),
	});
	const [response, setResponse] = useState();

	const { create, loading } = useCopyTimesheet();

	const onRangeChange = useCallback(
		([from, to]) => {
			setDate({ from: moment(from), to: moment(to) });
		},
		[setDate],
	);

	const onCopy = useCallback(() => {
		create(
			{
				startDate: moment.parseZone(date?.from).format("YYYY-MM-DD"),
				endDate: moment.parseZone(date?.to).format("YYYY-MM-DD"),
				filters: formatTimeSheetParams({
					...filters,
					from,
					to,
				}),

				oldDate: timesheet[0]?.date,
				user: user?.id,
			},
			(response) => {
				setResponse(response);
			},
			(err) => {
				getErrorMessage(err, t);
			},
		);
	}, [
		date,
		from,
		to,
		filters,
		timesheet?.date,
		user,
		create,
		setResponse,
		t,
	]);

	const onClose = useCallback(() => {
		if (response?.user) {
			refetch(response?.user);
		} else {
			refetch();
		}
		close();
	}, [close, refetch, response?.user]);

	return (
		<Modal
			title={<h2 className="mb-0 pb-0">{t("copy-timesheet")}</h2>}
			isOpen={isOpen}
			close={onClose}
			toggle={onClose}
			size="lg"
			centered
		>
			<div className="mb-5">
				<div className="mb-2 font-weight-600">
					{t("select-dates-to-copy-timesheet")}:
				</div>

				<div className="w-50">
					<RangeDateInput
						startDate={moment(date?.from).toDate()}
						endDate={moment(date?.to).toDate()}
						onChange={onRangeChange}
					/>
				</div>
			</div>

			<div
				className="mb-3"
				style={{
					maxHeight: "300px",
					overflowY: "auto",
				}}
			>
				<Table
					date={date}
					timesheet={timesheet}
					dateTimesheetMap={dateTimesheetMap}
					loading={loading}
					response={response}
				/>
			</div>

			<div className="d-flex justify-content-between mb-3">
				<Button
					color="primary"
					onClick={onCopy}
					loading={loading}
					disabled={loading}
				>
					{t("paste")}
				</Button>

				<Button
					color="dark"
					onClick={onClose}
					loading={loading}
					disabled={loading}
				>
					{t("close")}
				</Button>
			</div>
		</Modal>
	);
}

export default CopyTimesheetModal;
