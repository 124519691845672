import {Media} from "reactstrap";
import {initials} from "../../utils/helpers/string";
import {getUploadUrl} from "../../utils/helpers/upload";

const Avatar = ({ user, size = 0, txtStyle = {} }) => {

    return (
        <Media className="align-items-center">
            {user?.image ? (
                <img
                    src={getUploadUrl(user?.image)}
                    alt="user avatar"
                    className="avatar rounded-circle mr-"
                    style={{
                        cursor: "auto",
                        width: size || '48px',
                        height: size || '48px'
                    }}
                />
            ) : (
                <div
                    className="avatar rounded-circle d-flex align-items-center bg-muted"
                        style={{
                            cursor: "auto",
                            width: size || '48px',
                            height: size || '48px'
                        }}
                >
                    <h3 className="text-white mb-0" style={txtStyle}>
                        {initials(user)}
                    </h3>
                </div>
            )}
        </Media>
    );
}

export default Avatar;
