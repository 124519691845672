import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPrimaryActiveResource } from "../../../../../utils/helpers/user";

export const useJobsColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("job"),
        accessor: "jobs",
        disableSortBy: true,
        Cell: ({ value }) => {
            const job = getPrimaryActiveResource(value);
            if (!job) {
                return null;
            }
            return job.displayName;
        },
    }), [t]);
};
