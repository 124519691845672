import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import Password from "../../../../../components/Inputs/Password";
import { useAuthentication } from "../../../../Authentication/context";
import { useChangePassword } from "../../../../Authentication/RequiredSetPassword/api";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";

function ChangePassword({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { user, dispatch } = useAuthentication();
	const changePassword = useChangePassword();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const onFinish = useCallback(
		async (values) => {
			if (!user) {
				return;
			}
			setLoading(true);
			setError(null);

			try {
				await changePassword({
					oldPassword: values.oldPassword,
					plainPassword: values.newPassword,
				});

				dispatch({
					type: "set-user",
					payload: {
						user: { ...user, requirePasswordChange: false },
					},
				});
				close();
			} catch (err) {
				setError(err);
				if (typeof err) {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[user, dispatch, changePassword, setLoading, setError, close],
	);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["oldPassword", "newPassword", "confrimNewPassword"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Field
				name="oldPassword"
				label={t("old-password")}
				rules={[
					{
						required: true,
						message: t("required-old-password"),
					},
				]}
			>
				<Password placeholder={t("old-password")} />
			</Field>

			<Field
				name="newPassword"
				label={t("new-password")}
				rules={[
					{
						required: true,
						message: t("required-new-password"),
					},
				]}
			>
				<Password placeholder={t("new-password")} />
			</Field>

			<Field
				name="confirmNewPassword"
				label={t("confirm-password")}
				rules={[
					{
						required: true,
						message: t("required-confirm-password"),
					},
				]}
			>
				<Password placeholder={t("confirm-password")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={loading}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={loading}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangePassword;
