export const isPointInsidePolygon = (nvert, vertx, verty, testx, testy) => {
	let i,
		j,
		inside = false;
	for (i = 0, j = nvert - 1; i < nvert; j = i++) {
		if (
			verty[i] > testy != verty[j] > testy &&
			testx <
				((vertx[j] - vertx[i]) * (testy - verty[i])) /
					(verty[j] - verty[i]) +
					vertx[i]
		)
			inside = !inside;
	}
	return inside;
};
