import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import AccessLevelTree from "./AccessLevelTree";
import Text from "../../../../../components/Inputs/Text";

function Content({ mode, disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<BaseSettingsFormContent mode={mode} disabled={disabled} />

			<Field
				name="level"
				label={t("level")}
				rules={[
					{
						required: true,
						message: t("required-level")
					},
					() => ({
						validator(_, value) {
							if (value && Number(value) < 1) {
								return Promise.reject(
									new Error(t("min-role-level"))
								);
							}

							return Promise.resolve();
						}
					})
				]}
			>
				<Text type="number" placeholder={t("level")} />
			</Field>

			<ol
				type="i"
				style={{ columns: 2, columnGap: 16, color: "#898989" }}
				className="text-sm"
			>
				<li>{t("admin")}{" "}(1-10)</li>
				<li>{t("payroll-admin")}{" "}(11-20)</li>
				<li>{t("manager")}{" "}(21-50)</li>
				<li>{t("employee")}{" "}(50{" >"})</li>
			</ol>

			<Field
				name="permissions"
				label={t("permissions")}
				rules={[
					{
						required: true,
						message: t("required-permissions"),
					},
				]}
			>
				<AccessLevelTree disabled={disabled} />
			</Field>
		</>
	);
}

export default Content;
