import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

export const useLocationValidations = () => {
  const { t } = useTranslation();
  const isRequired = useCallback((value) => {
    if (!value) {
      return t("Location is required");
    }
    // if (value.timezoneValue) {
    //   return t("Location should have a timezone configured");
    // }
    return null;
  }, [t]);
  return useMemo(() => [isRequired], [isRequired])
}
