import useSWR from "swr";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { formatDateRange } from "../../../../../utils/helpers/api";

export const usePayGroupItems = (user) => {
    const { from, to } = useDateFilters();
    const { data } = useSWR(() => {
        if (!user?.payGroup?.id) {
            return null;
        }
        return {
            url: `/pay-group/${user.payGroup.id}/items`,
            params: {
                ...formatDateRange(from, to),
                pagination: "false",
            },
        };
    });
    return data?.result;
}
