import { useRef, useMemo, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ProjectModel } from "@bryntum/schedulerpro";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react";
import { CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loaders/Loading";
import Header from "./Header";
import { ScheduleEventModel } from "./ScheduleEventModel";
import { eventRenderer } from "./useConfig/eventRenderer";
import { useProfileDrawer } from "../hooks/useProfileDrawer";
import { useDeleteModal } from "../hooks/useDeleteModal";
import { useCopyModal } from "../hooks/useCopyModal";
// import { useTimeRangeStore } from "./other/useTimeRangeStore";
import { useConfig } from "./useConfig";
import { useViewPreset } from "./useConfig/useViewPreset";
import { useEditEvent } from "./EventEditDrawer";
import { useTransferModal } from "../hooks/useTransferModal";
import { useActionModal } from "../hooks/useActionModal";
import { useCount } from "../hooks/useCount";
import { useHolidayTooltip } from "../hooks/useHolidayTooltip";
import { useModal } from "../../../utils/hooks/useModal";
import { useCreateEvent, useSaveEvent } from "../api/useSaveEvent";
import { useEventResizeFeature } from "./useConfig/useEventResizeFeature";
import { useDateFilters } from "../hooks/useDateFilters";
import { useElementHeight } from "../../../utils/hooks/useElementHeight";
import { useAccess } from "../../../utils/hooks/access";
import "@bryntum/schedulerpro/schedulerpro.stockholm.css";
import "./_style.scss";

const icon = ( 
	<FontAwesomeIcon 
		className="text-warning"
		icon={faWarning} 
		fontSize={70} 
	/>
);

function Scheduler({
	mutate,
	resources,
	events,
	columns,
	isTeamScheduler = false,
	fetchUserSchedules,
	isLoading,
	filters,
	pagination,
	params,
	onSortClick
}) {
	const { t } = useTranslation();
	const { ref: actionsRef, height: actionsHeight } = useElementHeight();
	const [schedulerInstance, setSchedulerInstance] = useState(null);
	const [scheduleConfig, setScheduleConfig] = useState(null);
	const { mode, from, to } = useDateFilters();
	const schedulerRef = useRef(null);
	const [selectedEvents, setSelectedEvents] = useState([]);
	const { create: createEvent, loading: isCreating } = useCreateEvent({ isTeamScheduler });
	const { count, resetCount } = useCount({ isTeamScheduler, params });
	const { openTooltip, tooltip } = useHolidayTooltip();

	const {
		hasAccess: canCreate
	} = useAccess(isTeamScheduler ? "schedule.canCreate" : "schedule.canCreateMySchedule");
	const {
		hasAccess: canEdit
	} = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
	const {
		hasAccess: canCreateRequest
	} = useAccess(!isTeamScheduler && "request.canCreate");
	const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" :"schedule.canCopyMySchedule");

	const eventResizeFeature = useEventResizeFeature({ mode });

	const storeConfig = useMemo(() => {
		if (schedulerInstance && !schedulerInstance?.isDestroyed && typeof schedulerInstance.refresh === 'function') {
			schedulerInstance?.refresh();
		}
		return {
			resourceStore: { 
				data: resources,
			},
			eventStore: {
				tree: true,
				data: events?.map((event) => ({ 
					...event, 
					readOnly: event?.locked || (!canEdit && !canCopy && !canCreate)
				})),
			},
		};
	}, [resources, events, schedulerInstance, canEdit, canCopy, canCreate]);

	const [editEventDrawer, openEditDrawer, closeEditDrawer] = useEditEvent({ isTeamScheduler, mutate });
	const { drawer: profileDrawer, open: openProfile } = useProfileDrawer();
	const { 
		modal: deleteModal, 
		open: openDeleteModal 
	} = useDeleteModal({ mutate, params, isTeamScheduler, setSelectedEvents, resetCount });

	const { open: openWarningModal, modal: warningModal } = useModal({
		icon,
		color: "warning",
		message: t("do-you-want-to-edit-approved-event"),
		onConfirm: openEditDrawer
	});

	const {
		modal: copyModal,
		open: openCopyModal,
	} = useCopyModal({ isTeamScheduler, mutate, params, setSelectedEvents, resetCount });
	const { 
		modal: transferModal, 
		open: openTransferModal 
	} = useTransferModal({ mutate, params, setSelectedEvents, resetCount });
	const { 
		modal: actionModal, 
		open: openActionModal
	} = useActionModal({ mutate, params, isTeamScheduler, setSelectedEvents, resetCount });

	const onCloseEditDrawer =useCallback(() => {
		if (schedulerInstance?.editingRecord?.isCreating) {
			schedulerInstance?.editingRecord.remove();
			delete schedulerInstance?.editingRecord;
		}
		closeEditDrawer();
	}, [closeEditDrawer, schedulerInstance]);

	const onFailure = useCallback((event, source) => {
		fetchUserSchedules(event, source);
		onCloseEditDrawer();
	}, [onCloseEditDrawer, fetchUserSchedules])

	const { submit, loading } = useSaveEvent({ isTeamScheduler });
	function onEventSave(event, source) {
		submit(event.toJSON(), source, async (_, source) => {
			fetchUserSchedules(event, source);
			resetCount();
		}, () => onFailure(event, source));
	};

	const onEventCopy = useCallback((event, source) => {
		createEvent(event.toJSON(), 
			source,
			async() => {
				fetchUserSchedules(event, source);
				resetCount();
			}, 
			() => onFailure(event, source)
		);
	}, [createEvent, fetchUserSchedules, onFailure, resetCount]);

	const saveEventOnDb = useCallback((event, source) => {
		if (event?.type === "aftereventdrop") {
			const copyKeyPressed = (event?.context?.browserEvent || event?.domEvent)
			 	&& (event?.context?.browserEvent?.metaKey || event?.domEvent?.metaKey 
					|| event?.context?.browserEvent?.ctrlKey || event?.domEvent?.ctrlKey);
			if (copyKeyPressed && canCopy) {
				onEventCopy(event.eventRecords[0], source);
			} else {
				onEventSave(event.eventRecords[0], source);
			}
		} else {
			onEventSave(event, source);
		}
	}, [canCopy, onEventSave, onEventCopy]);

	const config = useConfig({
		mode,
		from,
		to,
		isTeamScheduler,
		openCopyModal,
		openProfile: isTeamScheduler && openProfile,
		saveEventOnDb,
		setSelectedEvents,
		openDeleteModal,
		openTransferModal: isTeamScheduler && openTransferModal,
		openActionModal,
		openEditDrawer,
		schedulerInstance,
	});

	// const timeRangeStore = useTimeRangeStore({ mode, user, company });
	const projects = useMemo(
		() => ({
			schedule: new ProjectModel({
				...storeConfig,
				eventModelClass: ScheduleEventModel,
				// timeRangeStore,
			}),
		}),
		[storeConfig/**, timeRangeStore*/]
	);

	const preset = useViewPreset({ mode, openTooltip });

	const resizeFeature = useMemo(() => {
		if (mode === "date" && canCreate) {
			return eventResizeFeature
		} else {
			return false;
		}
	}, [mode, canCreate, eventResizeFeature])

	useEffect(() => {
		if (schedulerInstance && !schedulerInstance?.isDestroyed && from && to && preset) {
			schedulerInstance.suspendRefresh();
			schedulerInstance.viewPreset = preset;
			schedulerInstance.scrollLeft = 0;
			schedulerInstance.weekStartDay = moment.parseZone(from).day()
			schedulerInstance.setTimeSpan(
				moment.parseZone(from).startOf("day").toDate(),
				moment.parseZone(to).endOf("day").toDate(),
			)
			schedulerInstance.resumeRefresh(true);
		}
	}, [from, to, schedulerInstance, preset]);
	
	useEffect(() => {
		if (schedulerRef && schedulerRef.current && (!schedulerInstance || schedulerInstance?.isDestroyed)) {
			setScheduleConfig(config)
			setSchedulerInstance(schedulerRef?.current?.instance);
			schedulerRef?.current?.instance.refresh();
		}
	}, [schedulerRef, config, schedulerInstance, setScheduleConfig, setSchedulerInstance]);

	return (
		<CardBody className="p-0 d-flex flex-column position-realtive">
			<Header 
				actionsRef={actionsRef}
				sort={params?.sort}
				selectedEvents={selectedEvents}
				filters={filters}
				openDeleteModal={openDeleteModal}
				openCopyModal={isTeamScheduler && openCopyModal}
				openActionModal={isTeamScheduler && openActionModal}
				onSortClick={onSortClick}
				events={events}
				isTeamScheduler={isTeamScheduler}
				mutate={mutate}
				params={params}
				count={count || 0}
				resetCount={resetCount}
			/>

			{(isLoading || isCreating || loading) ? (
				<div 
					className="w-100 position-absolute"
					style={{
						zIndex: 5,
						backgroundColor: "#eeeeeec2",
						height: `calc(100% - ${actionsHeight}px - 130px)`,
						right: "27px",
					}}
				>
					<div className="position-relative text-center" style={{ top: "50%"}}>
						<Loading />
					</div>
				</div> 
			) : null}

			<div
				style={{ height: `calc(100% - ${actionsHeight}px - 64px)` }}
			>
				<BryntumSchedulerPro
					ref={schedulerRef}
					height="100%"
					columns={columns}
					{...(scheduleConfig || config)}
					project={projects.schedule}
					onEventClick={onCloseEditDrawer}
					onCellClick={onCloseEditDrawer}
					onDragCreateStart={onCloseEditDrawer}
					onEventDblClick={({ eventRecord, source, resourceRecord }) => {
						if (canEdit || canCreateRequest) {
							if (eventRecord?.status === "approved" && !eventRecord?.locked && canEdit) {
								openWarningModal({ eventRecord, resourceRecord, source, onEventSave });
							} else if (!eventRecord?.locked) {
								openEditDrawer({ eventRecord, resourceRecord, source, onEventSave });
							}
						}
						return false;
					}}
					rowHeight={60}
					eventRenderer={eventRenderer}
					barMargin={2}
					resourceMargin={2}
					autoHeight={!isTeamScheduler}
					forceFit={true}
					// viewPreset={preset}
					presets={[preset]}
					minHeight={100}
					timeRangesFeature={{ showCurrentTimeLine: false }}
					multiEventSelect={true}
					zoomOnMouseWheel={false}
					zoomOnTimeAxisDoubleClick={false}
					preventScrollZoom={true}
					useInitialAnimation={false}
					createEventOnDblClick={canCreate}
					type="schedulerwithsubtasks"
					eventResizeFeature={resizeFeature}
					{...(mode !== 'date' ? {
						fillTicks: true,
					} : {
						fillTicks: false,
					})}
					onBeforeEventEdit={({ eventRecord, source, resourceRecord }) => {
						if (eventRecord?.status === "approved" && !eventRecord?.locked && canEdit) {
							openWarningModal({ eventRecord, resourceRecord, source, onEventSave });
						} else {
							openEditDrawer({ eventRecord, resourceRecord, source, onEventSave });
						}
						source.editingRecord = eventRecord;
						return false;
					}}
				/>

				{pagination}		
			</div>
			
			{tooltip}
			{editEventDrawer}
			{profileDrawer}
			{deleteModal}
			{copyModal}
			{transferModal}
			{actionModal}
			{warningModal}
		</CardBody>
	)
}

export default Scheduler;
