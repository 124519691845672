import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Modal from "../../../../../components/Modal";
import SimpleOldTable from "../../../../../components/SimpleOldTable";
import Button from "../../../../../components/Button";
import DateInput from "../../../../../components/Inputs/DateInput";
import { useColumns } from "./useColumns";
import { useCopyTimesheet } from "../../../api/useCopyTimesheet";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { formatTimeSheetParams } from "../../../api/helpers";
import { getErrorMessage } from "../../../../../utils/helpers/errors";
import { getStartDate, getEndDateFromStart } from "../../../components/Topbar/Filters/helpers";

function CopyTimesheet({ isOpen, close, refetch, filters, users }) {
    const { t } = useTranslation();
    const [response, setResponse] = useState();
    const { from, to } = useDateFilters();
    const [start, setStart] = useState(from);
    const [end, setEnd] = useState(to);
    const { teamCopy, loading } = useCopyTimesheet();

    const onWeekChange = useCallback(([val]) => {
        const date = moment(val);
        setStart(getStartDate(date, "week"));
        setEnd(getEndDateFromStart(date, "week"));
    }, []);

    const onCopy = useCallback(() => {
        teamCopy(
            {
                startDate: moment.parseZone(start).format("YYYY-MM-DD"),
                filters: formatTimeSheetParams({
                    ...filters,
                    from,
                    to
                }),
                users: users?.map(({ id }) => id)
            },
            (response) => {
                setResponse(response);
            },
            (err) => {
                getErrorMessage(err, t);
            },
        )
    }, [filters, from, to, start, users, setResponse, teamCopy, t]);

    const onClose = useCallback(() => {
        if (response) {
            close();
            refetch();
        } else {
            close();
        }
    }, [close, refetch, response]);

    const columns = useColumns({ response, loading });

    return (
        <Modal
            title={
                <h2 className="mb-0 pb-0">
                    {t("copy-timesheet")}
                </h2>
            }
            isOpen={isOpen}
            close={onClose}
            toggle={onClose}
            size="lg"
            centered
        >
            <div className="mb-5">
                <div className="mb-2 font-weight-600">
                    {t("select-date-to-copy-timesheets")}:
                </div>

                <div className="w-50">
                    <DateInput
                        startDate={moment(start).toDate()}
                        endDate={moment(end).toDate()}
                        onChange={onWeekChange}
                        selectsRange
                    />
                </div>
            </div>

            <div
                className="mb-3"
                style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                }}
            >
                <SimpleOldTable
                    data={users}
                    columns={columns.map(({accessor, id, ...all}) => {
                        return {...all, id: accessor || id}
                    })}
                    footer={false}
                />
            </div>

            <div className="d-flex justify-content-between mb-3">
                <Button
                    color="primary"
                    onClick={onCopy}
                    loading={loading}
                    disabled={loading}
                >
                    {t("paste")}
                </Button>

                <Button
                    color="dark"
                    onClick={onClose}
                    loading={loading}
                    disabled={loading}
                >
                    {t("close")}
                </Button>
            </div>
        </Modal>
    );
};

export default CopyTimesheet;
