import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

export const usePayCodeValidations = () => {
  const { t } = useTranslation();
  const isRequired = useCallback((value) => {
    if (!value) {
      return t("Pay code is required");
    }
    return null;
  }, [t]);
  return useMemo(() => [isRequired], [isRequired])
}
