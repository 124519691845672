import moment from "moment-timezone";
import {getCommonJobs, getCommonLevels, getCommonLocations} from "./helpers";

export const getInitial = ({ users, allocatedGroups }) => {
    let job, location = null;
    let levels = {};

    if (users) {
        const jobs = getCommonJobs(users);
        if (jobs.length === 1) {
            job = jobs[0].jobId;
        }

        const locations = getCommonLocations(users);
        if (locations.length === 1) {
            location = locations[0].locationId;
        }

        if (allocatedGroups) {
            levels = getCommonLevels(users, allocatedGroups);
        }
    }

    return {
        job,
        location,
        ...levels,
        /*
         * temp
        startDate: moment().toDate(),
        endDate: moment().add(2, "days").toDate(),
        in: moment().startOf("day").set({ hour: 9 }).toISOString(),
        out: moment().startOf("day").set({ hour: 17 }).toISOString(),
        */
    };
}
