import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../../../components/Modal";
import MealPenaltyRuleForm from "./Form";
import Field from "../../../../../../../components/Field";
import Checkbox from "../../../../../../../components/Inputs/Checkbox";

function MealPenaltyRule({ disabled }) {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);

	const openModal = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const closeModal = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	return (
		<div className="border-bottom mb-10">
			<div className="mb-3">
				<h3 className="font-weight-bolder text-sm">
					{t("meal-penalty-rule")}
				</h3>
			</div>

			<div className="mb-10">
				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue, getFieldError }) => {
						const status = getFieldValue([
							"mealPenaltyRule",
							"status",
						]);

						const error = getFieldError([
							"mealPenaltyRule",
							"firstMinimum",
						]);

						return (
							<>
								<div className="d-flex justify-content-between">
									<div className="d-flex align-items-center">
										<Field
											name={["mealPenaltyRule", "status"]}
											valuePropName="checked"
											noStyle
											className="mb-0 pt-2"
										>
											<Checkbox
												id="meal-penalty-rule"
												disabled={disabled}
											/>
										</Field>

										<p className="mr-2 mb-0 text-sm">
											{t("first-min-length")}
										</p>

										<BaseField
											name={[
												"mealPenaltyRule",
												"firstMinimum",
											]}
											rules={
												status
													? [
														{
															required:
																status,
															message: t("required-first-min"),
														},
														{
															validator(_, value) {
																if (Number(value) < 0) {
																	return Promise.reject(new Error(t("first-min-patten")))
																}
																return Promise.resolve()
															}
														},
													]
													: undefined
											}
											noStyle
											dependencies={[
												["mealPenaltyRule", "status"],
											]}
											className="mb-0"
										>
											<Input
												type="number"
												size="sm"
												readOnly={!status}
												className="input-number mr-1"
												disabled={disabled}
												step="0.1"
											/>
										</BaseField>

										<p className="text-sm mb-0">
											{t("minutes")}
										</p>
									</div>

									<div className="d-flex align-items-center justify-content-end">
										<FontAwesomeIcon
											className="text-sm cursor-pointer"
											icon={faPen}
											onClick={
												status ? openModal : undefined
											}
										/>
									</div>
								</div>

								{error && (
									<span className="invalid-feedback d-block">
										{error[0]}
									</span>
								)}
							</>
						);
					}}
				</BaseField>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue, getFieldError }) => {
						const status = getFieldValue([
							"mealPenaltyRule",
							"status",
						]);

						const error = getFieldError([
							"mealPenaltyRule",
							"firstDuration",
						]);

						const firstMinimum = getFieldValue([
							"mealPenaltyRule",
							"firstMinimum",
						]);

						return (
							<>
								<div className="d-flex align-items-center mb-2">
									<p className="mr-2 mb-0 text-sm">
										{t("break-duration")}
									</p>

									<BaseField
										name={[
											"mealPenaltyRule",
											"firstDuration",
										]}
										rules={
											status
												? [
													{
														required: status,
														message: t("required-first-duration"),
													},
													{
														validator(
															_,
															value,
														) {
															if (
																firstMinimum &&
																value !==
																null
															) {
																if (
																	Number(
																		firstMinimum,
																	) >
																	Number(
																		value,
																	)
																) {
																	return Promise.reject(
																		new Error(
																			t("first-duration-bigger-than-first-min"),
																		),
																	);
																}
															}

															return Promise.resolve();
														},
													},
												]
												: undefined
										}
										noStyle
										dependencies={[
											["mealPenaltyRule", "status"],
										]}
										className="mb-0"
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number mr-1"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="mr-2 text-sm mb-0">
										{t("minutes")}
									</p>
								</div>

								{error && (
									<span className="invalid-feedback d-block">
										{error[0]}
									</span>
								)}
							</>
						);
					}}
				</BaseField>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue, getFieldError }) => {
						const status = getFieldValue([
							"mealPenaltyRule",
							"status",
						]);

						const error = getFieldError([
							"mealPenaltyRule",
							"secondMinimum",
						]);

						return (
							<>
								<div className="d-flex align-items-center mb-2">
									<p className="mr-2 mb-0 text-sm">
										{t("second-min-length")}
									</p>

									<BaseField
										name={[
											"mealPenaltyRule",
											"secondMinimum",
										]}
										rules={
											status
												? [
													{
														required: status,
														message: t("required-second-min-length"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("second-min-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													},
												]
												: undefined
										}
										noStyle
										dependencies={[
											["mealPenaltyRule", "status"],
										]}
										className="mb-0"
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number mr-1"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="mr-2 text-sm mb-0">
										{t("minutes")}
									</p>
								</div>

								{error && (
									<span className="invalid-feedback d-block">
										{error[0]}
									</span>
								)}
							</>
						);
					}}
				</BaseField>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue, getFieldError }) => {
						const status = getFieldValue([
							"mealPenaltyRule",
							"status",
						]);

						const secondMinimum = getFieldValue([
							"mealPenaltyRule",
							"secondMinimum",
						]);

						const error = getFieldError([
							"mealPenaltyRule",
							"secondDuration",
						]);

						return (
							<>
								<div className="d-flex align-items-center">
									<p className="mr-2 text-sm mb-0">
										{t("break-duration")}
									</p>

									<BaseField
										name={[
											"mealPenaltyRule",
											"secondDuration",
										]}
										rules={
											status
												? [
													{
														required: status,
														message: t("required-second-duration"),
													},

													{
														validator(
															_,
															value,
														) {
															if (
																secondMinimum &&
																value !==
																null
															) {
																if (
																	Number(
																		value,
																	) <
																	Number(
																		secondMinimum,
																	)
																) {
																	return Promise.reject(
																		new Error(
																			t("second-duration-bigger-than-second-min"),
																		),
																	);
																}
															}

															return Promise.resolve();
														},
													},
												]
												: undefined
										}
										noStyle
										dependencies={[
											["mealPenaltyRule", "status"],
										]}
										className="mb-0"
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number mr-1"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="mr-2 text-sm mb-0">
										{t("minutes")}
									</p>
								</div>

								{error && (
									<span className="invalid-feedback d-block">
										{error[0]}
									</span>
								)}
							</>
						);
					}}
				</BaseField>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldsValue }) => (
					<Modal
						title={t("meal-penalty-parameters")}
						isOpen={visible}
						close={closeModal}
					>
						<MealPenaltyRuleForm
							close={closeModal}
							mealPenaltyRule={getFieldsValue()}
							disabled={disabled}
						/>
					</Modal>
				)}
			</BaseField>

			<BaseField name={["mealPenaltyRule", "eliglibleHourType"]}>
				<Input hidden />
			</BaseField>

			<BaseField name={["mealPenaltyRule", "eligliblePayCodes"]}>
				<Input hidden />
			</BaseField>

			<BaseField name={["mealPenaltyRule", "breakPayCodes"]}>
				<Input hidden />
			</BaseField>

			<BaseField name={["mealPenaltyRule", "violationPayCode"]}>
				<Input hidden />
			</BaseField>

			<BaseField name={["mealPenaltyRule", "violationDuration"]}>
				<Input hidden />
			</BaseField>
		</div>
	);
}

export default MealPenaltyRule;
