import { faNoteSticky, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isAddedTimeSheet } from "../helpers";

export const useActionsColumn = ({ edit, remove }) => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("actions"),
        id: "actions",
        hideable: false,
        Cell: ({ row, cell }) => {
            const { original } = row;
            const disabled = row.disabled || cell.disabled;
            const isAdded = isAddedTimeSheet(original);
            if (isAdded && !original.isTouched) {
                return null;
            }

            return (!original.locked && !disabled) && (
                <div className="d-flex justify-content-end align-items-center gap-x-3">
                    {edit && (
                        <span
                            className={classNames("cursor-pointer", original?.note ? "text-primary" : "")}
                            onClick={() => edit(original)}
                        >
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" />
                        </span>
                    )}

                    {!isAdded && remove && (
                        <span
                            className="cursor-pointer text-red"
                            onClick={() => remove({ data: original.id })}
                        >
                            <FontAwesomeIcon icon={faTrash} size="lg" />
                        </span>
                    )}
                </div>
            );
        },
    }), [t, edit, remove]);
};
