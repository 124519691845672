import { combineDateAndTime } from "../../../utils/helpers/date";

export const getClocksFromValues = (values, location) => {
	const timezone = location?.timezoneValue;

	const clocks = [
		{
			type: "SHIFT",
			mode: "START",
			time: values.start,
			device: "request",
			ip: values?.ip,
		},
	];

	values?.breaks
		?.filter((b) => !!b && !!b.start && b.end) // WORKAROUND
		.forEach(({ start, end, date }) => {
			let _s = combineDateAndTime(date, start);
			let _e = combineDateAndTime(date, end);
			if (timezone) {
				_s = _s.tz(timezone, true);
				_e = _e.tz(timezone, true);
			}
			_s = _s.toISOString(true);
			_e = _e.toISOString(true);
			clocks.push(
				{
					type: "BREAK",
					mode: "START",
					time: _s,
					device: "request",
					ip: values?.ip,
				},
				{
					type: "BREAK",
					mode: "END",
					time: _e,
					device: "request",
					ip: values?.ip,
				},
			);
		});

	clocks.push({
		type: "SHIFT",
		mode: "END",
		time: values.end,
		device: "request",
		ip: values?.ip,
	});

	return clocks;
};

export const formatFormData = (values) => {
	const timezone = values?.location?.timezoneValue;
	let start = combineDateAndTime(values.start.date, values.start.time);
	let end = combineDateAndTime(values.end.date, values.end.time);

	if (timezone) {
		start = start.tz(timezone, true);
		end = end.tz(timezone, true);
	}

	start = start.toISOString(true);
	end = end.toISOString(true);

	return {
		location: values.location,
		job: values.job,
		project: values?.project,
		note: values.note,
		startDate: start,
		endDate: end,
		clocks: getClocksFromValues(
			{
				start: start,
				end: end,
				breaks: values.breaks,
				ip: values?.ip,
			},
			values.location,
		),
		status: "ended",
	};
};
