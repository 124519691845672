import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import { useColumns } from "./columns";
import NTable from "../../../../components/NTable";
import Calendar from "./Calendar";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useNewTable } from "../../../../components/NTable/hook";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useOnExport } from "../../helpers/useOnExport";
import { useImportModal } from "../../helpers/useImportModal";

function PayGroup() {
    const { t } = useTranslation();

    const {
        state: { mode, data, totalItems, loading, submitting, error },
        selected,
        fetch,
        createSubmit,
        updateSubmit,
        goToHelp,
        goToView,
        goToCreate,
        goToEdit,
        goToClone,
        remove,
        batchRemove,
        settingsExport,
        loadingExport,
        setData,
    } = useSettingsCRUDHook("/pay-group");

    const { hasAccess: canAdd } = useAccess(
        "settings.payPolicies.payGroup.canCreate",
    );

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedPayGroup, setSelectedPayGroup] = useState();

    const onCalendarClose = useCallback(() => {
        setIsCalendarOpen(false);
    }, [setIsCalendarOpen]);

    const openCalendar = useCallback(
        (payGroup) => {
            setIsCalendarOpen(true);
            setSelectedPayGroup(payGroup);
        },
        [setIsCalendarOpen, setSelectedPayGroup],
    );

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: (data) =>
            Array.isArray(data)
                ? t("do-you-want-to-delete-pay-groups", { count: data?.length })
                : t("do-you-want-to-delete-pay-group"),
        onConfirm: (data) =>
            Array.isArray(data) ? batchRemove(data) : remove(data),
        permission: "settings.payPolicies.payGroup.canDelete",
    });
    const { unarchiveModal, isFormOpen, formProps } = useManageForm({
        mode,
        selected,
        createSubmit,
        updateSubmit,
    });

    const columns = useColumns({
        edit: useActionWithAccess(
            goToEdit,
            "settings.payPolicies.payGroup.canEdit",
        ),
        remove: openDeleteModal,
        clone: useActionWithAccess(
            goToClone,
            "settings.payPolicies.payGroup.canCreate",
        ),
        openCalendar,
    });

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "payGroups",
    });

    const queryFilters = useQueryFilters(table);

    const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
        mode,
        help: t("help"),
        view: t("view-pay-group"),
        create: t("add-pay-group"),
        edit: t("edit-pay-group"),
        clone: t("clone-pay-group"),
    });

    useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/pay-groups",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys,
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/pay-groups/import",
        exportUrl: "/export/pay-groups",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

    return (
        <div className="d-flex h-100">
            <Card className="flex-fill mb-0">
                <Topbar
                    title={t("pay-groups")}
                    add={canAdd && goToCreate}
                    help={goToHelp}
                    importFile={openImportModal}
                    onExport={onExport}
                    loading={loading || loadingExport}
                />

                <Card
                    className="mb-auto"
                    style={{ maxHeight: "calc(100% - 156px)" }}
                >
                    <Actions
                        filters={table.getState().columnFilters}
                        setFilters={table.setColumnFilters}
                        columns={table.getAllColumns()}
                    >
                        <BulkActions
                            rows={table.getState().rowSelection}
                            batchRemove={openDeleteModal}
                        />
                    </Actions>

                    <NTable table={table} />
                </Card>
            </Card>

            <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
                {isFormOpen ? (
                    <Form
                        {...formProps}
                        error={error}
                        loading={submitting}
                        close={goToView}
                    />
                ) : (
                    <>{t("help")}</>
                )}
            </Drawer>

            {importModal}

            {deleteModal}

            {unarchiveModal}

            {isCalendarOpen && (
                <Calendar
                    payGroup={selectedPayGroup}
                    visible={isCalendarOpen}
                    close={onCalendarClose}
                />
            )}
        </div>
    );
}

export default PayGroup;
