import {useCallback} from "react";
import {useDateFilters} from "../../hooks/useDateFilters"

export const useRefetch = ({ refetchUser, fetchOverview, refetchTimeSheets, filters }) => {
    const {from, to} = useDateFilters();

    const refetchOverview = useCallback(() => {
        refetchUser?.();
        fetchOverview({
            params: { from, to, filters: filters.filters },
        });
    }, [refetchUser, fetchOverview, from, to, filters.filters]);

    const refetchOverviewAndTimeSheets = useCallback(() => {
        refetchUser?.();
        refetchOverview();
        refetchTimeSheets();
    }, [refetchUser, refetchOverview, refetchTimeSheets]);

    return { refetchOverview, refetchOverviewAndTimeSheets };
}
