import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
	useCompanyDateFormat,
	useIsMasterCompany,
} from "../../../../utils/hooks/company";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
	renderCompanyResourceLabel,
} from "../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";
import { manageAbilityOptions } from "./Form/ManageAbilitySelect";
import { getCompaniesSearchFilters } from "../../../Profile/Personal/Actions/ChangeCompanyInformation";
import moment from "moment-timezone";
import {createColumnHelper} from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const dateFormat = useCompanyDateFormat();
	const { groups } = useContext(OrgLevelGroupsContext);

	const { actions } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("effectiveDate", {
				header: t("effective-date"),
				cell: (info) => {
                    const value = info.getValue();
                    return value && moment(value).format(dateFormat);
                },
			}),
			columnHelper.accessor("resourceId", {
				enableColumnFilter: true,
				header: t("calculation-groups"),
				cell: (info) => {
                    const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/calculation-group"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						mode="multiple"
					/>
				),
			}),
			columnHelper.accessor("manageAbility", {
				enableColumnFilter: true,
				header: t("manage-ability"),
				cell: (info) => t(info.getValue()),
				filterType: "select",
				Filter: (
					<Select showSearch>
						{manageAbilityOptions.map(({ value, label }) => (
							<Select.Option key={value} value={value}>
								{t(label)}
							</Select.Option>
						))}
					</Select>
				),
			}),
			columnHelper.accessor("priority", {
				enableColumnFilter: true,
				header: t("priority"),
				filterType: "text",
				Filter: <Input type="number" min={1} max={100} />,
			}),
			columnHelper.accessor("companies", {
				enableColumnFilter: true,
				header: t("companies"),
				cell: (info) => {
                    const value = info.getValue();
                    if (!(value?.length > 0)) {
                        return null;
                    }
					return (
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "1fr 1fr",
							}}
						>
							{value?.map((val) => (
								<span
									className="mr-1 mb-1 border rounded border-primary px-3 py-2 text-primary text-center"
									style={{
										textOverflow: "ellipsis",
										width: "100px",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									{val?.name}
								</span>
							))}
						</div>
					);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/companies"
						hasSearch
						getSearchFilters={getCompaniesSearchFilters}
						mode="multiple"
						renderLabel={renderCompanyResourceLabel}
					/>
				),
			}),
			columnHelper.accessor("locations", {
				enableColumnFilter: true,
				header: t("locations"),
				cell: (info) => {
                    const value = info.getValue();
                    return value?.length > 0 && (
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "1fr 1fr",
							}}
						>
							{value?.map((val) => (
								<span
									className="mr-1 mb-1 border rounded border-primary px-3 py-2 text-primary text-center"
									style={{
										textOverflow: "ellipsis",
										width: "100px",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									{renderSettingsResourceLabel(val)}
								</span>
							))}
						</div>
					);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/locations"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						mode="multiple"
					/>
				),
			}),
			columnHelper.accessor("payGroups", {
				enableColumnFilter: true,
				header: t("pay-groups"),
				cell: (info) => {
                    const value = info.getValue();
					return value?.length > 0 && (
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "1fr 1fr",
							}}
						>
							{value?.map((val) => (
								<span
									className="mr-1 mb-1 border rounded border-primary px-3 py-2 text-primary text-center"
									style={{
										textOverflow: "ellipsis",
										width: "100px",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									{renderSettingsResourceLabel(val)}
								</span>
							))}
						</div>
					);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/pay-group"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						mode="multiple"
					/>
				),
			}),
		];

		if (groups) {
			groups
				?.filter(({ hasHierarchy }) => hasHierarchy)
				.forEach(({ description, id }) => {
					const column = columnHelper.accessor("managedLevels", {
						enableColumnFilter: true,
						header: description,
						cell: (info) => {
                            const value = info.getValue();
							return value?.length > 0 && (
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr",
									}}
								>
									{value?.map((val) => (
										<span
											className="mr-1 mb-1 border rounded border-primary px-3 py-2 text-primary text-center"
											style={{
												textOverflow: "ellipsis",
												width: "100px",
												overflow: "hidden",
												whiteSpace: "nowrap",
											}}
										>
											{renderOrgLevelResourceLabel(val)}
										</span>
									))}
								</div>
							)
                        },
						filterType: "resource-select",
						Filter: (
							<ResourceSelect
								labelPropName="description"
								resourcePath={`/${id}/org-levels`}
								mode="multiple"
								renderLabel={renderOrgLevelResourceLabel}
								getSearchFilters={
									getSettingsOrgResourceSearchFilters
								}
								valuePropName="id"
								hasSearch
							/>
						),
					});
                    columns.push(column);
                });
		}
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [isMasterCompany, actions, groups, dateFormat, t]);
};
