import { useTranslation } from "react-i18next";
import { List, Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../components/Field";
import Checkbox from "../../../../../../../components/Inputs/Checkbox";
import Switch from "../../../../../../../components/Inputs/Switch";
import classNames from "classnames";

function RoundClockOutRule({ disabled }) {
    const { t } = useTranslation();

    return (
        <div className="d-flex align-items-center mb-2">
            <Field
                name={["unionRules", "clockOut", "status"]}
                valuePropName="checked"
                noStyle
                className="mb-0 pt-2"
            >
                <Checkbox id="status" disabled={disabled} />
            </Field>

            <div className="rule-container">
                <BaseField shouldUpdate>
                    {({}, {}, { getFieldValue, getFieldsError }) => {
                        const status = getFieldValue(["unionRules", "clockOut", "status"]);
                        return (
                            <>
                                <List name={["unionRules", "clockOut", "data"]}>
                                    {(data, { add, remove }) => {
                                        return (
                                            <>
                                                <div className="d-flex align-items-center mb-2">
                                                    <h5>{t("round-clock-out")}</h5> 
                                                    <FontAwesomeIcon 
                                                        className={classNames("text-sm mx-3 mb-2", (disabled || !status) ? "text-gray" : "text-primary cursor-pointer")}
                                                        icon={faPlus} 
                                                        onClick={(disabled || !status) ? undefined : () => add({})}
                                                    />
                                                </div>

                                                {data.map(({ key, name }) => {
                                                    const [error] = getFieldsError([
                                                        ["unionRules", "clockOut", "data", name, "start"],
                                                        ["unionRules", "clockOut", "data", name, "end"]
                                                    ])
                                                        .map(({ errors }) => errors)
                                                        .flat();

                                                    return (
                                                        <>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center mb-2">
                                                                    <p className="mr-2 text-sm mb-0">
                                                                        {`${name + 1}. `}{t("shift-equal-to")}
                                                                    </p>
                                
                                                                    <BaseField
                                                                        name={[name, "start"]}
                                                                        className="mb-0"
                                                                        rules={
                                                                            status
                                                                                ? [
                                                                                    {
                                                                                        required: status,
                                                                                        message:
                                                                                            t("required-start"),
                                                                                    },
                                                                                    {
                                                                                        validator(_, value) {
                                                                                            if (Number(value) < 0) {
                                                                                                return Promise.reject(new Error(t("start-bigger-than-zero")))
                                                                                            }
                                                                                            return Promise.resolve()
                                                                                        }
                                                                                    }
                                                                                ]
                                                                                : undefined
                                                                        }
                                                                        noStyle
                                                                        dependencies={[
                                                                            ["unionRules", "clockOut", "status"],
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            type="number"
                                                                            size="sm"
                                                                            readOnly={!status}
                                                                            className="input-number"
                                                                            disabled={disabled}
                                                                            step="0.1"
                                                                        />
                                                                    </BaseField>
                                
                                                                    <p className="mx-2 text-sm mb-0">
                                                                        {t("minutes-less-than")}
                                                                    </p>
                                
                                                                    <BaseField
                                                                        name={[name, "end"]}
                                                                        className="mb-0"
                                                                        rules={
                                                                            status
                                                                                ? [
                                                                                    {
                                                                                        required: status,
                                                                                        message:
                                                                                            t("required-end"),
                                                                                    },
                                                                                    {
                                                                                        validator(_, value) {
                                                                                            if (Number(value) < 0) {
                                                                                                return Promise.reject(new Error(t("end-bigger-than-zero")))
                                                                                            }
                                                                                            return Promise.resolve()
                                                                                        }
                                                                                    },
                                                                                ]
                                                                                : undefined
                                                                        }
                                                                        noStyle
                                                                        dependencies={[
                                                                            ["unionRules", "clockOut", "status"],
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            size="sm"
                                                                            readOnly={!status}
                                                                            className="input-number"
                                                                            disabled={disabled}
                                                                            type="number"
                                                                            step="0.1"
                                                                        />
                                                                    </BaseField>
                                
                                                                    <p className="text-sm mb-0 ml-1">
                                                                        {t("minutes-round-to")} 
                                                                        { " "}
                                                                        {getFieldValue(["unionRules", "clockOut", "data", name, "end"]) > 0 
                                                                            ? `${getFieldValue(["unionRules", "clockOut", "data", name, "end"])}` 
                                                                            : '--'} {t('minutes')
                                                                        }.
                                                                    </p>
                                                                </div>
                                                                {data.length > 1 && (
                                                                    <FontAwesomeIcon
                                                                        className={classNames("text-sm", (disabled || !status) ? "text-gray" : "text-danger cursor-pointer")}
                                                                        icon={faMinus}
                                                                        onClick={(disabled || !status) ? undefined : () => remove(name)}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="flex flex-col mb-1">
                                                                {error && (
                                                                    <span className="invalid-feedback d-block">
                                                                        {error}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                )}
                                            </>
                                        )
                                    }}
                                </List>
                                <div className="d-flex align-items-center">
                                    <Field
                                        name={["unionRules", "clockOut", "runOnManualUpdate"]}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                        className="mb-0"
                                    >
                                        <Switch disabled={disabled || !status} />
                                    </Field>
                                    <p className="text-sm mb-0 mx-2">{t("run-this-rule-on-manual-updates-also")}</p>
                                </div>
                            </>
                        )
                    }}
                </BaseField>
            </div>
        </div>
    );
}

export default RoundClockOutRule;
