import { useCallback } from "react";
import { useActionWithRefetch } from "../../../../hooks/useAction";
import { useApproveTimeSheet } from "../../../../api/useApproveTimeSheet";
import { useLockTimeSheet } from "../../../../api/useLockTimeSheet";
import { useDateFilters } from "../../../../hooks/useDateFilters";
import useApi from "../../../../../../utils/api";
import { formatTimeSheetParams } from "../../../../api/helpers";

export const useDateActions = (refetch, filters) => {
    const { authGet } = useApi();
    const { from, to } = useDateFilters();
    const { approveMany, unapproveMany } = useApproveTimeSheet();
    const { lockMany, unlockMany } = useLockTimeSheet();

    const baseApprove = useCallback((ids) => {
        approveMany(ids, async (value) => {
            return authGet(`/time-sheet/user/${value[0]?.user?.id}`, {
                params: {
                    ...formatTimeSheetParams({
                        from,
                        to,
                        filters
                    }),
                },
            }).then((response) => refetch(response))
        }
        )
    }, [approveMany, refetch, authGet, from, to, filters]);

    const baseUnapprove = useCallback((ids) => {
        unapproveMany(ids, async (value) => {
            return authGet(`/time-sheet/user/${value[0]?.user?.id}`, {
                params: {
                    ...formatTimeSheetParams({
                        from,
                        to,
                        filters
                    }),
                },
            }).then((response) => refetch(response))
        })
    }, [unapproveMany, refetch, authGet, from, to, filters])

    const baseLock = useCallback((ids) => {
        lockMany(ids, async (value) => {
            return authGet(`/time-sheet/user/${value[0]?.user?.id}`, {
                params: {
                    ...formatTimeSheetParams({
                        from,
                        to,
                        filters
                    }),
                },
            }).then((response) => refetch(response))
        })
    }, [lockMany, refetch, authGet, from, to, filters])

    const baseUnlock = useCallback((ids) => {
        unlockMany(ids, async (value) => {
            return authGet(`/time-sheet/user/${value[0]?.user?.id}`, {
                params: {
                    ...formatTimeSheetParams({
                        from,
                        to,
                        filters
                    }),
                },
            }).then((response) => refetch(response))
        })
    }, [unlockMany, refetch, authGet, from, to, filters])

    const approve = useActionWithRefetch(baseApprove, {
        permission: "timeSheet.canApprove",
        onSuccess: refetch,
    });
    const unapprove = useActionWithRefetch(baseUnapprove, {
        permission: "timeSheet.canApprove",
        onSuccess: refetch,
    });
    const lock = useActionWithRefetch(baseLock, {
        permission: "timeSheet.canLock",
        onSuccess: refetch,
    });
    const unlock = useActionWithRefetch(baseUnlock, {
        permission: "timeSheet.canLock",
        onSuccess: refetch,
    });

    return { approve, unapprove, lock, unlock };
}


