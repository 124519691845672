import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getToastErrorMessage } from "../../../utils/helpers/errors";
import { formatTimeSheetParams } from "./helpers";

export const useLockTimeSheet = () => {
	const { t } = useTranslation();
	const { authPut } = useApi();

	const lock = useCallback(
		async (id, onSuccess, onFailure) => {
			const loading = toast.loading(t("locking-timesheet"));
			try {
				const response = await authPut(`/time-sheet/${id}/lock`);
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheet-locked-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err, t("could-not-lock-timesheet")));
			}
		},
		[authPut, t]
	);

	const lockMany = useCallback(
		async (ids, onSuccess, onFailure) => {
			const loading = toast.loading(t("locking-timesheets", { count: ids.length }));
			try {
				const response = await authPut("/time-sheet/lock", { data: ids });
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheets-locked-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err, t("could-not-lock-timesheets")));
			}
		},
		[authPut, t]
	);

	const unlock = useCallback(
		async (id, onSuccess, onFailure) => {
			const loading = toast.loading(t("unlocking-timesheet"));
			try {
				const response = await authPut(`/time-sheet/${id}/unlock`);
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheet-unlocked-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err, t("could-not-unlock-timesheet")));
			}
		},
		[authPut, t]
	);

	const unlockMany = useCallback(
		async (ids, onSuccess, onFailure) => {
			const loading = toast.loading(t("unlocking-timesheets", { count: ids.length }));
			try {
				const response = await authPut("/time-sheet/unlock", { data: ids });
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("timesheets-unlocked-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err, t("could-not-unlock-timesheets")));
			}
		},
		[authPut, t]
	);

	const lockUsers = useCallback(
		async (ids, filters, onSuccess, onFailure) => {
			const loading = toast.loading(t("user-locking-timesheet", { count: ids.length }));
			try {
				const response = await authPut("/time-sheet/user/lock", {
					data: ids,
					params: formatTimeSheetParams(filters),
				});
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("user-timesheet-locked-successfully"));
				onSuccess?.(response);
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err, t("could-not-lock-user-timesheet")));
				onFailure?.(err);
			}
		},
		[authPut, t]
	);

	const unlockUsers = useCallback(
		async (ids, filters, onSuccess, onFailure) => {
			const loading = toast.loading(t("unlocking-user-timesheets", { count: ids.length }));
			try {
				const response = await authPut("/time-sheet/user/unlock", {
					data: ids,
					params: formatTimeSheetParams(filters),
				});
				await onSuccess?.(response);
				toast.dismiss(loading);
				toast.success(t("user-timesheets-unlocked-successfully"));
			} catch (err) {
				await onFailure?.(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err, t("could-not-unlock-user-timesheets")));
			}
		},
		[authPut, t]
	);

	return { lock, lockMany, unlock, unlockMany, lockUsers, unlockUsers };
};
