import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Select from "../../../../../components/Inputs/Select";
import {getSettingsResourceSearchFilters, renderSettingsResourceLabel} from "../../../../../utils/helpers/settings";
import {useUser} from "../../../../../utils/hooks/user";
import { useDateFilters } from "../../../hooks/useDateFilters";

export const PayGroupDateFilters = () => {
    const {t} = useTranslation();
    const user = useUser();
    const {payGroup, payGroupPeriod, setFilters} = useDateFilters();

    const onPayGroupChange = useCallback(({ code }) => {
        setFilters({ payGroup: code });
    }, [setFilters]);

    const onPeriodChange = useCallback((value) => {
        setFilters({ payGroupPeriod: value });
    }, [setFilters]);

    return (
        <Row>
            <Col>
                {false ? (
                    <Select
                        placeholder={t("Pay group")}
                        value={payGroup}
                        onChange={onPayGroupChange}
                        disabled
                    >
                        {user?.payGroup && (
                            <Select.Option value={user?.payGroup?.id}>
                                {renderSettingsResourceLabel(user?.payGroup)}
                            </Select.Option>
                        )}
                    </Select>
                ) : (
                    <ResourceSelect
                        resourcePath="/pay-group?pagination=off"
                        placeholder={t("Pay group")}
                        renderLabel={renderSettingsResourceLabel}
                        hasSearch
                        getSearchFilters={getSettingsResourceSearchFilters}
                        value={payGroup}
                        onChange={onPayGroupChange}
                    />
                )}
            </Col>

            <Col>
                <Select placeholder={t("Period")} value={payGroupPeriod} onChange={onPeriodChange}>
                    <Select.Option value="past">
                        {t("Past")}
                    </Select.Option>

                    <Select.Option value="current">
                        {t("Current")}
                    </Select.Option>

                    <Select.Option value="next">
                        {t("Next")}
                    </Select.Option>
                </Select>
            </Col>
        </Row>
    );
}

export default PayGroupDateFilters;
