import { useCallback } from "react";
import ImportModal from "../../../components/Modal/ImportModal";
import { useVisible } from "../../../utils/hooks/useVisible";

export const useImportModal = ({
    importUrl,
    exportUrl,
    setData,
    exportCall,
    loading,
}) => {
    const { open, visible, close } = useVisible();

	const onDownloadSample = useCallback(() => {
		exportCall(exportUrl, { defaultData: true });
	}, [exportCall]);

	const onSuccess = useCallback(
		(response) => {
            setData((prev) => prev.concat(response));
			close();
		},
		[setData, close],
	);

    const modal = (
        <ImportModal
            isOpen={visible}
            close={close}
            url={importUrl}
            onSuccess={onSuccess}
            onDownloadSample={onDownloadSample}
            loading={loading}
        />
    );

    return { open, modal };
}
