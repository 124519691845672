import {
	Card,
	CardHeader,
	CardBody,
	CardText,
	CardTitle,
	CardFooter,
	CardLink,
} from "reactstrap";
import { useTranslation } from "react-i18next";

function AuthCard({
	cardTitle,
	cardText,
	cardBody,
	cardFooter,
	link,
	linkText,
	className,
}) {
	const { t } = useTranslation();

	return (
		<Card className="shadow-none bg-transparent mt-7 mx-auto">
			<CardHeader
				className={`bg-transparent pb-2 border-bottom-0 ${className}`}
			>
				<CardTitle className="h3 font-weight-bolder mb-1">
					{t(cardTitle)}
				</CardTitle>

				<CardText className="mb-2">{t(cardText)}</CardText>
			</CardHeader>

			<CardBody className="border-bottom-0">{cardBody}</CardBody>

			{link &&
				<CardFooter className="text-center pt-0 px-lg-2 px-1 d-flex justify-content-center border-top-0 bg-transparent">
					<CardText className="text-sm m-1 mb-4">
						{t(cardFooter)}
						<CardLink
							href={link}
							className="mx-1 text-sm text-primary text-gradient font-weight-bold"
						>
							{t(linkText)}
						</CardLink>
					</CardText>
				</CardFooter>
			}
		</Card>
	);
}

export default AuthCard;
