import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormElement, { Field as BaseField } from "rc-field-form";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Place from "./place";
import ManageByLocation from "./ManageByLocation";
import ManageByOrgLevel from "./ManageByOrgLevel";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import UploadInput from "./UploadInput";
import ManageAbilitySelect from "./ManageAbilitySelect";
import Frequency from "./frequency";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
    useCompany,
    useIsMasterCompany,
} from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";
import {
    renderSettingsResourceLabel,
    getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { useUpload } from "./UploadInput/useUpload";
import { getUserSearchFilters } from "../../../../Profile/Personal/Actions/ChangeManagement";

const getUserName = (user) => {
    return `${user.firstName} ${user.middleName ? `${user.middleName} ` : ""}${user.lastName}`
};

function Form({ mode, values, error, loading, submit, close }) {
    const { t, i18n } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const company = useCompany();
    const { upload } = useUpload();
    const { access } = useModuleAccess("settings.general.employeeTypes");
    const disabled = useIsFieldDisabled({ ...access, mode });
    const [locations, setLocations] = useState(values?.location || []);
    const [selectedImage, setSelectedImage] = useState(values?.pages);
    const [language, setLanguage] = useState(i18n.language);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [imagesError, setImagesError] = useState(null);

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback(
        async (formValues) => {
            if (Object?.values(selectedImage || [])?.flat()?.length === 0) {
                setImagesError(t("upload-at-least-an-image"));
            } else if (company && Object?.values(selectedImage)?.flat()?.length > 0) {
                let pages;
                const languages = Object?.keys(selectedImage);
                const imagesToUpload = languages?.reduce((total, key) => {
                    const images = selectedImage?.[key]
                        ?.filter((img) => typeof img !== "string");

                    if (images?.length > 0) {
                        total = {
                            ...total,
                            [key]: images
                        }
                    }
                    return total;
                }, {});
                setImagesError(null);
                if (Object?.keys(imagesToUpload)?.length > 0) {
                    setLoadingUpload(true);
                    try {
                        const res = await Promise.all(languages?.map((key) => {
                            if (!imagesToUpload?.[key]) {
                                return;
                            }
                            return upload(imagesToUpload[key], formValues?.code, key, company)
                        }));

                        if (res) {
                            languages?.map((key, i) => {
                                if (mode === "create") {
                                    pages = {
                                        ...pages,
                                        [key]: res[i]?.map(({ path }) => path)
                                    }
                                } else if ((mode === "edit" || mode === "clone") && res[i]) {
                                    if (values?.pages[key]?.length > 0) {
                                        pages = {
                                            ...pages,
                                            ...values?.pages,
                                            [key]: [
                                                ...values?.pages[key],
                                                ...res[i]?.map(({ path }) => path)
                                            ]
                                        }
                                    } else {
                                        pages = {
                                            ...pages,
                                            [key]: res[i]?.map(({ path }) => path)
                                        }
                                    }
                                } else if ((mode === "edit" || mode === "clone") && !res[i]) {
                                    pages = {
                                        ...values?.pages,
                                        ...pages
                                    }
                                }
                            })
                        }
                    } catch (err) {
                        toast.error(t("upload-failed"))
                    } finally {
                        setLoadingUpload(false);
                    }
                } else {
                    pages = selectedImage;
                }

                const data = {
                    ...formValues,
                    date: moment(formValues?.date).format("YYYY-MM-DD"),
                    manageAbility: formValues?.manageAbility,
                    companies: formValues?.companies?.map((value) => value?.id) || [],
                    locations: formValues?.locations?.map((value) => value?.id) || [],
                    managedLevels: formValues?.managedLevels?.map((value) => value?.id) || [],
                    payGroups: formValues?.payGroups?.map((value) => value?.id) || [],
                    projects: formValues?.projects?.map((value) => value?.id) || [],
                    users: formValues?.users?.map((value) => value?.id) || [],
                    unions: formValues?.unions?.map((value) => value?.id) || [],
                    employeeTypes: formValues?.employeeTypes?.map((value) => value?.id) || [],
                    userGroups: formValues?.userGroups?.map((value) => value?.id) || [],
                    defaultLanguage: company?.settings?.language,
                    pages,
                };
                submit(data);
            }
        }, [mode, submit, company, selectedImage, values?.pages, setLoadingUpload, setImagesError, t]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            status: "active",
            ...values,
            date: values?.date ? moment(values?.date).toDate() : undefined,
            languages: values?.languages ? values?.languages : [i18n.language]
        });
    }, [form, values, i18n.language]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["code", "description", "status", "place", "frequency", "pages"],
            error
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement
            form={form}
            className="d-flex flex-column justify-content-between h-100"
            onFinish={onFinish}
        >
            <BaseSettingsFormContent mode={mode} />

            <Place />

            <Frequency />

            <ManageAbilitySelect
                form={form}
                values={values}
                setLocations={setLocations}
                disabled={disabled}
            />

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const manageAbility = getFieldValue("manageAbility");

                    const isCustom = manageAbility === "custom";

                    const managedByUsers = [ "by-users", "custom" ].includes(manageAbility);

                    const managedByUserGroups = manageAbility === "by-user-groups";

                    const manageByCompany = [
                        "by-company",
                        "by-company-and-location",
                        "by-company-and-organisation-level",
                        "by-company-and-location-and-organisation-level",
                        "by-company-and-pay-groups",
                        "custom"
                    ].includes(manageAbility);

                    const manageByLocation = [
                        "by-location",
                        "by-company-and-location",
                        "by-company-and-location-and-organisation-level",
                        "custom"
                    ].includes(manageAbility);

                    const manageByOrgLevel = [
                        "by-organisation-level",
                        "by-company-and-organisation-level",
                        "by-company-and-location-and-organisation-level",
                        "custom"
                    ].includes(manageAbility);

                    const manageByPayGroups = [
                        "by-pay-groups",
                        "by-company-and-pay-groups",
                        "by-pay-group-and-employee-type",
                        "by-projects-and-pay-groups",
                        "custom"
                    ].includes(manageAbility);

                    const manageByEmployeeType = [
                        "by-pay-group-and-employee-type", 
                        "custom"
                    ].includes(manageAbility);

                    const manageByProjects =[
                        "by-projects-and-pay-groups",
                        "custom"
                    ].includes(manageAbility);

                    const manageByUnions =[ "by-unions", "custom" ].includes(manageAbility);

                    return (
                        <>
                            {manageByCompany && (
                                <Field
                                    name="companies"
                                    label={t("companies")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-companies"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="name"
                                        resourcePath="/companies"
                                        placeholder={t("select-companies")}
                                        hasSearch
                                        getSearchFilters={getCompaniesSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {managedByUsers && (
                                <Field
                                    name="users"
                                    label={t("users")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-users"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="name"
                                        resourcePath="/users"
                                        placeholder={t("users")}
                                        renderLabel={getUserName}
                                        hasSearch
                                        getSearchFilters={getUserSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByLocation && (
                                <ManageByLocation
                                    setLocations={setLocations}
                                    isCustom={isCustom}
                                    disabled={disabled}
                                />
                            )}

                            {manageByOrgLevel && (
                                <ManageByOrgLevel
                                    locations={locations}
                                    manageAbility={manageAbility}
                                    disabled={disabled}
                                />
                            )}

                            {manageByPayGroups && (
                                <Field
                                    name="payGroups"
                                    label={t("pay-groups")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-pay-groups"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/pay-group"
                                        placeholder={t("Select pay groups")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByProjects && (
                                <Field
                                    name="projects"
                                    label={t("projects")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-projects"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/project"
                                        placeholder={t("projects")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByEmployeeType && (
                                <Field
                                    name="employeeTypes"
                                    label={t("employee-types")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-employee-types"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/employee-type"
                                        placeholder={t("employee-types")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {manageByUnions && (
                                <Field
                                    name="unions"
                                    label={t("unions")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-unions"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/union"
                                        placeholder={t("unions")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )}

                            {managedByUserGroups && (
                                <Field
                                    name="userGroups"
                                    label={t("user-groups")}
                                    rules={
                                        !isCustom && [
                                            {
                                                required: true,
                                                message: t("required-user-groups"),
                                            },
                                        ]
                                    }
                                >
                                    <ResourceSelect
                                        labelPropName="description"
                                        resourcePath="/user-group?pagination=false"
                                        placeholder={t("User groups")}
                                        renderLabel={renderSettingsResourceLabel}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        mode="multiple"
                                        disabled={disabled}
                                    />
                                </Field>
                            )} 
                        </>
                    );
                }}
            </BaseField>

            <UploadInput
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                language={language}
                setLanguage={setLanguage}
            />

            {imagesError !== null && (
                <span className="invalid-feedback d-block">
                    {imagesError}
                </span>
            )}

            <div className="d-flex justify-content-end mt-3">
                <Button
                    onClick={onClose}
                    disabled={loading || loadingUpload}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {(!disabled || isMasterCompany) && (
                    <Button
                        type="submit"
                        className="btn-dark btn-sm shadow-none"
                        loading={loading || loadingUpload}
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
