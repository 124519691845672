import { useMemo } from "react";

export const useQueryFilters = (table) => {
	const {
		pagination: { pageIndex, pageSize },
		sorting,
		columnFilters
	} = table.getState();
	return useMemo(() => {
		return {
			pageIndex,
			pageSize,
			sortBy: sorting,
			filters: columnFilters
				.filter(( filter ) => {
					if (Array.isArray(filter.value) && filter.value.length === 0) {
						return false;
					}
					if (filter.value === "") {
						return false;
					}
					return true;
				}),
		};
	}, [pageIndex, pageSize, sorting, columnFilters]);
}
