import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SimpleTable from "../../../../../../components/SimpleTable";
import { currencyFormatter } from "../../../../../../utils/helpers/currencyFormatter";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { getHourTypeColor } from "../../../../../../utils/helpers/hourType";
import {
	useCompanyCurrency,
	useCompanyTimeFormat,
} from "../../../../../../utils/hooks/company";

const columnHelper = createColumnHelper();

const Table = ({ data }) => {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
	const currency = useCompanyCurrency();

	const sortedData = useMemo(() => data.sort((a, b) => {
		if (a.total === b.total) {
			return b.duration - a.duration;
		}
		else {
			return b.total - a.total;
		}
	}), [data])

	const columns = useMemo(
		() => [
			columnHelper.accessor("payCode", {
				header: t("code"),
				// TODO: check these ones out. width: "50%",
				// TODO: check these ones out. minWidth: "50%",
				cell: (info) => {
					const value = info.getValue();
					const color = getHourTypeColor(value.hourType);
					return (
						<span className="font-weight-bold" style={{ color }}>
							{value.code}
						</span>
					);
				},
				footer: <span className="font-weight-bold">{t("total")}</span>,
			}),
			columnHelper.accessor("duration", {
				header: t("hours"),
				// TODO: check these one out. width: "25%",
				cell: (info) => {
					const value = info.getValue();
					if (value === undefined) {
						return null;
					}

					return (
						<span>
							{renderDurationAsFormat(value, timeFormat)}
						</span>
					);
				},
				footer: ({ table }) => {
					const rows = table.getCoreRowModel().rows;
					const value = rows.reduce((total, row) => {
						return total + row.original.duration;
					}, 0);
					return (
						<span className="font-weight-bold">
							{renderDurationAsFormat(value, timeFormat)}
						</span>
					);
				},
			}),
			columnHelper.accessor("total", {
				header: t("total"),
				// TODO: check this one later. width: "25%",
				cell: (info) => {
					const value = info.getValue();
					if (value === undefined) {
						return null;
					}
					return (
						<span>
							{currencyFormatter(value || 0, 2, currency)}
						</span>
					);
				},
				footer: ({ table }) => {
					const rows = table.getCoreRowModel().rows;
					const value = rows.reduce((total, row) => {
						return total + row.original.total;
					}, 0);
					return (
						<span className="font-weight-bold">
							{currencyFormatter(value, 2, currency)}
						</span>
					);
				},
			}),
		],
		[t, timeFormat, currency],
	);

	return <SimpleTable columns={columns} data={sortedData} />;
};

export default Table;
