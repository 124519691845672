import classNames from "classnames";
import { Input } from "reactstrap";
import Field from "../../Field";
import "./style.scss";

const defaultFileTypes = ["image/jpeg", "image/png"];
const defaultFileSizeLimit = 2;

function UploadInput({
	fileTypes = defaultFileTypes,
	fileSizeLimit = defaultFileSizeLimit,
	label = " ",
	onChangeUrl,
	onChange,
	loading,
	filePreview,
	insertText,
	className,
	children,
	name,
	inputClassname,
	...props
}) {
	return (
		<Field name={name} label={label} className={className}>
			<Input
				{...props}
				type="file"
				className={classNames(
					inputClassname ? inputClassname : "form-control",
				)}
				multiple={props.multiple}
				onChange={onChange}
			/>
		</Field>
	);
}

export default UploadInput;
