import { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Option } from "rc-select";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Select from "../../../components/Inputs/Select";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import {
	renderSettingsResourceLabel,
	getSettingsOrgResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../utils/helpers/settings";
import { renderUserName } from "../../../utils/helpers/user";
import { dateTimeFormat } from "../../../utils/helpers/date";
import {createColumnHelper} from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ approve, deny, view }) => {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
    const dateFormat = useCompanyDateFormat();
    const timeFormat = useCompanyTimeFormat();

	const columns = useMemo(() => {
		const cols = [
			columnHelper.accessor("startDate", {
				enableColumnFilter: true,
				header: t("start-date"),
				cell: (info) => {
                    const { data } = info.row.original;
					return dateTimeFormat(data?.startDate, dateFormat);
                },
			}),
			columnHelper.accessor("startTime", {
				header: t("start-time"),
				cell: (info) => {
                    const { data } = info.row.original;
					return dateTimeFormat(data?.startDate, timeFormat);
                },
			}),
			columnHelper.accessor("endDate", {
				header: t("end-date"),
				cell: (info) => {
                    const { data } = info.row.original;
					return dateTimeFormat(data?.endDate, dateFormat);
                },
			}),
			columnHelper.accessor("endTime", {
				header: t("end-time"),
				cell: (info) => {
                    const { data } = info.row.original;
					return dateTimeFormat(data?.endDate, timeFormat);
                },
			}),
			columnHelper.accessor("type", {
				enableColumnFilter: true,
				header: t("type"),
				cell: (info) => t(info.getValue()),
				filterType: "select",
				Filter: (
					<Select showSearch>
						<Option value="shift">{t("shift")}</Option>

						<Option value="absence">{t("absence")}</Option>

						<Option value="edit-shift">{t("edit-shift")}</Option>

						<Option value="edit-timesheet">
							{t("edit-timesheet")}
						</Option>

						<Option value="schedule">
							{t("schedule")}
						</Option>

						<Option value="edit-schedule">
							{t("edit-schedule")}
						</Option>

						<Option value="submit-schedule">
							{t("submit-schedule")}
						</Option>
					</Select>
				),
			}),
			columnHelper.accessor("user.employeeNumber", {
				enableColumnFilter: true,
				header: t("employee-number"),
			}),
			columnHelper.accessor("user", {
				enableColumnFilter: true,
				header: t("employee-name"),
				cell: (info) => {
                    const value = info.getValue();
                    return value && renderUserName(value);
                },
			}),
			columnHelper.accessor("data.location", {
				enableColumnFilter: true,
				header: t("location"),
				cell: (info) => {
                    const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/locations"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			}),
			columnHelper.accessor("data.job", {
				enableColumnFilter: true,
				header: t("job"),
				cell: (info) => {
                    const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/jobs"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			}),
			columnHelper.accessor("status", {
				enableColumnFilter: true,
				header: t("status"),
				cell: (info) => {
                    const value = info.getValue();
					let color;
					switch (value) {
						case "pending": {
							color = "orange";
							break;
						}
						case "approved": {
							color = "green";
							break;
						}
						case "denied": {
							color = "red";
							break;
						}
						default:
							break;
					}
					return (
						<span
							className="text-center px-3 py-1"
							style={{
								border: `1px solid ${color}`,
								color: color,
								borderRadius: "20px",
							}}
						>
							{t(value)}
						</span>
					);
				},
				filterType: "select",
				Filter: (
					<Select>
						<Option value="approved">{t("approved")}</Option>

						<Option value="denied">{t("denied")}</Option>

						<Option value="pending">{t("pending")}</Option>
					</Select>
				),
			}),
		];

		if (groups) {
            const groupsColumns = groups.map((group) => {
                const level = group.level.replace("_", "");
                return columnHelper.accessor(level, {
					enableColumnFilter: true,
					header: group.description,
					cell: (info) => {
                        const value = info.getValue();
						return value && renderOrgLevelResourceLabel(value);
                    },
					filterType: "select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath={`/${group?.id}/org-levels`}
							mode="multiple"
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							valuePropName="id"
							hasSearch
						/>
					),
                });
            });
			cols.splice(6, 0, ...groupsColumns);
		}

		if (approve || deny || view) {
            const actionsColumn = columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableColumnFilter: false,
                header: () => (
                    <div className="text-right">
                        {t("actions")}
                    </div>
                ),
                cell: (info) => {
                    const { id, status } = info.row.original;
                    if (status !== "pending") {
                        return null;
                    }
                    return (
                        <div
                            className={classNames(
                                "flex items-center justify-end gap-x-2",
                            )}
                        >
                            {approve && (
                                <i
                                    className="fa-solid fa-check text-green cursor-pointer"
                                    onClick={() => approve(id)}
                                    style={{ fontSize: "17px" }}
                                />
                            )}

                            {deny && (
                                <i
                                    className="fa-solid fa-xmark text-danger cursor-pointer mx-2"
                                    onClick={() => deny(id)}
                                    style={{ fontSize: "17px" }}
                                />
                            )}

							{view && (
								<i
									className="fa-solid fa-eye text-dark cursor-pointer"
									onClick={() => view(info.row.original)}
									style={{ fontSize: "17px" }}
								/>
							)}
                        </div>
                    );
                },
            });
			cols.push(actionsColumn);
		}

		return cols;
	}, [t, dateFormat, timeFormat, groups, approve, deny, view]);

	return columns;
};
