import { useContext, useMemo } from "react";
import { Row, Col } from "reactstrap";
import Group from "./Group";
import { OrgLevelGroupsContext } from "../../../../Settings/General/OrganisationLevelGroups/context";

function OrganisationLevels() {
	const { groups } = useContext(OrgLevelGroupsContext);

	const organisationLevelGroups = useMemo(() => {
		if (groups && groups.length > 0) {
			return [...groups].sort((a, b) => {
				if (a.isRequired === b.isRequired) {
					return 0;
				}
				return a.isRequired ? -1 : 1;
			});
		}
		return [];
	}, [groups]);

	const content = useMemo(
		() => (
			<Row>
				{organisationLevelGroups.map((group) => (
					<Col sm={12} md={6} key={group.id}>
						<Group
							name={`${group.level}`.replace("_", "")}
							group={group}
						/>
					</Col>
				))}
			</Row>
		),
		[organisationLevelGroups]
	);

	return content;
}

export default OrganisationLevels;
