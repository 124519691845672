import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { toast } from "react-toastify";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Select from "../../../../components/Inputs/Select";
import LanguageSelect from "../../../../components/Inputs/LanguageSelect";
import CustomTimeInput from "../../../../components/Inputs/CustomTimeInput";
import TimeFormatSelect from "../../../../components/Inputs/TimeFormatSelect";
import DateFormatSelect from "../../../../components/Inputs/DateFormatSelect";
import CurrencySelect from "../../../../components/Inputs/CurrencySelect";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import WeekdaySelect from "../../../../components/Inputs/WeekdaySelect";
import UploadInput from "../../../../components/Inputs/Upload";
import { useIsMasterCompany } from "../../../../utils/hooks/user";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { getUploadUrl } from "../../../../utils/helpers/upload";

const modules = [
  "time-tracking",
  "scheduling",
  "checklists-forms",
  "communication-engagement",
  "task-management",
  "employee-training",
  "knowledge-base",
];

const fileTypes = ["image/jpeg", "image/png"];
const fileSizeLimit = 2;

function FormContent({ setImages, images, form }) {
  const { t } = useTranslation();
  const isMasterCompany = useIsMasterCompany();
  const { access } = useModuleAccess("settings.general");
  const disabled = !access?.canEdit;

  const onLogoChange = useCallback(
    (info) => {
      if (
        !info.target.files[0].type ||
        !fileTypes.includes(info.target.files[0].type)
      ) {
        toast.error(t("jpg-png-file"));
        // setError(t("jpg-png-file"));
        return false;
      } else if (
        !info.target.files[0].size ||
        info.target.files[0].size > fileSizeLimit * 1024 * 1024
      ) {
        toast.error(t("image-size-rule"));
        // setError(t("image-size-rule"));
        return false;
      } else {
        setImages({
          ...images,
          logo: info.target.files[0],
        });
      }
    },
    [setImages, images, t]
  );

  const onBackgroundImageChange = useCallback(
    (info) => {
      if (
        !info.target.files[0].type ||
        !fileTypes.includes(info.target.files[0].type)
      ) {
        toast.error(t("jpg-png-file"));
        // setError(t("jpg-png-file"));
        return false;
      } else if (
        !info.target.files[0].size ||
        info.target.files[0].size > fileSizeLimit * 1024 * 1024
      ) {
        toast.error(t("image-size-rule"));
        // setError(t("image-size-rule"));
        return false;
      } else {
        setImages({
          ...images,
          backgroundImage: info.target.files[0],
        });
      }
    },
    [setImages, images, t]
  );

  const deleteLogo = useCallback(() => {
    form.setFieldsValue({ ...images, logo: null });
    setImages({
      ...images,
      logo: null,
    });
  }, [setImages, images, form]);

  const logoPreview = useMemo(() => {
    if (!images?.logo) {
      return null;
    }
    if (typeof images?.logo === "string") {
      return getUploadUrl(images?.logo);
    } else {
      return URL.createObjectURL(images?.logo);
    }
  }, [images?.logo]);

  const deleteBackgroundImage = useCallback(() => {
    form.setFieldsValue({ ...images, backgroundImage: null });
    setImages({
      ...images,
      backgroundImage: null,
    });
  }, [setImages, images, form]);

  const backgroundImagePreview = useMemo(() => {
    if (!images?.backgroundImage) {
      return null;
    }
    if (typeof images?.backgroundImage === "string") {
      return getUploadUrl(images?.backgroundImage);
    } else {
      return URL.createObjectURL(images?.backgroundImage);
    }
  }, [images?.backgroundImage]);

  return (
    <>
      <Field
        label={t("employee-number-length")}
        name="employeeNumberLength"
        rules={[
          {
            required: true,
            message: t("required-employee-number-length"),
          },
        ]}
      >
        <Text
          type="number"
          placeholder={t("employee-number-length")}
          min={4}
          max={10}
          disabled={!isMasterCompany}
        />
      </Field>

      <Field
        label={t("default-employee-number")}
        name="defaultEmployeeNumber"
        rules={[
          {
            required: true,
            message: t("required-default-employee-number"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const requiredLength = getFieldValue("employeeNumberLength");

              if (
                requiredLength &&
                value &&
                value.length !== Number(requiredLength)
              ) {
                return Promise.reject(
                  new Error(
                    t("default-employee-number-length", {
                      length: requiredLength,
                    })
                  )
                );
              }

              return Promise.resolve(true);
            },
          }),
        ]}
      >
        <Text
          placeholder={t("default-employee-number")}
          disabled={!isMasterCompany}
        />
      </Field>

      <Field
        label={t("badgeid-length")}
        name="badgeIdLength"
        rules={[
          {
            required: true,
            message: t("required-badgeid-length"),
          },
        ]}
      >
        <Text
          type="number"
          placeholder={t("badgeid-length")}
          min={4}
          max={10}
          disabled={!isMasterCompany}
        />
      </Field>

      <Field
        label={t("incorporation-countries")}
        name={["settings", "countries"]}
        rules={[
          {
            required: true,
            message: t("required-companies"),
          },
        ]}
      >
        <ResourceSelect
          labelPropName="name"
          resourcePath="/countries?pagination=false"
          hasCompany={false}
          placeholder={t("incorporation-countries")}
          mode="multiple"
          disabled={disabled}
          hasSearch
        />
      </Field>

      <Field
        label={t("default-language")}
        name={["settings", "language"]}
        rules={[
          {
            required: true,
            message: t("required-language"),
          },
        ]}
      >
        <LanguageSelect
          placeholder={t("default-language")}
          disabled={disabled}
        />
      </Field>

      <Field
        label={t("company-name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("required-company-name"),
          },
        ]}
      >
        <Text placeholder={t("company-name")} disabled={disabled} />
      </Field>

      <Field
        label={t("timezone")}
        name={["settings", "timezone"]}
        rules={[
          {
            required: true,
            message: t("required-timezone"),
          },
        ]}
      >
        <ResourceSelect
          labelPropName="text"
          resourcePath="/timezone?pagination=false"
          hasCompany={false}
          hasSearch
          placeholder={t("timezone")}
          disabled={disabled}
        />
      </Field>

      <Field
        label={t("time-format")}
        name={["settings", "timeFormat"]}
        rules={[
          {
            required: true,
            message: t("required-time-format"),
          },
        ]}
      >
        <TimeFormatSelect placeholder={t("time-format")} disabled={disabled} />
      </Field>

      <Field
        name={["settings", "dateFormat"]}
        label={t("date-format")}
        rules={[
          {
            required: true,
            message: t("required-date-format"),
          },
        ]}
      >
        <DateFormatSelect placeholder={t("date-format")} disabled={disabled} />
      </Field>

      <WeekdaySelect
        name={["settings", "startDay"]}
        label={t("start-day")}
        rules={[
          {
            required: true,
            message: t("required-start-day"),
          },
        ]}
        placeholder={t("start-day")}
        disabled={disabled}
      />

      <Field
        name={["settings", "currency"]}
        label={t("currency")}
        rules={[
          {
            required: true,
            message: t("required-currency"),
          },
        ]}
      >
        <CurrencySelect placeholder={t("currency")} disabled={disabled} />
      </Field>

      <Field
        name={["settings", "maxShiftLength"]}
        label={t("max-shift-length")}
        rules={[
          {
            required: true,
            message: t("required-shift-length"),
          },
          {
            validator(_, value) {
              if (Number(value) < 0) {
                return Promise.reject(new Error(t("shift-length-pattern")));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Text type="number" placeholder={t("max-shift-length")} />
      </Field>

      <div className="d-flex align-items-center justify-content-between gap-2 w-100">
        <Field
          className="w-50"
          name={["settings", "schedule", "shiftStart"]}
          label={t("shift-start")}
          rules={[
            {
              required: true,
              message: t("required-shift-start")
            },
          ]}
        >
          <CustomTimeInput />
        </Field>

        <Field
          className="w-50"
          name={["settings", "schedule", "shiftEnd"]}
          label={t("shift-end")}
          rules={[
            {
              required: true,
              message: t("required-shift-end")
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const shiftStart = getFieldValue(
                  ["settings", "schedule", "shiftStart"]
                );
                
                if (value && moment(value).isSameOrBefore(moment(shiftStart))) {
                  return Promise.reject(
                    new Error(t("shift-end-bigger-than-shift-start"))
                  )
                }
                return Promise.resolve();
              }
           })
          ]}
        >
          <CustomTimeInput />
        </Field>
      </div>

      <Field
        name={["settings", "timeDifference"]}
        label={t("time-difference")}
        rules={[
          {
            required: true,
            message: t("required-time-difference"),
          },
          {
            validator(_, value) {
              if (Number(value) < 0) {
                return Promise.reject(new Error(t("time-difference-pattern")));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Text type="number" placeholder={t("time-difference")} />
      </Field>

      <Field
        name={["settings", "modules"]}
        label={t("modules")}
        rules={[
          {
            required: true,
            message: t("required-modules"),
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={t("modules")}
          disabled={disabled || !isMasterCompany}
        >
          {modules.map((module) => (
            <Option key={module} value={_.camelCase(module)}>
              {t(module)}
            </Option>
          ))}
        </Select>
      </Field>

      <Field
        name={"executivePayrollEmailAddress"}
        label={t("executive-payroll-email")}
        rules={[
          {
            required: true,
            message: t("required-executive-payroll-email"),
          },
        ]}
      >
        <Text
          type="email"
          placeholder={t("executive-payroll-email")}
          disabled={disabled}
        />
      </Field>

      <div className="d-flex w-100 image-upload-input">
        <div className="w-50 mr-1">
          {logoPreview ? (
            <div className="d-flex flex-column">
              <span className="logo-label">{t("logo")}</span>

              <div className="border rounded px-3 py-2 d-flex justify-content-between align-items-center">
                <img alt="" src={logoPreview} className="logo-image" />

                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={deleteLogo}
                  className="cursor-pointer text-red ml-5"
                />
              </div>
            </div>
          ) : (
            <UploadInput
              name="logo"
              label={t("logo")}
              onChange={onLogoChange}
            />
          )}
        </div>

        <div className="w-50 ml-1">
          {backgroundImagePreview ? (
            <div className="d-flex flex-column">
              <span className="logo-label">{t("background-image")}</span>

              <div className="border rounded px-3 py-2 d-flex justify-content-between align-items-center">
                <img
                  alt=""
                  src={backgroundImagePreview}
                  className="logo-image"
                />

                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={deleteBackgroundImage}
                  className="cursor-pointer text-red ml-5"
                />
              </div>
            </div>
          ) : (
            <UploadInput
              name="backgroundImage"
              label={t("background-image")}
              onChange={onBackgroundImageChange}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default FormContent;
