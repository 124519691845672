import React from "react";
import Field from "../../../../../components/Field";
import { useTranslation } from "react-i18next";
import WeekdaySelect from "../../../../../components/Inputs/WeekdaySelect";
import Select from "../../../../../components/Inputs/Select";
import DateInput from "../../../../../components/Inputs/DateInput";
import { Field as BaseField } from "rc-field-form";

const frequencyOptions = ["once", "daily", "weekly", "one-time-per-user"/**, "biweekly" */];

const Frequency = () => {
    const { t } = useTranslation();

    return (
        <>
            <Field
                name="frequency"
                label={t("frequency")}
                rules={[
                    {
                        required: true,
                        message: t("required-frequency"),
                    },
                ]}
            >
                <Select placeholder={t("frequency")}>
                    {frequencyOptions.map((value) => {
                        return (
                            <Select.Option key={value} value={value}>
                                {t(value)}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Field>
            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue }) => {
                    const frequency = getFieldValue("frequency");

                    return (
                        <>
                            {frequency === "once" && (
                                <Field
                                    name="date"
                                    label={t("once")}
                                    className="d-flex flex-column"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-date"),
                                        },
                                    ]}
                                >
                                    <DateInput />
                                </Field>
                            )}

                            {frequency === "weekly" && (
                                <Field
                                    name="day"
                                    label={t("weekly")}
                                    className="d-flex flex-column"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-date"),
                                        },
                                    ]}
                                >
                                    <WeekdaySelect />
                                </Field>
                            )}
                            {/* {biweeklyFrequency && (
                                <Field
                                name="day"
                                label={t("biweekly")}
                                className="d-flex flex-column"
                                rules={[
                                    {
                                    required: true,
                                    message: t("required-date"),
                                    },
                                ]}
                                >
                                <WeekdaySelect />
                                </Field>
                            )} */}
                        </>
                    );
                }}
            </BaseField>
        </>
    );
};

export default Frequency;
