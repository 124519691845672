import { useTranslation } from "react-i18next";
import AppLoader from "../../components/Loaders/AppLoader";
import { useConfiguration } from "../Settings/Configuration/api/useConfiguration";
import { useUserPreferences } from "../Settings/Configuration/api/useUserPreferences";

function ConfigurationLoader({ children }) {
    const { t } = useTranslation();
    const { hasFetched: hasFetchedConfiguration } = useConfiguration();
    const { hasFetched: hasFetchedUserPreferences } = useUserPreferences();

    if (!hasFetchedConfiguration || !hasFetchedUserPreferences) {
        return (
            <AppLoader>
                {t("loading-configuration")}
            </AppLoader>
        );
    }

    return children;
}

export default ConfigurationLoader;
