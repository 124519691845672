import moment from "moment";

export const calculateRate = (val) => {
  if (val?.hourType !== 'amount') {
    if (val?.hourType === 'unpaid') {
      val.rateChangeManually = false;
      val.rate = 0;
    } else {
      val.rate = 0;
      val.rateChangeManually = false;
      if (val.payCode && val.payCode.defaultRate) {
        val.rate = val.payCode.defaultRate;
      } else if (val.job && val.job.hourlyRate) {
        val.rate = val.job?.hourlyRate || 0;
      }
    }
  } else {
    val.rateChangeManually = false;
  }
  val.rate = val.rate || 0;
  val.regRate = val.rate;
  val.rate = val.rate * (val.payCode && val.payCode.multiplier ? val.payCode.multiplier : 1);

  return val;
}

export const calculateTotal = (val) => {
  if (val?.hourType !== 'amount') {
    val.total = val.duration && val.duration > 0 ? ((val.duration / 3600) * val.rate) : 0;
  } else {
    val.total = val.rate;
  }
  return val;
}

export const calculateDuration = (val) => {
  if (val?.hourType !== 'unit') {
    if (val?.hourType !== 'amount') {
      let start = val.start;
      if (start > 30) {
        start = moment.unix(start); //.add(1, 'seconds');
      }
    
      if (val.end) {
        let end = val.end;
        if (end > 30) {
          end = moment.unix(end); //.add(1, 'seconds');
        }
        val.end = moment(end).set("milliseconds", 0).set("seconds", 0).unix()
        val.duration = end.diff(start, 'seconds');
      } else {
        val.duration = 0;
      }

      val.start = moment(start).set("milliseconds", 0).set("seconds", 0).unix();
    } else {
      val.duration = 0;
      val.start = moment.tz(val.timezone).startOf('day').unix();
      val.end = moment.tz(val.timezone).startOf('day').unix();
    }
  } 
  return val;
}

export const calculateOverNight = (val) => {
  val.overnight = '';
  if (val.end) {
    const start = moment.unix(val.start);
    const end = moment.unix(val.end);
    if (end.diff(start, 'days') > 0) {
      val.overnight = `+${end.diff(start, 'days')}`;
    }
  }
  return val;
}

export const makeCalculations = (val) => {
  val.hourType = val?.hourType || val?.payCode?.hourType || 'regular';
  val.timezone = val?.timezone || val?.location?.timezoneValue;
  val = calculateDuration(val);
  val = calculateRate(val);
  val = calculateTotal(val);
  val = calculateOverNight(val);

  return val;
}