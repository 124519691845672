import {
	useEffect,
	useMemo,
	useCallback,
	createContext,
	useContext,
} from "react";
import moment from "moment-timezone";
import { useSearchParams } from "react-router-dom";
import {
	getStartDateFromEndDate,
	getEndDateFromStartDate,
} from "../../Settings/PayPolicies/PayGroup/Form/helpers";
import { noop } from "lodash";
import { useCompanyTimeZone } from "../../../utils/hooks/company";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useAllPayGroups } from "./useAllPayGroups";

export const DateFiltersContext = createContext();

export const availableModes = ["date", "week", "month", "custom", "pay-group"];

const urlDateFormat = "YYYY-MM-DD";

const getQueryParamValue = (name, value) => {
	switch (name) {
		case "from":
		case "to": {
			return moment.parseZone(value).format(urlDateFormat);
		}
		default: {
			return value;
		}
	}
};

const formatQueryParams = (values, search, isDashboardComponent) => {
	const query = new URLSearchParams(search);
	for (const name in values) {
		const value = values[name];
		if (value === null) {
			query.delete(name);
		} else if (value !== undefined) {
			query.set(name, getQueryParamValue(name, value));
		}
	}
	if (!isDashboardComponent) {
		return query;
	}
};

export const TimeSheetTableDateFilterContext = ({ date, children }) => {
	const value = useMemo(
		() => ({
			mode: "week",
			from: date,
			to: date,
			payGroup: undefined,
			payGroupPeriod: undefined,
			setFilters: noop,
		}),
		[date],
	);

	return (
		<DateFiltersContext.Provider value={value}>
			{children}
		</DateFiltersContext.Provider>
	);
};

export const DateFiltersProvider = ({
	initialFilters: _initialFilters,
	children,
	isDashboardComponent = false,
}) => {
	const timezone = useCompanyTimeZone();
	const initialFilters = useInitialState(
		_initialFilters || {
			mode: "week",
			from: moment.tz(timezone).startOf("week").toISOString(true),
			to: moment.tz(timezone).endOf("week").toISOString(true),
			payGroup: undefined,
			payGroupPeriod: undefined,
		},
	);

	const {hasFetchedPayGroups, payGroups} = useAllPayGroups();

	const [search, setSearch] = useSearchParams();

	const {
		mode,
		from,
		to,
		payGroup: payGroupCode,
		payGroupPeriod,
	} = useMemo(() => {
		const query = new URLSearchParams(search);

		const mode = query.get("mode");
		const from = query.get("from");
		const to = query.get("to");
		const payGroup = query.get("payGroup");
		const payGroupPeriod = query.get("payGroupPeriod");

		return {
			mode: mode || undefined,
			from: from
				? moment(from, urlDateFormat).toISOString(true)
				: undefined,
			to: to ? moment(to, urlDateFormat).toISOString(true) : undefined,
			payGroup: payGroup ? payGroup : undefined,
			payGroupPeriod: payGroupPeriod ? payGroupPeriod : undefined,
		};
	}, [search]);

	const payGroup = useMemo(() => {
		return payGroups.find(({ code }) => payGroupCode === code);
	}, [payGroupCode, payGroups]);

	const setFilters = useCallback(
		(filters) => {
			if (!isDashboardComponent) {
				setSearch((prev) => {
					const updated = formatQueryParams(
						filters,
						prev.toString(),
						isDashboardComponent,
					);
					return updated.toString();
				});
			}
		},
		[setSearch, isDashboardComponent],
	);

	const resetFilters = useCallback(() => {
		setFilters(initialFilters);
	}, [setFilters, initialFilters]);

	useEffect(() => {
		if (!mode || !availableModes.includes(mode)) {
			resetFilters();
		}
	}, [mode, resetFilters]);

	useEffect(() => {
		if (mode === "pay-group" && payGroup) {
			if (hasFetchedPayGroups && !payGroup) {
				resetFilters();
			}
		}
	}, [mode, payGroup, payGroups, hasFetchedPayGroups, resetFilters]);

	useEffect(() => {
		if (mode === "pay-group") {
			if (payGroup && payGroupPeriod) {
				let { frequency, periodStartDate, periodEndDate } = payGroup;
				periodStartDate = moment
					.parseZone(periodStartDate)
					.toISOString(true);
				periodEndDate = moment
					.parseZone(periodEndDate)
					.toISOString(true);
				let period = payGroupPeriod;
				let from, to;
				switch (payGroupPeriod) {
					case "past": {
						to = moment
							.parseZone(periodStartDate)
							.subtract(1, "day")
							.toISOString(true);
						from = getStartDateFromEndDate(
							to,
							frequency,
						).toISOString(true);
						break;
					}
					case "current": {
						from = periodStartDate;
						to = periodEndDate;
						break;
					}
					case "next": {
						from = moment
							.parseZone(periodEndDate)
							.add(1, "day")
							.toISOString(true);
						to = getEndDateFromStartDate(
							from,
							frequency,
						).toISOString(true);
						break;
					}
					default: {
						period = "current";
						from = periodStartDate;
						to = periodEndDate;
						break;
					}
				}
				setFilters({ payGroupPeriod: period, from, to });
			}
		} else {
			if (payGroup || payGroupPeriod) {
				setFilters({
					payGroup: null,
					payGroupPeriod: null,
				});
			}
		}
	}, [mode, payGroup, payGroupPeriod, setFilters, timezone]);

	const value = useMemo(
		() => ({ mode, from, to, payGroup, payGroupPeriod, setFilters }),
		[mode, from, to, payGroup, payGroupPeriod, setFilters],
	);

	return (
		<DateFiltersContext.Provider value={value}>
			{children}
		</DateFiltersContext.Provider>
	);
};

export const useDateFilters = () => {
	const context = useContext(DateFiltersContext);
	if (context === undefined) {
		throw new Error("useDateFilters should be used within a provider");
	}
	return context;
};
