import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import BaseModal from "../../../../../../../../components/Modal";
import Button from "../../../../../../../../components/Button";
import Field from "../../../../../../../../components/Field";
import ObjectSelect from "../../ObjectSelect";
import { getCommonJobs, getCommonLocations } from "../../helpers";
import { getPrimaryActiveResource } from "../../../../../../../../utils/helpers/user";

function Modal({ isOpen, name, shiftName, close ,form: baseForm, resource, disabled }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const jobs = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonJobs([resource]);
    }, [resource]);

    const locations = useMemo(() => {
        if (!resource) {
            return [];
        }
        return getCommonLocations([resource]);
    }, [resource]);

    const shift = Form.useWatch(["shifts", shiftName, name], baseForm);
    const primaryJob = useMemo(() => {
		return getPrimaryActiveResource(resource?.jobs);
	}, [resource?.jobs]);

	const primaryLocation = useMemo(() => {
		return getPrimaryActiveResource(resource?.locations);
	}, [resource?.locations]);

    const onFinish = useCallback((values) => {
        const data = {
            ...shift,
            ...values?.shift
        };
        baseForm.setFieldValue(["shifts", shiftName, name], data);
        close();
    }, [name, baseForm, close, shift, shiftName]);

    useEffect(() => {
        form.setFieldsValue({
            shift: {
                ...shift,
                location: shift?.location || primaryLocation,
                job: shift?.job || primaryJob,
            }
        })
    }, [shift, form, primaryJob, primaryLocation]);

    return (
        <BaseModal
            title={t("edit-shift")}
            isOpen={isOpen}
            centered
            toggle={close}
        >
            <Form form={form} onFinish={onFinish}>
                <BaseField name={["shift", "start"]} />
                <BaseField name={["shift", "end"]}/>
                <BaseField name={["shift", "payCode"]} />
                <BaseField name={["shift", "amount"]} />
                <BaseField name={["shift", "type"]} />

                <Field 
                    name={["shift", "job"]}
                    label={t("job")}
                    rules={[
                        {
                            required: true,
                            message: t("required-job")
                        }
                    ]}
                >
                    <ObjectSelect
                        propValue="id" 
                        options={jobs}
                        disabled={disabled}
                    />
                </Field>

                <Field
                    className="w-100"
                    name={["shift", "location"]}
                    label={t("location")}
                    rules={[
                        {
                            required: true,
                            message: t("required-location")
                        }
                    ]}
                >
                    <ObjectSelect 
                        propValue="id" 
                        options={locations}  
                        disabled={disabled}
                    />
                </Field> 

                <div className="d-flex gap-2 align-items-center justify-content-center">
                    <Button
                        htmlType="button"
                        color="muted"
                        onClick={close}
                    >
                        {t("cancel")}
                    </Button>

                    {!disabled && (
                        <Button
                            htmlType="submit"
                            color="primary"
                        >
                            {t("save")}
                        </Button>
                    )}
                </div>
            </Form>  
        </BaseModal>
    );
}

export default Modal;
