import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import Text from "../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { ProfileContext } from "../../../context";

function ChangeAddress({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { changeAddress: profileSubmit } = useProfileApi();
	const { changeAddress: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(values) => {
			const data = {
				address: {
					...values,
					country: values?.country.id,
				},
			};

			submit(profileSubmit, peopleSubmit, data, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			country: user?.address?.country,
			address: user?.address?.address,
			address2: user?.address?.address2,
			city: user?.address?.city,
			stateOrProvince: user?.address?.stateOrProvince,
			zip: user?.address?.zip,
		});
	}, [user, form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"country",
				"address",
				"address2",
				"city",
				"stateOrProvince",
				"zip",
			],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="country"
				label={t("country")}
				rules={[
					{
						required: true,
						message: t("required-country"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="name"
					resourcePath="/countries?pagination=false"
					hasCompany={false}
					placeholder={t("country")}
					hasSearch
				/>
			</Field>

			<Field
				name="address"
				label={t("address1")}
				rules={[
					{
						required: true,
						message: t("required-address1"),
					},
				]}
			>
				<Text placeholder={t("address1")} />
			</Field>

			<Field name="address2" label={t("address2")}>
				<Text placeholder={t("address2")} />
			</Field>

			<Field
				name="city"
				label={t("city")}
				rules={[
					{
						required: true,
						message: t("required-city"),
					},
				]}
			>
				<Text placeholder={t("city")} />
			</Field>

			<Field
				name="stateOrProvince"
				label={t("state-or-province")}
				rules={[
					{
						required: true,
						message: t("required-state-or-province"),
					},
				]}
			>
				<Text placeholder={t("state-or-province")} />
			</Field>

			<Field
				name="zip"
				label={t("postal-code")}
				rules={[
					{
						required: true,
						message: t("required-postal-code"),
					},
				]}
			>
				<Text placeholder={t("postal-code")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeAddress;
