import { useMemo } from "react";
import { get } from "lodash";
import { useUserPreferences } from "../api/useUserPreferences";

export const useUserPreferencesModule = (module) => {
    const { data } = useUserPreferences();
    return useMemo(() => {
        return get(data, module);
    }, [data, module]);
}
