import { useMemo } from "react";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { useCustomFields } from "../../../../../hooks/useCustomFields";
import { generateUniqueRowId } from "./generateUniqueRowId";
import { useDates } from "./useDates";
import { useGetDependencyRowId } from "./useGetDependencyRowId";

export const useApiFormattedData = ({ data }) => {
  const dates = useDates();
  const getDependencyRowId = useGetDependencyRowId();
  const { allocatedGroups } = useGroups();
  const { data: customFields } = useCustomFields();

  return useMemo(() => {
    const map = data
      .filter((item) => {
        return ["unit", "amount"].includes(item.payCode.hourType)
      })
      .reduce((total, item) => {
        const id = getDependencyRowId(item);

        if (!(id in total)) {
          total[id] = {
            isTouched: false,
            user: item.user,
            payCode: item.payCode,
            job: item.job,
            location: item.location,
            ...allocatedGroups.reduce((total, group) => {
              const level = group.level.replace("_", "");
              total[level] = item[level];
              return total;
            }, {}),
            ...customFields.reduce((total, field) => {
              total.customFields[field.code] = item.customFields?.[field.code];
              return total;
            }, { customFields: {} }),
            dates: dates.reduce((total, date) => {
              total[date] = {
                duration: 0,
                amount: 0,
                approved: undefined,
                locked: undefined,
              };
              return total;
            }, {}),
          };
        }
        if (item.date in total[id].dates) {
          if (item.payCode.hourType === "amount") {
            total[id].dates[item.date].amount += item.total;
          } else {
            total[id].dates[item.date].duration += item.duration;
          }

          if (total[id].dates[item.date].locked === undefined) {
            total[id].dates[item.date].locked = item.locked;
          }
          if (
            total[id].dates[item.date].locked
            && !item.locked
          ) {
            total[id].dates[item.date].locked = false;
          }

          if (total[id].dates[item.date].approved === undefined) {
            total[id].dates[item.date].approved = item.status === "approved";
          }
          if (
            total[id].dates[item.date].approved
            && item.status !== "approved"
          ) {
            total[id].dates[item.date].approved = false;
          }
        }

        return total;
      }, {});
    return Object
      .values(map)
      .map((item) => {
        return {
          id: generateUniqueRowId(),
          ...item,
        };
      });
  }, [data, dates, allocatedGroups, customFields, getDependencyRowId]);
}
