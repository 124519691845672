import { useMemo, useEffect, useCallback } from "react";
import BaseForm, { Field as BaseField } from "rc-field-form";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import CustomTimeInput from "../../../../../../components/Inputs/CustomTimeInput";
import LevelSelect from "../../../../../TimeSheet/components/LevelSelect";
import Field from "../../../../../../components/Field";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Select from "../../../../../../components/Inputs/Select";
import DateInput from "../../../../../../components/Inputs/DateInput";
import Actions from "./Actions";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { useBulkClocking } from "../api";
import { useGetIpAddress } from "../../../../ClockIn/api";
import {
	getPrimaryActiveResource,
	renderUserName,
} from "../../../../../../utils/helpers/user";
import {
	getCommonLocations,
	getCommonJobs,
} from "../../../../../TimeSheet/Team/hooks/useAddBulk/Modal/Form/helpers";
import { renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import { combineDateAndTime } from "../../../../../../utils/helpers/date";

const formatProjectValue = (project) => ({
	code: project?.code,
	description: project?.description,
	id: project?.id,
	glSegment: project?.glSegment,
	orgLevelGroup: project?.orgLevelGroup?.id,
});

const formatTime = (date, time, timezone) => {
	return combineDateAndTime(
		moment(date, "YYYY-MM-DD"),
		moment(time),
		timezone,
	).toISOString(true);
};

const getUserSearchFilters = (value) => ({
	search: value,
});

function Form({ menu, status, setStatus, selectedUsers, setSelectedUsers, setData, setResponse }) {
	const { t } = useTranslation();
	const [form] = BaseForm.useForm();
	const { groups } = useGroups();
	const { ip, getIp } = useGetIpAddress();
	const { submit, loading, error } = useBulkClocking();
	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);
	const locations = useMemo(() => {
		return getCommonLocations(selectedUsers);
	}, [selectedUsers]);

	const jobs = useMemo(() => {
		return getCommonJobs(selectedUsers);
	}, [selectedUsers]);

	const { type, mode } = useMemo(() => {
		let type, mode;
		switch (status) {
			case "clocked-out":
			case "not-active":
			case "all": {
				type = "SHIFT";
				mode = "START";
				break;
			}
			case "on-break": {
				type = "BREAK";
				mode = "END";
				break;
			}
			case "clocked-in": {
				type = "SHIFT";
				mode = "END";
				break;
			}
			default:
				break;
		}
		return { type, mode };
	}, [status]);

	const typeOptions = useMemo(() => {
		if (menu === "clocked-in") {
			return [
				{
					value: "clocked-in",
					label: "clock-out"
				},
				{
					value: "clocked-out",
					label: "clock-in"
				}
			];
		} else if (menu === "on-break") {
			return [
				{
					value: "on-break",
					label: "end-break"
				},
				{
					value: "clocked-out",
					label: "clock-in"
				}
			]
		}
	}, [menu]);

	const onTypeChange = useCallback((value) => {
		setStatus(value)
	}, [setStatus])

	const formatData = useCallback(
		(user, formValues) => {
			const { date, time } = formValues;
			const timeZone = locations?.find(
				({ locationId }) => locationId === formValues.location,
			)?.timezoneValue;

			let data = {
				type,
				mode,
				ip,
				project: formatProjectValue(formValues?.project),
				user: user?.id,
				device: "bulk-clock",
				location: user.locations.find((location) => location.locationId === formValues?.location)?.id,
				job: user.jobs.find((job) => job.jobId === formValues?.job)?.id,
				time: formatTime(date, time, timeZone),
			};

			const location = getPrimaryActiveResource(user?.locations);
			const job = getPrimaryActiveResource(user?.jobs);

			if (!formValues?.job && !formValues?.location) {
				data = {
					...data,
					location: location?.id,
					job: job?.id,
					time: formatTime(date, time, location?.timezoneValue),
				};
			} else if (!formValues?.location) {
				data = {
					...data,
					location: location?.id,
					time: formatTime(date, time, location?.timezoneValue),
				};
			} else if (!formValues?.job) {
				data = { ...data, job: job?.id };
			}

			if (!(type === "SHIFT" && mode === "START")) {
				delete data.job;
				delete data.location;
				delete data.project;
			}

			return data;
		},
		[type, mode, ip, status],
	);

	const onFinish = useCallback(
		(values) => {
			const data = selectedUsers?.map((user) => formatData(user, values));
			submit(data, (response) => {
				setData((prev) => {
					return prev
						?.map((user) => {
							const userResponse = response.find((item) => user.id === item.user.id);
							if (!userResponse || userResponse.status === "error") {
								return user;
							}
							const responseLastShift = userResponse.data.shifts[userResponse.data.shifts.length - 1];
							const newClock = responseLastShift.clocks[responseLastShift.clocks.length - 1];
							if (menu === "all") {
								return {
									...user,
									lastClock: newClock,
								};
							}
							if (!user.lastClock) {
								return undefined;
							}
							if (
								newClock.mode !== user.lastClock.mode
								|| newClock.type !== user.lastClock.type
							) {
								return undefined;
							}
							return {
								...user,
								lastClock: newClock,
							};
						})
						?.filter((user) => !!user);
				})
				setSelectedUsers([]);
				setResponse(response);
			});
		},
		[submit, selectedUsers, formatData, setSelectedUsers, setData, menu, setResponse],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	const project = useMemo(() => {
		let group;
		if (selectedUsers?.length === 1) {
			const userLevels = [
				selectedUsers[0]?.level1,
				selectedUsers[0]?.level2,
				selectedUsers[0]?.level3,
				selectedUsers[0]?.level4,
			];

			group = userLevels?.find(
				(level) => level?.orgLevelGroup === useInClockInGroup?.id,
			);
		}
		return group;
	}, [selectedUsers, useInClockInGroup]);

	useEffect(() => {
		const location =
			(selectedUsers?.length === 1 || locations?.length === 1) &&
			locations?.find(({ primary }) => primary)?.locationId;

		const job =
			(selectedUsers?.length === 1 || jobs?.length === 1) &&
			jobs?.find(({ primary }) => primary)?.jobId;

		form.setFieldsValue({
			users: selectedUsers,
			location,
			job,
			project,
		});
	}, [form, selectedUsers, locations, jobs, project]);

	useEffect(() => {
		if (!selectedUsers || selectedUsers?.length === 0) {
			form.setFieldsValue({
				location: undefined,
				job: undefined,
				project: undefined,
			});
		} else if (!locations || locations.length === 0) {
			form.setFieldsValue({ location: undefined, project: undefined });
		} else if (!jobs || jobs?.length === 0) {
			form.setFieldsValue({ job: undefined });
		}
	}, [form, selectedUsers, locations, jobs]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["users", "location", "job", "project", "time"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<>
			{["on-break", "clocked-in"].includes(menu) && (
				<>
					<Label>
						{t("type")}
					</Label>

					<Select
						className="mb-4"
						value={status}
						onChange={onTypeChange}
					>
						{typeOptions?.map(({ value, label }) => (
							<Select.Option value={value} key={value}>
								{t(label)}
							</Select.Option>
						))}
					</Select>
				</>
			)}

			<BaseForm form={form} onFinish={onFinish}>
				<Field
					name="users"
					label={t("Users")}
					rules={[
						{
							required: true,
							message: t("At least 1 user is required"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath={`/shift/my-team?status=${menu}&page=1&perPage=20`}
						renderLabel={renderUserName}
						mode="multiple"
						placeholder={t("Users")}
						hasSearch
						getSearchFilters={getUserSearchFilters}
						onChange={setSelectedUsers}
					/>
				</Field>

				{(type === "SHIFT" && mode === "START") && (
					<>
						<Field
							name="location"
							label={t("location")}
							rules={[
								{
									required: locations?.length > 0 ? true : false,
									message: t("required-location"),
								},
							]}
						>
							<Select
								placeholder={t("location")}
								disabled={locations?.length === 0}
							>
								{locations?.map((location) => (
									<Select.Option value={location?.locationId}>
										{renderSettingsResourceLabel(location)}
									</Select.Option>
								))}
							</Select>
						</Field>

						<Field
							name="job"
							label={t("job")}
							rules={[
								{
									required: jobs.length > 0 ? true : false,
									message: t("required-job"),
								},
							]}
						>
							<Select placeholder={t("job")} disabled={jobs?.length === 0}>
								{jobs?.map((job) => (
									<Select.Option value={job?.jobId}>
										{renderSettingsResourceLabel(job)}
									</Select.Option>
								))}
							</Select>
						</Field>
					</>
				)}

				{useInClockInGroup && (
					<BaseField shouldUpdate>
						{({ }, { }, { getFieldValue }) => {
							const location = getFieldValue("location");
							if (!(type === "SHIFT" && mode === "START")) {
								return null;
							}

							return (
								<Field
									name="project"
									label={useInClockInGroup.description}
									rules={[
										{
											required: useInClockInGroup?.isRequired,
											message: t(
												`{{description}} is required`,
												{
													description:
														useInClockInGroup.description,
												},
											),
										},
									]}
									dependencies={["location"]}
								>
									<LevelSelect
										location={location}
										group={useInClockInGroup?.id}
										placeholder={useInClockInGroup.description}
									/>
								</Field>
							);
						}}
					</BaseField>
				)}

				<div className="d-flex w-100">
					<Field
						name="date"
						label={t("Date")}
						rules={[
							{
								required: true,
								message: t("Date"),
							},
						]}
						className="w-50 mr-1 d-flex flex-column"
					>
						<DateInput
							placeholderText={t("Date")}
							minDate={moment().subtract(1, "day").toDate()}
							maxDate={moment().toDate()}
						/>
					</Field>

					<Field
						name="time"
						label={t("Time")}
						rules={[
							{
								required: true,
								message: t("Time is required"),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									const date = getFieldValue("date");

									if (
										moment(date).isSame(moment(), "date") &&
										moment(
											combineDateAndTime(date, value),
										).isAfter(moment(), "seconds")
									) {
										return Promise.reject(
											new Error(
												t(
													"Time should be a value smmaller than current time",
												),
											),
										);
									}

									return Promise.resolve();
								},
							}),
						]}
						className="w-50 ml-1"
					>
						<CustomTimeInput />
					</Field>
				</div>

				<Actions status={status} loading={loading} />
			</BaseForm>
		</>
	);
}

export default Form;
