import { List } from "rc-field-form";
import { useColumnsMap } from "../../../helpers";
import Item from "./Item";

function Filters({ columns, form }) {
	const { getColumns } = useColumnsMap();

	const cols = getColumns(columns);

	return (
		<List name="filters">
			{(fields, { add, remove }) =>
				fields.map(({ name, key }) => {
					const maxLength = cols?.filter(({ type }) => type)?.length;
					const canAdd = fields.length < maxLength;
					const canRemove = fields.length > 1;

					return (
						<div className="d-flex align-items-center" key={key}>
							<Item
								key={key}
								columns={cols}
								name={name}
								form={form}
							/>

							<div className="d-flex align-items-center mt-0">
								{canAdd && (
									<span className="d-flex align-items-center justigy-content-center mb-4">
										<i
											className="fa-solid fa-plus cursor-pointer text-primary text-lg mx-2"
											onClick={() => add()}
										/>
									</span>
								)}

								{canRemove && (
									<span className="d-flex align-items-center justigy-content-center mb-4">
										<i
											className="fa-solid fa-minus cursor-pointer text-primary text-lg mx-2"
											onClick={() => remove(name)}
										/>
									</span>
								)}
							</div>
						</div>
					);
				})
			}
		</List>
	);
}

export default Filters;
