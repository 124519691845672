import moment from "moment-timezone";

export const isFilterValid = ({ name, method, value }) => {
    if (Array.isArray(value) && value.length === 0) {
        return false;
    }
    return !!name && !!method && ![undefined, null, ""].includes(value);
};

export const formatFilters = (filters) =>
	filters?.map(({ name, method, value }) => {
		return {
			name,
			method,
			value:
				typeof value === "object" || Array.isArray(value)
					? name === "year"
						? moment(value).format("YYYY")
						: value?.map((value) => (value?.id ? value?.id : value))
					: value,
		};
	});

export const formatParams = ({
	pageIndex,
	pageSize,
	filters,
	sortBy: [sortBy] = [],
	...params
}) => {
	const formatted = { ...params };
	if (pageIndex !== undefined) {
		formatted.page = pageIndex + 1;
	}
	if (pageSize) {
		formatted.perPage = pageSize;
	}

	const formattedFilters = formatFilters(filters);

	if (formattedFilters) {
		formattedFilters
			.filter(isFilterValid)
			.forEach(({ name, method, value }) => {
				formatted[`filter[${name}][method]`] = method;
				formatted[`filter[${name}][value]`] = value;
			});
	}

	if (sortBy) {
		const { id, desc } = sortBy;
		formatted[`orderBy[${id}]`] = desc ? "desc" : "asc";
	}
	return formatted;
};
