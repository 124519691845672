import Form from "rc-field-form";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import { usePeopleApi } from "../../../../../utils/api/people";
import { useProfileApi } from "../../../../../utils/api/profile";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import CalculationGroupRules from "../../../../Settings/PayPolicies/CalculationGroup/Form/Content/Rules";
import { initialValues } from "../../../../Settings/PayPolicies/CalculationGroup/Form/initialValues";
import { onFormProviderFinish } from "../../../../Settings/PayPolicies/CalculationGroup/Form/onFormProviderFinish";
import { ProfileContext } from "../../../context";

function Overtime({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { changeCalculationGroupRules: profileSubmit } = useProfileApi();
	const { changeCalculationGroupRules: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(data) => {
			submit(profileSubmit, peopleSubmit, data, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({ overtime: user?.calculationGroupRules?.overtime || initialValues.overtime });
	}, [user?.calculationGroupRules?.overtime, form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], submittingError);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form.FormProvider onFormFinish={onFormProviderFinish}>
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				scrollToFirstError
				name="calculationGroup"
			>
				<CalculationGroupRules disabled={false} />

				<div className="d-flex justify-content-end">
					<Button
						className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
						onClick={close}
						disabled={submitting}
					>
						{t("cancel")}
					</Button>

					<Button
						color="primary"
						type="submit"
						loading={submitting}
						className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
					>
						{t("save")}
					</Button>
				</div>
			</Form>
		</Form.FormProvider>
	);
}

export default Overtime;
