import { useTranslation } from "react-i18next";
import { CardHeader, CardBody } from "reactstrap";
import Table from "./Table";

function Home({ setStep, setData, data, setMode }) {
	const { t } = useTranslation();

	return (
		<>
			<CardHeader tag="h3" className="py-4">{t("system-reports")}</CardHeader>

			<CardBody className="p-0">
				<div style={{ height: "calc(100% - 200px)" }}>
					<Table
						setStep={setStep}
						setData={setData}
						data={data}
						setMode={setMode}
					/>
				</div>
			</CardBody>
		</>
	);
}

export default Home;
