import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../components/Button";

function Actions({ status, loading }) {
	const { t } = useTranslation();

	return useMemo(() => {
		switch (status) {
			case "clocked-out":
			case "not-active":
			default:
				return (
					<Button
						className="w-100 clock-in-btn"
						size="lg"
						loading={loading}
						disabled={loading}
					>
						{t("Clock in")}
					</Button>
				);
			case "clocked-in":
				return (
					<Button
						className="w-100 bg-red text-white border-0"
						size="lg"
						loading={loading}
						disabled={loading}
					>
						{t("Clock out")}
					</Button>
				);
			case "on-break":
				return (
					<Button
						className="w-100 break-btn"
						size="lg"
						loading={loading}
						disabled={loading}
					>
						{"End break"}
					</Button>
				);
		}
	}, [status, loading, t]);
}

export default Actions;
