import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
	useCompanyDateFormat,
	useIsMasterCompany,
} from "../../../../utils/hooks/company";
import { renderReportsLabel, getReportsSearchFilters } from "./Form";
import { createColumnHelper } from "@tanstack/react-table";
import { systemReports } from "../../../Reports/SystemReports/Table/systemReports";
import { renderUserName } from "../../../../utils/helpers/user";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone, openEmailFtpTooltip }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const dateFormat = useCompanyDateFormat();

	const {
        description,
        status,
        actions,
    } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			description,
			columnHelper.accessor("config.reportId", {
				enableColumnFilter: true,
				header: t("report"),
				cell: (info) => {
					const { isSystem, reportId, report } = info.row.original.config;
					if (isSystem) {
						return systemReports.find(({ id }) => id === reportId)?.name;
					}
					return report?.name;
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						renderLabel={renderReportsLabel}
						resourcePath="/reports"
						hasSearch
						getSearchFilters={getReportsSearchFilters}
						mode="multiple"
					/>
				),
			}),
			columnHelper.accessor("config.isSystem", {
				header: t("is-system"),
				cell: (info) => info.getValue() ? t("yes") : t("no"),
			}),
			columnHelper.accessor("user", {
				header: t("generate-as"),
				cell: (info) => {
					const value = info.getValue();
					if (!value) {
						return null;
					}
					return renderUserName(value, false);
				},
			}),
			columnHelper.accessor("config.filters.type", {
				header: t("filters"),
				cell: (info) => t(info.getValue()),
			}),
			columnHelper.accessor("config.filters.from", {
				header: t("start"),
				cell: (info) => {
                    const value = info.getValue();
					return value && moment.parseZone(value).format(dateFormat);
                },
			}),
			columnHelper.accessor("config.filters.to", {
				header: t("end"),
				cell: (info) => {
                    const value = info.getValue();
					return value && moment.parseZone(value).format(dateFormat);
                },
			}),
			columnHelper.accessor("config.sendVia", {
				enableColumnFilter: true,
				header: t("send-via"),
				cell: (info) => {
                    const { original } = info.row;
                    const value = info.getValue();
					const id = value === "email"
                        ? `email-${original?.id}`
                        : `ftp-${original?.id}`;
					return (
						value && (
							<span
								id={id}
								onMouseEnter={() =>
									openEmailFtpTooltip(original)
								}
								className="cursor-pointer"
								style={{
									borderBottom: "1px dotted",
								}}
							>
								{value === "ftp" ? t("ftp") : t("email")}

								<FontAwesomeIcon
									icon={
										value === "email" ? faEnvelope : faGlobe
									}
									className="mx-1 text-muted"
								/>
							</span>
						)
					);
				},
				filterType: "select",
				Filter: (
					<Select showSearch>
						<Select.Option value="email">
							{t("email")}
						</Select.Option>
						<Select.Option value="ftp">{t("ftp")}</Select.Option>
					</Select>
				),
			}),
            status
		];

		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [
		isMasterCompany,
        description,
        status,
		actions,
		dateFormat,
		openEmailFtpTooltip,
		t,
	]);
};
