import { useCallback, useEffect, useState } from "react";
import useApi from "..";

export const useJobs = () => {
	const { authGet } = useApi();

	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetch = useCallback(
		async (filters = {}) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authGet("/jobs", {
					params: {
						...filters,
					},
				});
				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[authGet, setData, setLoading, setError]
	);

	return { data, setData, totalItems, setTotalItems, loading, error, fetch };
};

export const useJob = (id) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState();

	const { authGet } = useApi();

	const fetch = useCallback(async () => {
		if (!id) {
			return;
		}

		setLoading(true);
		try {
			const response = await authGet(`/jobs/${id}`);

			if (response) {
				setData(response);
			}
		} catch (err) {
			setError(err);
		} finally {
			setLoading(false);
		}
	}, [id, authGet, setLoading, setError, setData]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return { loading, error, data };
};
