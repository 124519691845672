import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { 
    useCompanyDateFormat,
    useCompanyTimeFormat
} from "../../../../../../utils/hooks/company";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { getHourTypeColor } from "../../../../../../utils/helpers/hourType";
import classNames from "classnames";

const colors = {
	"regular": "#525f7f",
	"transfer": "#5428e0",
	"break": "#fe9700",
	"overtime": "#f8219c",
};

function Schedule({ data }) {
    const { t } = useTranslation();
    const { groups } = useGroups();
    const dateFormat = useCompanyDateFormat();
    const timeFormat = useCompanyTimeFormat();

    const levels = useCallback((event) => {
        const levels = [event?.level1, event?.level2, event?.level3, event?.level4];
        if (levels?.length > 0) {
            return groups?.map((group) => {
                return levels?.map((level) => {
                    if (group?.id === level?.orgLevelGroup) {
                        return (
                            <div key={level?.id} className="d-flex align-items-center flex-wrap">
                                <span className="font-weight-bold mr-2 text-sm text-dark mb-1">
                                    {t(group?.description)}: 
                                </span>
                                <span>
                                    {renderOrgLevelResourceLabel(level)}
                                </span>
                            </div> 
                        )
                    }
                })
            })
        }
    }, [groups, t]);

    const baseInfo = useCallback((event) => {
       return (
            <div className="d-flex flex-column">
                {/* <div>
                    <span className="font-weight-bold mr-2 text-sm text-dark mb-1">{t("created-by")}: </span>
                    <span>
                        {event?.createdBy?.firstName} {event?.createdBy?.lastName} 
                    </span>
                </div> */}

                <div className="d-flex gap-2 align-items-center">
                    <span className="font-weight-bold mr-2 text-sm text-dark mb-1">{t("start")}: </span>
                    <span>
                        {moment(event?.date).format(dateFormat)} 
                    </span>
                    <span>
                        {moment.parseZone(event?.startDate).format(timeFormat)}
                    </span>
                </div>

                <div className="d-flex gap-2 align-items-center">
                    <span className="font-weight-bold mr-2 text-sm text-dark mb-1">{t("end")}: </span>
                    <span>
                        {moment(event?.date).format(dateFormat)} 
                    </span>
                    <span>
                        {moment.parseZone(event?.endDate).format(timeFormat)}
                    </span>
                </div>

                {event?.job && (
                    <div>
                        <span className="font-weight-bold mr-2 text-sm text-dark">{t("job")}: </span>
                        {renderSettingsResourceLabel(event?.job)}
                    </div>
                )}

                {event?.location && (
                    <div>
                        <span className="font-weight-bold mr-2 text-sm text-dark">{t("location")}: </span>
                        {renderSettingsResourceLabel(event?.location)}
                    </div>
                )}

                {levels(event)}
            </div>
       )
    }, [levels, dateFormat, timeFormat, t]);

    const shifts = useCallback((shifts, startDate) => {
        const calculatedShifts = shifts?.map((shift) => {
            let  start = moment.parseZone(startDate).add(shift?.start, "seconds");
            let end = moment.parseZone(startDate).add(shift?.end, "seconds");
            
            return {
                ...shift,
                start,
                end,
            }
        });

        return (
            <div className="d-flex flex-column px-3">
                <span className="font-weight-bolder mx-3 text-sm text-dark mb-2">
                    {t("shifts")}
                </span>

                {calculatedShifts?.map((shift) => (
                    <div 
                        className="mb-2 pl-3" 
                        style={{ borderLeft: `2px solid ${colors[shift.type]}`}}
                    >
                        <span 
                            className="font-weight-bold text-xs" 
                            style={{ color: colors[shift.type] }}
                        >
                            {t(shift.type)}
                        </span> {" | "}
                        <span
							className={classNames("py-1 px-2 rounded text-xs", shift?.payCode?.hourType === "unpaid" ? "text-dark" : "text-white")}
							style={{
								backgroundColor: getHourTypeColor(shift?.payCode?.hourType),
							}}
						>
							{shift?.payCode?.code}
						</span>

                        <div className="d-flex align-items-center gap-3 flex-wrap text-xs my-1">
                            <span>
                                <span className="font-weight-bold mr-2 text-dark mb-1">{t("start")}: </span>
                                {moment(shift?.start).format(timeFormat)}
                            </span>

                            <span>
                                <span className="font-weight-bold mr-2 text-dark mb-1">{t("end")}: </span>
                                {moment(shift?.end).format(timeFormat)}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        )
    }, [timeFormat, t]);

    return (
        !data || data?.length === 0 ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center p-7">
                <i
                    className="fa-solid fa-inbox text-muted"
                    style={{ fontSize: "20px" }}
                />
    
                <span className="text-gray font-weight-400 text-xs">
                    {t("no-data")}
                </span>
            </div>
        ) : (
           <div className="d-flex p-2 flex-column" >
                {data?.map((event) => (
                    <div className="w-100 p-4 border border-muted rounded shadow mb-1 d-flex flex-wrap align-items-center gap-7">
                        {baseInfo(event)}
                        {event?.shifts && shifts(event?.shifts, event?.startDate)}
                    </div>
                ))}
            </div>
        )
    )
}

export default Schedule;
