import { Tooltip } from "reactstrap";
import "./style.scss";

const DaysTooltip = ({ isOpen, close, data }) => {
    const { days } = data;

    return (
        <Tooltip 
            isOpen={isOpen} 
            toggle={close} 
            trigger="hover" 
            target={"days" + data.id} 
            className="days-list-tooltip" 
            placement="right"
        >
            <div className="days-list-tooltip-container">
                <div className="d-flex flex-column p-2">
                    {days.map((day) => {
                        // const currentDate = day?.dates?.map((date) => moment(date).isSame(moment(), "year"));
                        // const hasHoliday = day?.date ? moment(day.date).isSame(moment(), "year") : !!currentDate;
                        return (
                            <div className="d-flex align-items-center gap-2 px-2">
                                <span>
                                    <span className="font-weight-bold text-primary">{day?.name}</span> 
                                </span>
                                {"-"}
                                <span>
                                    <span className="text-dark">
                                        {(day?.isSameDate || !day?.repeatEveryYear) ? day?.date : day?.dates?.[0]}
                                    </span>
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Tooltip>
    );
};

export default DaysTooltip;
