import FormElement, { List, Field as BaseField } from "rc-field-form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Select from "../../../../components/Inputs/Select";
import Field from "../../../../components/Field";
import BaseSettingsFormContent from "../../../../components/Form/BaseSettingsFormContent";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../helpers/useIsFieldDisabled";
import OptionsItem from "./OptionsItem";
import Switch from "../../../../components/Inputs/Switch";
import { Label } from "reactstrap";

const initialValues = {
	options: [
		{
			label: "",
			value: "",
		},
	],
};

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const [type, setType] = useState(values?.type);
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.general.customFields");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onTypeChange = useCallback(
		(value) => {
			setType(value);
			form.setFieldsValue(initialValues);
		},
		[setType, form],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			showByDefault: values?.showByDefault ? values?.showByDefault : true,
			options: values?.options ? values.options : initialValues.options,
			...values,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "type", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={submit}
		>
			<div>
				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<Field
					name="isRequired"
					label={t("is-required")}
					valuePropName="checked"
				>
					<Switch id="isRequired" />
				</Field>

				<Field
					name="showByDefault"
					label={t("show-by-default-timesheet-table")}
					valuePropName="checked"
				>
					<Switch id="showByDefault" />
				</Field>

				<Field
					name="type"
					label={t("type")}
					rules={[
						{
							required: true,
							message: t("required-type"),
						},
					]}
				>
					<Select placeholder={t("type")} onChange={onTypeChange}>
						<Select.Option value="text">{t("text")}</Select.Option>
						<Select.Option value="number">
							{t("number")}
						</Select.Option>
						<Select.Option value="email">
							{t("email")}
						</Select.Option>
						<Select.Option value="select">
							{t("select")}
						</Select.Option>
					</Select>
				</Field>

				{type === "select" && (
					<>
						<Label>
							{t("select-options")}
							<span className="text-red"> *</span>
						</Label>

						<List name="options">
							{(fields, { add, remove }) =>
								fields.map(({ name, key }) => {
									const canRemove = fields.length > 1;

									return (
										<div className="mb-4">
											<div
												className="d-flex align-items-center mb-1"
												key={key}
											>
												<OptionsItem
													name={name}
													key={key}
												/>

												<div className="d-flex align-items-center mt-0">
													<span className="d-flex align-items-center justify-content-center">
														<i
															className="fa-solid fa-plus cursor-pointer text-primary text-lg mx-2"
															onClick={() =>
																add()
															}
														/>
													</span>

													{canRemove && (
														<span className="d-flex align-items-center justify-content-center">
															<i
																className="fa-solid fa-minus cursor-pointer text-primary text-lg mx-2"
																onClick={() =>
																	remove(name)
																}
															/>
														</span>
													)}
												</div>
											</div>

											<BaseField shouldUpdate>
												{(
													{ },
													{ },
													{ getFieldsError },
												) => {
													const errors =
														getFieldsError([
															[
																"options",
																name,
																"label",
															],
															[
																"options",
																name,
																"value",
															],
														]);
													const error = errors[0]
														?.errors[0]
														? errors[0]?.errors[0]
														: errors[1]?.errors[0];

													return (
														error && (
															<div className="invalid-feedback d-block mb-3">
																{error}
															</div>
														)
													);
												}}
											</BaseField>
										</div>
									);
								})
							}
						</List>
					</>
				)}
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
