import { createContext, useContext } from "react";

export const TableContext = createContext();

export const useTableContext = () => {
	const context = useContext(TableContext);
	if (context === undefined) {
		throw new Error("useTableContext should be used within a provider");
	}
	return context;
}
