import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useNonAuthApi } from "../../../utils/api";

export const useChangePassword = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const { nonAuthPost } = useNonAuthApi();

	const changePassword = useCallback(
		async ({ newPassword, token }) => {
			try {
				setLoading(true);
				setError(null);
				const body = {
					password: newPassword,
					confirmationToken: token,
				};
				const response = await nonAuthPost(
					"/profile/forgot-password/reset",
					{ data: body },
				);

				if (response) {
					navigate("/login");
				}
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, nonAuthPost, navigate],
	);

	return { loading, error, changePassword };
};
