import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import Modal from "../../../../../components/Modal";
import SimpleTable from "../../../../../components/SimpleTable";
import Pagination from "../../../../../components/NTable/Pagination";
import { useJobsLogs } from "./api";
import { useColumns } from "./useColumns";

const perPage = 10;

function LogModal({ isOpen, close }) {
	const [page, setPage] = useState(0);

	const { data, loading, totalItems, fetch } = useJobsLogs();

	const columns = useColumns();

	const tableData = useMemo(
		() =>
			data
				?.map(({ valueType, cron, id }) =>
					valueType?.map((item) => ({ ...item, cron, id })),
				)
				?.flat(),
		[data],
	);

	useEffect(() => {
		const controller = new AbortController();
		fetch({ page, perPage }, controller);
		return () => controller.abort();
	}, [fetch, page]);

	return (
		<Modal isOpen={isOpen} toggle={close} centered size="lg">
			<div className="d-flex w-100 justify-content-end mb-3">
				<FontAwesomeIcon
					icon={faXmark}
					className="cursor-pointer"
					onClick={close}
				/>
			</div>

			<SimpleTable
				data={tableData}
				columns={columns}
				loading={loading}
				footer={false}
			/>

			<Pagination
				page={page}
				perPage={perPage}
				totalItems={totalItems}
				gotoPage={setPage}
				pageCount={Math.ceil(totalItems / 10)}
				showOnSizeChange={false}
				isListPagination={false}
			/>
		</Modal>
	);
}

export default LogModal;
