import { useCallback } from "react";
import useApi from "../../../../utils/api"
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { useUser } from "../../../../utils/hooks/user";

const CACHE_KEY = "company-configuration";

export const useConfigurationActions = (configurationName) => {
    const mainCompany = useUser()?.mainCompany;
    const { authPost } = useApi();
    const { data } = useConfiguration();

    const {
        trigger: submit,
        isMutating: submitting,
    } = useSWRMutation(
        mainCompany ? CACHE_KEY : null,
        useCallback(async (_, { arg }) => {
            const { config } = await authPost(
                `/preferencies/company/${mainCompany}/${configurationName}`,
                { data: arg },
            );
            return {
                ...data,
                [configurationName]: config,
            };
        }, [mainCompany, configurationName, authPost, data]),
        { populateCache: true, revalidate: false },
    );

    return { submit, submitting };
}

export const useConfiguration = () => {
    const mainCompany = useUser()?.mainCompany;
    const { authGet } = useApi();

    const { data, isLoading, error } = useSWR(
        mainCompany ? CACHE_KEY : null,
        useCallback(async () => {
            const { result } = await authGet(`/preferencies/company/${mainCompany}`);
            return result.reduce((total, item) => ({
                ...total,
                [item.preferenceKey]: item.config,
            }), {});
        }, [mainCompany, authGet]),
    );

    const hasFetched = !isLoading && (!!data || !!error);

    return { data, isLoading, hasFetched };
}
