import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useNewTable } from "../../../../components/NTable/hook";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useOnExport } from "../../helpers/useOnExport";
import { useImportModal } from "../../helpers/useImportModal";

function CalculationGroupRule() {
  const { t } = useTranslation();

  const {
    state: { mode, data, totalItems, loading, submitting, error },
    selected,
    fetch,
    createSubmit,
    updateSubmit,
    goToHelp,
    goToView,
    goToCreate,
    goToEdit,
    goToClone,
    remove,
    batchRemove,
    settingsExport,
    loadingExport,
    setData,
  } = useSettingsCRUDHook("/rules/calculation-group");

  const { hasAccess: canAdd } = useAccess(
    "settings.rules.calculationGroup.canCreate"
  );

  const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
    message: (data) =>
      Array.isArray(data)
        ? t("do-you-want-to-delete-calculation-group-rules", {
            count: data?.length,
          })
        : t("do-you-want-to-delete-calculation-group-rule"),
    onConfirm: (data) =>
      Array.isArray(data) ? batchRemove(data) : remove(data),
    permission: "settings.rules.calculationGroup.canDelete",
  });

  const { unarchiveModal, isFormOpen, formProps } = useManageForm({
    mode,
    selected,
    createSubmit,
    updateSubmit,
  });

  const columns = useColumns({
    edit: useActionWithAccess(
      goToEdit,
      "settings.rules.calculationGroup.canEdit"
    ),
    remove: openDeleteModal,
    clone: useActionWithAccess(
      goToClone,
      "settings.rules.calculationGroup.canCreate"
    ),
  });

  const table = useNewTable({
    data,
    columns,
    loading,
    totalItems,
    sessionFiltersKey: "calculationGroupRules",
  });

  const queryFilters = useQueryFilters(table);

  const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
    mode,
    help: t("help"),
    view: t("view-calculation-group-rule"),
    create: t("add-calculation-group-rule"),
    edit: t("edit-calculation-group-rule"),
    clone: t("clone-calculation-group-rule"),
  });

  useFetchResource({ fetch, state: table.getState() });

  const visibleColumnKeys = useVisibleColumnKeys(table);

  const onExport = useOnExport({
    url: "/export/rules/calculation-group",
    exportCall: settingsExport,
    queryFilters,
    visibleColumnKeys,
  });

  const { open: openImportModal, modal: importModal } = useImportModal({
    importUrl: "/rules/calculation-group/import",
    exportUrl: "/export/rules/calculation-group",
    setData,
    exportCall: settingsExport,
    loading: loading || loadingExport,
  });

  return (
    <div className="d-flex h-100">
      <Card className="flex-fill mb-0">
        <Topbar
          title={t("calculation-group-rules")}
          add={canAdd && goToCreate}
          help={goToHelp}
          importFile={openImportModal}
          onExport={onExport}
          loading={loading || loadingExport}
        />

        <Card className="mb-auto" style={{ maxHeight: "calc(100% - 156px)" }}>
          <Actions
            filters={table.getState().columnFilters}
            setFilters={table.setColumnFilters}
            columns={table.getAllColumns()}
          >
            <BulkActions
              rows={table.getState().rowSelection}
              batchRemove={openDeleteModal}
            />
          </Actions>

          <NTable table={table} />
        </Card>
      </Card>

      <Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
        {isFormOpen ? (
          <Form
            {...formProps}
            error={error}
            loading={submitting}
            close={goToView}
          />
        ) : (
          <>{t("help")}</>
        )}
      </Drawer>

      {importModal}

      {deleteModal}

      {unarchiveModal}
    </div>
  );
}

export default CalculationGroupRule;
