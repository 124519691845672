import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	faCheck,
	faLock,
	faLockOpen,
	faPlusCircle,
	faTrash,
	faXmark,
	faTableList,
	faUsers,
	faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardBody } from "reactstrap";
import moment from "moment-timezone";
import {
	useCompanyDateFormat,
	useCompanyTimeFormat,
} from "../../../../../../utils/hooks/company";
import {
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderHourType } from "../../../../../Settings/PayPolicies/PayCode/columns";
import { currencyFormatter } from "../../../../../../utils/helpers/currencyFormatter";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { getHourTypeColor } from "../../../../../../utils/helpers/hourType";
import { renderUserName } from "../../../../../../utils/helpers/user";

const renderAction = (action) => {
	switch (action) {
		case "update":
			return "updated";
		case "create":
			return "created";
		case "delete":
			return "deleted";
		case "lock":
			return "locked";
		case "unlock":
			return "unlocked";
		case "approve":
			return "approved";
		case "unapproved":
		case "unapprove":
			return "unapproved";
		case "split":
			return "unapproved";
		case "bulk-clock":
			return "bulk-clock";
		case "bulk-shift-start":
		case "bulk-shift-end":
		case "bulk-break-start":
		case "bulk-break-end":
			return action;
		default:
			return "";
	}
};

const renderIcon = (action) => {
	let icon = "";
	let className = "";
	switch (action) {
		case "create":
			icon = faPlusCircle;
			className = "text-green";
			break;
		case "delete":
			icon = faTrash;
			className = "text-red";
			break;
		case "lock":
			icon = faLock;
			className = "text-primary";
			break;
		case "unlock":
			icon = faLockOpen;
			className = "text-gray";
			break;
		case "approve":
			icon = faCheck;
			className = "text-info";
			break;
		case "unapproved":
		case "unapprove":
			icon = faXmark;
			className = "text-orange";
			break;
		case "split":
			icon = faTableList;
			className = "text-pink";
			break;
		case "bulk-clock":
		case "bulk-shift-start":
		case "bulk-shift-end":
		case "bulk-break-start":
		case "bulk-break-end":
			icon = faUsers;
			className = "text-gray";
			break;
		case "update":
			icon = faEdit;
			className = "text-orange";
			break;
		default:
			return "";
	}

	return icon && <FontAwesomeIcon icon={icon} className={className} />;
};

function History({ data, loading }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();

	const cardSider = useCallback(
		(item) => {
			return (
				<div className="d-flex flex-column align-items-start justify-content-center border-right pr-3">
					<span className="text-sm text-dark mb-1 font-weight-bolder">
						{item?.user?.firstName} {item?.user?.lastName}
					</span>
					<span className="mb-1 text-sm font-weight-600 text-gray">
						{moment(item?.createdAt).local().format(dateFormat)}
					</span>
					<span className="text-sm text-gray">
						{moment(item?.createdAt).local().format(timeFormat)}
					</span>
				</div>
			);
		},
		[dateFormat, timeFormat],
	);

	const levels = useCallback(
		(item) => {
			const levelElements = [];
			const levelTypes = ["level_1", "level_2", "level_3", "level_4"];
			for (const levelType of levelTypes) {
				const levelAccessor = levelType.replace("_", "");
				if (!item?.[levelAccessor]) {
					continue;
				}
				const group = groups?.find(
					(group) => group?.level === levelType,
				);
				levelElements.push(
					<span key={levelType}>
						<span className="font-weight-bolder text-sm mr-2 mb-1">
							{group?.description}:
						</span>
						{item?.oldValues && item?.oldValues[levelAccessor] && (
							<>
								<span className="text-sm font-weight-normal">
									<s style={{ color: "red" }}>
										<span style={{ color: "#525f7f" }}>
											{item?.oldValues[levelAccessor] &&
												renderOrgLevelResourceLabel(
													item?.oldValues[
													levelAccessor
													],
												)}
										</span>
									</s>
									&nbsp; =&gt; &nbsp;
								</span>
							</>
						)}
						{item?.[levelAccessor] &&
							renderOrgLevelResourceLabel(item?.[levelAccessor])}
					</span>,
				);
			}
			return levelElements;
		},
		[groups],
	);

	const dateColumn = useCallback(
		(item) => {
			if (
				!["amount", "unit"].includes(item?.hourType) &&
				item?.hourType
			) {
				return (
					<>
						{item?.startDate && (
							<>
								{item?.oldValues &&
									item?.oldValues?.startDate && (
										<span className="text-sm">
											<s style={{ color: "red" }}>
												{moment
													.parseZone(
														item?.oldValues
															?.startDate,
													)
													.format(timeFormat)}
											</s>
											<span>&nbsp; =&gt; &nbsp;</span>
										</span>
									)}
								<span className="text-green font-weight-bold text-sm">
									{moment
										.parseZone(item?.startDate)
										.format(timeFormat)}
								</span>
								<span className="text-lg mx-1">-</span>
							</>
						)}

						{item?.endDate && (
							<>
								{item?.oldValues &&
									item?.oldValues?.endDate && (
										<span className="text-sm">
											<s style={{ color: "red" }}>
												<span
													style={{ color: "#525f7f" }}
												>
													{moment
														.parseZone(
															item?.oldValues
																?.endDate,
														)
														.format(timeFormat)}
												</span>
											</s>
											<span>&nbsp; =&gt; &nbsp;</span>
										</span>
									)}
								<span className="text-red font-weight-bold text-sm">
									{moment
										.parseZone(item?.endDate)
										.format(timeFormat)}
								</span>
							</>
						)}
					</>
				);
			}
		},
		[timeFormat],
	);

	const payCodeColumn = useCallback((item) => {
		return (
			<>
				<span className="font-weight-bold text-xs">
					{item?.hourType !== "amount" &&
						item?.hourType !== "unit" && (
							<span className="text-lg mx-2">|</span>
						)}
				</span>
				{item?.oldValues && item?.oldValues.payCode && (
					<span className="text-xs">
						<s style={{ color: "red" }}>
							<span
								className="py-1 rounded"
								style={{ color: "#525f7f" }}
							>
								{item?.oldValues?.payCode?.code}
							</span>
						</s>
						&nbsp; =&gt; &nbsp;
					</span>
				)}
				{item?.payCode && (
					<span className="font-weight-bold text-xs">
						<span
							className="py-1 px-2 text-white rounded"
							style={{
								backgroundColor: getHourTypeColor(item?.payCode?.hourType),
							}}
						>
							{item?.payCode?.code}
						</span>
					</span>
				)}
			</>
		);
	}, []);

	const hourTypeColumn = useCallback((item) => {
		return (
			(item?.hourType ||
				(item?.oldValues && item?.oldValues.hourType)) && (
				<>
					<span className="text-lg mx-2 font-weight-bold">|</span>

					{item?.oldValues && item?.oldValues.hourType && (
						<>
							<s style={{ color: "red" }}>
								<span
									style={{ color: "#525f7f" }}
									className="text-xs"
								>
									{t(renderHourType(item?.oldValues.hourType))}
								</span>
							</s>
							&nbsp; =&gt; &nbsp;
						</>
					)}

					{item?.hourType && (
						<>
							<span
								className="font-weight-bold text-xs"
								style={{
									color: getHourTypeColor(item?.hourType),
								}}
							>
								{t(renderHourType(item?.hourType))}
							</span>
						</>
					)}
				</>
			)
		);
	}, [t]);

	const durationColumn = useCallback(
		(item) => {
			return (
				item?.hourType === "unit" && (
					<>
						<span className="text-lg mx-2 font-weight-bold">|</span>
						{item?.oldValues && item?.oldValues.duration && (
							<>
								<span className="text-xs">
									<s style={{ color: "red" }}>
										<span style={{ color: "#525f7f" }}>
											{renderDurationAsFormat(
												item?.oldValues.duration,
												timeFormat,
											)}
										</span>
									</s>
									&nbsp; =&gt; &nbsp;
								</span>
							</>
						)}

						<span className="font-weight-bold text-xs">
							{renderDurationAsFormat(item?.duration, timeFormat)}
						</span>
					</>
				)
			);
		},
		[timeFormat],
	);

	const totalColumn = useCallback((item) => {
		return (
			<>
				<span className="text-lg mx-2 font-weight-bold">|</span>
				{item?.oldValues && item?.oldValues.total && (
					<>
						<span className="text-xs">
							<s style={{ color: "red" }}>
								<span style={{ color: "#525f7f" }}>
									{currencyFormatter(
										item?.oldValues.total || 0,
										2,
										item?.job?.currency,
									)}
								</span>
							</s>
							&nbsp; =&gt; &nbsp;
						</span>
					</>
				)}
				<span className="font-weight-bold text-xs">
					{currencyFormatter(
						item?.total || 0,
						2,
						item?.job?.currency,
					)}
				</span>
			</>
		);
	}, []);

	const rateColumn = useCallback((item) => {
		return (
			<>
				<span className="text-lg mx-2 font-weight-bold">|</span>
				{item?.oldValues && item?.oldValues.rate && (
					<>
						<span className="text-xs">
							<s style={{ color: "red" }}>
								<span style={{ color: "#525f7f" }}>
									{item?.oldValues?.rate && (<>
										&nbsp; ({currencyFormatter(
											item?.oldValues.rate || 0,
											2,
											item?.job?.currency,
										)})
									</>)}
								</span>
							</s>
							&nbsp; =&gt; &nbsp;
						</span>
					</>
				)}
				<span className="font-weight-bold text-xs">
					{currencyFormatter(
						item?.rate || 0,
						2,
						item?.job?.currency,
					)}
				</span>
			</>
		);
	}, []);

	const jobColumn = useCallback((item) => {
		return (
			<>
				<span className="text-sm font-weight-bold mb-1">
					<i className="fa-briefcase fa-solid mr-2" />
					{item?.oldValues && item?.oldValues.job && (
						<>
							<span className="text-xs font-weight-normal">
								<s style={{ color: "red" }}>
									<span style={{ color: "#525f7f" }}>
										{item?.oldValues.job &&
											renderSettingsResourceLabel(
												item?.oldValues.job,
											)}
									</span>
								</s>
								&nbsp; =&gt; &nbsp;
							</span>
						</>
					)}
					{item?.job && renderSettingsResourceLabel(item?.job)}
				</span>
			</>
		);
	}, []);

	const locColumn = useCallback((item) => {
		return (
			<>
				<span className="text-xs font-weight-bold mb-1">
					<i className="fa-location-dot fa-solid mr-2" />
					{item?.oldValues && item?.oldValues.location && (
						<>
							<span className="text-xs font-weight-normal">
								<s style={{ color: "red" }}>
									<span style={{ color: "#525f7f" }}>
										{item?.oldValues.location &&
											renderSettingsResourceLabel(
												item?.oldValues.location,
											)}
									</span>
								</s>
								&nbsp; =&gt; &nbsp;
							</span>
						</>
					)}
					{item?.location &&
						renderSettingsResourceLabel(item?.location)}
				</span>
			</>
		);
	}, []);

	const noteColumn = useCallback((item) => {
		return ((item?.oldValues && item?.oldValues.note) || item?.note) && (
			<>
				<span className="text-xs font-weight-bold mb-1 mt-1">
					<i className="fa-note-sticky fa-solid mr-2" />
					{item?.oldValues && item?.oldValues.note && (
						<>
							<span className="text-xs font-weight-normal">
								<s style={{ color: "red" }}>
									<span style={{ color: "#525f7f" }}>
										{item?.oldValues.note}
									</span>
								</s>
								&nbsp; =&gt; &nbsp;
							</span>
						</>
					)}
					{item?.note}
				</span>
			</>
		);
	}, []);

	const content = useCallback(
		(item) => {
			return (
				item &&
				Object.keys(item).length > 0 && (
					<div className="d-flex flex-column">
						<span
							className=" mb-1 d-flex flex-wrap"
							style={{ alignItems: "center" }}
						>
							{dateColumn(item)}
							{payCodeColumn(item)}
							{hourTypeColumn(item)}
							{durationColumn(item)}
							{totalColumn(item)}
							{rateColumn(item)}
						</span>
						{jobColumn(item)}
						{locColumn(item)}
						<div className="d-flex flex-column text-sm font-weight-bold">
							{levels(item)}
						</div>
						{noteColumn(item)}
					</div>
				)
			);
		},
		[
			dateColumn,
			payCodeColumn,
			hourTypeColumn,
			durationColumn,
			totalColumn,
			levels,
			jobColumn,
			locColumn,
			noteColumn,
			rateColumn
		],
	);

	const renderTimeSheetLog = (item) => {
		return (
			<div className="w-100 p-3 border border-muted rounded shadow mb-1">
				{["lock", "unlock", "approve", "unapproved", "unapprove"].includes(
					item?.action,
				) ? (
					<div className="py-3 px-2">
						<b className="text-dark text-sm">
							{item?.user?.firstName +
								" " +
								item?.user?.lastName}
						</b>
						<span className="mx-2">
							{renderIcon(item?.action)}
						</span>
						<span className="text-sm">
							{t("action-timesheet-for-date", {
								action: t(renderAction(item?.action)),
							})}{" "}
						</span>
						<span className="text-sm font-weight-bolder">
							{moment(item?.date || data?.date)
								.format(dateFormat)}
						</span>
						<span className="text-sm"> on </span>
						<span className="text-sm mx-2">
							{moment(item?.createdAt).local()
								.format(dateFormat)} &nbsp;
							{moment(item?.createdAt).local()
								.format(timeFormat)}
						</span>
					</div>
				) : (
					<div className="d-flex p-2">
						{cardSider(item)}

						<div className="mx-3 d-flex flex-column wrap">
							<div className="text-sm mb-2 wrap">
								<span className="mr-2">
									{renderIcon(item?.action)}
								</span>
								{t(
									"action-timesheet-with-information",
									{ action: t(renderAction(item?.action)) },
								)}
								:
							</div>
							{content(item)}
						</div>
					</div>
				)}
			</div>
		);
	}

	const renderWorkSummaryLog = (item) => {
		return (
			<div className="w-100 p-3 border border-muted rounded shadow mb-1">
				<div className="py-3 px-2">
					<div className="mb-2">
						<b className="text-dark text-sm">
							{renderUserName(item?.user, false)}
						</b>

						<span className="mx-2">
							{renderIcon(item?.action)}
						</span>
						<span className="text-sm">
							{t("bulk-clock-for-date", {
								action: t(renderAction(item?.action)),
							})}{" "}
						</span>
						<span className="text-sm font-weight-bolder">
							{moment(item?.date)
								.format(dateFormat)}
						</span>
						<span className="text-sm"> on </span>
						<span className="text-sm mx-2">
							{moment(item?.createdAt)
								.format(dateFormat)} &nbsp;
							{moment(item?.createdAt)
								.format(timeFormat)}
						</span>
					</div>

					<div className="mb-2">
						{item.conflictingShiftIds
							? item.conflictingShiftIds.length === 1
								? t("shift-deleted-from-bulk")
								: t("shifts-deleted-from-bulk", { count: item.conflictingShiftIds.length })
							: null}
					</div>

					<div className="mb-2">
						{item.conflictingTimeSheetIds
							? item.conflictingTimeSheetIds.length === 1
								? t("timesheet-deleted-from-bulk")
								: t("timesheets-deleted-from-bulk", { count: item.conflictingTimeSheetIds.length })
							: null}
					</div>
				</div>
			</div>
		);
	}

	return loading ? (
		<div className="d-flex align-items-center justify-content-center w-100 h-100 my-5">
			<div
				className="spinner spinner-border spinner-border-lg text-primary"
				role="status"
			/>
		</div>
	) : !data || data?.length === 0 ? (
		<div className="w-100 d-flex flex-column justify-content-center align-items-center p-7">
			<i
				className="fa-solid fa-inbox text-muted"
				style={{ fontSize: "20px" }}
			/>

			<span className="text-gray font-weight-400 text-xs">
				{t("no-data")}
			</span>
		</div>
	) : (
		<CardBody
			style={{
				maxHeight: "inherit",
				overflowY: "auto",
				width: "100%",
			}}
		>
			{data?.map((item) => {
				switch (item.entity) {
					case "timesheet": {
						return item.value.map((item) => renderTimeSheetLog(item));
					}
					case "work-summary": {
						return item.value.map((item) => renderWorkSummaryLog(item));
					}
					default:
						return null;
				}
			})}
		</CardBody>
	);
}

export default History;
