import { Col, Row, Card, CardBody } from "reactstrap";
import ShiftView from "./ShiftView";
import Requests from "./Requests";
import { ClockInContainer } from "./store";
import { useCurrentShift } from "./ShiftView";
import AppLoader from "../../../components/Loaders/AppLoader";
import "./style.scss";

function TimeClock() {
	const loading = useCurrentShift();

	return (
		<ClockInContainer>
			<Row className="time-clock-my">
				<Col className="h-100" sm={12} lg={8}>
					{loading ? (
						<Card className="h-100">
							<CardBody>
								<AppLoader />
							</CardBody>
						</Card>
					) : (
						<ShiftView />
					)}
				</Col>

				<Col sm={12} lg={4} className="requests-margin">
					<Requests/>
				</Col>
			</Row>
		</ClockInContainer>
	);
}

export default TimeClock;
