import {
	useEffect,
	useRef,
	Children,
	isValidElement,
	cloneElement,
	useCallback,
	useState,
	useMemo,
} from "react";
import "./style.scss";

const mapStyle = { flexGrow: 1, height: "350px" };

const drawingManager = new window.google.maps.drawing.DrawingManager({
	drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
	drawingControl: false,
	drawingControlOptions: {
		position: window.google.maps.ControlPosition.TOP_CENTER,
		drawingModes: ["polygon"],
	},
	polygonOptions: {
		fillColor: "black",
		fillOpacity: 0.2,
		strokeWeight: 5,
		editable: true,
		zIndex: 1,
		strokeColor: "black",
		draggable: true,
		geodesic: true,
	},
});

function Map({
	options,
	children,
	coordinates,
	setCoordinates,
	setCenter,
	setZoom,
}) {
	const ref = useRef(null);
	const [hasInitCoords, setHasInitCoords] = useState(!!coordinates);
	const [map, setMap] = useState(null);

	const reset = useCallback(() => {
		setMap(null);
		setCoordinates(undefined);
		setHasInitCoords(false);
	}, [setMap, setCoordinates, setHasInitCoords]);

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map, setMap]);

	const polygon = useMemo(() => {
		if (hasInitCoords) {
			return new window.google.maps.Polygon({
				paths: coordinates,
				fillColor: "black",
				fillOpacity: 0.2,
				strokeWeight: 5,
				editable: true,
				zIndex: 1,
				strokeColor: "black",
				draggable: true,
				geodesic: true,
			});
		}
		return null;
		// eslint-disable-next-line
	}, [coordinates]);

	const getPath = useCallback(() => {
		const path = polygon.getPath();
		const len = path.getLength();
		const coordinates = [];
		for (let i = 0; i < len; i++) {
			const coordinate = path.getAt(i).toUrlValue(6).split(",");
			coordinates.push(coordinate);
		}
		const coords = [];
		coordinates.map((coordinate) =>
			coords.push({
				lat: Number(coordinate[0]),
				lng: Number(coordinate[1]),
			}),
		);
		setCoordinates(coords);

		setTimeout(() => {
			window.google.maps.event.clearInstanceListeners(polygon);
			polygon.setMap(null);
		}, 100);
	}, [polygon, setCoordinates]);

	useEffect(() => {
		if (drawingManager && map) {
			drawingManager.setMap(map);
		}
	}, [map]);

	useEffect(() => {
		if (polygon && map) {
			polygon.setMap(map);
		}
	}, [polygon, map]);

	useEffect(() => {
		if (polygon) {
			window.google.maps.event.addListener(polygon, "dragend", getPath);
		}
	}, [polygon, getPath]);

	useEffect(() => {
		if (polygon) {
			window.google.maps.event.addListener(
				polygon.getPath(),
				"insert_at",
				getPath,
			);
		}
	}, [polygon, getPath]);

	useEffect(() => {
		if (polygon) {
			window.google.maps.event.addListener(
				polygon.getPath(),
				"remove_at",
				getPath,
			);
		}
	}, [polygon, getPath]);

	const getDrawingManagerPath = useCallback(
		(event) => {
			const coords = event
				.getPath()
				.getArray()
				.map((value) => ({
					lat: value.lat(),
					lng: value.lng(),
				}));

			setCoordinates(coords);
		},
		[setCoordinates],
	);

	useEffect(() => {
		window.google.maps.event.addListener(
			drawingManager,
			"polygoncomplete",
			(event) => {
				setZoom(event.map.zoom);
				getDrawingManagerPath(event);

				const paths = event.getPaths();

				for (let i = 0; i < paths.length; i++) {
					window.google.maps.event.addListener(
						paths.getAt(i),
						"insert_at",
						() => getDrawingManagerPath(event),
					);

					window.google.maps.event.addListener(
						paths.getAt(i),
						"remove_at",
						() => {
							getDrawingManagerPath(event);
						},
					);

					window.google.maps.event.addListener(
						paths.getAt(i),
						"set_at",
						() => {
							getDrawingManagerPath(event);
						},
					);
				}
			},
		);
	}, [getDrawingManagerPath, setZoom]);

	useEffect(() => {
		if (drawingManager) {
			drawingManager.setDrawingMode(coordinates ? null : "polygon");
		}
	}, [coordinates]);

	// useEffect(() => {
	// 	if (!map) {
	// 		return;
	// 	}

	// 	if (coordinates && polygon) {
	// 		const bounds = new window.google.maps.LatLngBounds();
	// 		coordinates.forEach((position) => bounds.extend(position));
	// 		map.fitBounds(bounds, 0);
	// 	}
	// }, [map, coordinates, polygon]);

	useEffect(() => {
		if (map && options) {
			map.setOptions(options);
		}
	}, [map, options]);

	useEffect(() => {
		if (coordinates) {
			let lat = 0;
			let lng = 0;

			coordinates.map((c) => {
				lat += Number(c.lat);
				lng += Number(c.lng);
				return;
			});

			setCenter({
				lat: lat / coordinates.length,
				lng: lng / coordinates.length,
			});
		}
	}, [coordinates, setCenter]);

	return (
		<div className="org-level-map">
			<div ref={ref} style={mapStyle} />

			<div className="w-100 text-right">
				<i
					className="reset-btn fa-solid fa-rotate cursor-pointer bg-white border"
					onClick={reset}
				/>
			</div>

			{Children.map(
				children,
				(child) =>
					isValidElement(child) && cloneElement(child, { map }),
			)}
		</div>
	);
}

export default Map;
