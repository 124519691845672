import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../../../components/Field";
import Checkbox from "../../../../../../../../../components/Inputs/Checkbox";
import Modal from "../../../../../../../../../components/Modal";
import Details from "./Details";
import { getSmallerThanRuleValidator } from "../validator";

const getFieldName = (name) => ["overtime", "daily-overtime", name];

function DailyOvetime({ disabled }) {
	const { t } = useTranslation();

	const [visible, setVisible] = useState(false);

	const openModal = useCallback(() => setVisible(true), [setVisible]);

	const closeModal = useCallback(() => setVisible(false), [setVisible]);

	return (
		<div className="calculation-group-rule">
			<div className="d-flex align-items-center mr-1 w-100">
				<Field
					name={getFieldName("status")}
					valuePropName="checked"
					noStyle
					className="mb-0"
				>
					<Checkbox id="daily-overtime">
						{t("daily-overtime")}
					</Checkbox>
				</Field>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldError }) => {
					const data = getFieldValue(["overtime", "daily-overtime"]);

					const status = data.status;
					const error = getFieldError(getFieldName("value"));

					const weeklyOvertime = getFieldValue([
						"overtime",
						"weekly-overtime",
					]);

					return (
						<>
							<div className="d-flex align-items-center">
								<BaseField
									name={getFieldName("value")}
									rules={
										status
											? [
												{
													required: status,
													message: t(
														"required-daily-overtime-after",
													),
												},
												getSmallerThanRuleValidator(
													t,
													"daily-overtime",
													"weekly-overtime",
												),
											]
											: undefined
									}
									noStyle
									dependencies={[
										getFieldName("status"),
										[
											"overtime",
											"weekly-overtime",
											"value",
										],
									]}
									className="mb-0"
								>
									<Input
										className="border-primary"
										size="sm"
										readOnly={!status}
										type="number"
										disabled={disabled}
									/>
								</BaseField>

								<BaseField
									name={getFieldName("defaultPayCode")}
									hidden
								/>

								<BaseField
									name={getFieldName("payCodes")}
									hidden
								/>

								<BaseField
									name={getFieldName("hourTypes")}
									hidden
								/>

								<p className="text-sm mx-2 mb-0 text-center">
									{t("hours-day")}
								</p>
							</div>

							<div className="d-flex align-items-center justify-content-end">
								<FontAwesomeIcon
									className="text-sm cursor-pointer"
									icon={faPen}
									onClick={status ? openModal : undefined}
								/>
							</div>

							{error && (
								<span className="invalid-feedback d-block">
									{error}
								</span>
							)}

							<Modal
								title={t("daily-overtime-rule-parameters")}
								isOpen={visible}
								close={closeModal}
								centered={true}
							>
								<Details
									close={closeModal}
									overtime={data}
									weeklyOvertime={weeklyOvertime}
									disabled={disabled}
								/>
							</Modal>
						</>
					);
				}}
			</BaseField>
		</div>
	);
}

export default DailyOvetime;
