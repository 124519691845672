import { useContext, useMemo, useCallback, useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import BaseForm from "rc-field-form";
import { Wrapper } from "@googlemaps/react-wrapper";
import Search from "./Map/Search";
import Map from "./Map";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import Button from "../../../../../components/Button";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import StatusField from "../../../../../components/Field/StatusField";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import { useLocation, useSearch } from "./Map/helpers";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";

const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };
const defaultZoom = 11;
const fields = ["id", "code", "description", "glSegment", "location", "mapCenter", "mapZoom"];

function Form({ values, submit, loading, error, close }) {
	const { t } = useTranslation();
	const [form] = BaseForm.useForm();
	const { groups } = useContext(OrgLevelGroupsContext);
	const [level, setLevel] = useState();

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const coords = useMemo(() => {
		const validCoord = (values?.polygon?.coordinates || []).filter(c => Array.isArray(c) && c.every(l => l !== null));
		if (validCoord.length) {
			const coordinates = validCoord[0]?.map(
				(coordinate) => ({
					lat: coordinate[1],
					lng: coordinate[0],
				}),
			);

			return coordinates?.slice(0, -1);
		}
		return undefined;
	}, [values?.polygon?.coordinates[0]]);

	const [area, _setArea] = useState(coords);
	const setArea = useCallback((value) => {
		form.setFieldValue("area", value);
		form.validateFields(["area"])
		_setArea(value);
	}, [_setArea]);

	const formattedCoords = useMemo(() => {
		let coords = [];
		area?.forEach(({ lat, lng }) => coords.push([lng, lat]));
		return coords;
	}, [area]);

	const {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	} = useLocation(form);

	const onFinish = useCallback(
		(formValues) => {
			const data = {
				...formValues,
				marker,
				mapCenter: center,
				mapZoom: zoom,
				location: formValues?.location?.id,
				timezone: formValues?.timezone?.id,
				project: formValues?.project?.id,
				polygon: {
					type: "Polygon",
					coordinates: [[...formattedCoords, formattedCoords[0]]],
				},
			};
			submit(data);
		},
		[submit, marker, zoom, center, area, formattedCoords],
	);

	const onLevelChange = useCallback((value) => {
		const validCoord = (value?.location?.coordinates || []).filter(c => Array.isArray(c) && c.every(l => l !== null));

		if (validCoord?.length) {
			const coordinates = validCoord[0]?.map(
				(coordinate) => ({
					lat: coordinate[1],
					lng: coordinate[0],
				}),
			);
			setLevel({
				coordinates: coordinates?.slice(0, -1),
				mapCenter: value?.mapCenter,
				mapZoom: value?.mapZoom,
				hasChange: true
			});
		} else {
			setLevel({ hasChange: true });
		}
	}, [setLevel]);

	const { search, setSearch, handleSelect } = useSearch(
		changeMarker,
		setArea,
	);

	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			timeDifference: values?.timeDifference ? Number(values?.timeDifference) : 60,
			area: coords,
			...values,
		});
		_setArea(coords);
	}, [form, values, coords, _setArea]);

	useEffect(() => {
		setCenter(values?.mapCenter || defaultCenter);
		setZoom(values?.mapZoom || defaultZoom);
		setMarker(values?.marker || null);
	}, [setZoom, setCenter, setMarker]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["description", "status", "project", "timezone", "location"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<BaseForm form={form} onFinish={onFinish}>
			<Field
				label={t("description")}
				name="description"
				rules={[
					{ required: true, message: t("required-description") },
				]}
			>
				<Text placeholder={t("description")} />
			</Field>

			<Field label={t("location")} name="location">
				<ResourceSelect
					labelPropName="description"
					resourcePath="/locations"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("location")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
				/>
			</Field>

			<Field
				label={t("timezone")}
				name="timezone"
				rules={[
					{
						required: true,
						message: t("required-timezone"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="text"
					resourcePath="/timezone?pagination=false"
					hasCompany={false}
					hasSearch
					placeholder={t("timezone")}
				/>
			</Field>

			{group && (
				<Field
					name="project"
					label={t(group.code + " - " + group.description)}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath={`${group.id}/org-levels`}
						renderLabel={renderOrgLevelResourceLabel}
						getSearchFilters={(search) => 
							getSettingsOrgResourceSearchFilters(
								search, 
								fields
							)
						}
						hasSearch
						onChange={onLevelChange}
						placeholder={group.code + " - " + group.description}
					/>
				</Field>
			)}

			<Wrapper
				apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
			>
				<Search
					search={search}
					setSearch={setSearch}
					handleSelect={handleSelect}
				/>
			</Wrapper>

			<Map
			    level={level}
				marker={marker}
				center={center}
				zoom={zoom}
				area={area}
				setArea={setArea}
				setCenter={setCenter}
				setZoom={setZoom}
				setLevel={setLevel}
			/>

			<div style={{ marginTop: "-2.5rem" }}>
				<Field
					name="area"
					rules={[
						{
							required: true,
							message: t("area-required"),
						},
					]}
					children={<Fragment />}
				/>
			</div>

			<Field
				name="timeDifference"
				label={t("time-difference")}
				rules={[
					{
						required: true,
						message: t("required-time-difference"),
					},
					{
						validator(_, value) {
							if (Number(value) < 0) {
								return Promise.reject(new Error(
									t("time-difference-pattern")
								))
							}
							return Promise.resolve()
						}
					},
				]}
			>
				<Text type="number" placeholder={t("time-difference")} />
			</Field>

			<StatusField />

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				<Button
					type="submit"
					className="btn-dark btn-sm shadow-none"
					loading={loading}
				>
					{t("save")}
				</Button>
			</div>
		</BaseForm>
	);
}

export default Form;
