import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { useQrCodeClockIn } from "../context";
import { useShiftAction, useClockIn } from "./store";
import { useCurrentShift } from ".";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useClock = () => {
	const { token, qrCode, user } = useQrCodeClockIn();
	const { authPost } = useApi();
	// eslint-disable-next-line
	const [_, { setWorkSummary }] = useShiftAction();
	// eslint-disable-next-line
	const [{ }, { setShiftAction }] = useClockIn();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const currentShift = useCurrentShift();

	const submit = useCallback(
		async (values, onSuccess, onFailure) => {
			setLoading(true);
			setError(null);
			try {
				const data = {
					device: "qr-code",
					...values,
				};

				if (user && token && qrCode) {
					const response = await authPost("/shift", {
						data,
						headers: {
							Authorization: `Bearer ${token}`,
							Platform: "qr-code",
							PLATFORM_ID: qrCode?.id,
							Company: user?.companies[0]?.id,
							MainCompany: user?.mainCompany,
						},
					});
					onSuccess?.();
					setWorkSummary(response);
				}
			} catch (err) {
				setError(err);
				onFailure?.();
				if (typeof err === "string") {
					toast.error(err);
					setShiftAction(null);
					return currentShift;
				}
			} finally {
				setLoading(false);
			}
		},
		[
			setLoading,
			setError,
			authPost,
			setWorkSummary,
			currentShift,
			setShiftAction,
			token,
			qrCode?.id,
			user,
		],
	);

	return { loading, error, submit };
};

export const useUpdateShift = () => {
	const { t } = useTranslation();
	const { authPut } = useApi();
	const { token, qrCode, user } = useQrCodeClockIn();
	const [loading, setLoading] = useState(false);

	const update = useCallback(async (id, data, onSuccess) => {
		setLoading(true);
		try {
			if (user && token && qrCode) {
				const response = await authPut(`/shift/${id}`, {
					data,
					headers: {
						Authorization: `Bearer ${token}`,
						Platform: "qr-code",
						PLATFORM_ID: qrCode?.id,
						Company: user?.companies[0]?.id,
						MainCompany: user?.mainCompany,
					},
				});
	
				if(response){
					t("added-successfully");
					onSuccess?.(response);
				}
			}
		} catch(err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [token, qrCode?.id, user, setLoading, authPut, t]);

	return { update, loading }
}
