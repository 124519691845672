import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Password from "../../../../../../components/Inputs/Password";
import Select from "../../../../../../components/Inputs/Select";
import Text from "../../../../../../components/Inputs/Text";

function FtpOption({ disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<Field
				name="protocol"
				label={t("protocol")}
				rules={[{ required: true, message: t("required-protocol") }]}
			>
				<Select placeholder={t("protocol")} disabled={disabled}>
					<Select.Option value="ftp">{t("ftp")}</Select.Option>
					<Select.Option value="sftp">{t("sftp")}</Select.Option>
					<Select.Option value="ftps">{t("ftps")}</Select.Option>
				</Select>
			</Field>

			<Field
				name="host"
				label={t("host")}
				rules={[{ required: true, message: t("required-host") }]}
			>
				<Text placeholder={t("host")} disabled={disabled} />
			</Field>

			<Field
				name="port"
				label={t("port")}
				rules={[{ required: true, message: t("required-port") }]}
			>
				<Text placeholder={t("port")} disabled={disabled} />
			</Field>

			<Field
				name="path"
				label={t("path")}
				rules={[{ required: true, message: t("required-path") }]}
			>
				<Text placeholder={t("path")} disabled={disabled} />
			</Field>

			<Field
				name="ftpUsername"
				label={t("username")}
				rules={[{ required: true, message: t("required-username") }]}
			>
				<Text placeholder={t("username")} disabled={disabled} />
			</Field>

			<Field
				name="ftpPassword"
				label={t("password")}
				rules={[{ required: true, message: t("required-password") }]}
			>
				<Password placeholder={t("password")} disabled={disabled} />
			</Field>

			<Field
				name="filename"
				label={t("filename")}
				rules={[{ required: true, message: t("required-filename") }]}
			>
				<Text placeholder={t("filename")} disabled={disabled} />
			</Field>

			<div className="d-flex flex-column text-sm">
				<span>
					{t("allowed-variables-for-filename")}:{" "}
					<span className="text-info">*</span>
				</span>

				<ol type="i" style={{ columns: 2, columnGap: 16 }}>
					<li>{"{{"}current-date{"}}"}</li>
					<li>{"{{"}current-time{"}}"}</li>
					<li>{"{{"}timestamp{"}}"}</li>
					<li>{"{{"}period-start{"}}"}</li>
					<li>{"{{"}period-end{"}}"}</li>
					<li>{"{{"}report-name{"}}"}</li>
				</ol>
			</div>
		</>
	);
}

export default FtpOption;
