import steps from "../steps";
import { useSettingsOnboarding } from "../context";
import {useTranslation} from "react-i18next";
import {Card, Progress} from "reactstrap";
import classNames from "classnames";

function Steps() {
    const { t } = useTranslation();
    const { step } = useSettingsOnboarding();

    return (
        <Card className="steps-card d-flex flex-row justify-content-center bg-gradient-launch">
            <div className="col-10 d-flex">
                <section class="step-wizard">
                    <ul class="step-wizard-list-launch">
                        {steps.map(({ name }, i) => (
                            <li
                                key={(i).toString()}
                                className={classNames(
                                    "step-wizard-item current-item step-wizard-margin",
                                    (step >= i) && "on-item"
                                )}
                            >
                                <span class="progress-count">
                                    {i + 1}
                                </span>
                                <span class="progress-label">
                                    {t(name)}
                                </span>
                            </li>
                        ))}
                    </ul>
                </section>
            </div>

            <div className="border-left col-2 d-flex align-items-center justify-content-center dnone">
                <div className="col-10">
                    <Progress
                        className="progress-xs mb-0 "
                        color="orange"
                        max="100"
                        value={(step / 8) * 100}
                    />
                </div>
            </div>
        </Card>
    );
}

export default Steps;
