import moment from "moment-timezone";
import { useWatch } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { renderDurationAsFormat } from "../../../utils/helpers/date";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { useCompanyTimeFormat } from "../../../utils/hooks/company";

function PaidHours() {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
	const values = useWatch();

	let days = 0;
	let hours = "00:00";

	if (values?.allDay) {
		if (values?.startDate && values?.endDate) {
			days =
				moment(values?.endDate).diff(moment(values?.startDate), "day") +
				1;
			const duration = days === 0 ? "08" : (days * 8).toString();

			hours = `${duration}:00`;
		}
	} else {
		if (values?.date && values?.startDate && values?.endDate) {
			const start = combineDateAndTime(values?.date, values?.startDate);
			const end = combineDateAndTime(values?.date, values?.endDate);
			days = 1;
			const duration = end.diff(start, "second");
			hours = renderDurationAsFormat(duration, timeFormat);
		}
	}

	return (
		<div className="d-flex justify-content-between align-items-baseline pb-0">
			<h5 className="text-primary">{t("total-paid-hours")}</h5>
			<div className="d-flex flex-column text-primary">
				{values?.allDay && (
					<span className="flex justify-end">
						{days} {days === 1 ? t("day") : t("days")}
					</span>
				)}

				<span>
					( {t("work-hours", { hours })})
				</span>
			</div>
		</div>
	);
}

export default PaidHours;
