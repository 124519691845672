import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
} from "../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit }) => {
	const { t } = useTranslation();

	const { groups } = useContext(OrgLevelGroupsContext);

	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const { status } = useCommonColumns();

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("description", {
				enableColumnFilter: true,
				header: t("clock-name"),
			}),
			columnHelper.accessor("macAddress", {
				enableColumnFilter: true,
				header: t("serial-number"),
			}),
			columnHelper.accessor("deviceMacAddress", {
				header: t("device-id"),
			}),
			status,
			columnHelper.accessor("location", {
				enableColumnFilter: true,
				header: t("location"),
				cell: (info) => {
					const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/locations"
						renderLabel={renderSettingsResourceLabel}
						mode="multiple"
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			}),
			columnHelper.accessor("timezone.text", {
				header: t("timezone"),
			}),
		];

		if (group) {
			const primaryGroupColumn = columnHelper.accessor("project", {
				header: t("primary-group", {
					group: group.description
				}),
				cell: (info) => {
					const value = info.getValue();
					return value && renderOrgLevelResourceLabel(value);
				},
			});
			const otherProjectsColumn = columnHelper.accessor("otherProjects", {
				header: group.description,
				cell: (info) => {
					const value = info.getValue();
					if (!(value?.length > 0)) {
						return null;
					}
					return (
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "1fr 1fr",
							}}
						>
							{value.map((project) => (
								<span
									className="mr-1 mb-1 border rounded border-primary px-3 py-2 text-primary text-center"
									style={{
										textOverflow: "ellipsis",
										width: "100px",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									{renderOrgLevelResourceLabel(project)}
								</span>
							))}
						</div>
					);
				},
			});
			columns.push(
				primaryGroupColumn,
				columnHelper.accessor("project.code", {
					header: t("code"),
				}),
				columnHelper.accessor("project.glSegment", {
					header: t("gl-segment"),
				}),
				otherProjectsColumn
			);
		}

		const actionsColumn = columnHelper.display({
			id: "actions",
			enableHiding: false,
			enableColumnFilter: false,
			header: () => (
				<div className="text-right">
					{t("actions")}
				</div>
			),
			cell: (info) => {
				const { id, company, isDefault, connected } = info.row.original;
				return (
					<div className="d-flex align-items-center text-primary text-right">
						<span className="btn-inner--icon mr-3 icon-sm cursor-pointer">
							{connected ? (
								<span title={t("Disconnect")}>
									<i className="fa-solid fa-link" />
								</span>
							) : (
								<span title={t("Connect")}>
									<i className="fa-solid fa-link-slash" />
								</span>
							)}
						</span>

						<div className="actions text-primary text-right">
							{company && !isDefault && edit && (
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer"
									onClick={() => edit(id)}
									title={t("Edit")}
								>
									<i className="fas fa-pen" />
								</span>
							)}
						</div>
					</div>
				);
			},
		});
		columns.push(actionsColumn);
		return columns;
	}, [t, edit, group, status]);
};
