import { useState, useCallback } from "react";
import moment from "moment-timezone";

export const useUpdateTimeSheet = () => {
	const [data, setData] = useState({});

	const update = useCallback(
		(id, d) => {
			setData((prev) => {
				const existing = prev[id];
				let data;
				if (typeof d === "function") {
					data = d(existing);
				} else {
					data = d;
				}
				const updated = {
          isTouched: true,
					...(existing || {}),
					...data,
				};

				if (
					existing?.payCode?.hourType === "amount" &&
					"payCode" in data &&
					data.payCode?.hourType !== "amount"
				) {
					delete updated.rate;
				}

				if (["amount", "unit"].includes(data?.payCode?.hourType)) {
					const date = moment().startOf("day").toISOString();
					updated.startDate = date;
					updated.endDate = date;
				}

				return { ...prev, [id]: updated };
			});
		},
		[setData]
	);

  const resetOne = useCallback((id) => {
    setData((prev) => {
      return {
        ...prev,
        [id]: undefined,
      };
    });
  }, [setData]);

	const reset = useCallback(() => {
		setData({});
	}, [setData]);

	return { data, update, reset, resetOne };
}
