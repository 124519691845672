import { useMemo, useCallback, Fragment } from "react";
import { normalize } from "../../utils/helpers/normalize";
import Statistic from "./Statistic";
import AnalyticsTable from "./Table";

function Analytics({ hourTypes, data, loading, statistics }) {
    const renderStatisticsElements = useCallback((config) => {
        if (Array.isArray(config)) {
            return (
                <div className="d-flex flex-column gap-y-2">
                    {config.map((item, i) => (
                        <Fragment key={(i).toString()}>
                            {renderStatisticsElements(item)}
                        </Fragment>
                    ))}
                </div>
            );
        }
        return (
            <Statistic loading={loading} {...config} />
        );
    }, [loading]);

    const config = useMemo(() => {
        return normalize(data || [], "hourType");
    }, [data]);

    return (
        <div className="d-flex justify-content-between">
            <AnalyticsTable
                hourTypes={hourTypes}
                config={config}
                data={data}
            />

            <div className="d-flex">
                {statistics.map((item, i) => (
                    <Fragment key={(i).toString()}>
                        {renderStatisticsElements(item)}
                    </Fragment>
                ))}
            </div>
        </div>
    );
}

export default Analytics;
