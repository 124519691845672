export const renderSettingsResourceLabel = (val) => {
	if (val) {
		const { code, description } = val;
		return `${code}-${description}`;
	} 
	return ''
}

export const getPathWithParams = (basePath, withoutLookups, filters = {}, fields = [], otherFilters = {}) => {
	const params = [];
	if (withoutLookups) {
		params.push('withoutLookups=true');
	}
	if(Object.keys(filters).length > 0) {
		Object.keys(filters).forEach((key) => {
			const value = filters[key];
			if (Array.isArray(value)) {
				value.forEach((val) => {
					params.push(`filter[${key}][method]=exact&filter[${key}][value]=${val}`);
				});
			} else {
				params.push(`filter[${key}][method]=exact&filter[${key}][value]=${value}`);
			}
		});
	}
	if (Object.keys(otherFilters).length > 0) {
		Object.keys(otherFilters).forEach((key) => {
			const value = otherFilters[key];
			if (Array.isArray(value)) {
				value.forEach((val) => {
					params.push(`${key}[]=${val}`);
				});
			} else {
				params.push(`${key}[]=${value}`);
			}
		});
	}
	if (fields.length > 0) {
		fields.forEach((field) => {
			params.push(`fields[]=${field}`);
		});
	}
	return `${basePath}?${params.join('&')}`;
}

export const renderOrgLevelResourceLabel = (val) => {
	if (val) {
		const { code, description, glSegment } = val;
		return glSegment ? `${glSegment}-${description}` : `${code}-${description}`;
	}
	return ''
}

export const renderCompanyResourceLabel = (val) => {
	if (val) {
		const {
			externalCompanyCode,
			code,
			name,
		} = val;
		if (code && name) {
			return `${externalCompanyCode || code} - ${name}`;
		}
	}
	return ''
};

export const getSettingsOrgResourceSearchFilters = (search, fields = ["id", "code", "description", "glSegment"]) => ({
	"filter[code][method]": "contains",
	"filter[code][value]": search,
	"filter[description][method]": "contains",
	"filter[description][value]": search,
	"filter[glSegment][method]": "contains",
	"filter[glSegment][value]": search,
	fields,
	filterType: "or",
	withoutLookups: "true",
});

export const getSettingsResourceSearchFilters = (search) => ({
	"filter[code][method]": "contains",
	"filter[code][value]": search,
	"filter[description][method]": "contains",
	"filter[description][value]": search,
	fields: ["id", "code", "description"],
	filterType: "or",
	withoutLookups: "true",
});

export const getSettingsResourceSearchFiltersWithLookups = (search) => ({
	"filter[code][method]": "contains",
	"filter[code][value]": search,
	"filter[description][method]": "contains",
	"filter[description][value]": search,
});
