import { Row, Col } from "reactstrap";

function InformationRow({ title, value }) {
	return (
		<Row>
			<Col xs="6">
				<h4>{title}</h4>
			</Col>

			<Col xs="6">
				<p>{value}</p>
			</Col>
		</Row>
	);
}

export default InformationRow;
