import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import Checkbox from "../../../../../components/Inputs/Checkbox";
import InputNumberWithSuffix from "../../../../../components/Inputs/InputNumberWithSuffix";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";

function OptionalSettings({ data, submit, goBack, error, disabled }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const onFinish = useCallback(
		(values) => {
			submit(values);
		},
		[submit],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			maxBalance: data.maxBalance,
			preventNegativeBalance: data.preventNegativeBalance || false,
			carryoverLimit: data.carryoverLimit,
			waitingPeriod: data.waitingPeriod,
			tenureAccural: data ? data.tenureAccural : false,
		});
	}, [form, data]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			className="d-flex flex-column justify-content-between h-100"
		>
			<div>
				<p className="h4 mb-2 front-weight-bolder">
					{t("max-balance")}
				</p>

				<p className="mb-2 text-sm">
					{t("max-balance-description")}
				</p>

				<Field name="maxBalance" className="w-25">
					<InputNumberWithSuffix
						suffix="hr"
						className="border-right-0"
						inputGroupClassName="border-left-0"
						disabled={disabled}
					/>
				</Field>

				<p className="h4 mb-2 front-weight-bolder">
					{t("negative-balance")}
				</p>

				<p className="mb-2 text-sm">
					{t("negative-balance-description")}
				</p>

				<Field name="preventNegativeBalance" valuePropName="checked">
					<Checkbox id="preventNegativeBalance" disabled={disabled}>
						{t("prevent-negative-balances")}
					</Checkbox>
				</Field>

				<p className="h4 mb-2 front-weight-bolder">
					{t("carryover-limit")}
				</p>

				<p className="mb-2 text-sm">
					{t("carryover-limit-description")}
				</p>

				<Field name="carryoverLimit" className="w-25">
					<InputNumberWithSuffix
						suffix="hr"
						className="border-right-0"
						inputGroupClassName="border-left-0"
						disabled={disabled}
					/>
				</Field>

				<p className="h4 mb-2 front-weight-bolder">
					{t("waiting-period")}
				</p>

				<p className="mb-2 text-sm">
					{t("waiting-period-description")}
				</p>

				<Field name="waitingPeriod" className="w-25">
					<InputNumberWithSuffix
						suffix="hr"
						className="border-right-0"
						inputGroupClassName="border-left-0"
						disabled={disabled}
					/>
				</Field>

				<p className="h4 mb-2 front-weight-bolder">
					{t("tenure-accrual")}
				</p>

				<p className="mb-2 text-sm">
					{t("tenure-accrual-description")}
				</p>

				<Field name="tenureAccural" valuePropName="checked">
					<Checkbox id="tenureAccural" disabled={disabled}>
						{t("enable-tenure-accrual")}
					</Checkbox>
				</Field>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border"
					size="sm"
					onClick={goBack}
				>
					{t("back")}
				</Button>

				<Button
					type="submit"
					className="btn-round btn-icon shadow-none border px-3"
					color="dark"
					size="sm"
				>
					{t("continue")}
				</Button>
			</div>
		</Form>
	);
}

export default OptionalSettings;
