import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Field from "../Field";
import Text from "../Inputs/Text";
import StatusField from "../Field/StatusField";
import { useFocusOnElement } from "../../utils/hooks/useFocusOnElement";
import { firstToUpper } from "../../utils/helpers/string";

function BaseSettingsFormContent({ mode, disabled }) {
	const { t } = useTranslation();

	const ref = useFocusOnElement();

	const getCodeValueFromEvent = useCallback(
		(e) => e.target.value.toUpperCase(),
		[],
	);

	const getDescriptionValueFromEvent = useCallback(
		(e) => firstToUpper(e.target.value),
		[],
	);

	return (
		<>
			<Field
				label={t("code")}
				name="code"
				getValueFromEvent={getCodeValueFromEvent}
				rules={[
					{
						required: true,
						message: t("required-code"),
					},
					{
						message: t("code-max-length"),
						min: 1,
						max: 10,
					},
					{
						pattern: /^[a-zA-Z0-9]+$/,
						message: t(
							"code-pattern",
						),
					},
				]}
			>
				<Text
					ref={mode !== "edit" ? ref : undefined}
					placeholder={t("code")}
					disabled={mode === "edit" || disabled}
				/>
			</Field>

			<Field
				label={t("description")}
				name="description"
				getValueFromEvent={getDescriptionValueFromEvent}
				rules={[
					{
						required: true,
						message: t("required-description"),
					},
				]}
			>
				<Text placeholder={t("description")} disabled={disabled} />
			</Field>

			<StatusField disabled={disabled} />
		</>
	);
}

export default BaseSettingsFormContent;
