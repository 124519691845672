import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { toast } from "react-toastify";
import Topbar from "../../../components/Table/Topbar";
import TopbarAction from "../../../components/Table/Topbar/Action";
import Drawer from "../../../components/Drawer";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import DetailsModal from "./DetailsModal";
import { DateFiltersProvider } from "../../../components/DateRangeFilters/useDateFilters";
import OriginalClocksTable from "./Table";
import { useOriginalClocksExport } from "./api";
import { useVisible } from "../../../utils/hooks/useVisible";

function OriginalClocks() {
	const { t } = useTranslation();
	const { originalClocksExport, loading } = useOriginalClocksExport();
	const [exportData, setExportData] = useState();
	const {
		visible: isDetailsModalOpen,
		open: openDetailsModal, 
		close: closeDetailsModal, 
		selected: details
	} = useVisible();

	const {
		visible: isDrawerOpen,
		open: openDrawer,
		close: closeDrawer,
	} = useVisible();

	const {
		open: openProfileDrawer,
		close: closeProfileDrawer,
		visible: isProfileDrawerVisible,
		selected: selectedUser,
	} = useVisible();

	// const [detailsModal, setDetailsModal] = useState({
	// 	isOpen: false,
	// 	values: undefined,
	// });

	// const openDetailsModal = useCallback(
	// 	(values) =>
	// 		setDetailsModal({
	// 			isOpen: true,
	// 			values,
	// 		}),
	// 	[setDetailsModal],
	// );

	// const closeDetailsModal = useCallback(
	// 	() =>
	// 		setDetailsModal({
	// 			isOpen: false,
	// 			values: undefined,
	// 		}),
	// 	[setDetailsModal],
	// );

	const onExport = useCallback(
		() =>
			originalClocksExport(exportData, () =>
				toast.success(t("original-clocks-exported-successfully")),
			),
		[originalClocksExport, exportData, t],
	);

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar title={t("original-clocks")}>
					<TopbarAction
						onClick={onExport}
						loading={loading}
						disabled={loading}
					>
						{t("export")}
					</TopbarAction>

					<TopbarAction disabled>{t("print")}</TopbarAction>

					<TopbarAction onClick={openDrawer}>
						{t("help")}
					</TopbarAction>
				</Topbar>

				<Card
					className="m-0 p-0"
					style={{ height: "calc(100% - 94px)" }}
				>
					<DateFiltersProvider>
						<OriginalClocksTable
							openDetailsModal={openDetailsModal}
							setExportData={setExportData}
							openProfileDrawer={openProfileDrawer}
						/>
					</DateFiltersProvider>
				</Card>
			</Card>

			{isDetailsModalOpen && (
				<DetailsModal
					isOpen={isDetailsModalOpen}
					values={details}
					close={closeDetailsModal}
				/>
			)}

			<Drawer open={isDrawerOpen} close={closeDrawer} title={t("help")}>
				<>{t("help")}</>
			</Drawer>

			<ProfileDrawer
				visible={isProfileDrawerVisible}
				close={closeProfileDrawer}
				user={selectedUser}
			/>
		</div>
	);
}

export default OriginalClocks;
