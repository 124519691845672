import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/Inputs/Select";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { firstToUpper } from "../../../../utils/helpers/string";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const {
        code,
        description,
        status,
        actions,
    } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			columnHelper.accessor("type", {
				enableColumnFilter: true,
				header: t("type"),
				cell: (info) => {
                    const value = info.getValue();
                    return value && firstToUpper(value);
                },
				filterType: "select",
				Filter: (
					<Select>
						<Select.Option value="text">{t("text")}</Select.Option>
						<Select.Option value="number">
							{t("number")}
						</Select.Option>
						<Select.Option value="email">
							{t("email")}
						</Select.Option>
						<Select.Option value="select">
							{t("select")}
						</Select.Option>
					</Select>
				),
			}),
			columnHelper.accessor("isRequired", {
				header: t("is-required"),
				cell: (info) => {
					const value = info.getValue();
					return value ? t("yes") : t("no")
				}
			}),
			status,
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [
		t,
		isMasterCompany,
		code,
		description,
		status,
		actions,
	]);
};
