import { useEffect, useRef, useCallback } from "react";
import {
	useBeforeUnload as useRouterBeforeUnload,
	unstable_useBlocker as useBlocker,
} from "react-router-dom";

export const useBeforeUnload = (message, { beforeUnload } = {}) => {
	let blocker = useBlocker(
		useCallback(
			() =>
				typeof message === "string" ? !window.confirm(message) : false,
			[message],
		),
	);

	let prevState = useRef(blocker.state);
	useEffect(() => {
		if (blocker.state === "blocked") {
			blocker.reset();
		}
		prevState.current = blocker.state;
	}, [blocker]);

	useRouterBeforeUnload(
		useCallback(
			(event) => {
				if (beforeUnload && typeof message === "string") {
					event.preventDefault();
					event.returnValue = "";
					return "";
				}
			},
			[message, beforeUnload],
		),
		{ capture: true },
	);
};
