import { useAuthentication } from "../../features/Authentication/context";

export const useCompany = () => {
	const { company } = useAuthentication();
	return company;
};

export const useMainCompany = () => {
	const { company, user } = useAuthentication();
	return user?.company || company;
};

export const useHasCompany = () => {
	return !!useCompany();
};

export const useCompanyId = () => {
	const company = useCompany();
	return company?.id;
}

export const useIsMasterCompany = () => {
	const company = useCompany();
	return company?.isMasterCompany || false;
};

export const useCompanyTimeFormat = () => {
	const company = useCompany();
	return company?.settings.timeFormat || 'hh:mm A';
};

export const useCompanyDateFormat = () => {
	const company = useCompany();
	return company?.settings.dateFormat;
};

export const useCompanyCurrency = () => {
	const company = useCompany();
	return company?.settings.currency;
};

export const useCompanyTimeZone = () => {
	const company = useCompany();
	return company?.settings?.timezone?.value;
};
