import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import Select from "../Select";

const options = [
	"usd",
	"eur",
	"gbp",
	"cad",
	"chf",
];

function CurrencySelect({ ...props }) {
	const { t } = useTranslation();

	return (
		<Select {...props}>
			{options.map((value) => (
				<Option key={value} value={value}>
					{t(value)}
				</Option>
			))}
		</Select>
	);
}

export default CurrencySelect;
