import { useMemo } from "react";
import useSWR from "swr";
import { normalize } from "../../../../../../utils/helpers/normalize";
import { useCompany } from "../../../../../../utils/hooks/company";

export const usePayCodesMap = () => {
	const company = useCompany();
	const { data: { result: data } } = useSWR(
		{
			url: "/pay-code",
			params: {
				pagination: "false",
				company: company.id,
				withoutLookups: true,
			},
		},
		{ fallbackData: { result: [] } },
	);
	return useMemo(() => {
		return normalize(data, "code");
	}, [data]);
};
