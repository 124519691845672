import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import DateInput from "../../../../../components/Inputs/DateInput";
import CustomTimeInput from "../../../../../components/Inputs/CustomTimeInput";

function UnavailableTimeModal({ isOpen, close }) {
    const { t } = useTranslation();

    return (
        <Modal 
            title={(
                <h3>{t("set-unavaible-time")}</h3>
            )}
            isOpen={isOpen} 
            toggle={close} 
            centered
        >
            <Form>
                <Field 
                    className="w-100 d-flex flex-column"
                    name="date"
                    label={t("date")}
                    rules={[
                        {
                            required: true,
                            message: t("required-date")
                        }
                    ]}
                >
                    <DateInput placeholderText={t("date")} />
                </Field>

                <Field
                    name="startDate"
                    label={t("start-date")}
                    rules={[
                        {
                            required: true,
                            message: t("required-start-date")
                        }
                    ]}
                >
                    <CustomTimeInput />
                </Field>

                <Field
                    name="endDate"
                    label={t("end-date")}
                    rules={[
                        {
                            required: true,
                            message: t("required-end-date")
                        }
                    ]}
                >
                    <CustomTimeInput />
                </Field>

                <div className="d-flex align-items-center justify-content-end">
                    <Button 
                        color="muted" 
                        htmlType="button"
                        onClick={close}
                    >
                        {t("cancel")}
                    </Button>

                    <Button color="primary" htmlType="submit">
                        {t("save")}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default UnavailableTimeModal;
