import { useCallback, useEffect, useMemo } from "react";
import Form, { List } from "rc-field-form";
import Button from "../../../Button";
import classNames from "classnames";
import { isEqual } from "lodash";
import Filter from "./Filter";
import Toggle from "./Toggle";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { isFilterValid } from "../../../../utils/api/format";
import { useTranslation } from "react-i18next";
import "./_style.scss";

export const initialFilters = [{ name: "", method: "", value: "" }];

function Filters({ config, filters, setFilters }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { visible, toggle } = useVisible(false);
	const list = Form.useWatch("filters", form);

	const hasChange = useMemo(() => {
		return !isEqual(filters, list);
	}, [filters, list]);

	const areValid = useMemo(() => {
		return list?.every(isFilterValid);
	}, [list]);

	const changeFieldMethod = useCallback(
		(name, method) => {
			form.setFieldValue(["filters", name, "method"], method);
		},
		[form]
	);

	const changeFieldValue = useCallback(
		(name, value) => {
			form.setFieldValue(["filters", name, "value"], value);
		},
		[form]
	);

	const onFinish = useCallback(
		(values) => {
			setFilters(values.filters);
		},
		[setFilters]
	);

	const clearFilters = useCallback(() => {
		if (!isEqual(filters, initialFilters)) {
			setFilters(initialFilters);
		}
		form.setFieldValue("filters", initialFilters);
	}, [setFilters, form, filters]);

	useEffect(() => {
		form.setFieldValue("filters", filters);
	}, [form, filters]);

	return (
		<Form
			initialValues={{ filters }}
			form={form}
			onFinish={onFinish}
			className="table-filters d-flex align-items-start justify-content-end w-100"
		>
			<div className={classNames("responsive-filters", visible ? "d-flex flex-column" : "d-none")}>
				<div className="d-flex flex-column gap-y-4 px-0 w-100">
					<List name="filters">
						{(fields, { add, remove }) =>
							fields.map(({ name, key }) => (
								<Filter
									changeFieldMethod={changeFieldMethod}
									changeFieldValue={changeFieldValue}
									config={config}
									name={name}
									key={key}
									form={form}
									add={() => add({}, name + 1)}
									remove={() => remove(name)}
								/>
							))
						}
					</List>
				</div>

				<div className="d-flex mt-3">
					<Button
						color="primary"
						size="sm"
						className="mr-2"
						disabled={!hasChange || !areValid}
					>
						{t("search")}
					</Button>
					<Button
						color="secondary"
						size="sm"
						onClick={clearFilters}
						disabled={!hasChange && isEqual(list, initialFilters)}
					>
						{t("clear-filters")}
					</Button>
				</div>
			</div>
			<div className="ml-3">
				<Toggle open={visible} toggle={toggle} />
			</div>
		</Form>
	);
}

export default Filters;
