import Redirect from "../../../Routing/Redirect";
import { useCompany } from "../../../utils/hooks/company";
import Layout from "../Layout";
import Card from "../Layout/Card";
import CompanySelectForm from "./Form";

function CompanySelect() {
	const company = useCompany();

	if (company) {
		return <Redirect to="/" />
	}

	return (
		<Layout
			image="/assets/images/reset-password.jpg"
			text="It is during our darkest moments that we must focus to see the light."
			autor="Aristotle"
		>
			<Card
				cardTitle="welcome-to-simplitime"
				cardText="select-company"
				className="text-center"
				cardBody={<CompanySelectForm />}
			/>
		</Layout>
	);
}

export default CompanySelect;
