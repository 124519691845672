import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import Button from "../../../../../components/Button";
import "./style.scss";

function InfoTooltip({ close, isOpen, selected }) {
	const { t } = useTranslation();

	return (
		<Tooltip
			isOpen={isOpen}
			toggle={close}
			trigger="hover"
			autohide={false}
			placement="right"
			className="info-tooltip"
			target={`misspunch-${selected?.cellDate}-${selected?.id}`}
		>
			<div className="d-flex flex-column align-items-center mb-2">
				<i
					class="fa-solid fa-circle-info text-primary m-3"
					style={{ fontSize: "50px" }}
				/>

				<div className="text-lg font-weight-bolder text-dark">
					{t("warning")}
				</div>

				<div className="m-3 text-gray text-sm d-flex flex-column">
					<span>{t("there-is-mis-punch")}.</span>
					<span> {t("please-correct-it")}.</span>
				</div>

				<Button color="info" onClick={close} className="">
					{t("ok")}
				</Button>
			</div>
		</Tooltip>
	);
}

export default InfoTooltip;
