import { useCallback, useEffect } from "react";
import Form, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Switch from "../../../components/Inputs/Switch";
import Text from "../../../components/Inputs/Text";
import Password from "../../../components/Inputs/Password";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import { useUtaApi } from "./api";
import { useCompany } from "../../../utils/hooks/company";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { useAccess } from "../../../utils/hooks/access";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../utils/helpers/settings";

function Uta() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();

	const { hasAccess: canEdit } = useAccess(
		"settings.integration.uta.canEdit",
	);

	const { loading, error, submit } = useUtaApi();

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				active: values?.active,
				inactivePayGroups: values?.inactivePayGroups?.map(
					({ id }) => id,
				),
			};

			submit(data);
		},
		[submit],
	);

	useEffect(() => {
		form.setFieldsValue({
			active: company?.uta?.active,
			domain: company?.uta?.domain,
			clientId: company?.uta?.clientId,
			username: company?.uta?.username,
			password: company?.uta?.password,
			inactivePayGroups: company?.uta?.inactivePayGroups,
		});
	}, [form, company?.uta]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["domain", "username", "password"],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Form form={form} onFinish={onFinish}>
					<CardHeader>
						<Row>
							<Col xs="6" className="pl-4 d-flex">
								<h3 className="mb-0 mr-5">{t("uta")}</h3>{" "}
								<Field
									name="active"
									valuePropName="checked"
								>
									<Switch id="active" />
								</Field>
							</Col>

							<Col className="text-right" xs="6">
								<Button
									className="btn-dark px-3 btn-round btn-icon"
									size="sm"
									loading={loading}
									type="submit"
								>
									{t("save")}
								</Button>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="col-lg-9 col-md-12 general-settings">
						<BaseField shouldUpdate noStyle>
							{({ }, { }, { getFieldValue }) => {
								const status = getFieldValue("active");

								return (
									status && (
										<div className="grid grid-cols-2 gap-x-20">
											<Field
												name="domain"
												label={t("ultipro-domain")}
												rules={[
													{
														required: true,
														message: t(
															"required-ultipro-domain"
														),
													},
												]}
											>
												<Text
													placeholder={t(
														"ultipro-domain",
													)}
													disabled={!canEdit}
												/>
											</Field>

											<Field
												name="username"
												label={t("username")}
												rules={[
													{
														required: true,
														message:
															t("required-username"),
													},
												]}
											>
												<Text
													placeholder={t(
														"username",
													)}
													disabled={!canEdit}
												/>
											</Field>

											<Field
												name="password"
												label={t("password")}
												rules={[
													{
														required: true,
														message: t(
															"required-password"
														),
													},
												]}
											>
												<Password
													disabled={!canEdit}
													placeholder={t(
														"password",
													)}
												/>
											</Field>

											<Field
												name="inactivePayGroups"
												label={t(
													"inactive-pay-groups"
												)}
											>
												<ResourceSelect
													labelPropName="description"
													resourcePath="/pay-group"
													mode="multiple"
													placeholder={t(
														"inactive-pay-groups"
													)}
													renderLabel={
														renderSettingsResourceLabel
													}
													hasSearch
													getSearchFilters={
														getSettingsResourceSearchFilters
													}
												/>
											</Field>
										</div>
									)
								);
							}}
						</BaseField>
					</CardBody>
				</Form>
			</Card>
		</div>
	);
}

export default Uta;
