import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../../../components/Inputs/Select";
import { getActiveResources } from "../../../../../utils/helpers/user";
import { isAddedTimeSheet, isInputDisabled } from "../helpers";

export const useLocationColumn = ({ canEdit }) => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("location"),
        accessor: "location",
        disableFilters: false,
        filter: "text",
        Cell: ({ value, row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original } = row;
            const disabled = isInputDisabled(original) || row.disabled || cell.disabled;
            return (
                <Select
                    className="select-input group-input w-100"
                    showSearch
                    value={value?.id}
                    disabled={disabled || !canEdit}
                    dropdownMatchSelectWidth={false}
                    onChange={(id) => {
                        const location = original.user?.locations?.find(
                            (location) => location.id === id
                        );
                        if (!location) {
                            return;
                        }
                        if (isAddedTimeSheet(original)) {
                            updateAddedTimeSheet(original, { location });
                        } else {
                            updateTimeSheet(original.id, { location });
                        }
                    }}
                >
                    {value && (
                        <Select.Option value={value.id}>
                            {value.displayName}
                        </Select.Option>
                    )}

                    {getActiveResources(original.user?.locations || [])
                        ?.filter(({ id }) => id !== value?.id)
                        .map((location) => (
                            <Select.Option key={location.id} value={location.id}>
                                {location.displayName}
                            </Select.Option>
                        ))}
                </Select>
            );
        },
    }), [t, canEdit]);
};
