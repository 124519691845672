import { useMemo } from "react";
import { initialValues } from "./initialValues";

export const assignPayCodes = (
	values,
	{
		regularPayCode,
		unallocatedPayCode,
		mealUnpaidPayCode,
	},
	overtimeMap
) => {
	const data = JSON.parse(JSON.stringify(values));

	if (!data.regularDefaultPayCode && regularPayCode) {
		data.regularDefaultPayCode = regularPayCode;
	}

	if (!data.unallocatedDefaultPayCode && unallocatedPayCode) {
		data.unallocatedDefaultPayCode = unallocatedPayCode;
	}

	if (overtimeMap) {
		for (const type in data.overtime) {
			const overtimeRule = data.overtime[type];
			const defaultForType = overtimeMap[type];
			if (!overtimeRule.defaultPayCode && defaultForType) {
				overtimeRule.defaultPayCode = defaultForType;
			}
			if (
				(!overtimeRule.payCodes ||
					overtimeRule.payCodes.length === 0) &&
				regularPayCode
			) {
				overtimeRule.payCodes = [regularPayCode];
			}
		}
	}

	if (!data.breaks.manual.payCode && mealUnpaidPayCode) {
		data.breaks.manual.payCode = mealUnpaidPayCode;
	}

	return data;
};

export const useDefaultValue = ({
    overtimeMap,
    regularPayCode,
    mealUnpaidPayCode,
    unallocatedPayCode
}) => {
	return useMemo(() => {
		if (
			!overtimeMap ||
			!regularPayCode ||
			!mealUnpaidPayCode ||
			!unallocatedPayCode
		) {
			return;
		}

		const data = initialValues;

		return assignPayCodes(
			data,
			{ regularPayCode, unallocatedPayCode, mealUnpaidPayCode },
			overtimeMap
		);
	}, [overtimeMap, regularPayCode, unallocatedPayCode, mealUnpaidPayCode]);
}
