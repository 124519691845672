import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useReportsLogs = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);

	const { authGet } = useApi();

	const fetch = useCallback(
		async (params, controller) => {
			setLoading(true);
			try {
				const response = await authGet("/scheduling/report/logs", {
					params,
					signal: controller?.signal,
				});

				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[t, setLoading, setData, setTotalItems],
	);

	return { data, totalItems, loading, fetch };
};
