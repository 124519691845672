import { Option } from "rc-select";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import NumberInput from "../../../../../../components/Inputs/NumberInput";
import Select from "../../../../../../components/Inputs/Select";

function FixedOption({ disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<label className="form-control-label my-3">
				{t("total-per-year")}
			</label>

			<p className="text-muted text-sm">
				{t("number-of-hours-earned-each-year")}
			</p>

			<a
				href="#"
				className="h5 text-info bold font-weight-bolder d-flex mb-3"
			>
				{t("help-center")}
			</a>

			<div className=" d-flex  align-items-center">
				<Field
					name={["accural", "hoursPerYear"]}
					className="w-25 mr-2 mb-3"
				>
					<NumberInput disabled={disabled} />
				</Field>

				<p>{t("per-year")}</p>
			</div>

			<div>
				<label className="form-control-label">
					{t("earning-frequency")}
				</label>

				<p className="text-muted text-sm  mb-2">
					{t("can-be-added-or-applied")}
					<br />
					{t("recommended-details")}
				</p>

				<Field
					name={["accural", "frequency"]}
					rules={[
						{
							required: true,
							message: t("required-frequency"),
						},
					]}
				>
					<Select placeholder={t("frequency")} disabled={disabled}>
						<Option
							value="throughYearPayPeriods"
							key="throughYearPayPeriods"
						>
							{t("through-year-pay-periods")}
						</Option>

						<Option value="allAtOnce" key="allAtOnce">
							{t("all-at-once")}
						</Option>

						<Option
							value="employeeAniversaryDate"
							key="employeeAniversaryDate"
						>
							{t("employee-anniversary-date")}
						</Option>
					</Select>
				</Field>
			</div>
		</>
	);
}

export default FixedOption;
