import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseTopbar from "../../../../components/Table/Topbar";
import TopbarAction from "../../../../components/Table/Topbar/Action";
import { useIsMasterCompany } from "../../../../utils/hooks/company";

function Topbar({
	add,
	help,
	importFile,
	title,
	onExport = undefined,
	loading,
}) {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const disabled = useMemo(() => {
		if (
			add &&
			!isMasterCompany &&
			(title === "Devices" ||
				title === "Qr codes" ||
				title === "Whitelist numbers")
		) {
			return false;
		}
		if (!add || !isMasterCompany || loading) {
			return true;
		}

		return false;
	}, [add, title, isMasterCompany, loading]);

	return (
		<BaseTopbar title={title}>
			<TopbarAction onClick={add} disabled={disabled} loading={loading}>
				{t("add")}
			</TopbarAction>

			<TopbarAction
				disabled={!onExport || loading}
				onClick={onExport}
				loading={loading}
			>
				{t("export")}
			</TopbarAction>

			<TopbarAction
				onClick={importFile}
				disabled={!importFile || loading || disabled}
				loading={loading}
			>
				{t("import")}
			</TopbarAction>

			<TopbarAction disabled loading={loading}>
				{t("print")}
			</TopbarAction>

			<TopbarAction onClick={help} disabled={!help} loading={loading}>
				{t("help")}
			</TopbarAction>
		</BaseTopbar>
	);
}

export default Topbar;
