import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Select from "../../../../../components/Inputs/Select";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { useUser } from "../../../../../utils/hooks/user";
import { useDateFilters } from "../../../hooks/useDateFilters";
import moment from "moment";

export const PayGroupFilters = () => {
    const { t } = useTranslation();
    const user = useUser();
    const { payGroup, payGroupPeriod, setFilters, from, to } = useDateFilters();

    const onPayGroupChange = useCallback(({ code }) => {
        setFilters({ payGroup: code });
    }, [setFilters]);

    const onPeriodChange = useCallback((value) => {
        setFilters({ payGroupPeriod: value });
    }, [setFilters]);

    return (
        <Row>
            <Col>
                {false ? (
                    <Select
                        placeholder={t("pay-group")}
                        value={payGroup}
                        onChange={onPayGroupChange}
                        disabled
                    >
                        {user?.payGroup && (
                            <Select.Option value={user?.payGroup?.id}>
                                {renderSettingsResourceLabel(user?.payGroup)}
                            </Select.Option>
                        )}
                    </Select>
                ) : (
                    <ResourceSelect
                        resourcePath="/pay-group?pagination=off"
                        placeholder={t("pay-group")}
                        renderLabel={renderSettingsResourceLabel}
                        hasSearch
                        getSearchFilters={getSettingsResourceSearchFilters}
                        value={payGroup}
                        onChange={onPayGroupChange}
                    />
                )}
            </Col>

            <Col>
                <Select placeholder={t("period")} value={payGroupPeriod} onChange={onPeriodChange}>
                    <Select.Option value="past">
                        {t("past")}
                    </Select.Option>

                    <Select.Option value="current">
                        {t("current")}
                    </Select.Option>

                    <Select.Option value="next">
                        {t("next")}
                    </Select.Option>
                </Select>
            </Col>
            <Col>
                <div className="d-flex h-100">
                    <div className="align-items-center m-auto">
                        {from && to ? `${moment(from).format("MM/DD/YYYY")} - ${moment(to).format("MM/DD/YYYY")}` : ''}
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default PayGroupFilters;
