import { useMemo } from "react";
import { usePayCodesMap } from "../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";
import { useRegularPayCode } from "../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/useRegularPayCode";

export const useAddedPayCode = (user) => {
	const employeeTypeCode = user?.employeeType?.code;
	const employeeTypeAsDefault =
		user?.calculationGroup?.useEmployeeTypeAsDefaultPayCode;
	const regularDefaultPayCode = user?.calculationGroup?.regularDefaultPayCode;
	const payCodesMap = usePayCodesMap();
	const regularPayCode = useRegularPayCode(payCodesMap);
	return useMemo(() => {
		if (payCodesMap) {
			if (employeeTypeAsDefault && employeeTypeCode in payCodesMap) {
				return payCodesMap[employeeTypeCode];
			}
			if (
				regularDefaultPayCode &&
				regularDefaultPayCode.code in payCodesMap
			) {
				return payCodesMap[regularDefaultPayCode.code];
			}
		}
		return regularPayCode;
	}, [
		employeeTypeAsDefault,
		payCodesMap,
		employeeTypeCode,
		regularDefaultPayCode,
		regularPayCode,
	]);
};
