import { useMemo } from "react";
import { useAllAccessInList } from "../../../utils/hooks/access";

export const useActionsAccess = (basePath) => {
    const permissions = useMemo(() => {
        return ["canEdit", "canDelete", "canCreate"].map(
            (permission) => `${basePath}.${permission}`
        );
    }, [basePath]);

    const { hasAccess } = useAllAccessInList(permissions);

    return hasAccess;
}
