import { useEffect } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import {
	Card,
	CardHeader,
	CardText,
	CardTitle,
	CardBody,
	Col,
	Row,
	Container,
} from "reactstrap";
import DateInput from "../../../components/Inputs/DateInput";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import Button from "../../../components/Button";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function PersonalDetails({ user, submit, submitting, error }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["firstName", "middleName", "lastName", "birthDate", "note"],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	useEffect(() => {
		form.setFieldsValue({
			firstName: user?.firstName,
			lastName: user?.lastName,
		});
	}, [form, user]);

	return (
		<Row className="layout justify-content-center h-100 m-0">
			<Col
				xl={6}
				lg={5}
				md={6}
				className="d-flex flex-column mx-lg-0 mx-auto"
			>
				<Container>
					<div className="header-body text-center mb-7 mt-4">
						<Row className="justify-content-center">
							<Col className="px-5" lg="6" md="8" xl="5">
								<div className="d-flex justify-content-center sticky-top">
									<img
										src="/assets/images/logo-horizontal.png"
										alt=""
										width="250"
									/>
								</div>
							</Col>
						</Row>
					</div>
				</Container>

				<Card className="shadow-none bg-transparent mx-auto">
					<CardHeader className="bg-transparent pb-2 border-bottom-0">
						<CardTitle className="h2 font-weight-bolder mb-1">
							{t("complete-personal-information")}
						</CardTitle>

						<CardText className="mb-2">
							{t("improve-business-efficiency")}
						</CardText>
					</CardHeader>

					<CardBody className="border-bottom-0">
						<Form
							form={form}
							onFinish={submit}
							layout="vertical"
							scrollToFirstError
						>
							<Field
								name="firstName"
								label={t("first-name")}
								rules={[
									{
										required: true,
										message: t("required-firstname"),
									},
								]}
							>
								<Text placeholder={t("first-name")} />
							</Field>

							<Field name="middleName" label={t("middle-name")}>
								<Text placeholder={t("middle-name")} />
							</Field>

							<Field
								name="lastName"
								label={t("last-name")}
								rules={[
									{
										required: true,
										message: t("required-lastname"),
									},
								]}
							>
								<Text placeholder={t("last-name")} />
							</Field>

							<Field
								name="birthDate"
								label={t("birthday")}
								rules={[
									{
										required: true,
										message: t("required-birthday"),
									},
								]}
								className="d-flex flex-column"
							>
								<DateInput />
							</Field>

							<Field
								name="note"
								label={t("personal-information")}
								rules={[
									{
										required: true,
										message: t(
											"required-personal-information",
										),
									},
								]}
							>
								<Text placeholder={t("personal-information")} />
							</Field>

							<div className="text-right">
								<Button
									color="primary"
									type="submit"
									loading={submitting}
								>
									{t("save")}
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>

			<Col
				xl={6}
				lg={5}
				md={6}
				className="col-6 d-lg-flex d-none h-100 my-auto pe-0 text-center justify-content-center flex-column"
			>
				<div
					className="position-relative h-100 m-3 px-7 d-flex flex-column justify-content-center overflow-hidden image"
					style={{
						backgroundImage: `linear-gradient(87deg, rgba(94, 114, 228, 0.52), rgba(130, 94, 228, 0.73)), url(/assets/images/landscape-1.jpg)`,
						backgroundSize: "cover",
					}}
				>
					<h4 className="mt-5 text-white font-weight-bolder position-relative text-center">
						"No matter what people tell you, words and ideas can
						change the world."
					</h4>

					<p className="text-white position-relative text-center">
						"Robin Williams"
					</p>
				</div>
			</Col>
		</Row>
	);
}

export default PersonalDetails;
