import { useTranslation } from "react-i18next";
import { Collapse, Row, Col } from "reactstrap";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../../components/Button";
import TeamSchedulerAnalytics from "./Analytics";
import TeamSchedulerDateFilters from "./DateFilters";
import { useVisible } from "../../../../utils/hooks/useVisible";

function Topbar({ params }) {
    const { t } = useTranslation();
    const {
        visible: isAnalyticsOpen,
        toggle: toggleAnalytics,
    } = useVisible();

    return (
        <>
            <Row className='mb-3'>
                <Col xs="12" md="auto">
                    <Button
                        color="primary"
                        icon={<FontAwesomeIcon icon={isAnalyticsOpen ? faChevronUp : faChevronDown} />}
                        onClick={toggleAnalytics}
                    >
                        {t("analytics")}
                    </Button>
                </Col>

                <Col>
                    <TeamSchedulerDateFilters />
                </Col>
            </Row>

            <Collapse isOpen={isAnalyticsOpen}>
                {isAnalyticsOpen && <TeamSchedulerAnalytics params={params} />}
            </Collapse>
        </>
    );
}

export default Topbar;
