import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useLastClock } from "./store";

function ConfirmModal({ isOpen, submit, close, loading, selected = "start-shift" }) {
    const { t } = useTranslation();

    const lastClock = useLastClock();

    const seconds = useMemo(() =>
        moment().diff(moment(lastClock?.time), "seconds"),
        [lastClock?.time]);

    return <Modal
        isOpen={isOpen}
        close={close}
        centered
    >
        <div className="d-flex flex-column align-items-center m-5">
            <FontAwesomeIcon
                icon={faWarning}
                className="mb-4 text-primary"
                style={{
                    fontSize: "37px"
                }}
            />
            {t("last-shift-difference-with-current-time", {
                seconds,
                clockType: t(selected)?.toLowerCase()
            })}
        </div>

        <div className="d-flex align-items-center justify-content-end">
            <Button
                onClick={close}
                disabled={loading}
            >
                {t("close")}
            </Button>

            <Button
                color="primary"
                onClick={() => {
                    submit(selected);
                    close();
                }}
                loading={loading}
                disabled={loading}
            >
                {t("submit")}
            </Button>
        </div>
    </Modal>
}

export default ConfirmModal;