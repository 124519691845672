import axios from "axios";
import { useCallback, useEffect, useMemo } from "react";
import useSWR from "swr";
import { formatTimeSheetParams } from "../../api/helpers";
import useApi from "../../../../utils/api";
import moment from "moment-timezone";

export const getUserTimeSheetsSWRKey = (userId) => {
	return `TIMESHEET_TEAM_USER_${userId}_TIMESHEETS`;
}

export const useFetchUserTimeSheets = ({
	params,
	user,
	setState,
}) => {
	const key = useMemo(() => {
		if (!params.from || !params.to) {
			return null;
		}
		return getUserTimeSheetsSWRKey(`${user}_${params.from}_${params.to}`);
	}, [params, user]);

	const { authGet } = useApi();
	const fetcher = useCallback(() => {
		const url = axios.getUri({
			url: `/time-sheet/user/${user}`,
			params: {
				pagination: false,
				...formatTimeSheetParams(params),
			},
		});
		return authGet(url);
	}, [authGet, user, params]);

	const { data, isLoading, mutate } = useSWR(
		key,
		fetcher,
		{ fallbackData: { result: [] } },
	);

	const scheduledHoursFetch = useCallback(() => {
		const url = axios.getUri({
			url: `/schedules/scheduled-hours-by-date/${user}`,
			params: {
				startDate: moment(params.form).format("YYYY-MM-DD"),
				endDate: moment(params.to).format("YYYY-MM-DD")
			}
		});

		return authGet(url);
	}, [authGet, user, params]);

	const {
		data: scheduledHours,
		isLoading: isLoadingScheduledHours,
		mutate: mutateScheduledHours,
	} = useSWR(`${key}_SCHEDULER`, scheduledHoursFetch, { fallbackData: []});

	useEffect(() => {
		setState((prev) => ({
			...prev,
			data: data.result,
			loading: isLoading || isLoadingScheduledHours,
		}));
	}, [data, isLoading, isLoadingScheduledHours,  setState]);

	return {
		mutate,
		scheduledHours,
		mutateScheduledHours
	}
};
