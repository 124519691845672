import { useMemo } from "react";

export const useVisibleColumnKeys = (table) => {
	const visibleColumns = table.getVisibleFlatColumns();
	return useMemo(() => {
		return visibleColumns
			.map(({ columnDef: { accessorKey } }) => accessorKey)
			.filter((value) => value);
	}, [visibleColumns]);
}
