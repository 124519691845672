import { useMemo, useEffect } from "react";
import { normalize } from "../../../../../utils/helpers/normalize";
import { useConfigurationModule } from "../useConfigurationModule";
import { useUserPreferencesModule } from "../useUserPreferencesModule";

export const useSyncColumnVisibilityWithConfig = ({
	table,
	configuration,
	userPreferences,
}) => {
	const configColumns = useConfigurationModule(`${configuration}.columns`);
	const userPreferencesColumns = useUserPreferencesModule(`${userPreferences}.columns`);

	const allColumnMap = normalize(
		configColumns || [],
		"accessor",
	);
	(userPreferencesColumns || []).forEach(c => {
		if (!allColumnMap[c.accessor]) {
			allColumnMap[c.accessor] = c;
		}
	})
	const allColumns = Object.values(allColumnMap);

	const hiddenColumns = useMemo(() => {
		if (!allColumns?.length && !userPreferencesColumns?.length) {
			return undefined;
		}
		const userPreferencesColumnsMap = normalize(
			userPreferencesColumns || [],
			"accessor",
		);
		return allColumns.reduce((total, column) => {
			const { accessor } = column;
			let hide = column.hide;
			if (accessor in userPreferencesColumnsMap) {
				hide = userPreferencesColumnsMap[accessor].hide;
			}
			if (!hide) {
				return total;
			}
			return total.concat(accessor);
		}, []);
	}, [configColumns, userPreferencesColumns]);

	useEffect(() => {
		table.setColumnVisibility(
			(hiddenColumns || []).reduce((total, key) => {
				return {
					...total,
					[key]: false,
				};
			}, {})
		);
	}, [table.setColumnVisibility, hiddenColumns]);
}
