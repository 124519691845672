import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "rc-select";
import { Field as BaseField } from "rc-field-form";
import classnames from "classnames";
import { Row, Col } from "reactstrap";
import ManagersSelect from "../../../../components/Inputs/ManagersSelect";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import Field from "../../../../components/Field";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getSettingsResourceSearchFiltersWithLookups
} from "../../../../utils/helpers/settings";
import { useGetRoleByCode } from "../../../../utils/api/role";

function JobInformation({ form, loadingCalculationGroup }) {
	const { t } = useTranslation();

	const onResourceWithPrimaryChange = useCallback(
		(primaryFieldName, value) => {
			if (Array.isArray(value)) {
				const primaryField = form.getFieldValue(primaryFieldName);

				if (value.length === 1) {
					form.setFieldsValue({
						[primaryFieldName]: value[0].id,
					});
				} else {
					if (primaryField) {
						const hasPrimaryField =
							value.findIndex(
								(item) => item.id === primaryField,
							) !== -1;
						if (!hasPrimaryField) {
							form.setFieldsValue({
								[primaryFieldName]: null,
							});
						}
					}
				}
			}
		},
		[form],
	);

	const {
		data: defaultRole,
		loading: loadingDefaultRole,
		fetch: fetchDefaultRole,
	} = useGetRoleByCode();

	useEffect(() => {
		fetchDefaultRole("Employee");
	}, [fetchDefaultRole]);

	useEffect(() => {
		const value = form.getFieldValue("roles");
		if (value || !defaultRole) {
			return;
		}

		form.setFieldsValue({
			roles: [defaultRole],
		});
	}, [form, defaultRole]);

	return (
		<Row>
			<ManagersSelect form={form} isSelf={false} md={6} sm={12} />

			<Col sm={12} md={6}>
				<Field name="jobGroup" label={t("job-group")}>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/job-groups"
						renderLabel={renderSettingsResourceLabel}
						placeholder={t("job-group")}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						allowClear
					/>
				</Field>
			</Col>

			<Col sm={12} md={6}>
				<Field
					name="jobs"
					label={t("job")}
					rules={[
						{
							required: true,
							message: t("required-job"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/jobs"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						placeholder={t("job")}
					/>
				</Field>
			</Col>

			<BaseField dependencies={["locations"]} noStyle>
				{({ }, { }, { getFieldValue }) => {
					const locations = getFieldValue("locations");
					const showPrimary = locations && locations.length >= 2;

					return (
						<>
							<Col sm={12} md={6}>
								<Field
									className={classnames(
										!showPrimary && "col-span-2",
									)}
									name="locations"
									label={t("locations")}
									rules={[
										{
											required: true,
											message: t("required-locations"),
										},
									]}
								>
									<ResourceSelect
										labelPropName="description"
										resourcePath="/locations"
										renderLabel={
											renderSettingsResourceLabel
										}
										mode="multiple"
										placeholder={t("locations")}
										onChange={onResourceWithPrimaryChange.bind(
											null,
											"primaryLocation",
										)}
										hasSearch
										getSearchFilters={
											getSettingsResourceSearchFiltersWithLookups
										}
									/>
								</Field>
							</Col>

							<Col sm={12} md={6} hidden={!showPrimary}>
								<Field
									name="primaryLocation"
									label={t("primary-location")}
									required
								>
									<Select placeholder={t("primary-location")}>
										{locations?.map((location) => (
											<Option
												key={location.id}
												value={location.id}
											>
												{location.code +
													"-" +
													location.description}
											</Option>
										))}
									</Select>
								</Field>
							</Col>
						</>
					);
				}}
			</BaseField>

			<Col sm={12} md={6}>
				<Field
					name="payGroup"
					label={t("pay-group")}
					rules={[
						{
							required: true,
							message: t("required-pay-group"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/pay-group"
						placeholder={t("pay-group")}
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>
			</Col>

			<Col sm={12} md={6}>
				<Field
					name="calculationGroup"
					label={t("calculation-group")}
					rules={[
						{
							required: true,
							message: t("required-calculation-group"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/calculation-group"
						renderLabel={renderSettingsResourceLabel}
						placeholder={t("calculation-group")}
						loading={loadingCalculationGroup}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>
			</Col>

			<Col sm={12} md={6}>
				<Field
					name="roles"
					label={t("roles")}
					className="col-span-2"
					rules={[
						{
							required: true,
							message: t("required-roles"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/user-roles"
						mode="multiple"
						placeholder={t("roles")}
						loading={loadingDefaultRole}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				</Field>
			</Col>
		</Row>
	);
}

export default JobInformation;
