import { useTranslation } from "react-i18next";
import { CardHeader, CardBody } from "reactstrap";
import Button from "../../../components/Button";
import Table from "./Table";
import { useAccess } from "../../../utils/hooks/access";
import { useCallback } from "react";

function Home({ setStep, setData, data, setMode }) {
	const { t } = useTranslation();
	const { hasAccess: canCreate } = useAccess("reports.canCreate");
	const onClick = useCallback(() => {
		setStep(1);
		setMode("create");
	}, [setStep, setMode]);

	return (
		<>
			<CardHeader tag="h3">{t("reports")}</CardHeader>

			<CardBody className="m-0 p-0">
				<div style={{ height: "calc(100% - 200px)" }}>
					<div className="text-sm-right w-100 my-3 px-3">
						<Button
							color="dark"
							className="px-5"
							onClick={onClick}
							hidden={!canCreate}
						>
							{t("create-report")}
						</Button>
					</div>

					<Table
						setStep={setStep}
						setData={setData}
						data={data}
						setMode={setMode}
					/>
				</div>
			</CardBody>
		</>
	);
}

export default Home;
