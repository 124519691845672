import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useAuthenticateUser } from "../api";
import { useNonAuthApi } from "../../../utils/api";
import { useFirebase } from "../../Notifications/firebaseContext";

export const useLogin = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { nonAuthPost } = useNonAuthApi();
	const authenticateUser = useAuthenticateUser();
	const { firebaseToken } = useFirebase();

	const login = useCallback(
		async (data) => {
			setLoading(true);
			setError(null);
			try {
				const response = await nonAuthPost("/login", {
					data,
					headers: {
						Platform: "web",
						...(firebaseToken ? { FbToken: firebaseToken } : {}),
					},
				});
				if (response) {
					const { token, user } = response;
					authenticateUser(user, token, data.rememberMe);
				}
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[
			nonAuthPost,
			authenticateUser,
			setError,
			setLoading,
			firebaseToken,
		],
	);

	return { login, loading, error };
};
