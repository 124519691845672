import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import { renderOrgLevelResourceLabel } from "../../../../../utils/helpers/settings";
import { useGroups } from "../../../General/OrganisationLevelGroups/context";
import OptionsTable from "../../components/OptionsTable";

const pageSizes = [10, 20, 30, 40, 50, 100];

function Settings() {
    const { t } = useTranslation();

    const { allocatedGroups } = useGroups();
    const options = useMemo(() => {
        return [
            { label: t("employee-number"), name: "hideEmployeeNumber" },
            {
                label: renderOrgLevelResourceLabel(allocatedGroups[0]),
                name: "hideGroup",
                disabled: allocatedGroups?.length !== 1,
            },
            { label: t("project"), name: "hideProject" },
            { label: t("summary-tooltip"), name: "hideSummaryTooltip" },
            { label: t("miss-punch-tooltip"), name: "hideMissPunchTooltip" },
            { label: t("daily-summary"), name: ["timeSheet", "hideDailySummary"] },
        ];
    }, [t, allocatedGroups]);

    return (
        <div className="row">
            <div className="col-6">
                <OptionsTable fields={options} />
            </div>

            <div className="col-6">
                <Field name="defaultPageSize" label={t("default-page-size")}>
                    <Select allowClear>
                        {pageSizes.map((pageSize) => <Select.Option key={pageSize} value={pageSize}>
                            {pageSize}
                        </Select.Option>)}
                    </Select>
                </Field>
            </div>
        </div>
    );
}

export default Settings;
