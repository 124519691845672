import {useCallback} from "react";
import useApi from "../../utils/api"

export const useReadNotifications = () => {
    const {authPut} = useApi();

    const readOne = useCallback((id) => {
        return authPut(`/notifications/read/${id}`);
    }, [authPut]);

    const readAll = useCallback(() => {
        return authPut("/notifications/read-bulk");
    }, [authPut]);

    return { readOne, readAll };
}
