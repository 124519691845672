import { useTranslation } from "react-i18next";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useDateFilters } from "../DateAndPayGroupFilters/useDateFilters";

function BulkActions() {
	const { t } = useTranslation();
	const { visible, toggle } = useVisible();
	const { mode } = useDateFilters();

	return (
		<Dropdown
			isOpen={visible}
			toggle={toggle}
			className="d-flex align-items-center"
		>
			<span className="text-sm font-weight-600 text-dark mr-1 d-flex align-items-center">
				<span className="mb-1">{t("manager-view")}</span>
				<i
					className="fa-solid fa-angle-right mx-2"
					style={{ fontSize: "10px" }}
				/>
				<span className="mb-1">
					{mode === "pay-group" ? t("pay-group") : t("weekly")}
				</span>
				<i
					className="fa-solid fa-angle-right mx-2"
					style={{ fontSize: "10px" }}
				/>
			</span>

			<DropdownToggle
				className="btn-round btn-icon shadow-none border"
				size="sm"
			>
				{t("actions")}
			</DropdownToggle>

			<DropdownMenu>
				<DropdownItem onClick={() => console.log("export")}>
					{t("export")}
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

export default BulkActions;
