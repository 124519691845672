import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../../components/Field";
import Switch from "../../.../../../../../../../components/Inputs/Switch";

function Option({ name, title, disabled }) {
	const { t } = useTranslation();

	return (
		<div className="d-flex align-items-center justify-content-between my-3">
			<h3 className="font-weight-bolder text-sm">{t(title)}</h3>

			<div className="d-flex align-items-center">
				<Field
					name={name}
					valuePropName="checked"
					initialValue={false}
					noStyle
					className="mb-0"
				>
					<Switch disabled={disabled} />
				</Field>

				<BaseField noStyle shouldUpdate>
					{({ }, { }, { getFieldValue }) => (
						<p className="text-sm mb-0">
							{getFieldValue(name) === true
								? t("enabled")
								: t("disabled")}
						</p>
					)}
				</BaseField>
			</div>
		</div>
	);
}

export default Option;
