import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { provideContext } from "../../../utils/helpers/context";
import { useIsMasterCompany } from "../../../utils/hooks/company";
import { SettingsOnboardingProvider, useSettingsOnboarding } from "./context";
import Layout from "./Layout";
import steps from "./steps";
import "./style.scss";

function Onboarding() {
	const navigate = useNavigate();
	const { step, latestCompletedStep } = useSettingsOnboarding();
	const isMasterCompany = useIsMasterCompany();

	const stepConfig = useMemo(() => steps[step], [step]);

	useEffect(() => {
		if (!isMasterCompany) {
			navigate("/redirect");
		}
		if (latestCompletedStep >= 5) {
			navigate("/redirect");
		}
	}, [isMasterCompany, latestCompletedStep, navigate]);

	return (
		stepConfig && (
			<Layout
				title={stepConfig.name}
				description={stepConfig.description}
			>
				<stepConfig.content />
			</Layout>
		)
	);
}

export default provideContext(SettingsOnboardingProvider, Onboarding);
