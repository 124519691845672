import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import FormContent from "./FormContent";
import Button from "../../../../components/Button";
import { useUpdateCompany } from "../../../../utils/api/company";
import { useCompanyLogoUpload } from "../../../../utils/api/upload";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useAccess } from "../../../../utils/hooks/access";
import { useCompany } from "../../../../utils/hooks/company";
import { dateTimeParseZone } from "../../../../utils/helpers/date";
import { formatSubmittedData } from "./format";
import "./style.scss";

function General() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const [images, setImages] = useState({
		logo: company?.logo || null,
		backgroundImage: company?.backgroundImage || null,
	});
	const [isLogoUploading, setIsLogoUploading] = useState(false);
	const { upload: uploadLogo, uploadBackgroundImage } =
		useCompanyLogoUpload();
	const { loading, submit, error } = useUpdateCompany();

	const { hasAccess: canView } = useAccess("settings.general.canView");
	const { hasAccess: canEdit } = useAccess("settings.general.canEdit");
	
	const onFinish = useCallback(
		async (values) => {
			if (company) {
				let logoUrl = "",
					backgroundImageUrl = "";

				if (images?.logo === null) {
					logoUrl = "";
				} else if (typeof images?.logo === "string") {
					logoUrl = images.logo;
				} else if (images?.logo !== company?.logo) {
					setIsLogoUploading(true);
					try {
						const res = await uploadLogo(images?.logo);
						if (res) {
							logoUrl = res.path;
						}
					} catch (err) {
						toast.error(t("logo-upload-failed"));
					} finally {
						setIsLogoUploading(false);
					}
				}

				if (
					images?.backgroundImage === null ||
					!images?.backgroundImage
				) {
					backgroundImageUrl = "";
				} else if (typeof images?.backgroundImage === "string") {
					backgroundImageUrl = images.backgroundImage;
				} else if (
					images?.backgroundImage !== company?.backgroundImage
				) {
					setIsLogoUploading(true);
					try {
						const res = await uploadBackgroundImage(
							images?.backgroundImage,
						);
						if (res) {
							backgroundImageUrl = res.path;
						}
					} catch (err) {
						toast.error(t("background-image-upload-failed",));
					} finally {
						setIsLogoUploading(false);
					}
				}
				const updatedCompany = formatSubmittedData(company, {
					...values,
					logo: logoUrl,
					backgroundImage: backgroundImageUrl,
				});

				submit(company.id, updatedCompany, () =>
					toast.success(t("updated-successfully")),
				);
			}
		},
		[
			images,
			company,
			submit,
			uploadLogo,
			uploadBackgroundImage,
			setIsLogoUploading,
			t,
		],
	);

	useEffect(() => {
		form.setFieldsValue({
			...company,
			employeeNumberLength: company?.employeeNumberLength
				? company?.employeeNumberLength
				: 7,
			settings: {
				...company?.settings,
				maxShiftLength: company?.settings?.maxShiftLength
					? company?.settings?.maxShiftLength
					: 900,
				timeDifference: company?.settings?.timeDifference
					? company?.settings?.timeDifference
					: 60,
				schedule: {
					shiftStart: dateTimeParseZone(company?.settings?.schedule?.shiftStart, "time"),
					shiftEnd: dateTimeParseZone(company?.settings?.schedule?.shiftEnd, "time"),
				}
			},
			defaultEmployeeNumber: company?.defaultEmployeeNumber
				? company?.defaultEmployeeNumber
				: "7000000",
			badgeIdLength: company?.badgeIdLength,
		});
	}, [form, company]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"defaultEmployeeNumber",
				"language",
				"employeeNumberLength",
				"name",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<div className="d-flex h-100 general-settings">
			<Card className="flex-fill mb-0 h-100">
				{canView && (
					<Form
						form={form}
						onFinish={onFinish}
						style={{ height: "calc(100% - 150px)" }}
					>
						<CardHeader>
							<Row>
								<Col xs="6" className="pl-4">
									<h3 className="mb-0">
										{t("create-your-settings")}
									</h3>

									<h5 className="text-muted">
										{t("improve-business-efficiency")}
									</h5>
								</Col>

								<Col className="text-right" xs="6">
									{canEdit && (
										<Button
											className="btn-dark pr-3 btn-round btn-icon"
											size="sm"
											loading={loading || isLogoUploading}
											type="submit"
										>
											{t("save")}
										</Button>
									)}
								</Col>
							</Row>
						</CardHeader>

						<CardBody
							className="col-12 mb-auto h-100 general-settings-scroll"
							style={{
								overflowY: "auto",
							}}
						>
							<FormContent
								setImages={setImages}
								images={images}
								form={form}
							/>
						</CardBody>
					</Form>
				)}
			</Card>
		</div>
	);
}

export default General;
