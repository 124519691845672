import { useMemo } from "react";

export const useColumnsDefaultValue = ({ columns, hiddenColumns }) => {
    return useMemo(() => {
        return columns
            .filter((column) => {
                return !["selection", "actions"].includes(column.id);
            })
            .map((column) => {
                const accessor = column.accessor || column.id;
                const hide = hiddenColumns?.includes(accessor) || false;
                const hideSort = false; // TODO
                const hideCompletely = false; // TODO
                return { accessor, hide, hideCompletely, hideSort };
            });
    }, [columns, hiddenColumns]);
}

