import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import Button from "../../../../components/Button";
import { useClockIn } from "../store";
import { useCompany } from "../../../../utils/hooks/company";
import { useAccess } from "../../../../utils/hooks/access";

const Home = () => {
	const { t } = useTranslation();
	const company = useCompany();
	// eslint-disable-next-line
	const [{ }, { setShiftAction }] = useClockIn();

	const { hasAccess: canClockInOnWeb } = useAccess("clockin.canClockInOnWeb");

	return (
		<div className="d-flex flex-column">
			<div className="col text-center">
				<span>{moment().format("dddd, MMMM DD, yyyy")}</span>
				<h1 className="text-info">
					{moment().format(company?.settings?.timeFormat)}
				</h1>
			</div>

			<img
				alt="..."
				className={classNames(
					"rounded-circle img-center img-fluid  mb-1",
					canClockInOnWeb && "cursor-pointer",
				)}
				src={
					require("../../../../assets/img/people_svgs/Clock in.svg")
						.default
				}
				style={{ width: "380px" }}
				onClick={
					canClockInOnWeb
						? () => setShiftAction("start-shift")
						: undefined
				}
			/>

			{canClockInOnWeb && (
				<div className="col text-center">
					<Button
						color="default"
						className="text-white px-7 clock-btn"
						onClick={() => setShiftAction("start-shift")}
					>
						{t("clock-in")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default Home;
