import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { useCompanyCurrency, useIsMasterCompany } from "../../../../../utils/hooks/company";
import { 
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters
} from "../../../../../utils/helpers/settings";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, roles }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const currency = useCompanyCurrency();

	return useMemo(() => {
		const columns = [
			columnHelper.accessor("role", {
				header: t("role"),
				enableColumnFilter: true,
				cell: (info) => {
					const value = info.getValue();
					const role = roles?.find(({ id }) => id === value)
					return (value && role) && renderSettingsResourceLabel(role);
				},
				filterType:"resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/user-roles"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				)
			}),
			columnHelper.accessor("maxRate", {
				header: t("max-rate"),
				enableColumnFilter: true,
				filterType: "text",
				cell: (info) => {
					const value = info.getValue() || 0;
					return currencyFormatter(value, 2, currency);
				}
			}),
			columnHelper.accessor("maxAmount", {
				header: t("max-amount"),
				enableColumnFilter: true,
				filterType: "text",
				cell: (info) => {
					const value = info.getValue() || 0;
					return currencyFormatter(value, 2, currency);
				}
			}),
		];
		if (isMasterCompany && (edit || remove)) {
			columns.push(columnHelper.display({
				id: "actions",
				enableHiding: false,
				enableColumnFilter: false,
				header: (
					<div className="text-right">
						{t("actions")}
					</div>
				),
				cell: (info) => {
					const data = info.row.original;

					return (
						<div className="actions text-primary text-right">
							{edit && (
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer"
									onClick={() => edit(data)}
								>
									<i className="fas fa-pen" />
								</span>
							)}

							{remove && (
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer"
									onClick={() => remove(data)}
								>
									<i className="fas fa-trash" />
								</span>
							)}
						</div>
					);
				},
			}));
		}
		return columns;
	}, [isMasterCompany, currency, edit, remove, roles, t]);
};
