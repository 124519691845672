import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Text from "../Inputs/Text";
import Field from ".";
import { firstToUpper } from "../../utils/helpers/string";
import { useIsMasterCompany } from "../../utils/hooks/company";

function DescriptionField() {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const getValueFromEvent = useCallback(
		(e) => firstToUpper(e.target.value),
		[]
	);

	return (
		<Field
			label={t("description")}
			name="description"
			getValueFromEvent={getValueFromEvent}
			rules={[{
				required: true, message: t("required-description")
			}]}
		>
			<Text placeholder={t("description")} disabled={!isMasterCompany} />
		</Field>
	);
}

export default DescriptionField;
