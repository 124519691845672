import { useCallback, useEffect } from "react";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import { CardBody } from "reactstrap";
import Header from "../Layout/Header";
import FormContent from "../../General/OrganisationLevelGroups/Form/Content";
import { useSettingsOnboarding } from "../context";
import useApi from "../../../../utils/api";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useCompany } from "../../../../utils/hooks/company";
import { useTranslation } from "react-i18next";

function OrganisationLevel() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const { authPost } = useApi();
	const { submit, error } = useSettingsOnboarding();

	const call = useCallback(
		(values) => {
			if (!company?.id) {
				return;
			}
			return authPost("/org-level-groups", {
				data: { company: company.id, ...values },
			});
		},
		[company?.id, authPost],
	);

	const onFinish = useCallback(
		(values) => {
			submit(true, call(values));
		},
		[submit, call],
	);

	const saveAndRepeat = useCallback(() => {
		const values = form.getFieldsValue();
		submit(false, call(values), () =>
			toast.success(t("org-level-group-created")),
		);
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			allocatedBy: false,
			hasBudget: false,
			isRequired: false,
			hasHierarchy: false,
			inclueLocationHierarchy: false,
			useInClockIn: false,
		});
	}, [form, submit, call, t]);

	useEffect(() => {
		form.setFieldsValue({
			status: "active",
			allocatedBy: false,
			hasBudget: false,
			isRequired: false,
			hasHierarchy: false,
			inclueLocationHierarchy: false,
			useInClockIn: false,
		});
	}, [form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"code",
				"description",
				"status",
				"level",
				"allocatedBy",
				"hasBudget",
				"isRequired",
				"hasHierarchy",
				"inclueLocationHierarchy",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Header canSkipRegardless={true} saveAndRepeat={saveAndRepeat} />

			<CardBody>
				<FormContent form={form} />
			</CardBody>
		</Form>
	);
}

export default OrganisationLevel;
