import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppLoader from "../components/Loaders/AppLoader";
import DomainValidation from "./DomainValidation";
import { useAuthentication } from "../features/Authentication/context";
import { useOnboardingStep as useCompanyOnboardingStep } from "../features/Authentication/CompanyOnboarding/api";
import { useLatestCompletedStep as useSettingsOnboardingStep } from "../features/Settings/Onboarding/context/hook";
import { useIsMasterCompany } from "../utils/hooks/company";
import { NotificationsProvider } from "../features/Notifications/context";

function Authentication() {
	const navigate = useNavigate();
	const {
		hasAuthenticated,
		token,
		authenticated,
		company,
		authenticating,
		user,
	} = useAuthentication();

	/*
	const { hasAccess } = useAllAccessInList(permissions);

	if (isAuthenticated && !hasAccess) {
		return <Redirect to="/" />;
	}
	*/

	const companyOnboardingStep = useCompanyOnboardingStep();
	const settingsOnboardingStep = useSettingsOnboardingStep();

	const hasCompany = !!company;
	const isMasterCompany = useIsMasterCompany();
	const impersonate = localStorage.getItem("IMPERSONATE");
	const otpValidate = localStorage.getItem("OTP_VALIDATE");

	useEffect(() => {
		if (!token) {
			navigate("/redirect");
		}

		if (hasAuthenticated && !authenticated) {
			navigate("/redirect");
		}

		if (
			user?.twoFA?.status === "active" &&
			!impersonate &&
			otpValidate === null
		) {
			navigate("/login/validate");
		}

		if (
			authenticated && !hasCompany &&
			((user?.twoFA?.status !== "active" &&
				(user?.twoFA?.type ||
					user?.twoFA?.type === null ||
					!user?.twoFA?.type)) ||
				otpValidate !== null)
		) {
			navigate("/company-selection");
		}

		if (hasCompany && isMasterCompany && companyOnboardingStep < 4) {
			navigate("/company-onboarding");
		} else if (
			hasCompany &&
			isMasterCompany &&
			(!settingsOnboardingStep || settingsOnboardingStep < 5)
		) {
			navigate("/settings/onboarding");
		}

		if (user?.requirePasswordChange) {
			navigate("/required-set-password");
		}
	}, [
		navigate,
		hasAuthenticated,
		token,
		authenticated,
		hasCompany,
		isMasterCompany,
		companyOnboardingStep,
		settingsOnboardingStep,
		user?.requirePasswordChange,
		user?.twoFA,
		impersonate,
		otpValidate
	]);

	if (authenticating || (!hasAuthenticated && token)) {
		return <AppLoader />;
	}

	return (
		<NotificationsProvider>
			<DomainValidation domain="sub" isSecure={true} />
		</NotificationsProvider>
	);
}

export default Authentication;
