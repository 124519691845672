import {
	useEffect,
	useRef,
	Children,
	isValidElement,
	cloneElement,
	useCallback,
	useState,
	useMemo,
} from "react";
import "./style.scss";

const mapStyle = { flexGrow: 1, height: "350px" };
const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };
const defaultZoom = 11;

const drawingManager = new window.google.maps.drawing.DrawingManager({
	drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
	drawingControl: false,
	drawingControlOptions: {
		position: window.google.maps.ControlPosition.TOP_CENTER,
		drawingModes: ["polygon"],
	},
	polygonOptions: {
		fillColor: "black",
		fillOpacity: 0.2,
		strokeWeight: 5,
		editable: true,
		zIndex: 1,
		strokeColor: "black",
		draggable: true,
		geodesic: true,
	},
});

function Map({ 
	options, 
	children, 
	area, 
	setArea, 
	setCenter, 
	setZoom,
	level,
	setLevel
}) {
	const ref = useRef(null);
	const [hasInitCoords, setHasInitCoords] = useState(!!area);
	const [map, setMap] = useState(null);

	const reset = useCallback(() => {
		setMap(null);
		setArea(undefined);
		setHasInitCoords(false);
		setLevel({ hasChange: false });
		setZoom(defaultZoom);
		setCenter(defaultCenter);
	}, [setMap, setArea, setHasInitCoords, setLevel, setCenter, setZoom]);

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map, setMap]);

	const polygon = useMemo(() => {
		if (hasInitCoords) {
			return new window.google.maps.Polygon({
				paths: area,
				fillColor: "black",
				fillOpacity: 0.2,
				strokeWeight: 5,
				editable: true,
				zIndex: 1,
				strokeColor: "black",
				draggable: true,
				geodesic: true,
			});
		}
		return null;
	}, [area]);

	const getPath = useCallback(() => {
		const path = polygon.getPath();
		const len = path.getLength();
		const area = [];
		for (let i = 0; i < len; i++) {
			const coordinate = path.getAt(i).toUrlValue(6).split(",");
			area.push(coordinate);
		}
		const coords = [];
		area.map((coordinate) =>
			coords.push({
				lat: Number(coordinate[0]),
				lng: Number(coordinate[1]),
			}),
		);
		setArea(coords);
		setLevel({ hasChange: false });

		setTimeout(() => {
			window.google.maps.event.clearInstanceListeners(polygon);
			polygon.setMap(null);
		}, 100);
	}, [polygon, setArea, setLevel]);

	useEffect(() => {
		if (drawingManager && map) {
			drawingManager.setMap(map);
		}
	}, [map]);

	useEffect(() => {
		if (polygon && map) {
			polygon.setMap(map);
		}
	}, [polygon, map]);

	useEffect(() => {
		if (polygon) {
			window.google.maps.event.addListener(polygon, "dragend", getPath);
		}
	}, [polygon, getPath]);

	useEffect(() => {
		if (polygon) {
			window.google.maps.event.addListener(
				polygon.getPath(),
				"insert_at",
				getPath,
			);
		}
	}, [polygon, getPath]);

	useEffect(() => {
		if (polygon) {
			window.google.maps.event.addListener(
				polygon.getPath(),
				"remove_at",
				getPath,
			);
		}
	}, [polygon, getPath]);

	const getDrawingManagerPath = useCallback(
		(event) => {
			const coords = event
				.getPath()
				.getArray()
				.map((value) => ({
					lat: value.lat(),
					lng: value.lng(),
				}));

			setArea(coords);
		},
		[setArea],
	);

	useEffect(() => {
		window.google.maps.event.addListener(
			drawingManager,
			"polygoncomplete",
			(event) => {
				setZoom(event.map.zoom);
				getDrawingManagerPath(event);

				const paths = event.getPaths();

				for (let i = 0; i < paths.length; i++) {
					window.google.maps.event.addListener(
						paths.getAt(i),
						"insert_at",
						() => getDrawingManagerPath(event),
					);

					window.google.maps.event.addListener(
						paths.getAt(i),
						"remove_at",
						() => {
							getDrawingManagerPath(event);
						},
					);

					window.google.maps.event.addListener(
						paths.getAt(i),
						"set_at",
						() => {
							getDrawingManagerPath(event);
						},
					);
				}
			},
		);
	}, [getDrawingManagerPath, setZoom]);

	useEffect(() => {
		if (drawingManager) {
			drawingManager.setDrawingMode(area ? null : "polygon");
		}
	}, [area]);

	// useEffect(() => {
	// 	if (!map) {
	// 		return;
	// 	}

	// 	if (area && polygon) {
	// 		const bounds = new window.google.maps.LatLngBounds();
	// 		area.forEach((position) => bounds.extend(position));
	// 		map.fitBounds(bounds, 0);
	// 	}
	// }, [map, area, polygon]);

	useEffect(() => {
		if (map && options) {
			map.setOptions(options);
		}
	}, [map, options]);

	useEffect(() => {
		if (area) {
			let lat = 0;
			let lng = 0;
			area.map((c) => {
				lat += Number(c.lat);
				lng += Number(c.lng);
			});

			setCenter({
				lat: lat / area.length,
				lng: lng / area.length,
			});
		}
	}, [area, setCenter]);

	useEffect(() => {
		if (level?.hasChange) {
			reset();
			setArea(level?.coordinates);
			setHasInitCoords(!!level?.coordinates);
			if (level?.coordinates) {
				setCenter(level?.mapCenter || defaultCenter);
				setZoom(level?.mapZoom || defaultZoom);
			}
		}
	}, [reset, level, setArea, setHasInitCoords, setCenter, setZoom]);

	return (
		<div className="org-level-map">
			<div ref={ref} style={mapStyle} />

			<div className="w-100 text-right">
				<i
					className="reset-btn fa-solid fa-rotate cursor-pointer bg-white border"
					onClick={reset}
				/>
			</div>

			{Children.map(
				children,
				(child) =>
					isValidElement(child) && cloneElement(child, { map }),
			)}
		</div>
	);
}

export default Map;
