import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Modal } from "reactstrap";
import Table from "../../../../../components/Table";
import { TableContext } from "../../../../../components/Table/context";
import { useColumns } from "./useColumns";
import { useEnhancedTable } from "../../../../../components/Table/hook";
import { useReports } from "../../api";
import { formatParams } from "../../../../../utils/api/format";

const formatColumns = (columns) => {
	return columns
		?.filter?.(
			({ onlyFilter, isForExtraColumns }) =>
				!(onlyFilter || isForExtraColumns),
		)
		?.map(({ key }) => key);
};

function PreviewModal({ isOpen, columns, closePreviewModal }) {
	const { t } = useTranslation();

	const [response, setResponse] = useState([]);
	const [extraColumns, setExtraColumns] = useState();
	const [totalItems, setTotalItems] = useState(0);

	const { cols } = useColumns({ columns, payCodeColumns: extraColumns });

	const { fetchReportByColumns, loading } = useReports();

	const table = useEnhancedTable({
		hasURLBindedFilters: false,
		columns: cols,
		data: response,
		totalItems: totalItems,
		hasRowSelection: false,
	});

	const { globalFilter: filters } = table.state;

	useEffect(() => {
		const controller = new AbortController();

		if (columns && isOpen) {
			fetchReportByColumns(
				formatColumns(columns),
				formatParams({
					pageIndex: 0,
					pageSize: 5,
					filters,
				}),
				(res) => {
					setExtraColumns(res?.extraColumns);
					setResponse(res.result);
					setTotalItems(0);
				},
				controller,
			);
		}
		return () => controller.abort();
	}, [
		fetchReportByColumns,
		setResponse,
		setTotalItems,
		setExtraColumns,
		filters,
		columns,
		isOpen,
	]);

	return (
		<Modal
			isOpen={isOpen}
			className="modal-dialog-centered modal-xl pb-0 shadow-none"
		>
			<Card className="shadow-none">
				<CardHeader className="d-flex align-items-center justify-content-between w-100">
					<h3 className="mb-0">{t("custom-report")}</h3>

					<i
						className="fa-solid fa-xmark cursor-pointer"
						onClick={closePreviewModal}
					/>
				</CardHeader>

				<CardBody>
					<Card>
						<CardHeader tag="h3" className="border-0">
							{response.name}
						</CardHeader>

						<CardBody className="p-0">
							<TableContext.Provider value={table}>
								<Table loading={loading} />
							</TableContext.Provider>
						</CardBody>
					</Card>
				</CardBody>
			</Card>
		</Modal>
	);
}

export default PreviewModal;
