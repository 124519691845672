import { useCallback, useEffect } from "react";
import { useQrCodeClockIn } from "../context";
import useApi from "../../../utils/api";

export const useGetFlyers = (user, token) => {
    const { dispatch } = useQrCodeClockIn();
    const { authGet } = useApi();

    const getFlyers = useCallback(async (controller) => {
        dispatch({ type: "fetch-flyers-request" })
        try {
            if (user && token) {
                const response = await authGet("/flyer/user", {
                    signal: controller?.signal,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Company: user?.companies[0]?.id,
                        MainCompany: user?.mainCompany,
                    }
                });
                if (response) {
                    dispatch({
                        type: "fetch-flyers-success",
                        payload: { flyers: response }
                    })
                }
           }

        } catch (error) {
            dispatch({ type: "fetch-flyers-failed", payload: { error } })
        }
    }, [dispatch, authGet, user, token]);

    useEffect(() => {
        const contoller = new AbortController();
        getFlyers(contoller)
        return () => contoller.abort();
    }, [getFlyers]);
}