import { useMemo } from "react";
import { normalize } from "../../../../utils/helpers/normalize";
import { useConfigurationModule } from "./useConfigurationModule";

const generateFilters = (field) => {
    return [{ name: field, method: "", value: undefined }];
};

export const useConfigInitialFilters = (feature, defaultFilterField) => {
    const configFilters = useConfigurationModule(`${feature}.filters`);
    const configDefaultFilter = useConfigurationModule(`${feature}.defaultFilter`);

    return useMemo(() => {
        if (!configFilters) {
            return generateFilters(configDefaultFilter || defaultFilterField);
        }
        const filtersMap = normalize(
            configFilters.filter(({ hide }) => !hide),
            "accessor",
        );

        const filter = filtersMap[configDefaultFilter]
            || filtersMap[defaultFilterField]
            || Object.values(filtersMap)[0];

        if (!filter) {
            return [];
        }
        return generateFilters(filter.accessor);
    }, [configFilters, configDefaultFilter, defaultFilterField]);
}
