import { useState, useRef, useCallback, useEffect } from "react";

export const useGeolocated = (config) => {
	const {
		positionOptions = {
			enableHighAccuracy: false,
			maximumAge: Infinity,
			timeout: 5000,
		},
		isOptimisticGeolocationEnabled = true,
		userDecisionTimeout = undefined,
		suppressLocationOnMount = false,
		watchPosition = false,
		geolocationProvider = typeof navigator !== "undefined"
			? navigator.geolocation
			: undefined,
		onError,
		onSuccess,
	} = config;

	const userDecisionTimeoutId = useRef(0);
	const isCurrentlyMounted = useRef(true);
	const watchId = useRef(0);

	const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(
		isOptimisticGeolocationEnabled,
	);

	const [coords, setCoords] = useState();
	const [timestamp, setTimestamp] = useState();
	const [positionError, setPositionError] = useState();

	const cancelUserDecisionTimeout = useCallback(() => {
		if (userDecisionTimeoutId.current) {
			window.clearTimeout(userDecisionTimeoutId.current);
		}
	}, [userDecisionTimeoutId]);

	const handlePositionError = useCallback(
		(error) => {
			cancelUserDecisionTimeout();
			if (isCurrentlyMounted.current) {
				setCoords(() => undefined);
				setIsGeolocationEnabled(false);
				setPositionError(error);
			}
			onError?.(error);
		},
		[onError, cancelUserDecisionTimeout],
	);

	const handlePositionSuccess = useCallback(
		(position) => {
			cancelUserDecisionTimeout();
			if (isCurrentlyMounted.current) {
				setCoords(position.coords);
				setTimestamp(position.timestamp);
				setIsGeolocationEnabled(true);
				setPositionError(() => undefined);
			}
			onSuccess?.(position);
		},
		[onSuccess, cancelUserDecisionTimeout],
	);

	const getPosition = useCallback(() => {
		if (
			!geolocationProvider ||
			!geolocationProvider.getCurrentPosition ||
			!geolocationProvider.watchPosition
		) {
			throw new Error("The provided geolocation provider is invalid");
		}

		const funcPosition = (
			watchPosition
				? geolocationProvider.watchPosition
				: geolocationProvider.getCurrentPosition
		).bind(geolocationProvider);

		if (userDecisionTimeout) {
			userDecisionTimeoutId.current = window.setTimeout(() => {
				handlePositionError();
			}, userDecisionTimeout);
		}

		watchId.current = funcPosition(
			handlePositionSuccess,
			handlePositionError,
			positionOptions,
		);
	}, [
		geolocationProvider,
		watchPosition,
		userDecisionTimeout,
		handlePositionError,
		handlePositionSuccess,
		positionOptions,
	]);

	useEffect(() => {
		if (!suppressLocationOnMount) {
			getPosition();
		}

		return () => {
			cancelUserDecisionTimeout();
			if (watchPosition && watchId.current) {
				geolocationProvider?.clearWatch(watchId.current);
			}
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		getPosition,
		coords,
		timestamp,
		isGeolocationEnabled,
		isGeolocationAvailable: geolocationProvider,
		positionError,
	};
};
