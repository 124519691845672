import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faCalendar, faClock, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Card, CardHeader, CardBody, ListGroup } from "reactstrap";
import Action from './Action';
import { useVisible } from '../../../../utils/hooks/useVisible';
import AddShift from "../../../Request/AddShift";
import AddAbsence from "../../../Request/AddAbsence";
import AddSchedule from '../../../Request/AddSchedule';

const Requests = () => {
    const { t } = useTranslation();
    const { visible, selected, open, close } = useVisible();

    return (
        <div className='d-md-flex flex-md-column h-100'>
            <Card className='h-100 mb-0'>
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h5 className='h3 mb-0'>
                            {t("requests")}
                        </h5>

                        <Link className='btn btn-default btn-neutral btn-sm' to="/request">
                            {t("view-current-request")}
                        </Link>
                    </div>
                </CardHeader>

                <CardBody>
                    <ListGroup className='list my--3' flush>
                        <Action icon={faCalendar} onClick={() => open("add-schedule")} color="#F7961D" id="schedule">
                            {t("add-schedule-request")}
                        </Action>

                        <Action icon={faClock} onClick={() => open("add-shift")} color="#EF5323">
                            {t("add-shift-request")}
                        </Action>

                        <Action icon={faQuestionCircle} onClick={() => open("add-absence")} color="#4FC0B3">
                            {t("add-absence-request")}
                        </Action>
                    </ListGroup>
                </CardBody>
            </Card>

            <AddShift
                isOpen={visible && selected === "add-shift"}
                onClose={close}
            />

            <AddAbsence
                isOpen={visible && selected === "add-absence"}
                onClose={close}
            />

            <AddSchedule 
                isOpen={visible && selected === "add-schedule"}
                onClose={close}
            />
        </div>
    );
}

export default Requests;
