import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Col, Row } from "reactstrap";
import ResourceSelect from "../../components/Inputs/ResourceSelect";
import ColumnToggle from "../../components/NTable/Actions/ColumnToggle";
import Filters from "../../components/NTable/Actions/Filters";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
	renderCompanyResourceLabel,
} from "../../utils/helpers/settings";
import { getCompaniesSearchFilters } from "../Profile/Personal/Actions/ChangeCompanyInformation";
import { useColumnFiltersConfig } from "../../components/NTable/hook/useColumnFiltersConfig";

function Actions({ children, filters, setFilters, columns }) {
	const { t } = useTranslation();

	const baseConfig = useColumnFiltersConfig(columns);

	const config = useMemo(() => {
		return [
			...baseConfig,
			{
				name: "payGroup",
				label: t("pay-group"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/pay-group"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
			{
				name: "employeeType",
				label: t("employee-type"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/employee-type"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
			{
				name: "project",
				label: t("project"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/project"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
			{
				name: "union",
				label: t("union"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/union"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
			{
				name: "companies",
				label: t("companies"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/companies"
						hasSearch
						getSearchFilters={getCompaniesSearchFilters}
						mode="multiple"
						renderLabel={renderCompanyResourceLabel}
					/>
				),
			},
			{
				name: "calculationGroup",
				label: t("calculation-group"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/calculation-group"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
			{
				name: "roles",
				label: t("roles"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/user-roles"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
			{
				name: "holiday",
				label: t("holiday"),
				type: "resource-select",
				component: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/holiday"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			},
		];
	}, [t, baseConfig]);

	return (
		<CardHeader className="border-top p-3">
			<Row className="d-flex align-items-center justify-content-between mb-3 mb-md-0">
				<Col md={12} lg={3}>{children}</Col>

				<Col md={12} lg={children ? 9 : 12}>
					<div className="d-flex align-items-start">
						<Filters
							config={config}
							filters={filters}
							setFilters={setFilters}
						/>

						<ColumnToggle columns={columns} />
					</div>
				</Col>
			</Row>
		</CardHeader>
	);
}

export default Actions;
