import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../utils/api"
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { ProfileContext } from "../../context";

export const useJobHistory = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const { authGet } = useApi();
	const { isSelf, user } = useContext(ProfileContext)

	const fetch = useCallback(async(params, controller) => {
		setLoading(true);
		try {
			const url = isSelf 
				? "/profile/job-history" 
				: `/users/${user?.id}/job-history`;

			if (user?.id) {
				const response = await authGet(url, {
					params,
					signal: controller.signal
				});
	
				if(response) {
					setData(response?.result);
					setTotalItems(response?.totalItems);
				}
			}
		} catch(err) {
			getErrorMessage(err, t)
		} finally {
			setLoading(false);
		}
	}, [isSelf, user?.id, setLoading, setData, setTotalItems, authGet, t]);

	return { fetch, data, totalItems, loading }
}