import { useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "./Map";
import Marker from "./Marker";
import { mapStyleConfig } from '../../../../../../config/mapStyleConfig';

function Map({
	marker,
	center,
	zoom,
	coordinates,
	setCoordinates,
	setCenter,
	setZoom,
}) {
	const options = useMemo(
		() => ({
			center,
			zoom,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			...mapStyleConfig,
		}),
		[zoom, center],
	);

	return (
		<Wrapper
			apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
		>
			<GoogleMap
				options={options}
				coordinates={coordinates}
				setCoordinates={setCoordinates}
				setCenter={setCenter}
				setZoom={setZoom}
			>
				<Marker
					position={marker}
					icon={{
						path: window.google.maps.SymbolPath.CIRCLE,
						scale: 7,
						strokeColor: "red",
					}}
				/>
			</GoogleMap>
		</Wrapper>
	);
}

export default Map;
