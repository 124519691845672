import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { unserializeUrlFilters } from "./useTableURLFilters";
import isFinite from "lodash/isFinite";

export const useInitializeUrlFilters = ({
    hasURLBindedFilters,
    initialState,
    setFilters,
}) => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (hasURLBindedFilters) {
            const {
                pageIndex,
                pageSize,
                filters,
            } = unserializeUrlFilters(searchParams);
            if (
                !isFinite(pageIndex)
                || !isFinite(pageSize)
                || filters.length === 0
            ) {
                setFilters?.(initialState);
            }
        }
    }, [searchParams, initialState, setFilters]);
}
