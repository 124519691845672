import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import classNames from "classnames";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import ConfirmModal from "./ConfirmModal";
import { useQrCodeClockIn } from "../context";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import { useClock } from "./api";
import { useLastClock } from "./store";
import { getAddress } from "../../../utils/helpers/geocode";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useTime } from "../../../utils/hooks/time";
import { useCompany, useCompanyTimeFormat } from "../../../utils/hooks/company";

export const getClockAction = (type, mode) => {
	if (type === "SHIFT" && mode === "START") {
		return "clock-in";
	} else if (type === "BREAK" && mode === "START") {
		return "start-break";
	} else if (type === "BREAK" && mode === "END") {
		return "end-break";
	} else {
		return "clock-out";
	}
};

function Home() {
	const { t } = useTranslation();
	const {
		user,
		qrCode,
		dispatch,
		flyers,
		position,
		loading: loadingPosition,
		error: positionError,
		isGeolocationEnabled,
	} = useQrCodeClockIn();
	const isGeofenceRequired = user?.calculationGroup?.geofenceLocation;
	const { ip, getIp } = useGetIpAddress();
	const { submit, loading, error } = useClock();
	const { visible, open: openConfirmModal, close } = useVisible();
	const company = useCompany();
	const timeFormat = useCompanyTimeFormat();

	const canClockIn = useMemo(
		() => user?.permissions?.clockin?.canClockInOnQrcode,
		[user?.permissions?.clockin?.canClockInOnQrcode],
	);

	const startShiftFlyers = useMemo(() => {
		return flyers
			?.filter(({ place }) => place === "start-shift");
	}, [flyers]);

	const onClick = useCallback(async () => {
		const data = {
			type: "SHIFT",
			mode: "START",
			location: qrCode?.location?.id,
			job: user?.jobs?.find(
				({ primary, status }) => primary && status === "active",
			)?.id,
			project: {
				id: qrCode?.project?.id,
				description: qrCode?.project?.description,
				code: qrCode?.project?.code,
				glSegment: qrCode?.project?.glSegment,
				orgLevelGroup: qrCode?.project?.orgLevelGroup,
				level: qrCode?.project?.orgLevelGroup?.level?.replace("_", ""),
			},
			device: "qr-code",
		};

		if (isGeofenceRequired && isGeolocationEnabled) {
			if (!position?.lat || !position?.lng) {
				toast.error(t("required-geofence"));
				return;
			}

			const address = await getAddress({
				lat: position?.lat,
				lng: position?.lng,
			});
			data.geofence = {
				lat: position?.lat,
				lng: position?.lng,
				address,
			};
		}

		if (ip) {
			data.ip = ip;
		}

		const action = getClockAction(data.type, data.mode);

		submit(
			data,
			() => {
				const clockMode = startShiftFlyers?.length > 0
					? "start-shift-modal"
					: action;
				dispatch({
					type: "set-clock-mode",
					payload: { clockMode },
				})

			},
			() => getErrorMessage(error, t),
		);
	}, [
		submit,
		dispatch,
		ip,
		position,
		isGeofenceRequired,
		isGeolocationEnabled,
		qrCode,
		user,
		error,
		startShiftFlyers?.length,
		t,
	]);

	const lastClock = useLastClock();

	const currentTime = useTime();

	const canClockInWithoutConfirm = useMemo(() => {
		if (!lastClock || !lastClock?.time || !(qrCode?.timeDifference || company?.settings?.timeDifference)) {
			return true;
		}
		const timeDiff = Number(qrCode?.timeDifference || company?.settings?.timeDifference);
		if (timeDiff < 1 || typeof timeDiff !== 'number') {
			return true;
		}

		const time = lastClock?.time;
		const difference = moment.parseZone(currentTime).diff(moment.parseZone(time), "seconds");

		return difference > timeDiff;

	}, [lastClock, qrCode?.timeDifference, currentTime, company]);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	useEffect(() => {
		if (!isGeolocationEnabled) {
			toast.error(t("enable-location"));
		} else if (positionError && typeof positionError === "object") {
			toast.error(positionError);
		}
	}, [positionError, isGeolocationEnabled, t]);

	return (
		<div className="d-flex flex-column">
			<div className="col text-center">
				<span>{moment().format("dddd, MMMM DD, yyyy")}</span>
				<h1 className="text-info">
					{moment().format(timeFormat)}
				</h1>
			</div>

			<img
				alt="..."
				className={classNames(
					"rounded-circle img-center img-fluid  mb-1",
				)}
				src={"/assets/images/Clock in.svg"}
				style={{ width: "380px" }}
			/>

			{canClockIn && (
				<div className="col text-center">
					<Button
						color="default"
						className="text-white px-7 clock-btn"
						onClick={canClockInWithoutConfirm ? onClick : openConfirmModal}
						disabled={
							loading ||
							loadingPosition ||
							!(
								isGeofenceRequired &&
								position?.lat &&
								position?.lng
							)
						}
						loading={
							loading ||
							loadingPosition ||
							!(
								isGeofenceRequired &&
								position?.lat &&
								position?.lng
							)
						}
					>
						{t("clock-in")}
					</Button>
				</div>
			)}

			{visible && (
				<ConfirmModal
					isOpen={visible}
					submit={onClick}
					close={close}
					loading={loading}
				/>
			)}
		</div>
	);
}

export default Home;
