import {
	useEffect,
	useMemo,
	useCallback,
	createContext,
	useContext,
} from "react";
import moment from "moment-timezone";
import { useSearchParams } from "react-router-dom";
import { usePayGroups } from "../../utils/api/payGroup";
import {
	getStartDateFromEndDate,
	getEndDateFromStartDate,
} from "../../features/Settings/PayPolicies/PayGroup/Form/helpers";
import { useInitialState } from "../../utils/hooks/useInitialState";

export const DateFiltersContext = createContext();

export const availableModes = ["date", "week", "month", "custom", "pay-group"];

const urlDateFormat = "YYYY-MM-DD";

const getQueryParamValue = (name, value) => {
	switch (name) {
		case "from":
		case "to": {
			return moment.parseZone(value).format(urlDateFormat);
		}
		default: {
			return value;
		}
	}
};

export const formatQueryParams = (values, search, isDashboardComponent) => {
	const query = new URLSearchParams(search);
	for (const name in values) {
		const value = values[name];
		if (value === null) {
			query.delete(name);
		} else if (value !== undefined) {
			query.set(name, getQueryParamValue(name, value));
		}
	}
	if (!isDashboardComponent) {
		return query.toString();
	}
};

export const DateFiltersProvider = ({
	initialFilters: _initialFilters,
	children,
	isDashboardComponent = false,
}) => {
	const initialFilters = useInitialState(
		_initialFilters || {
			mode: "week",
			from: moment().startOf("week").toISOString(true),
			to: moment().endOf("week").toISOString(true),
			payGroup: undefined,
			payGroupPeriod: undefined,
		},
	);
	const {
		data: payGroups,
		hasFetched: hasFetchedPayGroups,
		fetch: fetchPayGroups,
	} = usePayGroups();

	const [search, setSearch] = useSearchParams();

	const {
		mode,
		from,
		to,
		payGroup: payGroupCode,
		payGroupPeriod,
	} = useMemo(() => {
		const mode = search.get("mode");
		const from = search.get("from");
		const to = search.get("to");
		const payGroup = search.get("payGroup");
		const payGroupPeriod = search.get("payGroupPeriod");

		return {
			mode: mode || undefined,
			from: from
				? moment(from, urlDateFormat).toISOString(true)
				: undefined,
			to: to ? moment(to, urlDateFormat).toISOString(true) : undefined,
			payGroup: payGroup || undefined,
			payGroupPeriod: payGroupPeriod || undefined,
		};
	}, [search]);

	const payGroup = useMemo(() => {
		return payGroups.find(({ code }) => payGroupCode === code);
	}, [payGroupCode, payGroups]);

	const setFilters = useCallback(
		(filters) => {
			const query = formatQueryParams(
				filters,
				search,
				isDashboardComponent,
			);
			setSearch(query);
		},
		[setSearch, search, isDashboardComponent],
	);

	const resetFilters = useCallback(() => {
		setFilters(initialFilters);
	}, [setFilters, initialFilters]);

	useEffect(() => {
		if (!mode || !availableModes.includes(mode)) {
			resetFilters();
		}
	}, [mode, to, from, resetFilters]);

	useEffect(() => {
		if (mode === "pay-group" && payGroup) {
			if (hasFetchedPayGroups && !payGroup) {
				resetFilters();
			}
		}
	}, [payGroup, payGroups, hasFetchedPayGroups, resetFilters]);

	useEffect(() => {
		if (mode === "pay-group") {
			if (payGroup && payGroupPeriod) {
				let { frequency, periodStartDate, periodEndDate } = payGroup;
				periodStartDate = moment
					.parseZone(periodStartDate)
					.toISOString(true);
				periodEndDate = moment
					.parseZone(periodEndDate)
					.toISOString(true);
				let period = payGroupPeriod;
				let from, to;
				switch (payGroupPeriod) {
					case "past": {
						to = moment
							.parseZone(periodStartDate)
							.subtract(1, "day")
							.toISOString(true);
						from = getStartDateFromEndDate(
							moment.parseZone(to),
							frequency,
						).toISOString(true);
						break;
					}
					case "current": {
						from = periodStartDate;
						to = periodEndDate;
						break;
					}
					case "next": {
						from = moment
							.parseZone(periodEndDate)
							.add(1, "day")
							.toISOString(true);
						to = getEndDateFromStartDate(
							from,
							frequency,
						).toISOString(true);
						break;
					}
					default: {
						period = "current";
						from = periodStartDate;
						to = periodEndDate;
						break;
					}
				}
				setFilters({ payGroupPeriod: period, from, to });
			}
		} else {
			if (payGroup || payGroupPeriod) {
				setFilters({
					payGroup: null,
					payGroupPeriod: null,
				});
			}
		}
	}, [mode, payGroup, payGroupPeriod, setFilters]);

	useEffect(() => {
		const controller = new AbortController();
		fetchPayGroups({
			params: { pagination: "false" },
			signal: controller.signal,
		});
		return () => controller.abort();
	}, [fetchPayGroups]);

	const value = useMemo(
		() => ({ mode, from, to, payGroup, payGroupPeriod, setFilters }),
		[mode, from, to, payGroup, payGroupPeriod, setFilters],
	);

	return (
		<DateFiltersContext.Provider value={value}>
			{children}
		</DateFiltersContext.Provider>
	);
};

export const useDateFilters = () => {
	const context = useContext(DateFiltersContext);
	if (context === undefined) {
		throw new Error("useDateFilters should be used within a provider");
	}
	return context;
};
