import { useEffect, useMemo, useState } from "react";
import Modal from "../../../../../components/Modal";
import Form from "./Form";
import Title from "./Title";
import "./style.scss";
import ResponseTable from "./ResponseTable";

function BulkClockModal({
	selectedUsers,
	isOpen,
	close,
	status: menu,
	setSelectedUsers,
	setData
}) {
	const [status, setStatus] = useState(menu);
	const [response, setResponse] = useState(null);

	useEffect(() => {
		setStatus(menu);
	}, [menu]);
  
  useEffect(() => {
    if (!isOpen) {
      setResponse(null);
    }
  }, [isOpen, setResponse]);

  let content;
  if (response) {
    content = <ResponseTable response={response} />;
  } else {
    content = (
      <Form
        status={status}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        setData={setData}
        setStatus={setStatus}
        menu={menu}
        setResponse={setResponse}
      />
    );
  }

	return (
		<Modal
			isOpen={isOpen}
			className="bulk-clock-modal"
			title={<Title close={close} status={status} />}
		>
      {content}
		</Modal>
	);
}

export default BulkClockModal;
