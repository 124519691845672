import { useRef, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { motion } from "framer-motion";
import "./_style.scss";

function Drawer({ className, open, close, title, children, style, footer }) {
	const ref = useRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				window.innerWidth < 768 &&
				ref.current &&
				!ref.current.contains(event.target)
			) {
				close(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, close]);

	return (
		open && (
			<motion.div
				key="panel"
				ref={ref}
				className={classNames(
					"drawer position-absolute m-0 col-11 col-sm-8 col-md-6 col-lg-5 p-0 drawer-absolute top-0 right-0 h-100",
					className,
				)}
				initial={{ x: "50%", opacity: 0.4 }}
				animate={{ x: 0, opacity: 1 }}
				transition={{ duration: 0.2, ease: "easeOut" }}
				style={style}
			>
				<Card className="shadow-none border border rounded-0 mb-0 overflow-auto scrollbar h-100">
					<CardHeader className="border-bottom d-flex justify-content-between align-items-center">
						<h3 className="mb-0">{title}</h3>

						<FontAwesomeIcon
							className="cursor-pointer font-weight-bolder text-center"
							icon={faClose}
							onClick={close}
						/>
					</CardHeader>

					<div className="content">
						<CardBody>{children}</CardBody>
					</div>

					{footer && (
						<CardFooter className="border-0 pt-0 px-1">
							{footer}
						</CardFooter>
					)}
				</Card>
			</motion.div>
		)
	);
}

export default Drawer;
