import {useCallback} from "react";
import {useExportTimeSheets} from "../../api/useExportTimeSheets";
import {useDateFilters} from "../../hooks/useDateFilters";
import { useActionWithAccess } from "../../../../utils/hooks/access";

export const useExport = ({ ids, columns, filters, other = {} }) => {
    const {from, to} = useDateFilters();
    const {downloadTeamSummary, downloadTeamDetails} = useExportTimeSheets();

    const downloadSummary = useActionWithAccess(
        useCallback(() => {
            downloadTeamSummary({ from, to, ids, filters, columns, ...other });
        }, [from, to, ids, columns, filters, downloadTeamSummary, other]),
        "timeSheet.canExport",
    );

    const downloadDetails = useActionWithAccess(
        useCallback(() => {
            downloadTeamDetails({ from, to, ids, filters, columns, ...other });
        }, [from, to, ids, columns, filters, downloadTeamDetails, other]),
        "timeSheet.canExportMyTimesheet",
    );

    return { downloadSummary, downloadDetails };
}
