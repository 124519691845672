import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field } from "rc-field-form";
import { Card, CardHeader, Row, Col } from "reactstrap";
import Button from "../../../../../components/Button";
import Topbar from "../../components/Topbar";
import ColumnsTable from "../../components/ColumnsTable";
import FiltersTable from "../../components/FiltersTable";
import "../style.scss";
import { useConfigurationModule } from "../../hooks/useConfigurationModule";
import { useMergeConfigurations } from "../../helpers";
import { useConfigurationActions } from "../../api/useConfiguration";

import { useFiltersDefaultValue } from "../hooks/useFiltersDefaultValue";
import { useColumnsDefaultValue } from "../hooks/useColumnsDefaultValue";

import { useColumns, useHiddenColumns } from "./useColumns";
import { useFilters } from "../../../../TimeSheet/Home/Actions/useFilters";
import OptionsTable from "../../components/OptionsTable";

const options = ["columns", "filters", "settings"];

function MyTimesheet() {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const config = useConfigurationModule("myTimesheet");

    const [menu, setMenu] = useState("columns");

    const columns = useColumns();
    const filters = useFilters();

    const content = useMemo(() => {
        switch (menu) {
            case "columns": {
                return (
                    <ColumnsTable config={columns} />
                );
            };
            case "filters": {
                return (
                    <FiltersTable config={filters} form={form} />
                );
            };
            case "settings": {
                const fields = [
                    { label: t("daily-summary"), name: "hideDailySummary" },
                    { label: t("weekly-summary"), name: "hideWeeklySummary" },
                ];
                return (
                    <OptionsTable fields={fields} />
                );
            };
        }
    }, [t, menu, columns, filters, form]);

    const { submit, submitting } = useConfigurationActions("myTimesheet");
    const onFinish = useCallback(async (formValues) => {
        try {
            await submit({
                entity: "company",
                config: formValues,
            });
        } catch (err) { }
    }, [submit]);

    const defaultColumnsValue = useMergeConfigurations(
        useColumnsDefaultValue({
            columns,
            hiddenColumns: useHiddenColumns(),
        }),
        config?.columns,
    );
    const defaultFiltersValue = useMergeConfigurations(
        useFiltersDefaultValue(filters),
        config?.filters,
    );
    useEffect(() => {
        form.setFieldsValue({
            columns: defaultColumnsValue,
            filters: defaultFiltersValue,
            hideDailySummary: config?.hideDailySummary || false,
            hideWeeklySummary: config?.hideWeeklySummary || false,
            defaultFilter: config?.defaultFilter,
        })
    }, [form, config, defaultColumnsValue, defaultFiltersValue]);

    return (
        <div className="d-flex h-100 configuration">
            <Card className="flex-fill mb-0 h-100">
                <Form form={form} onFinish={onFinish} style={{ height: "calc(100% - 160px)" }}>
                    <CardHeader>
                        <Row>
                            <Col xs="6" className="pl-4 d-flex">
                                <h3 className="mb-0 mr-5">{t("my-timesheet")}</h3>
                            </Col>

                            <Col className="text-right" xs="6">
                                <Button
                                    className="btn-dark px-3 btn-round btn-icon"
                                    size="sm"
                                    loading={submitting}
                                    type="submit"
                                >
                                    {t("save")}
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <Topbar options={options} menu={menu} setMenu={setMenu} />

                    <div
                        className="px-4"
                        style={{ overflow: "auto" }}
                    >
                        <Field name="columns" />
                        <Field name="filters" />
                        <Field name="hideDailySummary" />
                        <Field name="hideWeeklySummary" />
                        <Field name="defaultFilter" />

                        {content}
                    </div>
                </Form>
            </Card>
        </div>
    );
}

export default MyTimesheet;
