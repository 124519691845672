// import moment from "moment-timezone";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import useApi from "../../../../../utils/api";
// import { formatParams } from "../../../../../utils/api/format";
// import { formatDateRange } from "../../../../../utils/helpers/api";
import {
	getToastErrorMessage,
	isCancelError,
} from "../../../../../utils/helpers/errors";
import { formatTimeSheetParams } from "../../../api/helpers";

// const dummyData = new Array(5).fill(null).map((_, i) => ({
// 	data: [
// 		{ payCode: { code: "PC-1" }, duration: 10, total: 50, },
// 		{ payCode: { code: "PC-2" }, duration: 20, total: 40, },
// 		{ payCode: { code: "PC-3" }, duration: 30, total: 30, },
// 		{ payCode: { code: "PC-4" }, duration: 40, total: 20, },
// 		{ payCode: { code: "PC-5" }, duration: 50, total: 10, },
// 	],
// 	startDate: moment().add(i, "day").startOf("day"),
// 	endDate: moment().add(i + 1, "day").startOf("day"),
// }));

export const useUserOverview = (id) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const { authGet } = useApi();

	const fetch = useCallback(
		async ({ params, ...config }) => {
			if (!params.from || !params.to) {
				return;
			}

			setLoading(true);
			try {
				const response = await authGet(
					`/time-sheet/weekly-summary/${id}`,
					{
						...config,
						params: formatTimeSheetParams(params),
					},
				);
				setData(response);
			} catch (err) {
				if (!isCancelError(err)) {
					toast.error(getToastErrorMessage(err));
				}
			} finally {
				setLoading(false);
			}
		},
		[id, setLoading, authGet, setData],
	);

	return { data, fetch, loading };
};
