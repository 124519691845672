import useSwr from "swr";
import Select from "../../../../../components/Inputs/Select";
import { useCompanyId } from "../../../../../utils/hooks/company";
import { systemReports } from "../../../../Reports/SystemReports/Table/systemReports";
import { OptGroup } from "rc-select";
import { t } from "i18next";

function ReportSelect({ form, onChange, ...props }) {
    const companyId = useCompanyId();
    const { data, isLoading } = useSwr(() => {
        if (!companyId) {
            return null;
        }
        return {
            url: "/reports",
            params: {
                company: companyId,
                pagination: "false",
            },
        };
    });
    return (
        <Select
            loading={isLoading}
            showSearch
            optionFilterProp="children"
            onChange={(value, { isSystem }) => {
                form.setFieldValue("isSystem", isSystem);
                onChange(value);
            }}
            {...props}
        >
            {isLoading ? null : (
                <>
                    <OptGroup label={t("system-reports")}>
                        {systemReports.map((report) => (
                            <Select.Option key={report.id} value={report.id} isSystem={true}>
                                {report.name}
                            </Select.Option>
                        ))}
                    </OptGroup>
                    <OptGroup label={t("custom-reports")}>
                        {data?.result.map((option) => (
                            <Select.Option key={option.id} value={option.id} isSystem={false}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </OptGroup>
                </>
            )}
        </Select>
    );
}

export default ReportSelect;