import { createContext, useContext, useMemo } from "react";
import useSWR from "swr";
import { useCompany } from "../../utils/hooks/company";

const PayCodesContext = createContext();

export const PayCodesProvider = ({ children, params = {} }) => {
    const company = useCompany();
    const { data, isLoading } = useSWR({
        url: "/pay-code",
        params: {
            pagination: "false",
            withoutLookups: true,
            company: company.id,
            ...params,
        },
    });

    const value = useMemo(
        () => ({ data: data?.result, isLoading }),
        [data?.result, isLoading],
    );

    return (
        <PayCodesContext.Provider value={value}>
            {children}
        </PayCodesContext.Provider>
    )
}

export const usePayCodes = () => {
    const context = useContext(PayCodesContext);
    if (context === undefined) {
        throw new Error("usePayCodes should be used within a provider");
    }
    return context;
}

