import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Label } from "reactstrap";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";

function StartAndEndFields({ form, disabled }) {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column w-100">
			<div className="d-flex flex-column w-100 mr-1">
				<Label className="d-flex">
					{t("start")}
					<span className="text-red">*</span>
				</Label>

				<span className="text-xs mb-1">
					{"("}
					{t("number-of-days-report-running")}
					{")*"}
				</span>

				<Field
					name="start"
					rules={[
						{
							required: true,
							message: t("required-start"),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								const end = getFieldValue("end");
								const report = getFieldValue("reportId");

								if (report) {
									if (
										value &&
										(Number(value) >
											moment().diff(
												moment(report?.createdAt),
												"days",
											) ||
											Number(value < 0))
									) {
										return Promise.reject(
											new Error(
												t(
													"start-from-zero-to-days", {
													days: moment().diff(
														moment(
															report?.createdAt,
														),
														"days",
													)
												}
												),
											),
										);
									} else if (
										value &&
										Number(end) > Number(value)
									) {
										return Promise.reject(
											new Error(
												t("start-bigger-than-end"),
											),
										);
									}
								} else {
									return Promise.reject(
										new Error(
											t("select-report-before"),
										),
									);
								}

								return Promise.resolve();
							},
						}),
					]}
					dependencies={["end", "reportId"]}
				>
					<Text
						type="number"
						onChange={(e) => {
							return form.setFieldsValue({
								from: moment().subtract(
									Number(e.target.value),
									"days",
								),
							});
						}}
						disabled={disabled}
					/>
				</Field>
			</div>

			<div className="d-flex flex-column w-100">
				<Label className="d-flex">
					{t("end")}
					<span className="text-red">*</span>
				</Label>

				<span className="text-xs mb-1">
					{"("}
					{t("number-of-days-report-running")}
					{")*"}
				</span>

				<Field
					name="end"
					rules={[
						{
							required: true,
							message: t("required-end"),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								const start = getFieldValue("start");
								const report = getFieldValue("reportId");

								if (report) {
									if (
										value &&
										(Number(value) >
											moment().diff(
												moment(report?.createdAt),
												"days",
											) ||
											Number(value < 0))
									) {
										return Promise.reject(
											new Error(
												t(
													"end-from-zero-to-days", {
													days: moment().diff(
														moment(
															report?.createdAt,
														),
														"days",
													)
												}
												),
											),
										);
									} else if (
										value &&
										Number(start) < Number(value)
									) {
										return Promise.reject(
											new Error(
												t("end-smaller-than-start"),
											),
										);
									}
								} else {
									return Promise.reject(
										new Error(
											t("select-report-before"),
										),
									);
								}

								return Promise.resolve();
							},
						}),
					]}
					dependencies={["start", "reportId"]}
				>
					<Text
						type="number"
						onChange={(e) => {
							return form.setFieldsValue({
								to: moment().subtract(
									Number(e.target.value),
									"days",
								),
							});
						}}
						disabled={disabled}
					/>
				</Field>
			</div>
		</div>
	);
}

export default StartAndEndFields;
