import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import Field from "../../../../../../../components/Field";
import LevelSelect from "../../../../../components/LevelSelect";
import SubSection from "./SubSection";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { renderSettingsResourceLabel } from "../../../../../../../utils/helpers/settings";

function Levels({ form }) {
    const { t } = useTranslation();
    const location = Form.useWatch("location", form);
    const { allocatedGroups, loading: loadingGroups } = useGroups();

    return (
        allocatedGroups?.length > 0 && (
            <SubSection title={t("allocated-levels")} loading={loadingGroups}>
                <Row>
                    {allocatedGroups.map((group, i) => {
                        const isOdd = allocatedGroups.length % 2 !== 0;
                        const isLast = (allocatedGroups.length - 1) === i;
                        const size = isOdd && isLast ? 12 : 6;
                        return (
                            <Col md={size}>
                                <Field
                                    key={group.id}
                                    name={group.level.replace("_", "")}
                                    label={renderSettingsResourceLabel(group)}
                                >
                                    <LevelSelect
                                        group={group.id}
                                        location={location}
                                        allowClear
                                    />
                                </Field>
                            </Col>
                        );
                    })}
                </Row>
            </SubSection>
        )
    );
}

export default Levels;
