import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getSettingsOrgResourceSearchFilters,
	renderOrgLevelResourceLabel,
} from "../../../../../utils/helpers/settings";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";

export const useHomeFilters = () => {
	const { t } = useTranslation();
	const { groups } = useGroups();

	const renderLabel = useCallback((option) => {
		const { code, description, glSegment } = option;
		return t("group-home", {
			group: glSegment || code + "-" + description
		});
	}, [t]);

	return useMemo(
		() => [
			{
				id: "locationHome",
				Header: t("locations-home"),
				canFilter: true,
				filter: "resource-select",
				Filter: (
					<ResourceSelect
						resourcePath="/locations?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			},
			...groups.map((group) => ({
				id: `${group.level.replace("_", "")}Home`,
				Header: renderLabel(group),
				canFilter: true,
				filter: "resource-select",
				Filter: (
					<ResourceSelect
						mode="multiple"
						resourcePath={`/${group?.id}/org-levels`}
						renderLabel={renderOrgLevelResourceLabel}
						valuePropName="id"
						getSearchFilters={getSettingsOrgResourceSearchFilters}
						hasSearch
					/>
				),
			})),
		],
		[t, groups, renderLabel],
	);
};
