import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import SimpleTable from "../../../../../components/SimpleTable";
import { useColumns } from "./useColumns";
import { useGetFooterData } from "../helpers/useGetFooterData";
import "./style.scss";
import { useGetHourTypes } from "../helpers/useGetHourTypes";

function SummaryTooltip({ close, isOpen, data }) {
	const { t } = useTranslation();

	const target = useMemo(() => {
		const { isSummaryFooter, isDateCell, isDateFooter, date, user } = data;

		if (isSummaryFooter) {
			return "summaryfooter";
		} else if (isDateCell) {
			return `date${date + user?.id}`;
		} else if (isDateFooter) {
			return `dateFooter${date}`;
		} else {
			return `summary${user?.id}`;
		}
	}, [data]);

	const workedHoursData = useMemo(() => {
		if (data?.isSummaryFooter || data?.isDateFooter) {
			return Object.values(data)
				?.map((item) => item?.data)
				?.flat()
				?.filter((item) => item?.hourType !== "unpaid");
		} else {
			return (
				data?.data?.filter((item) => item?.hourType !== "unpaid") || []
			);
		}
	}, [data]);

	const unpaidData = useMemo(() => {
		if (data?.isSummaryFooter || data?.isDateFooter) {
			return Object.values(data)
				?.map((item) => item?.data)
				?.flat()
				?.filter((item) => item?.hourType === "unpaid");
		} else {
			return (
				data?.data?.filter((item) => item?.hourType === "unpaid") || []
			);
		}
	}, [data]);

	const workedHoursHourType = useMemo(() => {
		if (data?.isSummaryFooter || data?.isDateFooter) {
			return Object.values(data)
				?.map((item) => item?.hourTypes)
				?.flat()
				?.filter((item) => item !== "unpaid");
		} else {
			return data?.hourTypes?.filter((item) => item !== "unpaid");
		}
	}, [data]);

	const hourTypes = useGetHourTypes(workedHoursHourType);

	const unpaidHourTypes = useMemo(() => {
		if (data?.isSummaryFooter || data?.isDateFooter) {
			return Object.values(data)
				?.map((item) => item?.hourTypes)
				?.flat()
				?.filter((item) => item === "unpaid");
		} else {
			return data?.hourTypes?.filter((item) => item === "unpaid");
		}
	}, [data]);

	const unpaid = useGetHourTypes(unpaidHourTypes);

	const {
		workedHoursColumns,
		paidAmountColumns,
		unpaidHoursColumns,
		unpaidAmountColumns,
	} = useColumns({
		hourTypes,
		unpaidHourTypes: unpaid,
	});

	return (
		<Tooltip
			isOpen={isOpen}
			toggle={close}
			trigger="hover"
			autohide={false}
			target={target}
			placement="right"
			className="summary-tooltip"
		>
			<div className="border-bottom mb-3 d-flex justify-content-between align-items-center">
				<h3 className="py-2">{t("worked-hours")}</h3>

				<i
					className="fa-solid fa-xmark cursor-pointer text-dark"
					onClick={close}
				/>
			</div>

			<div className="d-flex align-items-center w-100 summary-table">
				<div className="w-100 mr-1">
					<SimpleTable
						columns={workedHoursColumns}
						data={useGetFooterData(
							workedHoursData?.filter(
								(item) => item !== undefined,
							),
						)}
					/>
				</div>
				<div className="w-100 ml-1">
					<SimpleTable
						columns={paidAmountColumns}
						data={useGetFooterData(
							workedHoursData?.filter(
								(item) => item !== undefined,
							),
						)}
					/>
				</div>
			</div>

			<h3 className="border-bottom py-2 px-0 mx-0 mb-3 text-left">
				{t("other")}
			</h3>

			<div className="d-flex align-items-center w-100 summary-table">
				<div className="w-100 mr-1">
					<SimpleTable
						columns={unpaidHoursColumns}
						data={useGetFooterData(
							unpaidData?.filter((item) => item !== undefined),
						)}
					/>
				</div>

				<div className="w-100 ml-1">
					<SimpleTable
						columns={unpaidAmountColumns}
						data={useGetFooterData(
							unpaidData?.filter((item) => item !== undefined),
						)}
					/>
				</div>
			</div>
		</Tooltip>
	);
}

export default SummaryTooltip;
