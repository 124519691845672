import { useCallback, useState } from "react";
import Table from "../../../../components/Table";
import { TableContext } from "../../../../components/Table/context";
import { useEnhancedTable } from "../../../../components/Table/hook";
import ActionsModal from "./ActionsModal";
import { useColumns } from "./useColumns";
import { DateFiltersProvider } from "../../components/DateRangeFilters/useDateFilters";
import { systemReports } from "./systemReports";

function ReportsTable({ setStep, setData, data }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalMode, setModalMode] = useState(null);

	// const { fetchReports, reports, totalItems, loading } = useReports();

	const generate = useCallback(
		(value, mode) => {
			setData({
				id: value.id,
				name: value?.name,
				columns: value?.columns,
				columnLabels: value?.columnLabels || {},
				// defaultFilters: value?.settings?.defaultFilters,
				//DUMMY DATA 
				extraColumns: value?.extraColumns,
				isDummyData: value?.isDummyData,
				data: (value?.data),
				hasDailyHours: value?.hasDailyHours,
				hasHistory: value?.hasHistory,
				hiddenDateRangeOption: value?.hiddenDateRangeOption
			});
			setIsModalOpen(true);
			setModalMode(mode);
		},
		[setIsModalOpen, setData, setModalMode],
	);



	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		setModalMode(null);
	}, [setIsModalOpen, setModalMode]);

	const columns = useColumns({
		generate,
		setData,
		setStep,
	});

	const table = useEnhancedTable({
		hasURLBindedFilters: false,
		columns,
		data: systemReports,
		totalItems: systemReports?.length,
		hasRowSelection: false,
	});

	const { pageIndex, pageSize, globalFilter: filters, sortBy } = table.state;

	// useEffect(() => {
	// 	const controller = new AbortController();

	// 	fetchReports(
	// 		formatParams({
	// 			pageIndex,
	// 			pageSize,
	// 			filters,
	// 			sortBy,
	// 		}),
	// 		controller,
	// 	);
	// 	return () => controller.abort();
	// }, [fetchReports, pageIndex, pageSize, sortBy, filters]);

	return (
		<DateFiltersProvider>
			<TableContext.Provider value={table}>
				<Table />
			</TableContext.Provider>

			<ActionsModal
				isOpen={isModalOpen}
				close={closeModal}
				data={data}
				setStep={setStep}
				setData={setData}
				mode={modalMode}
			/>
		</DateFiltersProvider>
	);
}

export default ReportsTable;
