import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { noop } from "lodash";
import { usePeopleApi } from "../api";
import { useApi } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";

const errorInFields = (error, fields) =>
	Object.keys(error).some((errorField) => fields.includes(errorField));

const getErrorStep = (error) => {
	if (errorInFields(error, ["type", "isInvited"])) {
		return 0;
	} else if (
		errorInFields(error, [
			"companies",
			"employeeNumber",
			"firstName",
			"middleName",
			"lastName",
			"email",
			"phone",
			"birthDate",
			"hiringDate",
		])
	) {
		return 1;
	} else {
		return 2;
	}
};

export const useAddEmplyeeApi = () => {
	const { t } = useTranslation();
	const { addEmployee } = usePeopleApi();

	const [data, setData] = useState({});
	const [step, baseSetStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const setStep = useCallback(
		(...args) => {
			baseSetStep(...args);
			setError(null);
		},
		[baseSetStep, setError],
	);

	const submit = useCallback(
		async (data, onSuccess = noop, onFailure = noop) => {
			setLoading(true);
			setError(null);
			try {
				const response = await addEmployee(data);
				if (response) {
					onSuccess(response);
				}
			} catch (err) {
				// debugger;
				if (err === "Email already in use") {
					setError({ email: [err] });
					baseSetStep(1);
				} else if(typeof err === "string" && err.startsWith("E11000 duplicate key error collection: simplitime.user index: unique_employeeNumber dup key")) {
					setError({ employeeNumber: [t("unique-employee-number")] });
					baseSetStep(1);
				} else if (typeof err === "object" && err !== null) {
					setError(err);
					baseSetStep(getErrorStep(err));
				} else {
					setError(err);
				}
				onFailure();
			} finally {
				setLoading(false);
			}
		},
		[setError, setLoading, baseSetStep, addEmployee, t],
	);

	return { submit, loading, error, data, setData, step, setStep };
};

export const useMainCalculationGroup = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);

	const fetch = useCallback(async () => {
		setLoading(true);
		setError(null);
		try {
			const res = await authGet(`/calculation-group`, {
				params: { isMain: true },
			});
			if (res && res.result.length > 0) {
				setData(res.result[0]);
			}
		} catch (err) {
			getErrorMessage(err, t);
			setError(err);
		} finally {
			setLoading(false);
		}
	}, [setLoading, setError, setData, authGet, getErrorMessage, t]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return { loading, error, data };
};
