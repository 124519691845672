import i18n from "i18next";
import I18NextHttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import moment from "moment-timezone";

const setLanguageFileUrl = (language, namespace) => {
  const lng = language[0];
  return `/locales/${lng}/${namespace}.json`;
};

export const supportedLngs = ["en", /*"sq", "es"*/];

i18n
  .use(I18NextHttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use({
    type: "logger",
    log(args) {
      this.output('log', args);
    },
    warn(args) {
      this.output('warn', args);
    },
    error(args) {
      this.output('error', args);
    },
    output() { },
  })
  .init({
    debug: true,
    fallbackLng: "en",
    backend: {
      // for all available options read the backend"s repository readme file
      loadPath: (language, namespace) => setLanguageFileUrl(language, namespace)
    },
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    supportedLngs,
    load: "languageOnly",
    nsSeparator: ':::',
    keySeparator: '::',
  });

const language = localStorage.getItem('i18nextLng');

if (language) {
  i18n.changeLanguage(language);
}

export default i18n;
