import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "reactstrap";
import {calculateAnalyticsTotals} from "../helpers";
import "./_style.scss";

import {renderDurationAsNumber} from "../../../utils/helpers/date";
import {getShortHourType} from "../../../utils/helpers/hourType";
import {useRenderPayment} from "../../../utils/hooks/useRenderPayment";

const AnalyticsTable = ({ hourTypes, config, data }) => {
    const {t} = useTranslation();
    const renderPayment = useRenderPayment();
    const { totalDuration, totalPayment } = useMemo(() => {
        if (!data) {
            return { totalDuration: 0, totalPayment: 0 };
        }
        return calculateAnalyticsTotals(data);
    }, [data]);

    return (
        <Card className="analytics-table mb-0 mr-2">
            <div className="inner-container">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    {t("type")}
                                </div>
                            </th>

                            {hourTypes.map((hourType) => (
                                <th key={hourType}>
                                    <div className="text-uppercase">
                                        {getShortHourType(hourType)}
                                    </div>
                                </th>
                            ))}

                            <th>
                                <div>
                                    {t("totals")}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    {t("hourly")}
                                </div>
                            </td>

                            {hourTypes.map((hourType) => (
                                <td key={hourType}>
                                    <div>
                                        {renderDurationAsNumber(config[hourType]?.duration || 0)}
                                    </div>
                                </td>
                            ))}

                            <td className="total">
                                <div>
                                    {renderDurationAsNumber(totalDuration)}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>
                                    {t("amount")}
                                </div>
                            </td>

                            {hourTypes.map((hourType) => (
                                <td key={hourType}>
                                    <div>
                                        {renderPayment(config[hourType]?.total || 0)}
                                    </div>
                                </td>
                            ))}

                            <td className="total">
                                <div>
                                    {renderPayment(totalPayment)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
}

export default AnalyticsTable;
