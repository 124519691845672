import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Wrapper } from "@googlemaps/react-wrapper";
import Marker from "./Marker";
import { renderUserName } from "../../../../utils/helpers/user";
import { mapStyleConfig } from "../../../../config/mapStyleConfig";

const mapStyle = { flexGrow: 1, height: "100%" };

function GoogleMap({ options, children, map, setMap }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map, setMap]);

  useEffect(() => {
    if (map && options) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={mapStyle} />
      {Children.map(
        children,
        (child) => isValidElement(child) && cloneElement(child, { map })
      )}
    </>
  );
}

function Map({ data }) {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);

  const markers = useMemo(() => {
    return data.reduce((total, user) => {
      if (!user.lastClock?.geofence) {
        return total;
      }

      return total.concat({
        position: {
          lat: user.lastClock.geofence.lat,
          lng: user.lastClock.geofence.lng,
        },
        title: renderUserName(user),
      });
    }, []);
  }, [data]);

  const mapOptions = useMemo(
    () => ({
      center: {
        lat: 40.7128,
        lng: -74.006,
      },
      zoom: 11,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false,
      restriction: {
        latLngBounds: {
          north: 85,
          south: -180,
          west: -155,
          east: 180,
        },
        strictBounds: false,
      },
      ...mapStyleConfig,
    }),
    []
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    if (markers.length === 0) {
      map.setCenter(new window.google.maps.LatLng(40.7128, -74.006));
      map.setZoom(11);
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds, markers.length <= 1 ? 20 : 50);

      if (markers.length === 1) {
        map.setZoom(11);
      }
    }
  }, [map, markers]);

  return (
    <Card style={{ height: "calc(100% - 30px)" }}>
      <CardHeader className="py-4">
        <h3 className="pb-1">{t("real-time")}</h3>
      </CardHeader>

      <CardBody className="m-0 p-0">
        <Wrapper
          apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
        >
          <GoogleMap options={mapOptions} map={map} setMap={setMap}>
            {markers.map((marker) => (
              <Marker {...marker} />
            ))}
          </GoogleMap>
        </Wrapper>
      </CardBody>
    </Card>
  );
}

export default Map;
