import { firstToUpper } from "./string";

export const getHourTypeColor = (hourType) => {
	switch (hourType) {
		case "unit":
			return "#181d38";
		case "regular":
			return "#525252";
		case "override":
			return "#d56843";
		case "amount":
			return "#ad3e6d";
		case "overtime":
			return "#2F75B5";
		case "double-time":
			return "#F8219C";
		case "double-time-and-half":
			return "#7F26FF";
		case "pto":
			return "#006EF8";
		case "unpaid":
			return "#F2F1F3";
		case "holiday":
			return "#0f8a01"
		default:
			return "#000";
	}
};

export const getShortHourType = (hourType) => {
	switch (hourType) {
		case "double-time":
			return "dt";
		case "double-time-and-half":
			return "dth";
		case "regular":
		case "override":
			return "reg";
		case "unit":
			return "unit";
		case "overtime":
			return "ot";
		case "pto":
			return "pto";
		case "holiday":
			return "hol";
		default:
			return firstToUpper(hourType.split("-").join(" "));
	}
};
