import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loaders/Loading";
import Home from "./Home";
import Running from "./Running";
import FlyerModal from "./FlyerModal";
import { useQrCodeClockIn } from "../context";
import { useMode, useClockIn, useLastClock } from "./store";
import useApi from "../../../utils/api";
import { useTime } from "../../../utils/hooks/time";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useQrCodeLogout } from "../api";

export const useCurrentShift = () => {
	const { t } = useTranslation();
	const { token, qrCode, user } = useQrCodeClockIn();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [_, { setWorkSummary }] = useClockIn();

	const fetch = useCallback(
		async (controller) => {
			setLoading(true);
			try {
				const response = await authGet("/shift/get-active", {
					signal: controller.signal,
					headers: {
						Authorization: `Bearer ${token}`,
						Platform: "qr-code",
						PLATFORM_ID: qrCode?.id,
						Company: user?.companies[0]?.id,
						MainCompany: user?.mainCompany,
					},
				});
				setWorkSummary(response);
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setWorkSummary, authGet, token, qrCode, user],
	);

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return loading;
};

function Shift() {
	const { t } = useTranslation();
	const { logout } = useQrCodeLogout();
	const mode = useMode();
	const time = useTime();
	const { user, clockMode, flyers: baseFlyers } = useQrCodeClockIn();
	const clock = useLastClock();

	const greeting = useMemo(() => {
		const hour = moment(time).hour();
		if (hour >= 0 && hour < 12) {
			return t("good-morning");
		} else if (hour >= 12 && hour < 20) {
			return t("good-afternoon");
		} else {
			return t("good-evening");
		}
	}, [time, t]);

	const modeColor = useMemo(() => {
		switch (mode) {
			case "shift":
			case "break": {
				return "text-info";
			}
			case "home":
			case "end": {
				return "text-danger";
			}
			default:
				return "text-info";
		}
	}, [mode]);

	const loading = useCurrentShift();

	const place = useMemo(() => {
		if (
			clockMode === "start-shift-modal" ||
			(clock?.mode === "START" && clock?.type === "SHIFT")
		) {
			return "start-shift";
		} else if (
			clockMode === "start-break-modal" ||
			(clock?.mode === "START" && clock?.type === "BREAK")
		) {
			return "start-break";
		} else if (
			clockMode === "end-break-modal" ||
			(clock?.mode === "END" && clock?.type === "BREAK")
		) {
			return "end-break";
		} else if (
			clockMode === "end-shift-modal" ||
			(clock?.mode === "END" && clock?.type === "SHIFT")
		) {
			return "end-shift";
		}
	}, [clockMode, clock?.mode, clock?.type]);

	const flyers = useMemo(() => {
		return baseFlyers
			?.filter((item) => item?.place === place);
	}, [baseFlyers, place]);

	return (
		<div className="position-relative h-100 w-100 align-items-center d-flex flex-column justify-content-center bg-white">
			<Card className="h-100 w-100 bg-transparent shadow-none">
				<CardHeader className="pb-3 pt-5 ">
					<Row>
						<Col
							xs="12"
							className="d-flex justify-content-between align-items-center"
						>
							<div>
								<h3 className="mb-0">
									{greeting},{" "}
									<span className="text-info">
										{user?.firstName}
									</span>
								</h3>

								<small className="text-muted">
									{t("have-great-day", { day: moment().format("dddd").toLocaleLowerCase() })}.
								</small>
							</div>

							<div>
								<FontAwesomeIcon
									className="text-red mr-4"
									icon={faRightFromBracket}
									onClick={logout}
									style={{
										fontSize: "30px",
									}}
								/>

								<FontAwesomeIcon
									className={modeColor}
									icon={faClock}
									style={{
										fontSize: "30px",
									}}
								/>
							</div>
						</Col>
					</Row>
				</CardHeader>

				<CardBody>
					{loading
						? (
							<div className="h-100 d-flex justify-content-center align-items-center">
								<Loading />
							</div>
						) : mode === "home" ||
							(clock?.type === "SHIFT" && clock?.mode === "END") ||
							clockMode === "start-shift-modal"
							? (
								<Home />
							) : (clockMode === "start-break-modal" ||
								clockMode === "end-break-modal" ||
								clockMode === "end-shift-modal" ||
								mode === "shift" ||
								mode === "break")
							&& (
								<Running />
							)}
				</CardBody>
			</Card>

			{flyers?.length > 0
				&& (clockMode === "start-break-modal" ||
					clockMode === "end-break-modal" ||
					clockMode === "end-shift-modal" ||
					clockMode === "start-shift-modal"
				) && <FlyerModal />
			}
		</div>
	);
}

export default Shift;
