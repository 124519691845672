import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import ManageAbilitySelect from "../../../../../components/Inputs/ManageAbilitySelect";
import Checkbox from "../../../../../components/Inputs/Checkbox";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
	canManageLevels,
	canManageLocations,
	hasManagingRole,
} from "../../../../../utils/helpers/user";
import {
	getSettingsOrgResourceSearchFilters,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { ProfileContext } from "../../../context";
import { useUser } from "../../../../../utils/hooks/user";

const getUserName = (user) =>
	`${user.firstName} ${user.middleName ? `${user.middleName} ` : ""}${user.lastName
	}`;

export const getUserSearchFilters = (search) => ({
	page: 1,
	perPage: 10,
	search
});

function ChangeManagement({ close }) {
	const { t } = useTranslation();

	const [form] = Form.useForm();
	const loggedUser = useUser();

	const { user, hierarchyGroup, submitting, submittingError, submit } =
		useContext(ProfileContext);

	const { changeManagement: profileSubmit } = useProfileApi();
	const { changeManagement: peopleSubmit } = usePeopleApi();

	const onAbilityChange = useCallback(
		(ability) => {
			switch (ability) {
				case null:
				case "manage-users":
				case "direct-company":
				case "direct-project":
				case "direct-users":
				case "direct-users-and-organisation-level":
				case "direct-users-and-location":
				case "direct-users-and-organisation-level-and-location":
				case "direct-users-and-organisation-level-and-project":
				case "direct-users-and-pay-groups":
				case "direct-users-and-calculation-group":
				case "direct-org-level-and-users": {
					form.setFieldsValue({
						managedLevels: [],
						managedLocations: [],
						managedPayGroups: [],
						managedCalcGroups: [],
						managedProjects: [],
						managedUsers: [],
					});
					break;
				}
				default:
					break;
			}
		},
		[form],
	);

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				managedLevels: values.managedLevels?.map(({ id }) => id) || [],
				managedLocations:
					values.managedLocations?.map(({ id }) => id) || [],
				managedPayGroups:
					values.managedPayGroups?.map(({ id }) => id) || [],
				managedCalcGroups:
					values.managedCalcGroups?.map(({ id }) => id) || [],
				managedProjects:
					values.managedProjects?.map(({ id }) => id) || [],
				managedUsers: values?.managedUsers?.map(({ id }) => id) || [],
				additionalLevels: values.additionalLevels?.map(({ id }) => id) || [],
			};

			submit(profileSubmit, peopleSubmit, data, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			manageAbility: user?.manageAbility,
			managedLevels: user?.managedLevels,
			additionalLevels: user?.additionalLevels || [],
			managedLocations: user?.managedLocations,
			managedPayGroups: user?.managedPayGroups,
			managedCalcGroups: user?.managedCalcGroups,
			managedProjects: user?.managedProjects,
			managedUsers: user?.managedUsers,
			includeDirectUsers:
				typeof user?.includeDirectUsers !== "undefined"
					? user.includeDirectUsers
					: true,
		});
	}, [user, form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"manageAbility",
				"managedLevels",
				"managedLocations",
				"managedPayGroups",
				"managedCalcGroups",
				"managedProjects",
				"managedUsers",
			],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	if (!hierarchyGroup || !hasManagingRole(loggedUser?.roles || [])) {
		return null;
	}

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="manageAbility"
				label={t("manage-ability")}
			>
				<ManageAbilitySelect
					placeholder={t("manage-ability")}
					onChange={onAbilityChange}
					allowClear
				/>
			</Field>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue }) => {
					const manageAbility = getFieldValue("manageAbility");
					const manageLevels = canManageLevels(manageAbility);
					const manageLocations = canManageLocations(
						manageAbility,
						hierarchyGroup,
					);

					const managePayGroups =
						manageAbility === "direct-users-and-pay-groups";
					const manageCalcGroups =
						manageAbility === "direct-users-and-calculation-group";

					const manageProjects = ["direct-users-and-organisation-level-and-project", 'direct-project'].includes(manageAbility);

					const manageUsers = manageAbility === "manage-users";

					return (
						<>
							{manageAbility && manageAbility !== "direct-users" && (
								<>
									<Field
										name="includeDirectUsers"
										valuePropName="checked"
									>
										<Checkbox id="include-direct-user">
											{t("include-direct-users")}
										</Checkbox>
									</Field>

									<Field
										name="managedUsers"
										label={t("manage-users")}
										rules={[
											{
												required: manageUsers,
												message: t(
													"required-users"
												),
											},
										]}
									>
										<ResourceSelect
											labelPropName="description"
											resourcePath="/users"
											renderLabel={getUserName}
											mode="multiple"
											placeholder={t("users")}
											hasSearch
											getSearchFilters={getUserSearchFilters}
										/>
									</Field>
								</>
							)}

							{manageLevels && (
								<Field
									name="managedLevels"
									label={t("manage-levels")}
									rules={[
										{
											required: true,
											message: t(
												"required-manage-levels"
											),
										},
									]}
								>
									<ResourceSelect
										labelPropName="description"
										resourcePath={`/${hierarchyGroup.id}/org-levels`}
										renderLabel={
											renderOrgLevelResourceLabel
										}
										mode="multiple"
										placeholder={t("organisation-levels")}
										hasSearch
										getSearchFilters={
											getSettingsOrgResourceSearchFilters
										}
									/>
								</Field>
							)}

							{manageLocations && (
								<Field
									name="managedLocations"
									label={t("manage-locations")}
									rules={[
										{
											required: true,
											message: t(
												"required-manage-locations"
											),
										},
									]}
								>
									<ResourceSelect
										labelPropName="description"
										resourcePath="/locations"
										renderLabel={
											renderSettingsResourceLabel
										}
										mode="multiple"
										placeholder={t("locations")}
										hasSearch
										getSearchFilters={
											getSettingsResourceSearchFilters
										}
									/>
								</Field>
							)}

							{managePayGroups && (
								<Field
									name="managedPayGroups"
									label={t("manage-pay-groups")}
									rules={[
										{
											required: true,
											message: t(
												"required-manage-pay-group"
											),
										},
									]}
								>
									<ResourceSelect
										mode="multiple"
										labelPropName="description"
										resourcePath="/pay-group"
										placeholder={t("pay-groups")}
										renderLabel={
											renderSettingsResourceLabel
										}
										hasSearch
										getSearchFilters={
											getSettingsResourceSearchFilters
										}
									/>
								</Field>
							)}

							{manageCalcGroups && (
								<Field
									name="managedCalcGroups"
									label={t("manage-calculation-group")}
									rules={[
										{
											required: true,
											message: t(
												"required-manage-calculation-group"
											),
										},
									]}
								>
									<ResourceSelect
										mode="multiple"
										labelPropName="description"
										resourcePath="/calculation-group"
										placeholder={t("calculation-group")}
										renderLabel={
											renderSettingsResourceLabel
										}
										hasSearch
										getSearchFilters={
											getSettingsResourceSearchFilters
										}
									/>
								</Field>
							)}

							{manageProjects && (
								<Field
									name="managedProjects"
									label={t("manage-projects")}
									rules={[
										{
											required: true,
											message: t(
												"required-manage-projects"
											),
										},
									]}
								>
									<ResourceSelect
										labelPropName="description"
										resourcePath="project"
										renderLabel={
											renderSettingsResourceLabel
										}
										mode="multiple"
										placeholder={t("projects")}
										hasSearch
										getSearchFilters={
											getSettingsResourceSearchFilters
										}
									/>
								</Field>
							)}

							<Field
								name="additionalLevels"
								label={t("additional-levels")}
							>
								<ResourceSelect
									labelPropName="description"
									resourcePath={`/${hierarchyGroup.id}/org-levels`}
									renderLabel={
										renderOrgLevelResourceLabel
									}
									mode="multiple"
									placeholder={t("organisation-levels")}
									hasSearch
									getSearchFilters={
										getSettingsOrgResourceSearchFilters
									}
								/>
							</Field>
						</>
					);
				}}
			</BaseField>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeManagement;
