import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Field from "../../../../../components/Field";
import Table from "./Table";
import { useAssignUsers } from "./api";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { getUserSearchFilters } from "../../../../Profile/Personal/Actions/ChangeManagement";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { Col, Row } from "reactstrap";

const getUserName = (user) => {
    return `${user.firstName} ${user.middleName ? `${user.middleName} ` : ""}${user.lastName}`
};

function AssignUsersToGroupModal({ isOpen, group, close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [response, setResponse] = useState([]);
    const { submit, loading, error } = useAssignUsers();

    const onFinish = useCallback((values) => {
        const data = {
            users: values?.users?.map(({ id }) => id),
            groups: [group?.id]
        };
        submit(data, (response) => {
            setResponse(response);
            form.resetFields();
        });
    }, [submit, setResponse, form]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(["users"], error);
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <Modal
            isOpen={isOpen}
            title={
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {t("assign-users-to-group", {
                        group: renderSettingsResourceLabel(group)
                    })}

                    <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faXmark}
                        onClick={close}
                    />
                </div>
            }
            size="xl"
            centered
        >
            <Row className="my-5">
                <Col>
                    <Table group={group} response={response} />
                </Col>

                <Col>
                    <Form form={form} onFinish={onFinish} className="w-100">
                        <Field name="users" className="mb-5">
                            <ResourceSelect
                                labelPropName="description"
                                resourcePath="/users"
                                renderLabel={getUserName}
                                mode="multiple"
                                placeholder={t("users")}
                                hasSearch
                                getSearchFilters={getUserSearchFilters}
                            />
                        </Field>

                        <BaseField shouldUpdate>
                            {({ }, { }, { getFieldValue }) => {
                                const users = getFieldValue("users");
                                return (
                                    <div className="d-flex align-items-center justify-content-end">
                                        <Button
                                            htmlType="button"
                                            onClick={close}
                                            disabled={loading}
                                        >
                                            {t("close")}
                                        </Button>

                                        <Button
                                            color="primary"
                                            htmlType="submit"
                                            disabled={!users || users?.length === 0}
                                            loading={loading}
                                        >
                                            {t("save")}
                                        </Button>
                                    </div>
                                );
                            }}
                        </BaseField>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
}

export default AssignUsersToGroupModal;
