import { CardHeader, Row, Col } from "reactstrap";
import Filters from "./Filters";
import ColumnToggle from "./ColumnToggle";
import { useColumnFiltersConfig } from "../hook/useColumnFiltersConfig";

function Actions({ children, filters, setFilters, columns }) {
	const config = useColumnFiltersConfig(columns);

	return (
		<CardHeader className="border-top p-3">
			<Row>
				<Col md={12} lg={3}>{children}</Col>

				<Col md={12} lg={children ? 9 : 12}>
					<div className="d-flex align-items-start">
						<Filters
							config={config}
							filters={filters}
							setFilters={setFilters}
						/>

						<ColumnToggle columns={columns} />
					</div>
				</Col>
			</Row>
		</CardHeader>
	);
}

export default Actions;
