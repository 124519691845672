import { useMemo } from "react";
import { useColumns as useTeamColumns } from "../../../../TimeSheet/Team/Table/useColumns";
import { useGroups } from "../../../General/OrganisationLevelGroups/context";
import { useAllowedColumns } from "../hooks/useAllowedColumns";

export const useHiddenColumns = () => {
    const { groups } = useGroups();
    return useMemo(() => [
        "jobs",
        "locations",
        "pb",
        "upto",
        "unpaid",
        ...groups.reduce((total, { allocatedBy, level }) => {
            if (!allocatedBy) {
                return total.concat(level.replace("_", ""));
            }
            return total;
        }, []),
    ], [groups]);
}

export const useColumns = () => {
    const columns = useTeamColumns({});
    return useAllowedColumns(columns);
}
