import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "../../../../utils/hooks/access";
import { useCompany } from "../../../../utils/hooks/company";
import { useColumnsMap } from "../helpers";

export const useColumns = ({ generate, setData, setStep }) => {
	const { t } = useTranslation();
	const company = useCompany();

	const { hasAccess: canGenerate } = useAccess("reports.canGenerate");
	const { hasAccess: canExport } = useAccess("reports.canExport");

	const { getColumns } = useColumnsMap();

	const onRowClick = useCallback(
		(value, data) => {
			setData({
				id: data?.id,
				name: value,
				columns: getColumns(data?.columns),
				columnLabels: data?.columnLabels || {},
				// defaultFilters: data?.settings?.defaultFilters,
				//DUMMY DATA
				// extraColumns: data?.extraColumns,
				// isDummyData: data?.isDummyData,
				hasDailyHours: data?.hasDailyHours,
				hiddenDateRangeOption: data?.hiddenDateRangeOption,
				// data: data?.data
			});
			setStep(1);
		},
		[setData, setStep, getColumns],
	);

	return useMemo(() => {
		const columns = [
			{
				Header: t("report-name"),
				accessor: "name",
				Cell: ({ value, row: { original } }) => (
					<span
						className="text-primary font-weight-bold cursor-pointer"
						onClick={() => onRowClick(value, original)}
					>
						{value}
					</span>
				),
			},
		];

		if (canExport || canGenerate) {
			columns.push({
				Header: t("actions"),
				id: "actions",
				Cell: ({ row: { original } }) => (
					<div>
						{canGenerate && (
							<span className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary">
								<i
									className="fa-solid fa-play"
									onClick={() =>
										generate(original, "generate")
									}
								/>
							</span>
						)}

						{canExport && (
							<span className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary">
								<i
									className="fa-solid fa-download"
									onClick={() => generate(original, "export")}
								/>
							</span>
						)}
					</div>
				),
			});
		}

		return columns;
	}, [
		t,
		generate,
		company,
		onRowClick,
		canGenerate,
		canExport,
	]);
};
