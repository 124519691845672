import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useOriginalClocks = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);

	const fetch = useCallback(
		async (params, controller) => {
			setLoading(true);
			try {
				const response = await authGet("/shift/punches", {
					params,
					signal: controller?.signal,
				});
				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (error) {
				getErrorMessage(error, t);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setData, setTotalItems, t, authGet],
	);

	return { fetch, loading, data, totalItems };
};

export const useOriginalClocksExport = () => {
	const { t } = useTranslation();
	const { call } = useApi();
	const [loading, setLoading] = useState(false);

	const originalClocksExport = useCallback(
		async (params, onSuccess) => {
			setLoading(true);
			try {
				const response = await call(
					{
						url: "/export/punches",
						params,
						headers: {
							"Content-Type": "Content-Disposition",
						},
						responseType: "arraybuffer",
					},
					true,
				);

				if (response) {
					const header = response.headers["content-disposition"];
					const parts = header.split(";");
					const filename = parts[1].split("=")[1];
					const downloadLink = document.createElement("a");
					const blob = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					const docUrl = URL.createObjectURL(blob);
					downloadLink.href = docUrl;
					downloadLink.download = filename;
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);

					onSuccess();
					return { status: response.status };
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[call, t, setLoading],
	);

	return { originalClocksExport, loading };
};
