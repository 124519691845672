import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPrimaryActiveResource } from "../../../../../utils/helpers/user";

export const useLocationsColumn = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("locations"),
        accessor: "locations",
        disableSortBy: true,
        Cell: ({ value }) => {
            const location = getPrimaryActiveResource(value);
            if (!location) {
                return null;
            }
            return location.displayName;
        },
    }), [t]);
};
