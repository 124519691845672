import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import moment from "moment-timezone";
import { Row } from "reactstrap";
import Button from "../../../../components/Button";
import ConfirmModal from "../ConfirmModal";
import FlyerModal from "../FlyerModal";
import { useClock } from "../api";
import { useClockIn, useWorkSummary, useLastShift, useLastClock } from "../store";
import { useGetIpAddress } from "../../../TimeClock/ClockIn/api";
import { getAddress } from "../../../../utils/helpers/geocode";
import { useQrCodeClockIn } from "../../context";
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { getClockAction } from "../Home";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useTime } from "../../../../utils/hooks/time";
import { useCompany } from "../../../../utils/hooks/company";

const Actions = () => {
	const { t } = useTranslation();

	const { submit, error } = useClock();
	const company = useCompany();
	const [{ mode }] = useClockIn();
	const shift = useLastShift();
	const workSummary = useWorkSummary();
	const { ip, getIp } = useGetIpAddress();

	const {
		user,
		qrCode,
		dispatch,
		position,
		isGeolocationEnabled,
		error: positionError,
		flyers,
		attestations
	} = useQrCodeClockIn();

	const lastClock = useLastClock();

	const {
		visible: isConfirmModalOpen,
		open: openConfirmModal,
		close: closeConfirmModal,
		selected
	} = useVisible();

	const currentTime = useTime();

	const canClockInWithoutConfirm = useMemo(() => {
		if (!lastClock || !lastClock?.time || !(qrCode?.timeDifference || company?.settings?.timeDifference)) {
			return true;
		}
		const timeDiff = Number(qrCode?.timeDifference || company?.settings?.timeDifference);
		if (timeDiff < 1 || typeof timeDiff !== 'number') {
			return true;
		}

		const time = lastClock?.time;
		const difference = moment.parseZone(currentTime).diff(moment.parseZone(time), "seconds");

		return difference > timeDiff;

	}, [lastClock, qrCode?.timeDifference, currentTime, company]);

	const isButtonLoading = useMemo(() => {
		return !(
			user?.calculationGroup?.geofenceLocation &&
			position?.lat &&
			position?.lng
		);
	}, [user, position]);

	const onClick = useCallback(
		async (action) => {
			let data;
			const workSummaryId = workSummary?.id;
			const shiftId = shift?.id;

			switch (action) {
				case "start-shift": {
					data = {
						type: "SHIFT",
						mode: "START",
						project: shift?.project,
						worksummaryId: workSummaryId,
						shiftId: shiftId,
						device: "qr-code",
						location: shift?.location?.id,
						job: shift?.job?.id
					};
					break;
				}
				case "start-break": {
					data = {
						type: "BREAK",
						mode: "START",
						worksummaryId: workSummaryId,
						shiftId: shiftId,
						project: shift?.project,
						location: shift?.location?.id,
						job: shift?.job?.id
					};
					break;
				}
				case "end-break": {
					data = {
						type: "BREAK",
						mode: "END",
						worksummaryId: workSummaryId,
						shiftId: shiftId,
						project: shift?.project,
						device: "qr-code",
						location: shift?.location?.id,
						job: shift?.job?.id
					};
					break;
				}
				case "end-shift": {
					data = {
						type: "SHIFT",
						mode: "END",
						worksummaryId: workSummaryId,
						shiftId: shiftId,
						project: shift?.project,
						device: "qr-code",
						location: shift?.location?.id,
						job: shift?.job?.id
					};
					break;
				}
				default:
					return;
			}

			if (
				user?.calculationGroup?.geofenceLocation &&
				isGeolocationEnabled
			) {
				if (!position?.lat || !position?.lng) {
					toast.error(t("required-geofence"));
					return;
				}
				const address = await getAddress({
					lat: position?.lat,
					lng: position?.lng,
				});
				data.geofence = {
					...{
						lat: position?.lat,
						lng: position?.lng,
					},
					address,
				};
			}

			if (ip) {
				data.ip = ip;
			}

			const clockType = getClockAction(data.type, data.mode);

			const clockMode = attestations?.length > 0 
				? "attestation"
				: flyers?.find(({ place }) => place === action)
				? `${action}-modal`
				: clockType;

			submit(
				data,
				() => dispatch({ type: "set-clock-mode", payload: { clockMode } }),
				() => {
					getErrorMessage(error, t);
				},
			);
		},
		[
			user?.calculationGroup,
			position,
			ip,
			shift,
			workSummary,
			isGeolocationEnabled,
			dispatch,
			error,
			submit,
			dispatch,
			flyers,
			attestations?.length,
			t,
		],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);

		return () => controller.abort();
	}, [getIp]);

	useEffect(() => {
		if (!isGeolocationEnabled) {
			toast.error(t("enable-location"));
		} else if (positionError) {
			toast.error(positionError);
		}
	}, [positionError, isGeolocationEnabled, t]);

	const content = useMemo(() => {
		switch (mode) {
			case "shift": {
				return (
					<>
						<Button
							onClick={canClockInWithoutConfirm
								? () => onClick("start-break")
								: () => openConfirmModal("start-break")}
							className="break-btn w-50"
							hidden={
								!user?.calculationGroup?.breaks?.manual?.status
							}
							disabled={isButtonLoading}
							loading={isButtonLoading}
						>
							{t("take-break")}
						</Button>

						<Button
							onClick={canClockInWithoutConfirm
								? () => onClick("end-shift")
								: () => openConfirmModal("end-shift")}
							color="danger"
							className={classNames(
								"border-0 w-50",
								!user?.calculationGroup?.breaks?.manual
									?.status && "w-100",
							)}
							loading={isButtonLoading}
							disabled={isButtonLoading}
						>
							{t("clock-out")}
						</Button>
					</>
				);
			}
			case "break": {
				return (
					<Button
						className="w-100 break-btn"
						onClick={canClockInWithoutConfirm
							? () => onClick("end-break")
							: () => openConfirmModal("end-break")}
						loading={isButtonLoading}
						disabled={isButtonLoading}
					>
						{t("end-break")}
					</Button>
				);
			}
			default:
				return (
					<>
						<Button
							onClick={canClockInWithoutConfirm
								? () => onClick("start-break")
								: () => openConfirmModal("start-break")}
							className="break-btn"
							loading={isButtonLoading}
							disabled={isButtonLoading}
						>
							{t("take-break")}
						</Button>

						<Button
							onClick={canClockInWithoutConfirm
								? () => onClick("end-shift")
								: () => openConfirmModal("end-shift")}
							color="danger"
							loading={isButtonLoading}
							disabled={isButtonLoading}
						>
							{t("clock-out")}
						</Button>
					</>
				);
		}
	}, [mode, onClick, t, isButtonLoading, canClockInWithoutConfirm]);

	return (
		<>
			<Row>
				<div className="col d-flex justify-content-between">{content}</div>
			</Row>

			{isConfirmModalOpen && (
				<ConfirmModal
					isOpen={isConfirmModalOpen}
					submit={onClick}
					selected={selected}
					close={closeConfirmModal}
					loading={isButtonLoading} />
			)}
		</>
	);
};

export default Actions;
