import moment from "moment-timezone";

export const getStartDateFromEndDate = (endDate, frequency) => {
    switch (frequency) {
        case "weekly": {
            return moment(endDate).subtract(1, "weeks").add(1, "day").toDate();
        }
        case "biweekly": {
            return moment(endDate).subtract(2, "weeks").add(1, "day").toDate();
        }
        case "semi-monthly": {
            if (moment.parseZone(endDate).date() <= 15) {
                return moment.parseZone(endDate).startOf("month").toDate();
            } else {
                return moment.parseZone(endDate).date(16).toDate();
            }
        }
        case "monthly": {
            return moment(endDate).startOf("month").toDate();
        }
        default:
            return;
    }
};

export const getEndDateFromStartDate = (startDate, frequency) => {
    if (startDate) {
        switch (frequency) {
            case "weekly": {
                return moment(startDate)
                    .add(1, "weeks")
                    .subtract(1, "day")
                    .toDate();
            }
            case "biweekly": {
                return moment(startDate)
                    .add(2, "weeks")
                    .subtract(1, "day")
                    .toDate();
            }
            case "semi-monthly": {
                if (moment(startDate).date() < 16) {
                    return moment(startDate).date(15).toDate();
                } else {
                    return moment(startDate).endOf("month").toDate();
                }
            }
            case "monthly": {
                return moment(startDate).endOf("month").toDate();
            }
            default:
                return;
        }
    }
};
