import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { CardBody } from "reactstrap";
import Header from "../../Layout/Header";
import FormContent from "../../../PayPolicies/CalculationGroup/Form/Content";
import { usePayCodesMap } from "../../../PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";
import { useOvertimePayCodes } from "../../../PayPolicies/CalculationGroup/Form/hooks/useOvertimePayCodes";
import { useRegularPayCode } from "../../../PayPolicies/CalculationGroup/Form/hooks/useRegularPayCode";
import { useUnallocatedPayCode } from "../../../PayPolicies/CalculationGroup/Form/hooks/useUnallocatedPayCode";
import { useMealUnpaid } from "../../../PayPolicies/CalculationGroup/Form/hooks/useMealUnpaid";
import { useSettingsOnboarding } from "../../context";
import useApi from "../../../../../utils/api";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useCompany, useCompanyTimeZone } from "../../../../../utils/hooks/company";
import { applyTimezone } from "../../../../../utils/helpers/date";
import { assignPayCodes, useDefaultValue } from "./helpers";
import { initialValues } from "./initialValues";

function CalculationGroup() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();
	const timezone = useCompanyTimeZone();
	const { authPost } = useApi();
	const { submit, skip, error } = useSettingsOnboarding();
	const [hasCreated, setHasCreated] = useState(false);

	const payCodesMap = usePayCodesMap();
	const overtimeMap = useOvertimePayCodes(payCodesMap);
	const regularPayCode = useRegularPayCode(payCodesMap);
	const unallocatedPayCode = useUnallocatedPayCode(payCodesMap);
	const mealUnpaidPayCode = useMealUnpaid(payCodesMap);

	const defaultValues = useDefaultValue({
		overtimeMap,
		regularPayCode,
		mealUnpaidPayCode,
		unallocatedPayCode,
	});

	const call = useCallback(
		(values) => {
			if (!company?.id) {
				return;
			}
			return authPost("/calculation-group", {
				data: { company: company.id, ...values },
			});
		},
		[company?.id, authPost],
	);

	const onFinish = useCallback(
		(values) => {
			const { 
				schedule: { 
					startShiftScheduleTime, 
					endShiftScheduleTime
				}
			} = values;

			const data = {
				...values,
				schedule: (startShiftScheduleTime || endShiftScheduleTime) && {
					startShiftScheduleTime: startShiftScheduleTime 
						&& timezone 
							? applyTimezone(startShiftScheduleTime, timezone, true).toISOString(true)
							: startShiftScheduleTime,
					endShiftScheduleTime: endShiftScheduleTime
						&&  timezone 
							? applyTimezone(endShiftScheduleTime, timezone, true).toISOString(true)
							: endShiftScheduleTime
				},
				rounding: values?.rounding ? values.rounding : 0,
			};

			submit(true, call(data), () => {
				setHasCreated(true);
			});
		},
		[timezone, submit, call, setHasCreated],
	);

	const saveAndRepeat = useCallback(() => {
		const values = form.getFieldsValue();
		const data = {
			...values,
			rounding: values?.rounding ? values.rounding : 0,
		};
		submit(false, call(data), () =>
			toast.success(t("calculation-group-created")),
		);
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			startDay: initialValues.startDay,
			isMain: false,
			breaks: initialValues.breaks,
			mealViolationRule: initialValues.mealViolationRule,
			unionRules: initialValues.unionRules,
			overtime: initialValues.overtime,
			guarante: initialValues.guarantee,
			rounding: initialValues.rounding,
			gpsLocation: false,
			geofenceLocation: false,
			signature: false,
			webcamOnMobile: false,
			preventTimeClockToTimesheet: false,
			regularDefaultPayCode: regularPayCode,
			unallocatedDefaultPayCode: unallocatedPayCode,
			color: initialValues.color,
		});
	}, [regularPayCode, unallocatedPayCode, form, submit, call, t]);

	const onSkip = useCallback(() => {
		if (hasCreated) {
			skip();
		} else {
			if (!defaultValues) {
				return;
			}
			call(defaultValues, () => {
				skip();
			});
		}
	}, [hasCreated, skip, call, defaultValues]);

	useEffect(() => {
		form.setFieldsValue({
			status: "active",
			startDay: initialValues.startDay,
			isMain: false,
			breaks: initialValues.breaks,
			mealViolationRule: initialValues.mealViolationRule,
			overtime: initialValues.overtime,
			rounding: initialValues.rounding,
			gpsLocation: false,
			geofenceLocation: false,
			signature: false,
			webcamOnMobile: false,
			regularDefaultPayCode: regularPayCode,
			unallocatedDefaultPayCode: unallocatedPayCode,
			color: initialValues.color,
		});
	}, [form, regularPayCode, unallocatedPayCode]);

	useEffect(() => {
		const data = initialValues;

		const assignedData = assignPayCodes(
			data,
			{ regularPayCode, unallocatedPayCode, mealUnpaidPayCode },
			overtimeMap,
		);

		form.setFieldsValue(assignedData);
	}, [
		form,
		regularPayCode,
		unallocatedPayCode,
		mealUnpaidPayCode,
		overtimeMap,
	]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Header
				canSkipRegardless={true}
				onSkip={onSkip}
				saveAndRepeat={saveAndRepeat}
			/>

			<CardBody>
				<FormContent form={form} rounding={initialValues.rounding} />
			</CardBody>
		</Form>
	);
}

export default CalculationGroup;
