import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Form from "rc-field-form";
import Field from "../../../../components/Field";
import Select from "../../../../components/Inputs/Select";

function SortInput({ form, columns }) {
    const { t } = useTranslation();

    const co = Form.useWatch("columns");

    const hidden = useMemo(() => {
        if (!co) {
            return [];
        }
        return co
            .filter((c) => {
                return c.hideCompletely || c.hideSort;
            })
            .map((c) => c.accessor || c.id);
    }, [co]);

    const options = useMemo(() => {
        return columns?.filter((column) => {
            const { id, accessor, disableSortBy } = column;
            if (hidden.includes(accessor) || hidden.includes(id)) {
                return false;
            }
            return disableSortBy !== true;
        });
    }, [columns, hidden]);

    const defaultSort = Form.useWatch("defaultSort");
    useEffect(() => {
        if (!defaultSort || !defaultSort.field || !defaultSort.type) {
            return;
        }
        if (hidden.includes(defaultSort.field)) {
            form.setFieldValue("defaultSort", {
                field: null,
                type: null,
            });
        }
    }, [form, defaultSort, hidden]);

    useEffect(() => {
        if (!defaultSort) {
            return;
        }
        const { field, type } = defaultSort;

        if (field && !type) {
            form.setFieldValue(["defaultSort", "type"], "desc");
        }
        if (!field && type) {
            form.setFieldValue(["defaultSort", "type"], null);
        }
    }, [form, defaultSort]);

    return (
        <>
            <Label className="font-weight-bold mb-3">
                {t("default-sort")}
            </Label>

            <div className="d-flex align-items-center gap-7 w-100">
                <Field
                    className="w-100"
                    name={["defaultSort", "field"]}
                >
                    <Select placeholder={t("field")} allowClear>
                        {options?.map(({ id, accessor, Header }) => (
                            <Select.Option
                                key={accessor || id}
                                value={accessor || id}
                            >
                                {Header}
                            </Select.Option>
                        ))}
                    </Select>
                </Field>

                {defaultSort?.field && (
                    <Field
                        className="w-100"
                        name={["defaultSort", "type"]}
                    >
                        <Select placeholder={t("order")}>
                            <Select.Option value="asc">
                                {t("asc")}
                            </Select.Option>
                            <Select.Option value="desc">
                                {t("desc")}
                            </Select.Option>
                        </Select>
                    </Field>
                )}
            </div>
        </>
    );
}

export default SortInput;
