import axios from "axios";
import moment from "moment-timezone";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useSWRMutation from "swr/mutation";
import useApi from "../../../../../../utils/api";

const useDateAction = ({
  action,
  userId,
  onSuccess,
  onFailure,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const { authPut } = useApi();

  return useSWRMutation(
    axios.getUri({
      url: `/time-sheet/user/${action}`,
    }),
    (url, { arg }) => {
      const date = moment(arg).format("YYYY-MM-DD");
      ref.current = toast.loading(
        t(`loading-timesheet-date-action-${action}`)
      );
      return authPut(url, {
        params: {
          startDate: date,
          endDate: date,
        },
        data: [userId],
      });
    },
    {
      onSuccess: async () => {
        toast.dismiss(ref.current);
        toast.success(
          t(`timesheet-date-action-${action}-success`)
        );
        onSuccess?.();
      },
      onError: async () => {
        toast.dismiss(ref.current);
        toast.error(
          t(`timesheet-date-action-${action}-failed`)
        );
        onFailure?.();
      },
      throwOnError: false,
    },
  );
}

export const useDateActions = ({ userId, onSuccess, onFailure }) => {
  const {
    trigger: lock,
    isMutating: isLocking,
  } = useDateAction({
    action: "lock",
    userId,
    onSuccess,
    onFailure,
  });
  const {
    trigger: unlock,
    isMutating: isUnlocking,
  } = useDateAction({
    action: "unlock",
    userId,
    onSuccess,
    onFailure,
  });
  const {
    trigger: approve,
    isMutating: isApproving,
  } = useDateAction({
    action: "approve",
    userId,
    onSuccess,
    onFailure,
  });
  const {
    trigger: unapprove,
    isMutating: isUnapproving,
  } = useDateAction({
    action: "unapprove",
    userId,
    onSuccess,
    onFailure,
  });
  const isMutating = isLocking || isUnlocking || isApproving || isUnapproving;

  return { lock, unlock, approve, unapprove, isMutating }
}