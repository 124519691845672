import moment from "moment-timezone";
import { isEndShift, isStartBreak } from "./clock";

export const getIntervals = (clocks) => {
	return clocks.reduce((total, clock, i) => {
		if (clocks[i + 1]) {
			return [...total, [clock, clocks[i + 1]]];
		} else {
			return total;
		}
	}, []);
};

export const areBreaksPaid = ({ manual, automatic }) => {
	if (manual.status && manual.payCode?.hourType !== "unpaid") {
		return true;
	} else if (automatic.status && automatic.payCode?.hourType !== "unpaid") {
		return true;
	}
	return false;
};

export const getBreakDuration = ({ manual }) => {
	if (manual.status && manual.mandatory) {
		return manual.duration;
	}
};

export const getPaidHours = (workSummary) => {
	const breaksPaid = areBreaksPaid(workSummary.calculationGroup.breaks);

	const total = workSummary.shifts.reduce((workSummaryTotal, shift) => {
		return (
			workSummaryTotal +
			shift.clocks.reduce((shiftTotal, clock, i) => {
				const nextClock = shift.clocks[i + 1];

				if (!nextClock || isEndShift(clock)) {
					return shiftTotal;
				}

				if (isStartBreak(clock) && !breaksPaid) {
					return shiftTotal;
				}

				return (
					shiftTotal +
					moment(nextClock.time).diff(clock.time, "seconds")
				);
			}, 0)
		);
	}, 0);

	const lastShift = workSummary.shifts[workSummary.shifts.length - 1];
	const lastClock = lastShift.clocks[lastShift.clocks.length - 1];
	if (!isEndShift(lastClock) && !(isStartBreak(lastClock) && !breaksPaid)) {
		return total + moment().diff(lastClock.time, "seconds");
	}

	return total;
};

export const getBreaks = (clocks) => {
	return clocks
		.filter((clock) => clock.type === "BREAK")
		.reduce((total, clock, i) => {
			if (i % 2 === 1) {
				return total;
			}
			return total.concat({
				start: clock.time,
				end: clocks[i + 1]?.time,
			});
		}, []);
};

export const calculateRate = ({ job, payCode, regRate, rateChangeManually }, reg = false) => {
	let rate;
	if (payCode.hourType !== "amount") {
		if (payCode.hourType === "unpaid") {
			rate = 0;
		} else if (rateChangeManually) {
			rate = parseFloat(`${regRate}`);
		} else {
			rate = 0;
			if (payCode && payCode.defaultRate) {
				rate = parseFloat(`${payCode.defaultRate}`); ;
			} else if (job && job.hourlyRate) {
				rate = parseFloat(`${job?.hourlyRate || 0}`); ;
			}
		}
	}
	rate = rate || 0;
	if (!reg) {
		rate = rate * (payCode && payCode.multiplier ? payCode.multiplier : 1);
	}
	return rate;
};

export const calculateRegularRate = ({ rate, payCode }) => {
	let regRate;
	if (payCode.hourType !== "amount" && payCode.hourType !== "unpaid") {
		if (payCode && payCode.defaultRate) {
			regRate = parseFloat(`${payCode.defaultRate}`);
		} else {
			regRate = rate / (payCode && payCode.multiplier ? payCode.multiplier : 1);
		}
	}
	regRate = regRate || 0;
	return regRate;
};
