import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpShortWide, faUserClock } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Button from "../../../../components/Button";
import BulkActions from "./BulkActions";
import UnavailableTimeModal from "./UnavailableTimeModal";
import { useActionsApi } from "../../api/useActionsApi";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useAccess } from "../../../../utils/hooks/access";

function Header({ 
	actionsRef,
	sort, 
	onSortClick, 
	filters, 
	selectedEvents,
	openCopyModal,
	openDeleteModal,
	openActionModal,
	events,
	isTeamScheduler,
	mutate,
	params,
	count,
	resetCount
}) {
	const { t } = useTranslation();
	const { visible, open, close } = useVisible();
	const { bulkSubmit } = useActionsApi({ isTeamScheduler });

	const { hasAccess: canApproveEvent } = useAccess("schedule.canApproveMySchedule");
	const { hasAccess: canCreate } = useAccess("schedule.canCreateMySchedule");

	const onSubmit = useCallback(() => {
		const data = {
			from: params?.startDate,
			to: params?.endDate,
			filters: params
		};
		if (!isTeamScheduler) {
			bulkSubmit("submit", data, (res) => {
				const submitted = res?.filter((item) => item?.status)?.length || 0;
				mutate();
				resetCount((prev) => prev - submitted, { revalidate: false });
			});
		} else {
			bulkSubmit("publish", data, (res) => {
				const published = res?.filter((item) => item?.status)?.length || 0;
				mutate();
				resetCount((prev) => prev - published, { revalidate: false });
			});
		}
	}, [
		isTeamScheduler, 
		bulkSubmit,  
		mutate, 
		params,
		resetCount
	]);

	return (
		<>
			<Card innerRef={actionsRef} className="mb-0 position-static">
				<CardHeader className="border-top p-3">
					<div
						className="d-flex justify-content-between"
						style={{ marginRight: -24 }}
					>
						<div className="d-flex align-items-center gap-1">
							<BulkActions 
								selectedEvents={selectedEvents} 
								openCopyModal={openCopyModal}
								openDeleteModal={openDeleteModal}
								openActionModal={openActionModal}
								events={events}
								isTeamScheduler={isTeamScheduler}
							/>

							{((canApproveEvent && isTeamScheduler)
								|| (canCreate && !isTeamScheduler)) && (
								<Button
									className="shadow-none border mx-0"
									color="primary"
									size="sm"
									onClick={onSubmit}
									disabled={count === 0}
								>
									{t(isTeamScheduler ? "publish" : "submit")}{` (${count})`}
								</Button>
							)}

							{onSortClick && (
								<FontAwesomeIcon
									icon={faArrowUpShortWide}
									className={classNames("cursor-pointer", sort && "text-primary")}
									onClick={onSortClick}
								/>
							)}

							{!isTeamScheduler && (
								<FontAwesomeIcon 
									icon={faUserClock}
									className="cursor-pointer mx-1"
									onClick={open}
								/>
							)}
						</div>

						{filters}	
					</div>
				</CardHeader>
			</Card>

			<UnavailableTimeModal isOpen={visible} close={close} />
		</>
	)
}

export default Header;
