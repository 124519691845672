import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../../../utils/api";
import { formatTimeSheetParams } from "../../../api/helpers";

export const useAddBulkCall = ({ filters, onSuccess, onFailure }) => {
    const { t } = useTranslation();
    const { authPut } = useApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const submit = useCallback(async (timeSheets) => {
        setLoading(true);
        setError(null);
        try {
            await Promise.all(
                Object
                    .keys(timeSheets)
                    .map((userId) => {
                        return authPut("/time-sheet/batch", {
                            data: {
                                0: timeSheets[userId].map((timeSheet) => ({
                                    ...timeSheet,
                                    id: undefined,
                                }))
                            },
                            params: formatTimeSheetParams({ userId, ...filters }),
                        });
                    })
            );
            onSuccess?.();
            toast.success(t("timesheets-added-successfully"));
        } catch (err) {
            setError(err);
            onFailure?.();
        } finally {
            setLoading(false);
        }
    }, [t, authPut, setLoading, setError]);

    return { submit, loading, error };
}
