import classNames from "classnames";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomTimeInput from "../../../../../components/Inputs/CustomTimeInput";
import { applyTimezone, combineDateAndTime } from "../../../../../utils/helpers/date";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { getTimeSheetTimeZone, isAddedTimeSheet, isTimeInputDisabled } from "../helpers";
import { useAddedPayCode } from "../hooks/useAddedPayCode";

export const getClockInputStyle = ({
    color,
    border,
    backgroundColor,
    value,
    original,
}) => {
    let style;
    if (value) {
        if (original.payCode?.hourType === "unpaid") {
            style = {
                color: "#00080e",
                backgroundColor: "#f2f1f3a6",
                border: `1px solid transparent`,
                padding: '0.2rem!important',
                fontSize: '0.9rem',
            };
        } else {
            style = {
                color,
                backgroundColor,
                border,
                padding: '0.2rem!important',
                fontSize: '0.9rem',
            };
        }
    } else {
        style = {
            color,
            backgroundColor: "transparent",
            border: `1px solid ${color}`,
            padding: '0.2rem!important',
            fontSize: '0.9rem',
        };
    }
    return style;
}

export const useInColumn = ({ user, canEdit }) => {
    const { t } = useTranslation();
    const timeFormat = useCompanyTimeFormat();
    const payCode = useAddedPayCode(user);
    const style = useMemo(() => {
        if (timeFormat) {
            switch (timeFormat.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    return { width: '60px' };
                case 6:
                case 7:
                    return { width: '70px' };
                case 8:
                case 9:
                case 10:
                    return { width: '80px' };
                default:
                    return { width: '116px' }
            }
        }
        return { width: '116px' }
    }, [timeFormat])

    return useMemo(() => ({
        Header: t("in"),
        accessor: "startDate",
        alwaysVisible: true,
        headCustomProps: { style },
        Cell: ({ value, row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original } = row;
            const disabled = isTimeInputDisabled(original) || row.disabled || cell.disabled;
            const timeZone = getTimeSheetTimeZone(original);
            const currentValue = value
                ? timeZone
                    ? moment.parseZone(value).toISOString(true)
                    : value
                : undefined;
            const style = getClockInputStyle({
                color: "#00998b",
                backgroundColor: "#00998b1a",
                border: `1px solid transparent`,
                value,
                original,
            });

            if (["unit", "amount"].includes(original?.payCode?.hourType)) {
                return "-";
            }

            return (
                <CustomTimeInput
                    timeFormat={timeFormat}
                    className={classNames("time-input text-center", value && "filled",
                        original?.payCode?.hourType === "unpaid" && "font-weight-400")}
                    style={style}
                    disabled={disabled || !canEdit}
                    placeholder={t("time")}
                    value={currentValue}
                    onChange={(val) => {
                        let startDate = "";
                        if (val) {
                            startDate = combineDateAndTime(
                                moment(original.date, "YYYY-MM-DD"),
                                moment(val),
                            );
                            startDate = applyTimezone(startDate, timeZone, true);
                            if (original.endDate) {
                                if (startDate.date() !== moment.parseZone(original.endDate).date()) {
                                    let endDate = combineDateAndTime(
                                        moment(original.date, "YYYY-MM-DD"),
                                        moment.parseZone(original.endDate),
                                    );
                                    endDate = applyTimezone(endDate, timeZone, true);
                                    if (endDate.isAfter(startDate, "minute")) {
                                        updatedFields.endDate = endDate.toISOString(true);
                                    }
                                }
                                if (moment(original.endDate).isBefore(startDate)) {
                                    updatedFields.endDate = moment
                                        .parseZone(original.endDate)
                                        .add(1, "day")
                                        .toISOString(true);
                                }
                            }
                            startDate = startDate.toISOString(true);
                        }

                        const duration = original?.endDate && startDate
                            ? moment(original.endDate).diff(moment(startDate), "seconds") 
                            : original?.duration || 0;

                        const updatedFields = { startDate, duration };
        
                        if (isAddedTimeSheet(original)) {
                            if (!original?.payCode) {
                                updatedFields.payCode = payCode;
                            }
                            updateAddedTimeSheet(original, updatedFields);
                        } else {
                            updateTimeSheet(original.id, updatedFields);
                        }
                    }}
                />
            );
        },
    }), [t, timeFormat, canEdit, payCode]);
};
