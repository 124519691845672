import moment from "moment-timezone";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import { isUserActive, renderUserName } from "../../../../../utils/helpers/user";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { faTriangleExclamation, faUserAlt, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Column({ user, viewProfile }) {
    const { t } = useTranslation();
    const ref = useRef();
    const { visible, toggle } = useVisible();
    const isTerminated = user.status === "terminated";
    const isUserBeforeHiring = !isUserActive(user);
    return (
        <>
            <span ref={ref} className={`${isTerminated ? 'terminated' : (isUserBeforeHiring ? 'hiring' : '')}`} >
                {viewProfile && (
                    <FontAwesomeIcon
                        icon={faUserAlt}
                        className="text-muted mr-2 cursor-pointer"
                        onClick={() => viewProfile(user)}
                    />
                )}
                {user.name || renderUserName(user, false)}
                {isTerminated && (
                    <>
                        &nbsp; <FontAwesomeIcon icon={faTriangleExclamation} />
                    </>
                )}
                {!isTerminated && isUserBeforeHiring && (
                    <>
                        &nbsp; <FontAwesomeIcon icon={faCheckSquare} />
                    </>
                )}
            </span>

            {!isTerminated && isUserBeforeHiring && (
                <Tooltip isOpen={visible} toggle={toggle} target={ref} placement="auto">
                    <span className="text-sm font-weight-bolder px-2">
                        {t("user-hiring-at")}{" "}
                        {moment.parseZone(user.hiringDate).format("DD MMM YYYY")}
                    </span>
                </Tooltip>
            )}

            {isTerminated && (
                <Tooltip isOpen={visible} toggle={toggle} target={ref} placement="auto">
                    <span className="text-sm font-weight-bolder px-2">
                        {t("user-terminated-at")}{" "}
                        {moment.parseZone(user.terminatedAt).format("DD MMM YYYY")}
                    </span>
                </Tooltip>
            )}
        </>
    );
}

export const useNameColumn = ({ viewProfile }) => {
    const { t } = useTranslation();

    return useMemo(() => ({
        Header: t("name"),
        id: "name",
        Footer: t("total"),
        Cell: ({ row: { original } }) => <Column user={original} viewProfile={viewProfile} />,
        canSort: true
    }), [t, viewProfile]);
};
