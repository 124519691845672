import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";
import Form from "../../CustomFields/Clockin/Form";

function CreateCustomFieldModal({ isOpen, close, submit, setData, loading }){
	const { t } = useTranslation();

	const onFinish = useCallback((values) => {
		submit(values, (response)=> {
			setData((prev)=> prev.concat(response));
			close();
		})
	}, [submit, setData, close]);;

	return(
		<Modal
			isOpen={isOpen}
			title={(
			    <div className="d-flex align-items-center justify-content-between">
					{t("add-clock-in-custom-field")}

					<FontAwesomeIcon 
						icon={faXmark}
						className="cursor-pointer"
						onClick={close}
					/>
				</div>
			)}
			centered
		>
			<Form 
			    mode="create"
				close={close}
				loading={loading}
				submit={onFinish}
			/>
		</Modal>
	);
}

export default CreateCustomFieldModal;
