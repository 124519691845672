import {useCallback} from "react";
import {useTableContext} from "../../../components/Table/context"

export const useCallbackWithReset = (callback) => {
    const {reset} = useTableContext();
    return useCallback((...args) => {
        callback(...args);
        reset();
    }, [callback, reset]);
}
