import { Card, Row, Col } from "reactstrap";
import { Outlet, useParams } from "react-router-dom";
import Topbar from "./Topbar";
import Overview from "./Overview";
import Actions from "./Actions";
import { ProfileProvider } from "./context";
import "./style.scss";

function Profile({ isSelf = false }) {
	const { id } = useParams();

	return (
		<ProfileProvider id={isSelf ? null : id} isSelf={isSelf}>
			<Card className="bg-secondary h-100 profile-card">
				<Topbar />

				<Row style={{ height: "calc(100% - 130px)" }} className="profile-content">
					<Col
						sm={12}
						md={8}
						className="h-100 m-0"
					>
						<Card className="h-100 m-0">
							<Outlet />
						</Card>
					</Col>

					<Col
						sm={12}
						md={4}
						style={{
							height: "calc(100%)",
						}}
					>
						<Overview />

						<div
							style={{
								height: "60%"
							}}
						>
							<Actions />
						</div>
					</Col>
				</Row>
			</Card>
		</ProfileProvider>
	);
}

export default Profile;
