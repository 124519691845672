import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import { Option } from "rc-select";
import Select from "../../../../../components/Inputs/Select";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import { renderUserName } from "../../../../../utils/helpers/user";
import ManagersSelect from "../../../../../components/Inputs/ManagersSelect";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { ProfileContext } from "../../../context";

export const getCompaniesSearchFilters = (search) => ({
	"filter[name][method]": "contains",
	"filter[name][value]": search,
	"filter[code][method]": "contains",
	"filter[code][value]": search,
	fields: ["id", "name", "code"],
	filterType: "or",
	withoutLookups: "true",
});

const formatFormValues = (values) => ({
	companies: values?.companies.map(({ id }) => id),
	payGroup: values?.payGroup?.id,
	calculationGroup: values?.calculationGroup?.id,
	locations: values?.locations?.map(
		({ id, code, description, timezone }) => ({
			primary: values?.primaryLocation === id,
			locationId: id,
			code,
			description,
			displayName: renderSettingsResourceLabel({
				code,
				description,
			}),
			timezoneId: timezone?.id,
			timezoneValue: timezone?.value,
			status: "active",
			shared: false,
			startDate: new Date(),
		}),
	),
	supervisors: values.supervisors?.map(
		({ id, firstName, middleName, lastName, employeeNumber, email }) => ({
			primary: values.primarySupervisor === id,
			firstName,
			lastName,
			email,
			employeeNumber,
			userId: id,
			fullName: renderUserName({
				firstName,
				middleName,
				lastName,
			}),
		}),
	),
});

function ChangeCompanyInformation({ close }) {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit, isSelf } =
		useContext(ProfileContext);

	const isApi = user?.isApi;

	const { changeCompanyInformation: profileSubmit } = useProfileApi();
	const { changeCompanyInformation: peopleSubmit } = usePeopleApi();

	const onLocationsChange = useCallback(
		(value) => {
			if (Array.isArray(value)) {
				const primaryField = form.getFieldValue("primaryLocation");

				if (value.length === 1) {
					form.setFieldsValue({
						primaryLocation: value[0].id,
					});
				} else {
					if (primaryField) {
						const hasPrimaryField =
							value.findIndex(
								(item) => item.id === primaryField,
							) !== -1;
						if (!hasPrimaryField) {
							form.setFieldsValue({
								primaryLocation: null,
							});
						}
					}
				}
			}
		},
		[form],
	);

	const onFinish = useCallback(
		(values) => {
			let data = formatFormValues(values);
			if (isApi) {
				data = { calculationGroup: values?.calculationGroup?.id };
			}
			submit(profileSubmit, peopleSubmit, data, close);
		},
		[isApi, submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			companies: user?.companies,
			payGroup: user?.payGroup,
			locations: user?.locations?.map(
				({
					locationId,
					code,
					description,
					timezoneId,
					timezoneValue,
				}) => ({
					id: locationId,
					code,
					description,
					timezone: {
						id: timezoneId,
						value: timezoneValue,
					},
				}),
			),
			primaryLocation: user?.locations?.find(({ primary }) => primary)
				?.locationId,
			supervisors: user?.supervisors?.map(({ userId, ...user }) => ({
				...user,
				id: userId,
			})),
			primarySupervisor: user?.supervisors?.find(({ primary }) => primary)
				?.userId,
			calculationGroup: user?.calculationGroup,
		});
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["companies", "locations", "payGroup"],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="companies"
				label={t("companies")}
				rules={[
					{
						required: true,
						message: t("required-companies"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="name"
					resourcePath="/companies"
					placeholder={t("companies")}
					hasSearch
					getSearchFilters={getCompaniesSearchFilters}
					mode="multiple"
					disabled={isApi}
				/>
			</Field>

			<ManagersSelect
				form={form}
				isSelf={isSelf}
				disabled={isApi}
				sm={12}
				md={12}
				className="px-0"
			/>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue }) => {
					const locations = getFieldValue("locations");
					const showPrimary = locations && locations.length >= 2;

					return (
						<>
							<Field
								name="locations"
								label={t("locations")}
								rules={[
									{
										required: true,
										message: t(
											"required-locations"
										),
									},
								]}
							>
								<ResourceSelect
									labelPropName="description"
									resourcePath="/locations"
									renderLabel={renderSettingsResourceLabel}
									mode="multiple"
									placeholder={t("locations")}
									onChange={onLocationsChange}
									hasSearch
									getSearchFilters={
										getSettingsResourceSearchFilters
									}
									disabled={isApi}
								/>
							</Field>

							<Field
								name="primaryLocation"
								label={t("primary-location")}
								hidden={!showPrimary}
								required
								dependencies={["locations"]}
							>
								<Select
									placeholder={t("primary-location")}
									showSearch
									disabled={isApi}
								>
									{locations?.map((location) => (
										<Option
											key={location.id}
											value={location.id}
										>
											{renderSettingsResourceLabel(
												location,
											)}
										</Option>
									))}
								</Select>
							</Field>
						</>
					);
				}}
			</BaseField>

			<Field
				name="payGroup"
				label={t("pay-group")}
				rules={[
					{
						required: true,
						message: t("required-pay-group"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/pay-group"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("pay-group")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={isApi}
				/>
			</Field>

			<Field
				name="calculationGroup"
				label={t("calculation-group")}
				rules={[
					{
						required: true,
						message: t("required-calculation-group"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="description"
					resourcePath="/calculation-group"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("calculation-group")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
				/>
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeCompanyInformation;
