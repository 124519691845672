import moment from "moment-timezone";
import { applyTimezone } from "../../../../../utils/helpers/date";

export const formatSubmittedData = (values) => ({
    ...values,
    periodStartDate: moment(values?.periodStartDate).format(
        "YYYY-MM-DDTHH:mm:ss",
    ),
    periodEndDate: applyTimezone(values?.periodEndDate).format(
        "YYYY-MM-DDTHH:mm:ss",
    ),
    country: values?.country?.id,
    timeSheetLockDate: Number(values?.timeSheetLockDate),
});
