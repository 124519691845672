import { useState, useCallback, useEffect } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Text from "../../../../../../components/Inputs/Text";
import DescriptionField from "../../../../../../components/Field/DescriptionField";
import StatusField from "../../../../../../components/Field/StatusField";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../components/Button";
import FixedOption from "./FixedOption";
import HourlyOption from "./HourlyOption";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import Select from "../../../../../../components/Inputs/Select";
import { Option } from "rc-select";

function Details({ mode, data, submit, goBack, error, disabled }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [method, setMethod] = useState(data?.accural?.method || "hourly");

	const onFinish = useCallback(
		(values) => {
			submit({
				...values,
				accural: {
					...values.accural,
					method,
				},
			});
		},
		[submit, method],
	);

	const getValueFromEvent = useCallback(
		(e) => e.target.value.toUpperCase(),
		[],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			code: data?.code ? data.code : "",
			description: data.description,
			status: data.status ? data.status : "active",
			timeOffStatus: data.timeOffStatus,
			accural: data.accural || {
				method: "hourly",
				earned: "",
				worked: "",
			},
		});
	}, [form, data]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "timeOffStatus"],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			className="d-flex flex-column justify-content-between h-100"
		>
			<div>
				<Field
					name="code"
					label={t("name")}
					getValueFromEvent={getValueFromEvent}
					rules={[
						{
							required: true,
							message: t("required-name"),
						},
						{
							message: t("name-length"),
							min: 1,
							max: 10,
						},
						{
							pattern: /^[a-zA-Z0-9]+$/,
							message: t("name-pattern"),
						},
					]}
				>
					<Text placeholder={t("name")} disabled={disabled} />
				</Field>

				<DescriptionField />

				<StatusField />

				<Field
					name="timeOffStatus"
					label={t("time-off-status")}
					rules={[
						{
							required: true,
							message: t("required-time-off-status"),
						},
					]}
				>
					<Select
						placeholder={t("time-off-status")}
						disabled={disabled}
					>
						<Option value="pto" key="pto">
							{t("pto")}
						</Option>

						<Option value="paidSickLeave" key="paidSickLeave">
							{t("paid-sick-leave")}
						</Option>

						<Option value="unpaid" key="unpaid">
							{t("unpaid")}
						</Option>

						<Option value="custom" key="custom">
							{t("custom")}
						</Option>
					</Select>
				</Field>

				<h5>{t("accrual-method")}</h5>

				<Checkbox
					id="fixed"
					onChange={() => setMethod("fixed")}
					checked={method === "fixed"}
					disabled={disabled}
				>
					{t("fixed-option")}
				</Checkbox>

				<Checkbox
					id="hourly"
					onChange={() => setMethod("hourly")}
					checked={method === "hourly"}
					disabled={disabled}
				>
					{t("hourly-option")}
				</Checkbox>

				{method === "fixed" ? (
					<FixedOption disabled={disabled} />
				) : (
					<HourlyOption form={form} disabled={disabled} />
				)}
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border"
					size="sm"
					onClick={goBack}
				>
					{t("cancel")}
				</Button>

				<Button
					type="submit"
					className="btn-round btn-icon shadow-none border px-3"
					color="dark"
					size="sm"
				>
					{t("continue")}
				</Button>
			</div>
		</Form>
	);
}

export default Details;
