import { CardHeader, Col, Row } from "reactstrap";
import Filters from "../../../../components/Table/Actions/Filters";
import { useTableContext } from "../../../../components/Table/context";

function ReportTableHeader() {
  const {
    state: { globalFilter },
    columns,
    setGlobalFilter,
  } = useTableContext();

  return (
    <CardHeader className="border-top p-3">
      <Row className="align-items-center">
        <Col md={12} lg={12}>
          <div className="d-flex align-items-start">
            <Filters
              columns={columns}
              filters={globalFilter}
              setFilters={setGlobalFilter}
              hideFilterButton={true}
            />
          </div>
        </Col>
      </Row>
    </CardHeader>
  );
}

export default ReportTableHeader;
