import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useVisible } from "../../../../utils/hooks/useVisible";

const Download = ({ downloadSummary, downloadDetails }) => {
    const { t } = useTranslation();
    const { visible, toggle } = useVisible();

    return (
        <Dropdown isOpen={visible} toggle={toggle}>
            <DropdownToggle
                className="inline-flex items-center shadow-none mr-2 p-0 bg-transparent border-0"
                size='sm'
                disabled={!downloadSummary && !downloadDetails}
            >
                <FontAwesomeIcon
                    className="text-dark"
                    style={{ fontSize: "16px" }}
                    icon={faDownload}
                />
            </DropdownToggle>

            <DropdownMenu right>
                <DropdownItem
                    onClick={downloadSummary}
                    disabled={!downloadSummary}
                >
                    {t("download-summary")}
                </DropdownItem>

                <DropdownItem
                    onClick={downloadDetails}
                    disabled={!downloadDetails}
                >
                    {t("download-details")}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default Download;
