import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {Spinner} from "reactstrap";
import {useVisible} from "../../../../../../../utils/hooks/useVisible";

function SubSection({ title, loading, children }) {
    const {visible, toggle} = useVisible();

    return (
        <div className="mb-4 rounded border border-light">
            <div
                className={classNames(
                    "d-flex justify-content-between align-items-center cursor-pointer p-3 bg-lighter",
                    visible ? "rounded-top" : "rounded",
                )}
                onClick={toggle}
            >
                <h3 className="mb-0 d-flex align-items-center">
                    {title}

                    {loading && (
                        <Spinner className="ml-2" size="sm" />
                    )}
                </h3>

                <FontAwesomeIcon icon={visible ? faChevronUp : faChevronDown} />
            </div>

            {visible &&  (
                <div className="p-3 rounded-bottom">
                    {children}
                </div>
            )}
        </div>
    );
}

export default SubSection;
