import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { OrgLevelGroupsContext } from "../OrganisationLevelGroups/context";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();
	const { groups } = useContext(OrgLevelGroupsContext);

	const { code, description, status, actions } = useCommonColumns({
		edit,
		remove,
		clone,
	});

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
			columnHelper.accessor("timezone.text", {
				header: t("timezone"),
			}),
			columnHelper.accessor("address1", {
				header: t("address"),
			}),
			columnHelper.accessor("country.name", {
				header: t("country"),
			}),
			columnHelper.accessor("state", {
				header: t("state"),
			}),
			columnHelper.accessor("city", {
				header: t("city"),
			}),
			columnHelper.accessor("postalCode", {
				header: t("postal-code"),
			}),
		];

		if (groups) {
			groups.forEach((group) => {
                const level = `${group.level}`.replace("_", "");
                const column = columnHelper.accessor(level, {
                    enableColumnFilter: true,
					header: group.description,
					cell: (info) => {
                        const value = info.getValue();
                        return (
                            <div className="d-flex flex-column">
                                {value && value?.map((level) => (
                                    <span>
                                        {renderOrgLevelResourceLabel(level)}
                                    </span>
                                ))}
                            </div>
                        );
					},
					filterType: "resource-select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath={`${group.id}/org-levels`}
							mode="multiple"
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							valuePropName="id"
							hasSearch
						/>
					),
				});
				columns.push(column);
            });
		}

		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [
		t,
		isMasterCompany,
		code,
		description,
		status,
		actions,
		groups,
	]);
};
