import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ModalFooter, Modal, ModalHeader, ModalBody } from "reactstrap";
import Button from "../../../components/Button";
import Loading from "../../../components/Loaders/Loading";
import { getUploadUrl } from '../../../utils/helpers/upload';
import { useClockInCustomFields } from "../../Settings/ClockIn/Attestation/Form/api";
import { useAttestation } from "../ClockIn/ShiftSummary/api";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";

function DetailsModal({ isOpen, values, close }) {
	const { t } = useTranslation();
	const { fetch, data: clockInCustomFields, loading } = useClockInCustomFields();
	const { 
		data: attestations, 
		loading: loadingAttestation, 
		fetch: getAttestations 
	} = useAttestation();

	const customFields = useMemo(() => {
		return values?.clocks?.filter(
			(clock) => clock?.type === "SHIFT" && clock?.mode === "END" && clock?.customFields
		)?.map((item) => item?.customFields);
	}, [values]);

	const attestationCodes = useMemo(() => {
		return customFields?.length > 0 
			? customFields?.map((item) => Object.keys(item))?.flat()
			: [];
	}, [customFields]);

	useEffect(() => {
		const controller = new AbortController();
		fetch({ pagination: false }, controller);
		return () => controller.abort();
	}, [fetch]);

	useEffect(() => {
		const controller = new AbortController();
		if (values?.user) {
			getAttestations(values.user, controller);
		}
		return () => controller.abort();
	}, [getAttestations, values?.user]);

	return (
		<Modal 
			isOpen={isOpen} 
			centered 
			size={attestationCodes?.length ? "lg" : "md"}
		>
			<ModalHeader>{t("original-clock-details")}</ModalHeader>

			<ModalBody style={{ minHeight: "200px" }}>
				{(loading || loadingAttestation) ? (
					<div className="w-100 d-flex align-items-center justify-content-center h-100">
						<Loading />
					</div>
				) : (
					<table
						border="1"
						className="w-100"
						style={{ borderColor: "#dee2e6" }}
					>
						<tr>
							<td 
								className={classNames(
									"p-2 font-weight-bolder", 
									attestationCodes?.length ? "w-25" : "w-50"
								)}
							>
								{t("clock-in-ip")}
							</td>
							<td className="p-2 w-75">
								{values?.clockInIp ? values?.clockInIp : "--"}
							</td>
						</tr>
						<tr>
							<td 
								className={classNames(
									"p-2 font-weight-bolder", 
									attestationCodes?.length ? "w-25" : "w-50"
								)}
							>
								{t("clock-in-image")}
							</td>
							<td className="p-2 w-75">
								{values?.clockInImage ? (
									<img
										alt="User Clockin"
										src={getUploadUrl(values?.clockInImage)}
										className="mr-auto"
										width={200}
									/>
								) : "--"}
							</td>
						</tr>
						<tr>
							<td 
								className={classNames(
									"p-2 font-weight-bolder", 
									attestationCodes?.length ? "w-25" : "w-50"
								)}
							>
								{t("clock-out-ip")}
							</td>
							<td className="p-2 w-75">
								{values?.clockOutIp ? values?.clockOutIp : "--"}
							</td>
						</tr>
						<tr>
							<td 
								className={classNames(
									"p-2 font-weight-bolder", 
									attestationCodes?.length ? "w-25" : "w-50"
								)}
							>
								{t("clock-out-image")}
							</td>
							<td className="p-2 w-75">
								{values?.clockOutImage ? <img
									alt="User Clockout"
									src={getUploadUrl(values?.clockOutImage)}
									className="mr-auto"
									width={200}
								/> : "--"}
							</td>
						</tr>

						{attestationCodes?.length > 0 && 
							attestationCodes?.map((code) => { 
								const attestation = attestations?.find((item) => item?.code === code);
								return (
									<tr>
										<td className="p-2 w-25 font-weight-bolder">
											{attestation ? renderSettingsResourceLabel(attestation) : code}
										</td>
										
										<td>
											{customFields?.map((field) => {
												const data = field?.[code] || {};
												const keys = Object?.keys(data) || [];
	
												return (
													<div 
														className="w-100" 
														style={{
															display: "grid",
															gridTemplateColumns: "1fr 1fr",
														}}
													>
														{keys?.map((item) => {
															const customField = clockInCustomFields?.find((value) => value.code === item);
															const option = (options, value) => options?.find((opt) => opt?.value === value)?.label || "";
															
															return (
																<>
																	<span 
																		className={classNames(keys.indexOf(item) < keys?.length - 1 &&
																			"border-bottom",
																			"m-0 p-2 w-100 font-weight-bolder border-right"
																		)}
																	>
																		{customField ? renderSettingsResourceLabel(customField) : item}
																	</span>

																	<span 
																		className={classNames(keys.indexOf(item) < keys?.length - 1 &&
																			"border-bottom",
																			"m-0 p-2 w-100"
																		)}
																	>
																		{customField?.type === "select" 
																			? option(customField?.options, data?.[item]) 
																			: data[item] ? data[item] : "--"
																		}
																	</span>
																</>	
															)}
														)}
													</div>
												)}
											)}
										</td>
									</tr>
								)}
						)}
					</table>
				)}
			</ModalBody>

			<ModalFooter className="p-0 m-3">
				<Button
					type="button"
					className="bg-gray text-white border-0"
					onClick={close}
				>
					{t("close")}
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default DetailsModal;
