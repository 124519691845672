import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Col, Row } from "reactstrap";
import Field from "../../../../../../../components/Field";
import CustomTimeInput from "../../../../../../../components/Inputs/CustomTimeInput";
import Progress from "./Progress";
import DurationInput, { getDurationInputValue } from "../../../../../../../components/Inputs/DurationInput";
import { useCalculatedSegments } from "./useCalculatedSegments";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../utils/helpers/date";

function TimeInputs({
	defaultSegment,
	form,
	setHasChanges,
	disabled,
	automaticBreaks,
	setIsAddedSegment,
}) {
	const { t } = useTranslation();
	const date = Form.useWatch("date", form);
    let endTime = Form.useWatch("endDate", form);
    let startTime = Form.useWatch("startDate", form);
	const shifts = Form.useWatch("shifts", form);

	const eventType = Form.useWatch("eventType", form);

	const canAddSegements = useMemo(() => {
		return eventType?.allowSegmentation ? true : false;
	}, [eventType]);

    const {
        getCalculatedSegmentsFromEnd,
        getCalculatedSegmentsFromStart
    } = useCalculatedSegments({ baseSegments: shifts, defaultSegment, date });

    const handleStartDateChange = useCallback((value) => {
        setHasChanges(true);
		setIsAddedSegment(false);
		if (!automaticBreaks && canAddSegements) {
			const startDate = combineDateAndTime(moment(date), moment(value));
			if (endTime && value) {
				endTime = combineDateAndTime(moment(date), moment(endTime));
				const diff = moment(endTime).diff(moment(startDate), "seconds");
				if (diff >= 900 || !moment(startDate).isAfter(moment(endTime), "seconds")) {
					let segments = [{
						...defaultSegment,
						start: startDate,
						end: endTime,
					}];
					if (shifts?.length > 0) {
						segments = getCalculatedSegmentsFromStart(startDate);
						if (!moment(segments[segments?.length - 1]?.end).isSame(moment(endTime), "seconds")) {
							segments = getCalculatedSegmentsFromEnd(endTime, segments);
						}
					}
					form.setFieldValue("shifts", segments);
				}
			}
		} else {
			if (endTime && value) {
				const startDate = combineDateAndTime(moment(date), moment(value));
				const endDate = combineDateAndTime(moment(date), moment(endTime));
				const duration = moment(endDate).diff(moment(startDate), "seconds");
				if (duration > 0) {
					form.setFieldValue("duration", renderDurationAsFormat(duration || 0, "HH:mm"));
				}
			}
		}
    }, [
		automaticBreaks,
        shifts?.length,
        defaultSegment,
        date,
        endTime,
        // getCalculatedSegmentsFromStart,
        getCalculatedSegmentsFromEnd,
        setHasChanges,
		setIsAddedSegment,
		canAddSegements,
		form
    ]);
	
    const handleEndDateChange = useCallback((value) => {
		setHasChanges(true);
		setIsAddedSegment(false);
        if (!automaticBreaks && canAddSegements) {
			const endDate = combineDateAndTime(moment(date), moment(value));
			if (startTime && value) {
				startTime = combineDateAndTime(moment(date), moment(startTime));
				const diff = moment(endDate).diff(moment(startTime), "seconds");
				if (diff >= 900 || !moment(endDate).isBefore(moment(startTime), "seconds")) {
					let segments = [{
						...defaultSegment,
						start: startTime,
						end: endDate,
					}];
					if (shifts?.length > 0) {
						segments = getCalculatedSegmentsFromEnd(endDate);
						if (!moment(segments[0]?.start).isSame(moment(startTime), "seconds")) {
							segments = getCalculatedSegmentsFromStart(startTime, segments);
						}
					}
					form.setFieldValue("shifts", segments);
				}
			}
		} else {
			if (startTime && value) {
				const startDate = combineDateAndTime(moment(date), moment(startTime));
				const endDate = combineDateAndTime(moment(date), moment(value));
				const duration = moment(endDate).diff(moment(startDate), "seconds");
				if (duration > 0) {
					form.setFieldValue("duration", renderDurationAsFormat(duration || 0, "HH:mm"));
				}
			}
		}
    }, [
		form,
		automaticBreaks,
        date,
        shifts?.length,
        defaultSegment,
        startTime,
        getCalculatedSegmentsFromEnd,
        getCalculatedSegmentsFromStart,
        setHasChanges,
		setIsAddedSegment,
		canAddSegements
    ]);

	const onDurationChange = useCallback((value) => {
		if (value && startTime) {
			const duration = getDurationInputValue(value);
			const startDate = combineDateAndTime(moment(date), moment(startTime));
			if (duration > 0) {
				const endDate = moment(startDate).add(duration, "seconds");
				form.setFieldValue("endDate", endDate);
			}
		}
	}, [startTime, date, form]);

	return (
		<>
			<Row>
				<Col md={5} xs={12}>
					<Field
						className="d-flex flex-column"
						name="startDate"
						label={t("shift-start")}
						dependencies={["endDate"]}
						rules={[
							{
								required: true,
								message: t("required-shift-start")
							},
							({ getFieldValue }) => ({
								validator: (_, value) => {
									const date = getFieldValue("date");
									let endDate = getFieldValue("endDate");
									endDate = combineDateAndTime(moment(date), moment(endDate));
									const startDate = combineDateAndTime(moment(date), moment(value));
									if (endDate && startDate && !canAddSegements) {
										const diff = moment(endDate).diff(moment(startDate), "seconds");
										if (diff < 900 || moment(startDate).isAfter(moment(endDate), "seconds")) {
											return Promise.reject(new Error(t("shift-start-difference-from-shift-end")))
										}
									}
									return Promise.resolve();
								}
							})
						]}
						hidden={canAddSegements}
					>
						<CustomTimeInput 
							hidden={canAddSegements} 
							onChange={handleStartDateChange} 
							disabled={disabled} 
						/>
					</Field>
				</Col>

				<Col md={5} xs={12}>
					<Field
						hidden={canAddSegements}
						className="d-flex flex-column"
						name="endDate"
						label={t("shift-end")}
						dependencies={["startDate"]}
						rules={[
							{
								required: true,
								message: t("required-shift-end")
							},
							({ getFieldValue }) => ({
								validator: (_, value) => {
									const date = getFieldValue("date");
									let startDate = getFieldValue("startDate");
									startDate = combineDateAndTime(moment(date), moment(startDate));
									const endDate = combineDateAndTime(moment(date), moment(value));

									if (startDate && endDate && !canAddSegements) {
										const diff = moment(endDate).diff(moment(startDate), "seconds");
										if (diff < 900 || moment(endDate).isBefore(moment(startDate), "seconds")) {
											return Promise.reject(new Error(t("shift-start-difference-from-shift-end")))
										}
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<CustomTimeInput 
							hidden={canAddSegements} 
							onChange={handleEndDateChange} 
							disabled={disabled} 
						/>
					</Field>
				</Col>

				<Col md={2} xs={12}>
					<Field
						hidden={canAddSegements}
						className="d-flex flex-column"
						name="duration"
						label={t("duration")}
						dependencies={["startDate"]}
						rules={[
							{
								required: true,
								message: t("required-duration")
							},
						]}
					>
						<DurationInput 
							hidden={canAddSegements} 
							disabled={disabled} 
							onChange={onDurationChange}
						/>
					</Field>
				</Col>
			</Row>

			{canAddSegements && (
				<Progress form={form} />
			)}
		</>
	)
}

export default TimeInputs;
