import { useMemo } from "react";

export const normalize = (
	data,
	field
) =>
	data.reduce(
		(total, resource) => ({ ...total, [resource[field]]: resource }),
		{}
	);

export const normalizeById = (
	data
) => normalize(data, "id");

export const useNormalizedResource = (resources) =>
	useMemo(() => normalizeById(resources), [resources]);
