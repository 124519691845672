import {
    useReactTable,
    flexRender,
    getCoreRowModel,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Table as BaseTable } from "reactstrap";
import Loading from "../Loaders/Loading";
import { useCallback } from "react";

function SimpleTable({
    /* hook props*/
    loading,
    columns,
    data,
    /* table props */
    footer = true,
    getRowProps,
    ...props
}) {
    const { t } = useTranslation();
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getRowId: useCallback((row, _, parent) => {
            const id = row.id;
            if (parent) {
                return [parent.id, id].join(".");
            }
            return id;
        }, []),
    });

    return (
        <BaseTable
            className="align-items-center table-flush"
            hover
            responsive
            {...props}
        >
            <thead className="thead-light">
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                style={{ color: "#000000b5" }}
                            >
                                {header.isPlaceholder ? null : (
                                    <div>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody>
                {loading ? (
                    <tr>
                        <td colSpan="999">
                            <div className="d-flex justify-content-center">
                                <Loading />
                            </div>
                        </td>
                    </tr>
                ) : data.length === 0 ? (
                    <tr>
                        <td colSpan="999">
                            <div className="d-flex justify-content-center">
                                {t("no-data")}
                            </div>
                        </td>
                    </tr>
                ) : (
                    table.getRowModel().rows.map((row) => {
                        const rowProps = getRowProps?.(row) || {};
                        return (
                            <tr key={row.id} {...rowProps}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })
                )}
            </tbody>

            {footer ? (
                <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                                <td key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.footer,
                                              header.getContext(),
                                          )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            ) : null}
        </BaseTable>
    );
}

export default SimpleTable;
