import moment from "moment";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../../components/Drawer";
import Loading from "../../../../../components/Loaders/Loading";
import { useCompanyCurrency, useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { currencyFormatter } from "../../../../../utils/helpers/currencyFormatter";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { useUserOverview } from "./api";
import Week, { getWeekTotal } from "./Week";
import "./_style.scss";

function Summary({ open, close, user, range, filters }) {
    const { from, to, payGroup } = range;
    const { t } = useTranslation();
    const timeFormat = useCompanyTimeFormat();
    const currency = useCompanyCurrency();

    const { data, loading, fetch } = useUserOverview(user.id);

    const isSameDay = useMemo(() => {
        return moment(from).isSame(to, "day");
    }, [from, to]);

    const weeksTotals = useMemo(() => {
        return Object.values(data).reduce(
            (totals, week) => {
                const weekTotals = getWeekTotal(week);
                return {
                    duration: totals.duration + weekTotals.duration,
                    total: totals.total + weekTotals.total,
                };
            },
            { duration: 0, total: 0 }
        );
    }, [data]);

    useEffect(() => {
        const controller = new AbortController();
        fetch({
            params: { from, to, payGroup: [payGroup?.id], ...filters },
            signal: controller.signal,
        });
        return () => controller.abort();
    }, [fetch, from, to, payGroup?.id, filters]);

    return (
        <Drawer
            title={
                <div className="d-flex align-items-center">
                    <h3 className="mb-0 mr-3">
                        {isSameDay ? t("daily-summary") : t("weekly-summary")}
                    </h3>

                    {isSameDay && (
                        <span className="text-gray text-xs">
                            {moment(from).format(`ddd, MMM DD YYYY`)}
                        </span>
                    )}
                </div>
            }
            close={close}
            open={open}
            className="summary-drawer">
            {loading ? (
                <div className="py-4 d-flex align-items-center justify-content-center">
                    <Loading />
                </div>
            ) : (
                <>
                    <div
                        className='timeline timeline-one-side'
                        data-timeline-axis-style='dashed'
                        data-timeline-content='axis'
                    >
                        {Object.values(data).map((week) => (
                            <Week
                                key={week.startDate}
                                isSameDay={isSameDay}
                                week={week}
                            />
                        ))}
                    </div>

                    {!isSameDay && (
                        <div className="timeline timeline-one-side">
                            <div className="timeline-block">

                                <div className='timeline-content mw-100 top-0 p-0 bg-info d-flex'>
                                    <div class="table-responsive">
                                        <table role="table" class="align-items-center table-flush table table-hover">
                                            <tbody>
                                                <tr role="row" className="bg-info">
                                                    <td role="cell">
                                                        <h3 className='text-white mb-0'>
                                                            {t("totals")}
                                                        </h3>
                                                    </td>
                                                    <td role="cell">
                                                        <h4 className='text-white mb-0'>
                                                            {renderDurationAsFormat(weeksTotals.duration, timeFormat)}
                                                        </h4>
                                                    </td>
                                                    <td role="cell">
                                                        <h4 className='text-white mb-0'>
                                                            {currencyFormatter(weeksTotals.total, 2, currency)}
                                                        </h4>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </Drawer>
    );
}

export default Summary;
