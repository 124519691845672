import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Table as BaseTable, Card } from "reactstrap";
import Button from "../../../../../components/Button";
import Loading from "../../../../../components/Loaders/Loading";

function Table({ table, loading, submit, isSubmitting }) {
  const { t } = useTranslation();
  const { hasChanges, hasErrors, reset } = table.options.meta;
  const { canEdit } = table.options.meta.permissions;

  return (
    <Card className="timesheet-table mb-0 h-100 position-static">
      <BaseTable
        className="align-items-center table-flush"
        hover
        responsive
      >
        <thead className="thead-light">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const canSort = header.column.getCanSort();
                const className = classNames(
                  header.column.getCanSort() && 'cursor-pointer select-none',
                );
                const sortDirection = header.column.getIsSorted();
                const sortIcon = sortDirection
                  ? sortDirection === "desc" ? faSortDown : faSortUp
                  : faSort;
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={className}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{ color: "#000000b5" }}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                        {canSort && (
                          <FontAwesomeIcon className="ml-2" icon={sortIcon} />
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td colSpan="999">
                <div className="d-flex justify-content-center">
                  <Loading />
                </div>
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} {...table.options.meta.getRowProps?.(row)}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>

        <tfoot className="font-weight-bolder">
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <td key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.footer,
                      header.getContext(),
                    )}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </BaseTable>

      {canEdit && (
        <div className="timesheet-table-footer border-top">
          <div className="bulk-actions">
            <div className="p-4 d-flex justify-content-end align-items-center">
              <Button
                color="danger"
                disabled={!hasChanges || isSubmitting}
                onClick={reset}
              >
                {t("cancel")}
              </Button>

              <Button
                type="submit"
                color="primary"
                loading={isSubmitting}
                disabled={!hasChanges || isSubmitting || hasErrors}
                onClick={() => submit({})}
              >
                {t("save-changes")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}

export default Table;