import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useWorkSummaryHistory = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	const getWorkSummary = useCallback(
		async (date, user, controller) => {
			setLoading(true);

			try {
				const response = await authGet("/shift/work-summary", {
					signal: controller?.signal,
					params: {
						date,
						user,
					},
				});

				if (response) {
					setData(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[authGet, setLoading, setData, t],
	);

	return { loading, data, getWorkSummary };
};

export const useHistory = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const getHistory = useCallback(
		async (date, user, controller) => {
			setLoading(true);
			try {
				const response = await authGet("/time-sheet/history", {
					signal: controller?.signal,
					params: {
						date,
						user,
					},
				});

				if (response) {
					setData(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setData, authGet, t],
	);

	return { loading, data, getHistory };
};

export const useSchedule = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const getEvents = useCallback(async(endpoint, date, controller) => {
		setLoading(true);
		try {
			const response = await authGet(endpoint, {
				params: {
					startDate: date,
					endDate: date
				},
				signal: controller?.signal
			});
			if (response) {
				setData(response);
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [setLoading, setData, authGet, t]);

	return { data, loading, getEvents };
}
