import { useMemo } from "react";

export const useColumns = ({ columns, payCodeColumns }) => {
	const cols = useMemo(() => {
		const previewColumns = [
			...columns
				?.filter?.(
					({ onlyFilter, isForExtraColumns }) =>
						!(onlyFilter || isForExtraColumns),
				)
				?.map((column) => ({
					Header: column.label,
					accessor: column.key,
					disableSortBy: true,
				})),
		];

		if (payCodeColumns?.length > 0) {
			payCodeColumns.map((item) =>
				previewColumns.push({
					Header: item.label,
					accessor: item.accessor,
					disableSortBy: true,
				}),
			);
		}

		return previewColumns;
	}, [columns, payCodeColumns]);

	return { cols };
};
