import {useCallback} from "react";
import {useApproveTimeSheet} from "../../api/useApproveTimeSheet"
import {useLockTimeSheet} from "../../api/useLockTimeSheet";
import {useDateFilters} from "../../hooks/useDateFilters";
import { useActionWithAccess } from "../../../../utils/hooks/access";

export const useBulkActions = ({ refetch, filters }) => {
    const {from, to} = useDateFilters();
    const {approveUsers, unapproveUsers} = useApproveTimeSheet();
    const {lockUsers, unlockUsers} = useLockTimeSheet();

    const approve = useActionWithAccess(
        useCallback((ids) => {
            approveUsers(ids, { from, to, ...filters }, refetch);
        }, [approveUsers, from, to, filters, refetch]),
        "timeSheet.canApprove",
    );

    const unapprove = useActionWithAccess(
        useCallback((ids) => {
            unapproveUsers(ids, { from, to, ...filters }, refetch);
        }, [unapproveUsers, from, to, filters, refetch]),
        "timeSheet.canApprove",
    );

    const lock = useActionWithAccess(
        useCallback((ids) => {
            lockUsers(ids, { from, to, ...filters }, refetch);
        }, [lockUsers, from, to, filters, refetch]),
        "timeSheet.canLock",
    );

    const unlock = useActionWithAccess(
        useCallback((ids) => {
            unlockUsers(ids, { from, to, ...filters }, refetch);
        }, [unlockUsers, from, to, filters, refetch]),
        "timeSheet.canLock",
    );

    return {approve, unapprove, lock, unlock};
}
