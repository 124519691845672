import { useMemo } from "react";
import { useAccess } from "../../../../../utils/hooks/access";

export const usePermissions = () => {
  const { hasAccess: canEdit } = useAccess("timeSheet.canEditMyTimesheet");
  const { hasAccess: canViewHistory } = useAccess("timeSheet.canViewHistoryMyTimesheet");
  const { hasAccess: canLock } = useAccess("timeSheet.canLockMyTimesheet");
  const { hasAccess: canApprove } = useAccess("timeSheet.canApproveMyTimesheet");

  return useMemo(() => {
    return {
      canEdit,
      canViewHistory,
      canLock,
      canApprove,
    };
  }, [
    canEdit,
    canViewHistory,
    canLock,
    canApprove,
  ])
}
