import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import classNames from "classnames";
import { debounce } from "lodash";
import { usePeopleApi } from "../../features/People/api";

function SearchInput({ close }) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const { fetch, data } = usePeopleApi();

  const onSearchChange = useMemo(
    () =>
      debounce((e) => {
        setSearch(e.target.value);
      }, 500),
    [setSearch]
  );

  useEffect(() => {
    return () => onSearchChange.cancel();
  }, [onSearchChange]);

  useEffect(() => {
    const controller = new AbortController();

    if (search !== "") {
      const params = {
        page: 1,
        perPage: 10,
        search,
      };

      fetch(params, controller);
    }

    return () => controller.abort();
  }, [fetch, search]);

  const onOptionClick = useCallback(
    (id) => {
      navigate(`/people/${id}/job-pay`);
      setSearch("");
    },
    [navigate, setSearch]
  );

  const onMouseLeave = useCallback(
    () =>
      setTimeout(() => {
        setSearch("");
      }, 1000),
    [setSearch]
  );

  return (
    <div onMouseLeave={onMouseLeave}>
      <div
        className="mb-0 bg-white d-flex justify-content-between align-items-center responsive-search"
        style={{ borderRadius: "2rem" }}
      >
        <div className="d-flex align-items-center w-100">
          <i className="fas fa-search ml-3 text-sm text-muted" />
          <Input onChange={onSearchChange} className="text-muted w-100" />
        </div>
        <i
          className="fas fa-xmark mx-3 text-sm text-muted close-xmark"
          onClick={close}
        />
      </div>

      {search !== "" && data?.length > 0 && (
        <div
          className={classNames(
            "position-absolute bg-white w-25 ml-2 p-1 rounded d-flex flex-column search-select-scroll"
          )}
          style={{
            top: "77%",
            zIndex: 2,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {data?.map((user) => (
            <span
              className="px-2 rounded mb-1 py-1 bg-secondary cursor-pointer"
              onClick={() => onOptionClick(user?.id)}
            >
              {user && user?.firstName + " " + user?.lastName}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchInput;
