import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Row, Col } from "reactstrap";
import DateInput from "../../../../components/Inputs/DateInput";
import PhoneInput from "react-phone-input-2";
import moment from "moment-timezone";
import Field from "../../../../components/Field";
import Select from "../../../../components/Inputs/Select";
import Text from "../../../../components/Inputs/Text";
import Layout from "../Layout";
import { useUser } from "../../../../utils/hooks/user";
import { useCompany } from "../../../../utils/hooks/company";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import {
	applyTimezone,
	dateTimeParseZone,
} from "../../../../utils/helpers/date";
import "react-phone-input-2/lib/style.css";

function Step2({ setStep, data, setData, error, step }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const user = useUser();
	const company = useCompany();

	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);

	const goBack = useCallback(() => {
		setStep(0);
		setData({});
	}, [setStep, setData]);

	const onFinish = useCallback(
		(values) => {
			const stepData = {
				firstName: values?.firstName,
				middleName: values?.middleName,
				lastName: values?.lastName,
				email: values?.email,
				phone: values?.phone,
				birthDate: applyTimezone(values?.birthDate, timezone, true),
				hiringDate: applyTimezone(values?.hiringDate, timezone, true),
				companies: values?.companies,
				badgeId: values?.badgeId,
				employeeNumber: values?.employeeNumber,
			};

			setData({ ...data, ...stepData });
			setStep(2);
		},
		[setData, setStep, data, timezone],
	);

	useEffect(() => {
		form.setFieldsValue({
			firstName: data?.firstName,
			middleName: data?.middleName,
			lastName: data?.lastName,
			email: data?.email,
			phone: data?.phone,
			birthDate: data?.birthDate && dateTimeParseZone(moment(data?.birthDate), "date"),
			hiringDate: data?.hiringDate && dateTimeParseZone(moment(data?.hiringDate), "date"),
			companies: data?.companies,
			badgeId: data?.badgeId,
			employeeNumber: data?.employeeNumber,
		});
	}, [form, data]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"companies",
				"employeeNumber",
				"firstName",
				"middleName",
				"lastName",
				"email",
				"phone",
				"birthDate",
				"hiringDate",
				"badgeId",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Layout close={goBack} step={step}>
				<h3 className="border-bottom w-100 pb-4 px-4 mb-4 mx-0">
					{t("create-employee-profile")}
				</h3>

				<h4 className="text-muted font-weight-600 w-100 px-4">
					{t("basics")}
				</h4>

				<Row className="w-100 p-4">
					<Col sm={12} md={6}>
						<Field
							name="companies"
							label={t("companies")}
							rules={[
								{
									required: true,
									message: t("required-companies"),
								},
							]}
						>
							<Select
								placeholder={t("select-companies")}
								mode="multiple"
								optionFilterProp="children"
							>
								{user?.companies?.map((company) => (
									<Select.Option key={company.id} value={company.id}>
										{company.name}
									</Select.Option>
								))}
							</Select>
						</Field>
					</Col>

					<Col sm={12} md={6}>
						<Field 
							name="employeeNumber" 
							label={t("employee-number")}
							rules={[
								{
									pattern: /^[0-9]+$/,
									message: t("employee-number-pattern")
								},
								{
									message: t("employee-number-max-length", { 
										max: Number(company?.employeeNumberLength || 7) 
									}),
									min: 1,
									max: Number(company?.employeeNumberLength || 7),
								},
							]}
						>
							<Text placeholder={t("employee-number")} />
						</Field>
					</Col>

					<Col sm={12} md={6}>
						<Field
							name="badgeId"
							label={t("badge-id")}
							rules={[
								{
									required: true,
									message: t("required-badge-id"),
								},
								{
									message: t(
										"badge-id-length",
										{
											badgeIdLength:
												Number(company?.badgeIdLength || 4),
										},
									),
									min: Number(company?.badgeIdLength || 4),
									max: Number(company?.badgeIdLength || 4),
								},
							]}
						>
							<Text type="number" placeholder={t("badge-id")} />
						</Field>
					</Col>

					<Col sm={12} md={6}>
						<Field
							name="firstName"
							label={t("first-name")}
							rules={[
								{
									required: true,
									message: t("required-firstname"),
								},
							]}
						>
							<Text placeholder={t("first-name")} />
						</Field>
					</Col>

					<Col sm={12} md={6} hidden={data?.isInvited}>
						<Field
							name="middleName"
							label={t("middle-name")}
							hidden={data?.isInvited}
						>
							<Text placeholder={t("middle-name")} />
						</Field>
					</Col>

					<Col sm={12} md={6}>
						<Field
							name="lastName"
							label={t("last-name")}
							rules={[
								{
									required: true,
									message: t("required-lastname"),
								},
							]}
						>
							<Text placeholder={t("last-name")} />
						</Field>
					</Col>

					<Col sm={12} md={6}>
						<Field
							name="email"
							label={t("email")}
							rules={[
								{
									required: true,
									message: t("required-email"),
								},
								{
									type: "email",
									message: t("invalid-email"),
								},
							]}
						>
							<Text type="email" placeholder={t("email")} />
						</Field>
					</Col>

					<Col sm={12} md={6} hidden={data.isInvited}>
						<Field name="birthDate" label={t("date-of-birth")} className="d-flex flex-column">
							<DateInput placeholderText={t("date-of-birth")} />
						</Field>
					</Col>

					<Col sm={12} md={6} hidden={data.isInvited}>
						<Field
							name="phone"
							label={t("phone-number")}
							hidden={data?.isInvited}
						>
							<PhoneInput
								country="us"
								enableSearch
								inputClass="w-100"
								inputProps={{ hidden: data.isInvited }}
							/>
						</Field>
					</Col>

					<Col sm={12} md={6}>
						<Field
							name="hiringDate"
							label={t("start-date")}
							dependencies={["birthDate"]}
							rules={[
								{
									required: true,
									message: t("required-start-date"),
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										const birthDate =
											getFieldValue("birthDate");

										if (birthDate && value) {
											if (
												moment
													.parseZone(value)
													.isSameOrBefore(
														moment.parseZone(birthDate),
														"date",
													)
											) {
												return Promise.reject(
													new Error(
														t("start-date-bigger-than-birthdate"),
													),
												);
											}
										}

										return Promise.resolve(true);
									},
								}),
							]}
							className="d-flex flex-column"
						>
							<DateInput placeholderText={t("start-date")} />
						</Field>
					</Col>
				</Row>
			</Layout>
		</Form>
	);
}

export default Step2;
