import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ListGroupItem } from "reactstrap";

function Action({ icon, color, children, onClick, id }) {
    return (
        <ListGroupItem
            className='px-0 py-4 cursor-pointer d-flex align-items-center'
            onClick={onClick}
            id={id}
        >
            <FontAwesomeIcon className="mr-3" size="xl" icon={icon} color={color} />

            <span className="text-sm">
                {children}
            </span>
        </ListGroupItem>
    );
}

export default Action;
