import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Button from "../../../../components/Button";
import { useNotes } from "../../api/useNote";

function AddNote({ close, isOpen, selected, setData }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { addNote, loading } = useNotes();

	const onFinish = useCallback(
		(value) => {
			const data = {
				value: value.note,
				entityId: selected?.date,
				user: selected?.user?.id,
				entity: "timesheet",
			};

			addNote(data, (response) => {
				setData((prev) =>
					prev?.map((item) => {
						if (selected?.id && item?.id === selected?.id) {
							return {
								...item,
								[selected?.date]: {
									...item[selected.date],
									note: response.value,
								},
							};
						} else {
							return item;
						}
					}),
				);
				close();
			});
		},
		[selected, addNote, close, setData],
	);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	useEffect(() => {
		form.setFieldsValue({ note: selected?.note });
	}, [form, selected?.note]);

	return (
		<Modal
			title={
				<div className="text-primary d-flex justify-content-between align-items-center">
					<span>{t("add-note")}</span>
					<span>
						<i
							className="fa-solid fa-xmark cursor-pointer"
							onClick={onClose}
						/>
					</span>
				</div>
			}
			isOpen={isOpen}
			toggle={onClose}
			centered
		>
			<Form form={form} onFinish={onFinish}>
				<Field
					name="note"
					rules={[{ required: true, message: t("required-note") }]}
				>
					<Text type="textarea" rows={5} />
				</Field>

				<div className="d-flex justify-content-between align-items-center">
					<Button
						color="primary"
						loading={loading}
						disabled={loading}
					>
						{t("save")}
					</Button>

					<Button color="dark" disabled={loading} onClick={onClose}>
						{t("close")}
					</Button>
				</div>
			</Form>
		</Modal>
	);
}

export default AddNote;
