import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as isUUID } from "is-uuid";

const FiltersContext = createContext();

const URL_FILTER_PATTERN = /filter\[(\w*)\]\[(\w*)\]/;

const isUrlFilter = (name) => {
    return URL_FILTER_PATTERN.test(name);
}

const getSearchParamsFilters = (searchParams) => {
    const filters = {};
    for (const item of  searchParams.entries()) {
        let [name, value] = item;
        if (!isUrlFilter(name)) {
            continue;
        }
        const [_, field, method] = URL_FILTER_PATTERN.exec(name);
        const isMulti = name.endsWith("[]");

        if (!filters[field]) {
            filters[field] = {
                name: field,
                method,
                value: isMulti ? [] : undefined,
            };
        }

        if (!value) {
            continue;
        }
        if (isUUID(value)) {
            value = { id: value };
        }
        if (isMulti) {
            filters[field].value.push(value);
        } else {
            filters[field].value = value;
        }
    }
    return Object.values(filters);
}

export const FiltersProvider = ({
    initialValue,
    sessionKey,
    children,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialUrlValue] = useState(() => {
        const searchParamsFilters = getSearchParamsFilters(searchParams);
        if (searchParamsFilters.length > 0) {
            return searchParamsFilters;
        }
    });

    useEffect(() => {
        const keys = [];
        for (const key of searchParams.keys()) {
            if (isUrlFilter(key)) {
                keys.push(key);
            }
        }
        if (keys.length === 0) {
            return;
        }
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            for (const key of keys) {
                params.delete(key);
            }
            return params;
        });
    }, [searchParams, setSearchParams]);

    const setStorage = useCallback((value) => {
        if (!sessionKey) {
            return;
        }
        let filters;
        try {
            filters = JSON.parse(sessionStorage.getItem(sessionKey)) || {};
            filters[sessionKey] = value;
        } catch (err) {
            filters = {
                [sessionKey]: value,
            };
        }
        try {
            sessionStorage.setItem("filters", JSON.stringify(filters));
        } catch (err) {
            console.error("Could not set filters on session storage");
        }
    }, [sessionKey]);

    const [filters, setFilters] = useState(() => {
        let value;
        if (initialUrlValue?.length > 0) {
            setStorage(initialUrlValue);
        }
        try {
            value = JSON.parse(sessionStorage.getItem("filters")) || {};
            if (!(sessionKey in value)) {
                value[sessionKey] = initialValue;
            }
            value = value[sessionKey];
        } catch (err) {
            value = initialValue;
        }
        return value;
    });

    const onChange = useCallback((value) => {
        setStorage(value);
        setFilters(value);
    }, [setFilters, setStorage])

    const value = useMemo(() => {
        return [filters, onChange];
    }, [filters, onChange]);

    return (
        <FiltersContext.Provider value={value}>
            {children}
        </FiltersContext.Provider>
    )
}

export const useFilters = () => {
    const context = useContext(FiltersContext);
    if (context === undefined) {
        throw new Error("useFilters should be used within a provider");
    }
    return context;
}
