import { useState, useEffect, useRef } from "react";

export const useElementHeight = () => {
	const ref = useRef(null);

	const [height, setHeight] = useState(undefined);

	useEffect(() => {
		const resizeObserver = new ResizeObserver(([{ target }]) =>
			setHeight(target.clientHeight)
		);
		const element = ref.current;
		if (element) {
			resizeObserver.observe(element);
		}
		return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
		};
	}, [setHeight]);

	return {
		ref,
		height,
	};
};
