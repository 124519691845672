import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useColumns = () => {
    const { t } = useTranslation();

    return useMemo(() => [
        {
            Header: t("name"),
            accessor: "search",
            alwaysVisible: true,
        },
        {
            Header: t("summary"),
            accessor: "summary",
        }
    ], [t]);
}
