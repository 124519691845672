import moment from 'moment-timezone';

export default function getSqTranslate() {
    return moment.defineLocale('sq', {
        months: 'Janar_Shkurt_Mars_Prill_Maj_Qershor_Korrik_Gusht_Shtator_Tetor_Nëntor_Dhjetor'.split(
            '_'
        ),
        monthsParseExact: true,
        monthsShort: 'jan._shku._mar._pri._maj_qer._korr._gush._shta._tet._nën._dhj.'.split('_'),
        weekdays: 'E hënë_E martë_E mërkurë_E enjte_E premte_E shtunë_E dielë'.split('_'),
        weekdaysShort: 'hën._mar._mër._enj._pre._sht._dje.'.split('_'),
        // weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        longDateFormat: {
            LT: 'h:mm A',
            LTS: 'h:mm:ss A',
            L: 'DD/MM/YYYY',
            LL: 'D MMMM YYYY',
            LLL: 'D MMMM YYYY h:mm A',
            LLLL: 'dddd, D MMMM YYYY h:mm A',
        },
        calendar: {
            sameDay: '[Sot në] LT',
            nextDay: '[Nesër në] LT',
            nextWeek: 'dddd [ne] LT',
            lastDay: '[Dje në] LT',
            lastWeek: '[E fundit] dddd [në] LT',
            sameElse: 'L'
        },
        relativeTime: {
            future: 'në %s',
            past: 'ka %s',
            s: 'disa sekonda',
            m: 'një minut',
            mm: '%d minuta',
            h: 'një orë',
            hh: '%d orë',
            d: 'një ditë',
            dd: '%d ditë',
            M: 'një muaj',
            MM: '%d muaj',
            y: 'një vit',
            yy: '%d vite'
        },
        dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
        ordinal: function (number) {
            var b = number % 10,
                output =
                    ~~((number % 100) / 10) === 1
                        ? 'th'
                        : b === 1
                            ? 'st'
                            : b === 2
                                ? 'nd'
                                : b === 3
                                    ? 'rd'
                                    : 'th';
            return number + output;
        },
    });
} 