import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getToastErrorMessage } from "../../../utils/helpers/errors";

export const useDeleteTimeSheet = () => {
    const { t } = useTranslation();
    const { authDelete } = useApi();

    const call = useCallback(
        (id) => authDelete(`/time-sheet/${id}`),
        [authDelete],
    )

    const bulkCall = useCallback(
        (id, dates) => authDelete(`/time-sheet/date/${id}`, {
            data: dates
        }),
        [authDelete],
    )

    const remove = useCallback(
        async (id, onSuccess, onFailure) => {
            const loading = toast.loading(t("deleting-timesheet"));
            try {
                await call(id);
                await onSuccess?.(id);
                toast.dismiss(loading);
                toast.success(t("timesheet-deleted"));
            } catch (err) {
                await onFailure?.(err);
                toast.dismiss(loading);
                toast.error(
                    getToastErrorMessage(err, t("could-not-delete-timesheet"))
                );
            }
        },
        [call, t]
    );

    const removeMany = useCallback(
        async (ids, dates, userId, onSuccess, onFailure) => {
            const loading = toast.loading(t("deleting-timesheets", {
                count: ids.length
            }));
            try {
                await bulkCall(userId, dates);
                toast.dismiss(loading);
                toast.success(t("timesheets-deleted", {
                    count: ids.length
                }));
                onSuccess?.();
            } catch (err) {
                toast.dismiss(loading);
                toast.error(
                    getToastErrorMessage(err, t("could-not-delete-timesheets", {
                        count: ids.length
                    })
                    )
                );
                onFailure?.(err);
            }
        },
        [call, t]
    );

    return { remove, removeMany };
};
