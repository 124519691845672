import { useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { usePeopleApi } from "../../../../../utils/api/people";
import { ProfileContext } from "../../../context";

function ChangeRole({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);

	const { changeRole } = usePeopleApi();

	const onFinish = useCallback(
		(values) => {
			const data = {
				roles: values?.roles?.map(({ id }) => id),
			};
			submit(() => undefined, changeRole, data, close);
		},
		[changeRole, submit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			roles: user?.roles,
		});
	}, [form, user?.roles]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["roles"],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError, t]);

	return (
		<Form form={form} layout="vertical" onFinish={onFinish}>
			<Field
				label={t("roles")}
				name="roles"
				rules={[
					{
						required: true,
						message: t("required-roles"),
					},
				]}
			>
				<ResourceSelect
					placeholder={t("roles")}
					labelPropName="description"
					resourcePath="/user-roles"
					renderLabel={renderSettingsResourceLabel}
					mode="multiple"
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
				/>
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default ChangeRole;
