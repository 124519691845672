import PlacesAutocomplete from "react-places-autocomplete";
import { Wrapper } from "@googlemaps/react-wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input, InputGroup, InputGroupText, Nav, NavItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./style.scss";

function Search({ search, setSearch, handleSelect, disabled }) {
	const { t } = useTranslation();

	return (
		<Wrapper
			apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
		>
			<PlacesAutocomplete
				value={search}
				onChange={setSearch}
				onSelect={handleSelect}
			>
				{({
					getInputProps,
					suggestions,
					getSuggestionItemProps,
					loading,
				}) => (
					<div className="location-search">
						<InputGroup className="input-group">
							<Input
								{...getInputProps({
									className: "mb-2",
									placeholder: t("search-places"),
								})}
								disabled={disabled}
								className="border-0"
							/>

							<InputGroupText className="border-0">
								<FontAwesomeIcon icon={faSearch} />
							</InputGroupText>
						</InputGroup>

						<Nav className="card border-radius-lg shadow ml-3 w-100">
							{loading ? (
								<NavItem key="loading" className="text-center">
									<div
										className="spinner spinner-border spinner-border-sm mr-2"
										role="status"
									/>
								</NavItem>
							) : (
								suggestions.map((suggestion) => (
									<NavItem
										className="px-3 py-2 bg-gray-100"
										{...getSuggestionItemProps(suggestion)}
										key={suggestion.placeId}
									>
										{suggestion.description}
									</NavItem>
								))
							)}
						</Nav>
					</div>
				)}
			</PlacesAutocomplete>
		</Wrapper>
	);
}

export default Search;
