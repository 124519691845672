import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { List } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Field from "../../Field";
import Button from "../../Button";
import Tag from "./Tag";
import "./style.scss";

function TagInput({
	name,
	label,
	buttonLabel,
	form,
	rules,
	disabled,
	...props
}) {
	const inputRef = useRef();
	const [inputValue, setInputValue] = useState("");
	const [inputVisible, setInputVisible] = useState(false);
	const { t } = useTranslation();

	const getDataFromForm = useCallback(
		() => form.getFieldValue(name),
		[form, name],
	);
	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};
	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	return (
		<List name={name} rules={rules}>
			{(data, { add, remove }, { errors }) => {
				const handleInputConfirm = () => {
					const allItems = getDataFromForm();
					if (
						inputValue &&
						(allItems || []).indexOf(inputValue) === -1
					) {
						add(inputValue);
					}
					setInputVisible(false);
					setInputValue("");
				};

				return (
					<div className="mb-3">
						<Field label={label}>
							<>
								{inputVisible && (
									<Input
										ref={inputRef}
										readOnly={disabled}
										type="text"
										onChange={handleInputChange}
										onBlur={handleInputConfirm}
										onPressEnter={handleInputConfirm}
										value={inputValue}
										{...props}
									/>
								)}

								<div>
									{!inputVisible && (
										<Button
											color="primary"
											onClick={showInput}
											className="d-flex align-items-center"
										>
											<FontAwesomeIcon
												icon={faPlus}
												className="mr-1"
											/>
											{t("new-button", {
												buttonLabel
											})}
										</Button>
									)}
								</div>
							</>
						</Field>

						{/* <List.ErrorList errors={errors} /> */}

						<div className="tag-input">
							{data?.map(({ key, name }) => {
								const allItems = getDataFromForm();
								if (allItems) {
									return (
										<Tag
											key={key}
											remove={() => remove(name)}
											item={allItems[name]}
										/>
									);
								}
								return null;
							})}
						</div>
					</div>
				);
			}}
		</List>
	);
}

export default TagInput;
