import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useBulkClocking = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { authPost } = useApi();

	const submit = useCallback(
		async (data, onSuccess) => {
			setLoading(true);
			setError(null);
			try {
				const response = await authPost("/shift/bulk-clock", {
					data,
				});

				if (response) {
					onSuccess?.(response);
				}
			} catch (err) {
				setError(err);
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setError, authPost, t],
	);

	return { submit, loading, error };
};
