import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Card, CardHeader, CardBody, Tooltip } from "reactstrap";
import { useTaskListApi } from "./api";
import { useSessionFilters } from "../../../../components/NTable/hook/useSessionFilters";
import "./style.scss";

function TaskList() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [_, onSessionFiltersChange] = useSessionFilters("teamRequests"); 
	const {
		nonApprovedTimesheets,
		missedPunches,
		pendingRequests,
		overtime,
		mealViolation,
		fetch,
		loading,
	} = useTaskListApi();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const toggle = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [setIsTooltipOpen, isTooltipOpen]);

	const onClick = useCallback(
		(type) => {
			const from = moment().startOf("week").format("YYYY-MM-DD");
			const to = moment().endOf("week").format("YYYY-MM-DD");
			const requestFilters = {
				pagination: {
					pageIndex: 0,
					pageSize: 20
				}, 
				columnFilters: [
					{
						name: "status",
						method: "exact",
						value: "pending"
					}
				],
				sorting: []
			};

			switch (type) {
				case "non-approved-timesheets":
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[status][exact]=unauthorized`,
					);
				case "pending-requests":
					onSessionFiltersChange(requestFilters);
					return navigate(
						"/request/team",
					);
				case "missed-punches":
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[status][exact]=misspunch`,
					);
				case "overtime":
					return navigate(
						`/time-sheet/team?mode=week&from=${from}&to=${to}&filter[hourType][exact]=overtime`,
					);
				default:
					return navigate("/");
			}
		},
		[navigate, onSessionFiltersChange],
	);

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return (
		<Card style={{ height: "calc(100% - 30px)" }}>
			{loading ? (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<div
						className="spinner spinner-border spinner-border-sm"
						style={{ height: "30px", width: "30px" }}
						role="status"
					/>
				</div>
			) : (
				<>
					<CardHeader className="d-flex flex-column  align-items-start border-0">
						<h3 className="mb-3">{t("what-should-work-on")}</h3>
						<div className="text-sm text-primary font-weight-bolder border-bottom border-primary">
							{t("task-list")}
						</div>
					</CardHeader>

					<CardBody
						className="py-0 dashboard-task-list-scroll"
						style={{ overflow: "auto" }}
					>
						<div className="d-flex border-bottom align-items-center pb-2">
							<div>
								<i className="fa-solid fa-calendar-check text-primary text-lg mr-3 text-info" />
							</div>

							<div className="d-flex flex-column">
								<span className="mb-1">
									{t("timesheets-that-need-approval")}
								</span>

								<span
									className="text-sm text-info font-weight-bold cursor-pointer"
									onClick={() =>
										onClick("non-approved-timesheets")
									}
								>
									{t(
										"check-non-approved-timesheets",
										{
											nonApprovedTimesheets,
										},
									)}{" "}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>

						<div className="d-flex border-bottom align-items-center py-2">
							<div>
								<i className="fa-solid fa-pen text-primary text-lg mr-3 text-red" />
							</div>

							<div className="d-flex flex-column">
								<span className="mb-1">
									{t("missed-punches")}
								</span>

								<span
									className="text-sm text-red font-weight-bold cursor-pointer"
									onClick={() => onClick("missed-punches")}
								>
									{t(
										"check-missed-punches",
										{
											missedPunches,
										},
									)}{" "}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>

						<div className="d-flex border-bottom align-items-center py-2">
							<div>
								<i className="fa-solid fa-check text-warning text-lg mr-3" />
							</div>

							<div className="d-flex flex-column">
								<span className="mb-1">
									{t("requests-to-review")}
								</span>

								<span
									className="text-sm text-warning font-weight-bold cursor-pointer"
									onClick={() => onClick("pending-requests")}
								>
									{t(
										"check-pending-requests",
										{
											pendingRequests,
										},
									)}{" "}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>
						<div className="d-flex border-bottom align-items-center py-2">
							<div>
								<i
									className="fa-solid fa-clock text-lg mr-3"
									style={{ color: "#ef3dd8" }}
								/>
							</div>

							<div className="d-flex flex-column">
								<span className="mb-1">
									{t("timesheets-with-overtime")}
								</span>

								<span
									className="text-sm  font-weight-bold cursor-pointer"
									style={{ color: "#ef3dd8" }}
									onClick={() => onClick("overtime")}
								>
									{t("check-overtime-timesheets", {
										overtime,
									})}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>

						<div
							className="d-flex border-bottom align-items-center py-2"
							id="mealViolation"
						>
							<div>
								<i className="fa-solid fa-list text-gray text-lg mr-3" />
							</div>

							<div className="d-flex flex-column text-gray">
								<span className="mb-1">
									{t("meal-violations")}
								</span>

								<span className="text-sm text-gray font-weight-bold">
									{t("check-meal-violation", {
										mealViolation
									})}{" "}
									<i className="fa-solid fa-greater-than text-xs ml-2" />
								</span>
							</div>
						</div>

						<Tooltip
							isOpen={isTooltipOpen}
							toggle={toggle}
							target="mealViolation"
						>
							<span className="text-sm font-weight-bolder px-2">
								{t("coming-soon")}
							</span>
						</Tooltip>
					</CardBody>
				</>
			)}
		</Card>
	);
}

export default TaskList;
