import React, { useEffect, useMemo } from "react";
import Modal from "../../../../components/Modal";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export const useBreakErrorModal = () => {
  const { visible, selected, open, close } = useVisible();

  const modal = useMemo(() => {
    return (
      <Modal
        isOpen={visible}
        toggle={close}
        className={"modal-dialog-centered"}
      >
        <div className="text-center">
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="text-danger"
            size="3x"
          />
        </div>

        <p className="text-dark text-center font-weight-bold mt-4">{selected}</p>
        <div className="text-center">
          <button className="btn btn-secondary" onClick={close}>
            Close
          </button>
        </div>
      </Modal>
    );
  }, [selected, close, visible]);

  useEffect(() => {
    const controller = new AbortController();
    if (visible) {
      setTimeout(() => {
        if (controller.signal.aborted) {
          return;
        }
        close();
      }, 5000);
    }
    return () => {
      controller.abort();
    };
  }, [visible, close]);

  return useMemo(() => {
    return {
      open,
      modal,
    };
  }, [open, modal]);
};
