import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Option } from "rc-select";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Select from "../../../components/Inputs/Select";
import { useAuthentication } from "../context";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { adaptMomentToCompany } from "../../../utils/helpers/adaptMomentToCompany";

export const setCompanyStorageTokens = (id) => {
	const isLocalStorage = !!localStorage.getItem("TOKEN");
	if (isLocalStorage) {
		localStorage.setItem("COMPANY", id);
	} else {
		localStorage.setItem("COMPANY", id);
	}
};

function CompanySelectForm() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { user, dispatch } = useAuthentication();

	const onFinish = useCallback(
		(values) => {
			const company = user?.companies?.find(
				({ id }) => id === values.company,
			);

			setLoading(true);
			setError(null);

			try {
				if (company) {
					adaptMomentToCompany(company, user);
					dispatch({ type: "set-company", payload: { company } });
					setCompanyStorageTokens(company.id);
					navigate("/");
				}
			} catch (err) {
				setError(err);

				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[
			user,
			dispatch,
			setCompanyStorageTokens,
			navigate,
			setError,
			setLoading,
			getErrorMessage,
			t,
		],
	);

	useEffect(() => {
		const masterCompany = user?.companies.find(
			({ isMasterCompany }) => isMasterCompany,
		);
		const company = masterCompany || user?.companies[0];
		form.setFieldsValue({
			company: company?.id,
		});
	}, [user?.companies, form]);

	useEffect(() => {
		const formErrors = generateErrorsConfigForForm(["company"], error);
		form.setFields(formErrors);
	}, [form, error]);

	return (
		<Form form={form} onFinish={onFinish}>
			<Field
				name="company"
				rules={[
					{
						requried: true,
						message: t("required-company"),
					},
				]}
			>
				<Select style={{ width: "300px" }}>
					{user?.companies?.map(({ id, code, name }) => (
						<Option key={id} value={id}>
							{code} - {name}
						</Option>
					))}
				</Select>
			</Field>

			<Button
				color="primary"
				type="submit"
				className="w-100"
				loading={loading}
			>
				{t("continue")}
			</Button>
		</Form>
	);
}

export default CompanySelectForm;
