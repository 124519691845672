import { useState } from "react";
import ShiftView from "../../TimeClock/ClockIn/ShiftView";
import Requests from "../../TimeClock/ClockIn/Requests";
import { OverviewProvider } from "../../TimeSheet/components/Topbar/context";
import Timesheet from "./Timesheet";
import { DateFiltersProvider } from "../../TimeSheet/hooks/useDateFilters";
import "./style.scss";
import { FiltersProvider } from "../../TimeSheet/filtersContext";
import { PayCodesProvider } from "../../TimeSheet/reusedResourcesContext";
import ConfigurationLoader from "../../TimeSheet/configurationLoader";

function EmployeeDashboard() {
	const [refetchTimesheet, setRefetchTimesheet] = useState(0);

	return (
		<div
			className="employee-dashboard"
		>
			<div className="d-lg-flex h-50 ">
				<div className="shiftview-responsive col-12 col-lg-8 d-md-flex flex-md-column px-0 px-lg-2 h-100 ">
					<ShiftView setRefetchTimesheet={setRefetchTimesheet} />
				</div>

				<div className="requests-responsive col-12 col-lg-4 d-md-flex flex-md-column px-0 px-lg-2 ml-3 h-100">
					<Requests />
				</div>
			</div>

			<div
				className="h-50 my-4 scroll"
				style={{ overflowY: "auto", overflowX: "hidden" }}
			>
				<PayCodesProvider>
          <ConfigurationLoader>
            <FiltersProvider
              initialValue={[{
                name: "hourType",
                method: "exact",
                value: [],
              }]}
            >
              <DateFiltersProvider isDashboardComponent={true}>
                <OverviewProvider>
                  <Timesheet refetchTimesheet={refetchTimesheet} />
                </OverviewProvider>
              </DateFiltersProvider>
            </FiltersProvider>
          </ConfigurationLoader>
				</PayCodesProvider>
			</div>
		</div>
	);
}

export default EmployeeDashboard;
