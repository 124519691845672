import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";

export const useImport = () => {
	const { t } = useTranslation();
	const { authPost } = useApi();
	const [loading, setLoading] = useState(false);

	const importFile = useCallback(
		async (url, data, onSuccess) => {
			setLoading(true);
			try {
				const response = await authPost(url, {
					data,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				if (response) {
					onSuccess?.(response);
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[authPost, setLoading, t],
	);

	return { importFile, loading };
};
