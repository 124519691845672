import { useMemo } from "react";
import ColumnToggle from "./ColumnToggle";
import { useTableContext } from "../../../../../../components/Table/context";
import BulkActions from "../../../../Home/Actions/BulkActions";
import Download from "../../../../Home/Actions/Download";
import { useExport } from "../../../hooks/useExport";

const Actions = ({ user, filters, ...props }) => {
    const {
        columns,
        state: { selectedRowIds },
    } = useTableContext();

    const ids = useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);
    const columnKeys = useMemo(
        () => columns.map(({ id }) => id)?.filter((c) => !!c),
        [columns],
    );
    const { downloadSummary, downloadDetails } = useExport({
        ids,
        columns: columnKeys,
        filters,
        other: { userId: user?.id },
    });

    return (
        <div className="d-flex justify-content-between align-items-center">
            <BulkActions userId={user?.id} {...props} />

            <div>
                <Download
                    downloadSummary={downloadSummary}
                    downloadDetails={downloadDetails}
                />

                <ColumnToggle
                    prefix={`timesheet-user-${user?.id}`}
                    columns={columns}
                />
            </div>
        </div>
    );
}

export default Actions;
