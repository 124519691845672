import { useCallback, useEffect } from "react";
import { useNonAuthApi } from "../../../utils/api";
import { isCancelError } from "../../../utils/helpers/errors";
import { useQrCodeClockIn } from "../context";
import { getSubDomainFromDomain } from "../../../utils/helpers/url";

export const useArea = (id) => {
	const { area, domain, dispatch } = useQrCodeClockIn();
	const { nonAuthGet } = useNonAuthApi();

	const getArea = useCallback(
		async (controller) => {
			const subDomain = getSubDomainFromDomain(domain);

			dispatch({ type: "get-area" });
			try {
				const { coordinates } = await nonAuthGet(`/qrCode/${id}/area`, {
					params: { uniqueKey: subDomain },
					signal: controller.signal,
				});
				dispatch({
					type: "get-area-success",
					payload: { area: coordinates },
				});
			} catch (err) {
				if (isCancelError(err)) {
					dispatch({ type: "cancel-get-area" });
					return;
				}
				dispatch({
					type: "get-area-failure",
					payload: { error: err },
				});
			}
		},
		[dispatch, domain],
	);

    const hasArea = !!area;
	useEffect(() => {
		const controller = new AbortController();
        if (!hasArea) {
            getArea(controller);
        }
		return () => controller.abort();
	}, [getArea, hasArea]);
};
