import { useState, useCallback } from "react";
import noop from "lodash/noop";
import { useCompany } from "../../../../utils/hooks/company";
import { useUpdateCompany } from "../../../../utils/api/company";

export const useLatestCompletedStep = () => {
	const company = useCompany();
	return company?.lastCompletedSettingsOnboardingStep;
};

export const useCanSkip = (stepNumber) => {
	const lastStep = useLatestCompletedStep();
	return (
		lastStep !== undefined && lastStep !== null && lastStep >= stepNumber
	);
};

export const useIncrementLatestCompletedStep = () => {
	const company = useCompany();
	const lastStep = useLatestCompletedStep();
	const { loading, error, submit: updateCompanySubmit } = useUpdateCompany();

	const submit = useCallback(
		(step, onSuccess = noop, onFailure = noop) => {
			if (
				(step === 0 && lastStep === undefined) ||
				(company?.id &&
					lastStep !== undefined &&
					lastStep !== null &&
					lastStep < step)
			) {
				const data = {
					lastCompletedSettingsOnboardingStep: step,
				};

				updateCompanySubmit(company.id, data, onSuccess, onFailure);
			} else {
				onSuccess();
			}
		},
		[company?.id, lastStep, updateCompanySubmit],
	);

	return { loading, error, submit };
};

export const useOnboardingStep = () => {
	const latestCompletedStep = useLatestCompletedStep();

	const [step, setStep] = useState(() => {
		if (latestCompletedStep !== undefined && latestCompletedStep !== null) {
			return latestCompletedStep + 1;
		}
		return 0;
	});

	const incrementStep = useCallback(() => {
		setStep((prev) => (prev || 0) + 1);
	}, [setStep]);

	const decrementStep = useCallback(
		() => setStep((prev) => (prev || 0) - 1),
		[setStep],
	);

	return {
		step,
		latestCompletedStep,
		incrementStep,
		decrementStep,
	};
};
