import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetDependencyRowId } from "../internal/useGetDependencyRowId";
import { useValidationConfig } from "../internal/useValidationConfig";
import { useApiFormattedData } from "../internal/useApiFormattedData";
import { useDates } from "../internal/useDates";
import { useMergedData } from "../internal/useMergedData";
import { useTableState } from "../internal/useTableState";

import { useValidations } from "../../../../../components/TimeSheetTable/hooks/useTable/validations";

const getRowProps = (errors, row) => {
  const props = {};
  const hasError = errors[row.id]
    ? Object.values(errors[row.id]).some((err) => !!err)
    : false;
  if (hasError) {
    props.className = "has-error";
  }
  return props;
}

const getRowId = (row, _, parent) => {
  const id = row.id;
  if (parent) {
    return [parent.id, id].join(".");
  }
  return id;
}

export const useTable = ({
  user,
  columns,
  data,

  permissions,
  lock, unlock, approve, unapprove, isLoadingDateAction,
}) => {
  const { t } = useTranslation();
  const dates = useDates();

  const apiFormattedData = useApiFormattedData({ data });

  const {
    added, updated, removed,
    addRow, updateRow, removeRow,
    reset,
  } = useTableState({ user, apiFormattedData });

  const mergedData = useMergedData({
    added,
    updated,
    removed,
    apiFormattedData,
  });

  const { errors } = useValidations(
    useValidationConfig(),
    mergedData,
  );
  const dateErrors = useMemo(() => {
    return dates.reduce((total, date) => {
      let duration = mergedData.reduce((total, item) => {
        if (!(date in item.dates)) {
          return total;
        }
        return total + item.dates[date].duration;
      }, 0);
      duration = moment.duration(duration, "seconds").asHours();
      total[date] = null;
      if (duration > 40) {
        total[date] = t("daily-total-should-not-surpas-40-hours");
      }
      return total;
    }, {});
  }, [t, dates, mergedData]);
  const hasErrors = useMemo(() => {
    const hasDateErrors = Object.values(dateErrors).some((err) => !!err)
    return hasDateErrors || Object
      .values(errors)
      .some((fields) => {
        if (!fields) {
          return false;
        }
        return Object.values(fields).some((err) => !!err);
      });
  }, [errors, dateErrors]);

  const getDependencyRowId = useGetDependencyRowId();
  const hasChanges = useMemo(() => {
    const sameLength = Object.keys(apiFormattedData).length === Object.keys(mergedData).length;
    if (!sameLength) {
      return true;
    }
    const initialCombinations = apiFormattedData
      .map((item) => getDependencyRowId(item))
      .sort();
    const finalCombinations = mergedData
      .map((item) => getDependencyRowId(item))
      .sort();
    const sameKeys = isEqual(initialCombinations, finalCombinations);
    if (!sameKeys) {
      return true;
    }
    return !apiFormattedData.every((item) => {
      const itemid = getDependencyRowId(item);
      const otheritem = mergedData.find((otheritem) => {
        return getDependencyRowId(otheritem) === itemid;
      });
      if (!otheritem) {
        return false;
      }
      const a = (dates) => {
        return Object
          .keys(item.dates)
          .reduce((total, date) => {
            total[date] = dates[date].duration;
            return total;
          }, {});
      }
      const b = (dates) => {
        return Object
          .keys(item.dates)
          .reduce((total, date) => {
            total[date] = dates[date].amount;
            return total;
          }, {});
      }
      return isEqual(
        a(item.dates),
        a(otheritem.dates),
      ) && isEqual(
        b(item.dates),
        b(otheritem.dates),
      )
    });
  }, [getDependencyRowId, apiFormattedData, mergedData]);

  useEffect(() => {
    if (!hasChanges) {
      reset();
    }
  }, [hasChanges, reset]);

  return useReactTable({
    initialState: {
      columnFilters: [],
      sorting: [],
    },
    meta: useMemo(() => ({
      getRowProps: getRowProps.bind(null, errors),

      permissions,
      user,

      hasChanges, hasErrors, errors, dateErrors,

      addRow, updateRow, removeRow, reset,
      lock, unlock, approve, unapprove, isLoadingDateAction,
    }), [
      user,
      permissions,
      hasChanges, hasErrors, errors, dateErrors,
      addRow, updateRow, removeRow, reset,
      lock, unlock, approve, unapprove, isLoadingDateAction,
    ]),

    data: mergedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId,

    manualFiltering: true,

    enableFilters: true,
    enableHiding: true,
  });
}
