import Form, { Field as BaseField, List } from "rc-field-form";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import Select from "../../../../../../components/Inputs/Select";

function FiltersTable({
  form,
  filtersName = "filters",
  defaultFilterName = "defaultFilter",
  config
}) {
  const { t } = useTranslation();

  const filterValues = Form.useWatch(filtersName);
  const filters = useMemo(() => {
    if (!filterValues) {
      return [];
    }
    return filterValues.filter(({ hide }) => {
      return !hide;
    });
  }, [filterValues]);

  const map = useMemo(() => {
    return config.reduce((total, column) => {
      const accessor = column.accessor || column.id;
      total[accessor] = column;
      return total;
    }, {});
  }, [config]);

  const filtersValue = Form.useWatch(filtersName);
  const defaultFilterValue = Form.useWatch(defaultFilterName);
  useEffect(() => {
    if (!defaultFilterValue || !filtersValue) {
      return;
    }
    const filter = filtersValue.find((item) => {
      return item.accessor === defaultFilterValue;
    });
    if (filter && filter.hide) {
      form.setFieldValue("defaultFilter", null);
    }
  }, [form, filtersValue, defaultFilterValue]);

  return (
    <div className="d-flex align-items-start gap-20">
      <table border="0" className="d-inline-block">
        <thead>
          <tr>
            <th />
            <th className="text-center px-3 py-2 text-dark">
              {t("hide")}
            </th>
          </tr>
        </thead>

        <tbody>
          <List name={filtersName}>
            {(fields) => fields?.map(({ key, name }) => (
              <tr key={key}>
                <td className="px-3 py-1 font-weight-bold">
                  <BaseField name={[name, "accessor"]}>
                    {({ value }) => map[value].Header}
                  </BaseField>
                </td>

                <td className="px-3 py-1">
                  <div className="d-flex align-items-center justify-content-center">
                    <BaseField name={[name, "hide"]} valuePropName="checked">
                      <Checkbox
                        id={`${filtersName}-${name}`}
                        labelClassName="px-2"
                      />
                    </BaseField>
                  </div>
                </td>
              </tr>
            ))}
          </List>
        </tbody>
      </table>

      <Field
        label={t("default-filter")}
        name={defaultFilterName}
      >
        <Select allowClear>
          {filters.map((filter) =>
            <Select.Option key={filter.accessor} value={filter.accessor}>
              {map[filter.accessor].Header}
            </Select.Option>)}
        </Select>
      </Field>
    </div>
  );
}

export default FiltersTable;
