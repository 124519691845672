import { useMemo, useCallback } from "react";
import Form, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import CustomFields from "./CustomFields";
import { Col, Row } from "reactstrap";
import DateInput from "../../../../../../../components/Inputs/DateInput";
import PayCodeSelect from "../../../../../components/PayCodeSelect";
import DurationInput from "../../../../../../../components/Inputs/DurationInput";
import MoneyInput from "../../../../../../../components/Inputs/MoneyInput";
import Select from "../../../../../../../components/Inputs/Select";
import Field from "../../../../../../../components/Field";
import CustomTimeInput from "../../../../../../../components/Inputs/CustomTimeInput";
import Levels from "./Levels";
import { getCommonCompanies, getCommonJobs, getCommonLocations, getCommonPayGroup } from "./helpers";
import { getInitial } from "./initial";
import { useInitialState } from "../../../../../../../utils/hooks/useInitialState";
import { renderSettingsResourceLabel } from "../../../../../../../utils/helpers/settings";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import { useDateFilters } from "../../../../../hooks/useDateFilters";

const doesPayCodeDisableTime = (payCode) => {
    return ["amount", "unit"].includes(payCode?.hourType);
}

const AddBulkForm = ({ form, users }) => {
    const { t } = useTranslation();
    const { from, to } = useDateFilters();

    const companies = useMemo(() => {
        if (!users) {
            return [];
        }
        return getCommonCompanies(users);
    }, [users]);
    const payGroup = useMemo(() => {
        if (!users) {
            return null;
        }
        return getCommonPayGroup(users);
    }, [users]);
    const payCode = Form.useWatch("payCode", form);
    const timeInputDisabled = useMemo(
        () => ["amount", "unit"].includes(payCode?.hourType),
        [payCode?.hourType],
    );
    const onPayCodeChange = useCallback((value) => {
        const prevTimeInputDisabled = doesPayCodeDisableTime(
            form.getFieldValue("payCode")
        );
        const timeInputDisabled = ["amount", "unit"].includes(value?.hourType);
        if (timeInputDisabled) {
            const date = moment().startOf("day").toISOString();
            form.setFieldsValue({ in: date, out: date });
        } else if (prevTimeInputDisabled) {
            form.setFieldsValue({ in: undefined, out: undefined });
        }
    }, [form]);

    const jobs = useMemo(() => {
        if (!users) {
            return [];
        }
        return getCommonJobs(users);
    }, [users]);

    const locations = useMemo(() => {
        if (!users) {
            return [];
        }
        return getCommonLocations(users);
    }, [users]);

    const { allocatedGroups } = useGroups();
    const initialValues = useInitialState(getInitial({ users, allocatedGroups }))

    const endDate = Form.useWatch("endDate", form);
    const filterStartDate = useCallback((date) => {
        if (
            moment(date).isAfter(to)
            || moment(date).isBefore(from)
        ) {
            return false;
        }
        if (endDate && moment(date).isAfter(endDate)) {
            return false;
        }
        return true;
    }, [from, to, endDate]);

    const startDate = Form.useWatch("startDate", form);
    const filterEndDate = useCallback((date) => {
        if (
            moment(date).isAfter(to)
            || moment(date).isBefore(from)
        ) {
            return false;
        }
        if (startDate && moment(date).isBefore(startDate)) {
            return false;
        }
        return true;
    }, [from, to, startDate]);

    return (
        <Form form={form} initialValues={initialValues}>
            <Row>
                <Col md={6}>
                    <Field
                        name="startDate"
                        label={t("start-date")}
                        rules={[
                            {
                                required: true,
                                message: t("required-start-date"),
                            },
                        ]}
                        className="d-flex flex-column w-100"
                    >
                        <DateInput filterDate={filterStartDate} autocomplete="off" />
                    </Field>
                </Col>

                <Col md={6}>
                    <Field
                        name="endDate"
                        label={t("end-date")}
                        rules={[
                            {
                                required: true,
                                message: t("required-end-date"),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, endDate) {
                                    const startDate = getFieldValue("startDate");
                                    if (
                                        startDate
                                        && endDate
                                        && moment(endDate).isBefore(startDate, "day")
                                    ) {
                                        return Promise.reject(
                                            new Error("end-date-bigger-than-start-date")
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        dependencies={["startDate"]}
                        className="d-flex flex-column w-100"
                    >
                        <DateInput filterDate={filterEndDate} autocomplete="off" />
                    </Field>
                </Col>
            </Row>


            <Field
                name="payCode"
                label={t("time-code")}
                rules={[
                    {
                        required: true,
                        message: t("required-time-code"),
                    },
                ]}
            >
                <PayCodeSelect
                    companies={companies}
                    payGroup={payGroup}
                    allowClear
                    onChange={onPayCodeChange}
                />
            </Field>

            <BaseField name="payCode">
                {({ value: payCode }) => {
                    switch (payCode?.hourType) {
                        case "unit": {
                            return (
                                <Field
                                    name="duration"
                                    label={t("duration")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-duration"),
                                        },
                                    ]}>
                                    <DurationInput />
                                </Field>
                            );
                        }
                        case "amount": {
                            return (
                                <Field
                                    name="rate"
                                    label={t("amount")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-amount"),
                                        },
                                    ]}
                                >
                                    <MoneyInput className="d-block w-100" />
                                </Field>
                            );
                        }
                        default:
                            return (
                                <Row>
                                    <Col md={6}>
                                        <Field
                                            name="in"
                                            label={t("clock-in")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("required-clock-in"),
                                                },
                                            ]}
                                        >
                                            <CustomTimeInput disabled={timeInputDisabled} />
                                        </Field>
                                    </Col>

                                    <Col md={6}>
                                        <Field
                                            name="out"
                                            label={t("clock-out")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("required-clock-out"),
                                                },
                                            ]}
                                        >
                                            <CustomTimeInput disabled={timeInputDisabled} />
                                        </Field>
                                    </Col>
                                </Row>
                            )
                    }

                    return null;
                }}
            </BaseField>

            {jobs.length > 0 && (
                <Field name="job" label={t("job")}>
                    <Select allowClear>
                        {jobs.map((job) => (
                            <Select.Option key={job.jobId} value={job.jobId}>
                                {renderSettingsResourceLabel(job)}
                            </Select.Option>
                        ))}
                    </Select>
                </Field>
            )}

            {locations.length > 0 && (
                <Field name="location" label={t("location")}>
                    <Select allowClear>
                        {locations.map((location) => (
                            <Select.Option key={location.locationId} value={location.locationId}>
                                {renderSettingsResourceLabel(location)}
                            </Select.Option>
                        ))}
                    </Select>
                </Field>
            )}
            <Levels form={form} users={users} />

            <CustomFields />
        </Form>
    );
}

export default AddBulkForm;
