import { useContext, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Row, Col } from "reactstrap";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Switch from "../../../../../components/Inputs/Switch";
import DateRange from "./DateRange";
import { OrgLevelGroupsContext } from "../../../../Settings/General/OrganisationLevelGroups/context";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { firstToUpper } from "../../../../../utils/helpers/string";

function Topbar({ onFiltersChange, filters, group, setFilters }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const groupWithBudget = useMemo(
		() => groups?.find(({ hasBudget }) => hasBudget),
		[groups],
	);

	const getFilterValues = useCallback(
		(values) => {
			return {
				field: `${groupWithBudget?.level}`.replace("_", ""),
				value: values?.map((value, index) => 
					value?.id ? value?.id : group?.value?.[index]
				)?.filter((value) => value),
			};
		},
		[groupWithBudget, group],
	);

	const onTypeChange = useCallback((e) => {
		const type = e.target.checked ? "hours" : "amount"; 
		setFilters((prev) => ({
			...prev,
			type,
		}))
	}, [setFilters]);

	const onChange = useMemo(
		() =>
			debounce((value) => {
				onFiltersChange(getFilterValues(value));
			}, 1000),
		[onFiltersChange, getFilterValues],
	);

	useEffect(() => {
		return () => onChange.cancel();
	}, [onChange]);

	return (
		<Row className="align-items-center">
			<Col className="d-flex align-items-center" xs="12" md="12" lg="2">
				<span className="text-dark font-weight-bold mr-2">
					{t("amount")}
				</span>
				<span className="mb-1">
					<Switch 
						id="chartType" 
						onChange={onTypeChange}
						checked={filters?.type === "hours"}
					/>
				</span>
				<span className="text-info font-weight-bold mr-2">
					{firstToUpper(t("hours"))}
				</span>
			</Col>

			<Col className="d-flex align-items-center" xs="12" md="6" lg="5">
				{groupWithBudget && (
					<>
						<h3 className="mr-2 text-nowrap mb-0">
							{groupWithBudget.description}
						</h3>

						<ResourceSelect
							className="w-44"
							labelPropName="description"
							resourcePath={`/${groupWithBudget.id}/org-levels`}
							mode="multiple"
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							valuePropName="id"
							onChange={onChange}
							hasSearch
							allowClear
							placeholder={t("search")}
						/>
					</>
				)}
			</Col>

			<Col className="d-flex align-items-center" xs="12" md="6" lg="5">
				<DateRange filters={filters} setFilters={setFilters} />
			</Col>
		</Row>
	);
}

export default Topbar;
