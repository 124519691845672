import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { usePayCodesMap } from "../../../Settings/PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";
import { useRegularPayCode } from "../../../Settings/PayPolicies/CalculationGroup/Form/hooks/useRegularPayCode";
import { useCompanyCurrency } from "../../../../utils/hooks/company";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../utils/helpers/currencyFormatter";

const colors = {
	"regular": "#525f7f",
	"transfer": "#5428e0",
	"break": "#fe9700",
	"overtime": "#f8219c",
}

export const useEventResizeFeature = ({ mode }) => {
	const { t } = useTranslation();

	const currency = useCompanyCurrency();
	const payCodesMap = usePayCodesMap();
	const regularPayCode = useRegularPayCode(payCodesMap);

	const getDefaultPayCode = useCallback((user) => {
		const employeeTypeCode = user?.employeeType?.code;
		const employeeTypeAsDefault =
			user?.calculationGroup?.useEmployeeTypeAsDefaultPayCode;
		const regularDefaultPayCode = user?.calculationGroup?.regularDefaultPayCode;
		if (payCodesMap) {
			if (employeeTypeAsDefault && employeeTypeCode in payCodesMap) {
				return payCodesMap[employeeTypeCode];
			}
			if (
				regularDefaultPayCode &&
				regularDefaultPayCode.code in payCodesMap
			) {
				return payCodesMap[regularDefaultPayCode.code];
			}
		}
		return regularPayCode;
	}, [payCodesMap, regularPayCode]);

	const getShifts = useCallback((record, endDate) => {
		const baseShifts = record?.shifts;
		const endTimestamp = moment(endDate).set('millisecond', 0).set('seconds', 0).unix();
		const duration = endTimestamp - record?.timestamp;
		const payCode =  getDefaultPayCode(record?.resource);
		const defaultShift = {
			type: "regular",
			payCode
		};
		const shifts = [];
		for(let i = 0; i < baseShifts?.length; i++) {
			if (
				(baseShifts[i]?.end > duration && baseShifts[i]?.start < duration)
				|| (baseShifts[i]?.end < duration && baseShifts?.length - 1 === i)
			) {
				if (baseShifts[i]?.type === "regular" || baseShifts[i]?.type === "transfer") {
					shifts.push({
						...baseShifts[i],
						end: duration,
						duration: duration - baseShifts[i]?.start
					});
					break;
				} else {
					shifts.push(
						{ ... baseShifts[i] },
						{
							...defaultShift,
							start: baseShifts[i]?.end,
							end: duration,
							duration: duration - baseShifts[i]?.end
						}
					);
					break;
				}
			} else {
				shifts.push(baseShifts[i]);
			}
		}
		return shifts;
	}, [getDefaultPayCode]);

	const getIntervals = useCallback((segments) => {
		return segments?.map((segment) => {
			const duration = typeof segment?.end === "number" 
				? segment?.end - segment?.start 
				: moment(segment?.end).diff(moment(segment?.start), "seconds");

			return {
				type: segment?.type,
				duration
			}
		})
	}, []);

	const getIntervalWidth = useCallback(
		(intervals, shifts, duration) => {
			const totalDuration = intervals.reduce(
				(total, interval) => interval.duration + total, 0
			);

			const lastShift = shifts?.length === intervals?.length;
			
			if (lastShift) {
				return duration / totalDuration;
			}
			return duration / totalDuration / 2;
		},
		[],
	);

	const eventResizeFeature = useMemo(() => ({
		tooltipTemplate: ({ record, endDate }) => {
			const shifts = getShifts(record, endDate);
			const intervals = getIntervals(shifts);

			return (
                `<div style="height:100%; width:100%; margin:0px; padding: 15px 20px;">
                    <div 
                        style="
                            height: 22px; 
                            border-radius: 5px; 
                            display: flex; 
                            flex-direction: 
                            row; margin-bottom: 10px;
                            border: 1px solid #fff;
                        "
                    >
                        ${intervals.map(({ type, duration }, i) => {
                            return `
                                <span
                                    key="${type}-${duration}-${i}"
                                    style="
                                        width: ${getIntervalWidth(intervals, shifts, duration) * 100}%;
                                        background-color: ${colors[type]};
                                        height: 20px;
                                        border-radius: ${i === intervals?.length - 1
                                             ? "0px 5px 5px 0px" 
                                             : i === 0 ? "5px 0px 0px 5px"
                                             : "0px"};
                                    "
                                ></span>
                        `}).join("")}
                    </div>

                    <table border="1">
                        <thead>
                            <th style="padding: 5px;">${t("start")}</th>
                            <th style="padding: 5px;">${t("end")}</th>
                            <th style="padding: 5px;">${t("type")}</th>
                            <th style="padding: 5px;">${t("duration")}</th>
                            <th style="padding: 5px;">${t("total")}</th>
                        </thead>

                        <tbody>
                            ${shifts?.map((shift) => { 
                                const startDate = record?.startDate;
                                const start = moment(startDate).add(shift.start, "seconds");
                                const end = moment(startDate).add(shift.end, "seconds");

                                return (
                                    `
                                        <tr style="margin-bottom: 5px;">
                                            <td style="padding: 5px;">${moment(start).format("hh:mm A")}</td>
                                            <td style="padding: 5px;">${moment(end).format("hh:mm A")}</td>
                                            <td style="padding: 5px;">${shift.payCode?.code}</td>
                                            <td style="padding: 5px;">${renderDurationAsFormat(shift.duration, "HH:mm")}</td>
                                            <td style="padding: 5px;">
                                                ${currencyFormatter(shift?.total || 0, 2, record?.job?.currency || currency)}
                                            </td>
                                        </tr>
                                    `
                                )}
                            ).join("")}
                        </tbody>
                    </table>
                </div>`
            )
		}
	}), [getShifts, getIntervalWidth, getIntervals, currency]);

	return useMemo(() => {
		if (mode === "date") {
			return eventResizeFeature;
		} else {
			return false;
		}
	}, [mode, eventResizeFeature]);
}