import { useMemo } from "react";
import {
  faGears,
  faLayerGroup,
  faPeopleLine,
  faBuilding,
  faLocationDot,
  faPeopleRoof,
  faUser,
  faListCheck,
  faUsersRectangle,
  faBriefcase,
  faPersonChalkboard,
  faPeopleGroup,
  faFileInvoice,
  faSackDollar,
  faGifts,
  faStopwatch,
  faCalculator,
  faHome,
  faClone,
  faClock,
  faChildReaching,
  faCircleNodes,
  faReceipt,
  faTableList,
  faPhone,
  faQrcode,
  faProjectDiagram,
  faUserGroup,
  faUserClock,
  faBriefcaseClock,
  faPersonCircleCheck,
  faU,
  faTextHeight,
  faBook,
  faRectangleList,
  faUsersBetweenLines,
  faCalendarDays,
  faClipboardList,
  faFolderTree,
  faFileCode,
  faUserGear,
  faImages,
  faEnvelope,
  faCalendarCheck,
  faCoins,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../utils/hooks/user";
import { getViewPermissions } from "../../utils/helpers/access";
import { useAccess, useAccessInList } from "../../utils/hooks/access";

export const useSidebarConfig = () => {
  const user = useUser();
  const accessMap = user?.permissions;

  const generalSettings = useMemo(
    () =>
      accessMap?.settings?.general
        ? getViewPermissions(accessMap?.settings?.general, "settings.general")
        : [],
    [accessMap?.settings?.general]
  );

  const { hasAccess: canViewGeneralSettings } =
    useAccessInList(generalSettings);

  const { hasAccess: canViewConfiguration } = useAccessInList([
    "settings.configuration.canView",
	"settings.configuration.rate.canView",
  ]);

  const { hasAccess: canManageUsers } = useAccess("canManageUsers");

  const timeClockPermissions = useMemo(
    () =>
      accessMap?.clockin
        ? getViewPermissions(accessMap?.clockin, "clockin")
        : [],
    [accessMap?.clockin]
  );
  const { hasAccess: canViewClockin } = useAccessInList(timeClockPermissions);

  const { hasAccess: canViewTimesheet } = useAccessInList([
    "timeSheet.canViewMyTimesheet",
    "timeSheet.canView",
  ]);

  const { hasAccess: canViewScheduler } = useAccessInList([
	"schedule.canView",
	"schedule.canViewMySchedule"
  ])

  const { hasAccess: canViewRequest } = useAccessInList([
    "request.canViewTeamRequest",
    "request.canView",
  ]);

  const payPoliciesSettings = useMemo(
    () =>
      accessMap?.settings?.payPolicies
        ? getViewPermissions(
          accessMap?.settings?.payPolicies,
          "settings.payPolicies"
        )
        : [],
    [accessMap?.settings?.payPolicies]
  );
  const { hasAccess: canViewPayPolicies } =
    useAccessInList(payPoliciesSettings);

  const integrationSettings = useMemo(
    () =>
      accessMap?.settings?.integration
        ? getViewPermissions(
          accessMap?.settings?.integration,
          "settings.integration"
        )
        : [],
    [accessMap?.settings?.integration]
  );

  const { hasAccess: canViewIntegration } =
    useAccessInList(integrationSettings);

  const { hasAccess: canViewClockinSettings } = useAccessInList([
    "device.canView",
    "qrCode.canView",
    "whitelistNumber.canView",
    "settings.flyer.canView",
		"settings.attestation.canView"
  ]);

  const { hasAccess: canViewGroup } = useAccessInList([
    "settings.general.organizationLevelGroup.canView",
    "settings.general.organizationLevelGroup.organizationLevel.canView",
  ]);

  const customFieldSettings = useMemo(
    () =>
      accessMap?.settings?.general.customFields
        ? getViewPermissions(
          accessMap?.settings?.general.customFields,
          "settings.general.customFields"
        )
        : [],
    [accessMap?.settings?.customFields]
  );

  const { hasAccess: canViewCustomFields } =
    useAccessInList(customFieldSettings);

  const ruleSettings = useMemo(
    () =>
      accessMap?.settings?.rules
        ? getViewPermissions(accessMap?.settings?.rules, "settings.rules")
        : [],
    [accessMap?.settings?.rules]
  );

  const schedulingSettings = useMemo(
    () =>
      accessMap?.settings?.scheduling
        ? getViewPermissions(
          accessMap?.settings?.scheduling,
          "settings.scheduling"
        )
        : [],
    [accessMap?.settings?.rules]
  );

  const { hasAccess: canViewRules } = useAccessInList(ruleSettings);
  const { hasAccess: canViewScheduling } = useAccessInList(schedulingSettings);

  const { hasAccess: canViewReports } = useAccess("reports.canView");

	return useMemo(
		() => [
			{
				index: true,
				title: "dashboard",
				icon: faHome,
				permissions: ["dashboard.canView"],
			},
			{
				path: "people",
				icon: faUser,
				permissions: ["people.canView"],
			},
			{
				path: "time-clock",
				icon: faClock,
				access: canViewClockin,
				children: canManageUsers
					? [
						{
							index: true,
							path: "team",
							title: "my-team",
							icon: faUserGroup,
							permissions: ["canManageUsers"],
						},
						{
							path: "my",
							title: "my-shift",
							icon: faUserClock,
							permissions: ["clockin.canView"],
						},
						{
							path: "original-clocks",
							title: "original-clocks",
							icon: faBriefcaseClock,
							permissions: ["clockin.canViewOriginalClock"],
						},
					]
					: [
						{
							index: true,
							path: "my",
							title: "my-shift",
							icon: faUserClock,
							permissions: ["clockin.canView"],
						},
						{
							path: "original-clocks",
							title: "original-clocks",
							icon: faBriefcaseClock,
							permissions: ["clockin.canViewOriginalClock"],
						},
					],
			},
			{
				path: "time-sheet",
				icon: faClone,
				access: canViewTimesheet,
				children: canManageUsers
					? [
						{
							index: true,
							path: "team",
							title: "my-team",
							icon: faUserGroup,
							permissions: ["timeSheet.canView"],
						},
						{
							path: "weekly",
							title: "weekly",
							icon: faCalendarWeek,
							permissions: ["timeSheet.canView"],
						},
						{
							path: "manager-view",
							title: "manager-view",
							icon: faUsersBetweenLines,
							permissions: ["timeSheet.canView"],
						},
						{
							path: "my",
							title: "my-timesheet",
							icon: faUserClock,
							permissions: ["timeSheet.canViewMyTimesheet"],
						},
					]
					: [
						{
							index: true,
							path: "my",
							title: "my-timesheet",
							icon: faUserClock,
							permissions: ["timeSheet.canViewMyTimesheet"],
						},
					],
			},
			{
				path: "scheduler",
				icon: faCalendarCheck,
				access: canViewScheduler,
				children: canManageUsers
					? [
							{
								index: true,
								path: "team",
								title: "my-team",
								icon: faUserGroup,
								permissions: ["schedule.canView"],
							},
							{
								path: "my",
								title: "my-schedule",
								icon: faUserClock,
								permissions: ["schedule.canViewMySchedule"],
							},
					  ]
					: [
							{
								index: true,
								path: "my",
								title: "my-schedule",
								icon: faUserClock,
								permissions: ["schedule.canViewMySchedule"],
							},
					  ],
			},
			{
				path: "request",
				icon: faChildReaching,
				access: canViewRequest,
				children: canManageUsers
					? [
						{
							index: true,
							path: "team",
							title: "my-team",
							icon: faUserGroup,
							permissions: ["request.canViewTeamRequest"],
						},
						{
							path: "my",
							title: "my-request",
							icon: faUserClock,
							permissions: ["request.canView"],
						},
					]
					: [
						{
							index: true,
							path: "my",
							title: "my-request",
							icon: faUserClock,
							permissions: ["request.canView"],
						},
					],
			},
			{
				path: "reports",
				icon: faTableList,
				access: canViewReports,
				children: [
					{
						index: true,
						path: "system-reports",
						permissions: ["reports.canView"],
						title: "system-reports",
						icon: faFolderTree
					},
					{
						path: "custom-reports",
						title: "custom-reports",
						permissions: ["reports.canView"],
						icon: faFileCode
					}
				]
			},
			{ type: "divider" },
			{
				type: "title",
				title: "settings",
				access:
					!canViewGeneralSettings &&
					!canViewClockinSettings &&
					!canViewPayPolicies &&
					!canViewIntegration &&
					!canViewConfiguration &&
					!canViewScheduling &&
					!canViewRules &&
					!canViewCustomFields
			},
			{
				path: "settings",
				inSidebar: false,
				children: [
					{
						path: "general",
						icon: faGears,
						access: canViewGeneralSettings,
						children: [
							{
								index: true,
								inSidebar: false,
							},
							{
								path: "groups",
								icon: faLayerGroup,
								access: canViewGroup,
								children: [
									{ index: true, inSidebar: false },
									{
										path: ":id/levels",
										permissions: [
											"settings.general.organizationLevelGroup.organizationLevel.canView",
										],
									},
								],
							},
							{
								path: "companies",
								icon: faBuilding,
								permissions: [
									"settings.general.companies.canView",
								],
							},
							{
								path: "scheduler",
								icon: faCalendarDays,
								permissions: [
									"settings.general.canView"
								]
							},
							{
								path: "locations",
								icon: faLocationDot,
								permissions: [
									"settings.general.locations.canView",
								],
							},
							{
								path: "employee-type",
								icon: faPeopleLine,
								permissions: [
									"settings.general.employeeTypes.canView",
								],
							},
							{
								path: "user-group",
								icon: faUserGroup,
								permissions: [
									"settings.general.userGroup.canView",
								],
							},
							{
								path: "union",
								icon: faU,
								permissions: ["settings.general.union.canView"],
							},
							{
								path: "projects",
								icon: faRectangleList,
								permissions: [
									"settings.general.project.canView",
								],
							},
							{
								path: "job-groups",
								icon: faUsersRectangle,
								permissions: [
									"settings.general.jobGroups.canView",
								],
							},
							{
								path: "job-families",
								icon: faPeopleRoof,
								permissions: [
									"settings.general.jobFamilies.canView",
								],
							},
							{
								path: "jobs",
								icon: faBriefcase,
								permissions: ["settings.general.jobs.canView"],
							},
							{
								path: "tasks",
								icon: faListCheck,
								permissions: ["settings.general.tasks.canView"],
							},
							{
								path: "roles",
								icon: faPersonChalkboard,
								permissions: ["settings.general.roles.canView"],
							},
						],
					},
					{
						path: "pay-policy",
						icon: faReceipt,
						access: canViewPayPolicies,
						children: [
							{
								path: "pay-group",
								icon: faPeopleGroup,
								permissions: [
									"settings.payPolicies.payGroup.canView",
								],
							},
							{
								path: "pay-code",
								icon: faFileInvoice,
								permissions: [
									"settings.payPolicies.payCode.canView",
								],
							},
							{
								path: "earning-group",
								icon: faFileInvoice,
								permissions: [
									"settings.payPolicies.earningGroup.canView",
								],
							},
							{
								path: "budget",
								icon: faSackDollar,
								permissions: [
									"settings.payPolicies.budget.canView",
								],
							},
							{
								path: "holiday-calendar",
								icon: faGifts,
								permissions: [
									"settings.payPolicies.holidays.canView",
								],
							},
							{
								path: "time-off",
								icon: faStopwatch,
								permissions: [
									"settings.payPolicies.timeOff.canView",
								],
							},
							{
								path: "calculation-group",
								icon: faCalculator,
								permissions: [
									"settings.payPolicies.calculationGroup.canView",
								],
							},
						],
					},
					{
						path: "clock-in",
						icon: faClock,
						access: canViewClockinSettings,
						children: [
							{
								path: "whitelist-number",
								icon: faPhone,
								permissions: ["whitelistNumber.canView"],
							},
							{
								path: "qr-code",
								icon: faQrcode,
								permissions: ["qrCode.canView"],
							},
							{
								path: "device",
								icon: faProjectDiagram,
								permissions: ["device.canView"],
							},
							{
								path: "flyers",
								icon: faImages,
								permissions: ["settings.flyer.canView"],
							},
							{
								path: "attestation",
								icon: faEnvelope,
								permissions: ["settings.attestation.canView"],
							},
						],
					},
					{
						path: "custom-fields",
						icon: faTextHeight,
						access: canViewCustomFields,
						children: [
							{
								path: "timesheet",
								icon: faUserClock,
								permissions: [
									"settings.general.customFields.canView",
								],
							},
							{
								path: "clock-in",
								icon: faClock,
								permissions: [
									"settings.general.customFields.canView",
								],
							},
						],
					},
					{
						path: "integration",
						icon: faCircleNodes,
						access: canViewIntegration,
						children: [
							{
								path: "ultipro",
								icon: faPersonCircleCheck,
								permissions: [
									"settings.integration.ultipro.canView",
								],
							},
							{
								path: "uta",
								icon: faPersonChalkboard,
								permissions: [
									"settings.integration.uta.canView",
								],
							},
						],
					},
					{
						path: "rules",
						icon: faBook,
						access: canViewRules,
						children: [
							{
								path: "calculation-group",
								icon: faCalculator,
								permissions: [
									"settings.rules.calculationGroup.canView",
								],
							},
						],
					},
					{
						path: "job-scheduling",
						icon: faCalendarDays,
						access: canViewScheduling,
						children: [
							{
								path: "reports",
								icon: faClipboardList,
								permissions: [
									"settings.scheduling.reports.canView",
								],
							},
							{
								path: "jobs",
								icon: faBriefcaseClock,
								permissions: [
									"settings.scheduling.jobs.canView",
								],
							},
						],
					},
					{
						path: "configuration",
						icon: faUserGear,
						access: canViewConfiguration,
						children: canManageUsers ? [
							{
								path: "timesheet",
								icon: faClone,
								access: canViewConfiguration,
								children: [
									{
										path: "team",
										icon: faUserGroup,
										title: "my-team",
										permissions: [
											"settings.configuration.canView",
										],
									},
									{
										path: "manager-view",
										icon: faUsersBetweenLines,
										permissions: [
											"settings.configuration.canView",
										],
									},
									{
										path: "weekly",
										icon: faCalendarWeek,
										permissions: [
											"settings.configuration.canView",
										],
									},
									{
										path: "my",
										icon: faUserClock,
										title: "my-timesheet",
										permissions: [
											"settings.configuration.canView",
										],
									},
									{
										path: "rate",
										icon: faCoins,
										permissions: [
											"settings.configuration.canView"
										],
									}
								]
							},
						] : [
								{
									path: "timesheet",
									icon: faClone,
									access: canViewConfiguration,
									children: [
										{
											path: "my",
											icon: faUserClock,
											title: "my-timesheet",
											permissions: [
												"settings.configuration.canView"
											],
										},
										{
											path: "rate",
											icon: faCoins,
											permissions: [
												"settings.configuration.canView"
											],
										}
									]
								},
						],
					},
				],
			},
		],
		[
			canManageUsers,
			canViewGeneralSettings,
			canViewClockin,
			canViewTimesheet,
			canViewRequest,
			canViewPayPolicies,
			canViewClockinSettings,
			canViewIntegration,
			canViewGroup,
			canViewCustomFields,
			canViewRules,
			canViewScheduling,
			canViewConfiguration,
			canViewReports,
			canViewScheduler
		],
	);
};
