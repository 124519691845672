import moment from "moment-timezone";

const type1 = /^(0[1-9]|1[0-9])$/; //10-19
const type2 = /^([0-9](a|p))$/; //1a
const type3 = /^((0[1-9]|1[0-2])(a|p))$/; //10a
const type4 = /^([0-9])$/; //0-9
const type5 = /^((0[1-9]|1[0-2])(am|pm))$/; //10am 10AM
const type6 = /^([0-9](am|pm))$/; //1am 2AM
const type7 = /^(2[0-3])$/; //21

const type8 = /^((0[1-9]|1[3-9])(p))$/; //13-19 p
const type9 = /^((0[1-9]|1[0-9])(pm))$/; //10-19 pm
const type10 = /^((2[0-3])(p))$/; //21
const type11 = /^((2[0-3])(pm))$/; //21PM

const type12 = /^((0[0-9]|1[0-9]|2[0-3])[0-5][0-9])$/; //1456 => 14:56
const type13 = /^(([0-9])[0-5][0-9])$/; //135=>01:35
const type19 = /^((0[0-9]|1[0-2])[0-5][0-9](a|p))$/; //1355p
const type191 = /^((0[0-9]|1[0-2])[0-5][0-9](a|p)m)$/; //1355pm
const type20 = /^(([0-9])[0-5][0-9](a|p))$/; //135p=>01:35
const type201 = /^(([0-9])[0-5][0-9](a|p)m)$/; //135pm=>01:35

const type14 = /^((1[0-2]|0?[1-9]):([0-5]?[0-9]):([0-5]?[0-9])([ap][m]))$/; //12:00:00 am
const type15 = /^((0[0-9]|1?[0-9]|2[0-3]):([0-5]?[0-9]):([0-5]?[0-9]))$/; //12:00:00 -> HH:mm
const type22 = /^((1[0-2]|0?[1-9]):([0-5]?[0-9]):([0-5]?[0-9])([ap]))$/; //12:00:00 am

const type16 = /^(0[0-9])$/; //01
const type17 = /^(0[0-9](a|p))$/; //01a
const type18 = /^(0[0-9](am|pm))$/; //01am 02AM
const type23 = /^([0-9]):([0-5][0-9])((a|p))$/; //hh:mm a

const reg12 = /^(0[1-9]|1[0-2]):([0-5][0-9])((a|p)m)$/; //hh:mm a
const type21 = /^(0[1-9]|1[0-2]):([0-5][0-9])(a|p)$/; //hh:mm a

const reg24 = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; //HH:mm

const format12 = "YYYY-MM-DD, hh:mm:ss A";
const format24 = "YYYY-MM-DD, HH:mm:ss ";

export const checkTime = (value) => {
	const today = new Date();
	const todaydate =
		today.getFullYear() +
		"-" +
		(Number(today.getMonth()) + 1) +
		"-" +
		today.getDate();

	if (type4.test(value)) {
		return moment(
			todaydate + ", 0" + value + ":00:00 am",
			format12
		).millisecond(0);
	}
	if (type16.test(value)) {
		return moment(
			todaydate + ", " + value + ":00:00 am",
			format12
		).millisecond(0);
	} else if (type1.test(value)) {
		if (parseInt(value) >= 12) {
			return moment(
				todaydate + ", " + value + ":00:00 pm",
				format12
			).millisecond(0);
		} else {
			return moment(
				todaydate + ", " + value + ":00:00 am",
				format12
			).millisecond(0);
		}
	} else if (type2.test(value)) {
		return moment(
			todaydate +
				", 0" +
				value.substring(0, 1) +
				":00:00 " +
				value.substring(1, 2) +
				"m",
			format12
		).millisecond(0);
	} else if (type17.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, 2) +
				":00:00 " +
				value.substring(2) +
				"m",
			format12
		).millisecond(0);
	} else if (type3.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, 2) +
				":00:00 " +
				value.substring(2, 3) +
				"m",
			format12
		).millisecond(0);
	} else if (type5.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, 2) +
				":00:00 " +
				value.substring(2),

			format12
		).millisecond(0);
	} else if (type6.test(value)) {
		return moment(
			todaydate +
				", 0" +
				value.substring(0, 1) +
				":00:00 " +
				value.substring(1),
			format12
		).millisecond(0);
	} else if (type18.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, 2) +
				":00:00 " +
				value.substring(3),
			format12
		).millisecond(0);
	} else if (type7.test(value)) {
		return moment(
			todaydate + ", " + value + ":00:00 PM",
			format12
		).millisecond(0);
	} else if (type14.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, value.length - 2) +
				" " +
				value.substring(value.length - 2),
			format12
		).millisecond(0);
	} else if (type22.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, value.length - 1) +
				" " +
				value.substring(value.length - 1) +
				"m",
			format12
		).millisecond(0);
	} else if (type15.test(value)) {
		return moment(todaydate + ", " + value, format24).millisecond(0);
	} else if (
		type8.test(value) ||
		type9.test(value) ||
		type10.test(value) ||
		type11.test(value)
	) {
		return moment(
			todaydate + ", " + value.substring(0, 2) + ":00:00 pm",
			format12
		).millisecond(0);
	} else if (type12.test(value)) {
		return moment(
			todaydate + ", " + value.substring(0, 2) + ":" + value.substring(2),
			format24
		).millisecond(0);
	} else if (type13.test(value)) {
		return moment(
			todaydate +
				", 0" +
				value.substring(0, 1) +
				":" +
				value.substring(2) +
				":00",
			format24
		).millisecond(0);
	} else if (type20.test(value)) {
		return moment(
			todaydate +
				", 0" +
				value.substring(0, 1) +
				":" +
				value.substring(1, 3) +
				":00 " +
				value.substring(3) +
				"p",
			format12
		).millisecond(0);
	} else if (type201.test(value)) {
		return moment(
			todaydate +
				", 0" +
				value.substring(0, 1) +
				":" +
				value.substring(1, 3) +
				":00 " +
				value.substring(3),
			format12
		).millisecond(0);
	} else if (type19.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, 2) +
				":" +
				value.substring(2, 4) +
				":00 " +
				value.substring(4) +
				"m",
			format12
		).millisecond(0);
	} else if (type191.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, 2) +
				":" +
				value.substring(2, 4) +
				":00 " +
				value.substring(4),
			format12
		).millisecond(0);
	} else if (reg12.test(value)) {
		return moment(todaydate + ", " + value, format12).millisecond(0);
	} else if (type23.test(value)) {
		return moment(
			todaydate +
				", 0" +
				value.substring(0, value.length - 1) +
				":00 " +
				value.substring(value.length - 1) +
				"p",
			format12
		).millisecond(0);
	} else if (type21.test(value)) {
		return moment(
			todaydate +
				", " +
				value.substring(0, value.length - 1) +
				":00 " +
				value.substring(value.length - 1) +
				"m",
			format12
		).millisecond(0);
	} else if (reg24.test(value)) {
		return moment(todaydate + ", " + value + ":00", format24).millisecond(
			0
		);
	} else return "";
};
