import React from "react";
import ReactDOM from "react-dom/client";
// plugins styles from node_modules
// import 'react-notification-alert/dist/animate.css'
// import 'react-perfect-scrollbar/dist/css/styles.css'
// import '@fullcalendar/common/main.min.css'
// import '@fullcalendar/daygrid/main.min.css'
// import 'sweetalert2/dist/sweetalert2.min.css'
// import 'select2/dist/css/select2.min.css'
// import 'quill/dist/quill.core.css'
// import '@fortawesome/fontawesome-free/css/all.min.css'
// plugins styles downloaded
import "react-datepicker/dist/react-datepicker.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
