import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Button from "../../../../../../components/Button";
import Filters from "./Filters";
import Employee from "./Employee";
import EmployeeSelect from "./EmployeeSelect";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import { useAuthentication } from "../../../../../Authentication/context";
import { useIsMasterCompany } from "../../../../../../utils/hooks/company";
import "./style.scss";

function EnrollEmployees({ data, submit, goBack, error, disabled }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { company } = useAuthentication();

	const [countries, setCountries] = useState(
		company?.settings.countries || [],
	);

	const isMasterCompany = useIsMasterCompany();

	const [locations, setLocations] = useState([]);

	const [awaitingEmployees, setAwaitingEmployees] = useState([]);
	const [addedEmployees, setAddedEmployees] = useState(data?.employees || []);

	const selectedEmployees = useMemo(
		() => awaitingEmployees.concat(addedEmployees),
		[awaitingEmployees, addedEmployees],
	);

	const onSelectEmployee = useCallback(
		(user) => {
			setAwaitingEmployees((prev) => prev.concat(user));
		},
		[setAwaitingEmployees],
	);

	const addEmployee = useCallback(
		(employee) => {
			setAwaitingEmployees((prev) =>
				prev.filter((emp) => emp.id !== employee.id),
			);
			setAddedEmployees((prev) => prev.concat(employee));
		},
		[setAddedEmployees, setAwaitingEmployees],
	);

	const addAll = useCallback(() => {
		setAwaitingEmployees([]);
		setAddedEmployees((prev) => prev.concat(awaitingEmployees));
	}, [awaitingEmployees, setAwaitingEmployees, setAddedEmployees]);

	const removeEmployee = useCallback(
		(employee) => {
			setAwaitingEmployees((prev) => prev.concat(employee));
			setAddedEmployees((prev) =>
				prev.filter((emp) => emp.id !== employee.id),
			);
		},
		[setAwaitingEmployees, setAddedEmployees],
	);

	const removeAll = useCallback(() => {
		setAwaitingEmployees((prev) => prev.concat(addedEmployees));
		setAddedEmployees([]);
	}, [setAwaitingEmployees, setAddedEmployees, addedEmployees]);

	const onFinish = useCallback(() => {
		const data = {
			employees: addedEmployees,
		};

		submit(data);
	}, [submit, addedEmployees]);

	useEffect(() => {
		setAddedEmployees(data?.employees || []);
	}, [data?.employees]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			className="d-flex flex-column justify-content-between h-100"
		>
			<div className="row">
				<div className="col-lg-6 px-3 col-md-12 border-right">
					<Filters
						countries={countries}
						setCountries={setCountries}
						locations={locations}
						setLocations={setLocations}
						disabled={disabled}
					/>

					<div className="mb-2">
						<p className="h4 font-weight-bolder text-sm">
							{t("choose-employee")}
						</p>

						<EmployeeSelect
							locations={locations.map(({ id }) => id)}
							selected={selectedEmployees}
							onSelect={onSelectEmployee}
							disabled={disabled}
						/>

						<div className="text-right my-3">
							<Button
								className="m-0 border border-dark"
								onClick={disabled ? undefined : addAll}
								size="sm"
							>
								{t("add-all")}
							</Button>
						</div>
					</div>

					<div className="mb-2">
						<div className="mb-3">
							<p className="h4 font-weight-bolder text-sm">
								{t("no-pto-policy-assigned")}
							</p>
						</div>

						<p className="h4 font-weight-bolder text-sm">
							{t("other-pto-policies-assigened")}
						</p>
					</div>

					<div className="">
						{awaitingEmployees?.map((employee) => (
							<Employee
								key={employee.id}
								active={false}
								user={employee}
								onClick={addEmployee}
								disabled={disabled}
							/>
						))}
					</div>
				</div>

				<div className="col-lg-6 col-sm-12  px-2">
					<div className="text-right mb-2">
						<Button
							className="text-info font-weight-bolder px-4 py-2"
							onClick={removeAll}
							size="sm"
						>
							{t("remove-all")}
						</Button>
					</div>

					<div className="">
						{addedEmployees.map((employee) => (
							<Employee
								key={employee.id}
								active
								user={employee}
								onClick={removeEmployee}
								disabled={disabled || !isMasterCompany}
							/>
						))}
					</div>
				</div>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border"
					size="sm"
					onClick={goBack}
				>
					{t("back")}
				</Button>

				<Button
					type="submit"
					className="btn-round btn-icon shadow-none border px-3"
					color="dark"
					size="sm"
				>
					{t("continue")}
				</Button>
			</div>
		</Form>
	);
}

export default EnrollEmployees;
