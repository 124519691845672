import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../../../../../utils/api"
import { getErrorMessage } from "../../../../../utils/helpers/errors";

export const useGetUsers = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const { authGet } = useApi();

	const fetch = useCallback(async(id, params, controller) => {
		setLoading(true);
		try {
			const response = await authGet(`flyer/read/${id}`, {
				params,
				signal: controller?.signal
			});

			if(response){
				setData(response.result);
				setTotalItems(response.totalItems);
			}
		} catch (err) {
			getErrorMessage(err, t);
		} finally {
			setLoading(false);
		}
	}, [setLoading, setData, setTotalItems, authGet, t]);

	return { fetch, totalItems, data, loading };
}