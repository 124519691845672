import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import DateInput from "../../../../components/Inputs/DateInput";
import RangeDateInput from "./RangeDateInput";
import Select from "../../../../components/Inputs/Select";
import { useDateFilters } from "./useDateFilters";
import { getStartDate, getEndDateFromStart } from "./helpers";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../utils/helpers/settings";

const DateRangeFilters = ({ setHasChangedDateFilter, hiddenOption }) => {
	const { t } = useTranslation();

	const { mode, from, to, payGroup, payGroupPeriod, setFilters } =
		useDateFilters();

	const onDateChange = useCallback(
		(val) => {
			if (!mode) {
				return;
			}
			const value = moment(val);
			const data = {
				from: getStartDate(value, mode),
			};
			if (mode !== "custom") {
				data.to = getEndDateFromStart(value, mode);
			}
			setFilters(data);
			if (setHasChangedDateFilter) {
				setHasChangedDateFilter(true);
			}
		},
		[mode, setFilters, setHasChangedDateFilter],
	);

	const onWeekChange = useCallback(
		([value]) => {
			onDateChange(value);
			if (setHasChangedDateFilter) {
				setHasChangedDateFilter(true);
			}
		},
		[onDateChange, setHasChangedDateFilter],
	);

	const onRangeChange = useCallback(
		([from, to]) => {
			setFilters({ from: moment(from), to: moment(to) });
			if (setHasChangedDateFilter) {
				setHasChangedDateFilter(true);
			}
		},
		[setFilters, setHasChangedDateFilter],
	);

	const onPayGroupChange = useCallback(
		({ code }) => {
			setFilters({ payGroup: code });
		},
		[setFilters],
	);

	const onPeriodChange = useCallback(
		(value) => {
			setFilters({ payGroupPeriod: value });
		},
		[setFilters],
	);

	const onModeChange = useCallback(
		(mode) => {
			let from;
			let to;
			let payGroup;
			let payGroupPeriod;
			switch (mode) {
				case "date": {
					from = moment().toISOString(true);
					to = moment().toISOString(true);
					break;
				}
				case "week": {
					from = moment().startOf("week").toISOString(true);
					to = moment().endOf("week").toISOString(true);
					break;
				}
				case "month": {
					from = moment().startOf("month").toISOString(true);
					to = moment().endOf("month").toISOString(true);
					break;
				}
				case "custom": {
					from = undefined;
					to = undefined;
					break;
				}
				case "pay-group": {
					from = undefined;
					to = undefined;
					payGroup = undefined;
					payGroupPeriod = "current";
					break;
				}
				default: {
					from = undefined;
					to = undefined;
					break;
				}
			}
			setFilters({ mode, from, to, payGroup, payGroupPeriod });
			if (setHasChangedDateFilter) {
				setHasChangedDateFilter(true);
			}
		},
		[setFilters, setHasChangedDateFilter],
	);

	return (
		<Row className="d-flex align-items-center">
			<Col xs='12' md="auto" className="pr-0">
				<Select
					placeholder={t("mode")}
					value={mode}
					onChange={onModeChange}
					className="mr-1"
				>
					<Select.Option value="date">{t("date")}</Select.Option>
					<Select.Option value="week" >{t("week")}</Select.Option>
					<Select.Option value="month" hidden={hiddenOption}>{t("month")}</Select.Option>
					<Select.Option value="custom" hidden={hiddenOption}>{t("custom")}</Select.Option>
					<Select.Option value="pay-group" hidden={hiddenOption}>
						{t("pay-group")}
					</Select.Option>
				</Select>
			</Col>

			<Col xs='12' md="auto" className="px-1">
				{mode === "pay-group" ? (
					<>
						<div className="d-flex w-100">
							<ResourceSelect
								resourcePath="/pay-group?pagination=off"
								renderLabel={renderSettingsResourceLabel}
								hasSearch
								getSearchFilters={getSettingsResourceSearchFilters}
								onChange={onPayGroupChange}
								value={payGroup}
							/>

							<Select
								className="ml-1"
								value={payGroupPeriod}
								onChange={onPeriodChange}
							>
								<Select.Option value="past">{t("past")}</Select.Option>
								<Select.Option value="current">
									{t("current")}
								</Select.Option>
								<Select.Option value="next">{t("next")}</Select.Option>
							</Select>
						</div>

						<div className="d-flex h-100">
							<div className="align-items-center m-auto" style={{ minWidth: '170px', textAlign: 'center' }}>
								{from && to ? `${moment(from).format("MM/DD/YYYY")} - ${moment(to).format("MM/DD/YYYY")}` : ''}
							</div>
						</div>
					</>
				) : (
					<InputGroup className='input-group-alternative border shadow-none flex-nowrap'>
						{mode === "custom" ? (
							<RangeDateInput
								startDate={moment(from).toDate()}
								endDate={moment(to).toDate()}
								onChange={onRangeChange}
							/>
						) : mode === "week" ? (
							<DateInput
								startDate={moment(from).toDate()}
								endDate={moment(to).toDate()}
								onChange={onWeekChange}
								selectsRange
							/>
						) : (
							<DateInput
								value={moment(from).toDate()}
								showMonthYearPicker={mode === "month"}
								dateFormat={
									mode === "month" ? "yyyy-MM" : undefined
								}
								onChange={onDateChange}
							/>
						)}
						<InputGroupAddon addonType="append">
							<InputGroupText>
								<FontAwesomeIcon icon={faCalendar} />
							</InputGroupText>
						</InputGroupAddon>
					</InputGroup>
				)}
			</Col>
		</Row>
	);
};

export default DateRangeFilters;
