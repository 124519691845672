import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal";
import Form from "./Form";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import { useMyRequests } from "../api";
import { calculateFields } from "../../Scheduler/api/useSaveEvent";
import { prepareEventForDb } from "../../Scheduler/helpers/prepareEventForDb";
import { useUser } from "../../../utils/hooks/user";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { useCompany } from "../../../utils/hooks/company";
import "./styles.scss";

function AddSchedule({ isOpen, onClose, setData }) {
	const { t } = useTranslation();
	const user = useUser();
	const company = useCompany();
	const { ip, getIp } = useGetIpAddress();
	const { loading, error, create } = useMyRequests();

	const onFinish = useCallback((values) => {
		if (!user) {
			return;
		}

		const date = moment(values?.date).format("YYYY-MM-DD");
		let startDate = combineDateAndTime(
			moment(date),
			moment(values.startDate),
		);
		let endDate = combineDateAndTime(
			moment(date),
			moment(values.endDate),
		);
		if (startDate && endDate.isBefore(startDate)) {
			endDate.add(1, "day");
		}
		
		const calcFields = {
			...calculateFields(values),
			date,
			startDate: startDate.toISOString(true),
			endDate: endDate.toISOString(true),
			duration: endDate.diff(startDate, 'seconds'),
			durationUnit: 'second',
			company
		};

		const request = {
			user: user.id,
			type: "schedule",
			data: prepareEventForDb(calcFields),
			device: "web-application",
			ip: ip ? ip : "",
		}
		create(request, (response) => {
			onClose();
			setData((prev) => (prev || [])?.concat(response));
			toast.success(t("request-created-successfully"));
		})
	}, [user, company, ip, create, setData, onClose, t]);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);
		return () => controller.abort();
	}, [getIp]);

	return (
		<Modal 
			className="add-schedule-modal modal-dialog-centered modal-lg pb-0 shadow-none"
			title={t("add-schedule-request")}
			isOpen={isOpen}
			toggle={onClose}
			size="lg"
			scrollable
			centered
		>
			<Form 
				loading={loading}
				error={error}
				onClose={onClose}
				onFinish={onFinish}
			/>
		</Modal>
	)
}

export default AddSchedule;
