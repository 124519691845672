import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../Layout";
import Card from "../Layout/Card";
import SignUpForm from "./Form";
import Result from "./Result";

function SignUp() {
	const { t } = useTranslation();
	const [isFinished, setIsFinished] = useState(false);
	const [email, setEmail] = useState("");

	const cardBody = useMemo(() => {
		if (isFinished) {
			return <Result email={email} />;
		} else {
			return (
				<SignUpForm setIsFinished={setIsFinished} setEmail={setEmail} />
			);
		}
	}, [email, isFinished, setIsFinished, setEmail, t]);

	const cardTitle = useMemo(() => {
		if (isFinished) {
			return t("verify-email");
		} else {
			return t("sign-up");
		}
	}, [isFinished, t]);

	const cardText = useMemo(() => {
		if (isFinished) {
			return t("verify-email-description");
		} else {
			return t("app-and-running");
		}
	}, [isFinished, t]);

	const cardClassname = useMemo(
		() => (isFinished ? "text-center" : "text-start"),
		[isFinished]
	);

	return (
		<Layout
			image="/assets/images/landscape-1.jpg"
			text="No matter what people tell you, words and ideas can change the world."
			autor="Robin Williams"
		>
			<Card
				cardTitle={cardTitle}
				cardText={cardText}
				cardFooter="already-enjoying-simplitime"
				link="/login"
				linkText="go-to-domain"
				className={cardClassname}
				cardBody={cardBody}
			/>
		</Layout>
	);
}

export default SignUp;
