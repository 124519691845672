import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Topbar from "../../components/Topbar";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import { useNewTable } from "../../../../components/NTable/hook";
import { useManageForm } from "../../helpers/useManageForm";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import {useQueryFilters} from "../../helpers/useQueryFilters";
import {useImportModal} from "../../helpers/useImportModal";
import {useOnExport} from "../../helpers/useOnExport";
import {useVisibleColumnKeys} from "../../helpers/useVisibleColumnKeys";

function QrCode() {
	const { t } = useTranslation();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/qrCode");

	const { hasAccess: canAdd } = useAccess("qrCode.canCreate");

	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
        edit: useActionWithAccess(goToEdit, "qrCode.canCreate")
	});

    const table = useNewTable({
        data,
        columns,
        loading,
        totalItems,
        sessionFiltersKey: "qrCodes",
        enableRowSelection: false,
    });

    const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-qr-code"),
		create: t("add-qr-code"),
		edit: t("edit-qr-code"),
	});

	useFetchResource({ fetch, state: table.getState() });

    const visibleColumnKeys = useVisibleColumnKeys(table);

    const onExport = useOnExport({
        url: "/export/qr-code",
        exportCall: settingsExport,
        queryFilters,
        visibleColumnKeys
    });

    const { open: openImportModal, modal: importModal } = useImportModal({
        importUrl: "/qrCode/import",
        exportUrl: "/export/qr-code",
        setData,
        exportCall: settingsExport,
        loading: loading || loadingExport,
    });

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title="Qr codes"
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
                    <Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
                    />

                    <NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			{importModal}

			{unarchiveModal}
		</div>
	);
}

export default QrCode;
