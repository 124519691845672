import { useCallback, memo } from "react";
import moment from "moment-timezone";
import { Field } from "rc-field-form";
import { useWorkSummary } from "../store";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { getPaidHours } from "../../../../utils/helpers/shift";
import { useCompany } from "../../../../utils/hooks/company";

const formatData = (workSummary, { startDate, endDate, breaks }) => {
	const newWorkSummary = { ...workSummary };

	// const timezone = newWorkSummary.shifts.slice(-1)[0].location.timezoneValue;

	let _startDate = moment.parseZone(startDate);
	let _endDate = moment.parseZone(endDate);
	// if (timezone) {
	// 	_startDate = _startDate.tz(timezone, true);
	// 	_endDate = _endDate.tz(timezone, true);
	// }
	_startDate = _startDate.toISOString(true);
	_endDate = _endDate.toISOString(true);

	const lastShift = {
		...newWorkSummary.shifts.slice(-1)[0],
		startDate: _startDate,
		endDate: _endDate,
	};

	const clocks = [
		{
			mode: "START",
			type: "SHIFT",
			time: _startDate,
		},
	];

	breaks.forEach((b) => {
		if (!b || !b.start || !b.end) {
			return;
		}
		const { start, end } = b;
		clocks.push(
			{
				type: "BREAK",
				mode: "START",
				time: moment.parseZone(start).toISOString(true),
			},
			{
				type: "BREAK",
				mode: "END",
				time: moment.parseZone(end).toISOString(true),
			},
		);
	});

	clocks.push({
		mode: "END",
		type: "SHIFT",
		time: _endDate,
	});

	lastShift.clocks = clocks;
	newWorkSummary.shifts = [lastShift];
	return newWorkSummary;
};

function PaidHours() {
	const company = useCompany();
	const workSummary = useWorkSummary();

	const renderPaidHours = useCallback(
		(data) => {
			if (!workSummary || !data.startDate || !data.endDate) {
				return renderDurationAsFormat(0, company?.settings.timeFormat);
			}
			const ws = formatData(workSummary, data);
			return renderDurationAsFormat(
				getPaidHours(ws),
				company?.settings.timeFormat,
			);
		},
		[workSummary, company],
	);

	return (
		<Field shouldUpdate noStyle>
			{({}, {}, { getFieldsValue }) => {
				const data = getFieldsValue(["startDate", "endDate", "breaks"]);
				return <>{renderPaidHours(data)}</>;
			}}
		</Field>
	);
}

export default memo(PaidHours);
