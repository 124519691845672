import { useContext, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BaseForm, { Field as BaseField } from "rc-field-form";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Button from "../../../../components/Button";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import StatusField from "../../../../components/Field/StatusField";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import Checkbox from "../../../../components/Inputs/Checkbox";

function Form({ values, submit, error, close, loading }) {
	const { t } = useTranslation();
	const [form] = BaseForm.useForm();

	const { groups } = useContext(OrgLevelGroupsContext);

	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				timezone: values?.timezone?.id,
				location: values?.location?.id,
				project: values?.project?.id,
				hasMultipleProjects: values?.hasMultipleProjects
					? values?.hasMultipleProjects
					: false,
				otherProjects: values?.otherProjects?.map(({ id }) => id),
			};
			submit(data);
		},
		[submit],
	);

	const onHasMultipleProjects = useCallback(() => {
		form.setFieldsValue({ otherProjects: undefined })
	}, [form])

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			logOutAfter: values?.logOutAfter ? Number(values?.logOutAfter) : 20,
			...values,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"description",
				"status",
				"project",
				"otherProjects",
				"location",
				"timezone",
				"macAddress",
				"deviceMacAddress",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<BaseForm form={form} onFinish={onFinish}>
			<Field label={t("clock-name")} name="description">
				<Text placeholder={t("clock-name")} />
			</Field>

			<Field
				label={t("serial-number")}
				name="macAddress"
				rules={[
					{
						required: true,
						message: t("required-serial-number"),
					},
				]}
			>
				<Text placeholder={t("serial-number")} />
			</Field>

			<Field label={t("device-id")} name="deviceMacAddress">
				<Text placeholder={t("device-id")} />
			</Field>

			<Field label={t("location")} name="location">
				<ResourceSelect
					labelPropName="description"
					resourcePath="/locations"
					renderLabel={renderSettingsResourceLabel}
					placeholder={t("location")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
				/>
			</Field>

			<Field label={t("timezone")} name="timezone">
				<ResourceSelect
					labelPropName="text"
					resourcePath="/timezone?pagination=false"
					hasCompany={false}
					hasSearch
					placeholder={t("timezone")}
				/>
			</Field>

			{group && (
				<>
					<Field
						name="project"
						label={t("primary-group", {
							group: group.description
						})}
						rules={[
							{
								required: true,
								message: t(
									"required-primary-group", {
									group: group.description
								}
								),
							},
						]}
					>
						<ResourceSelect
							labelPropName="description"
							resourcePath={`${group.id}/org-levels`}
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							placeholder={group.code + " - " + group.description}
							hasSearch
						/>
					</Field>

					<Field name="hasMultipleProjects" valuePropName="checked">
						<Checkbox id="hasMultipleProjects">
							{t("has-multiple-project", {
								project: group.description,
							})}
						</Checkbox>
					</Field>

					<BaseField shouldUpdate>
						{({ }, { }, { getFieldValue }) => {
							const hasMultipleProjects = getFieldValue(
								"hasMultipleProjects",
							);

							return (
								hasMultipleProjects && (
									<Field
										name="otherProjects"
										label={group.description}
									>
										<ResourceSelect
											labelPropName="description"
											resourcePath={`${group.id}/org-levels`}
											renderLabel={
												renderOrgLevelResourceLabel
											}
											getSearchFilters={
												getSettingsOrgResourceSearchFilters
											}
											placeholder={
												group.code +
												" - " +
												group.description
											}
											mode="multiple"
											hasSearch
										/>
									</Field>
								)
							);
						}}
					</BaseField>
				</>
			)}

			<Field
				name="logOutAfter"
				label={t("log-out-after")}
				rules={[
					{
						required: true,
						message: t("required-log-out-after"),
					},
					{
						validator(_, value) {
							if (Number(value) < 0) {
								return Promise.reject(new Error(t("logout-after-pattern")))
							}
							return Promise.resolve()
						}
					},
				]}
			>
				<Text />
			</Field>

			<StatusField />

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				<Button
					type="submit"
					className="btn-dark btn-sm shadow-none"
					loading={loading}
				>
					{t("save")}
				</Button>
			</div>
		</BaseForm>
	);
}

export default Form;
