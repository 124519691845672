export default function getSqTranslate() {
    return ({
        everyText: 'çdo',
        emptyMonths: 'çdo muaj',
        emptyMonthDays: 'çdo ditë e muajit',
        emptyMonthDaysShort: 'ditë e muajit',
        emptyWeekDays: 'çdo ditë e javës',
        emptyWeekDaysShort: ' ditë e javës',
        emptyHours: 'çdo orë',
        emptyMinutes: 'çdo minutë',
        emptyMinutesForHourPeriod: 'çdo',
        yearOption: 'vit',
        monthOption: 'muaj',
        weekOption: 'javë',
        dayOption: 'ditë',
        hourOption: 'orë',
        minuteOption: 'minutë',
        prefixPeriod: 'Çdo',
        prefixMonths: 'në',
        prefixMonthDays: 'në',
        prefixWeekDays: 'në',
        prefixWeekDaysForMonthAndYearPeriod: 'dhe',
        prefixHours: 'në',
        prefixMinutes: ':',
        prefixMinutesForHourPeriod: 'në',
        suffixMinutesForHourPeriod: 'minutë(a)',
        errorInvalidCron: 'Shprehje jo e vlefshme',
        clearButtonText: 'Fshi',
        weekDays: 'E hënë_E martë_E mërkurë_E enjte_E premte_E shtunë_E dielë'.split('_'),
        months: 'Janar_Shkurt_Mars_Prill_Maj_Qershor_Korrik_Gusht_Shtator_Tetor_Nëntor_Dhjetor'.split(
            '_'
        ),
        // Order is important, the index will be used as value
        altWeekDays: 'Hën_Mar_Mër_Enj_Pre_Sht_Dje'.split('_'),
        // Order is important, the index will be used as value
        altMonths: 'Jan_Shku_Mar_Pri_Maj_Qer_Korr_Gush_Shta_Tet_Nën_Dhj'.split('_'),
    })
}