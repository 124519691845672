import { useCallback, useMemo } from "react";
import { useConfigurationModule } from "../../../../../../Settings/Configuration/hooks/useConfigurationModule";
import { normalize } from "../../../../../../../utils/helpers/normalize";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";

export const useGetRateConfigMax = () => {
  const rateConfig = useConfigurationModule("rate");
  const map = useMemo(() => {
    return normalize(rateConfig || [], "role");
  }, [rateConfig]);
  return useCallback((roleIds, field) => {
    return roleIds.reduce((total, role) => {
      const value = map[role]?.[field];
      if (!isNumber(value)) {
        return total;
      }
      if (!isNumber(total)) {
        return value;
      }
      if (value > total) {
        return value;
      }
      return total;
    }, undefined);
  }, [map]);
}

const appliesForValidation = (row) => {
  return row.payCode && row.payCode?.hourType !== "amount";
}

export const useRateValidations = () => {
  const { t } = useTranslation();
  const getMax = useGetRateConfigMax();

  const maxValue = useCallback((value, row) => {
    if (!appliesForValidation(row)) {
      return null;
    }
    const jobRate = row.job?.hourlyRate;
    const maxConfig = getMax(row.user.roles, "maxRate");
    let max = isNumber(maxConfig) ? maxConfig : undefined;
    if (isNumber(max) && isNumber(jobRate) && jobRate > max) {
      max = jobRate;
    }

    if (!isNumber(max) || !isNumber(value)) {
      return null;
    }

    if (value > max) {
      if (isNumber(jobRate)) {
        return t("timesheet-rate-smaller-than-max-rate-or-job-rate", { rate: max, jobRate });
      }
      return t("timesheet-rate-smaller-than-max-rate", { rate: jobRate });
    }
  }, [t, getMax]);

  return useMemo(() => [maxValue], [maxValue]);
}
