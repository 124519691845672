import { useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Actions from "./Actions";
import Topbar from "../../components/Table/Topbar";
import TopbarAction from "../../components/Table/Topbar/Action";
import Drawer from "../../components/Drawer";
import BulkActions from "./BulkActions";
import VerifyModal from "./VerifyModal";
import AssignGroupsToUsers from "./AssignGroupsToUsers";
import NTable from "../../components/NTable";
import { usePeopleApi, useVerifyUser } from "./api";
import { useColumns } from "./useColumns";
import { useDeleteModal } from "../Settings/helpers/useDeleteModal";
import { formatParams } from "../../utils/api/format";
import { OrgLevelGroupsContext } from "../Settings/General/OrganisationLevelGroups/context";
import { useVisible } from "../../utils/hooks/useVisible";
import { useNewTable } from "../../components/NTable/hook";
import { useInitialState } from "../../utils/hooks/useInitialState";
import { useVisibleColumnKeys } from "../Settings/helpers/useVisibleColumnKeys";
import { useQueryFilters } from "../Settings/helpers/useQueryFilters";
import { useImportModal } from "../Settings/helpers/useImportModal";
import { useOnExport } from "../Settings/helpers/useOnExport";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faFileExport, faFileImport, faPersonCircleQuestion, faPrint } from "@fortawesome/free-solid-svg-icons";

function People() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { groups } = useContext(OrgLevelGroupsContext);

	const {
		visible: isOpenVerifyModal,
		open: openVerifyModal,
		close: closeVerifyModal,
		selected: selectedUser,
	} = useVisible();

	const {
		visible: isDrawerOpen,
		open: openDrawer,
		close: closeDrawer,
	} = useVisible();

	const {
		visible: isOpenAssignModal,
		open: openAssignModal,
		close: closeAssignModal,
	} = useVisible();

	const {
		data,
		totalItems,
		loading,
		loadingExport,
		fetch,
		batchRemove,
		peopleExport,
		setData,
	} = usePeopleApi();

	const {
		loading: loadingVerify,
		verify,
		data: verifiedUser,
		setData: setVerifiedUser,
		setLoading: setVerifyLoading,
	} = useVerifyUser();

	const columns = useColumns({
		verify: openVerifyModal,
		loadingVerify,
	});

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		permission: "canManageUsers",
		message: (data) => t("do-you-want-to-delete-users", { count: data?.length }),
		onConfirm: (data) => batchRemove(data),
	});

	const table = useNewTable({
		data,
		columns,
		totalItems,
		loading,
		initialState: useInitialState(() => {
			const columnVisibility = {
				email: false,
				phone: false,
			};
			groups.forEach((group) => {
				if (group.allocatedBy) {
					return;
				}
				const level = group.level.replace("_", "");
				columnVisibility[level] = false;
			});
			const columnFilters = [
				{
					method: "contains",
					name: "employeeNumber",
					value: "",
				},
			];
			return { columnVisibility, columnFilters };
		}),
		sessionFiltersKey: "users",
	});

	const queryFilters = useQueryFilters(table);

	const fetchData = useCallback(() => {
		const controller = new AbortController();
		fetch(formatParams(queryFilters), controller);
	}, [fetch, queryFilters]);

	useEffect(() => {
		const controller = new AbortController();
		fetch(formatParams(queryFilters), controller);
		return () => controller.abort();
	}, [fetch, queryFilters]);

	const visibleColumnKeys = useVisibleColumnKeys(table);

	const onExport = useOnExport({
		url: "/export/users",
		exportCall: peopleExport,
		queryFilters,
		visibleColumnKeys
	});

	const { open: openImportModal, modal: importModal } = useImportModal({
		importUrl: "/users/import",
		exportUrl: "/export/users",
		setData,
		exportCall: peopleExport,
		loading: loading || loadingExport,
	});

	return (
		<div className="people d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar title={t("people")}>
					<TopbarAction
						id="add-employee"
						onClick={() => navigate("/people/add-employee")}
						loading={loadingExport}
						tooltip={t("add-employee")}
						disabled={loadingExport} 
						color="red"
					>
						<FontAwesomeIcon icon={faAdd} />
					</TopbarAction>

					<TopbarAction
						id="export"
						onClick={onExport}
						loading={loadingExport}
						tooltip={t("export")}
						disabled={loading || loadingExport}
						color="green"
					>
						<FontAwesomeIcon icon={faFileExport} />
					</TopbarAction>

					<TopbarAction
						id="import"
						onClick={openImportModal}
						loading={loadingExport}
						tooltip={t("import")}
						disabled={loadingExport}
						color="blue"
					>
						<FontAwesomeIcon icon={faFileImport} />
					</TopbarAction>

					<TopbarAction id="print" disabled loading={loadingExport} tooltip={t("print")} color="purple">
						<FontAwesomeIcon icon={faPrint} />
					</TopbarAction>

					<TopbarAction
						id="help"
						onClick={openDrawer}
						loading={loadingExport}
						tooltip={t("help")}
						disabled={loadingExport}
						color="orange"
					>
						<FontAwesomeIcon icon={faPersonCircleQuestion} />
					</TopbarAction>
				</Topbar>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100vh - 200px)"}}
				>
					<Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
					>
						<BulkActions
							rows={table.getState().rowSelection}
							batchRemove={openDeleteModal}
							assign={openAssignModal}
						/>
					</Actions>

					<NTable table={table} />
				</Card>
			</Card>

			<Drawer open={isDrawerOpen} close={closeDrawer} title={t("help")}>
				<>{t("help")}</>
			</Drawer>

			{importModal}

			{isOpenVerifyModal && (
				<VerifyModal
					isOpen={isOpenVerifyModal}
					user={selectedUser}
					verify={verify}
					close={closeVerifyModal}
					loading={loadingVerify}
					setVerifiedUser={setVerifiedUser}
					setLoading={setVerifyLoading}
					verifiedUser={verifiedUser}
					fetchData={fetchData}
				/>
			)}

			{isOpenAssignModal && (
				<AssignGroupsToUsers
					isOpen={isOpenAssignModal}
					users={table.getState().rowSelection}
					close={closeAssignModal}
					setData={setData}
				/>
			)}

			{deleteModal}
		</div>
	);
}

export default People;
