import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Field, useWatch } from "rc-field-form";
import { faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/Button";
import CustomTimeInput from "../../../components/Inputs/CustomTimeInput";
import { useValidations } from "./validations";
import DateInput from "../../../components/Inputs/DateInput";

function Break({ name, add, remove, form }) {
	const { t } = useTranslation();

	const values = useWatch();

	const validations = useValidations();

	useEffect(() => {
		if (
			values?.start?.date &&
			values?.end?.date &&
			moment(values?.start?.date).isSame(
				moment(values?.end?.date),
				"date",
			)
		) {
			form.setFieldValue(
				["breaks", name, "date"],
				moment(values?.start?.date).toDate(),
			);
		}
	}, [values, form]);

	return (
		<div className="d-flex flex-column">
			<div className="d-flex">
				<Field shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const startDate = getFieldValue(["start", "date"]);
						const endDate = getFieldValue(["end", "date"]);

						const disabled =
							startDate &&
								endDate &&
								moment(startDate).isSame(moment(endDate), "date")
								? true
								: false;

						return (
							<Field
								name={[name, "date"]}
								dependencies={[
									["start", "date"],
									["end", "date"],
								]}
								rules={[
									{
										required: true,
										message: t("required-date"),
									},
									() => ({
										validator(_, value) {
											if (startDate && endDate && value) {
												if (
													moment(value).isBefore(
														moment(startDate),
														"date",
													)
												) {
													return Promise.reject(
														new Error(
															t(
																"date-bigger-than-start-date",
															),
														),
													);
												} else if (
													moment(value).isAfter(
														moment(endDate),
														"date",
													)
												) {
													return Promise.reject(
														new Error(
															t(
																"date-smaller-than-end-date",
															),
														),
													);
												} else if (name > 0) {
													const date = getFieldValue([
														"breaks",
														name - 1,
														"date",
													]);

													if (
														moment(value).isBefore(
															moment(
																date,
																"date",
															),
														)
													) {
														return Promise.reject(
															new Error(
																t(
																	"current-date-after-previous-break",
																),
															),
														);
													}
												}

												return Promise.resolve();
											}
										},
									}),
								]}
								className="w-auto"
							>
								<DateInput
									disabled={disabled}
									placeholderText={t("date")}
									className="w-auto"
								/>
							</Field>
						);
					}}
				</Field>

				<Field
					name={[name, "start"]}
					dependencies={[
						"start",
						"startDate",
						"end",
						"endDate",
						name > 0 ? ["breaks", name - 1, "end"] : "",
					]}
					validateFirst
					rules={[
						{
							required: true,
							message: t("required-start"),
						},
						validations.beforeShiftStart.bind(
							null,
							t("start-time-before-shift-start"),
							name,
						),
						validations.afterShiftEnd.bind(
							null,
							t("end-time-before-shift-end"),
							name,
						),
						validations.breakStartAfterPrevEnd.bind(
							null,
							t("start-time-after-previous-break"),
							name,
						),
					]}
				>
					<CustomTimeInput />
				</Field>

				<Field
					name={[name, "end"]}
					dependencies={[
						"start",
						"startDate",
						"end",
						"endDate",
						["breaks", name, "start"],
					]}
					rules={[
						{
							required: true,
							message: t("required-end"),
						},
						validations.breakAfterStart.bind(
							null,
							t("end-time-bigger-than-start-time"),
							name,
						),
						validations.beforeShiftStart.bind(
							null,
							t("start-time-after-shift-start"),
							name,
						),
						validations.afterShiftEnd.bind(
							null,
							t("end-time-before-shift-end"),
							name,
						),
					]}
				>
					<CustomTimeInput />
				</Field>

				<Button onClick={add} className="mr-0">
					<FontAwesomeIcon icon={faPlus} />
				</Button>

				<Button onClick={remove} className="ml-0">
					<FontAwesomeIcon icon={faRemove} />
				</Button>
			</div>

			<Field shouldUpdate>
				{({ }, { }, { getFieldsError }) => {
					const errors = getFieldsError([
						["breaks", name, "date"],
						["breaks", name, "start"],
						["breaks", name, "end"],
					]);

					const error = errors[0]?.errors[0]
						? errors[0]?.errors[0]
						: errors[1]?.errors[0]
							? errors[1]?.errors[0]
							: errors[2]?.errors[0];

					return (
						error && (
							<div className="invalid-feedback d-block">
								{error}
							</div>
						)
					);
				}}
			</Field>
		</div>
	);
}

export default Break;
