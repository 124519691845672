import { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthentication } from "../context";
import useApi from "../../../utils/api";
import { useCompanyApi } from "../../../utils/api/company";
import { adaptMomentToCompany } from "../../../utils/helpers/adaptMomentToCompany";

export const useOnboardingStep = () => {
	const { company, user } = useAuthentication();

	return useMemo(() => {
		if (company?.settings?.businessIndustry) {
			return 4;
		} else if (user?.phone) {
			return 3;
		} else if (company?.logo) {
			return 2;
		} else if (company?.name) {
			return 1;
		} else {
			return 0;
		}
	}, [company, user]);
};

export const useOnboarding = () => {
	const { dispatch } = useAuthentication();
	const { authPut } = useApi();
	const navigate = useNavigate();

	const { company, user } = useAuthentication();

	const userStep = useOnboardingStep();

	const [step, setStep] = useState(userStep);
	const [values, setValues] = useState({
		name: company?.name || "",
		logo: company?.logo || "",
		phone: user?.phone || "",
		settings: {
			modules: company?.settings?.modules || [],
			themeColor: company?.settings?.themeColor || "",
			businessIndustry: company?.settings?.businessIndustry || "",
			employeeCount: company?.settings?.employeeCount || {
				min: 1,
				max: 10,
			},
		},
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { updateCompany } = useCompanyApi();

	const incrementStep = useCallback(() => {
		setStep((prev) => prev + 1);
	}, [setStep]);

	const goBack = useCallback(() => {
		setStep((prev) => prev - 1);
	}, [setStep]);

	const submit = useCallback(
		async ({ settings, ...rest }) => {
			if (!company) {
				return;
			}

			setLoading(true);
			setError(null);

			const { settings: prevSettings, ...prevValues } = values;
			const newValues = {
				...company,
				...prevValues,
				...rest,
				settings: {
					...company?.settings,
					...prevSettings,
					countries: company?.settings?.countries.map(({ id }) => id),
					timezone: company?.settings?.timezone?.id,
				},
			};
			Object.assign(newValues.settings, settings);

			setValues(newValues);

			try {
				const response = await updateCompany(company.id, newValues);

				if (response) {
					incrementStep();
					if (step === 3) {
						adaptMomentToCompany(response, user);
						dispatch({
							type: "set-company",
							payload: { company: response },
						});
						navigate("/");
					}
				}
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[
			values,
			incrementStep,
			company,
			updateCompany,
			dispatch,
			step,
			user,
			navigate,
			setLoading,
			setError,
			setValues,
		],
	);

	const submitPhoneNumber = useCallback(
		async (data) => {
			if (!user) {
				return;
			}

			setValues((prev) => ({ ...prev, ...data }));

			try {
				setLoading(true);
				setError(null);

				const response = await authPut("/profile", {
					data,
				});

				if (response) {
					incrementStep();
					dispatch({ type: "set-user", payload: { response } });
				}
			} catch (err) {
				setError(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			} finally {
				setLoading(false);
			}
		},
		[
			incrementStep,
			user,
			authPut,
			dispatch,
			setValues,
			setLoading,
			setError,
		],
	);

	return {
		step,
		values,
		loading,
		error,
		submit,
		submitPhoneNumber,
		skip: incrementStep,
		goBack,
	};
};
