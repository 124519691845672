import { useMemo } from "react";
import { useEarningGroupsContext } from "../../../earningGroupsContext"
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";

const getCellValue = (code, overall) => {
    const value = overall?.find((i) => i.earningGroup === code);

    if (value && value?.duration > 0) {
        return renderDurationAsFormat(value?.duration, "HH:mm")
    } else {
        return "--"
    }
}

const getFooterValue = (code, data) => {
    let total = 0;
    data?.forEach(({ overall }) => overall?.forEach((i) => {
        if (code === i.earningGroup) {
            total += i.duration
        }
    }))
    if (total > 0) {
        return renderDurationAsFormat(total, "HH:mm")
    } else {
        return "--"
    }
}

export const useEarningGroupsColumns = () => {
    const { data } = useEarningGroupsContext();

    return useMemo(() =>
        data?.map(({ code, id }) => ({
            Header: code,
            accessor: id,
            id,
            Cell: ({ row: { original } }) => {
                return getCellValue(id, original?.overall);
            },
            Footer: ({ data }) => getFooterValue(id, data)
        })) || []
        , [data]);
}
