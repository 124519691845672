import { useCallback } from "react";
import useApi from "../../../utils/api"
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useDeleteEvents = ({ isTeamScheduler }) => {
	const { t } = useTranslation();
	const { authDelete } = useApi();

	const remove = useCallback(async(id, onSuccess) => {
		try {
			const endpoint = isTeamScheduler ? `/schedules/${id}` : `/my/schedules/${id}`;
			await authDelete(endpoint);
			toast.success(t("event-deleted-successfully"))
			onSuccess?.();
		} catch (err) {
			getErrorMessage(err, t)
		}
	}, [isTeamScheduler, authDelete, t]);

	const removeUserEvents = useCallback(async(userId, data, onSuccess) => {
		try {
			const endpoint = isTeamScheduler ? `/schedules/user/${userId}` : "/my/schedules/user";
			const response = await authDelete(endpoint, { data });
			if (response) {
				const data = response?.filter(({ status }) => status);
				toast.success(t("events-deleted-successfully", { count: data?.length || 0 }));
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t)
		}
	}, [isTeamScheduler, authDelete, t]);

	const bulkRemove = useCallback(async(data, onSuccess) => {
		try {
			const endpoint = isTeamScheduler ? "/schedules/bulk" : "/my/schedules/bulk";
			const response = await authDelete(endpoint, { data });
			if (response) {
				const data = response?.filter(({ status }) => status);
				toast.success(t("events-deleted-successfully", { count: data?.length || 0 }));
				onSuccess?.();
			}
		} catch (err) {
			getErrorMessage(err, t)
		}
	}, [isTeamScheduler, authDelete, t]);

	return { remove, removeUserEvents, bulkRemove };
}