import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Form, { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import Field from "../../../../components/Field";
import DateInput from "../../../../components/Inputs/DateInput";
import Text from "../../../../components/Inputs/Text";
import Button from "../../../../components/Button";
import TimeInputs from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/TimeInputs";
import ObjectSelect from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/ObjectSelect";
import LevelSelect from "../../../TimeSheet/components/LevelSelect";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Shifts from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/Shifts";
import { PayCodesProvider } from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/PayCodeSelect/usePayCodes";
import { useUser } from "../../../../utils/hooks/user";
import { getPrimaryActiveResource } from "../../../../utils/helpers/user";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import { 
	getCommonJobs, 
	getCommonLocations 
} from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/helpers";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { 
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters
} from "../../../../utils/helpers/settings";
import { useAddedPayCode } from "../../../TimeSheet/components/TimeSheetTable/hooks/useAddedPayCode";
import { useEarningGroupsContext } from "../../../TimeSheet/earningGroupsContext";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../utils/helpers/date";
import { useCompany } from "../../../../utils/hooks/company";
import { getShifts } from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form";
import { useCalculatedShifts } from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/hooks/useCalculatedShifts";
import { 
	calculateShiftsWithAutomaticBreaks, 
	getAutomaticBreaks 
} from "../../../Scheduler/SchedulerComponent/EventEditDrawer/Drawer/Form/Shifts/helper";

function AddSchedule({ values, onClose, onFinish, error, loading, mode }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const user = useUser();
	const company = useCompany();
	const allShifts = Form.useWatch("shifts", form);
	const [hasChanges, setHasChanges] = useState(false);
	const [isAddedSegment, setIsAddedSegment] = useState(false);
	const { allocatedGroups } = useGroups();
	const defaultPayCode = useAddedPayCode(user);
	const { data: earningGroups } = useEarningGroupsContext();

	const primaryJob = useMemo(() => {
		return getPrimaryActiveResource(user?.jobs);
	}, [user?.jobs]);

	const primaryLocation = useMemo(() => {
		return getPrimaryActiveResource(user?.locations);
	}, [user?.locations]);

	const levels = useMemo(() => {
		const data = values?.data;
		return allocatedGroups?.reduce((total, group) => {
			const level = group?.level.replace("_", "");
			if (user && user[level]) {
				return { ...total, [level]: user[level] };
			} else if (data && data[level]) {
				return { ...total, [level]: data[level] };
			} else {
				return total;
			}
		}, {});
	}, [allocatedGroups, user, values]);

	const date = Form.useWatch("date", form);

	const defaultSegment = useMemo(() => {
		const scheduleSettings = user?.calculationGroup?.schedule;
		const companySettings = company?.settings?.schedule;
		const data = values?.data;

		const shiftStart = (scheduleSettings?.shiftStart && moment.parseZone(scheduleSettings?.shiftStart))
			|| (companySettings?.shiftStart && moment.parseZone(companySettings?.shiftStart))
			|| moment().set({ hour: 9, minute: 0, second: 0 });
		const shiftEnd = (scheduleSettings?.shiftEnd && moment.parseZone(scheduleSettings?.shiftEnd))
			|| (companySettings?.shiftEnd && moment.parseZone(companySettings?.shiftEnd))
			|| moment().set({ hour: 17, minute: 0, second: 0 });

		let start = moment.parseZone(shiftStart);
		let end = moment.parseZone(shiftEnd);

		if (data) {
			start = moment.parseZone(data?.startDate)
			start = moment.parseZone(data?.endDate)
		}

		start = combineDateAndTime(moment(date), moment(start));
		end = combineDateAndTime(moment(date), moment(end));

		const duration = moment(end).diff(moment(start), "seconds");

		return {
			type: "regular",
			payCode: defaultPayCode,
			location: primaryLocation,
			job: primaryJob,
			duration: renderDurationAsFormat(duration || 0, "HH:mm"),
			start,
			end,
			...levels
		}
	}, [
		defaultPayCode, 
		user?.calculationGroup?.schedule, 
		values?.data, 
		company?.settings?.schedule, 
		levels,
		primaryJob,
		primaryLocation,
		date
	]);

	const defaultEarningGroup = useMemo(() => {
		return earningGroups?.find((group) => group?.code === "REG");
	}, [earningGroups]);

	const jobs = useMemo(() => {
		if (!user) {
			return [];
		}
		return getCommonJobs([user]);
	}, [user]);

	const locations = useMemo(() => {
		if (!user) {
			return [];
		}
		return getCommonLocations([user]);
	}, [user]);

	const startDate = Form.useWatch("startDate", form);
	const endDate = Form.useWatch("endDate", form);
	const payCode = Form.useWatch("payCode", form);

	const shiftsWithAutomaticBreaks = useMemo(() => {
		let shifts = [defaultSegment];
		const breaks = getAutomaticBreaks(user?.calculationGroup?.breaks, shifts, defaultSegment);
		if (breaks?.length > 0) {
			shifts = calculateShiftsWithAutomaticBreaks(shifts, breaks);
		}
		return shifts;
	}, [defaultSegment, user?.calculationGroup?.breaks]);

	const segments = useMemo(() => {
		if (user?.calculationGroup?.breaks?.automatic?.status) {
			return shiftsWithAutomaticBreaks
		} else {
			return [defaultSegment];
		}
	}, [
		shiftsWithAutomaticBreaks, 
		defaultSegment, 
		user?.calculationGroup?.breaks?.automatic?.status
	]);

	const calculatedShifts = useCalculatedShifts({
		form,
		eventRecord: values?.data,
		events: [],
		defaultSegment,
		isCreating: !values?.data,
		segments
	})

	const onEarningGroupChange = useCallback((value) => {
		const duration = moment(endDate).diff(moment(startDate), "seconds");
		if (!value?.allowSegmentation && startDate && endDate) {
			const data = [{
				...defaultSegment,
				payCode,
				start: startDate,
				end: endDate,
				duration: renderDurationAsFormat(duration, "HH:mm")
			}];
			form.setFieldValue("duration", data[0]?.duration);
			form.setFieldValue("shifts", getShifts(data, defaultSegment));
		} else {
			const data = !values ? [{
				...defaultSegment,
				start: startDate,
				end: endDate,
				payCode: defaultPayCode,
				duration: renderDurationAsFormat(duration, "HH:mm")
			}]: calculatedShifts;
			form.setFieldValue("shifts", getShifts(data, defaultSegment));
		}
	}, [form, defaultPayCode, startDate, endDate, defaultSegment, payCode, calculatedShifts, values]);

	const calcShifts = useMemo(() => {
		const shifts = allShifts?.flat() || calculatedShifts;
		return getShifts(shifts, defaultSegment);
	}, [calculatedShifts, defaultSegment, allShifts]);

	const initialValues = useMemo(() => {
		const data = values?.data;
		const allShifts = calcShifts?.flat();
		const date = data?.date || data?.startDate;
		const startDate = data?.startDate
				? moment.parseZone(data?.startDate) 
				: allShifts?.[0]?.start 
					? moment.parseZone(allShifts[0]?.start) 
					: moment(defaultSegment?.start);
		const endDate = data?.endDate
				? moment.parseZone(data?.endDate)
				: allShifts?.[allShifts?.length - 1]?.end 
					? moment.parseZone(allShifts[allShifts?.length - 1]?.end) 
					: moment.parseZone(defaultSegment?.end);
		const duration = moment(endDate).diff(moment(startDate), "seconds");
		
		return { 
			resourceId: user?.id,
			job: allShifts?.[0]?.job || primaryJob,
			location: allShifts?.[0]?.location || primaryJob,
			date: date && moment(date).toDate(),
			startDate,
			endDate,
			duration: renderDurationAsFormat(duration, "HH:mm"),
			shifts: calcShifts,
			eventType: data?.eventType || defaultEarningGroup,
			payCode: !data?.eventType?.allowSegmentation && allShifts?.[0]?.payCode,
			note: data?.note,
			...levels
		};
	}, [
		form, 
		values?.data, 
		levels, 
		user?.id,
		primaryJob, 
		primaryLocation, 
		defaultEarningGroup, 
		calcShifts, 
		defaultSegment,
		calcShifts
	]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"startDate", 
				"endDate", 
				"date", 
				"location", 
				"job",
				"level1",
				"level2",
				"level3",
				"level4",
				"shifts"
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	useEffect(() => {
		const shifts = allShifts?.flat();
		if (shifts?.length > 0) {
			const startDate = combineDateAndTime(moment(date), shifts?.[0]?.start);
			const endDate = combineDateAndTime(moment(date), moment(shifts?.[shifts?.length - 1]?.end));
			form.setFieldValue("startDate", startDate);
			form.setFieldValue("endDate", endDate);
		}
	}, [allShifts, form]);

	const eventType = Form.useWatch("eventType", form);
	const location = Form.useWatch("location", form);
	const job = Form.useWatch("job", form);
	const level1 = Form.useWatch("level1", form);
	const level2 = Form.useWatch("level2", form);
	const level3 = Form.useWatch("level3", form);
	const level4 = Form.useWatch("level4", form);

	useEffect(() => {
		if (!eventType?.allowSegmentation && payCode && startDate && endDate) {
			const duration = moment(endDate).diff(moment(startDate), "seconds");
			const data = [{
				...defaultSegment,
				payCode,
				start: startDate,
				end: endDate,
				level1,
				level2,
				level3,
				level4,
				location,
				job,
				duration: renderDurationAsFormat(duration, "HH:mm")
			}];
			form.setFieldValue("shifts", getShifts(data, defaultSegment));
		}
	}, [
		eventType?.allowSegmentation, 
		payCode, 
		defaultSegment, 
		startDate, 
		endDate,
		location,
		job,
		level1,
		level2,
		level3,
		level4,
		form
	]);

	return (
		<PayCodesProvider>
			<Form 
				form={form} 
				onFinish={onFinish}
				initialValues={initialValues}
				className="h-100"
			>
				<div 
					className="p-4"
					style={{ 
						height: "calc(100% - 70px)", 
						overflowY: "auto", 
						overflowX: "hidden" 
					}}
				>
					<Field
						className="w-100 d-flex flex-column"
						name="date"
						label={t("shift-date")}
						rules={[
							{
									required: true,
									message: t("required-start-date"),
								},
							]}
					>
						<DateInput className="w-100" disabled={!!mode} />
					</Field>

					<Field
						name="resourceId"
						hidden={true}
					>
						<Text />
					</Field>

					{!eventType?.allowSegmentation && (
						<BaseField name="shifts" />
					)}
					
					<TimeInputs 
						form={form}
						automaticBreaks={user?.calculationGroup?.breaks?.automatic?.status}
						setHasChanges={setHasChanges}
						defaultSegment={defaultSegment}
						disabled={!!mode}
						setIsAddedSegment={setIsAddedSegment}
					/>

					<Field
						name="eventType"
						label={t("event-type")}
						rules={[
							{
								required: true,
								message: t("required-event-type")
							}
						]}
					>
						<ResourceSelect
							labelPropName="description"
							resourcePath="/earning-group?forSchedule=true"
							mode="single"
							renderLabel={renderSettingsResourceLabel}
							getSearchFilters={getSettingsResourceSearchFilters}
							valuePropName="id"
							hasSearch
							onChange={onEarningGroupChange}
							disabled={!!mode}
						/>
					</Field>

					<BaseField shouldUpdate>
						{({ }, { }, { getFieldValue }) => {
							const eType = getFieldValue("eventType");
							const filter = `filter[earningGroup][method]=exact
								&filter[earningGroup][value][]=${eType?.id}
								&forSchedule=true`;
							return !eType?.allowSegmentation && (
								<Field
									name="payCode"
									label={t("pay-code")}
									rules={[
										{
											required: true,
											message: t("required-pay-code")
										}
									]}
								>
									<ResourceSelect
										labelPropName="description"
										renderLabel={renderSettingsResourceLabel}
										valuePropName="code"
										resourcePath={`/pay-code?${filter}`}
										placeholder={t("pay-code")}
										hasSearch
										getSearchFilters={getSettingsResourceSearchFilters}
									/>
								</Field>
							)
						}}
					</BaseField>

					{(locations.length > 0 && !eventType?.allowSegmentation) && (
						<Field
							name="location"
							label={t("location")}
							rules={[
								{
									required: true,
									message: t("required-location"),
								},
							]}
						>
							<ObjectSelect propValue="id" options={locations} disabled={!!mode} />
						</Field>
					)}

					{(jobs.length > 0 && !eventType?.allowSegmentation) && (
						<Field
							name="job"
							label={t("job")}
							rules={[
								{
									required: true,
									message: t("required-job"),
								},
							]}
						>
							<ObjectSelect propValue="id" options={jobs} disabled={!!mode} />
						</Field>
					)}

					{!eventType?.allowSegmentation && allocatedGroups?.map((group) => (
						<BaseField shouldUpdate>
							{({ }, { }, { getFieldValue }) => {
								const location = getFieldValue("location");
								return (
									<Field
										key={group.id}
										name={group?.level?.replace("_", "")}
										label={renderOrgLevelResourceLabel(group)}
										rules={[
											{
												required: group?.isRequired,
												message: t("required-group", {
													group: renderOrgLevelResourceLabel(group)
												})
											}
										]}
									>
										<LevelSelect
											group={group.id}
											location={location}
											allowClear={!group.isRequired}
											disabled={!!mode}
										/>
									</Field>
								);
							}}
						</BaseField>
					))}

					<BaseField shouldUpdate>
						{({}, {}, { getFieldValue }) => {
							const startDate = getFieldValue("startDate");

							return (eventType?.allowSegmentation && startDate) && (
								<Shifts 
									form={form}
									resource={user}
									disabled={!!mode}
									eventRecord={values}
									// setHasChanges={setHasChanges}
									// setIsAddedSegment={setIsAddedSegment}
									defaultSegment={defaultSegment}
								/>
						)}}
					</BaseField>

					<div className="my-4">
						<Field
							name="note"
							label={t("attach-request-note")}
							className="h-75"
						>
							<Input
								id="exampleFormControlTextarea3"
								resize="none"
								rows="7"
								type="textarea"
								className="h-100 "
								disabled={!!mode}
							/>
						</Field>
						<footer className="note-border  p-2 border-top-0 text-sm">
							*{t(
								"requests-send-for-manager-approval"
							)}
						</footer>
					</div>
					{/* <Segments
						form={form}
						resource={user}
						eventRecord={values}
						defaultSegment={defaultSegment}
						segments={segments}
						setSegments={setSegments}
						setHasChanges={setHasChanges}
						disabled={!!mode}
						setIsAddedSegment={setIsAddedSegment}
					/> */}
				</div>

				{!mode && ( 
					<div className="border-top py-3 text-center align-items-center justify-content-center">
						<Button
							className="btn-icon text-muted"
							type="button"
							color="white"
							onClick={onClose}
							loading={loading}
						>
							<span className="btn-inner--icon">
								<FontAwesomeIcon icon={faCircleXmark} />
							</span>
							<span className="btn-inner--text">{t("cancel")}</span>
						</Button>

						<Button
							className="btn-icon text-info"
							type="submit"
							color="white"
							disabled={loading}
						>
							<span className="btn-inner--icon">
								<FontAwesomeIcon icon={faCircleCheck} />
							</span>

							<span className="btn-inner--text">
								{t("send-for-approval")}
							</span>
						</Button>
					</div>
				)}
			</Form>
		</PayCodesProvider>
	)
}

export default AddSchedule;
