import { createContext, useReducer, useContext, useMemo } from "react";
import { isMainSubDomain } from "../../utils/helpers/url";
import { isPointInsidePolygon } from "./helpers";

export const QrCodeClockinContext = createContext();

const initialState = (() => {
	const isMainDomain = isMainSubDomain();
	const domain =
		process.env.REACT_APP_IS_LOCAL === "true"
			? process.env.REACT_APP_LOCAL_COMPANY
			: window.location.hostname;

	return {
		isMainDomain,
		domain,
		hasValidatedDomain: isMainDomain ? true : false,
		domainValid: isMainDomain ? true : false,
		validatingDomain: false,

		hasAuthenticated: false,
		authenticated: false,
		authenticating: false,

		fetchingArea: false,
		area: null,

		hasValidatedLocation: false,
		isValidatingLocation: false,
		// TODO: this has to change, i don't like keeping single errors on context
		// that navigate between multiple routes, the error might display in another page
		// this way, u have to do a tidious job of cleaning up errors while navigating around the app
		validatingLocationError: null,
		qrCode: null,

		hasCheckedPosition: false,
		isGeolocationEnabled: false,
		position: null,

		token: localStorage.getItem("QR_CODE_CLOCK_IN_TOKEN"),
		user: null,
		company: null,
		mainCompany: null,

		shift: null,
		clockMode: null,

		flyers: [],
		attestation: [],
		fetchingFlyers: false,
		fetchingFlyersError: null,
		updateFlyerError: null,
		updateFlyerLoading: false
	};
})();

export const qrCodeClockinReducer = (state, action) => {
	switch (action.type) {
		case "validate-domain": {
			return {
				...state,
				domainValid: false,
				validatingDomain: true,
			};
		}
		case "validate-domain-succeeded": {
			return {
				...state,
				domainValid: true,
				validatingDomain: false,
				hasValidatedDomain: true,
				companyName: action.payload,
			};
		}
		case "validate-domain-failed": {
			return {
				...state,
				domainValid: false,
				validatingDomain: false,
				hasValidatedDomain: true,
			};
		}
		case "validate-domain-cancel": {
			return {
				...state,
				domainValid: initialState.domainValid,
				validatingDomain: initialState.validatingDomain,
				hasValidatedDomain: initialState.hasValidatedDomain,
			};
		}
		case "authenticate": {
			return {
				...state,
				authenticated: false,
				authenticating: true,
			};
		}
		case "authenticate-succeeded": {
			return {
				...state,
				authenticated: true,
				authenticating: false,
				hasAuthenticated: true,
				user: action.payload.user,
				accessMap: action.payload.user.permissions,
				company: action.payload.company,
			};
		}
		case "authenticate-failed": {
			return {
				...state,
				authenticated: false,
				authenticating: false,
				hasAuthenticated: true,
				token: null,
				accessMap: null,
				company: null,
			};
		}
		case "authenticate-cancel": {
			return {
				...state,
				authenticated: initialState.authenticated,
				authenticating: initialState.authenticating,
				hasAuthenticated: initialState.hasAuthenticated,
				user: initialState.user,
				accessMap: initialState.accessMap,
				company: initialState.company,
			};
		}
		case "validate-location": {
			return {
				...state,
				isValidatingLocation: true,
				validatingLocationError: null,
			};
		}
		case "get-area": {
			return {
				...state,
				fetchingArea: true,
				area: null,
			};
		}
		case "get-area-success": {
			return {
				...state,
				fetchingArea: false,
				area: action.payload.area,
			};
		}
		case "get-area-failure": {
			return {
				...state,
				fetchingArea: false,
				area: null,
			};
		}
		case "cancel-get-area": {
			return {
				...state,
				fetchingArea: initialState.fetchingArea,
				area: initialState.area,
			};
		}
		case "validate-location-success": {
			return {
				...state,
				hasValidatedLocation: true,
				isValidatingLocation: false,
				validatingLocationError: null,
				qrCode: action.payload.qrCode,
			};
		}
		case "validate-location-failed": {
			return {
				...state,
				hasValidatedLocation: true,
				isValidatingLocation: false,
				validatingLocationError: action.payload.error,
			};
		}
		case "validate-location-cancel": {
			return {
				...state,
				hasValidatedLocation: initialState.hasValidatedLocation,
				isValidatingLocation: initialState.isValidatingLocation,
				validatingLocationError: initialState.validatingLocationError,
				qrCode: initialState.qrCode,
			};
		}
		case "set-position": {
			return {
				...state,
				hasCheckedPosition: true,
				position: action.payload.position,
				isGeolocationEnabled: action.payload.isGeolocationEnabled,
			};
		}
		case "set-clock-mode": {
			return {
				...state,
				clockMode: action.payload.clockMode,
			};
		}
		case "login": {
			return {
				...state,
				user: action.payload.user,
				accessMap: action.payload.user.permissions,
				token: action.payload.token,
				company: action.payload.company,
				authenticated: true,
				hasAuthenticated: true,
			};
		}
		case "log-out": {
			return {
				...state,
				token: null,
				user: initialState.user,
				company: initialState.company,
				mainCompany: initialState.mainCompany,
			};
		}
		case "reset-for-validation": {
			return {
				...state,
				fetchingArea: initialState.fetchingArea,
				area: initialState.area,

				hasValidatedLocation: initialState.hasValidatedLocation,
				isValidatingLocation: initialState.isValidatingLocation,
				validatingLocationError: initialState.validatingLocationError,
				qrCode: initialState.qrCode,

				shift: initialState.shift,
				clockMode: initialState.clockMode,
			};
		}
		case "fetch-flyers-request": {
			return {
				...state,
				fetchingFlyers: true,
				fetchingFlyersError: null,
			}
		}
		case "fetch-flyers-success": {
			return {
				...state,
				fetchingFlyers: false,
				fetchingFlyersError: null,
				flyers: action.payload.flyers
			}
		}
		case "fetch-flyers-failed": {
			return {
				...state,
				fetchingFlyers: false,
				fetchingFlyersError: action.payload.error,
			}
		}
		case "update-flyer-request": {
			return {
				...state,
				updateFlyerLoading: true,
				updateFlyerError: null
			}
		}
		case "update-flyer-success": {
			return {
				...state,
				updateFlyerLoading: false,
				updateFlyerError: null,
				flyers: state?.flyers?.filter((flyer) =>
					flyer?.id !== action?.payload?.flyerId
				)
			}
		}
		case "update-flyer-failed": {
			return {
				...state,
				updateFlyerLoading: false,
				updateFlyerError: action.payload.error
			}
		}
		case "fetch-attestation-request": {
			return {
				...state,
				fetchingAttestation: true,
				fetchingAttestationError: null,
			}
		}
		case "fetch-attestation-success": {
			return {
				...state,
				fetchingAttestation: false,
				fetchingAttestationError: null,
				attestations: action.payload.attestations
			}
		}
		case "fetch-attestation-failed": {
			return {
				...state,
				fetchingAttestation: false,
				fetchingAttestationError: action.payload.error,
			}
		}
		default: {
			throw new Error(
				`Authentication reducer case "${action.type}" not handled`,
			);
		}
	}
};

export function QrCodeClockinProvider({ children }) {
	const [state, dispatch] = useReducer(qrCodeClockinReducer, initialState);

	const pointInPolygon = useMemo(() => {
		if (!state.area || !state.position) {
			return false;
		}

		/*
		 * WORKAROUND: when area === [[null]], consider it as point in polygon
		 * then redirect to error page and consider the qr code with mistake
		 */
		if (state.area[0].length === 1 && state.area[0][0] === null) {
			return true;
		}

		const vertx = state.area[0]?.map((val) => val[0])?.slice(0, -1);
		const verty = state.area[0]?.map((val) => val[1])?.slice(0, -1);

		const inside = isPointInsidePolygon(
			state.area[0]?.length - 1,
			vertx,
			verty,
			state.position.lng,
			state.position.lat,
		);

		return inside;
	}, [state.area, state.position]);

	const contextValue = useMemo(
		() => ({
			...state,
			isPointInsidePolygon: pointInPolygon,
			dispatch,
		}),
		[state, pointInPolygon, dispatch],
	);

	return (
		<QrCodeClockinContext.Provider value={contextValue}>
			{children}
		</QrCodeClockinContext.Provider>
	);
}

export const useQrCodeClockIn = () => {
	const context = useContext(QrCodeClockinContext);
	if (context === undefined) {
		throw new Error("useQrCodeClockIn should be used within a provider");
	}
	return context;
};
