import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useApi from "../../../utils/api"
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useUser } from "../../../utils/hooks/user";
import { useCompany } from "../../../utils/hooks/company";

export const useValidate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { authPost } = useApi();
    const user = useUser();
    const company = useCompany();

    const submit = useCallback(async (data) => {
        setLoading(true);
        try {
            const response = await authPost("/profile/validate", {
                data,
                headers: {
                    Company: user.companies[0].id,
                    MainCompany: user.mainCompany,
                }
            });
            if (response) {
                toast.success(t("successfully-validated"));
                localStorage.setItem("OTP_VALIDATE", "true");
                if (!company) {
                    navigate("/company-selection");
                } else {
                    navigate("/")
                }
            }
        } catch (err) {
            setError(err);
            if (typeof err === "string") {
                getErrorMessage(err, t)
            }
        } finally {
            setLoading(false);
        }
    }, [user.companies, user.mainCompany, company, setLoading, setError, authPost, navigate, t]);

    return { submit, loading, error }
}