import debounce from "lodash/debounce";
import { useCallback, useMemo, useState } from "react";
import { SEARCH_DEBOUNCE_WAIT } from "../../../components/Inputs/ResourceSelect";
import useSWR from "swr";
import {
    getSettingsOrgResourceSearchFilters,
    renderOrgLevelResourceLabel,
    renderSettingsResourceLabel,
} from "../../../utils/helpers/settings";
import Select from "../../../components/Inputs/Select";
import { useCompanyId } from "../../../utils/hooks/company";
import axios from "axios";
import useApi from "../../../utils/api";

function LevelSelect({ group, value, onChange, location, payGroup, union, ...props }) {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const onDropdownVisibleChange = useCallback((open) => {
        if (!open) {
            setSearch("");
        }
        setOpen(open);
    }, [setOpen, setSearch]);

    const companyId = useCompanyId();
    const { authGet } = useApi();
    const { data, isLoading } = useSWR(
        useMemo(() => {
            if (!companyId || !group) {
                return null;
            }
            let searchParams = {};
            if (search) {
                searchParams = getSettingsOrgResourceSearchFilters(search);
            }
            const url = axios.getUri({
              url: `/${group}/org-levels`,
              params: {
                company: companyId,
                fields: [],
                  ...searchParams,
                  "withManageAbility[company]": companyId,
                  "withManageAbility[payGroup]": payGroup,
                  "withManageAbility[union]": union,
              }
            });
      return url;
        }, [search, companyId, group, payGroup, union]),
        useCallback((url) => authGet(url), [authGet]),
        { dedupingInterval: 100000 },
    );
    const hasMore = useMemo(() => {
        if (!data) {
            return false;
        }
        return data.totalItems > data.result.length || search !== '';
    }, [data, search]);

    const searchProps = useMemo(() => {
        if (hasMore === false) {
            return {
                showSearch: true,
                optionFilterProp: "children",
            };
        }

        const onSearch = debounce((value) => setSearch(value), SEARCH_DEBOUNCE_WAIT);

        return { showSearch: true, filterOption: false, onSearch };
    }, [hasMore, setSearch]);

    const options = useMemo(() => {
        if (search) {
            return data?.result || [];
        }
        return data?.result?.filter(({ code }) => code !== value?.code) || [];
    }, [search, data, value?.code]);

    const componentValue = value?.code;

    const componentOnChange = useCallback((_, option) => {
        onChange(option?.option);
    }, [onChange]);

    return (
        <Select
            {...props}
            {...searchProps}
            value={componentValue}
            onChange={componentOnChange}
            loading={isLoading}
            dropdownMatchSelectWidth={false}
            optionLabelProp="description"
            open={open}
            onDropdownVisibleChange={onDropdownVisibleChange}
        >
            {!search && value && (
                <Select.Option
                    value={value.code}
                    option={value}
                    description={value.description}
                >
                    {renderOrgLevelResourceLabel(value)}
                </Select.Option>
            )}

            {options?.map((option) => (
                <Select.Option
                    key={option.code}
                    value={option.code}
                    option={option}
                    description={option.description}
                >
                    {option.glSegment
                        ? `${option.glSegment}-${option.description}`
                        : renderSettingsResourceLabel(option)}
                </Select.Option>
            ))}
        </Select>
    );
}

export default LevelSelect;
