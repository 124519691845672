import { useCallback, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { currencyFormatter } from "../../../../../../utils/helpers/currencyFormatter";
import { useCompanyCurrency, useCompanyTimeFormat } from "../../../../../../utils/hooks/company";
import Table from "./Table";

export const getWeekTotal = (week) =>
	Object
		.values(week.data)
		.reduce((total, item) => ({
			duration: total.duration + item.duration,
			total: total.total + item.total,
		}), { duration: 0, total: 0 });

function Week({ week, isSameDay }) {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
	const currency = useCompanyCurrency();

	const summary = useCallback(() => {
		const { duration, total } = Object
			.values(week.data)
			.reduce((total, item) => ({
				duration: total.duration + item.duration,
				total: total.total + item.total,
			}), { duration: 0, total: 0 });
		return (
			<div>
				<div index={0} className="text-cyan-800">
					{t("total")}
				</div>

				<div index={1} className="text-cyan-800">
					{renderDurationAsFormat(duration, timeFormat)}
				</div>

				<div index={2} className="text-cyan-800">
					{currencyFormatter(total || 0, 2, currency)}
				</div>
			</div>
		);
	}, [week, timeFormat, currency, t]);

	const data = useMemo(
		() => Object.values(week.data),
		[week.data],
	);

	return (
		<div className='timeline-block'>
			<span className='timeline-step badge-info' />

			<div className='timeline-content mw-100'>
				{!isSameDay && (
					<small className='text-muted font-weight-bold mb-2'>
						{t("weekly-summary")}:

						{" "}

						{!moment(week.startDate).isSame(week.endDate, "date") &&
							moment(week.startDate).format("DD to ")}
						{moment(week.endDate).format("DD MMM, YYYY")}
					</small>
				)}

				<Table data={data} />
			</div>
		</div>
	);
}

export default Week;
