
import { beforedragcreate } from './beforedragcreate';
import BeforeEventAdd from './beforeeventadd';
import { saveSchedule } from './saveSchedule';
import BeforeEventDropFinalize from './BeforeEventDropFinalize';
import EventResizeEnd from './EventResizeEnd';
import { eventSelectionChange } from './eventSelectionChange';
import { afterEventDrop } from './afterEventDrop';

export const catchAllEvents = (event, {
  saveEventOnDb,
  company,
  allocatedGroups,
  openDeleteModal: beforeEventDelete,
  setSelectedEvents,
  defaultPayCode,
}) => {
  switch (event.type) {
    case "beforedragcreate":
      return beforedragcreate(event);
    case "beforeeventdelete":
      beforeEventDelete({ event, type: "cell" });
      return false;
    case "eventresizeend":
      return EventResizeEnd(event, allocatedGroups, defaultPayCode, saveEventOnDb);
    case "aftereventsave":
      return saveSchedule(event, saveEventOnDb);
    case "aftereventdrop": 
      return afterEventDrop(event, saveEventOnDb);
    case "beforeeventdropfinalize":
      return BeforeEventDropFinalize(event || {}, allocatedGroups);
    case "beforeeventadd":
      return BeforeEventAdd(event || {}, company);
    case "eventselectionchange":
     return eventSelectionChange(event, setSelectedEvents);

    // case "eventcontextmenuitem":
    //   if (event.item.name === "deleteEvent")
    //     console.log('deleteEvent');
    //   return;
    // case "beforeeventdelete":
    //   console.log('deleteEvent');
    //   return;
    // case "beforeeventedit":

    //   return true;
    // case "beforeeventeditshow":
      
    //   break;
    // case "eventselectionchange":
      
    //   return;
    // case "cellcontextmenubeforeshow":
      
    //   return;
    // case "beforeeventresize":
    // case "eventcontextmenubeforeshow":
    //   return !event.eventRecord.resource.data.terminatedStatus;
    default:
      // if (event.type.split('before').length > 1) { console.log(event.type) };
  }
}
