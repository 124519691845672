import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { firstToUpper } from "../../../../utils/helpers/string";
import { Tooltip } from "reactstrap";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { createColumnHelper } from "@tanstack/react-table";
import HourTypeSelect from "../../../../components/Inputs/HourTypeSelect";

export const renderHourType = (hourType) => {
	if (hourType === "pto") {
		return "PTO";
	}
	return hourType
		? firstToUpper(hourType.replaceAll("-", " ").toLowerCase())
		: "";
};

const columnHelper = createColumnHelper();

const AllocationResources = ({
	info,
	getTooltipId,
	renderTooltip,
	renderResource,
}) => {
	const value = info.getValue();
	const { visible, toggle } = useVisible();
	const count = value?.length || 0;
	const tooltipId = getTooltipId(info.row.original.id);
	return (
		<div>
			<div id={tooltipId}>
				{renderTooltip(count)}
			</div>

			{count > 0 && (
				<Tooltip
					target={tooltipId}
					isOpen={visible}
					toggle={toggle}
				>
					<span className="text-sm font-weight-bolder px-2">
						{value.map(renderResource)}
					</span>
				</Tooltip>
			)}
		</div>
	);
}

const getAllocationResourceColumn = ({
	header,
	accessor,
	getTooltipId,
	renderTooltip,
	renderResource,
}) => {
	return columnHelper.accessor(accessor, {
		header,
		cell: (info) => {
			return (
				<AllocationResources
					info={info}
					getTooltipId={getTooltipId}
					renderTooltip={renderTooltip}
					renderResource={renderResource}
				/>
			);
		},
	});
}

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const { code, description, status, actions } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
			code,
			description,
			status,
			columnHelper.accessor("earningGroup", {
				enableColumnFilter: true,
				header: t("earning-group"),
				cell: (info) => {
					const value = info.getValue()
					return value && renderSettingsResourceLabel(value);
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/earning-group?pagination=false"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						valuePropName="id"
						hasSearch
					/>
				),
			}),
			columnHelper.accessor("hourType", {
				enableColumnFilter: true,
				header: t("hour-type"),
				cell: (info) => renderHourType(info.getValue()),
				filterType: "select",
				Filter: (
					<HourTypeSelect />
				),
			}),
			columnHelper.accessor("multiplier", {
				header: t("multiplier"),
			}),
			columnHelper.accessor("defaultRate", {
				header: t("default-rate"),
			}),
			columnHelper.accessor("color", {
				header: t("color"),
				cell: (info) => (
					<span
						style={{
							backgroundColor: info.getValue(),
							borderRadius: "10px",
							display: "inline-block",
							border: "1px solid #dee2e6",
							height: "12px",
							width: "12px",
						}}
					/>
				),
			}),
			columnHelper.accessor("allowedAllocationType", {
				header: t("allowed-allocation-type"),
				cell: (info) => {
					const value = info.getValue();
					return value ? t(value) : t("none");
				},
			}),
			columnHelper.accessor("includeOrExcludeAllocated", {
				header: t("include-or-exclude-allocated"),
				cell: (info) => {
					const { allowedAllocationType } = info.row.original;
					if (allowedAllocationType === null) {
						return t("none");
					}
					return info.getValue() ? t("include") : t("exclude");
				},
			}),
			getAllocationResourceColumn({
				header: t("allowed-companies"),
				accessor: "allowedCompanies",
				getTooltipId: (id) => `allowed-company-list-${id}`,
				renderTooltip: (count) => count === 1
					? t("count-company", { count })
					: t("count-companies", { count }),
				renderResource: ({ name }) => name,
			}),
			getAllocationResourceColumn({
				header: t("allowed-pay-groups"),
				accessor: "allowedPayGroups",
				getTooltipId: (id) => `allowed-pay-group-list-${id}`,
				renderTooltip: (count) => count === 1
					? t("count-pay-group", { count })
					: t("count-pay-groups", { count }),
				renderResource: renderSettingsResourceLabel,
			}),
			getAllocationResourceColumn({
				header: t("excluded-companies"),
				accessor: "excludedCompanies",
				getTooltipId: (id) => `excluded-company-list-${id}`,
				renderTooltip: (count) => count === 1
					? t("count-company", { count })
					: t("count-companies", { count }),
				renderResource: ({ name }) => name,
			}),
			getAllocationResourceColumn({
				header: t("excluded-pay-groups"),
				accessor: "excludedPayGroups",
				getTooltipId: (id) => `excluded-pay-group-list-${id}`,
				renderTooltip: (count) => count === 1
					? t("count-pay-group", { count })
					: t("count-pay-groups", { count }),
				renderResource: renderSettingsResourceLabel,
			}),
		];
		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [t, isMasterCompany, code, description, status, actions]);
};
