import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import { useUnarchiveModal } from "./useUnarchiveModal";

export const useManageForm = ({
    mode,
    selected,
    createSubmit,
    updateSubmit,
}) => {
    const { t } = useTranslation();

    const {
        modal: unarchiveModal,
        open: openUnarchiveModal
    } = useUnarchiveModal({
        message: useCallback(
            (item) => t(
                "archived-item",
                { code: item?.code }
            ),
            [t],
        ),
        onConfirm: useCallback((values) => {
            updateSubmit(values.id, values);
        }, [updateSubmit]),
    });

    const submit = useMemo(() => {
        let func;
        if (mode === "edit" && selected?.id) {
            func = updateSubmit.bind(null, selected?.id);
        } else {
            func = createSubmit;
        }
        return (values, onSuccess = noop, onFailure = noop) => {
            func(values, onSuccess, (e) => {
                if (typeof e === "string" && e.startsWith("Unarchive")) {
                    const id = e.replace("Unarchive:", "");
                    openUnarchiveModal({ id, ...values });
                }
                onFailure(e);
            });
        };
    }, [mode, selected?.id, updateSubmit, createSubmit, openUnarchiveModal]);

    const isFormOpen = useMemo(() => {
        return ["create", "edit", "clone"].includes(mode);
    }, [mode]);

    const formProps = useMemo(() => ({
        values: selected && mode === "clone"
            ? { ...selected, code: "" }
            : selected,
        submit,
        mode,
    }), [selected, mode, submit]);

    return { unarchiveModal, isFormOpen, formProps };
}
