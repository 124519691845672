import FormElement from "rc-field-form";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.payCode");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				earningGroup: values.earningGroup ? values.earningGroup.id : null,
				multiplier: Number(values.multiplier),
				defaultRate: Number(values.defaultRate),
				allowedCompanies:
					values.allowedCompanies?.map(({ id }) => id) || [],
				allowedPayGroups:
					values.allowedPayGroups?.map(({ id }) => id) || [],
				excludedCompanies:
					values.excludedCompanies?.map(({ id }) => id) || [],
				excludedPayGroups:
					values.excludedPayGroups?.map(({ id }) => id) || [],
				inputDurationAsNumber: values.inputDurationAsNumber || false,
				forSchedule: values.forSchedule || false,
				payCode: values?.payCode && values.payCode.id,
			};
			submit(data);
		},
		[submit],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			forBreak: values?.forBreak ? values.forBreak : false,
			forSchedule: values?.forSchedule ? values.forSchedule : false,
			color: values?.color ? values.color : "#010a63",
			forAbsence: values?.forAbsence ? values.forAbsence : false,
			multiplier: values?.multiplier?.toFixed(2),
			defaultRate: values?.defaultRate?.toFixed(2),
			allowedAllocationType: values ? values?.allowedAllocationType : null,
			includeOrExcludeAllocated: values ? values?.includeOrExcludeAllocated : false,
			allowedCompanies: values?.allowedCompanies || [],
			allowedPayGroups: values?.allowedPayGroups || [],
			excludedCompanies: values?.excludedCompanies || [],
			excludedPayGroups: values?.excludedPayGroups || [],
			...values,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"code",
				"description",
				"status",
				"hourType",
				"multiplier",
				"defaultRate",
				"color",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Content
					form={form}
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
