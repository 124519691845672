import moment from "moment-timezone";
import { useMemo } from "react";
import { useDateFilters } from "../../../../../hooks/useDateFilters";

export const useDates = () => {
  const { from, to } = useDateFilters();
  return useMemo(() => {
    return new Array(
      moment(to).diff(from, "day") + 1
    ).fill(null).map((_, i) => {
      return moment(from).add(i, "day").format("YYYY-MM-DD");
    });
  }, [from, to]);
}