import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseForm from "rc-field-form";
import { Label } from "reactstrap";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import StatusField from "../../../../../components/Field/StatusField";
import Cron from "./Cron";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import {
	useCompanyTimeZone,
	useIsMasterCompany,
} from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = BaseForm.useForm();
	const [cronTimeConfig, setCronTimeConfig] = useState(
		values ? values?.cronTimeConfig : "* * * * *",
	);
	const timezone = useCompanyTimeZone();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.scheduling.jobs");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(formValues) => {
			const data = {
				type: "system-job",
				description: formValues?.description,
				config: formValues?.jobId,
				status: formValues?.status,
				cronTimeConfig,
			};

			submit(data);
		},
		[submit, cronTimeConfig, timezone],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			description: values?.description,
			jobId: values?.config,
			...values?.cronTimeConfig,
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], error);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<BaseForm
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Field
					name="description"
					label={t("description")}
					rules={[
						{
							required: true,
							message: t("required-description"),
						},
					]}
				>
					<Text placeholder={t("description")} disabled={disabled} />
				</Field>

				<Field
					name="jobId"
					label={t("job")}
					rules={[
						{
							required: true,
							message: t("required-job"),
						},
					]}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath="/scheduling/jobs"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						placeholder={t("job")}
						hasSearch
						disabled={disabled}
					/>
				</Field>

				<Label className="mb-1">{t("scheduled-on")}:</Label>

				<Cron
					cronTimeConfig={cronTimeConfig}
					setCronTimeConfig={setCronTimeConfig}
					disabled={disabled}
				/>

				<StatusField disabled={disabled} />
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</BaseForm>
	);
}

export default Form;
