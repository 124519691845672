import { useCallback } from "react";
import useSWR from "swr";
import { toast } from "react-toastify";
import useApi from "../../../utils/api";
import { getToastErrorMessage, isCancelError } from "../../../utils/helpers/errors";

export const useFetchTeamScheduleAnalytics = ({ filters, startDate, endDate }) => {
    const { authGet } = useApi();

	const fetch = useCallback(
		async ([key, params]) => {
			try {
				const response = await authGet(key, { 
					params });
                return response;
			} catch (err) {
				if (!isCancelError(err)) {
					toast.error(getToastErrorMessage(err, "Could not get team schedule analytics"));
				}
			}
		},
		[authGet],
	);

    return useSWR(
		["/schedules/team/overview", {
			...filters,
			startDate,
			endDate
		}],
		fetch,
		{
			fallbackData: {
				data: [],
				budget: { hours: 0, amount: 0 }
			},
		},
	);
}

export const useFetchMyScheduleAnalytics = ({ filters, startDate, endDate }) => {
    const { authGet } = useApi();

	const fetch = useCallback(
		async ([key, params]) => {
			try {
				const response = await authGet(key, {
					params
				});
                return response;
			} catch (err) {
				if (!isCancelError(err)) {
					toast.error(getToastErrorMessage(err, "Could not get team schedule analytics"));
				}
			}
		},
		[authGet, filters, startDate, endDate],
	);

    return useSWR(
		["/schedules/overview", {
			...filters,
			startDate,
			endDate
		}], 
		fetch, {
        fallbackData: [],
    });
}
