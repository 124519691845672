import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "@googlemaps/react-wrapper";
import Field from "../../../../../components/Field";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import Text from "../../../../../components/Inputs/Text";
import Map from "../../../../../components/Map";
import Search from "../../../../../components/Map/Search";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { useSearch } from "../helpers";
import {
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../OrganisationLevelGroups/context";
import { FormGroup } from "reactstrap";

function Content({ mode, changeMarker, marker, zoom, center, disabled }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
	const { search, setSearch, handleSelect } = useSearch(changeMarker);

	return (
		<>
			<BaseSettingsFormContent mode={mode} disabled={disabled} />

			<Field
				name="timezone"
				label={t("timezone")}
				rules={[
					{
						required: true,
						message: t("required-timezone"),
					},
				]}
			>
				<ResourceSelect
					labelPropName="text"
					resourcePath="/timezone?pagination=false"
					hasCompany={false}
					hasSearch
					placeholder={t("timezone")}
					disabled={disabled}
				/>
			</Field>

			{groups.map((group) => (
				<Field
					name={`${group.level}`.replace("_", "")}
					label={renderOrgLevelResourceLabel(group)}
				>
					<ResourceSelect
						labelPropName="description"
						resourcePath={`/${group.id}/org-levels`}
						placeholder={
							renderOrgLevelResourceLabel(group)
						}
						renderLabel={renderOrgLevelResourceLabel}
						allowClear
						hasSearch
						getSearchFilters={getSettingsOrgResourceSearchFilters}
						mode="multiple"
						disabled={disabled}
					/>
				</Field>
			))}

			<h3 className="font-weight-300">{t("address-details")}</h3>

			<FormGroup>
				<Wrapper
					apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}
				>
					<Search
						search={search}
						setSearch={setSearch}
						handleSelect={handleSelect}
					/>
				</Wrapper>
			</FormGroup>

			<Field
				name="country"
				rules={[{ required: true, message: t("required-country") }]}
			>
				<ResourceSelect
					labelPropName="name"
					resourcePath="/countries?pagination=false"
					hasCompany={false}
					placeholder={t("country")}
					hasSearch
					disabled={disabled}
				/>
			</Field>

			<Field
				name="state"
				rules={[
					{
						required: true,
						message: t("required-state"),
					},
				]}
			>
				<Text placeholder={t("state")} disabled={disabled} />
			</Field>

			<Field
				name="city"
				rules={[
					{
						required: true,
						message: t("required-city"),
					},
				]}
			>
				<Text placeholder={t("city")} disabled={disabled} />
			</Field>

			<Field name="address1">
				<Text placeholder={t("address1")} disabled={disabled} />
			</Field>

			<Field name="address2">
				<Text placeholder={t("address2")} disabled={disabled} />
			</Field>

			<Field
				name="postalCode"
				rules={[
					{
						requried: true,
						message: t("required-postal-code"),
					},
				]}
			>
				<Text placeholder={t("postal-code")} disabled={disabled} />
			</Field>

			<Map
				setSearch={setSearch}
				center={center}
				zoom={zoom}
				marker={marker}
				changeMarker={changeMarker}
			/>
		</>
	);
}

export default Content;
