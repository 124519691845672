import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useTable,
	useFilters,
	useGlobalFilter,
	useSortBy,
	usePagination,
	useRowSelect,
} from "react-table";
import Checkbox from "../../Inputs/Checkbox";
import { isEqual } from "lodash";
import { useTableURLFilters } from "./useTableURLFilters";
import { useInitializeUrlFilters } from "./useInitializeUrlFilters";

const useDefaultFilters = (columns) => {
	const firstFilterableColumn = columns.find(
		({ disableFilters }) => !disableFilters,
	);
	return firstFilterableColumn
		? [
			{
				name: firstFilterableColumn.accessor,
				method: "contains",
				value: "",
			},
		]
		: [];
};

export const useInitialState = ({
	urlState,
	columns,
	initialFilters,
	initialHiddenColumns,
}) => {
	const defaultFilters = useDefaultFilters(columns);
	const [initialState] = useState(() => {
		const { filters: urlFilters, ...urlOtherState } = urlState;
		let state = {
			pageIndex: 0,
			pageSize: 20,
			...urlOtherState,
			globalFilter: defaultFilters,
			hiddenColumns: initialHiddenColumns,
		};

		if (urlFilters?.length > 0) {
			state.globalFilter = urlFilters;
		} else if (initialFilters) {
			state.globalFilter = initialFilters;
		}

		return state;
	});

	return initialState;
};

export const useEnhancedTable = ({
	hasURLBindedFilters = true,
	columns,
	hiddenColumns = [],
	data,
	totalItems,
	hasRowSelection = true,
	filters = undefined,
	pageSize = 20,
	getRowProps,
	getCellProps,
	...props
}) => {
	const [pageCount, setPageCount] = useState(
		Math.ceil(totalItems / pageSize),
	);

	const [urlInitialFilters, onTableChange] = useTableURLFilters();
	const initialState = useInitialState({
		urlState: hasURLBindedFilters ? urlInitialFilters : {},
		columns,
		initialFilters: filters,
		initialHiddenColumns: hiddenColumns,
	});

	useInitializeUrlFilters({
		hasURLBindedFilters,
		initialState,
		setFilters: onTableChange,
	});

	const table = useTable(
		{
			columns,
			data,
			totalItems,
			initialState,
			manualPagination: true,
			manualSortBy: true,
			manualFilters: true,
			pageCount,
			manualGlobalFilter: true,
			stateReducer: useCallback((state, action, prevState) => {
				let newState = { ...state };
				if (
					action.type === "setGlobalFilter"
					&& !isEqual(newState.globalFilter, prevState.globalFilter)
				) {
					newState.pageIndex = 0;
				}
				if (hasURLBindedFilters) {
					if (
						!isEqual(newState.pageIndex, prevState.pageIndex)
						|| !isEqual(newState.pageSize, prevState.pageSize)
						|| !isEqual(newState.sortBy, prevState.sortBy)
						|| !isEqual(newState.globalFilter, prevState.globalFilter)
					) {
						onTableChange(newState);
					}
				}
				return newState;
			}, [hasURLBindedFilters, onTableChange]),
			defaultColumn: {
				hideable: true,
				alwaysVisible: false,
				disableFilters: true,
			},
			filterTypes: useMemo(() => ({}), []),
			getRowId: useCallback(({ id }) => id, []),
			...props,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		hasRowSelection && useRowSelect,
		(hooks) => {
			hasRowSelection &&
				hooks.visibleColumns.push((columns) => [
					{
						id: "selection",
						Header: ({
							getToggleAllRowsSelectedProps,
							toggleRowSelected,
							isAllPageRowsSelected,
							page,
						}) => {
							const modifiedOnChange = (event) => {
								page.forEach((row) => {
									!row.original.isDefault &&
										row.original.company !== "" &&
										toggleRowSelected(
											row.id,
											event.currentTarget.checked,
										);
								});
							};

							let selectableRowsInCurrentPage = 0;
							let selectedRowsInCurrentPage = 0;
							page.forEach((row) => {
								row.isSelected && selectedRowsInCurrentPage++;
								!row.original.isDefault &&
									row.original.company !== "" &&
									selectableRowsInCurrentPage++;
							});

							const disabled = selectableRowsInCurrentPage === 0;
							const checked =
								(isAllPageRowsSelected ||
									selectableRowsInCurrentPage ===
									selectedRowsInCurrentPage) &&
								!disabled;

							return (
								<Checkbox
									id="selection-all"
									{...getToggleAllRowsSelectedProps()}
									onChange={modifiedOnChange}
									checked={checked}
								/>
							);
						},
						Cell: ({ row }) => (
							<Checkbox
								id={`selection-${row.id}`}
								{...row.getToggleRowSelectedProps()}
								disabled={
									row.original.isDefault ||
									row.original.company === ""
								}
							/>
						),
					},
					...columns,
				]);
			getRowProps && hooks.getRowProps.push(getRowProps);
			getCellProps && hooks.getCellProps.push(getCellProps);
		},
	);

	useEffect(() => {
		setPageCount(Math.ceil(totalItems / table.state.pageSize));
	}, [setPageCount, totalItems, table.state.pageSize]);

	return table;
};
