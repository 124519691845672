import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import PrintComponent from "./PrintComponent";
import { OrgLevelGroupsContext } from "../../General/OrganisationLevelGroups/context";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit }) => {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);

	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const { description, status } = useCommonColumns({ edit });

	return useMemo(() => {
		const columns = [
			description,
			status,
			columnHelper.accessor("location", {
				enableColumnFilter: true,
				header: t("location"),
				cell: (info) => {
					const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath="/locations"
						renderLabel={renderSettingsResourceLabel}
						mode="multiple"
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			}),
			columnHelper.accessor("timezone.text", {
				header: t("timezone"),
			}),
		];

		if (group) {
			const groupColumn = columnHelper.accessor("project", {
				enableColumnFilter: true,
				header: group.description,
				cell: (info) => {
					const value = info.getValue();
					return value && renderOrgLevelResourceLabel(value);
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						labelPropName="description"
						resourcePath={`${group.id}/org-levels`}
						renderLabel={renderOrgLevelResourceLabel}
						mode="multiple"
						hasSearch
						getSearchFilters={getSettingsOrgResourceSearchFilters}
					/>
				),
			});
			columns.push(
				groupColumn,
				columnHelper.accessor("project.code", {
					header: t("code"),
				}),
				columnHelper.accessor("project.glSegment", {
					header: t("gl-segment"),
				}),
			);
		}

		if (edit) {
			const actionsColumn = columnHelper.display({
				id: "actions",
				enableHiding: false,
				enableColumnFilter: false,
				header: () => (
					<div className="text-right">
						{t("actions")}
					</div>
				),
				cell: (info) => {
					const {
						id,
						location,
						description,
						project,
						company,
						isDefault,
					} = info.row.original;

					return (
						<div className="actions text-primary text-right">
							{company && !isDefault && edit && (
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer"
									onClick={() => edit(id)}
									title={t("Edit")}
								>
									<i className="fas fa-pen" />
								</span>
							)}

							<PrintComponent
								id={id}
								project={project}
								description={description}
								location={location}
							/>
						</div>
					);
				},
			});
			columns.push(actionsColumn);
		}
		return columns;
	}, [t, edit, group, description, status]);
};
