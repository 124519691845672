import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useEmployeeNumberFilter = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        id: "employeeNumber",
        Header: t("employee-number"),
        filter: "text",
        canFilter: true,
    }), [t]);
}
