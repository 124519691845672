import { useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { useDateFilters } from "../DateAndPayGroupFilters/useDateFilters";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { useAccess } from "../../../../utils/hooks/access";
import { useGetUniqueElements } from "./helpers/useGetUniqueElements";
import { firstToUpper } from "../../../../utils/helpers/string";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";

import { useConfigurationModule } from "../../../Settings/Configuration/hooks/useConfigurationModule";

export const enumerateDaysBetweenDates = (startDate, endDate) => {
	const now = startDate,
		dates = [];

	while (now.isSameOrBefore(endDate)) {
		dates.push(now.format("YYYY-MM-DD"));
		now.add(1, "days");
	}

	return dates;
};

const moveFromStatusToStatus = (obj, originKey, destinationKey) => {
	return {
		[originKey]: {
			amount: 0,
			ids: [],
		},
		[destinationKey]: {
			amount: obj?.[destinationKey]?.amount + obj?.[originKey]?.amount,
			ids: obj?.[destinationKey]?.ids.concat(...obj?.[originKey]?.ids),
		},
	};
};

export const useColumns = ({
	refetchUsers,
	addNote,
	openInfoTooltip,
	approve,
	unapprove,
	openSummaryTooltip,
	setData,
	openProfileDrawer,
}) => {
	const { t } = useTranslation();

	const config = useConfigurationModule("timesheetManagerView");

	const { from, to, filters } = useDateFilters();
	const { hasAccess: canApprove } = useAccess("timeSheet.canApprove");
	const { hasAccess: canManageNote } = useAccess("timeSheet.manageNote");
	const getUniqueElements = useGetUniqueElements();

	const { groups } = useGroups();

	const allocatedByGroup = useMemo(() =>
		groups?.find(({ allocatedBy }) => allocatedBy)
		, [groups])

	const days = useMemo(
		() => moment(to).diff(moment(from), "days"),
		[from, to],
	);

	const toDate = useMemo(
		() => moment(moment.parseZone(from)).add(days, "days"),
		[from, days],
	);

	const columns = useMemo(() => {
		const results = enumerateDaysBetweenDates(
			moment.parseZone(from),
			moment.parseZone(toDate),
		);

		const cols = [
			{
				Header: <div className="text-center mb-1">{t("name")}</div>,
				accessor: "search",
				columnToggleLabel: t("name"),
				alwaysVisible: true,
				className: "name",
				Cell: ({
					row: {
						original: { user },
					},
				}) => {
					const level = [user?.level1, user?.level2, user?.level3, user?.level4]?.find((level) =>
						level?.orgLevelGroup === allocatedByGroup?.id);

					const project = user?.project;

					return (
						<div className="d-flex flex-column">
							<span className="font-weight-bold text-dark text-xs text-uppercase">
								{user?.name}
							</span>

							{!config?.hideEmployeeNumber && (
								<span>EMP: {user?.employeeNumber}</span>
							)}

							{(level && !config?.hideGroup) && (
								<span
									className="text-sm"
									style={{
										color: "#3d22cb"
									}}
								>
									{allocatedByGroup?.code}: {level.glSegment || level.description}
								</span>
							)}

							{(project && !config?.hideProject) && (
								<span
									className="text-sm"
									style={{
										color: "#5428e0"
									}}
								>
									PRO: {project.code || project.description}
								</span>
							)}
						</div>
					)
				},
				Footer: (
					<div className="text-dark font-weight-bold">
						{t("total")}
					</div>
				),
			},
			{
				Header: <div className="text-center mb-1">{t("summary")}</div>,
				accessor: "summary",
				columnToggleLabel: t("summary"),
				className: "summary",
				Cell: ({ value, row: { original } }) => {
					const total =
						value?.timesheet?.approved?.amount +
						value?.timesheet?.unapproved?.amount;

					const unapproveIds = value?.timesheet?.unapproved?.ids;
					const approveIds = value?.timesheet?.approved?.ids;

					const updater = (origin, destination) => {
						setData((prev) =>
							prev?.map((item) => {
								if (item?.id !== original?.id) {
									return item;
								}
								const updatedDates = Object.keys(item)
									.filter((date) => {
										return /\d{4}-\d{2}-\d{2}/.test(date);
									})
									.reduce((total, date) => {
										return {
											...total,
											[date]: {
												...item[date],
												timesheet:
													moveFromStatusToStatus(
														item[date].timesheet,
														origin,
														destination,
													),
											},
										};
									}, {});
								return {
									...item,
									...updatedDates,
									summary: {
										...item?.summary,
										timesheet: moveFromStatusToStatus(
											item?.summary?.timesheet,
											origin,
											destination,
										),
									},
								};
							}),
						);
					};

					const _approve = () => {
						approve(
							unapproveIds,
							updater.bind(null, "unapproved", "approved"),
						);
					};

					const _unnaprove = () => {
						unapprove(
							approveIds,
							updater.bind(null, "approved", "unapproved"),
						);
					};

					return (
						original && (
							<div>
								<div
									className="d-flex align-items-center mb-1"
									style={{
										height: "12px",
									}}
								>
									{value?.timesheet?.approved?.amount > 0 && (
										<i
											className={classNames(
												"fa-solid fa-check mr-2 text-xs cursor-pointer text-info",
												!canApprove && "d-none",
											)}
											onClick={_unnaprove}
										/>
									)}

									{value?.timesheet?.unapproved?.amount >
										0 && (
											<i
												className={classNames(
													"fa-solid fa-xmark text-red cursor-pointer",
													!canApprove && "d-none",
												)}
												onClick={_approve}
											/>
										)}
								</div>

								<div className="d-flex align-items-center justify-content-between mb-1">
									<span
										className="d-flex flex-column mr-3"
										id={`summary${original?.user?.id}`}
										onMouseEnter={() => {
											if (config?.hideSummaryTooltip) {
												return;
											}
											openSummaryTooltip({
												...original?.overall,
												user: original?.user,
												isDateCell: false,
											});
										}}
									>
										<span className="text-sm">
											{firstToUpper(t("hours"))}: &nbsp;
											<span className="font-weight-bolder">
												{renderDurationAsFormat(
													value?.workedHours
														? value?.workedHours
														: 0,
													"HH:mm",
												)}
											</span>
										</span>
									</span>

									{/* <span className="d-flex flex-column">
										<span className="text-sm">
											{t("amount")}:
										</span>

										<span className="font-weight-bolder text-dark">
											{currencyFormatter(
												value?.paidAmount
													? value?.paidAmount
													: 0,
											)}
										</span>
									</span> */}
								</div>

								<div>
									{value?.timesheet?.approved?.amount}
									{"/"}
									{total} {t("approved")}
								</div>
							</div>
						)
					);
				},
				Footer: ({ rows }) => {
					const summary = rows?.reduce(
						(total, row) => {
							return {
								approved: {
									amount:
										total.approved.amount +
										row?.original?.summary?.timesheet
											?.approved?.amount,
									ids: total?.approved?.ids?.concat(
										row?.original?.summary?.timesheet
											?.approved?.ids,
									),
								},
								unapproved: {
									amount:
										total.unapproved.amount +
										row?.original?.summary?.timesheet
											?.unapproved?.amount,
									ids: total?.unapproved?.ids?.concat(
										row?.original?.summary?.timesheet
											?.unapproved?.ids,
									),
								},
								total:
									total.total +
									row?.original?.summary?.timesheet?.approved
										?.amount +
									row?.original?.summary?.timesheet
										?.unapproved?.amount,
								workedHours:
									total.workedHours +
									row?.original?.summary?.workedHours,
								paidAmount:
									total.paidAmount +
									row?.original?.summary?.paidAmount,
							};
						},
						{
							approved: { amount: 0, ids: [] },
							unapproved: { amount: 0, ids: [] },
							total: 0,
							workedHours: 0,
							paidAmount: 0,
						},
					);

					const overallData = rows?.map(
						(row) => row?.original?.overall,
					);

					return (
						<div>
							<div className="">
								{summary?.approved?.amount > 0 && (
									<i
										className={classNames(
											"fa-solid fa-check mr-2 text-xs cursor-pointer text-info",
											!canApprove && "d-none",
										)}
										onClick={() =>
											unapprove(
												summary?.approved?.ids,
												refetchUsers,
											)
										}
									/>
								)}

								{summary?.unapproved?.amount > 0 && (
									<i
										className={classNames(
											"fa-solid fa-xmark   text-red cursor-pointer",
											!canApprove && "d-none",
										)}
										onClick={() =>
											approve(
												summary?.unapproved?.ids,
												refetchUsers,
											)
										}
									/>
								)}
							</div>

							<div className="d-flex align-items-center justify-content-between ">
								<span
									className="d-flex flex-column mr-3"
									id="summaryfooter"
									onMouseEnter={() => {
										if (config?.hideSummaryTooltip) {
											return;
										}
										openSummaryTooltip({
											...overallData,
											isSummaryFooter: true,
											isDateCell: false,
										})
									}}
								>
									<span className="text-sm">
										{firstToUpper(t("hours"))}: &nbsp;
										<span className="font-weight-bolder">
											{renderDurationAsFormat(
												summary?.workedHours,
												"HH:mm",
											)}
										</span>
									</span>
								</span>

								{/* <span className="d-flex flex-column">
									<span className="text-sm">
										{t("amount")}:
									</span>

									<span className="font-weight-bolder text-dark">
										{currencyFormatter(summary?.paidAmount)}
									</span>
								</span> */}
							</div>

							<div>
								{summary?.approved?.amount}
								{"/"}
								{summary?.total} {t("approved")}
							</div>
						</div>
					);
				},
			},
		];

		results.map((date) =>
			cols.push({
				Header: (
					<div className="d-flex flex-column align-items-center">
						<span className="font-weight-bolder">
							{moment(date).format("dddd").toLowerCase()}
						</span>
						<span>{moment(date).format("MMM DD, YYYY")}</span>
					</div>
				),
				columnToggleLabel: moment(date).format("ddd, MMM DD, YYYY"),
				accessor: date,
				Cell: ({
					value,
					row: {
						toggleRowExpanded,
						getToggleRowExpandedProps,
						setState,
						state,
						isExpanded,
						original,
					},
				}) => {
					const unapproveIds = value?.timesheet?.unapproved?.ids;
					const approveIds = value?.timesheet?.approved?.ids;

					const updater = (origin, destination) => {
						setData((prev) =>
							prev?.map((item) => {
								if (item?.id !== original?.id) {
									return item;
								}

								const updatedDate = {
									...item[date],
									timesheet: moveFromStatusToStatus(
										item[date].timesheet,
										origin,
										destination,
									),
								};

								return {
									...item,
									[date]: updatedDate,
									summary: {
										...item.summary,
										timesheet: {
											[origin]: {
												amount:
													item.summary.timesheet[
														origin
													].amount -
													updatedDate.timesheet[
														destination
													].amount,
												ids: item.summary.timesheet[
													origin
												].ids.filter((id) => {
													return updatedDate.timesheet[
														destination
													].ids.includes(id);
												}),
											},
											[destination]: {
												amount:
													item.summary.timesheet[
														destination
													].amount +
													updatedDate.timesheet[
														destination
													].amount,
												ids: item.summary.timesheet[
													destination
												].ids.concat(
													...updatedDate.timesheet[
														destination
													].ids,
												),
											},
										},
									},
								};
							}),
						);
					};

					const _approve = () => {
						approve(
							unapproveIds,
							updater.bind(null, "unapproved", "approved"),
						);
					};

					const _unnaprove = () => {
						unapprove(
							approveIds,
							updater.bind(null, "approved", "unapproved"),
						);
					};

					return (
						<div>
							<div className="mb-1 d-flex justify-content-between align-items-center">
								<div style={{ height: "12px" }}>
									{value?.timesheet?.approved?.amount > 0 && (
										<i
											className={classNames(
												"fa-solid fa-check text-xs mr-2 cursor-pointer text-info",
												!canApprove && "d-none",
											)}
											onClick={_unnaprove}
										/>
									)}

									{value?.timesheet?.unapproved?.amount >
										0 && (
											<i
												className={classNames(
													"fa-solid fa-xmark  mr-2 text-red cursor-pointer",
													!canApprove && "d-none",
												)}
												onClick={_approve}
											/>
										)}

									{(value?.hasMissPunch && !config?.hideMissPunchTooltip) && (
										<i
											id={`misspunch-${date}-${original?.id}`}
											className="fa-solid fa-circle-info text-primary cursor-pointer"
											onMouseEnter={() =>
												openInfoTooltip({
													...original,
													cellDate: date,
												})
											}
										/>
									)}
								</div>

								{(value?.timesheet?.unapproved?.amount > 0 ||
									value?.timesheet?.approved?.amount > 0) && (
										<i
											className={classNames(
												"fa-solid fa-note-sticky cursor-pointer",
												value?.note
													? "text-dark"
													: "text-red",
												!canManageNote && "d-none",
											)}
											onClick={() =>
												addNote({
													...value,
													user: original?.user,
													id: original?.id,
												})
											}
										/>
									)}
							</div>

							<div className="d-flex align-items-center justify-content-between mb-1">
								<span
									className="d-flex flex-column mr-3"
									id={`date${date + original?.user?.id}`}
								// onMouseEnter={() =>
								// 	openSummaryTooltip({
								// 		...original[date]?.overall,
								// 		user: original?.user,
								// 		date,
								// 		isSummaryFooter: false,
								// 		isDateCell: true,
								// 	})
								// }
								>
									<span className="text-sm">
										{firstToUpper(t("hours"))}: &nbsp;

										<span className="font-weight-bolder">
											{value?.workedHours ? renderDurationAsFormat(
												value?.workedHours
													? value?.workedHours
													: 0,
												"HH:mm",
											) : '--'}
										</span>
									</span>
								</span>

								{/* <span className="d-flex flex-column">
									<span className="text-sm">
										{t("amount")}:
									</span>

									<span className="font-weight-bolder text-dark">
										{currencyFormatter(
											value?.paidAmount
												? value?.paidAmount
												: 0,
										)}
									</span>
								</span> */}
							</div>

							<div
								{...getToggleRowExpandedProps({
									onClick: () => {
										const isSameDate = state.date === date;
										if (isSameDate) {
											setState((prev) => ({
												...prev,
												date: null,
											}));
											toggleRowExpanded(false);
										} else {
											setState((prev) => ({
												...prev,
												date,
											}));
											toggleRowExpanded(true);
										}
									},
								})}
							>
								{isExpanded && state.date === date ? (
									<i className="fa-solid fa-angle-up cursor-pointer text-muted" />
								) : (
									<i className="fa-solid fa-angle-down cursor-pointer text-muted" />
								)}
							</div>
						</div>
					);
				},
				Footer: ({ rows }) => {
					const data = rows?.map((row) => row?.original[date]);

					const total = data?.reduce(
						(total, row) => {
							if (row) {
								return {
									workedHours:
										total?.workedHours + row?.workedHours,
									paidAmount:
										total?.paidAmount + row?.paidAmount,
								};
							}
							return total;
						},
						{
							workedHours: 0,
							paidAmount: 0,
						},
					);

					// const overallData = rows?.map(
					// 	(row) => row?.original[date]?.overall,
					// );

					return (
						<>
							<div style={{ height: "12px" }} />
							<div className="d-flex align-items-center justify-content-between">
								<span
									className="d-flex flex-column mr-3 mb-1"
									id={`dateFooter${date}`}
								// onMouseEnter={() =>
								// 	openSummaryTooltip({
								// 		...overallData,
								// 		isSummaryFooter: false,
								// 		isDateFooter: true,
								// 		isDateCell: false,
								// 		date,
								// 	})
								// }
								>
									<span className="text-sm">
										{firstToUpper(t("hours"))}:
										<span className="font-weight-bolder">
											{total?.workedHours ? renderDurationAsFormat(
												total?.workedHours,
												"HH:mm",
											) : ' --'}
										</span>
									</span>
								</span>

								{/* <span className="d-flex flex-column">
									<span className="text-sm">
										{t("amount")}:
									</span>

									<span className="font-weight-bolder text-dark">
										{currencyFormatter(total?.paidAmount)}
									</span>
								</span> */}
							</div>
						</>
					);
				},
			}),
		);

		return cols;
	}, [
		from,
		to,
		toDate,
		filters,
		canApprove,
		canManageNote,
		config,
		addNote,
		openInfoTooltip,
		openSummaryTooltip,
		approve,
		unapprove,
		setData,
		getUniqueElements,
		refetchUsers,
		openProfileDrawer,
		t,
		allocatedByGroup,
	]);

	return columns;
};
