import { ListGroup } from "reactstrap";
import Clock from "./Clock";

const List = ({ clocks, isShiftView }) => {
    return (
        <ListGroup data-toggle='checklist' flush>
            {clocks.map((clock) => (
                <Clock key={clock.id} clock={clock} isShiftView={isShiftView}/>
            ))}
        </ListGroup>
    );
}

export default List;
