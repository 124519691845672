import { useCallback, useEffect } from "react";
import { useQrCodeClockIn } from "../context";
import useApi from "../../../utils/api";

export const useGetAttestations = (user, token) => {
    const { dispatch } = useQrCodeClockIn();
    const { authGet } = useApi();

    const getFlyers = useCallback(async (controller) => {
        dispatch({ type: "fetch-attestation-request" })
        try {
           if (user?.id && token) {
                const response = await authGet(`/attestation/user/${user?.id}`, {
                    signal: controller?.signal,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Company: user?.companies[0]?.id,
                        MainCompany: user?.mainCompany,
                    }
                });

                if (response) {
                    dispatch({
                        type: "fetch-attestation-success",
                        payload: { attestations: response }
                    })
                }
            }

        } catch (error) {
            dispatch({ type: "fetch-attestation-failed", payload: { error } })
        }
    }, [dispatch, authGet, user, token]);

    useEffect(() => {
        const contoller = new AbortController();
        getFlyers(contoller)
        return () => contoller.abort();
    }, [getFlyers]);
}