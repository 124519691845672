import FormElement from "rc-field-form";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useAuthentication } from "../../../../Authentication/context";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import { months } from "./Content";

const getMonthInitialValue = (values, month) => {
    return {
        amount: values?.months?.[month]?.amount || 0,
        hours: values?.months?.[month]?.hours || 0,
    };
};

const getFormattedMonthValue = (values, month) => {
    return {
        amount: values?.[month]?.amount || 0,
        hours: values?.[month]?.hours || 0,
    };
};

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const { access } = useModuleAccess("settings.payPolicies.budget");
    const disabled = useIsFieldDisabled({ ...access, mode });

    const { company } = useAuthentication();

    const { groups } = useContext(OrgLevelGroupsContext);

    const group = useMemo(
        () => groups?.find((group) => group.hasBudget),
        [groups],
    );

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback(
        (formValues) => {
            const data = {
                year: moment(formValues.year).year(),
                locationId: formValues?.locationId?.id,
                levelId: formValues?.levelId?.id,
                orgLevelGroupId: group?.id,
                status: "active",
                months: months.reduce((total, month) => {
                    return {
                        ...total,
                        [month]: getFormattedMonthValue(formValues, month),
                    };
                }, {}),
            };
            submit(data);
        },
        [submit, group],
    );

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            year:
                mode === "edit"
                    ? moment().year(values?.year).toDate()
                    : undefined,
            levelId: values?.levelId ? values?.levelId : undefined,
            locationId: values?.locationId ? values.locationId : undefined,
            ...months.reduce((total, month) => {
                return {
                    ...total,
                    [month]: getMonthInitialValue(values, month),
                };
            }, {}),
        });
    }, [form, values, company?.settings?.currency, mode]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["locationId", "levelId", "year"],
            error,
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement
            form={form}
            className="d-flex flex-column justify-content-between h-100"
            onFinish={onFinish}
        >
            <div>
                <Content mode={mode} disabled={disabled || !isMasterCompany} />
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {(!disabled || isMasterCompany) && (
                    <Button
                        type="submit"
                        className="btn-dark btn-sm shadow-none"
                        loading={loading}
                    >
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
