import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../components/Drawer";
import AddShiftForm from "../AddShift/Form";
import AddAbsenceForm from "../AddAbsence/Form";
import AddSchedule from "../AddSchedule/Form";
import useApi from "../../../utils/api";
import { useVisible } from "../../../utils/hooks/useVisible";

export const useViewDrawer = () => {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const { authGet } = useApi();
    const { open, visible, selected, close } = useVisible();

    const getSchedule = useCallback(async(id, controller) => {
        setLoading(true);
        try {
            const response = await authGet(`/schedules/${id}`, {
                signal: controller?.signal
            });
            if(response) {
                setData({ data: response });
            }
        } catch(err) {
        } finally {
            setLoading(false);
        }
    }, [setLoading, setData, authGet]);

    const title = useMemo(() => {
        switch(selected?.type) {
            case "shift":
                return t("shift");
            case "absence": 
                return t("absence");
            case "edit-shift":
                return t("edit-shift");
            case "edit-timesheet":
                return t("edit-timesheet");
            case "schedule":
            case "edit-schedule":
            case "submit-schedule":
                return t("schedule");
            default:
                break;
        }
    }, [selected?.type, t]);

    const children = useMemo(() => {
        switch(selected?.type) {
            case "shift":
            case "edit-shift":
                return <AddShiftForm values={selected} mode="view" />;
            case "absence": 
                return <AddAbsenceForm values={selected} mode="view"/>;
            case "schedule":
            case "submit-schedule":
                return <AddSchedule values={selected} mode="view"/>;
            case "edit-schedule":
                return (
                    <div className="d-flex gap-2 h-100">
                        {(data?.data && !loading) && (
                             <div className="h-100">
                                <div className="mb-3 mx-3 font-weight-bold text-dark">{t("schedule-values")}</div>
                                <AddSchedule values={data} mode="view" />
                            </div>
                        )}

                        <div className="h-100">
                            <div className="mb-3 mx-3 font-weight-bold text-dark">{t("edit-schedule-request")}</div>
                            <AddSchedule values={selected} mode="view" />
                        </div>
                    </div>
                )
            default:
                break;
        }
    }, [selected?.type, data, loading]);

    useEffect(() => {
        const controller = new AbortController();
        if (selected?.data?.id && visible) {
            getSchedule(selected.data.id, controller);
        }
        return () => controller.abort();
    }, [visible, selected?.data?.id, getSchedule])

    const drawer = useMemo(() => (
        <Drawer
            className="view-request-drawer"
            title={title}
            open={visible}
            close={close}
        >
            {children}
        </Drawer>
    ), [close, visible, title, children]);

    return { drawer, open };
}