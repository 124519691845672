import {useCallback} from "react"
import {currencyFormatter} from "../helpers/currencyFormatter";
import {useCompanyCurrency} from "./company";

export const useRenderPayment = () => {
    const currency = useCompanyCurrency();
    return useCallback((value) => {
        return currencyFormatter(value, 2, currency);
    }, [currency]);
}
