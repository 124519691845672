import { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { toast } from "react-toastify";
import Button from "../../Button";
import UploadInput from "../../Inputs/Upload";
import Modal from "..";
import { useImport } from "./api";
import { getUploadUrl } from "../../../utils/helpers/upload";
import "./style.scss";

const fileTypes = [
	".csv",
	"application/vnd.ms-excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const fileSizeLimit = 2;

function ImportModal({
	isOpen,
	close,
	url,
	onSuccess,
	onDownloadSample,
	loading,
}) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [file, setFile] = useState(null);

	const { importFile, loading: loadingImport } = useImport();

	const onChange = useCallback(
		(info) => {
			if (
				!info.target.files[0].type ||
				!fileTypes.includes(info.target.files[0].type)
			) {
				toast.error(t("can-upload-excel"));
				return false;
			} else if (
				!info.target.files[0].size ||
				info.target.files[0].size > fileSizeLimit * 1024 * 1024
			) {
				toast.error(t("file-size"));
				return false;
			} else {
				setFile({
					file: info.target.files[0],
					name: info.target.files[0].name,
				});
			}
		},
		[setFile, t],
	);

	const preview = useMemo(() => {
		if (!file?.file) {
			return null;
		}
		if (typeof file.file === "string") {
			return getUploadUrl(file.file);
		}

		return URL.createObjectURL(new Blob([file?.file]));
	}, [file, getUploadUrl]);

	const onFinish = useCallback(() => {
		const formData = new FormData();
		formData.append("file", file?.file);
		importFile(url, formData, (response) => {
			onSuccess(response);
			form.resetFields();
			setFile(null);
		});
	}, [importFile, setFile, file?.file, url, onSuccess, form]);

	const deleteFile = useCallback(() => {
		form.resetFields();
		setFile(null);
	}, [form, setFile]);

	const onClose = useCallback(() => {
		form.resetFields();
		setFile(null);
		close();
	}, [close, setFile, form]);

	return (
		<Modal
			isOpen={isOpen}
			centered
			title={
				<div className="d-flex align-items-center justify-content-between">
					<span>{t("import")}</span>

					<Button
						color="dark"
						onClick={onDownloadSample}
						loading={loading}
						disabled={loading}
						className="py-1"
					>
						<i className="fa-solid fa-download " />{" "}
						{t("download-sample")}
					</Button>
				</div>
			}
			toggle={onClose}
			className="import-modal"
		>
			<Form form={form} onFinish={onFinish}>
				<UploadInput
					multiple={false}
					onChange={onChange}
					fileList={[]}
					fileSizeLimit={fileSizeLimit}
					fileTypes={fileTypes}
					name="file"
				/>

				{file?.file && preview && (
					<div className="border border-primary rounded px-3 py-2 d-flex justify-content-between align-items-center mb-3">
						<div className="flex items-center">
							<i className="fa-solid fa-paperclip mr-2 text-muted" />

							<span>{file.name}</span>
						</div>

						<i
							className="fa-sharp fa-solid fa-trash cursor-pointer text-danger"
							onClick={deleteFile}
						/>
					</div>
				)}

				<div className="d-flex justify-content-between">
					<Button
						color="primary"
						type="submit"
						loading={loadingImport}
						disabled={loadingImport || !file?.file}
					>
						{t("import")}
					</Button>

					<Button
						color="dark"
						disabled={loadingImport}
						type="button"
						onClick={onClose}
					>
						{t("cancel")}
					</Button>
				</div>
			</Form>
		</Modal>
	);
}

export default ImportModal;
