import { useTranslation } from "react-i18next";
import { Field, List } from "rc-field-form";
import Checkbox from "../../../../components/Inputs/Checkbox";
import { useMemo } from "react";

function Row({ fieldName, name, config, canSort }) {
    return (
        <tr>
            <td className="px-3 py-1 font-weight-bold">
                {config.Header}
            </td>

            <td className="px-3 py-1">
                <div className="d-flex align-items-center justify-content-center">
                    <Field
                        name={[name, "hide"]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            id={`${fieldName}-${name}`}
                            disabled={config.alwaysVisible}
                            labelClassName="px-2"
                        />
                    </Field>
                </div>
            </td>

            <td>
                <div className="d-flex align-items-center justify-content-center px-3 py-1">
                    <Field
                        name={[name, "hideCompletely"]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            id={`${fieldName}-${name}-hideCompletely`}
                            disabled={config.alwaysVisible}
                            labelClassName="px-2"
                        />
                    </Field>
                </div>
            </td>

            <Field name={[name, "hideSort"]} valuePropName="checked">
                {(field) => {
                    if (!canSort) {
                        return null;
                    }
                    return (
                        <td className="px-3 py-1">
                            <div className="d-flex align-items-center justify-content-center">
                                <Checkbox
                                    id={`${fieldName}-${name}-hideSort`}
                                    disabled={config.disableSortBy}
                                    labelClassName="px-2"
                                    {...field}
                                />
                            </div>
                        </td>
                    );
                }}
            </Field>
        </tr>
    );
}

function ColumnsTable({ config, fieldName = "columns" }) {
    const { t } = useTranslation();

    const canSort = useMemo(() => {
        return config.some((column) => {
            return column.disableSortBy;
        })
    }, [config]);

    const map = useMemo(() => {
        return config.reduce((total, column) => {
            const accessor = column.accessor || column.id;
            total[accessor] = column;
            return total;
        }, {});
    }, [config]);

    return (
        <table className="d-block">
            <thead>
                <tr>
                    <th />

                    <th className="text-center px-3 py-2 text-dark">
                        {t("hide")}
                    </th>

                    <th className="text-center px-3 py-2 text-danger">
                        {t("hide-completely")}
                    </th>

                    {canSort && (
                        <th className="text-center px-3 py-2 text-info">
                            {t("hide-sort")}
                        </th>
                    )}
                </tr>
            </thead>

            <tbody>
                <List name={fieldName}>
                    {(fields) => fields?.map(({ name }) => (
                        <Field key={name} name={[name, "accessor"]}>
                            {({ value }) => (
                                <Row
                                    key={name}
                                    fieldName={fieldName}
                                    name={name}
                                    config={map[value]}
                                    canSort={canSort}
                                />
                            )}
                        </Field>
                    ))}
                </List>
            </tbody>
        </table>
    );
}

export default ColumnsTable;
