import { useCallback } from "react";
import { useUploadApi } from "../../../../../../utils/api";

export const useUpload = () => {
  const { uploadDelete, uploadPost } = useUploadApi();

  const upload = useCallback(
    (data, code, language, company) => {
      const formData = new FormData();

      for (let i = 0; i < Object.values(data)?.length; i++) {
        formData.append("files", data[i]);
      }

      return uploadPost(`/upload/flyers/${code}/${language}`, {
        baseURL: "",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Company: company,
        },
      });
    },
    [uploadPost]
  );

  const remove = useCallback((url) => {
    return uploadDelete(url, {
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }, [uploadDelete]);

  return { upload, remove };
};
