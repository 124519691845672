import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";

function Filters({
	countries,
	setCountries,
	locations,
	setLocations,
	disabled,
}) {
	const { t } = useTranslation();

	const onCountriesChange = useCallback(
		(value) => {
			setCountries(value);
			setLocations([]);
		},
		[setCountries, setLocations],
	);

	const onLocationsChange = useCallback(
		(value) => {
			setLocations(value);
		},
		[setLocations],
	);

	const locationUrl = useMemo(() => {
		const query = new URLSearchParams();

		if (countries) {
			countries.forEach(({ id }) => {
				query.append("country", id.toString());
			});
		}

		query.set("pagination", "false");

		return `/locations?${query.toString()}`;
	}, [countries]);

	return (
		<>
			<div className="mb-3">
				<p className="h4 font-weight-bolder text-sm">
					{t("choose-country")}
				</p>

				<ResourceSelect
					mode="multiple"
					labelPropName="name"
					className="w-full"
					resourcePath="/countries?pagination=false"
					placeholder={t("select-countries")}
					value={countries}
					onChange={onCountriesChange}
					hasSearch
					hasCompany={false}
					disabled={disabled}
				/>
			</div>

			<div className="mb-3">
				<p className="h4 font-weight-bolder text-sm">
					{t("choose-location")}
				</p>

				<ResourceSelect
					mode="multiple"
					className="w-full"
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath={locationUrl}
					placeholder={t("select-locations")}
					value={locations}
					onChange={onLocationsChange}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={disabled}
				/>
			</div>
		</>
	);
}

export default Filters;
