import { useContext, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import QRCodeStyling from "qr-code-styling";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { getUploadUrl } from "../../../../../utils/helpers/upload";
import { useMainCompany } from "../../../../../utils/hooks/company";

function ComponentToPrint({ id, location, description, project }) {
	const { t } = useTranslation();
	const company = useMainCompany();
	const { groups } = useContext(OrgLevelGroupsContext);

	const group = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	useEffect(() => {
		const qrCode = new QRCodeStyling({
			width: 700,
			height: 700,
			data: `${window.location.origin}/qr-code-clock-in/${id}`,
			image: "/assets/images/logo_min.png",
			dotsOptions: {
				color: "#000",
				type: "rounded",
			},
			imageOptions: {
				crossOrigin: "anonymous",
				margin: 20,
			},
		});
		document.getElementById(`qr-image-${id}`).innerHTML = "";
		qrCode.append(document.getElementById(`qr-image-${id}`));
	}, [id]);

	return (
		<div
			id={`qr-content-${id}`}
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<div className="d-flex justify-content-center mx-5" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
				<img
					alt=""
					src={
						company?.logo
							? getUploadUrl(company.logo)
							: "/assets/images/logo-horizontal.png"
					}
					style={{ maxHeight: 200, maxWidth: 700 }}
				/>
			</div>

			<div style={{ marginTop: 20 }} />

			<div id={`qr-image-${id}`} />

			<div style={{ marginTop: 20 }} />

			<div className="d-flex justify-content-around my-7 w-100 mx-5">
				<div className="d-flex flex-column">
					{location && (
						<>
							<h2 className="font-weight-bolder text-dark display-3">
								{t("location")}:
							</h2>

							<h3 className="font-weight-bolder text-dark display-4">
								{renderSettingsResourceLabel(location)}
							</h3>
						</>
					)}
				</div>

				<div className="d-flex flex-column">
					{project && (
						<>
							<h2 className="font-weight-bolder text-dark display-3">
								{group ? group.description : t("level")}:
							</h2>

							<h3 className="font-weight-bolder text-dark display-4">
								{project.description}
							</h3>
						</>
					)}
				</div>
			</div>

			<div className="w-100 mb-5 d-flex flex-column align-items-center">
				<h2 className="font-weight-bolder text-dark display-3">
					{t("description")}:
				</h2>

				<h3 className="font-weight-bolder text-dark display-4">
					{description}
				</h3>
			</div>
		</div>
	);
}

export default ComponentToPrint;
