import Loading from "../Loading";

function AppLoader({ children }) {
	return (
		<div className="h-100 d-flex justify-content-center align-items-center flex-column">
			<Loading />
            {children}
		</div>
	);
}

export default AppLoader;
