import { useCallback } from "react";
import { useVisible } from "../../../../../../utils/hooks/useVisible";
import { useUpdateTimeSheet } from "../../../../api/useUpdateTimeSheet";
import useApi from "../../../../../../utils/api";
import { useDateFilters } from "../../../../hooks/useDateFilters";
import { formatTimeSheetParams } from "../../../../api/helpers";
import { useActionWithAccess } from "../../../../../../utils/hooks/access";

export const useEdit = ({ refetch, filters }) => {
	const { visible, open, close, selected } = useVisible();
	const { authGet } = useApi();
	const { from, to } = useDateFilters();

	const { submit, loading, error } = useUpdateTimeSheet();

	const submitEdit = useActionWithAccess(
		useCallback(
			(id, data, onSuccess) => {
				submit(id, { note: data?.note }, async ({ user: { id } }) => {
					return await authGet(`/time-sheet/user/${id}`, {
						params: {
							userId: id,
							...formatTimeSheetParams({
								from,
								to,
								filters
							}),
						},
					}).then((response) => {
						refetch(response);
						onSuccess?.();
					})
				});
			},
			[submit, refetch, authGet, from, to, filters],
		),
		"timeSheet.canEdit",
	);

	return {
		visible,
		open,
		close,
		selected,
		loading,
		error,
		submit: submitEdit,
	};
};
