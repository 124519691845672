import { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import {
	Row,
	Col,
	FormGroup,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
} from "reactstrap";
import { toast } from "react-toastify";
import classNames from "classnames";
import UploadInput from "../../../../components/Inputs/Upload";
import Layout from "../Layout";
import Content from "../Layout/Content";
import { useAuthentication } from "../../context";
import { useCompanyLogoUpload } from "../../../../utils/api/upload";
import { getUploadUrl } from "../../../../utils/helpers/upload";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const fileTypes = ["image/jpeg", "image/png"];
const fileSizeLimit = 2;

function Step2({ submit, skip, values, goBack, loading, step }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { company } = useAuthentication();
	const { upload: uploadLogo, remove: deleteLogo } = useCompanyLogoUpload();
	const [error, setError] = useState(null);

	const [isLogoUploading, setIsLogoUploading] = useState(false);
	const [logo, setLogo] = useState(values?.logo || company?.logo || null);

	const deleteCompanyLogo = useCallback(() => {
		form.resetFields();
		setLogo(null);
	}, [setLogo, form]);

	const preview = useMemo(() => {
		if (!logo) {
			return null;
		}
		if (typeof logo === "string") {
			return getUploadUrl(logo);
		} else {
			return URL.createObjectURL(logo);
		}
	}, [logo]);

	const onChange = useCallback(
		(info) => {
			if (
				!info.target.files[0].type ||
				!fileTypes.includes(info.target.files[0].type)
			) {
				toast.error(t("jpg-png-file"));
				setError(t("jpg-png-file"));
				return false;
			}

			if (
				!info.target.files[0].size ||
				info.target.files[0].size > fileSizeLimit * 1024 * 1024
			) {
				toast.error(t("image-size-rule"));
				setError(t("image-size-rule"));
				return false;
			}

			setLogo(info.target.files[0]);
			setError(null);
		},
		[setLogo, t, setError],
	);

	const onFinish = useCallback(
		async (data) => {
			let logoUrl = "";

			if (logo === null) {
				logoUrl = "";
			} else if (typeof logo === "string") {
				logoUrl = logo;
			} else {
				setIsLogoUploading(true);
				try {
					const res = await uploadLogo(logo);
					if (res) {
						logoUrl = res.path;
					}
				} catch (err) {
					toast.error(t("logo-upload-failed"));
				} finally {
					setIsLogoUploading(false);
				}
			}

			submit({
				...data,
				logo: logoUrl,
			});

			if (logoUrl === "") {
				await deleteLogo();
			}
		},
		[submit, uploadLogo, logo, deleteLogo, setIsLogoUploading, setLogo],
	);

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = useCallback(
		() => setDropdownOpen((prevState) => !prevState),
		[setDropdownOpen],
	);

	useEffect(() => {
		const formErrors = generateErrorsConfigForForm(["logo"], error);
		form.setFields(formErrors);
	}, [form, error]);

	return (
		<Layout step={step}>
			<Content
				cardTitle="branding"
				form={form}
				onFinish={onFinish}
				initiialValues={values}
				skip={skip}
				goBack={goBack}
				loading={loading || isLogoUploading}
				step={step}
			>
				<Row className="mt-3">
					<Col>
						{preview && (
							<Row className="justify-content-center align-items-center d-flex mb-2">
								<img
									alt=""
									src={preview}
									className="mb-1 w-100 mb-2"
									height={300}
								/>

								<FontAwesomeIcon
									icon={faTrash}
									onClick={deleteCompanyLogo}
									className="cursor-pointer text-red"
								/>
							</Row>
						)}

						<UploadInput
							name="logo"
							onChange={onChange}
							fileList={[]}
							multiple={false}
							label={t("upload-logo")}
							fileTypes={fileTypes}
							fileSizeLimit={fileSizeLimit}
							insertText={t("upload-logo")}
							className={classNames("files", preview && "d-none")}
						/>

						{error}
						<FormGroup className="m-0 col-12 p-0">
							<ButtonDropdown
								isOpen={dropdownOpen}
								toggle={toggle}
								className="d-flex justify-content-between col-12 p-0"
							>
								<DropdownToggle
									caret
									className="d-flex justify-content-between align-items-center shadow text-muted bg-white btn-white bg-gradient-white"
								>
									{t("mobile-theme")}
								</DropdownToggle>

								<DropdownMenu className="col-12 shadow">
									<DropdownItem>Action</DropdownItem>
									<DropdownItem>Action</DropdownItem>
									<DropdownItem>Action</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						</FormGroup>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
}

export default Step2;
