import { useState, useCallback, useEffect } from "react";
import DateInput from "../../../../components/Inputs/DateInput";
import moment from "moment-timezone";

const RangeDateInput = ({
    startDate: parentStartDate,
    endDate: parentEndDate,
    onChange: parentOnChange,
    ...rest
}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [maxDate, setMaxDate] = useState();

    const onChange = useCallback((value) => {
        const [start, end] = value;
        setMaxDate(moment(start).add(30, "days").toDate());
        if (end) {
            parentOnChange?.([start, end]);
        } else {
            setStartDate(start);
            setEndDate(end);
        }
    }, [parentOnChange, setStartDate, setEndDate, setMaxDate]);

    useEffect(() => {
        setStartDate(parentStartDate);
        setEndDate(parentEndDate);
    }, [parentStartDate, parentEndDate, setStartDate, setEndDate]);

    return (
        <DateInput
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            selectsRange
            maxDate={maxDate}
            {...rest}
        />
    );
}

export default RangeDateInput;