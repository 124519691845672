import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../components/Field";
import CustomTimeInput from "../../../../components/Inputs/CustomTimeInput";
import { applyTimezone } from "../../../../utils/helpers/date";

function Clocks({ timezone }) {
	const { t } = useTranslation();
	const [error, setError] = useState(null);

	return (
		<>
			<div>
				<h5>{t("clock-in")}</h5>

				<Field name="startDate" className="border-r-2">
					<CustomTimeInput />
				</Field>

				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) => {
						const startDate = getFieldValue("startDate");
						const endDate = getFieldValue("endDate");

						const _endDate = applyTimezone(
							moment(endDate),
							timezone,
							true,
						);

						if (
							moment(startDate).isAfter(
								moment(_endDate),
								"seconds",
							)
						) {
							setError(
								t("clockout-time-bigger-than-clockin-time"),
							);
						} else {
							setError(null);
						}

						return (
							<>
								<h5>{t("clock-out")}</h5>

								<Field name="endDate">
									<CustomTimeInput />
								</Field>
							</>
						);
					}}
				</BaseField>
			</div>

			{error !== null && (
				<p className="text-danger font-weight-bold text-sm">{error}</p>
			)}
		</>
	);
}

export default Clocks;
