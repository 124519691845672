import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Form from "rc-field-form";
import { CardBody } from "reactstrap";
import { useCompanyApi } from "../../../../utils/api/company";
import { useCompany } from "../../../../utils/hooks/company";
import FormContent from "../../General/General/FormContent";
import { formatSubmittedData } from "../../General/General/format";
import Header from "../Layout/Header";
import { useSettingsOnboarding } from "../context";
import { useAuthentication } from "../../../Authentication/context";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { adaptMomentToCompany } from "../../../../utils/helpers/adaptMomentToCompany";
import { useCompanyLogoUpload } from "../../../../utils/api/upload";

function GeneralSettings() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { submit, error } = useSettingsOnboarding();
	const { dispatch, user } = useAuthentication();
	const { updateCompany } = useCompanyApi();
	const company = useCompany();
	const { upload: uploadLogo, uploadBackgroundImage } =
		useCompanyLogoUpload();
	const [images, setImages] = useState({
		logo: company?.logo || null,
		backgroundImage: company?.backgroundImage || null,
	});

	const onFinish = useCallback(
		async (values) => {
			if (!company) {
				return;
			}

			let logoUrl = "",
				backgroundImageUrl = "";

			if (images?.logo === null) {
				logoUrl = "";
			} else if (typeof images?.logo === "string") {
				logoUrl = images.logo;
			} else if (images?.logo !== company?.logo) {
				try {
					const res = await uploadLogo(images?.logo);
					if (res) {
						logoUrl = res.path;
					}
				} catch (err) {
					toast.error(t("logo-upload-failed"));
				}
			}

			if (images?.backgroundImage === null || !images?.backgroundImage) {
				backgroundImageUrl = "";
			} else if (typeof images?.backgroundImage === "string") {
				backgroundImageUrl = images.backgroundImage;
			} else if (images?.backgroundImage !== company?.backgroundImage) {
				try {
					const res = await uploadBackgroundImage(
						images?.backgroundImage,
					);
					if (res) {
						backgroundImageUrl = res.path;
					}
				} catch (err) {
					toast.error(t("logo-upload-failed"));
				}
			}
			const updatedCompany = formatSubmittedData(company, {
				...values,
				logo: logoUrl,
				backgroundImage: backgroundImageUrl,
			});

			submit(
				true,
				updateCompany(company.id, updatedCompany),
				(response) => {
					adaptMomentToCompany(response, user);
					dispatch({
						type: "set-company",
						payload: {
							company: response,
						},
					});
				},
			);
		},
		[
			company,
			submit,
			dispatch,
			images,
			user,
			updateCompany,
			uploadBackgroundImage,
			uploadLogo,
		],
	);

	useEffect(() => {
		form.setFieldsValue({
			settings: {
				maxShiftLength: 900,
			},
		});
	}, [form, company]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], error);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<Form form={form} initialValues={company} onFinish={onFinish}>
			<Header />

			<CardBody>
				<FormContent
					form={form}
					setImages={setImages}
					images={images}
				/>
			</CardBody>
		</Form>
	);
}

export default GeneralSettings;
