import { useMemo } from "react";
import EmployeeTypes from "../../features/Settings/General/EmployeeTypes";
import UserGroup from "../../features/Settings/General/UserGroup";
import General from "../../features/Settings/General/General";
import JobFamilies from "../../features/Settings/General/JobFamilies";
import JobGroups from "../../features/Settings/General/JobGroups";
import Jobs from "../../features/Settings/General/Jobs";
import Locations from "../../features/Settings/General/Locations";
import OrganisationLevelGroups from "../../features/Settings/General/OrganisationLevelGroups";
import OrganisationLevel from "../../features/Settings/General/OrganisationLevels";
import Roles from "../../features/Settings/General/Roles";
import Tasks from "../../features/Settings/General/Tasks";
import Company from "../../features/Settings/General/Company";
import PayGroup from "../../features/Settings/PayPolicies/PayGroup";
import PayCode from "../../features/Settings/PayPolicies/PayCode";
import EarningGroup from "../../features/Settings/PayPolicies/EarningGroup";
import Holiday from "../../features/Settings/PayPolicies/Holiday";
import TimeOffPayPolicy from "../../features/Settings/PayPolicies/TimeOffRequestCode";
import CalculationGroup from "../../features/Settings/PayPolicies/CalculationGroup";
import Budget from "../../features/Settings/PayPolicies/Budget";
import UltiPro from "../../features/Settings/Integration/UltiPro";
import WhitelistNumber from "../../features/Settings/ClockIn/WhitelistNumber";
import QrCode from "../../features/Settings/ClockIn/QrCode";
import Device from "../../features/Settings/ClockIn/Device";
import Uta from "../../features/Settings/Integration/Uta";
import Union from "../../features/Settings/General/Union";
import TimesheetCustomFields from "../../features/Settings/CustomFields/Timesheet";
import CalculationGroupRule from "../../features/Settings/Rules/CalculationGroup";
import Project from "../../features/Settings/General/Project";
import Reports from "../../features/Settings/Scheduling/Reports";
import JobSchedule from "../../features/Settings/Scheduling/Jobs";
import TimesheetMyTeamConfig from "../../features/Settings/Configuration/Timesheet/MyTeam";
import MyTimesheetConfig from "../../features/Settings/Configuration/Timesheet/MyTimesheet";
import ManagerViewConfig from "../../features/Settings/Configuration/Timesheet/ManagerView";
import WeeklyConfig from "../../features/Settings/Configuration/Timesheet/Weekly";
import Flyers from "../../features/Settings/ClockIn/Flyers";
import Rate from "../../features/Settings/Configuration/Timesheet/Rate";
import ClockInCustomFields from "../../features/Settings/CustomFields/Clockin";
import Attestation from "../../features/Settings/ClockIn/Attestation";
import Scheduler from "../../features/Settings/General/Scheduler";
import { useAccessInList } from "../../utils/hooks/access";
import { getViewPermissions } from "../../utils/helpers/access";

export const useSettingsRoutes = (permissions) => {
	const generalSettings = useMemo(
		() =>
		  permissions?.settings?.general
			? getViewPermissions(permissions?.settings?.general, "settings.general")
			: [],
		[permissions?.settings?.general]
	);
	
	const { hasAccess: canViewGeneralSettings } = useAccessInList(generalSettings);

	const { hasAccess: canViewClockinSettings } = useAccessInList([
		"device.canView",
		"qrCode.canView",
		"whitelistNumber.canView",
		"settings.flyer.canView"
	]);

	const payPoliciesSettings = useMemo(
		() =>
		  permissions?.settings?.payPolicies
			? getViewPermissions(
			  permissions?.settings?.payPolicies,
			  "settings.payPolicies"
			)
			: [],
		[permissions?.settings?.payPolicies]
	);
	
	const { hasAccess: canViewPayPolicies } = useAccessInList(payPoliciesSettings);

	const { hasAccess: canViewIntegration } = useAccessInList([
		"settings.integration.ultipro.canView",
		"settings.integration.uta.canView"
	]);

	return useMemo(() => ({
		path: "settings",
		access:
				canViewGeneralSettings ||
				canViewClockinSettings ||
				canViewPayPolicies ||
				canViewIntegration ||
				permissions?.settings?.configuration?.canView || 
				permissions?.settings?.scheduling?.reports?.canView ||
				permissions?.settings?.scheduling?.jobs?.canView || 
				permissions?.settings?.general?.rules?.calculationGroup?.canView || 
				permissions?.settings?.general?.customFields?.canView ||
				permissions?.settings?.general?.organizationLevelGroup.canView,
		children: [
			{
				path: "general",
				access: canViewGeneralSettings,
				children: [
					{ index: true, element: <General />, access: permissions?.settings?.general?.canView },
					{
						path: "groups",
						access: permissions?.settings?.general?.organizationLevelGroup.canView,
						children: [
							{ 
								index: true, 
								element: <OrganisationLevelGroups />,
								access: permissions?.settings?.general?.organizationLevelGroup.canView
							},
							{ 
								path: ":id/levels", 
								element: <OrganisationLevel />,
								access: permissions?.settings?.general?.organizationLevelGroup.organizationLevel.canView
							},
						],
					},
					{ 
						path: "companies", 
						element: <Company />, 
						access: permissions?.settings?.general?.companies?.canView 
					},
					{ 
						path: "locations", 
						element: <Locations />, 
						access: permissions?.settings?.general?.locations?.canView 
					},
					{ 
						path: "employee-type", 
						element: <EmployeeTypes />,
						access: permissions?.settings?.general?.employeeTypes?.canView  
					},
					{ 
						path: "user-group", 
						element: <UserGroup />,
						access: permissions?.settings?.general?.userGroup?.canView 
					},
					{
						path: "scheduler",
						element: <Scheduler />,
						access: permissions?.settings?.general?.employeeTypes?.canView
					},
					{ 
						path: "union", 
						element: <Union />,
						access: permissions?.settings?.general?.union?.canView 
					},
					{ 
						path: "projects", 
						element: <Project />,
						access: permissions?.settings?.general?.project?.canView 
					},
					{ 
						path: "job-groups", 
						element: <JobGroups />,
						access: permissions?.settings?.general?.jobGroups?.canView 
					},
					{ 
						path: "job-families", 
						element: <JobFamilies />,
						access: permissions?.settings?.general?.jobFamilies?.canView 
					},
					{ 
						path: "jobs", 
						element: <Jobs />,
						access: permissions?.settings?.general?.jobs?.canView 
					},
					{ 
						path: "roles", 
						element: <Roles />,
						access: permissions?.settings?.general?.roles?.canView 
					},
					{ 
						path: "tasks", 
						element: <Tasks />,
						access: permissions?.settings?.general?.tasks?.canView 
					},
				],
			},
			{
				path: "pay-policy",
				access: canViewPayPolicies,
				children: [
					{ 
						path: "pay-group", 
						element: <PayGroup />,
						access: permissions?.settings?.payPolicies?.payGroup?.canView 
					},
					{ 
						path: "pay-code", 
						element: <PayCode />,
						access: permissions?.settings?.payPolicies?.payCode?.canView 
					},
					{ 
						path: "earning-group", 
						element: <EarningGroup />,
						access: permissions?.settings?.payPolicies?.earningGroup?.canView  
					},
					{ 
						path: "budget", 
						element: <Budget />,
						access: permissions?.settings?.payPolicies?.budget?.canView 
					},
					{ 
						path: "holiday-calendar", 
						element: <Holiday />,
						access: permissions?.settings?.payPolicies?.holidays?.canView  
					},
					{ 
						path: "time-off", 
						element: <TimeOffPayPolicy />,
						access: permissions?.settings?.payPolicies?.timeOff?.canView  
					},
					{ 
						path: "calculation-group", 
						element: <CalculationGroup />,
						access: permissions?.settings?.payPolicies?.calculationGroup?.canView 
					},
				],
			},
			{
				path: "integration",
				access: canViewIntegration,
				children: [
					{ 
						path: "ultipro", 
						element: <UltiPro />,
						access: permissions?.settings?.integration?.ultipro?.canView 
					},
					{ 
						path: "uta", 
						element: <Uta />,
						access: permissions?.settings?.integration?.uta?.canView 
					},
				],
			},
			{
				path: "clock-in",
				access: canViewClockinSettings,
				children: [
					{
						path: "whitelist-number",
						element: <WhitelistNumber />,
						access: permissions?.whitelistNumber?.canView
					},
					{
						path: "qr-code",
						element: <QrCode />,
						access: permissions?.qrCode?.canView
					},
					{
						path: "device",
						element: <Device />,
						access: permissions?.device?.canView
					},
					{
						path: "flyers",
						element: <Flyers />,
						access: permissions?.settings?.flyer?.canView
					},
					{
						path: "attestation",
						element: <Attestation />,
						access: permissions?.settings?.attestation?.canView
					}
				],
			},
			{
				path: "custom-fields",
				access: permissions?.settings?.general?.customFields?.canView,
				children: [
					{
						path: "timesheet",
						element: <TimesheetCustomFields />,
						access: permissions?.settings?.general?.customFields?.canView 
					},
					{
						path: "clock-in",
						element: <ClockInCustomFields />,
						access: permissions?.settings?.general?.customFields?.canView 
					},
				],
			},
			{
				path: "rules",
				access: permissions?.settings?.general?.rules?.calculationGroup?.canView ,
				children: [
					{
						path: "calculation-group",
						element: <CalculationGroupRule />,
						access: permissions?.settings?.general?.rules?.calculationGroup?.canView 
					},
				],
			},
			{
				path: "job-scheduling",
				access: permissions?.settings?.scheduling?.reports?.canView ||
						permissions?.settings?.scheduling?.jobs?.canView,
				children: [
					{
						path: "reports",
						element: <Reports />,
						access: permissions?.settings?.scheduling?.reports?.canView,
					},
					{
						path: "jobs",
						element: <JobSchedule />,
						access: permissions?.settings?.scheduling?.jobs?.canView,
					},
				],
			},
			{
				path: "configuration",
				access: permissions?.settings?.configuration?.canView,
				children: [
					{
						path: "timesheet",
						children: [
							{
								path: "team",
								element: <TimesheetMyTeamConfig />,
								access: permissions?.settings?.configuration?.canView,
							},
							{
								path: "manager-view",
								element: <ManagerViewConfig />,
								access: permissions?.settings?.configuration?.canView,
							},
							{
								path: "weekly",
								element: <WeeklyConfig />,
								access: permissions?.settings?.configuration?.canView,
							},
							{
								path: "my",
								element: <MyTimesheetConfig />,
								access: permissions?.settings?.configuration?.canView,
							},
							{
								path: "rate",
								element: <Rate />,
								access: permissions?.settings?.configuration?.canView,
							}
						]
					}
				]
			}
		],
	}), [permissions, canViewClockinSettings, canViewGeneralSettings, canViewIntegration, canViewPayPolicies])
}