import { result } from "lodash";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import AppLoader from "../../components/Loaders/AppLoader";
import { useAuthentication } from "../../features/Authentication/context";

export const getAccessValue = (map, accessPath) =>
	!!result(map, accessPath, false);

export const hasAccessInList = (map, accessPaths) =>
	accessPaths.some((accessPath) => getAccessValue(map, accessPath));

export const hasAllAccessInList = (map, accessPaths) =>
	accessPaths?.every((accessPath) => getAccessValue(map, accessPath));

export const useModuleAccess = (accessPath) => {
	const { accessMap, authenticating } = useAuthentication();

	const access = useMemo(
		() => (accessMap ? result(accessMap, accessPath, false) : null),
		[accessPath, accessMap],
	);

	return { access, authenticating };
};

export const useAccess = (accessPath) => {
	const { accessMap, authenticating } = useAuthentication();

	const hasAccess = useMemo(
		() => (accessMap ? getAccessValue(accessMap, accessPath) : false),
		[accessPath, accessMap],
	);

	return { hasAccess, authenticating };
};

export const useAccessInList = (accessPaths) => {
	const { accessMap, authenticating } = useAuthentication();

	const hasAccess = useMemo(() => {
		if (!accessMap) {
			return false;
		}
		return hasAccessInList(accessMap, accessPaths);
	}, [accessMap, accessPaths]);

	return { hasAccess, authenticating };
};

export const useAllAccessInList = (accessPaths) => {
	const { accessMap, authenticating } = useAuthentication();

	const hasAccess = useMemo(() => {
		if (!accessMap) {
			return false;
		}
		return hasAllAccessInList(accessMap, accessPaths);
	}, [accessMap, accessPaths]);

	return { hasAccess, authenticating };
};

export const useActionWithAccess = (call, permission) => {
    const { hasAccess } = useAccess(permission);
    return useMemo(() => {
        if (!hasAccess) {
            return null;
        }
        return call;
    }, [call, hasAccess]);
}

export const Access = ({
	children,
	accessPath,
	loader = <AppLoader />,
	fallback = null,
}) => {
	const { hasAccess, authenticating } = useAccess(accessPath);
	return <>{hasAccess ? children : authenticating ? loader : fallback}</>;
};

export const useAccessAllowance = (accessPath, redirectUrl = "/") => {
	const navigate = useNavigate();
	const { hasAccess, authenticating } = useAccess(accessPath);
	useEffect(() => {
		if (!authenticating && !hasAccess) {
			navigate(redirectUrl);
		}
	}, [hasAccess, authenticating, navigate, redirectUrl]);
};
