import { useCallback, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Form from "rc-field-form";
import QRCodeStyling from "qr-code-styling";
import Modal from "../../../../components/Modal";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import Button from "../../../../components/Button";
import { ProfileContext } from "../../context";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../utils/api/profile";
import { usePeopleApi } from "../../../../utils/api/people";

const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    data: "",
    image: "/assets/images/logo_min.png",
    dotsOptions: {
        color: "#000",
        type: "rounded",
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
    },
});

function QrCodeModal({ isOpen, close }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const ref = useRef(null);
    const { user, submitting, submittingError, submit } = useContext(ProfileContext);

    const { authVerify: profileSubmit } = useProfileApi();
    const { authVerify: peopleSubmit } = usePeopleApi();

    const onFinish = useCallback((values) => {
        submit(
            profileSubmit,
            peopleSubmit,
            values,
            () => {
                localStorage.setItem("OTP_VALIDATE", "true");
                close();
            });
    }, [submit, peopleSubmit, profileSubmit, close]);

    useEffect(() => {
        qrCode.update({
            data: `${user?.twoFA?.otp_auth_url}?secret=${user?.twoFA?.otp_base32}`
        });
    }, [user?.twoFA, qrCode]);

    useEffect(() => {
        qrCode.append(ref.current);
    }, [qrCode, ref.current]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["token"],
            submittingError,
        );
        form.setFields(fieldErrors);
    }, [form, submittingError])

    return (
        <Modal
            title={
                <div className="d-flex justify-content-between">
                    {t("two-factor-auth") + " (2FA)"}
                    <FontAwesomeIcon
                        icon={faXmark}
                        className="cursor-pointer"
                        onClick={close}
                    />
                </div>
            }
            isOpen={isOpen}
            centered
        >
            <h4 className="text-primary border-bottom py-1">
                {t("config-google-auth")}
            </h4>

            <ol type="1" className="m-0 px-4 py-3">
                <li>{t("install-google-authenticator")}</li>
                <li>{t("select-plus-icon")}</li>
                <li>{t("select-scan-a-barcode")}</li>
            </ol>

            <h4 className="text-primary border-bottom py-1">
                {t("scan-qr-code-to-generate-verify-code")}
            </h4>

            <div ref={ref} className="text-center" />

            <h4 className="text-primary border-bottom py-1">
                {t("enter-code-into-your-app")}
            </h4>

            <div className="d-flex my-3">
                <span className="font-weight-bold mr-2">{t("secret-key")}:</span>
                {user?.twoFA?.otp_base32}
            </div>

            <h4 className="text-primary border-bottom py-1">
                {t("verify-code")}
            </h4>
            <p className="text-xs">{t("verify-auth-code")}</p>

            <Form form={form} onFinish={onFinish}>
                <Field
                    name="token"
                    rules={[
                        {
                            required: true,
                            message: t("required-auth-code")
                        }
                    ]}
                >
                    <Text placeholder={t("auth-code")} />
                </Field>

                <div className="d-flex justify-content-end">
                    <Button
                        className="mr-2"
                        htmlType="button"
                        disabled={submitting}
                        onClick={close}
                    >
                        {t("close")}
                    </Button>

                    <Button
                        color="primary"
                        htmlType="submit"
                        loading={submitting}
                    >
                        {t("verify-and-activate")}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default QrCodeModal;
