import { useMemo } from "react";
import { useJobValidations } from "../../../../../components/TimeSheetTable/hooks/useTable/validations/job";
import { useLevelsValidations } from "../../../../../components/TimeSheetTable/hooks/useTable/validations/levels";
import { useLocationValidations } from "../../../../../components/TimeSheetTable/hooks/useTable/validations/location";
import { usePayCodeValidations } from "../../../../../components/TimeSheetTable/hooks/useTable/validations/payCode";

export const useValidationConfig = () => {
  const job = useJobValidations();
  const location = useLocationValidations();
  const payCode = usePayCodeValidations();
  const levels = useLevelsValidations();

  return useMemo(() => {
    return {
      job,
      location,
      payCode,
      ...levels,
    };
  }, [job, location, payCode, levels]);
}
