import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import {
	useIsMasterCompany,
} from "../../../../utils/hooks/company";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../utils/helpers/settings";
import {createColumnHelper} from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone }) => {
	const { t } = useTranslation();
	const isMasterCompany = useIsMasterCompany();

	const {
        description,
        status,
        actions,
    } = useCommonColumns({ edit, remove, clone });

	return useMemo(() => {
		const columns = [
            description,
			columnHelper.accessor("config", {
				enableColumnFilter: true,
				header: t("job"),
				cell: (info) => {
                    const value = info.getValue();
					return value && renderSettingsResourceLabel(value);
                },
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/jobs"
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
						mode="multiple"
					/>
				),
			}),
			status,
		];

		if (isMasterCompany && actions) {
			columns.push(actions);
		}
		return columns;
	}, [
		t,
		isMasterCompany,
        description,
        status,
		actions,
	]);
};
