import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import Select from "../../../../components/Inputs/Select";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { firstToUpper } from "../../../../utils/helpers/string";

const columnHelper = createColumnHelper();

const manageAbilityOptions = ["by-calculation-groups", "by-pay-groups", "by-location", "by-company"];

export const useColumns = ({ edit, remove, clone, openAllocatedResourceTooltip, openDaysTooltip }) => {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();

    const { code, description, status, actions } = useCommonColumns({ edit, remove, clone });

    return useMemo(() => {
        const columns = [
            code,
            description,
            status,
            columnHelper.accessor("manageAbility", {
                enableColumnFilter: true,
                header: t("manage-ability"),
                cell: (info) => {
                    const value = info.getValue();
                    return t(value);
                },
                filterType: "select",
                Filter: (
                    <Select>
                        {manageAbilityOptions.map((value) => {
                            return (
                                <Select.Option key={value} value={value}>
                                    {t(value)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                )
            }),
            columnHelper.accessor("allocatedResources", {
                header: t("allocated-resources"),
                cell: (info) => {
                    const value = info.row.original;
                    let allocatedResources, label;

                    if (value.manageAbility === "by-calculation-groups") {
                        allocatedResources = value.calculationGroups?.length;
                        allocatedResources > 1 ? (label = t("calculation-groups")) : (label = t("calculation-group"));
                    }
                    if (value.manageAbility === "by-location") {
                        allocatedResources = value.locations?.length;
                        allocatedResources > 1 ? (label = t("locations")) : (label = t("location"));
                    }
                    if (value.manageAbility === "by-pay-groups") {
                        allocatedResources = value.payGroups?.length;
                        allocatedResources > 1 ? (label = t("pay-groups")) : (label = t("pay-group"));
                    }
                    if (value.manageAbility === "by-company") {
                        allocatedResources = value.companies?.length;
                        allocatedResources > 1 ? (label = t("comapnies")) : (label = t("company"));
                    }

                    return (
                        allocatedResources && (
                            <span
                                id={"allocatedResources" + value.id}
                                onMouseEnter={() => openAllocatedResourceTooltip(value)}
                                className="cursor-pointer "
                            >
                                {allocatedResources} <u>{label}</u>
                            </span>
                        )
                    );
                },
            }),
            columnHelper.accessor("days", {
                header: firstToUpper(t("days")),
                cell: (info) => {
                    const data = info.row.original;
                    const label = data?.days?.length > 1 ? t("days") : t("day");
                    return (
                        <span
                            id={"days" + data.id}
                            onMouseEnter={() => openDaysTooltip(data)}
                            className="cursor-pointer"
                        >
                            <u>{data?.days?.length || 0} {label}</u>
                        </span>
                    )
                },
            }),
        ];
        if (isMasterCompany && actions) {
            columns.push(actions);
        }
        return columns;
    }, [t, isMasterCompany, code, description, status, actions, openAllocatedResourceTooltip, openDaysTooltip]);
};
