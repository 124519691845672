import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import "./style.scss";

function EmailsTooltip({ close, isOpen, selected }) {
	const { t } = useTranslation();

	const target = useMemo(() => {
		if (selected?.config?.sendVia === "email") {
			return `email-${selected?.id}`;
		} else {
			return `ftp-${selected?.id}`;
		}
	}, [selected]);

	const content = useMemo(() => {
		switch (selected?.config?.sendVia) {
			case "email":
				return (
					<div className="w-100 d-flex flex-column w-100">
						{selected?.config?.emails?.map((email) => (
							<span className="bg-primary rounded p-2 border-bottom text-sm font-weight-500">
								{email}
							</span>
						))}
					</div>
				);
			case "ftp":
				return (
					<table className="ftp-table w-100">
						<tr className="border-bottom">
							<th>{t("protocol")}:</th>
							<td>{selected?.config?.protocol}</td>
						</tr>

						<tr className="border-bottom">
							<th>{t("host")}:</th>
							<td>{selected?.config?.host}</td>
						</tr>

						<tr className="border-bottom">
							<th>{t("port")}:</th>
							<td>{selected?.config?.port}</td>
						</tr>

						<tr>
							<th>{t("path")}:</th>
							<td>{selected?.config?.path}</td>
						</tr>
					</table>
				);
		}
	}, [selected?.config, t]);

	return (
		<Tooltip
			isOpen={isOpen}
			toggle={close}
			trigger="hover"
			autohide={false}
			target={target}
			placement="left"
			className="email-ftp-tooltip"
		>
			{content}
		</Tooltip>
	);
}

export default EmailsTooltip;
