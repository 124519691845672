import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import DaysList from "./DaysList";
import ImportHolidays from "./ImportHolidays";
import ManageAbilitySelect from "./ManageAbilitySelect";

function Content({ mode, form, disabled }) {
    return (
        <>
            <BaseSettingsFormContent mode={mode} disabled={disabled} />

            <ManageAbilitySelect form={form} disabled={disabled} />

            <ImportHolidays form={form} disabled={disabled} />

            <DaysList form={form} disabled={disabled} />
        </>
    );
}

export default Content;
