import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import {
	Card,
	Container,
	Row,
	Col,
	CardBody,
	CardHeader,
	CardText,
	CardTitle,
	CardFooter,
	CardLink,
} from "reactstrap";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Password from "../../../components/Inputs/Password";
import { generateErrorsConfigForForm } from "../../../utils/helpers/errors";

function PasswordForm({ submit, submitting, error }) {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const onFinish = useCallback(
		({ plainPassword }) => {
			submit({
				plainPassword,
				oldPassword: "",
			});
		},
		[submit]
	);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["plainPassword"],
			error
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Row className="layout justify-content-center h-100 m-0">
			<Col
				xl={6}
				lg={5}
				md={6}
				className="d-flex flex-column mx-lg-0 mx-auto"
			>
				<Container>
					<div className="header-body text-center mb-7 mt-4">
						<Row className="justify-content-center">
							<Col className="px-5" lg="6" md="8" xl="5">
								<div className="d-flex justify-content-center sticky-top">
									<img
										src="/assets/images/logo-horizontal.png"
										alt=""
										width="250"
									/>
								</div>
							</Col>
						</Row>
					</div>
				</Container>

				<Card className="shadow-none bg-transparent my-7 mx-auto px-7">
					<CardHeader className="bg-transparent pb-2 border-bottom-0 text-center">
						<CardTitle className="h3 font-weight-bolder mb-1">
							{t("welcome-to-simplitime")}
						</CardTitle>

						<CardText className="mb-2">
							{t("get-started-with-simplitime")}
						</CardText>
					</CardHeader>

					<CardBody className="border-bottom-0 text-center">
						<Form
							form={form}
							onFinish={onFinish}
							layout="vertical"
							scrollToFirstError
						>
							<Field
								name="plainPassword"
								rules={[
									{
										required: true,
										message: t("input-your-password"),
									},
								]}
							>
								<Password
									placeholder={t("password")}
								/>
							</Field>

							<Field
								name="repeatPlainPassword"
								dependencies={["plainPassword"]}
								rules={[
									{
										required: true,
										message: t("confirm-your-password"),
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												!value ||
												getFieldValue(
													"plainPassword"
												) === value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													t("passwords-dont-match")
												)
											);
										},
									}),
								]}
							>
								<Password
									placeholder={t("re-enter-password")}
								/>
							</Field>

							<div className="flex justify-center mb-3">
								<Button
									color="primary"
									type="submit"
									loading={submitting}
								>
									{t("set-password")}
								</Button>
							</div>
						</Form>
					</CardBody>

					<CardFooter className="text-center pt-0 px-lg-2 px-1 d-flex justify-content-center border-top-0 bg-transparent">
						<CardText className="text-sm m-1 mb-4">
							{t("need-help")}{" "}
							<CardLink
								href="#"
								className="mx-1 text-sm text-primary text-gradient font-weight-bold"
							>
								{t("help-center")}
							</CardLink>
						</CardText>
					</CardFooter>
				</Card>
			</Col>

			<Col
				xl={6}
				lg={5}
				md={6}
				className="col-6 d-lg-flex d-none h-100 my-auto pe-0 text-center justify-content-center flex-column"
			>
				<div
					className="position-relative h-100 m-3 px-7 d-flex flex-column justify-content-center overflow-hidden image"
					style={{
						backgroundImage: `linear-gradient(87deg, rgba(94, 114, 228, 0.52), rgba(130, 94, 228, 0.73)), url(/assets/images/landscape-1.jpg)`,
						backgroundSize: "cover",
					}}
				>
					<h4 className="mt-5 text-white font-weight-bolder position-relative text-center">
						"No matter what people tell you, words and ideas can
						change the world."
					</h4>

					<p className="text-white position-relative text-center">
						"Robin Williams"
					</p>
				</div>
			</Col>
		</Row>
	);
}

export default PasswordForm;
