import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Form from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../../../../components/Button";
import Modal from "../../../../../../../../components/Modal";
import { recalculateShifts } from "../helper";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../../../utils/helpers/date";
import { getDurationInputValue } from "../../../../../../../../components/Inputs/DurationInput";
import { useAddedPayCode } from "../../../../../../../TimeSheet/components/TimeSheetTable/hooks/useAddedPayCode";

function ErrorModal({ isOpen, close, selected, form, resource, defaultSegment }) {
    const { t } = useTranslation();
    const date = Form.useWatch("date", form);
    const allShifts = Form.useWatch("shifts", form);
    const defaultPayCode = useAddedPayCode(resource);

    const {
        field,
        value,
        shiftName,
        name,
        text,
    } = selected;

    const calcGroupBreaks = useMemo(() => {
        return resource?.calculationGroup?.breaks;
    }, [resource?.calculationGroup?.breaks]);
    const isMandatory = (calcGroupBreaks?.manual?.status && calcGroupBreaks?.manual?.mandatory) ? true : false;
    const breakAmount = isMandatory && calcGroupBreaks?.manual?.mandatory
        ? Number(calcGroupBreaks?.manual?.duration || 0) : 0;

    const calculateUpShifts = useCallback((upShifts) => {
       return upShifts?.map((shift) => {
            return recalculateShifts(
                shift,
                "end",
                shift?.length - 1,
                value,
                breakAmount * 60,
                defaultSegment,
                false,
                calcGroupBreaks,
                defaultPayCode
            )
        });
    }, [value, breakAmount, defaultSegment, calcGroupBreaks, defaultPayCode]);

    const calculateDownShifts = useCallback((downShifts) => {
        return downShifts?.map((shift) => {
            return recalculateShifts(
                shift,
                "start",
                0,
                value,
                breakAmount * 60,
                defaultSegment,
                false,
                calcGroupBreaks,
                defaultPayCode
            )
        });
    }, [value, breakAmount, defaultSegment, calcGroupBreaks, defaultPayCode])

    const approve = useCallback(() => {
        const start = form.getFieldValue(["shifts", shiftName, name, "start"]);
        const end = form.getFieldValue(["shifts", shiftName, name, "end"]);
        let duration = form.getFieldValue(["shifts", shiftName, name, "duration"]);
        duration = getDurationInputValue(duration || "00:00");

        if (start && (end || duration > 0)) {
            let _upShifts = allShifts?.slice(0, shiftName);
            let _downShifts = allShifts?.slice(shiftName + 1, allShifts?.length);

            if (_upShifts?.length > 0 && field === "start") {
                _upShifts = calculateUpShifts(_upShifts);
            } 

            if (_downShifts?.length > 0 && (field === "end")) {
                _downShifts = calculateDownShifts(_downShifts);
            }

            const _shifts = recalculateShifts(
                form.getFieldValue(["shifts", shiftName]),
                field,
                name,
                value,
                breakAmount * 60,
                defaultSegment,
                true,
                calcGroupBreaks,
                defaultPayCode
            );
                
            form.setFieldValue("shifts", [..._upShifts, _shifts, ..._downShifts])
        }
        close();
    }, [
        form, 
        shiftName, 
        name, 
        breakAmount, 
        calcGroupBreaks, 
        defaultPayCode, 
        value, 
        allShifts,
        calculateDownShifts,
        calculateUpShifts
    ]);

    const cancel = useCallback(() => {
        let downShifts = allShifts?.slice(0, shiftName);
        let upShifts = allShifts?.slice(shiftName + 1, allShifts?.length);
        let currentShift = allShifts?.[shiftName]?.flat();
        downShifts = downShifts?.flat();
        upShifts = upShifts?.flat();
        let endDate = downShifts?.[downShifts?.length - 1]?.end;
        endDate = combineDateAndTime(moment(date), moment(endDate));
        let startDate = upShifts?.[0]?.start;
        startDate = combineDateAndTime(moment(date), moment(startDate));
        if (field === "start") {
            form.setFieldValue(["shifts", shiftName, name, "start"], endDate);
        } else if (field === "end") {
            const duration = moment(startDate).diff(moment(currentShift?.[name]?.start), "seconds");
            form.setFieldValue(
                ["shifts", shiftName, name, "duration"], 
                renderDurationAsFormat(duration, "HH:mm")
            );
            form.setFieldValue(["shifts", shiftName, name, "end"], startDate);
        }
        close();
    }, [close, allShifts, date, shiftName, name, form]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={close}
            centered
            closeOnBackdrop={false}
        >
            <div className="d-flex flex-column align-items-center">
                <FontAwesomeIcon
                    className="text-warning my-4"
                    icon={faWarning} 
                    style={{
                        fontSize: "50px"
                    }}
                />

                <div className="mb-5">
                    {t(text)}
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-end">
                <Button
                    htmlType="button"
                    onClick={cancel}
                >
                    {t("no")}
                </Button>
                <Button
                    color="warning"
                    onClick={approve}
                >
                    {t("yes")}
                </Button>
            </div>
        </Modal>
    );
}

export default ErrorModal;
