import { useEffect, useCallback, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { noop } from "lodash";
import { useCompany } from "../../../utils/hooks/company";
import { initialState, reducer } from "./reducer";
import { formatParams } from "../../../utils/api/format";
import useApi from "../../../utils/api";
import { getToastErrorMessage } from "../../../utils/helpers/errors";
import { getErrorMessage } from "../../../utils/helpers/errors";

const useSettingsCRUDHook = (url) => {
	const { t } = useTranslation();
	const company = useCompany();
	const [loadingExport, setLoadingExport] = useState(false);

	const [state, dispatch] = useReducer(reducer, initialState);

	const { authGet, authPost, authPut, authDelete, call } = useApi();

	const selected = useMemo(() => {
		const data = state.data;
		const selectedId = state.selectedId;
		return selectedId !== null && data !== null
			? data.find((resource) => resource.id === selectedId)
			: undefined;
	}, [state.data, state.selectedId]);

	const setMode = useCallback(
		(mode, selectedId = null) =>
			dispatch({
				type: "changeMode",
				payload: { mode, selectedId },
			}),
		[dispatch],
	);

	const setState = useCallback(
		(updater) => {
			dispatch({
				type: "setState",
				payload:
					typeof updater === "function" ? updater(state) : updater,
			});
		},
		[state, dispatch],
	);

	const setData = useCallback((updater) => {
		setState((prev) => ({
			...prev,
			data: typeof updater === "function"
				? updater(prev.data)
				: updater,
		}));
	}, [setState]);

	const goToView = useCallback(() => setMode("view"), [setMode]);
	const goToHelp = useCallback(() => setMode("help"), [setMode]);
	const goToCreate = useCallback(() => setMode("create"), [setMode]);
	const goToEdit = useCallback((id) => setMode("edit", id), [setMode]);
	const goToClone = useCallback((id) => setMode("clone", id), [setMode]);

	const fetch = useCallback(
		async (params, controller) => {
			dispatch({ type: "requestFetch" });
			try {
				const response = await authGet(url, {
					params: {
						status: ["active", "inactive"],
						...formatParams(params),
					},
					signal: controller?.signal,
				});
				if (response) {
					dispatch({
						type: "fetchSucceeded",
						payload: response,
					});
				}
			} catch (err) {
				dispatch({ type: "fetchFailed", payload: err });
				if (typeof err === "string") {
					toast.error(err);
				}
			}
		},
		[url, authGet, dispatch],
	);

	const createSubmit = useCallback(
		async (data, onSuccess = noop, onFailure = noop) => {
			if (!company?.id) {
				return;
			}
			dispatch({ type: "requestCreate" });
			try {
				const response = await authPost(url, {
					data: { ...data, company: company?.id },
				});
				if (response) {
					dispatch({
						type: "createSucceeded",
						payload: response,
					});
					onSuccess(response);
					toast.success(t("resource-created"));
					goToView();
				}
			} catch (err) {
				dispatch({
					type: "createFailed",
					payload: err,
				});
				onFailure(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			}
		},
		[url, authPost, dispatch, company?.id, goToView, t],
	);

	const updateSubmit = useCallback(
		async (id, data, onSuccess = noop, onFailure = noop) => {
			dispatch({ type: "requestUpdate" });
			try {
				const response = await authPut(`${url}/${id}`, {
					data: { ...data, company: company?.id },
				});
				if (response) {
					dispatch({
						type: "updateSucceeded",
						payload: response,
					});
					onSuccess(response);
					toast.success(t("resource-updated"));
					goToView();
				}
			} catch (err) {
				dispatch({ type: "updateFailed", payload: err });
				onFailure(err);
				if (typeof err === "string") {
					toast.error(err);
				}
			}
		},
		[url, authPut, dispatch, company?.id, goToView, t],
	);

	const removeCall = useCallback(
		(id) => authDelete(`${url}/${id}`),
		[url, authDelete],
	);

	const remove = useCallback(
		async (id, onSuccess = noop, onFailure = noop) => {
			const loading = toast(t("deleting-resouce"), {
				autoClose: false,
			});
			try {
				await removeCall(id);
				dispatch({ type: "delete", payload: id });
				onSuccess(id);
				toast.dismiss(loading);
				toast.success(t("resource-deleted"));
			} catch (err) {
				onFailure(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err));
			}
		},
		[removeCall, dispatch, t],
	);

	const batchRemove = useCallback(
		async (ids, onSuccess = noop, onFailure = noop) => {
			const loading = toast(t("deleting-reources", { count: ids?.length }), {
				autoClose: false,
			});
			try {
				await Promise.all(ids.map((id) => removeCall(id)));
				dispatch({ type: "deleteBatch", payload: ids });
				onSuccess(ids);
				toast.dismiss(loading);
				toast.success(t("resources-deleted"));
			} catch (err) {
				onFailure(err);
				toast.dismiss(loading);
				toast.error(getToastErrorMessage(err));
			}
		},
		[dispatch, t, removeCall],
	);

	useEffect(() => {
		if (state.mode === "edit" && !selected) {
			goToView();
		}
	}, [state.mode, selected, goToView]);

	const settingsExport = useCallback(
		async (url, params) => {
			setLoadingExport(true);
			try {
				const response = await call(
					{
						url,
						params,
						headers: {
							"Content-Type": "Content-Disposition",
						},
						responseType: "arraybuffer",
					},
					true,
				);

				if (response) {
					const header = response.headers["content-disposition"];
					const parts = header.split(";");
					const filename = parts[1].split("=")[1];
					const downloadLink = document.createElement("a");
					const blob = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					const docUrl = URL.createObjectURL(blob);
					downloadLink.href = docUrl;
					downloadLink.download = filename;
					document.body.appendChild(downloadLink);
					downloadLink.click();
					document.body.removeChild(downloadLink);

					toast.success(t("exported-successfully"));
					return { status: response.status };
				}
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoadingExport(false);
			}
		},
		[call, setLoadingExport, t],
	);

	return {
		state,
		setState,
		setData,
		fetch,
		createSubmit,
		updateSubmit,
		remove,
		batchRemove,
		selected,
		goToView,
		goToHelp,
		goToCreate,
		goToEdit,
		goToClone,
		settingsExport,
		loadingExport,
	};
};

export default useSettingsCRUDHook;
