import { useState, useMemo, useCallback, useEffect } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import Field from "../../../components/Field";
import Text from "../../../components/Inputs/Text";
import Select from "../../../components/Inputs/Select";
import Button from "../../../components/Button";
import FlyerModal from "./FlyerModal";
import { useQrCodeClockIn } from "../context";
import { useLastShift } from "./store";
import { useUpdateShift } from "./api";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";

function Attestation() {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [page, setPage] = useState(0);
	const [values, setValues] = useState();
	const shift = useLastShift();
	const { attestations, flyers, dispatch, clockMode } = useQrCodeClockIn();
	const { update, loading } = useUpdateShift();

	const customFields = useMemo(() => {
		return attestations[page]?.customFields
	}, [attestations, page]);

	const onPrevClick = useCallback(() => {
		setValues((prev) => ({
			...prev,
			...form.getFieldValue(),
		}));
        setPage((prev) => prev - 1);
    }, [setPage, setValues, form]);

	const endShiftFlyers = useMemo(() => {
		return flyers?.find(({ place }) => place === "end-shift")
	}, [flyers])

	const close = useCallback(() => {
		const clockMode = endShiftFlyers ? "end-shift-modal": "clock-out";
		dispatch({ type: "set-clock-mode", payload: { clockMode } })
	}, [dispatch, endShiftFlyers])

	const onNextClick = useCallback((formValues) => {
		if (attestations?.length > 0 && page === attestations?.length - 1) {
			update(
				shift?.id, 
				{ customFields: { ...values, ...formValues } },
				close
			);
		} else if (page === attestations?.length - 1) {
			update(
				shift?.id, 
				{ customFields: { ...values, ...formValues } },
				close
			);
		} else {
			setValues((prev) => ({
				...prev,
				...formValues,
			}));

            setPage((prev) => prev + 1);
        }
	}, [setPage, attestations, page, setValues, shift?.id , update, values, close]);

	useEffect(() => {
		if (values && values[attestations[page]?.code]) {
			form.setFieldsValue(values)
		}
	}, [form, attestations, page, values])

	return (
		<>
			<Form
				className="h-100"
				form={form}
				onFinish={onNextClick}
			>
				<div className="h-75 mx-7">
					<div className="w-100 text-center p-5 font-weight-bold text-lg">
						{renderSettingsResourceLabel(attestations[page])}
					</div>

					{customFields?.map((field) => (
						<Field
							name={[attestations[page]?.code, field.code]}
							label={field.description}
							rules={[
								{
									required: field.isRequired,
									message: t("required-field")
								}
							]}
						>
							{field?.type === "select" ? (
								<Select placeholder={field?.description} allowClear={!field.isRequired}>
									{field?.options?.map(({ label, value }) => (
										<Select.Option value={value} key={value}>
											{label}
										</Select.Option>
									))}
								</Select>
							) : (
								<Text type={field.type} placeholder={field.description} />
							)}
						</Field>
					))}
				</div>

				<div className="d-flex align-items-center justify-content-center h-25">
					<Button
						className="bg-primary text-white px-3 py-2 rounded text-center cursor-pointer mr-2 font-weight-bold"
						style={{
							fontSize: "15px"
						}}
						htmlType="button"
						onClick={onPrevClick}
						disabled={loading || page <= 0}
					>
						{t("prev")}
					</Button>

					<Button
						className="bg-primary text-white px-3 py-2 rounded text-center cursor-pointer mr-2 font-weight-bold"
						style={{
							fontSize: "15px",
							maxWidth: "fit-content",
						}}
					>
						{page + 1}
					</Button>

					<Button
						className="bg-primary text-white px-3 py-2 rounded text-center cursor-pointer font-weight-bold"
						style={{
							fontSize: "15px",
						}}
						htmlType="submit"
						loading={loading}
					>
						{page + 1 === attestations?.length
							? t("continue")
							: t("next")}
					</Button>
				</div>
			</Form>

			{clockMode === "end-shift-modal" && endShiftFlyers && <FlyerModal />}
		</>
	)
}

export default Attestation;
