import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Field from "../../../../../components/Field";
import DateInput from "../../../../../components/Inputs/DateInput";
import Text from "../../../../../components/Inputs/Text";
import Button from "../../../../../components/Button";
import { usePeopleApi } from "../../../../../utils/api/people";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { ProfileContext } from "../../../context";
import { useCompany } from "../../../../../utils/hooks/company";
import {
	applyTimezone,
	dateTimeParseZone,
} from "../../../../../utils/helpers/date";

function TerminateEmployee({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();

	const timezone = useMemo(
		() => company?.settings?.timezone?.value,
		[company?.settings?.timezone?.value],
	);

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { terminate } = usePeopleApi();

	const onFinish = useCallback(
		(values) => {
			const data = {
				...values,
				terminatedAt: applyTimezone(
					values.terminatedAt,
					timezone,
					true,
				),
				status: "terminated",
			};
			submit(() => undefined, terminate, data, close);
		},
		[timezone, terminate, submit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			terminatedAt: user?.terminatedAt
				? dateTimeParseZone(user?.terminatedAt, "date")
				: undefined,
			status: user?.status,
		});
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["terminatedAt"],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field
				name="terminatedAt"
				label={t("terminated-date")}
				rules={[
					{
						required: true,
						message: t("required-terminated-date"),
					},
				]}
				className="d-flex flex-column"
			>
				<DateInput placeholderText={t("terminated-date")} />
			</Field>

			<Field name="reason" label={t("reason")}>
				<Text placeholder={t("reason")} />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default TerminateEmployee;
