import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DurationInput, { getDurationInputValue } from "../../../../../components/Inputs/DurationInput";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { useCompanyTimeFormat } from "../../../../../utils/hooks/company";
import { isAddedTimeSheet } from "../helpers";
import { getHourTypeColor } from "../../../../../utils/helpers/hourType";
import { useAccess } from "../../../../../utils/hooks/access";
import NumberInput from "../../../../../components/Inputs/NumberInput";
import { usePayCodesMap } from "../../../../Settings/PayPolicies/CalculationGroup/Form/hooks/usePayCodeMap";

export const getTimeSheetDuration = (timeSheet) => {
    const { startDate, endDate, payCode } = timeSheet;
    if (!startDate || !endDate) {
        return 0;
    }
    if (payCode?.hourType === "unit") {
        return timeSheet.duration;
    }
    return moment(endDate).diff(moment(startDate), "second");
}

export const useDurationColumn = ({ canEdit }) => {
    const { t } = useTranslation();
    const timeFormat = useCompanyTimeFormat();

    const hourTypeColor = getHourTypeColor();
    const { hasAccess: canUpdateDuration } = useAccess("timeSheet.canUpdateDuration");

    const map = usePayCodesMap();

    return useMemo(() => ({
        Header: t("duration"),
        accessor: "duration",
        alwaysVisible: true,
        Cell: ({ row, cell, updateAddedTimeSheet, updateTimeSheet }) => {
            const { original } = row;
            const { startDate, endDate, locked } = original;
            const payCode = map[original.payCode.code]
            if (!startDate) {
                return null;
            }

            const disabled = (!isAddedTimeSheet(original) && locked)
                || row.disabled || cell.disabled;

            if (payCode?.hourType === "unit" || (canUpdateDuration && payCode?.hourType !== "amount")) {
                if (payCode?.inputDurationAsNumber) {
                    return (
                        <NumberInput
                            value={(moment.duration(original.duration, "second").asHours() || 0).toFixed(2)}
                            disabled={disabled || !canEdit}
                            className="time-input"
                            style={{
                                color: '#595959',
                                backgroundColor: 'transparent',
                                border: '1px solid #d9d9d9',
                                fontSize: '0.9rem',
                            }}
                            onChange={(e) => {
                                let val = Number(e.target.value);
                                if (val === NaN) {
                                    return;
                                }
                                if (val < 0) {
                                    val = 0;
                                } else if (val >= 24) {
                                    val = 23.99;
                                }
                                let duration = (startDate && endDate) 
                                    ? moment.parseZone(endDate).diff(moment.parseZone(startDate), "seconds")
                                    : 0;
                                let endD = endDate;

                                if (val) {
                                    duration = moment.duration(val, "hour").asSeconds();
                                }
                            
                                if (duration > 0) {
                                    endD = moment.parseZone(startDate).add(duration, "seconds").toISOString(true); 
                                }

                                if (isAddedTimeSheet(original)) {
                                    updateAddedTimeSheet(original, { duration, endDate: endD });
                                } else {
                                    updateTimeSheet(original.id, { duration, endDate: endD });
                                }
                            }}
                         />
                    );
                }
                const value = renderDurationAsFormat(original.duration, "HH:mm")
                return (
                    <DurationInput
                        value={value}
                        disabled={disabled || !canEdit}
                        timeFormat="HH:mm"
                        className="time-input"
                        style={{
                            color: '#595959',
                            backgroundColor: 'transparent',
                            border: '1px solid #d9d9d9',
                            fontSize: '0.9rem',
                        }}
                        onChange={(val) => {
                            let duration = (startDate && endDate) 
                                ? moment.parseZone(endDate).diff(moment.parseZone(startDate), "seconds")
                                : 0;
                            let endD = endDate;

                            if (val) {
                                duration = getDurationInputValue(val);
                            }
                          
                            if (duration > 0) {
                                endD = moment.parseZone(startDate).add(duration, "seconds").toISOString(true); 
                            }

                            if (isAddedTimeSheet(original)) {
                                updateAddedTimeSheet(original, { duration, endDate: endD });
                            } else {
                                updateTimeSheet(original.id, { duration, endDate: endD });
                            }
                        }}
                    />
                );
            } else if (payCode?.hourType === "amount") {
                return "-"
            } else {
                const duration = moment(endDate).diff(moment(startDate), "second");
                return renderDurationAsFormat(duration, "HH:mm");
            }
        },
    }), [t, map, timeFormat, canUpdateDuration, canEdit]);
};
