import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Field from "../../../../../components/Field";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import DateInput from "../../../../../components/Inputs/DateInput";
import { useAuthentication } from "../../../../Authentication/context";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import {
    renderSettingsResourceLabel,
    renderOrgLevelResourceLabel,
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { firstToUpper } from "../../../../../utils/helpers/string";
import DurationInput, {
    getDurationInputValue,
} from "../../../../../components/Inputs/DurationInput";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import { isNumber } from "lodash";

function ExtendedMoneyInput({ value: _value, onChange: _onChange, ...props }) {
    const value = useMemo(() => {
        if (!isNumber(_value)) {
            return undefined;
        }
        return (_value || 0).toFixed(2);
    }, [_value]);

    const onChange = useCallback(
        (value) => {
            if (!_onChange) {
                return;
            }
            if (isNaN(value)) {
                value = Number(value?.replace(/[^0-9\.]+/g, ""));
            }
            _onChange(value || 0);
        },
        [_onChange],
    );

    return <MoneyInput value={value} onChange={onChange} {...props} />;
}

function ExtendedDurationInput({
    value: _value,
    onChange: _onChange,
    ...props
}) {
    const value = useMemo(() => {
        return renderDurationAsFormat(_value || 0, "HH:mm");
    }, [_value]);

    const onChange = useCallback(
        (value) => {
            if (!_onChange) {
                return;
            }
            _onChange(getDurationInputValue(value));
        },
        [_onChange],
    );

    return <DurationInput value={value} onChange={onChange} {...props} />;
}

export const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

function Content({ disabled }) {
    const { t } = useTranslation();
    const { groups } = useContext(OrgLevelGroupsContext);
    const { company } = useAuthentication();

    const group = useMemo(
        () => groups?.find((value) => value.hasBudget),
        [groups],
    );

    return (
        <>
            <Field name="locationId" label={t("location")}>
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/locations"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                    disabled={disabled}
                    allowClear
                    placeholder={t("location")}
                />
            </Field>

            {group && (
                <Field
                    name="levelId"
                    label={renderOrgLevelResourceLabel(group)}
                    rules={[
                        {
                            required: true,
                            message: t("required-group", {
                                group: renderOrgLevelResourceLabel(group),
                            }),
                        },
                    ]}
                >
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath={`${group?.id}/org-levels`}
                        renderLabel={renderOrgLevelResourceLabel}
                        getSearchFilters={getSettingsOrgResourceSearchFilters}
                        hasSearch
                        placeholder={t(`${group.description}`)}
                        disabled={disabled}
                    />
                </Field>
            )}

            <Field
                name="year"
                label={t("year")}
                rules={[
                    {
                        required: true,
                        message: t("required-year"),
                    },
                ]}
                className="d-flex flex-column"
            >
                <DateInput
                    showYearPicker
                    dateFormat="yyyy"
                    disabled={disabled}
                    placeholderText={t("year")}
                />
            </Field>

            {months.map((month) => (
                <>
                    <Label>{t(month)}</Label>

                    <div className="d-flex align-items-center gap-1 w-100">
                        <Field name={[month, "amount"]} className="w-50">
                            <ExtendedMoneyInput
                                prefix={renderCurrencySymbol(
                                    company?.settings.currency,
                                )}
                                className="form-control"
                                disabled={disabled}
                                placeholder={t("value")}
                            />
                        </Field>

                        <Field name={[month, "hours"]} className="w-50">
                            <ExtendedDurationInput
                                placeholder={firstToUpper(t("hours"))}
                            />
                        </Field>
                    </div>
                </>
            ))}
        </>
    );
}

export default Content;
