import { useCallback } from "react";
import { useBatchUpdateTimeSheets } from "../../../../api/useBatchUpdateTimeSheets";
import { isAddedTimeSheet } from "../../../../components/TimeSheetTable/helpers";
import { useDateFilters } from "../../../../hooks/useDateFilters";

export const useBatchUpdate = ({ user, refetch, filters }) => {
    const { from, to } = useDateFilters();
    const { submit, loading } = useBatchUpdateTimeSheets(user?.id);

    const batchUpdate = useCallback(
        ({ addedTimeSheets, updatedTimeSheets }, onSuccess, onFailure) => {
            const data = {
                added: Object
                    .values(addedTimeSheets)
                    .flat()
                    .filter((timeSheet) => timeSheet.isTouched)
                    .filter((timeSheet) => {
                        if (!isAddedTimeSheet(timeSheet)) {
                            return;
                        }
                        return timeSheet.startDate && timeSheet.endDate;
                    }),
                updated: updatedTimeSheets,
                filters: { from, to, filters },
            };
            submit(data, async (response) => {
                await refetch(response)
                return onSuccess?.(response);
            }, onFailure);
        },
        [submit, refetch, from, to, filters],
    );

    return { submit: batchUpdate, loading };
}
