import { Navigate } from "react-router-dom";
import Base from "./";
import ErrorPage from "./ErrorPage";
import LogIn from "./LogInForm";
import Shift from "./Shift";
import Success from "./Success";
import Flyers from "../Settings/ClockIn/Flyers";
import Attestation from "./Shift/Attestation";

export default {
    path: "/qr-code-clock-in/:id/",
    element: <Base />,
    children: [
        {
            path: "not-valid",
            element: <ErrorPage />,
        },
        {
            path: "login",
            element: <LogIn />,
        },
        {
            path: "clocks",
            element: <Shift />,
        },
        {
            path: "attestation",
            element: <Attestation />,
        },
        {
            path: "flyers",
            element: <Flyers />
        },
        {
            path: "success/:action",
            element: <Success />,
        },
        {
            path: "*",
            element: <Navigate to="" />,
        },
    ],
};
