import { useState, useCallback, useMemo } from "react";
import BaseCron from "react-js-cron";
import Text from "../../../../../components/Inputs/Text";
import getSqTranslate from "../../../../../utils/helpers/cron/getSqTranslate";
import getEsTranslate from "../../../../../utils/helpers/cron/getEsTranslate";
import getEnTranslate from "../../../../../utils/helpers/cron/getEnTranslate";
import "react-js-cron/dist/styles.css";

function Cron({ cronTimeConfig, setCronTimeConfig, disabled }) {
	const [error, setError] = useState();

	const customSetValue = useCallback(
		(newValue) => {
			setCronTimeConfig(newValue);
		},
		[setCronTimeConfig],
	);

	const language = localStorage.getItem("i18nextLng");
	const sq = getSqTranslate();
	const en = getEnTranslate();
	const es = getEsTranslate();

	const locale = useMemo(() => {
		switch (language) {
			case "sq":
				return sq;
			case "es":
				return es;
			case "en":
			default:
				return en
		}
	}, [language])

	return (
		<>
			<Text
				onBlur={(event) => {
					setCronTimeConfig(event.target.value);
				}}
				onChange={(e) => customSetValue(e.target.value)}
				className="mb-3"
				value={cronTimeConfig}
				disabled={disabled}
			/>

			<BaseCron
				value={cronTimeConfig}
				setValue={customSetValue}
				clearButtonProps={{
					className: "bg-primary",
				}}
				onError={setError}
				disabled={disabled}
				locale={locale}
				humanizeLabels={true}
			/>

			<p className="invalid-feedback d-block">
				{error && error?.description}
			</p>
		</>
	);
}

export default Cron;
