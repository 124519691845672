import { faEllipsisVertical, faMinus, faPlus, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";
import { noop } from "lodash";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from "reactstrap";
import DurationInput, { getDurationInputValue } from "../../../../../../../components/Inputs/DurationInput";
import Select from "../../../../../../../components/Inputs/Select";
import { currencyFormatter } from "../../../../../../../utils/helpers/currencyFormatter";
import { renderDurationAsFormat } from "../../../../../../../utils/helpers/date";
import { renderSettingsResourceLabel } from "../../../../../../../utils/helpers/settings";
import { getActiveResources, getPrimaryActiveResource } from "../../../../../../../utils/helpers/user";
import { useVisible } from "../../../../../../../utils/hooks/useVisible";
import { useGroups } from "../../../../../../Settings/General/OrganisationLevelGroups/context";
import LevelSelect from "../../../../../components/LevelSelect";
import PayCodeSelect from "../../../../../components/PayCodeSelect";
import { getCustomFieldInput } from "../../../../../components/TimeSheetTable/useColumns/useCustomFieldsColumns";
import { useCustomFields } from "../../../../../hooks/useCustomFields";
import { useDates } from "../internal/useDates";
import MoneyInput from "../../../../../../../components/Inputs/MoneyInput";
import { renderCurrencySymbol } from "../../../../../../../utils/helpers/job";

const getRate = (item) => {
  const multiplier = parseFloat(item.payCode?.multiplier?.toString()) || 1;
  let rate = 0;
  if (item.job?.hourlyRate) {
    rate = item.job?.hourlyRate;
  } else if (item.payCode?.defaultRate) {
    rate = parseInt(item.job?.hourlyRate);
  }
  return rate * multiplier;
}

const dateHasItems = (rows, date) => {
  return !!rows.find((item) => {
    if (!(date in item.dates)) {
      return false;
    }
    return item.dates[date].duration > 0;
  });
}

const isDateLocked = (rows, date) => {
  return rows.every((item) => {
    const locked = item.dates[date].locked;
    return [undefined, true].includes(locked);
  }, undefined);
}

const isDateApproved = (rows, date) => {
  return rows.every((item) => {
    const approved = item.dates[date].approved;
    return [undefined, true].includes(approved);
  }, undefined);
}

function DateActionDropdown({
  date,
  locked, approved,
  lock, unlock, approve, unapprove,
  openDaySummary, openCopyModal,
}) {
  const { t } = useTranslation();
  const { visible, open, close } = useVisible();

  return (
    <Dropdown
      className="aaaabbbb"
      isOpen={visible}
      toggle={noop}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <DropdownToggle className="aaaabbbb__toggle">
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem header className="text-center">
          {date}
        </DropdownItem>

        {locked ? (
          <DropdownItem
            disabled={!unlock}
            onClick={() => unlock(date)}
          >
            {t("unlock")}
          </DropdownItem>
        ) : (
          <DropdownItem
            disabled={!lock}
            onClick={() => lock(date)}
          >
            {t("lock")}
          </DropdownItem>
        )}

        {approved ? (
          <DropdownItem
            disabled={!unapprove || locked}
            onClick={() => unapprove(date)}
          >
            {t("unapprove")}
          </DropdownItem>
        ) : (
          <DropdownItem
            disabled={!approve || locked}
            onClick={() => approve(date)}
          >
            {t("approve")}
          </DropdownItem>
        )}

        <DropdownItem
          disabled={!openDaySummary}
          onClick={openDaySummary}
        >
          {t("view-summary")}
        </DropdownItem>

        <DropdownItem
          disabled={!openCopyModal}
          onClick={openCopyModal}
        >
          {t("copy")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export const useColumns = ({
  user: tableUser,
  openDaySummary,
  openCopyModal,
}) => {
  const { t } = useTranslation();
  const dates = useDates();
  const { data: customFields } = useCustomFields();
  const { allocatedGroups } = useGroups();

  return useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      columnHelper.display({
        id: "add",
        enableSorting: false,
        enableFilters: false,
        enableHiding: false,
        header: null,
        cell: (info) => {
          const { canEdit } = info.table.options.meta.permissions;
          if (!canEdit) {
            return null;
          }

          const { id, locked } = info.row.original;
          const {
            user,
            errors,
            addRow, removeRow
          } = info.table.options.meta;
          const canRemove = !locked && info.table.options.data.length > 1;

          const ErrorTooltip = ({ row, errors }) => {
            const error = useMemo(() => {
              if (!errors[row.id]) {
                return false;
              }
              return Object.values(errors[row.id]).find((err) => !!err)
            }, [errors[row.id]]);

            const { visible, toggle } = useVisible();

            if (!error) {
              return null;
            }

            const id = `timesheet-${user.id}-row-${row.id}-error`;

            return (
              <>
                <span id={id}>
                  <FontAwesomeIcon
                    className="cursor-pointer text-red"
                    icon={faTriangleExclamation}
                    size="lg"
                  />
                  <Tooltip target={id} isOpen={visible} toggle={toggle}>
                    <span className="text-sm font-weight-bolder px-2 m-2">
                      {error}
                    </span>
                  </Tooltip>
                </span>
              </>
            );
          }

          return (
            <span className="d-inline-flex gap-x-2">
              <span
                className="cursor-pointer text-info"
                onClick={() => addRow(info.row.index + 1)}
              >
                <FontAwesomeIcon icon={faPlus} size="lg" />
              </span>

              {canRemove && (
                <span
                  className="cursor-pointer text-red"
                  onClick={() => removeRow(id, info.row.index)}
                >
                  <FontAwesomeIcon icon={faMinus} size="lg" />
                </span>
              )}

              <ErrorTooltip row={info.row} errors={errors} />
            </span>
          );
        },
      }),
      columnHelper.accessor("payCode", {
        id: "payCode",
        enableSorting: false,
        enableFilters: false,
        header: t("time-code"),
        cell: (info) => {
          const { user, updateRow } = info.table.options.meta;
          const { canEdit } = info.table.options.meta.permissions;
          const value = info.getValue();
          return (
            <div className="text-left">
              <PayCodeSelect
                companies={user.companies}
                payGroup={user.payGroup}
                placeholder="--"
                value={value}
                disabled={!canEdit}
                onChange={(newValue) => {
                  if (value?.hourType === newValue.hourType) {
                    updateRow(info.row.original.id, { payCode: newValue });
                  } else {
                    updateRow(info.row.original.id, {
                      payCode: newValue,
                      dates: Object
                        .keys(info.row.original.dates)
                        .reduce((total, date) => {
                          total[date] = {
                            ...info.row.original.dates[date],
                            amount: 0,
                            duration: 0,
                          };
                          return total;
                        }, {}),
                    });
                  }
                }}
              />
            </div>
          );
        },
      }),
      ...dates.map((date) => {
        return columnHelper.accessor(`dates.${date}`, {
          id: date,
          enableSorting: false,
          enableFilters: false,
          enableHiding: false,
          header: ({ table }) => {
            const {
              user,
              lock, unlock, approve, unapprove, isLoadingDateAction,
            } = table.options.meta;
            const permissions = table.options.meta.permissions;
            const hasItems = dateHasItems(table.options.data, date);
            let canLock, canApprove = false;
            let locked, approved = false;
            if (hasItems) {
              if (permissions.canLock) {
                canLock = true;
              }
              if (permissions.canApprove) {
                canApprove = true;
              }
              locked = isDateLocked(table.options.data, date);
              approved = isDateApproved(table.options.data, date);
            }
            return (
              <div className="d-flex gap-x-2">
                <div className="d-flex flex-column align-items-center">
                  <span className="font-weight-bolder">
                    {moment(date).format("ddd").toLowerCase()}
                  </span>
                  <span>{moment(date).format("MMM DD, YYYY")}</span>
                </div>

                <DateActionDropdown
                  date={moment(date).format("MMM DD, YYYY")}
                  userId={user.id}
                  {...{
                    canLock, canApprove, locked, approved,
                    lock: canLock ? lock : undefined,
                    unlock: canLock ? unlock : undefined,
                    approve: canApprove ? approve : undefined,
                    unapprove: canApprove ? unapprove : undefined,
                    isLoadingDateAction,
                  }}
                  openCopyModal={openCopyModal ? () => {
                    openCopyModal({ date, user });
                  } : undefined}
                  openDaySummary={openDaySummary ? () => {
                    openDaySummary(
                      user,
                      moment(date).startOf("day").toISOString(true),
                    );
                  } : undefined}
                />
              </div>
            );
          },
          cell: (info) => {
            const { id, payCode } = info.row.original;
            const { updateRow } = info.table.options.meta;
            const { canEdit } = info.table.options.meta.permissions;
            const hasItems = dateHasItems(info.table.options.data, date);
            let locked = false;
            if (hasItems) {
              locked = isDateLocked(info.table.options.data, date);
            }

            if (locked === true) {
              console.log({ date, data: info.table.options.data })
            }
            if (payCode?.hourType === "amount") {
              const { job } = info.row.original;
              const value = info.getValue()?.amount || 0;
              return (
                <MoneyInput
                  className="money-input"
                  prefix={renderCurrencySymbol(job?.currency)}
                  value={value ? value.toFixed(2) : "--"}
                  disabled={locked || !canEdit}
                  style={{
                    borderColor: value ? "#000000" : undefined,
                    borderWidth: "0.17rem",
                    borderStyle: 'solid',
                    borderRadius: '0.375rem',
                    width: 60,
                  }}
                  onChange={(value) => {
                    updateRow(id, {
                      dates: {
                        ...info.row.original.dates,
                        [date]: {
                          ...info.row.original.dates[date],
                          amount: value,
                        },
                      },
                    });
                  }}
                />
              );
            }
            const value = info.getValue()?.duration || 0;
            const formattedValue = value === 0
              ? "--"
              : renderDurationAsFormat(value, "HH:mm", false)

            return (
              <DurationInput
                value={formattedValue}
                timeFormat="HH:mm"
                className="time-input"
                disabled={locked || !canEdit}
                style={{
                  color: '#595959',
                  backgroundColor: 'transparent',
                  border: '1px solid #d9d9d9',
                  fontSize: '0.9rem',
                }}
                onChange={(value) => {
                  value = getDurationInputValue(value) || 0;
                  updateRow(id, {
                    dates: {
                      ...info.row.original.dates,
                      [date]: {
                        ...info.row.original.dates[date],
                        duration: value,
                      },
                    },
                  });
                }}
              />
            );
          },
          footer: ({ column, table }) => {
            const { dateErrors } = table.options.meta;
            let value = table.options.data.reduce((total, item) => {
              if (!(column.id in item.dates)) {
                return total;
              }
              return total + item.dates[column.id].duration;
            }, 0);
            if (value === 0 || !value) {
              value = "--";
            } else {
              value = renderDurationAsFormat(value || 0, "HH:mm");
            }
            const ErrorTooltip = ({ userId, column, error }) => {
              const { visible, toggle } = useVisible();

              if (!error) {
                return null;
              }

              const id = `timesheet-${userId}-column-${column.id}-date-error`;

              return (
                <>
                  <span id={id}>
                    <FontAwesomeIcon
                      className="cursor-pointer text-red"
                      icon={faTriangleExclamation}
                      size="lg"
                    />
                    <Tooltip target={id} isOpen={visible} toggle={toggle}>
                      <span className="text-sm font-weight-bolder px-2 m-2">
                        {error}
                      </span>
                    </Tooltip>
                  </span>
                </>
              );
            }
            return (
              <span className="d-inline-flex align-items-center gap-x-2">
                <span>
                  {value}
                </span>

                <ErrorTooltip
                  userId={tableUser.id}
                  column={column}
                  error={dateErrors[date]}
                />
              </span>
            );
          },
        });
      }),
      columnHelper.display({
        id: "total-hours",
        header: t("total-hours"),
        cell: (info) => {
          const value = dates.reduce((total, date) => {
            if (!(date in info.row.original.dates)) {
              return total;
            }
            return total + info.row.original.dates[date].duration;
          }, 0);
          if (value === 0 || !value) {
            return "--";
          }
          return <b>{renderDurationAsFormat(value || 0, "HH:mm")}</b>;
        },
        footer: ({ table }) => {
          const value = table.options.data.reduce((total, data) => {
            return total + dates.reduce((total, date) => {
              if (!(date in data.dates)) {
                return total;
              }
              return total + data.dates[date].duration;
            }, 0);
          }, 0);
          if (value === 0 || !value) {
            return "--";
          }
          return <b>{renderDurationAsFormat(value || 0, "HH:mm")}</b>;
        },
      }),
      columnHelper.display({
        id: "total-amount",
        header: t("total-amount"),
        cell: (info) => {
          const { user } = info.table.options.meta;
          const hourType = info.row.original.payCode?.hourType;
          const currency = getPrimaryActiveResource(user.jobs || [])?.currency;
          let value = 0;
          switch (hourType) {
            case "amount": {
              value = dates.reduce((total, date) => {
                return total + info.row.original.dates[date]?.amount;
              }, 0);
              break;
            }
            case "unit": {
              value = dates.reduce((total, date) => {
                const hours = moment
                  .duration(info.row.original.dates[date]?.duration || 0, "second")
                  .asHours();
                return total + (hours * getRate(info.row.original));
              }, 0);
              break;
            }
            default: {
              break;
            }
          }
          if (value === 0 || !value) {
            return "--";
          }
          return <b>{currencyFormatter(value, 2, currency)}</b>;
        },
        footer: ({ table }) => {
          const currency = getPrimaryActiveResource(tableUser.jobs || [])?.currency;
          const value = table.options.data.reduce((total, data) => {
            const hourType = data.payCode.hourType;
            switch (hourType) {
              case "amount": {
                return total + dates.reduce((total, date) => {
                  return total + data.dates[date]?.amount;
                }, 0);
              }
              case "unit": {
                return total + dates.reduce((total, date) => {
                  const hours = moment
                    .duration(data.dates[date]?.duration || 0, "second")
                    .asHours();
                  return total + (hours * getRate(data));
                }, 0);
              }
              default: {
                return total;
              }
            }
          }, 0);
          if (value === 0 || !value) {
            return "--";
          }
          return <b>{currencyFormatter(value || 0, 2, currency)}</b>;
        },
      }),
      ...allocatedGroups.map((group) => {
        const level = group.level.replace("_", "");
        return columnHelper.accessor(level, {
          id: level,
          enableSorting: false,
          enableFilters: false,
          header: group?.description,
          cell: (info) => {
            const value = info.getValue();
            const { original } = info.row;
            const { updateRow } = info.table.options.meta;
            const { canEdit } = info.table.options.meta.permissions;

            if (!group.allocatedBy) {
              return value && renderSettingsResourceLabel(value);
            }

            return (
              <LevelSelect
                className="select-input group-input w-100"
                group={group.id}
                location={original.location?.id}
                payGroup={original.user.payGroup?.id}
                union={original.user.union?.id || original.user.union}
                disabled={!canEdit}
                value={value}
                onChange={(value) => {
                  updateRow(info.row.original.id, { [level]: value });
                }}
              />
            );
          },
        });
      }),
      columnHelper.accessor("job", {
        id: "job",
        enableSorting: false,
        enableFilters: false,
        header: t("job"),
        cell: (info) => {
          const value = info.getValue();
          const { updateRow } = info.table.options.meta;
          const { canEdit } = info.table.options.meta.permissions;
          const { original, activeJobs } = info.row;
          const jobData = Array.isArray(activeJobs)
            ? activeJobs
            : getActiveResources(original.user?.jobs || []);
          return (
            <Select
              className="select-input group-input w-100"
              showSearch
              dropdownMatchSelectWidth={false}
              disabled={!canEdit}
              value={value?.id}
              onChange={(id) => {
                const job = jobData.find(
                  (job) => job.id === id
                );
                if (!job) {
                  return;
                }
                updateRow(info.row.original.id, { job });
              }}
            >
              {value && (
                <Select.Option value={value.id}>
                  {value.displayName}
                </Select.Option>
              )}

              {jobData?.filter(({ id }) => id !== value?.id)
                .map((job) => (
                  <Select.Option key={job.id} value={job.id}>
                    {job.displayName}
                  </Select.Option>
                ))}
            </Select>
          );
        },
      }),
      columnHelper.accessor("location", {
        id: "location",
        enableSorting: false,
        enableFilters: false,
        header: t("location"),
        cell: (info) => {
          const value = info.getValue();
          const { updateRow } = info.table.options.meta;
          const { canEdit } = info.table.options.meta.permissions;
          const { original } = info.row;
          return (
            <Select
              className="select-input group-input w-100"
              showSearch
              dropdownMatchSelectWidth={false}
              disabled={!canEdit}
              value={value?.id}
              onChange={(id) => {
                updateRow(info.row.original.id, {
                  location: info.row.original.user.locations?.find(
                    (location) => location.id === id
                  ),
                });
              }}
            >
              {value && (
                <Select.Option value={value.id}>
                  {value.displayName}
                </Select.Option>
              )}

              {getActiveResources(original.user?.locations || [])
                ?.filter(({ id }) => id !== value?.id)
                .map((location) => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.displayName}
                  </Select.Option>
                ))}
            </Select>
          );
        },
      }),
      ...customFields.map((field) => {
        return columnHelper.accessor(`customFields.${field.code}`, {
          id: `customFields.${field.code}`,
          enableSorting: false,
          enableFilters: false,
          header: field.description,
          cell: (info) => {
            const { updateRow } = info.table.options.meta;
            const { canEdit } = info.table.options.meta.permissions;
            return getCustomFieldInput({
              config: field,
              disabled: !canEdit,
              value: info.getValue(),
              onChange: (value) => {
                updateRow(info.row.original.id, {
                  customFields: {
                    ...info.row.original.customFields,
                    [field.code]: value,
                  },
                });
              },
            });
          },
        });
      }),
    ];
  }, [
    t,
    dates, customFields, allocatedGroups,
    openDaySummary, openCopyModal,
  ]);
}